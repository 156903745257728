import * as React from 'react';

import { Locale } from '../../../i18n';
import { Recruiter } from '../../../modules/recruiters/types';

import LoggedInActions from './LoggedInActions';
import LoggedOutActions from './LoggedOutActions';

import './NavigationActions.style.scss';

export type NavigationActionsProps = {
    recruiter: Recruiter | null;

    onClickLogout: () => void;
    onChangeLanguage: (lang: Locale) => void;
    onClickTutorial: () => void;
};

function NavigationActions({ recruiter, onClickLogout, onChangeLanguage, onClickTutorial }: NavigationActionsProps) {
    return (
        <div>
            {(!!recruiter && (
                <LoggedInActions
                    recruiter={recruiter}
                    onClickLogout={onClickLogout}
                    onClickTutorial={onClickTutorial}
                />
            )) || <LoggedOutActions onChangeLanguage={onChangeLanguage} />}
        </div>
    );
}

export default NavigationActions;
