import * as React from 'react';
import classNames from 'classnames';

type Props = {
    icon: string;
    className?: string;
};

type Icons = { [index: string]: string };

const icons: Icons = {
    avatar: require('../../assets/images/ic_avatar.svg'),
    letter: require('../../assets/images/ic_letter.svg'),
    pen: require('../../assets/images/ic_pen.svg'),
    'star-circle': require('../../assets/images/ic_star-circle.svg')
};

const Icon = (props: Props): React.ReactElement => {
    const className = classNames('tf-icon', props.className);

    const src = icons[props.icon];

    return <img className={className} src={src} />;
};

export default Icon;
