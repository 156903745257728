import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';

type LocalDocument = Document & {
    elementsFromPoint?: Function;
    msElementsFromPoint?: Function;
};

function getDropTargetElementsAtPoint(x, y, dropTargets) {
    const localDocument: LocalDocument = document;

    if (localDocument.msElementsFromPoint) {
        return localDocument.msElementsFromPoint(x, y);
    }

    return dropTargets.filter((t) => {
        const rect = t.getBoundingClientRect();
        return x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top;
    });
}

function DragAndDropContainer(props: React.PropsWithChildren<{}>) {
    const localDocument: LocalDocument = document;

    const hasNativeElementsFromPoint = localDocument && localDocument.elementsFromPoint;

    return (
        <DndProvider
            backend={TouchBackend}
            options={{
                enableMouseEvents: true,
                enableKeyboardEvents: true,
                getDropTargetElementsAtPoint: !hasNativeElementsFromPoint && getDropTargetElementsAtPoint
            }}
            {...props}
        />
    );
}

export default DragAndDropContainer;
