import * as React from 'react';
import classNames from 'classnames';

import { useOnResize } from '../../hooks/useOnResize';

import './MessageListScroll.style.scss';

export type MessageListScrollProps = {
    children: React.ReactNode;
    // we use that only for watching for changes
    messages: any[];
    useLegacyTheme?: boolean;
};

function MessageListScroll({ children, messages, useLegacyTheme }: MessageListScrollProps) {
    const [isSticky, setIsSticky] = React.useState(true);

    const elementRef = React.useRef<HTMLDivElement | null>(null);

    const scrollIfSticky = React.useCallback(() => {
        if (isSticky && elementRef.current) {
            elementRef.current.scrollTop = elementRef.current.scrollHeight;
        }
    }, [isSticky, messages]);

    React.useEffect(() => {
        scrollIfSticky();
    }, [scrollIfSticky]);

    useOnResize(() => {
        scrollIfSticky();
    }, [scrollIfSticky]);

    const onScroll = () => {
        if (!elementRef.current) {
            return null;
        }

        const scrollBottom = elementRef.current.scrollTop + elementRef.current.clientHeight;

        setIsSticky(scrollBottom + 1 >= elementRef.current.scrollHeight);
    };

    return (
        <div
            className={classNames('MessageListScroll', {
                'MessageListScroll--legacy-theme': useLegacyTheme
            })}
            ref={elementRef}
            onScroll={onScroll}
        >
            {children}
        </div>
    );
}

export default MessageListScroll;
