import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Job } from '../../modules/jobs/types';
import {
    hasExpiringCandidates,
    hasValidActiveUntilDate,
    isActive,
    isArchived,
    isDraft
} from '../../modules/jobs/utils';
import { getCandidatesRoute, getCandidatesWithNewMessagesRoute, getJobRoute } from '../../routes';

import BadgeIcon from '../BadgeIcon';
import Button from '../Button';
import Details from '../Details';
import DetailsPrimary from '../Details/DetailsPrimary';
import DetailsSecondary from '../Details/DetailsSecondary';
import FormattedLongNumericDate from '../FormattedLongNumericDate';
import Icon from '../Icon';
import JobType from '../JobType';
import JobState from '../JobState';

import {
    calculateCountOfNewCandidates,
    calculateCountOfSeenCandidates,
    calculateCountOfUnreadMessages
} from './JobListItem.utils';
import { JobListItemTitle } from './JobListItemTitle';

import './JobListItem.style.scss';

type Props =
    | {
          skeleton?: false | void;

          job: Job;
          onClickCheckoutStart: () => void;
      }
    | {
          skeleton: true;
      };

class JobListItem extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleClickCheckoutStart = this.handleClickCheckoutStart.bind(this);
    }

    handleClickCheckoutStart(event: React.MouseEvent) {
        event.preventDefault();

        if (this.props.skeleton) {
            return;
        }

        this.props.onClickCheckoutStart();
    }

    render(): React.ReactNode {
        const className = classNames('JobListItem', {
            'JobListItem--skeleton': !!this.props.skeleton
        });

        return (
            <div className={className}>
                <div className="JobListItem__informations">
                    <div className="JobListItem__state">
                        {!this.props.skeleton && <JobState job={this.props.job} />}
                    </div>

                    <div className="JobListItem__details">
                        <Details>
                            {this.renderDetailsPrimary()}
                            {this.renderDetailsSecondary()}
                        </Details>
                    </div>
                </div>

                {this.renderActions()}
            </div>
        );
    }

    renderDetailsPrimary() {
        if (this.props.skeleton) {
            return <DetailsPrimary truncate className="JobListItem__details-primary" />;
        }

        const { job } = this.props;

        return (
            <DetailsPrimary className="JobListItem__details-primary">
                <JobListItemTitle job={job} className={!job.title ? 'JobListItem__hint' : undefined}>
                    {job.title || <FormattedMessage id="JOB_LIST_ITEM.NO_TITLE" />}
                </JobListItemTitle>{' '}
                <Link to={getJobRoute(job.id)} className="JobListItem__details-primary-icon-link">
                    <Icon icon="pen" className="JobListItem__details-primary-icon" />
                </Link>
            </DetailsPrimary>
        );
    }

    renderDetailsSecondary() {
        if (this.props.skeleton) {
            return <DetailsSecondary className="JobListItem__details-secondary" />;
        }

        const { job } = this.props;
        let content: React.ReactNode = null;

        if (isDraft(job)) {
            content = (
                <span className="JobListItem__hint">
                    <FormattedMessage id="JOB_LIST_ITEM.HINT_DRAFT" />
                </span>
            );
        } else if (isActive(job)) {
            if (hasValidActiveUntilDate(job)) {
                content = (
                    <FormattedMessage
                        id="JOB_LIST_ITEM.ACTIVE_UNTIL"
                        values={{
                            date: <FormattedLongNumericDate value={job.active_until} />
                        }}
                    />
                );
            }
        } else if (isArchived(job)) {
            content = (
                <span className="JobListItem__hint">
                    <FormattedMessage id="JOB_LIST_ITEM.HINT_ARCHIVED" />
                </span>
            );
        }

        return (
            <DetailsSecondary className="JobListItem__details-secondary">
                {!!job.product && (
                    <span className="JobListItem__ad-type">
                        <JobType job={job} />
                        {content && ' • '}
                    </span>
                )}
                {content}
            </DetailsSecondary>
        );
    }

    renderActions() {
        if (this.props.skeleton) {
            return (
                <div className="JobListItem__actions">
                    <div className="JobListItem__action JobListItem__action--fix-width JobListItem__action--details" />
                    <div className="JobListItem__action" />
                    <div className="JobListItem__action JobListItem__action--fix-width" />
                </div>
            );
        }

        const { job } = this.props;

        const newCandidates = calculateCountOfNewCandidates(job.statistics);
        const seenCandidates = calculateCountOfSeenCandidates(job.statistics);
        const unreadMessages = calculateCountOfUnreadMessages(job.statistics);

        // Generate actions
        const actions = [
            <div key={0} className="JobListItem__action JobListItem__action--fix-width JobListItem__action--details">
                <Link to={getJobRoute(job.id)} className="JobListItem__action-link">
                    <Icon icon="pen" />
                </Link>
            </div>
        ];

        if (isDraft(job) && !newCandidates && !seenCandidates && !unreadMessages) {
            actions.push(
                <div key={1} className="JobListItem__action">
                    <Button
                        type="button"
                        onClick={this.handleClickCheckoutStart}
                        typeStyle="raised"
                        variationStyle="brand"
                    >
                        <FormattedMessage id="JOB_LIST_ITEM.ACTION_CHECKOUT" />
                    </Button>
                </div>
            );
        } else {
            actions.push(
                <div key={1} className="JobListItem__action">
                    <Link to={getCandidatesRoute(job.id)} className="JobListItem__action-link">
                        <BadgeIcon
                            icon="avatar"
                            primaryBadge={newCandidates}
                            secondaryBadge={seenCandidates}
                            faded={hasExpiringCandidates(job)}
                        />
                    </Link>
                </div>
            );

            actions.push(
                <div key={2} className="JobListItem__action JobListItem__action--fix-width">
                    <Link to={getCandidatesWithNewMessagesRoute(job.id)} className="JobListItem__action-link">
                        <BadgeIcon icon="letter" primaryBadge={unreadMessages} />
                    </Link>
                </div>
            );
        }

        return <div className="JobListItem__actions">{actions}</div>;
    }
}

export default JobListItem;
