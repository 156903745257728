import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import DialogPageContainer from '../DialogPageContainerV2';
import Button from '../Button';
import { getLoginRoute, getSignupRoute } from '../../routes';

import sessionExpiredImage from '../../assets/images/session_expired.svg';

import './style.scss';

function SessionExpiredPage(): React.ReactElement {
    return (
        <DialogPageContainer containerClassName="SessionExpiredPage__dialog">
            <img src={sessionExpiredImage} className="SessionExpiredPage__illustration" role="presentation" />
            <h1 className="SessionExpiredPage__title">
                <FormattedMessage id="SESSION_EXPIRED_PAGE.TITLE" />
            </h1>
            <p className="SessionExpiredPage__description">
                <FormattedMessage id="SESSION_EXPIRED_PAGE.DESCRIPTION" />
            </p>
            <div className="SessionExpiredPage__actions">
                <Button
                    typeStyle="flat"
                    variationStyle="brand"
                    component={Link}
                    to={getSignupRoute()}
                    className="SessionExpiredPage__action-signup"
                >
                    <FormattedMessage id="SESSION_EXPIRED_PAGE.ACTION.SIGNUP" />
                </Button>
                <Button typeStyle="raised" variationStyle="brand" component={Link} to={getLoginRoute()}>
                    <FormattedMessage id="SESSION_EXPIRED_PAGE.ACTION.LOGIN" />
                </Button>
            </div>
        </DialogPageContainer>
    );
}

export default SessionExpiredPage;
