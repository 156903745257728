import { combineReducers } from 'redux';

import resetOnLogout from '../../authentication/reducer/resetOnLogout';
import { PACKAGES_FETCH, PACKAGE_FETCH } from '../../packages/actions';

import {
    EntityTypeEntitesState,
    EntityTypeFetchStatusState,
    EntityTypeStateEntitiesPartial,
    EntityTypeStateFetchStatusPartial
} from './';
import { Action } from '../../types';
import { Package } from '../../packages/types';

export type PackageEntityTypeState = EntityTypeStateEntitiesPartial<Package> & EntityTypeStateFetchStatusPartial;

export const entitiesReducer = (
    state: EntityTypeEntitesState<Package> = {},
    action: Action
): EntityTypeEntitesState<Package> => {
    switch (action.type) {
        case PACKAGES_FETCH.SUCCESS:
            const pkgs: Package[] = action.payload.data.results;

            // Create a new state object by creating a copy of the actual state and insert/replace packages.
            return pkgs.reduce((state: EntityTypeEntitesState<Package>, pkg: Package) => {
                return {
                    // Create a weak copy of the current state
                    ...state,

                    // Insert/replace package by id
                    [pkg.id]: pkg
                };
            }, state);

        case PACKAGE_FETCH.SUCCESS:
            const pkg = action.payload.data as Package;

            return {
                ...state,
                [pkg.id]: pkg
            };

        default:
            return state;
    }
};

export const fetchStatusReducer = (
    state: EntityTypeFetchStatusState = {},
    action: Action
): EntityTypeFetchStatusState => {
    let packageId;

    switch (action.type) {
        case PACKAGE_FETCH.REQUEST:
            packageId = action.payload.packageId;

            return {
                ...state,
                [packageId]: 'loading'
            };

        case PACKAGE_FETCH.SUCCESS:
            packageId = action.payload.packageId;

            return {
                ...state,
                [packageId]: 'loaded'
            };

        case PACKAGE_FETCH.FAILURE:
            packageId = action.payload.packageId;

            return {
                ...state,
                [packageId]: 'failed'
            };

        default:
            return state;
    }
};

const packagesReducer = combineReducers<PackageEntityTypeState>({
    entities: entitiesReducer,
    fetchStatus: fetchStatusReducer
});

export default resetOnLogout(packagesReducer);
