import { omit } from '../../../utils/object';

import resetOnLogout from '../../authentication/reducer/resetOnLogout';

import {
    FETCH_CONVERSATION_MESSAGES,
    CONVERSATION_MESSAGE_SEND,
    CONVERSATION_MESSAGES_POLLING
} from '../../conversations/actions';

import { EntityTypeEntitesState } from './';
import { Action } from '../../types';
import { Message } from '../../conversations/types';

const messagesReducer = (
    state: EntityTypeEntitesState<Message> = {},
    action: Action
): EntityTypeEntitesState<Message> => {
    switch (action.type) {
        case FETCH_CONVERSATION_MESSAGES.SUCCESS:
        case CONVERSATION_MESSAGES_POLLING.SUCCESS:
            const messages: Message[] = action.payload.response.data.results;

            return messages.reduce((state: EntityTypeEntitesState<Message>, message: Message) => {
                return {
                    ...state,
                    [message.id]: message
                };
            }, state);

        case CONVERSATION_MESSAGE_SEND.REQUEST:
            const message: Message = action.payload.message;

            return {
                ...state,
                [message.id]: message
            };

        case CONVERSATION_MESSAGE_SEND.SUCCESS:
            const newMessage = action.payload.response.data;
            const localMessageId = action.payload.localMessageId;

            return {
                ...omit(state, localMessageId),
                [newMessage.id]: newMessage
            };

        default:
            return state;
    }
};

export default resetOnLogout(messagesReducer);
