import React from 'react';
import { FormattedMessage } from 'react-intl';

import JobFormBenefitsFormField, { JobFormBenefitsFormFieldValue } from './JobFormBenefitsFormField';

export type JobFormBenefitsFormFieldsetValues = {
    benefits: JobFormBenefitsFormFieldValue;
};

export type JobFormBenefitsFormFieldsetProps = {};

function JobFormBenefitsFormFieldset({}: JobFormBenefitsFormFieldsetProps) {
    return (
        <fieldset className="panel">
            <div className="panel-heading">
                <h2 className="panel-title">
                    <FormattedMessage id="JOB_EDIT.BENEFITS_FORM_FIELDSET.TITLE" />
                </h2>
                <span className="panel-subtitle">
                    <FormattedMessage id="JOB_EDIT.BENEFITS_FORM_FIELDSET.SUBTITLE" />
                </span>
            </div>
            <div className="panel-body">
                <JobFormBenefitsFormField />
            </div>
        </fieldset>
    );
}

export default JobFormBenefitsFormFieldset;
