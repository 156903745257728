import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import PageContainer from '../../components/PageContainer';
import Headline from '../../components/Headline';
import HeadlineTitle from '../../components/Headline/HeadlineTitle';

import ResetPasswordConfirmPageForm from './ResetPasswordConfirmPageForm';
import { FormValues } from './ResetPasswordConfirmPageForm/ResetPasswordConfirmPageForm.constants';

import { confirmPasswordReset, resetPasswordResetConfirm } from '../../modules/authentication/actions';
import { getResetPasswordResetStatus } from '../../modules/authentication/selectors';

import './ResetPasswordConfirmPage.style.scss';

import { State as ReduxState } from '../../store/reducer';
import { ResetStatus } from '../../modules/types';

type RouteParams = {
    uid: string;
    token: string;
};

type ConnectorProps = RouteComponentProps<RouteParams>;

type Props = ConnectorProps & {
    resetStatus: ResetStatus;
    confirmPasswordReset: typeof confirmPasswordReset;
    resetPasswordResetConfirm: typeof resetPasswordResetConfirm;
};

export type State = {
    values: FormValues;
};

class ResetPasswordConfirmPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            values: {
                newPassword: '',
                newPasswordConfirm: ''
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.resetPasswordResetConfirm();
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (!(event.target instanceof window.HTMLInputElement)) {
            return;
        }

        const name: string = event.target.name as keyof FormValues;
        const value: string = event.target.value;

        this.setState(({ values }) => {
            return {
                values: {
                    ...values,
                    [name]: value
                }
            };
        });
    }

    handleSubmit(event: React.FormEvent) {
        event.preventDefault();

        const { newPassword } = this.state.values;

        const { uid, token } = this.props.match.params;

        this.props.confirmPasswordReset(newPassword, uid, token);
    }

    render() {
        const { values } = this.state;
        const { resetStatus } = this.props;

        let titleId = '';
        if (resetStatus !== 'resetted') {
            titleId = 'RESET_PASSWORD_CONFIRM.TITLE';
        } else if (resetStatus === 'resetted') {
            titleId = 'RESET_PASSWORD_CONFIRM.TITLE.SUBMITTED';
        }

        return (
            <PageContainer>
                <div className="ResetPasswordConfirmPage__small-container">
                    <Headline center>
                        <HeadlineTitle>
                            <FormattedMessage id={titleId} />
                        </HeadlineTitle>
                    </Headline>
                    <hr className="ResetPasswordConfirmPage__seperator" />
                    <ResetPasswordConfirmPageForm
                        values={values}
                        resetStatus={resetStatus}
                        onChange={this.handleChange}
                        onSubmit={this.handleSubmit}
                    />
                </div>
            </PageContainer>
        );
    }
}

const maptStateToProps = (state: ReduxState) => ({
    resetStatus: getResetPasswordResetStatus(state)
});

const mapDispatchToProps = {
    confirmPasswordReset,
    resetPasswordResetConfirm
};

export default connect(maptStateToProps, mapDispatchToProps)(ResetPasswordConfirmPage);
