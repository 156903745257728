import { combineReducers } from 'redux';
import { SET_LOCALE, SetLocaleAction } from './actions';

import { Locale, fallbackLocale } from '../../i18n/locale';

export const I18N_MOUNT = 'i18n';

type LocaleState = Locale;
const initialLocaleState: LocaleState = fallbackLocale;

function localeReducer(state: LocaleState = initialLocaleState, action: SetLocaleAction): LocaleState {
    switch (action.type) {
        case SET_LOCALE:
            return action.payload.locale;
        default:
            return state;
    }
}

export type I18NState = {
    locale: LocaleState;
};

export default combineReducers<I18NState>({
    locale: localeReducer
});
