import React from 'react';

/**
 * Caches the given value and invalidates it as soon as the dependencies change.
 */
export default function useConditionalCache<Type>(value: Type, dependencies: React.DependencyList): Type {
    return React.useMemo(() => {
        return value;
    }, dependencies);
}
