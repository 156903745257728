import he from 'he';
import { compose } from './function';

const POSSIBLE_FIRST_SYMBOLS = '[\\+\\(\\d]';
const POSSIBLE_SECOND_SYMBOLS = '[\\d ]';
const THIRD_SYMBOL_DIGIT = '\\d';
const POSSIBLE_PREFIX_START = '[\\d \\(]';
const POSSIBLE_PREFIX_END = '[\\d \\(\\)]';
const ALL_POSSIBLE_SYMBOLS = '[\\d \\(\\)/]+';
const POSSIBLE_LAST_SMYBOLS = '[\\d- ]+';
const END_SYMBOL_DIGIT = '\\d';

export const VALID_PHONE_NUMBER_PATTERN =
    POSSIBLE_FIRST_SYMBOLS +
    POSSIBLE_SECOND_SYMBOLS +
    THIRD_SYMBOL_DIGIT +
    POSSIBLE_PREFIX_START +
    POSSIBLE_PREFIX_END +
    ALL_POSSIBLE_SYMBOLS +
    POSSIBLE_LAST_SMYBOLS +
    END_SYMBOL_DIGIT;

export const VALID_URL_PATTERN = 'https?:\\/\\/(-\\.)?([^\\s\\/?\\.#-]+\\.?)+(\\/[^\\s]*)?';

export const VALID_PREFIX_PATTERN = '(\\s|^)';
export const VALID_SUFFIX_PATTERN = '(\\s|$)';

function getMatchesArguments(args: string[]): string[] {
    // Last two arguments aren't matches
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#Specifying_a_function_as_a_parameter
    return args.slice(0, -2);
}

export function encodeHTML(html: string = ''): string {
    return he.encode(html);
}

export function lineBreakify(text: string = ''): string {
    return text.replace(/\n/g, '<br>');
}

export function linkify(text: string = ''): string {
    const pattern = new RegExp(`${VALID_PREFIX_PATTERN}(${VALID_URL_PATTERN})${VALID_SUFFIX_PATTERN}`, 'gm');

    return text.replace(pattern, (match, ...args) => {
        const matches = getMatchesArguments(args);
        const beforeUrl = matches[0];
        const url = matches[1];
        const afterUrl = matches[matches.length - 1];

        return `${beforeUrl}<a href="${url}" target="_blank" rel="noopener">${url}</a>${afterUrl}`;
    });
}

export function phoneify(text: string = ''): string {
    const pattern = new RegExp(`${VALID_PREFIX_PATTERN}(${VALID_PHONE_NUMBER_PATTERN})${VALID_SUFFIX_PATTERN}`, 'gm');

    return text.replace(pattern, (match, ...args) => {
        const matches = getMatchesArguments(args);
        const beforePhoneNumber = matches[0];
        const phoneNumber = matches[1];
        const afterPhoneNumber = matches[matches.length - 1];

        return `${beforePhoneNumber}<a href="tel:${phoneNumber}">${phoneNumber}</a>${afterPhoneNumber}`;
    });
}

export const textToHtml = compose(lineBreakify, phoneify, linkify, encodeHTML);
