import { combineReducers } from 'redux';

import { CONVERSATION_FETCH, CONVERSATION_UPDATE, CONVERSATION_POLLING } from '../actions';

import { LOGOUT_SUCCESS } from '../../authentication/actions';

const entryReducer = (state = null, action) => {
    switch (action.type) {
        case CONVERSATION_FETCH.SUCCESS:
            const conversation = action.payload.response.data;

            return conversation.id;

        case LOGOUT_SUCCESS:
            return null;

        default:
            return state;
    }
};

const fetchStatusReducer = (state = 'none', action) => {
    switch (action.type) {
        case CONVERSATION_FETCH.REQUEST:
            return 'loading';

        case CONVERSATION_FETCH.SUCCESS:
            return 'loaded';

        case CONVERSATION_FETCH.FAILURE:
            return 'failed';

        case LOGOUT_SUCCESS:
            return 'none';

        default:
            return state;
    }
};

const updateStatusReducer = (state = 'none', action) => {
    switch (action.type) {
        case CONVERSATION_UPDATE.REQUEST:
            return 'updating';

        case CONVERSATION_UPDATE.SUCCESS:
            return 'updated';

        case CONVERSATION_UPDATE.FAILURE:
            return 'failed';

        case LOGOUT_SUCCESS:
            return null;

        default:
            return state;
    }
};

const pollingStatusReducer = (state = 'none', action) => {
    switch (action.type) {
        case CONVERSATION_POLLING.REQUEST:
            return 'loading';

        case CONVERSATION_POLLING.SUCCESS:
            return 'loaded';

        case CONVERSATION_POLLING.FAILURE:
            return 'failed';

        case LOGOUT_SUCCESS:
            return 'none';

        default:
            return state;
    }
};

const errorReducer = (state = null, action) => {
    switch (action.type) {
        case CONVERSATION_FETCH.FAILURE:
            return action.payload.errors;

        case CONVERSATION_UPDATE.FAILURE:
            return action.payload.errors;

        case LOGOUT_SUCCESS:
            return null;

        default:
            return state;
    }
};

const conversationReducer = combineReducers({
    entry: entryReducer,
    fetchStatus: fetchStatusReducer,
    updateStatus: updateStatusReducer,
    pollingStatus: pollingStatusReducer,
    error: errorReducer
});

export default conversationReducer;
