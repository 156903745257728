import { Button, FormField, Input } from '@truffls/design-system-react';
import * as React from 'react';
import ProgressBar from 'react-bootstrap/lib/ProgressBar';
import { FormattedMessage } from 'react-intl';
import { MapDispatchToProps, MapStateToProps, connect } from 'react-redux';

import { cancelJobParsing, parseJob } from '../../modules/jobParsing/actions';
import { JOB_PARSING_ERROR, JOB_PARSING_STATUS } from '../../modules/jobParsing/constants';
import {
    JobParsingErrorState,
    JobParsingServerErrorsState,
    JobParsingStatusState
} from '../../modules/jobParsing/reducer';
import { getJobParsingError, getJobParsingServerErrors, getJobParsingStatus } from '../../modules/jobParsing/selectors';
import { ERROR_CODE_VALIDATION_ERROR } from '../../services/api/constants';
import { getErrorWithCode } from '../../services/api/utils';
import { ApplicationState } from '../../store/types';
import { prefixHttp } from '../../utils/url';

import styles from './JobCreateSectionParse.module.scss';

export type JobCreateSectionParseProps = {};

type ConnectedStateProps = {
    jobParsingStatus: JobParsingStatusState;
    jobParsingError: JobParsingErrorState;
    jobParsingServerErrors: JobParsingServerErrorsState;
};

type ConnectedDispatchProps = {
    parseJob: typeof parseJob;
    cancelJobParsing: typeof cancelJobParsing;
};

type ConnectedProps = ConnectedStateProps & ConnectedDispatchProps;

type JobCreateSectionParsePropsWithConnected = JobCreateSectionParseProps & ConnectedProps;

function JobCreateSectionParse({
    jobParsingStatus: parsingStatus,
    jobParsingError,
    jobParsingServerErrors,
    parseJob,
    cancelJobParsing
}: JobCreateSectionParsePropsWithConnected) {
    const [value, setValue] = React.useState<string>('');

    React.useEffect(() => {
        return () => {
            cancelJobParsing();
        };
    }, []);

    const handleSubmit = () => {
        if (/\S/.test(value)) {
            const safeValue = prefixHttp(value, true);

            setValue(safeValue);
            parseJob(safeValue);
        }
    };

    const handleKeyPress = (evt: React.KeyboardEvent<HTMLInputElement>) => {
        if (evt.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setValue(evt.target.value);
    };

    const isParsing = parsingStatus === JOB_PARSING_STATUS.PARSING;
    const hasValue = !!value;

    return (
        <div className={styles.root}>
            {!isParsing && (
                <div className={styles.form}>
                    <FormField
                        id="url"
                        labelText="URL"
                        helpText={<FormattedMessage id="JOB_CREATE_SECTION_PARSE.LABEL" />}
                    >
                        <Input
                            type="text"
                            onKeyPress={handleKeyPress}
                            onChange={handleChange}
                            value={value}
                            autoFocus
                        />
                    </FormField>
                </div>
            )}

            {isParsing && hasValue && (
                <div className={styles.progressContainer}>
                    <ProgressBar active now={100} className={styles.progressBar} />
                    <div className={styles.progressUrl}>{value}</div>
                </div>
            )}

            <div className={styles.action}>
                <Button
                    type="button"
                    onClick={handleSubmit}
                    disabled={isParsing || !hasValue}
                    typeStyle="raised"
                    variationStyle="brand"
                >
                    <FormattedMessage id="JOB_CREATE_SECTION_PARSE.BUTTON" />
                </Button>
            </div>

            {parsingStatus === JOB_PARSING_STATUS.FAILED && (
                <div className={styles.error}>
                    <JobCreateSectionParseErrorMessage
                        jobParsingError={jobParsingError}
                        jobParsingServerErrors={jobParsingServerErrors}
                    />
                </div>
            )}
        </div>
    );
}

const mapStateToProps: MapStateToProps<ConnectedStateProps, JobCreateSectionParseProps, ApplicationState> = (
    state
) => ({
    jobParsingStatus: getJobParsingStatus(state),
    jobParsingError: getJobParsingError(state),
    jobParsingServerErrors: getJobParsingServerErrors(state)
});

const mapDispatchToProps: MapDispatchToProps<ConnectedDispatchProps, JobCreateSectionParseProps> = {
    parseJob,
    cancelJobParsing
};

export default connect(mapStateToProps, mapDispatchToProps)(JobCreateSectionParse);

type JobCreateSectionParseErrorMessageProps = {
    jobParsingError: JobParsingErrorState;
    jobParsingServerErrors: JobParsingServerErrorsState;
};

function JobCreateSectionParseErrorMessage({
    jobParsingError,
    jobParsingServerErrors
}: JobCreateSectionParseErrorMessageProps) {
    if (jobParsingError === JOB_PARSING_ERROR.TIMEOUT) {
        return <FormattedMessage id="JOB_CREATE_SECTION_PARSE.ERROR_TIMEOUT" />;
    } else if (jobParsingError === JOB_PARSING_ERROR.SERVER && !!jobParsingServerErrors) {
        const validationApiError = getErrorWithCode(jobParsingServerErrors, ERROR_CODE_VALIDATION_ERROR);

        if (!!validationApiError && validationApiError.source?.pointer === '/url') {
            return <FormattedMessage id="JOB_CREATE_SECTION_PARSE.ERROR_SERVER_INVALID_URL" />;
        }
    }
    return <FormattedMessage id="JOB_CREATE_SECTION_PARSE.ERROR_UNKNOWN" />;
}
