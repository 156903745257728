import { combineReducers } from 'redux';
import { createMapReducer } from '../../../utils/redux';
import { FETCH_STATUS } from '../../constants';
import { Action, Errors, FetchStatus } from '../../types';
import { CANDIDATES_BY_APPLICANT_ID_FETCH } from '../actions';
import { CandidateId } from '../types';

type FetchStatusState = FetchStatus;

const initialFetchStatusState: FetchStatusState = FETCH_STATUS.NONE;

function fetchStatusReducer(state: FetchStatusState = initialFetchStatusState, action: Action): FetchStatusState {
    switch (action.type) {
        case CANDIDATES_BY_APPLICANT_ID_FETCH.REQUEST:
            return FETCH_STATUS.LOADING;

        case CANDIDATES_BY_APPLICANT_ID_FETCH.SUCCESS:
            return FETCH_STATUS.LOADED;

        case CANDIDATES_BY_APPLICANT_ID_FETCH.FAILURE:
            return FETCH_STATUS.FAILED;

        default:
            return state;
    }
}

type EntriesState = CandidateId[];

const initialEntriesState = [];

function entriesReducer(state: EntriesState = initialEntriesState, action: Action): EntriesState {
    switch (action.type) {
        case CANDIDATES_BY_APPLICANT_ID_FETCH.SUCCESS:
            const { data } = action.payload;
            return data.results.map((candidate) => candidate.id);

        default:
            return state;
    }
}

type ErrorsState = Errors;

const initialErrorsState = [];

function errorsReducer(state: ErrorsState = initialErrorsState, action: Action): ErrorsState {
    switch (action.type) {
        case CANDIDATES_BY_APPLICANT_ID_FETCH.FAILURE:
            return action.payload.errors;

        // We clear the errors on success
        case CANDIDATES_BY_APPLICANT_ID_FETCH.SUCCESS:
            return initialErrorsState;

        default:
            return state;
    }
}

type ByApplicantState = {
    fetchStatus: FetchStatusState;
    entries: EntriesState;
    errors: ErrorsState;
};

export const byApplicantInitialState: ByApplicantState = {
    fetchStatus: initialFetchStatusState,
    entries: initialEntriesState,
    errors: initialErrorsState
};

const byApplicant = combineReducers<ByApplicantState>({
    fetchStatus: fetchStatusReducer,
    entries: entriesReducer,
    errors: errorsReducer
});

export type State = {
    [applicantId: string]: ByApplicantState;
};

function getApplicantIdFromAction(action: Action): string | null {
    return (action.payload && !!action.payload.applicantId && String(action.payload.applicantId)) || null;
}

const byApplicants = createMapReducer(byApplicant, getApplicantIdFromAction);

export default byApplicants;
