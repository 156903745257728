import { Checkbox } from '@truffls/design-system-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useField } from '../../../utils/form/react-final-form';
import JobFormFieldAnchor from '../JobFormFieldAnchor';

export type JobFormRemotePossibleFormFieldValue = boolean;
export type JobFormRemotePossibleFormFieldName = 'remote_possible';
export const JOB_EDIT_REMOTE_POSSIBLE_FORM_FIELD_NAME: JobFormRemotePossibleFormFieldName = 'remote_possible';

export type JobFormRemotePossibleFormFieldProps = {};

function JobFormRemotePossibleFormField({}: JobFormRemotePossibleFormFieldProps) {
    const {
        input: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
            value: _,
            ...input
        }
    } = useField<JobFormRemotePossibleFormFieldValue, HTMLInputElement>(JOB_EDIT_REMOTE_POSSIBLE_FORM_FIELD_NAME, {
        type: 'checkbox'
    });

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_REMOTE_POSSIBLE_FORM_FIELD_NAME} />
            <Checkbox {...input} id="job-form-field-input-remote_possible">
                <FormattedMessage id="JOB_EDIT.REMOTE_POSSIBLE_FORM_FIELD.LABEL_TEXT" />
            </Checkbox>
        </React.Fragment>
    );
}

export default JobFormRemotePossibleFormField;
