import * as React from 'react';
import classNames from 'classnames';

import { generateCellClassNames } from './utils';
import { isNew, isExpiring } from '../../modules/candidates/utils';

import './style.scss';

import { Candidate } from '../../modules/candidates/types';

type Props = {
    candidate: Candidate | null;
};

const CandidateStateProgressBar = (props: Props) => {
    const candidate = props.candidate;
    let state;
    let stateStatus = 0;

    if (!!candidate) {
        state = isNew(candidate) ? 'locked' : candidate.state;
    }

    switch (state) {
        case 'locked':
            stateStatus = 1;
            break;
        case 'interesting':
            stateStatus = 2;
            break;
        case 'phone-interview':
            stateStatus = 3;
            break;
        case 'interview':
            stateStatus = 4;
            break;
        case 'hired':
            stateStatus = 5;
            break;
    }

    const className = classNames('CandidateStateProgressBar', `CandidateStateProgressBar--${state}`, {
        'CandidateStateProgressBar--expiring': !!candidate && isExpiring(candidate)
    });

    return (
        <div className={className}>
            <div className={generateCellClassNames(stateStatus, 1)}>
                <div className="CandidateStateProgressBar__cell-marker" />
            </div>
            <div className={generateCellClassNames(stateStatus, 2)}>
                <div className="CandidateStateProgressBar__cell-marker" />
            </div>
            <div className={generateCellClassNames(stateStatus, 3)}>
                <div className="CandidateStateProgressBar__cell-marker" />
            </div>
            <div className={generateCellClassNames(stateStatus, 4)}>
                <div className="CandidateStateProgressBar__cell-marker" />
            </div>
            <div className={generateCellClassNames(stateStatus, 5)}>
                <div className="CandidateStateProgressBar__cell-marker" />
            </div>
        </div>
    );
};

export default CandidateStateProgressBar;
