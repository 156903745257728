import { createAction as createActionLegacy } from 'redux-actions';

import { ApiEntityResponse, ApiError } from '../../services/api/types';
import { formatISO } from '../../utils/date';
import { createAction, createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';
import { Conversation, ConversationId } from './types';

const createType = createActionTypeCreator('CANDIDATE_CONVERSATION');

// POLLING
export const START_MESSAGES_POLLING = createType('START_MESSAGES_POLLING');
export const STOP_MESSAGES_POLLING = createType('STOP_MESSAGES_POLLING');
export const startMessagesPolling = createActionLegacy(START_MESSAGES_POLLING, (conversationId: ConversationId) => ({
    conversationId
}));
export const stopMessagesPolling = createActionLegacy(STOP_MESSAGES_POLLING, (conversationId: ConversationId) => ({
    conversationId
}));

export const CONVERSATION_POLLING = createRequestActionTypes(createType('CONVERSATION_POLLING'));
export const conversationPolling = {
    request: createAction(CONVERSATION_POLLING.REQUEST, (conversationId: ConversationId) => ({
        payload: {
            conversationId
        }
    })),
    success: createAction(
        CONVERSATION_POLLING.SUCCESS,
        (conversationId: ConversationId, response: ApiEntityResponse<Conversation>) => ({
            payload: {
                conversationId,
                response
            }
        })
    ),
    failure: createAction(CONVERSATION_POLLING.FAILURE, (conversationId: ConversationId, errors: ApiError[]) => ({
        payload: {
            conversationId,
            errors
        }
    }))
};
export type ConversationPollingActions$Request = ReturnType<typeof conversationPolling['request']>;
export type ConversationPollingActions$Success = ReturnType<typeof conversationPolling['success']>;
export type ConversationPollingActions$Failure = ReturnType<typeof conversationPolling['failure']>;

export const CONVERSATION_MESSAGES_POLLING = createRequestActionTypes(createType('CONVERSATION_MESSAGES_POLLING'));
export const conversationMessagesPolling = {
    request: createActionLegacy(CONVERSATION_MESSAGES_POLLING.REQUEST, (conversationId: ConversationId) => ({
        conversationId
    })),
    success: createActionLegacy(CONVERSATION_MESSAGES_POLLING.SUCCESS, (conversationId: ConversationId, response) => ({
        conversationId,
        response
    })),
    failure: createActionLegacy(CONVERSATION_MESSAGES_POLLING.FAILURE, (conversationId: ConversationId, errors) => ({
        conversationId,
        errors
    }))
};

// FETCH_CONVERSATION
export const FETCH_CONVERSATION = createType('FETCH_CONVERSATION');
export const fetchConversation = createAction(FETCH_CONVERSATION, (conversationId: ConversationId) => ({
    payload: {
        conversationId
    }
}));
export type FetchConversationAction = ReturnType<typeof fetchConversation>;

export const CONVERSATION_FETCH = createRequestActionTypes(createType('CONVERSATION_FETCH'));
export const conversationFetch = {
    request: createAction(CONVERSATION_FETCH.REQUEST, (conversationId: ConversationId) => ({
        payload: {
            conversationId
        }
    })),
    success: createAction(
        CONVERSATION_FETCH.SUCCESS,
        (conversationId: ConversationId, response: ApiEntityResponse<Conversation>) => ({
            payload: {
                conversationId,
                response
            }
        })
    ),
    failure: createAction(CONVERSATION_FETCH.FAILURE, (conversationId: ConversationId, errors: ApiError[]) => ({
        payload: {
            conversationId,
            errors
        }
    }))
};
export type ConversationFetchActions$Request = ReturnType<typeof conversationFetch['request']>;
export type ConversationFetchActions$Success = ReturnType<typeof conversationFetch['success']>;
export type ConversationFetchActions$Failure = ReturnType<typeof conversationFetch['failure']>;

// FETCH_CONVERSATION_MESSAGES
export const FETCH_CONVERSATION_MESSAGES = createRequestActionTypes(createType('FETCH_CONVERSATION_MESSAGES'));
export const fetchConversationMessages = {
    request: createActionLegacy(FETCH_CONVERSATION_MESSAGES.REQUEST, (conversationId) => ({ conversationId })),
    success: createActionLegacy(FETCH_CONVERSATION_MESSAGES.SUCCESS, (conversationId, response) => ({
        conversationId,
        response
    })),
    failure: createActionLegacy(FETCH_CONVERSATION_MESSAGES.FAILURE, (conversationId, errors) => ({
        conversationId,
        errors
    }))
};

// UPDATE_CONVERSATION
export const UPDATE_CONVERSATION = createType('UPDATE_CONVERSATION');
export const CONVERSATION_UPDATE = createRequestActionTypes(createType('CONVERSATION_UPDATE'));
export const updateConversation = createAction(
    UPDATE_CONVERSATION,
    (conversationId: ConversationId, newConversation: Partial<Conversation>) => ({
        payload: {
            conversationId,
            newConversation
        }
    })
);
export type UpdateConversationAction = ReturnType<typeof updateConversation>;

export const conversationUpdate = {
    request: createAction(
        CONVERSATION_UPDATE.REQUEST,
        (conversationId: ConversationId, newConversation: Partial<Conversation>) => ({
            payload: {
                conversationId,
                newConversation
            }
        })
    ),
    success: createAction(
        CONVERSATION_UPDATE.SUCCESS,
        (conversationId: ConversationId, response: ApiEntityResponse<Conversation>) => ({
            payload: {
                conversationId,
                response
            }
        })
    ),
    failure: createAction(CONVERSATION_UPDATE.FAILURE, (conversationId: ConversationId, errors: ApiError[]) => ({
        payload: {
            conversationId,
            errors
        }
    }))
};
export type ConversationUpdateActions$Request = ReturnType<typeof conversationUpdate['request']>;
export type ConversationUpdateActions$Success = ReturnType<typeof conversationUpdate['success']>;
export type ConversationUpdateActions$Failure = ReturnType<typeof conversationUpdate['failure']>;

export const updateConversationLastRead = (conversationId, lastRead: string | Date = new Date()) => {
    const lastReadStr: string = lastRead instanceof Date ? formatISO(lastRead) : lastRead;

    return updateConversation(conversationId, {
        last_read: lastReadStr
    });
};

// SEND_CONVERSATION_MESSAGE
export const SEND_CONVERSATION_MESSAGE = createType('SEND_CONVERSATION_MESSAGE');
export const CONVERSATION_MESSAGE_SEND = createRequestActionTypes(createType('CONVERSATION_MESSAGE_SEND'));
export const sendConversationMessage = createActionLegacy(
    SEND_CONVERSATION_MESSAGE,
    (conversationId, ownerId, messageText = '', attachments = [], updateLastRead = false) => {
        return {
            conversationId,
            ownerId,
            messageText,
            attachments,
            updateLastRead
        };
    }
);
export const conversationMessageSend = {
    request: createActionLegacy(CONVERSATION_MESSAGE_SEND.REQUEST, (conversationId, message) => ({
        conversationId,
        message
    })),
    success: createActionLegacy(CONVERSATION_MESSAGE_SEND.SUCCESS, (conversationId, localMessageId, response) => ({
        conversationId,
        localMessageId,
        response
    })),
    failure: createActionLegacy(CONVERSATION_MESSAGE_SEND.FAILURE, (conversationId, errors) => ({
        conversationId,
        errors
    }))
};

// SEND_CONVERSATION_TEXT
export const SEND_CONVERSATION_TEXT = createType('SEND_CONVERSATION_TEXT');
export const sendConversationText = createActionLegacy(
    SEND_CONVERSATION_TEXT,
    (conversationId, textMessage, updateLastRead = false) => ({
        conversationId,
        message: textMessage,
        updateLastRead
    })
);

// SEND_CONVERSATION_ATTACHMENT
export const SEND_CONVERSATION_ATTACHMENT = createType('SEND_CONVERSATION_ATTACHMENT');
export const sendConversationAttachment = createActionLegacy(
    SEND_CONVERSATION_ATTACHMENT,
    (conversationId, attachmentMessage, updateLastRead = false) => ({
        conversationId,
        message: attachmentMessage,
        updateLastRead
    })
);

// UPLOAD_CONVERSATION_ATTACHMENT
export const UPLOAD_CONVERSATION_ATTACHMENT = createType('UPLOAD_CONVERSATION_ATTACHMENT');
export const CONVERSATION_ATTACHMENT_UPLOAD = createRequestActionTypes(createType('CONVERSATION_ATTACHMENT_UPLOAD'));
export const conversationAttachmentUpload = {
    request: createActionLegacy(CONVERSATION_ATTACHMENT_UPLOAD.REQUEST, (file) => ({ file })),
    success: createActionLegacy(CONVERSATION_ATTACHMENT_UPLOAD.SUCCESS, (file, response) => ({ file, response })),
    failure: createActionLegacy(CONVERSATION_ATTACHMENT_UPLOAD.FAILURE, (file, errors) => ({ file, errors }))
};
