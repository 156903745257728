const LEVELS = ['log', 'debug', 'info', 'warn', 'error'];

export function createLogger(name) {
    const logger = LEVELS.reduce((logger, level) => {
        logger[level] = (...args) => {
            const method = console[level] || console.log;
            return method.apply(console, [`[${name}]`, ...args]);
        };

        return logger;
    }, {});

    // If the environment is production or test set an empty function for debug.
    if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
        logger.debug = (noop) => noop;
    }

    return logger;
}
