import { Schema } from 'hast-util-sanitize';
import { Handlers, defaultHandlers } from 'mdast-util-to-hast';
import { remark } from 'remark';
import html from 'remark-html';

export type ConvertMarkdownToHtmlSyncOptions = {
    emojiListItems?: boolean;
};

export function convertMarkdownToHtmlSync(
    markdown: string,
    { emojiListItems }: ConvertMarkdownToHtmlSyncOptions = {}
): string {
    let mdastToHastHandlers: Handlers | null = null;
    let hastSchema: Schema | null = null;

    if (emojiListItems) {
        const plugin = emojiListItemsPlugin();

        mdastToHastHandlers = plugin.mdastToHastHandlers;
        hastSchema = plugin.hastSchema;
    }

    const r = remark().use(html, {
        handlers: mdastToHastHandlers,
        sanitize: hastSchema
    });

    const vfile = r.processSync(markdown);

    if (vfile.value instanceof Uint8Array) {
        return new TextDecoder().decode(vfile.value);
    }

    return vfile.value;
}

const listItemTextStartsWithEmojiPattern = /^(?:\u00a9|\u00ae|[\u2000-\u3300]\ufe0f?|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/;

export function emojiListItemsPlugin(): {
    mdastToHastHandlers: Handlers;
    hastSchema: Schema;
} {
    return {
        mdastToHastHandlers: {
            listItem: (h, node, parent) => {
                const element = defaultHandlers.listItem(h, node, parent);

                if (parent?.type !== 'list' || parent.ordered === true) {
                    return element;
                }

                if (element.children.length > 0) {
                    const firstChild = element.children[0];

                    if (firstChild.type === 'text') {
                        const match = firstChild.value.match(listItemTextStartsWithEmojiPattern);

                        if (!!match) {
                            element.properties['data-emoji'] = match[0];
                            firstChild.value = firstChild.value
                                .replace(listItemTextStartsWithEmojiPattern, '')
                                .replace(/^\s+/, '');
                        }
                    }
                }

                return element;
            }
        },
        hastSchema: {
            attributes: {
                li: ['data-emoji']
            }
        }
    };
}
