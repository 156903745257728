import React from 'react';
import { makeJobFormFieldAnchorId } from './JobFormFieldAnchor.utils';

export type JobFormFieldAnchorProps = {
    name: string;
};

function JobFormFieldAnchor({ name }: JobFormFieldAnchorProps) {
    return <span id={makeJobFormFieldAnchorId(name)} />;
}

export default JobFormFieldAnchor;
