import { Modifier } from '@popperjs/core';

export const sameWidth: Modifier<'sameWidth', {}> = {
    name: 'sameWidth',
    enabled: true,
    phase: 'beforeWrite',
    requires: ['computeStyles'],
    fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
    }
};
