import { createSelector } from 'reselect';

import { State as RootState } from '../../store/reducer';
import { CREATE_STATUS } from '../constants';
import { CreateStatus } from '../types';

import { getCheckoutProcessState } from '../entities/selectors';

import { getAdvertismentCreateStatus } from '../packages/selectors';

import { PROCESS_STATUS } from './contstants';

import { MOUNT, State, initialCreateStatus } from './reducer';
import { CheckoutProcessStateId, ProcessStatus } from './types';
import { isCheckoutProcessStateAdvertisePackage } from './utils';

function getRoot(state: RootState): State {
    return state[MOUNT];
}

const getCreateStatuses = createSelector(getRoot, (state: State) => {
    return state.createStatus;
});

function getCreateStatus(state: RootState, checkoutProcessStateId: CheckoutProcessStateId) {
    return getCreateStatuses(state)[checkoutProcessStateId] || initialCreateStatus;
}

const CREATE_STATUS_TO_PROCESS_STATUS = {
    [CREATE_STATUS.NONE]: PROCESS_STATUS.NONE,
    [CREATE_STATUS.CREATING]: PROCESS_STATUS.PROCESSING,
    [CREATE_STATUS.CREATED]: PROCESS_STATUS.PROCESSED,
    [CREATE_STATUS.FAILED]: PROCESS_STATUS.FAILED
};

export function getCheckoutProcessStateProcessStatus(
    state: RootState,
    checkoutProcessStateId: CheckoutProcessStateId
): ProcessStatus {
    const checkoutProcessState = getCheckoutProcessState(state, checkoutProcessStateId);

    if (!checkoutProcessState) {
        return PROCESS_STATUS.NONE;
    }

    let createStatus: CreateStatus = CREATE_STATUS.NONE;
    if (isCheckoutProcessStateAdvertisePackage(checkoutProcessState)) {
        const packageId = checkoutProcessState.data.packageToUse;
        createStatus = getAdvertismentCreateStatus(state, packageId);
    } else {
        createStatus = getCreateStatus(state, checkoutProcessStateId);
    }

    return CREATE_STATUS_TO_PROCESS_STATUS[createStatus];
}
