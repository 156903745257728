import { combineReducers } from 'redux';

import { omit } from '../../../utils/object';

import resetOnLogout from '../../authentication/reducer/resetOnLogout';
import {
    TEMPLATES_FETCH,
    TEMPLATE_FETCH,
    TEMPLATE_CREATE,
    TEMPLATE_UPDATE,
    TEMPLATE_DELETE
} from '../../templates/actions';

import { UPDATE_STATUS, DELETE_STATUS, FETCH_STATUS } from '../../constants';

import {
    EntityTypeEntitesState,
    EntityTypeFetchStatusState,
    EntityTypeUpdateStatusState,
    EntityTypeDeleteStatusState,
    EntityTypeStateEntitiesPartial,
    EntityTypeStateFetchStatusPartial,
    EntityTypeStateUpdateStatusPartial,
    EntityTypeStateDeleteStatusPartial
} from './';
import { Action } from '../../types';
import { Template } from '../../templates/types';

export type TemplateEntityTypeState = EntityTypeStateEntitiesPartial<Template> &
    EntityTypeStateFetchStatusPartial &
    EntityTypeStateUpdateStatusPartial &
    EntityTypeStateDeleteStatusPartial;

export const entitiesReducer = (
    state: EntityTypeEntitesState<Template> = {},
    action: Action
): EntityTypeEntitesState<Template> => {
    switch (action.type) {
        case TEMPLATES_FETCH.SUCCESS:
            const templates: Template[] = action.payload.data.results;

            return templates.reduce((state, template) => {
                return {
                    ...state,
                    [template.id]: template
                };
            }, state);

        case TEMPLATE_FETCH.SUCCESS:
        case TEMPLATE_CREATE.SUCCESS:
        case TEMPLATE_UPDATE.SUCCESS:
            const template: Template = action.payload.data;

            return {
                ...state,
                [template.id]: template
            };

        case TEMPLATE_DELETE.SUCCESS:
            const templateId = action.payload.templateId;
            return omit(state, '' + templateId);

        default:
            return state;
    }
};

export const fetchStatusReducer = (
    state: EntityTypeFetchStatusState = {},
    action: Action
): EntityTypeFetchStatusState => {
    let templateId;

    switch (action.type) {
        case TEMPLATE_FETCH.REQUEST:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: FETCH_STATUS.LOADING
            };

        case TEMPLATE_FETCH.SUCCESS:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: FETCH_STATUS.LOADED
            };

        case TEMPLATE_FETCH.FAILURE:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: FETCH_STATUS.FAILED
            };

        case TEMPLATE_DELETE.SUCCESS:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: FETCH_STATUS.NONE
            };

        default:
            return state;
    }
};

export const updateStatusReducer = (
    state: EntityTypeUpdateStatusState = {},
    action: Action
): EntityTypeUpdateStatusState => {
    let templateId;

    switch (action.type) {
        case TEMPLATE_UPDATE.REQUEST:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: UPDATE_STATUS.UPDATING
            };

        case TEMPLATE_UPDATE.SUCCESS:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: UPDATE_STATUS.UPDATED
            };

        case TEMPLATE_UPDATE.FAILURE:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: UPDATE_STATUS.FAILED
            };

        case TEMPLATE_DELETE.SUCCESS:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: UPDATE_STATUS.NONE
            };

        default:
            return state;
    }
};

export const deleteStatusReducer = (
    state: EntityTypeDeleteStatusState = {},
    action: Action
): EntityTypeDeleteStatusState => {
    let templateId;

    switch (action.type) {
        case TEMPLATE_DELETE.REQUEST:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: DELETE_STATUS.DELETING
            };

        case TEMPLATE_DELETE.SUCCESS:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: DELETE_STATUS.DELETED
            };

        case TEMPLATE_DELETE.FAILURE:
            templateId = action.payload.templateId;

            return {
                ...state,
                [templateId]: DELETE_STATUS.FAILED
            };

        default:
            return state;
    }
};

const templatesReducer = combineReducers<TemplateEntityTypeState>({
    entities: entitiesReducer,
    fetchStatus: fetchStatusReducer,
    updateStatus: updateStatusReducer,
    deleteStatus: deleteStatusReducer
});

export default resetOnLogout(templatesReducer);
