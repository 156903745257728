import * as React from 'react';
import classNames from 'classnames';

import './style.scss';

type Props = {
    className?: string;
    children: React.ReactNode;
};

const ContentFrame = (props: Props) => {
    return <div className={classNames('ContentFrame', props.className)}>{props.children}</div>;
};

export default ContentFrame;
