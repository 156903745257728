import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { animated } from 'react-spring/renderprops';

import './TutorialDesktopContent.style.scss';

import { ANIMATION_STEP_INIT, ANIMATION_STEP_NEXT_STEP, AnimationStep } from '../../constants';

import { getPropertyOrFallback } from '../../../../utils/object';

import DecorationLine from '../../../../components/DecorationLine';
import StepsDots from '../../../../components/StepsDots';
import Button from '../../../../components/Button';

import TutorialDesktopContentText from './TutorialDesktopContentText';
import TutorialDesktopContentGtc from './TutorialDesktopContentGtc';

import ToggleAnimation from '../../../../components/ToggleAnimation';

import { emptyTransition, AnimationKeyframes, AnimationLeaveKeyframes } from './animations';

type Props = {
    open: boolean;
    step: number;
    stepsCount: number;
    animationState: AnimationStep;
    goingRight: boolean;
    showGtc: boolean;
    acceptedConditions: boolean;
    acceptGtc: () => void;
    skipTutorial: () => void;
    goToStep: (arg1: number) => void;
    toggleConditions: () => void;
};

const TutorialDesktopContent = ({
    open,
    step,
    stepsCount,
    animationState,
    goingRight,
    showGtc,
    acceptedConditions,
    acceptGtc,
    skipTutorial,
    goToStep,
    toggleConditions
}: Props) => (
    <ToggleAnimation
        native
        open={open}
        from={emptyTransition}
        enter={emptyTransition}
        leave={
            step === 0
                ? AnimationLeaveKeyframes[ANIMATION_STEP_INIT]
                : AnimationLeaveKeyframes[ANIMATION_STEP_NEXT_STEP]
        }
    >
        {(leaveProps: any) => (
            <AnimationKeyframes native state={animationState}>
                {({ wrapperTranslate, childTranslate, ...styleProps }) => (
                    <animated.div
                        className="TutorialDesktopContentAnimationWrapper"
                        style={{
                            ...styleProps,
                            opacity: getPropertyOrFallback(leaveProps.opacity, styleProps.opacity),
                            transform: getPropertyOrFallback(leaveProps.wrapperTranslate, wrapperTranslate).interpolate(
                                (x, y, selfX, selfY) =>
                                    `translate(
                                            calc(${x}% + ${selfX}%),
                                            calc(${y}% + ${selfY}%)
                                        )`
                            )
                        }}
                    >
                        <animated.div
                            className="TutorialDesktopContent"
                            style={{
                                transform: getPropertyOrFallback(leaveProps.childTranslate, childTranslate).interpolate(
                                    (x, y, selfX, selfY) =>
                                        `translate(
                                            calc(${x}px - ${selfX}%),
                                            calc(${y}px - ${selfY}%)
                                        )`
                                )
                            }}
                        >
                            {step === 0 && (
                                <DecorationLine
                                    className="TutorialDesktopContent__decoration-line"
                                    width={60}
                                    height={8}
                                />
                            )}

                            {step > 0 && <StepsDots stepsCount={stepsCount} activeStep={step} onStepClick={goToStep} />}

                            <div className="TutorialDesktopContent__text">
                                <TutorialDesktopContentText open={open} step={step} goingRight={goingRight} />
                            </div>

                            {showGtc && step === 0 && (
                                <TutorialDesktopContentGtc
                                    step={step}
                                    acceptedConditions={acceptedConditions}
                                    toggleConditions={toggleConditions}
                                />
                            )}

                            <div className="TutorialDesktopContent__actions">
                                {step === 0 && (
                                    <Button
                                        data-testid="skip-tutorial-button"
                                        disabled={!acceptedConditions}
                                        typeStyle="flat"
                                        variationStyle="normal"
                                        onClick={() => {
                                            acceptGtc();
                                            skipTutorial();
                                        }}
                                    >
                                        <FormattedMessage id={`TUTORIAL.STEP_${step}.SKIP_TUTORIAL`} />
                                    </Button>
                                )}

                                <Button
                                    data-testid="next-step-button"
                                    key={`button-step-${step}`}
                                    disabled={!acceptedConditions}
                                    typeStyle="raised"
                                    variationStyle="brand"
                                    onClick={() => {
                                        if (step === 0) {
                                            acceptGtc();
                                        }
                                        goToStep(step + 1);
                                    }}
                                >
                                    <FormattedMessage
                                        id={
                                            step === 0
                                                ? `TUTORIAL.STEP_${step}.START_TUTORIAL`
                                                : `TUTORIAL.STEP_${step}.BUTTON_LABEL`
                                        }
                                    />
                                </Button>
                            </div>
                        </animated.div>
                    </animated.div>
                )}
            </AnimationKeyframes>
        )}
    </ToggleAnimation>
);

export default TutorialDesktopContent;
