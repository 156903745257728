import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Job } from '../../modules/jobs/types';
import { Recruiter } from '../../modules/recruiters/types';
import { getAdvertisementFromCheckoutProcessState, isAllowedToOrder } from '../../modules/checkout/utils';
import { CheckoutProcessState } from '../../modules/checkout/types';
import { CheckoutProcessStep } from '../../routes';
import CheckoutInfoBar from '../CheckoutInfoBar';
import CheckoutProgressBar from '../CheckoutProgressBar';
import Headline from '../Headline';
import HeadlineSubtitle from '../Headline/HeadlineSubtitle';
import HeadlineTitle from '../Headline/HeadlineTitle';

import classes from './CheckoutHeader.module.scss';

export type CheckoutHeaderProps = {
    currentStep: CheckoutProcessStep;
    recruiter: Recruiter | null;
    checkoutProcessState: CheckoutProcessState;
    job?: Job | null;
    headline?: boolean;
    progressBar?: boolean;
};

function CheckoutHeader({
    currentStep,
    recruiter,
    checkoutProcessState,
    job,
    headline = true,
    progressBar = true
}: CheckoutHeaderProps) {
    const recruiterIsAllowedToOrder = isAllowedToOrder(recruiter);

    const advertisement = !!checkoutProcessState
        ? getAdvertisementFromCheckoutProcessState(checkoutProcessState)
        : null;
    const advertisementAction = !!advertisement ? advertisement.action : null;

    let infoBarElement: React.ReactNode = null;
    if (recruiterIsAllowedToOrder && !!advertisementAction && !!job) {
        infoBarElement = <CheckoutInfoBar advertisementAction={advertisementAction} job={job} />;
    }

    let progressBarElement: React.ReactNode = null;
    if (progressBar && recruiterIsAllowedToOrder) {
        progressBarElement = <CheckoutProgressBar currentStep={currentStep} />;
    }

    let title: React.ReactNode = null;
    let subtitle: React.ReactNode = null;

    if (headline) {
        if (recruiterIsAllowedToOrder) {
            title = <FormattedMessage id={`CHECKOUT_HEADER.TITLE.${currentStep}`} />;
        } else {
            title = <FormattedMessage id="CHECKOUT_HEADER.ALLOWED_TO_ORDER.TITLE" />;
            subtitle = <FormattedMessage id="CHECKOUT_HEADER.ALLOWED_TO_ORDER.SUBTITLE" />;
        }
    }

    return (
        <div
            className={classNames(classes.root, {
                [classes.hasInfobar]: recruiterIsAllowedToOrder && !!job
            })}
        >
            {infoBarElement}

            <div className="container">
                {progressBarElement}

                {!!title && (
                    <Headline center>
                        <HeadlineTitle>{title}</HeadlineTitle>

                        {!!subtitle && <HeadlineSubtitle>{subtitle}</HeadlineSubtitle>}
                    </Headline>
                )}
            </div>
        </div>
    );
}

export default CheckoutHeader;
