import React from 'react';
import { FormattedMessage } from 'react-intl';
import JobFormExperiencesFormFields, { JobFormExperiencesFormFieldsValue } from './JobFormExperiencesFormFields';
import JobFormWorktypesFormFields, { JobFormWorktypesFormFieldsValue } from './JobFormWorktypesFormFields';
import JobFormCareerStatutesFormFields, {
    JobFormCareerStatutesFormFieldsValue
} from './JobFormCareerStatutesFormFields';
import JobFormAvailabilityFormFields, { JobFormAvailabilityFormFieldsValue } from './JobFormAvailabilityFormFields';
import JobFormHiringBudgetFormFields, { JobFormHiringBudgetFormFieldsValue } from './JobFormHiringBudgetFormFields';

export type JobFormWorkConditionsFormFieldsetValues = {
    experiences: JobFormExperiencesFormFieldsValue;
    worktypes: JobFormWorktypesFormFieldsValue;
    career_statutes: JobFormCareerStatutesFormFieldsValue;
    start_date: JobFormAvailabilityFormFieldsValue;
    hiring_budget: JobFormHiringBudgetFormFieldsValue;
};

export type JobFormWorkConditionsFormFieldsetProps = {};

function JobFormWorkConditionsFormFieldset({}: JobFormWorkConditionsFormFieldsetProps) {
    return (
        <fieldset className="panel">
            <div className="panel-heading">
                <h2 className="panel-title h2">
                    <FormattedMessage id="JOB_EDIT.WORK_CONDITIONS_FORM_FIELDSET.TITLE" />
                </h2>
            </div>
            <div className="panel-body">
                <JobFormExperiencesFormFields />
                <JobFormWorktypesFormFields />
                <JobFormCareerStatutesFormFields />
                <JobFormAvailabilityFormFields />
                <JobFormHiringBudgetFormFields />
            </div>
        </fieldset>
    );
}

export default JobFormWorkConditionsFormFieldset;
