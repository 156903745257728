import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getFormValues } from 'redux-form';

import SignupForm from '../../SignupForm';
import {
    FORM_NAME,
    EMAIL_TAKEN_DECISION_REQUEST_TOKEN,
    FormValues as SignupFormValues,
    EmailTakenDecision
} from '../../SignupForm/constants';
import { getEmailTakenDecision } from '../../SignupForm/selectors';
import { resetEmailTakenDecision } from '../../SignupForm/actions';

import SignupRequestTokenSent from '../../../components/SignupRequestTokenSent';
import SignupSuccess from '../../../components/SignupSuccess';

import { RequestStatus } from '../../../modules/types';
import { getRecruiter } from '../../../modules/recruiters/selectors';
import { isSignedUp } from '../../../modules/recruiters/utils';
import { Recruiter } from '../../../modules/recruiters/types';
import { signup, resetSignupRequest, resetRequestTokenRequest } from '../../../modules/authentication/actions';
import {
    StatusState as SignupStatus,
    ResultState as SignupResult
} from '../../../modules/authentication/reducer/signup';
import {
    getSignupStatus,
    getSignupResult,
    getRequestTokenRequestStatus
} from '../../../modules/authentication/selectors';
import { isRequestTokenRequestSucceed } from '../../../modules/authentication/utils';

import { parseSearch } from '../../../utils/url';

import { State as ApplicationState } from '../../../store/reducer';

import './SignupPageContent.style.scss';

type SignupPageContentProps = {};

type SignupPageContentWithRouterProps = SignupPageContentProps & RouteComponentProps;

type ConnectedStateProps = {
    recruiter: Recruiter | null;
    signupStatus: SignupStatus;
    signupResult: SignupResult;
    signupFormValues: SignupFormValues;
    emailTakenDecision: EmailTakenDecision;
    requestTokenRequestStatus: RequestStatus;
};

type ConnectedDispatchProps = {
    signup: typeof signup;
    resetSignupRequest: typeof resetSignupRequest;
    resetEmailTakenDecision: typeof resetEmailTakenDecision;
    resetRequestTokenRequest: typeof resetRequestTokenRequest;
};

type SignupPageWithStoreProps = SignupPageContentWithRouterProps & ConnectedStateProps & ConnectedDispatchProps;

type SignupPageAllProps = SignupPageWithStoreProps;

type State = {
    recruiter: Recruiter | null;
    submittedFormValues: SignupFormValues | null;
};

class SignupPageContent extends React.Component<SignupPageAllProps, State> {
    state: State = {
        recruiter: null,
        submittedFormValues: null
    };

    componentWillUnmount() {
        this.props.resetSignupRequest();
        this.props.resetRequestTokenRequest();
        this.props.resetEmailTakenDecision();
    }

    componentDidMount() {
        // We store the recruiter to that we have it still available at a later point
        // when the user will be logged out – e.g. successful signup
        this.setState({
            recruiter: this.props.recruiter
        });
    }

    componentDidUpdate(prevProps: SignupPageAllProps) {
        if (prevProps.signupStatus !== 'signingUp' && this.props.signupStatus === 'signingUp') {
            this.setState({
                submittedFormValues: this.props.signupFormValues
            });
        }
    }

    render() {
        const { signupStatus, signupResult, emailTakenDecision, requestTokenRequestStatus, location } = this.props;
        const { recruiter, submittedFormValues } = this.state;

        const submittedEmail = (!!submittedFormValues && submittedFormValues.email) || null;

        let recruiterAndNotSignedUp = false;
        let sameEmailAddress = false;

        if (!!recruiter) {
            recruiterAndNotSignedUp = !isSignedUp(recruiter);

            if (!!submittedEmail) {
                sameEmailAddress = recruiter.email === submittedEmail;
            }
        }

        let destinationUrl;
        if (!!location) {
            destinationUrl = parseSearch(location.search)['destination-url'];
        }

        const showSignupRequestTokenSent =
            emailTakenDecision === EMAIL_TAKEN_DECISION_REQUEST_TOKEN &&
            isRequestTokenRequestSucceed(requestTokenRequestStatus);

        if (signupStatus === 'signedUp') {
            const trialEndDate = (!!signupResult && signupResult.trial_end) || null;

            return (
                <div className="SignupPageContent__content">
                    <SignupSuccess
                        trialEndDate={trialEndDate}
                        destinationUrl={destinationUrl}
                        recruiterAndNotSignedUp={recruiterAndNotSignedUp}
                        sameEmailAddress={sameEmailAddress}
                    />
                </div>
            );
        } else if (signupStatus === 'failed' && showSignupRequestTokenSent) {
            return (
                <div className="SignupPageContent__content">
                    <SignupRequestTokenSent destinationUrl={destinationUrl} />
                </div>
            );
        } else {
            return (
                <div className="SignupPageContent__content">
                    <SignupForm />
                </div>
            );
        }
    }
}

const getSignupFormValues = getFormValues(FORM_NAME);

const mapStateToProps = (state: ApplicationState): ConnectedStateProps => ({
    recruiter: getRecruiter(state),
    signupStatus: getSignupStatus(state),
    signupResult: getSignupResult(state),
    signupFormValues: getSignupFormValues(state) as SignupFormValues,
    emailTakenDecision: getEmailTakenDecision(state),
    requestTokenRequestStatus: getRequestTokenRequestStatus(state)
});

const mapDispatchToProps: ConnectedDispatchProps = {
    signup,
    resetSignupRequest,
    resetEmailTakenDecision,
    resetRequestTokenRequest
};

const withStore = connect<
    ConnectedStateProps,
    ConnectedDispatchProps,
    SignupPageContentWithRouterProps,
    ApplicationState
>(mapStateToProps, mapDispatchToProps);

export default withRouter(withStore(SignupPageContent));
