import React from 'react';
import { getJobsRoute } from '../../../routes';
import LoadingPage from '../../LoadingPage';

export type AutomaticRequestTokenAuthenticatedRequestPageProps = {
    destinationUrl?: string;
    redirect: (path: string) => void;
};

export function AutomaticRequestTokenAuthenticatedRequestPage({
    destinationUrl = getJobsRoute(),
    redirect
}: AutomaticRequestTokenAuthenticatedRequestPageProps) {
    React.useEffect(() => {
        redirect(destinationUrl);
    }, []);

    return <LoadingPage />;
}
