import { createSelector } from 'reselect';

import { getUIState } from '../../../modules/ui/selectors';

const getTutorialState = createSelector(getUIState, (uiState: ReturnType<typeof getUIState>) => uiState.tutorial);

export const getTutorialOpen = createSelector(getTutorialState, (tutorialState) => tutorialState.open);

export const getCompleteTutorialUpdateStatus = createSelector(
    getTutorialState,
    (tutorialState) => tutorialState.completeUpdateStatus
);
