import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { getDaysLeft } from '../../../utils/date';

import './SignupSuccessTrialCountdown.style.scss';

export type SignupSuccessTrialCountdownProps = {
    trialEndDate: string;
    children: React.ReactNode;
};

function SignupSuccessTrialCountdown({ trialEndDate, children }: SignupSuccessTrialCountdownProps) {
    const daysLeft = getDaysLeft(trialEndDate, Date.now());

    return (
        <div className="SignupSuccessTrialCountdown">
            <div className="SignupSuccessTrialCountdown__icon-container">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    className="SignupSuccessTrialCountdown__icon"
                >
                    <path
                        fillRule="evenodd"
                        d="M12,2 C6.4775,2 2,6.4775 2,12 C2,17.5225 6.4775,22 12,22 C17.5225,22 22,17.5225 22,12 C21.9941667,6.48 17.52,2.00583333 12,2 Z M16.3333333,16.3508333 C16.0191667,16.6833333 15.4975,16.7058333 15.1558333,16.4025 L11.0233333,12.6141667 C10.85,12.4566667 10.7508333,12.2341667 10.75,12 L10.75,7.41666667 C10.75,6.95666667 11.1233333,6.58333333 11.5833333,6.58333333 C12.0433333,6.58333333 12.4166667,6.95666667 12.4166667,7.41666667 L12.4166667,11.6333333 L16.2783333,15.1733333 C16.6191667,15.4833333 16.6441667,16.01 16.3341667,16.3508333 C16.3341667,16.3508333 16.3333333,16.3516667 16.3333333,16.3508333 L16.3333333,16.3508333 Z"
                    />
                </svg>
            </div>

            <div className="SignupSuccessTrialCountdown__title">
                <FormattedMessage
                    id="SIGNUP_SUCCESS_TRIAL_COUNTDOWN.COUNTDOWN"
                    values={{
                        days: daysLeft
                    }}
                />
            </div>
            <p className="SignupSuccessTrialCountdown__content">{children}</p>
        </div>
    );
}

export default SignupSuccessTrialCountdown;
