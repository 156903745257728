import apiClientV4 from '../../services/api/v4';
import { Coordinates } from './types';
import { convertCoordinatesToGeoJSON } from './utils';

type Params = {
    containsCoordinates?: Coordinates;
};

type ApiParams = {
    polygon_contains?: string;
};

export function fetchJobRegions(params: Params = {}) {
    const apiParams: ApiParams = {};

    if (!!params.containsCoordinates) {
        apiParams.polygon_contains = convertCoordinatesToGeoJSON(params.containsCoordinates);
    }

    return apiClientV4.get('/job-regions/', {
        params: apiParams
    });
}
