import React, { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import debounce from 'just-debounce-it';

import { getCandidateRoute, getJobRoute } from '../../routes';

import { FetchStatus } from '../../modules/types';

import { SearchResult } from '../../modules/search/types';
import { SEARCH_RESULT_TYPE_CANDIDATE, SEARCH_RESULT_TYPE_JOB } from '../../modules/search/constants';
import {
    getSearchResults,
    getSearchResultsPaginationNextPage,
    getSearchFetchStatus,
    getSearchResultsPaginationCount
} from '../../modules/search/selectors';
import { resetSearch, fetchSearchResults } from '../../modules/search/actions';

import SearchBarForm from './SearchBarForm';
import SearchBarResultsList from './SearchBarResultsList';
import SearchBarCloseButton from './SearchBarCloseButton';
import SearchBarLoadMoreOption from './SearchBarLoadMoreOption';
import SearchBarLoadingState from './SearchBarLoadingState';
import SearchBarEmptyState from './SearchBarEmptyState';

import { useSearchBar } from './SearchBar.hooks';

import './SearchBar.style.scss';

const initialValues = {
    query: '',
    type: SEARCH_RESULT_TYPE_CANDIDATE
};

type Props = {
    searchResults: SearchResult[];
    searchResultsFetchStatus: FetchStatus;
    searchResultsCount: number;
    nextSearchResultsPage: number | null;

    resetSearch: typeof resetSearch;
    fetchSearchResults: typeof fetchSearchResults;
};

function SearchBar({
    searchResults,
    searchResultsFetchStatus,
    searchResultsCount,
    nextSearchResultsPage,

    resetSearch,
    fetchSearchResults
}: Props) {
    const history = useHistory();

    const isLoading = searchResultsFetchStatus === 'loading';
    const isLoaded = searchResultsFetchStatus === 'loaded';
    const hasItems = searchResults.length > 0;
    const hasMoreItems = searchResultsCount > searchResults.length;

    const fetchSearchResultsDebounced = useMemo(() => {
        return debounce(fetchSearchResults, 350);
    }, [fetchSearchResults]);

    const onLoadItems = (values) => {
        if (values.query === '') {
            resetSearch();
            return;
        }

        fetchSearchResultsDebounced({
            type: values.type,
            query: values.query
        });
    };

    const onLoadMoreItems = (values) => {
        if (nextSearchResultsPage === null) {
            return;
        }

        fetchSearchResults({
            type: values.type,
            query: values.query,
            page: nextSearchResultsPage,
            append: true
        });
    };

    const [nextRoutePush, setNextRoutePush] = useState<string | null>(null);
    const onSelectItem = (selectedItem: SearchResult) => {
        if (selectedItem.type === SEARCH_RESULT_TYPE_CANDIDATE) {
            const candidate = selectedItem.data;

            const candidateRoute = getCandidateRoute(candidate.job.id, candidate.id);

            setNextRoutePush(candidateRoute);
        } else if (selectedItem.type === SEARCH_RESULT_TYPE_JOB) {
            const job = selectedItem.data;

            const jobRoute = getJobRoute(job.id);

            setNextRoutePush(jobRoute);
        }
    };

    // Workaround for https://github.com/downshift-js/downshift/issues/874
    useEffect(() => {
        if (!nextRoutePush) {
            return;
        }

        history.push(nextRoutePush);
        setNextRoutePush(null);
    }, [nextRoutePush]);

    const onReset = () => {
        resetSearch();
    };

    const {
        values,
        isOpen,
        highlightedIndex,

        getComboboxProps,
        getQueryLabelProps,
        getQueryInputProps,
        getTypeLabelProps,
        getTypeInputProps,
        getMenuProps,
        getItemProps,
        getLoadMoreActionProps,
        getBackdropProps,

        openMenu,
        closeMenu
    } = useSearchBar({
        initialValues,

        items: searchResults,
        count: searchResultsCount,

        onLoadItems,
        onLoadMoreItems,
        onSelectItem,
        onReset
    });

    const shouldShowResults = isOpen;
    const shouldShowResultsList = hasItems && (values.query !== '' || isLoading);
    const shouldShowLoadingIndicator = values.query !== '' && isLoading;
    const shouldShowLoadingIndicatorAsOverlay = searchResults.length > 0;
    const shouldShowEmptyState = isLoaded && searchResults.length === 0;
    const shouldShowLoadMoreAction = shouldShowResultsList && hasMoreItems;

    const computedClassNames = classNames('SearchBar', {
        'SearchBar--expanded': isOpen
    });

    return (
        <div className={computedClassNames} {...getComboboxProps()}>
            <div className="SearchBar__container">
                <SearchBarForm
                    getQueryLabelProps={getQueryLabelProps}
                    getQueryInputProps={getQueryInputProps}
                    getTypeLabelProps={getTypeLabelProps}
                    getTypeInputProps={getTypeInputProps}
                    isOpen={isOpen}
                    openMenu={openMenu}
                />

                <SearchBarCloseButton closeMenu={closeMenu} className="SearchBar__close-button" />

                <div
                    {...getMenuProps({
                        className: 'SearchBar__results'
                    })}
                >
                    {shouldShowResults && (
                        <div className="SearchBar__results-scrollable">
                            {shouldShowLoadingIndicator && (
                                <SearchBarLoadingState asOverlay={shouldShowLoadingIndicatorAsOverlay} />
                            )}

                            {shouldShowEmptyState && <SearchBarEmptyState />}

                            {shouldShowResultsList && (
                                <div className="SearchBar__results-list">
                                    <SearchBarResultsList
                                        items={searchResults}
                                        highlightedIndex={highlightedIndex}
                                        getItemProps={getItemProps}
                                        search={values.query}
                                    />
                                </div>
                            )}

                            {shouldShowLoadMoreAction && (
                                <SearchBarLoadMoreOption
                                    highlight={highlightedIndex === searchResults.length}
                                    {...getLoadMoreActionProps()}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>

            <div
                {...getBackdropProps({
                    className: 'SearchBar__backdrop'
                })}
            ></div>
        </div>
    );
}

export { SearchBar };

const mapStateToProps = (state) => {
    const searchResults = getSearchResults(state);
    const searchResultsFetchStatus = getSearchFetchStatus(state);
    const searchResultsCount = getSearchResultsPaginationCount(state);
    const nextSearchResultsPage = getSearchResultsPaginationNextPage(state);

    return {
        searchResults,
        searchResultsFetchStatus,
        searchResultsCount,
        nextSearchResultsPage
    };
};

const mapDispatchToProps = {
    resetSearch,
    fetchSearchResults
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
