import { Job } from '../../jobs/types';
import { isDraft, isActive, isArchived } from '../../jobs/utils';

import {
    CheckoutProcessState,
    CheckoutProcessState$Advertise$Product,
    CheckoutProcessState$Advertise$Package,
    CheckoutProcessStateMethod,
    Advertisement,
    AdvertisementAction,
    CheckoutProcessState$BulkUp
} from '../types';

export function getAdvertisementFromCheckoutProcessState(
    checkoutProcessState: CheckoutProcessState
): Advertisement | null {
    if (checkoutProcessState.method === 'advertise') {
        return checkoutProcessState.data.advertisement;
    }

    return null;
}

export function createCheckoutId() {
    return Math.random().toString(36).substring(7);
}

export function determinateAdvertisementAction(job: Job): AdvertisementAction {
    if (isDraft(job)) {
        return 'start';
    } else if (isActive(job)) {
        return 'prolong';
    } else if (isArchived(job)) {
        return 'restart';
    }

    throw new Error(`Can\'t determinate state by given job ([state: ${job.state}])`);
}

export function createAdvertisement(job: Job): Advertisement {
    const advertisement: Partial<Advertisement> = {
        action: determinateAdvertisementAction(job),
        job: job.id
    };

    return advertisement as Advertisement;
}

export function createCheckoutProcessStateSekeleton(
    method: CheckoutProcessStateMethod /*, options */
): Partial<CheckoutProcessState> {
    const checkoutProcessStateSekeleton: Object = {
        id: createCheckoutId(),
        method,
        data: {}
    };

    return checkoutProcessStateSekeleton;
}

export function isCheckoutProcessStateBulkUp(
    checkoutProcessState: CheckoutProcessState
): checkoutProcessState is CheckoutProcessState$BulkUp {
    return checkoutProcessState.method === 'bulk-up';
}

export function isCheckoutProcessStateAdvertise(
    checkoutProcessState: CheckoutProcessState
): checkoutProcessState is CheckoutProcessState$Advertise$Product | CheckoutProcessState$Advertise$Package {
    return checkoutProcessState.method === 'advertise';
}

export function isCheckoutProcessStateAdvertiseProduct(
    checkoutProcessState: CheckoutProcessState
): checkoutProcessState is CheckoutProcessState$Advertise$Product {
    return (
        checkoutProcessState.method === 'advertise' &&
        'product' in checkoutProcessState.data &&
        !!checkoutProcessState.data.product
    );
}

export function isCheckoutProcessStateAdvertisePackage(
    checkoutProcessState: CheckoutProcessState
): checkoutProcessState is CheckoutProcessState$Advertise$Package {
    return (
        checkoutProcessState.method === 'advertise' &&
        'packageToUse' in checkoutProcessState.data &&
        !!checkoutProcessState.data.packageToUse
    );
}
