import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';
import { getLinkHref, getLinkLabelMessageId } from './utils';

import './style.scss';

type Props = {
    listName: string;
    jobId: number;
};

const CandidateListPageFooter = (props: Props) => {
    const linkHref = getLinkHref(props.listName, props.jobId);
    const linkLabelMessageId = getLinkLabelMessageId(props.listName);

    return (
        <div className="CandidateListPageFooter">
            <Button component={Link} to={linkHref} typeStyle="raised" variationStyle="normal">
                <FormattedMessage id={linkLabelMessageId} />
            </Button>
        </div>
    );
};

export default CandidateListPageFooter;
