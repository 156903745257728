import createTransform from 'props-transform';
import { custom as diff } from 'object-diff';
import { omit, omitNullValues, toEntries } from './object';

type Ordering = null | string;
type Page = number;

export type Params = {
    ordering: Ordering;
    page: Page;
    [name: string]: any;
};

export type FilterValues = {
    [name: string]: string[];
};

type OrderingField = string;
type OrderingFields = OrderingField[];

type OrderingValue = 'asc' | 'desc';
export type OrderingValues = {
    [name: string]: null | OrderingValue;
};

/*
 * Transforms query params and converts converts the property values to the correct type.
 */
export const transformQueryParams: (queryParams: object) => object = createTransform().add('page', (page) =>
    parseInt(page, 10)
);

function equalWithArray(a: unknown, b: unknown) {
    if (a instanceof Array && b instanceof Array) {
        const arrA: any[] = a;
        const arrB: any[] = b;

        if (a.length === 0 && b.length === 0) {
            return true;
        }

        // Check if the items in the arrays are the same
        return (
            a.length === b.length &&
            arrA.every((v: any) => {
                return arrB.indexOf(v);
            })
        );
    }

    return a === b;
}

export const diffParams = diff.bind(null, {
    equal: equalWithArray
});

/**
 * Create the query params based on the diff of the default parameters and the parameters.
 */
export function convertParamsToQueryParams(defaultParams: object, params: object): object {
    return diffParams(defaultParams, params || {});
}

export function convertOrderingsToOrdering(orderings: OrderingValues): Ordering {
    if (!orderings) {
        return null;
    }

    const entries = toEntries(omitNullValues(orderings));

    if (!!entries.length) {
        const entry = entries[0];
        return `${entry.key}-${entry.value}`;
    }

    return null;
}

export function convertFilterAndOrderingValuesToParams(
    filters: FilterValues,
    orderings: OrderingValues,
    page: number
): Params {
    return {
        ...filters,
        ordering: convertOrderingsToOrdering(orderings),
        page
    };
}

export function splitOrdering(ordering: Ordering): null | { field: string; value: OrderingValue } {
    if (!ordering) {
        return null;
    }

    const match = ordering.match(/(.+)-(asc|desc)$/);

    if (!match) {
        throw new TypeError('Value is not an ordering.');
    }

    const field = match[1] as string;
    const value = match[2] as OrderingValue;

    return {
        field,
        value
    };
}

export function convertOrderingToOrderings(orderingFields: OrderingFields, ordering: Ordering): OrderingValues {
    const splitted = splitOrdering(ordering);

    return orderingFields.reduce((orderings: OrderingValues, field: OrderingField) => {
        let value: OrderingValue | null = null;

        if (!!splitted && splitted.field === field) {
            value = splitted.value;
        }

        return {
            ...orderings,
            [field]: value
        };
    }, {});
}

export function convertParamsToFilterValues(params: Params): FilterValues {
    return omit(params, ['ordering', 'page']);
}

export function convertParamsToOrderingValues(orderingFields: OrderingFields, params: Params): OrderingValues {
    return convertOrderingToOrderings(orderingFields, params.ordering || null);
}
