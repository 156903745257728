import React from 'react';
import { FieldValidator } from 'final-form';
import { useFieldArray } from 'react-final-form-arrays';

import { createArrayMinLength } from '../../../../utils/form/validators';
import { onJobFormPageValidationLevel } from '../../../JobFormPage/JobFormPage.utils';

import JobFormTasksCreateFormField from './JobFormTasksCreateFormField';
import { JobFormTasksEditModeContextProvider } from './JobFormTasksEditModeContext';
import JobFormTasksListFormFields from './JobFormTasksListFormFields';

type JobFormTasksFormFieldValue = string;
export type JobFormTasksFormFieldsValue = JobFormTasksFormFieldValue[];
export type JobFormTasksFormFieldsName = 'tasks';
export const JOB_EDIT_TASKS_FORM_FIELDS_NAME = 'tasks';

const MIN_LENGTH = 1;

const minLengthArrayValidator = createArrayMinLength(MIN_LENGTH) as FieldValidator<JobFormTasksFormFieldsValue>;
const validate = onJobFormPageValidationLevel<JobFormTasksFormFieldsValue>('full', minLengthArrayValidator);

export type JobFormTasksFormFieldsProps = {};

function JobFormTasksFormFields({}: JobFormTasksFormFieldsProps) {
    useFieldArray<JobFormTasksFormFieldValue>(JOB_EDIT_TASKS_FORM_FIELDS_NAME, {
        validate
    });

    return (
        <JobFormTasksEditModeContextProvider>
            <div>
                <span id="form-job-field-tasks" />
                <JobFormTasksCreateFormField />
                <JobFormTasksListFormFields />
            </div>
        </JobFormTasksEditModeContextProvider>
    );
}

export default JobFormTasksFormFields;
