import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import './style.scss';

import { matchesBreakpoint, BREAKPOINT_MD } from '../../utils/viewport';

import { getCandidate } from '../../modules/entities/selectors';
import {
    getFileRequestByCandidateIdCreateStatus,
    getFileRequestByCandidateIdErrors
} from '../../modules/files/selectors';
import { requestFiles, resetFilesRequest } from '../../modules/files/actions';
import { FILE_CATEGORIES, FILE_CATEGORY_GENERATED_RESUME } from '../../modules/files/constants';

import {
    convertSelectedTilesToRequestedDocumentsWithoutOther,
    getIndexFromName,
    getNonEmptyOtherFields,
    getNewOtherInput,
    canSubmitRequest
} from './utils';
import { OTHER_FIELDS_ANIMATION_DURATION } from './constants';

import scrolled, { ScrolledProps } from '../../components/Scrolled';

import RequestDocumentsViewMobile from './RequestDocumentsViewMobile';
import RequestDocumentsViewDesktop from './RequestDocumentsViewDesktop';
import RequestDocumentsViewTiles from './RequestDocumentsViewTiles';
import RequestDocumentsViewOtherInputs from './RequestDocumentsViewOtherInputs';

import { FileCategory } from '../../modules/files/types';
import { Candidate } from '../../modules/candidates/types';
import { CreateStatus, Errors } from '../../modules/types';

type RequestedDocuments = {
    category: FileCategory;
    name?: string;
};

export type SelectedTiles = {
    resume: boolean;
    'graduation-certificate': boolean;
    certificate: boolean;
    'work-sample': boolean;
    'employment-reference': boolean;
    other: boolean;
};

type Props = ScrolledProps & {
    open: boolean;
    onClose: () => void;

    jobId: number;

    candidate: Candidate;
    candidateId: number;

    requestFiles: (jobId: number, candidateId: number, requestedFiles: RequestedDocuments) => void;
    resetFilesRequest: (candidateId: number) => void;

    fileRequestStatus: CreateStatus;
    fileRequestErrors: Errors;
};

type State = {
    isViewportMobile: boolean;
    selectedTiles: SelectedTiles;
    otherFieldValues: {
        id: string;
        value: string;
    }[];
};

const TILES_CATEGORIES = Object.keys(FILE_CATEGORIES)
    .map((key) => FILE_CATEGORIES[key])
    .filter((category) => category !== FILE_CATEGORY_GENERATED_RESUME);

const initialSelectedTiles = TILES_CATEGORIES.reduce((acc, category) => {
    acc[category] = false;
    return acc;
}, {});

class RequestDocumentsView extends React.Component<Props, State> {
    overlayPageContainer: HTMLElement | null = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            isViewportMobile: false,
            selectedTiles: initialSelectedTiles,
            otherFieldValues: [getNewOtherInput()]
        };

        this.handleAddOtherField = this.handleAddOtherField.bind(this);
        this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
        this.handleChangeOtherField = this.handleChangeOtherField.bind(this);
        this.handleRemoveOtherField = this.handleRemoveOtherField.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.makeScrollableRef = this.makeScrollableRef.bind(this);
        this.isSubmitButtonDisabled = this.isSubmitButtonDisabled.bind(this);
    }

    componentDidMount() {
        if (!matchesBreakpoint(BREAKPOINT_MD)) {
            this.setState({ isViewportMobile: true });
        } else {
            this.setState({ isViewportMobile: false });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.open && !!this.props.open) {
            if (!matchesBreakpoint(BREAKPOINT_MD)) {
                this.setState({ isViewportMobile: true });
            } else {
                this.setState({ isViewportMobile: false });
            }
        }

        if (!!prevProps.open && !this.props.open) {
            this.setState({
                selectedTiles: initialSelectedTiles,
                otherFieldValues: [getNewOtherInput()]
            });
        }

        // When an error occurs while requesting, we scroll to the top the OverlayPage,
        // because the error is shown up there
        if (!prevProps.fileRequestErrors && !!this.props.fileRequestErrors && !!this.overlayPageContainer) {
            this.overlayPageContainer.scrollTop = 0;
        }
    }

    componentWillUnmount() {
        const { onScroll } = this.props;

        if (!!onScroll && !!this.overlayPageContainer) {
            this.overlayPageContainer.removeEventListener('scroll', onScroll as any);
        }
    }

    handleChangeCheckbox(category: FileCategory, checked: boolean) {
        const currentSelectedTiles = this.state.selectedTiles;

        // On other check we need to setup scrollTop after animation to know if
        // we should initially display a top border in the actions bar.
        // Setting scrollTop on a non scrollable div does nothing, so we abuse that.
        if (category === FILE_CATEGORIES.OTHER && checked) {
            if (!!this.overlayPageContainer) {
                setTimeout(() => {
                    this.overlayPageContainer && (this.overlayPageContainer.scrollTop = 1);
                }, OTHER_FIELDS_ANIMATION_DURATION);
            }
        }

        // On other check we need to setup scrollTop after animation to know if
        // we should initially display a top border in the actions bar.
        // Setting scrollTop on a non scrollable div does nothing, so we abuse that.
        if (category === 'other' && checked) {
            if (!!this.overlayPageContainer) {
                setTimeout(() => {
                    this.overlayPageContainer && (this.overlayPageContainer.scrollTop = 1);
                }, OTHER_FIELDS_ANIMATION_DURATION);
            }
        }

        // We need to replace the current otherFieldValues with an array with one empty
        // empty string inside to reset all values and the count of the OTHER category fields
        // We need to set a timeout to let the animation finish before clearing the array
        if (category === FILE_CATEGORIES.OTHER && !checked) {
            setTimeout(() => {
                this.setState({
                    otherFieldValues: [getNewOtherInput()]
                });
            }, OTHER_FIELDS_ANIMATION_DURATION);
        }

        this.setState({
            selectedTiles: {
                ...currentSelectedTiles,
                [category]: checked
            }
        });
    }

    handleChangeOtherField(event) {
        const target = event.currentTarget;
        const index = getIndexFromName(target.name);
        const { otherFieldValues } = this.state;

        const updatedOtherFieldValues = [
            ...otherFieldValues.slice(0, index),
            getNewOtherInput({
                id: otherFieldValues[index].id,
                value: target.value
            }),

            ...otherFieldValues.slice(index + 1)
        ];

        this.setState({
            otherFieldValues: updatedOtherFieldValues
        });
    }

    handleRemoveOtherField(event) {
        const target = event.currentTarget;
        const index = getIndexFromName(target.name);
        const { otherFieldValues } = this.state;

        otherFieldValues.splice(index, 1);

        if (otherFieldValues.length === 0) {
            // Deselect "Other" tile if no inputs left
            this.setState((state) => ({
                selectedTiles: {
                    ...state.selectedTiles,
                    [FILE_CATEGORIES.OTHER]: false
                },

                otherFieldValues: [getNewOtherInput()]
            }));
        } else {
            this.setState({ otherFieldValues });
        }
    }

    handleAddOtherField() {
        const currentOtherFieldValues = this.state.otherFieldValues;

        if (currentOtherFieldValues.length < 3) {
            this.setState({
                otherFieldValues: [...currentOtherFieldValues, getNewOtherInput()]
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();

        const notEmptyOtherFieldValues = getNonEmptyOtherFields(this.state.otherFieldValues);

        const selectedDocuments = convertSelectedTilesToRequestedDocumentsWithoutOther(this.state.selectedTiles);

        const otherDocuments = notEmptyOtherFieldValues.map((field) => {
            return {
                category: FILE_CATEGORIES.OTHER,
                name: field.value
            };
        });

        const requestedDocuments = selectedDocuments.concat(otherDocuments);

        this.props.requestFiles(this.props.jobId, this.props.candidateId, requestedDocuments);
    }

    handleClose() {
        const { resetFilesRequest, candidate, onClose } = this.props;

        resetFilesRequest(candidate.id);
        onClose();
    }

    makeScrollableRef(element: HTMLElement | null) {
        const { onScroll } = this.props;

        if (!!element) {
            this.overlayPageContainer = element;

            if (!!onScroll) {
                this.overlayPageContainer.addEventListener('scroll', onScroll as any);
            }
        }
    }

    isSubmitButtonDisabled() {
        const { selectedTiles, otherFieldValues } = this.state;

        return !canSubmitRequest(selectedTiles, otherFieldValues);
    }

    render() {
        const { selectedTiles, isViewportMobile, otherFieldValues } = this.state;
        const { open, fileRequestStatus, fileRequestErrors, scrollTop } = this.props;

        let errorMessage;
        if (!!fileRequestErrors) {
            errorMessage = (
                <div className="RequestDocumentsView__error-message">
                    <FormattedMessage id="REQUEST_DOCUMENTS_VIEW.ERROR_MESSAGE" />
                </div>
            );
        }

        const content = (
            <div>
                {errorMessage}
                <RequestDocumentsViewTiles
                    categories={TILES_CATEGORIES}
                    handleChangeCheckbox={this.handleChangeCheckbox}
                    selectedTiles={selectedTiles}
                    isViewportMobile={isViewportMobile}
                />

                <RequestDocumentsViewOtherInputs
                    show={selectedTiles.other}
                    values={otherFieldValues}
                    changeValueField={this.handleChangeOtherField}
                    addField={this.handleAddOtherField}
                    removeField={this.handleRemoveOtherField}
                />
            </div>
        );

        const isSubmittingRequest = fileRequestStatus === 'creating';

        if (isViewportMobile) {
            return (
                <RequestDocumentsViewMobile
                    open={open}
                    submittingRequest={isSubmittingRequest}
                    submitEnabled={this.isSubmitButtonDisabled()}
                    scrollTop={scrollTop}
                    onClose={this.handleClose}
                    onSubmit={this.handleSubmit}
                    makeRef={this.makeScrollableRef}
                >
                    {content}
                </RequestDocumentsViewMobile>
            );
        } else {
            return (
                <RequestDocumentsViewDesktop
                    open={open}
                    submittingRequest={isSubmittingRequest}
                    submitEnabled={this.isSubmitButtonDisabled()}
                    onClose={this.handleClose}
                    onSubmit={this.handleSubmit}
                >
                    {content}
                </RequestDocumentsViewDesktop>
            );
        }
    }
}

const mapStateToProps = (state, props) => {
    return {
        candidate: getCandidate(state, props.candidateId),

        fileRequestErrors: getFileRequestByCandidateIdErrors(state, props.candidateId),
        fileRequestStatus: getFileRequestByCandidateIdCreateStatus(state, props.candidateId)
    };
};

const mapDispatchToProps = {
    requestFiles,
    resetFilesRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(scrolled(RequestDocumentsView));
