import {
    AuthenticationState,
    AuthenticationStateStatus,
    AuthenticationStateType
} from './AuthenticationState.constants';

export type CalculateAuthenticationStateProps = {
    authenticationChecked: boolean;
    isAuthenticated: boolean;
};

export type CalculateAuthenticationStateResult = AuthenticationState;

export function calculateAuthenticationState({
    authenticationChecked,
    isAuthenticated
}: CalculateAuthenticationStateProps): CalculateAuthenticationStateResult {
    if (!authenticationChecked) {
        return null;
    }

    return isAuthenticated ? 'authenticated' : 'anonymous';
}

export type CalculateAuthenticationStateStatusProps = {
    type: AuthenticationStateType | null;
    state: AuthenticationState;
};

export function calculateAuthenticationStateStatus({
    type,
    state
}: CalculateAuthenticationStateStatusProps): AuthenticationStateStatus {
    if (state === null) {
        return 'loading';
    }

    if (type === 'authenticated') {
        return state === 'authenticated' ? 'succeeded' : 'failed';
    } else if (type === 'anonymous') {
        return state === 'anonymous' ? 'succeeded' : 'failed';
    }

    return 'succeeded';
}
