import qs, { ParsedQs } from 'qs';
import { instanceOfNodeError } from './errors';

export function isValidUrl(url: string): boolean {
    try {
        new URL(url);
        return true;
    } catch (error) {
        if (instanceOfNodeError(error, Error) && error.code === 'ERR_INVALID_URL') {
            return false;
        }

        throw error;
    }
}

export function getUrlSearch(url: string): string {
    const startIndex = url.indexOf('?');

    return url.substring(startIndex);
}

export type ParsedSearch = ParsedQs;

export function parseSearch<R extends ParsedSearch>(search: string): R {
    search = search.indexOf('?') === 0 ? search.substring(1) : search;

    return qs.parse(search, {
        allowDots: true
    }) as R;
}

export function stringifySearch(data: Object | Array<any>): string {
    return (
        '?' +
        qs.stringify(data, {
            allowDots: true
        })
    );
}

export function getPathnameFromHash(hash: string): string {
    return hash.slice(1);
}

const URL_PROTOCOL_PATTERN = /^https?:\/\//i;

export function prefixHttp(str: string, ssl?: boolean): string {
    if (!str.match(URL_PROTOCOL_PATTERN)) {
        return (ssl ? 'https://' : 'http://') + str;
    }

    return str;
}

export function deprefixHttp(url: string): string {
    const match = url.match(URL_PROTOCOL_PATTERN);

    if (!!match) {
        return url.substr(match[0].length);
    }

    return url;
}

export function appendPathname(baseUrl: string, pathname: string): string {
    const url = new URL(baseUrl);

    url.pathname = url.pathname.replace(/\/$/, '') + '/' + pathname.replace(/^\//, '');

    return url.href;
}

export function extractPath(url: string | URL) {
    const { href, origin } = new URL(url);

    return href.slice(origin.length);
}

export function convertSearchParamsToObject(searchParams: URLSearchParams): Record<string, string> {
    return Object.fromEntries(searchParams.entries());
}
