import apiClientV5 from '../../services/api/v5';
import { toCommaSeparated, toBooleanString } from '../../services/api/utils';

import { Job } from '../../modules/jobs/types';
import { ApiEntityResponse, ApiListResponse } from '../../services/api/types';

type JobId = Job['id'];

type QueryParams = {
    goodNewsEveryone?: boolean;
    ordering?: string | string[];
    page?: number | 'last';
    pageSize?: number;
    state?: string | string[];
    stateOfCandidate?: string | string[];
    isPremium?: boolean;
    search?: string;
};

type Params = {
    good_news_everyone?: string;
    ordering?: string;
    page?: number | 'last';
    page_size?: number;
    state?: string;
    state_of_candidate?: string;
    is_premium?: string;
    search?: string;
};

export function fetchJobs(queryParams: QueryParams = {}): Promise<ApiListResponse<Job>> {
    const parsedQueryParams: Params = {};

    const { goodNewsEveryone, isPremium, page, pageSize, state, stateOfCandidate, ordering, search } = queryParams;

    if (typeof goodNewsEveryone === 'boolean') {
        parsedQueryParams.good_news_everyone = toBooleanString(goodNewsEveryone);
    }
    if (typeof isPremium === 'boolean') {
        parsedQueryParams.is_premium = toBooleanString(isPremium);
    }
    if (page) {
        parsedQueryParams.page = page;
    }
    if (pageSize) {
        parsedQueryParams.page_size = pageSize;
    }
    if (state) {
        parsedQueryParams.state = toCommaSeparated(state);
    }
    if (stateOfCandidate) {
        parsedQueryParams.state_of_candidate = toCommaSeparated(stateOfCandidate);
    }
    if (ordering) {
        parsedQueryParams.ordering = toCommaSeparated(ordering);
    }
    if (search) {
        parsedQueryParams.search = search;
    }

    return apiClientV5.get('/jobs/', {
        params: parsedQueryParams
    });
}

export type CreateJobInput = Partial<Job> | Pick<Job, 'url'>;

export function createJob(job: CreateJobInput): Promise<ApiEntityResponse<Job>> {
    return apiClientV5.post('/jobs/', job);
}

export function getJobById(jobId: JobId): Promise<ApiEntityResponse<Job>> {
    return apiClientV5.get(`/jobs/${jobId}/`);
}

export function updateJob(jobId: JobId, newJob: Partial<Job>): Promise<ApiEntityResponse<Job>> {
    return apiClientV5.patch(`/jobs/${jobId}/`, newJob);
}

export function deleteJob(jobId: JobId) {
    return apiClientV5.delete(`/jobs/${jobId}/`);
}

export function archiveJob(jobId: JobId) {
    return apiClientV5.put(`/jobs/${jobId}/archive/`);
}
