import * as React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-overlays';

import './DialogV2.style.scss';

import ScaleTransition from '../ScaleTransition';

// Types
export type DialogProps = {
    open: boolean;
    onClose?: () => void;
    restoreFocus?: boolean;
};

type Props = DialogProps & {
    className?: string;
    containerClassName?: string;
    children: React.ReactNode;
};

function DialogV2({ open, onClose, className, containerClassName, children, restoreFocus }: Props) {
    const handleClose = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Modal
            className={classNames('tf-dialog', className, {
                'tf-dialog--open': open
            })}
            backdropClassName="tf-dialog__backdrop"
            show={open}
            onBackdropClick={handleClose}
            transition={ScaleTransition}
            restoreFocus={restoreFocus}
        >
            <div className={classNames('DialogV2 tf-dialog__container', containerClassName)}>{children}</div>
        </Modal>
    );
}

export default DialogV2;
