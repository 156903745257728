import React from 'react';
import Application from './Application';
import ApplicationProviders from './ApplicationProviders';

export type ApplicationRootProps = {};

function ApplicationRoot({}: ApplicationRootProps) {
    return (
        <ApplicationProviders>
            <Application />
        </ApplicationProviders>
    );
}

export default ApplicationRoot;
