import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import './JobFormTasksListItemPlaceholder.style.scss';

function JobFormTasksListItemPlaceholder() {
    return (
        <div className="JobFormTasksListItemPlaceholder">
            <FormattedMessage id="JOB_EDIT.TASKS_LIST_ITEM_PLACEHOLDER.TEXT" />
        </div>
    );
}

export default JobFormTasksListItemPlaceholder;
