import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import CheckoutAddressFrom from '../CheckoutAddressForm';
import { convertRecruiterToFormValues, showFieldsetPersonalInformation } from '../CheckoutAddressForm/utils';

import CheckoutActionBar from '../../components/CheckoutActionBar';
import CheckoutActionBarButton from '../../components/CheckoutActionBar/CheckoutActionBarButton';
import { continueToNextStep } from './actions';

import { Recruiter } from '../../modules/recruiters/types';
import { CheckoutProcessState } from '../../modules/checkout/types';
import { FormValues } from '../CheckoutAddressForm/constants';
import { Job } from '../../modules/jobs/types';
import CheckoutHeader from '../../components/CheckoutHeader/CheckoutHeader';

type ConnectorProps = RouteComponentProps & {
    recruiter: Recruiter | null;
    checkoutProcessState: CheckoutProcessState;
    job: Job | null;
};

type ConnectedStateProps = {};
type ConnectedDispatchProps = {
    continueToNextStep: typeof continueToNextStep;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type State = {
    initialFormValues: FormValues | void;
    fieldsetPersonalInformationVisible: boolean;
};

class CheckoutAddressPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            initialFormValues: undefined,
            fieldsetPersonalInformationVisible: true
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const { recruiter } = this.props;

        if (!!recruiter) {
            this.setState({
                initialFormValues: convertRecruiterToFormValues(recruiter),
                fieldsetPersonalInformationVisible: showFieldsetPersonalInformation(recruiter)
            });
        }
    }

    handleSubmit(formValues: FormValues) {
        this.props.continueToNextStep(this.props.checkoutProcessState, formValues);
    }

    render() {
        const { recruiter, checkoutProcessState, job } = this.props;
        const { initialFormValues, fieldsetPersonalInformationVisible } = this.state;

        return (
            <React.Fragment>
                <CheckoutHeader
                    currentStep="address"
                    recruiter={recruiter}
                    checkoutProcessState={checkoutProcessState}
                    job={job}
                />
                <div className="container">
                    <CheckoutAddressFrom
                        onSubmit={this.handleSubmit}
                        initialValues={initialFormValues as FormValues}
                        fieldsetPersonalInformationVisible={fieldsetPersonalInformationVisible}
                    >
                        {this.renderActionBar()}
                    </CheckoutAddressFrom>
                </div>
            </React.Fragment>
        );
    }

    renderActionBar() {
        const { history } = this.props;

        const backAction = (
            <CheckoutActionBarButton onClick={() => history.goBack()}>
                <FormattedMessage id="CHECKOUT_PAGE.ACTION.BACK" />
            </CheckoutActionBarButton>
        );

        const saveAction = (
            <CheckoutActionBarButton type="submit" primary>
                <FormattedMessage id="CHECKOUT_PAGE.ACTION.CONTINUE" />
            </CheckoutActionBarButton>
        );

        return <CheckoutActionBar leftAction={backAction} rightAction={saveAction} />;
    }
}

const mapStateToProps = (): ConnectedStateProps => ({});

const mapDispatchToProps: ConnectedDispatchProps = {
    continueToNextStep
};

export default withRouter(
    connect<ConnectedStateProps, ConnectedDispatchProps, ConnectorProps>(
        mapStateToProps,
        mapDispatchToProps
    )(CheckoutAddressPage)
);
