import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import CandidateResume from '../../components/CandidateResume';
import ContentFrame from '../../components/ContentFrame';
import InfoBox from '../../components/InfoBox';

import { State as ApplicationState } from '../../store/reducer';

import { loadCandidate } from '../../modules/candidates/actions';
import { Candidate } from '../../modules/candidates/types';
import { getExpirationDaysLeft, isExpiring, isNotInteresting, isUnprocessed } from '../../modules/candidates/utils';
import { getCandidate, getCandidateFetchStatus } from '../../modules/entities/selectors';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { Recruiter } from '../../modules/recruiters/types';
import { FetchStatus } from '../../modules/types';

import { links } from '../../constants';

type PossibleRouteParams = {
    candidateId: string;
};

type ConnectorProps = RouteComponentProps<PossibleRouteParams>;

type ConnectedStateProps = {
    candidateId: number;

    candidate: Candidate | null;
    candidateFetchStatus: FetchStatus;

    recruiter: Recruiter | null;
};
type ConnectedDispatchProps = {
    loadCandidate: typeof loadCandidate;
};

type ConnectProps = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type Props = ConnectProps & {
    intl: IntlShape;
};

class CandidateProfilePage extends React.Component<Props> {
    componentDidMount() {
        if (this.props.candidateFetchStatus === 'none') {
            this.props.loadCandidate(this.props.candidateId);
        }
    }

    renderInfoBox() {
        const { candidate, intl } = this.props;

        if (!!candidate && isExpiring(candidate)) {
            const privacyPageLink = (
                <a href={links.privacy[intl.locale]} target="_blank" rel="noopener noreferrer">
                    <FormattedMessage id="CANDIDATE_PROFILE_PAGE.EXPIRATION_NOTE.LINK" />
                </a>
            );

            return (
                <ContentFrame>
                    <InfoBox type="danger">
                        <FormattedMessage
                            id="CANDIDATE_PROFILE_PAGE.EXPIRATION_NOTE"
                            values={{
                                link: privacyPageLink,
                                daysLeft: getExpirationDaysLeft(candidate)
                            }}
                        />
                    </InfoBox>
                </ContentFrame>
            );
        } else if (!!candidate && (isUnprocessed(candidate) || isNotInteresting(candidate))) {
            return (
                <ContentFrame>
                    <InfoBox type="danger">
                        <FormattedMessage id="CANDIDATE_PROFILE_PAGE.NOT_UNLOCKED_NOTE" />
                    </InfoBox>
                </ContentFrame>
            );
        }
    }

    render() {
        const { candidate, recruiter } = this.props;

        if (!candidate || !recruiter) {
            return null;
        }

        return (
            <div>
                {this.renderInfoBox()}
                <CandidateResume candidate={candidate} />
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => {
    const match = props.match;

    const candidateId = parseInt(match.params.candidateId, 10);

    return {
        candidateId,

        candidate: getCandidate(state, candidateId),
        candidateFetchStatus: getCandidateFetchStatus(state, candidateId),

        recruiter: getRecruiter(state)
    };
};

const mapDispatchToProps = {
    loadCandidate
};

const CandidateProfilePageWithIntl = injectIntl(CandidateProfilePage);

const withStore = connect<ConnectedStateProps, ConnectedDispatchProps, ConnectorProps, ApplicationState>(
    mapStateToProps,
    mapDispatchToProps
);

export default withStore(CandidateProfilePageWithIntl);
