import { JobRegion } from '../types';
import { createMapReducer } from '../../../utils/redux';
import { JOB_REGIONS_BY_COORDINATES_FETCH } from '../actions';
import { convertCoordinatesToCoordinatesString } from '../utils';
import { FETCH_STATUS } from '../../constants';
import { Action, FetchStatus } from '../../types';
import { combineReducers } from 'redux';

type EntriesState = JobRegion[];

export type ListState = {
    fetchStatus: FetchStatus;
    entries: EntriesState;
};

export type ListsByCoordinatesState = {
    [index: string]: ListState;
};

export const initialListFetchStatusState: FetchStatus = FETCH_STATUS.NONE;

export function listFetchStatusReducer(state: FetchStatus = initialListFetchStatusState, action: Action): FetchStatus {
    switch (action.type) {
        case JOB_REGIONS_BY_COORDINATES_FETCH.REQUEST:
            return FETCH_STATUS.LOADING;

        case JOB_REGIONS_BY_COORDINATES_FETCH.SUCCESS:
            return FETCH_STATUS.LOADED;

        case JOB_REGIONS_BY_COORDINATES_FETCH.FAILURE:
            return FETCH_STATUS.FAILED;

        default:
            return state;
    }
}

export const initialListEntriesState: EntriesState = [];

export function listEntriesReducer(state: EntriesState = initialListEntriesState, action: Action): EntriesState {
    switch (action.type) {
        case JOB_REGIONS_BY_COORDINATES_FETCH.SUCCESS:
            return action.payload.data.results;

        default:
            return state;
    }
}

export const initialListState: ListState = {
    fetchStatus: initialListFetchStatusState,
    entries: initialListEntriesState
};

export const listReducer = combineReducers<ListState>({
    fetchStatus: listFetchStatusReducer,
    entries: listEntriesReducer
});

const allowedActions = [
    JOB_REGIONS_BY_COORDINATES_FETCH.REQUEST,
    JOB_REGIONS_BY_COORDINATES_FETCH.SUCCESS,
    JOB_REGIONS_BY_COORDINATES_FETCH.FAILURE
];

const getCoordinatesAsKey = (action) => {
    if (!allowedActions.includes(action.type)) {
        return null;
    }

    return convertCoordinatesToCoordinatesString(action.payload.coordinates);
};

export default createMapReducer(listReducer, getCoordinatesAsKey);
