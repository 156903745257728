import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';

import './style.scss';

// Types
import { Localization } from '../../modules/types';

type SnackbarAction = {
    label: Localization | Localization['id'];
    onAction: Function;
};

export type SnackbarProps = {
    content: Localization | Localization['id'];
    action?: SnackbarAction;
};

class Snackbar extends React.Component<SnackbarProps> {
    renderContent(): React.ReactNode {
        const { content } = this.props;
        let text;

        if (typeof content === 'string' || typeof content === 'number') {
            text = <FormattedMessage id={content} />;
        } else {
            text = <FormattedMessage {...content} />;
        }

        return <div className="tf-snackbar__content">{text}</div>;
    }

    renderAction(): React.ReactNode {
        const { action } = this.props;
        let label;

        if (!action) {
            return null;
        }

        if (typeof action.label === 'string' || typeof action.label === 'number') {
            label = <FormattedMessage id={action.label} />;
        } else {
            label = <FormattedMessage {...action.label} />;
        }

        return (
            <div className="tf-snackbar__action">
                <Button type="button" typeStyle="flat" variationStyle="brand" onClick={action.onAction}>
                    {label}
                </Button>
            </div>
        );
    }

    render() {
        return (
            <div className="SnackBar tf-snackbar">
                {this.renderContent()}
                {this.renderAction()}
            </div>
        );
    }
}

export default Snackbar;
