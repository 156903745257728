import React from 'react';
import {
    AutomaticRequestTokenAnonymousRequestPageContainer,
    AutomaticRequestTokenAnonymousRequestPageContainerProps
} from './AutomaticRequestTokenAnonymousRequestPage';
import {
    AutomaticRequestTokenAuthenticatedRequestPageContainer,
    AutomaticRequestTokenAuthenticatedRequestPageContainerProps
} from './AutomaticRequestTokenAuthenticatedRequestPage';

export type AutomaticRequestTokenRequestPageContextValue = {
    AutomaticRequestTokenAuthenticatedRequestPageContainer: React.ComponentType<
        AutomaticRequestTokenAuthenticatedRequestPageContainerProps
    >;
    AutomaticRequestTokenAnonymousRequestPageContainer: React.ComponentType<
        AutomaticRequestTokenAnonymousRequestPageContainerProps
    >;
};

export const defaultAutomaticRequestTokenRequestPageContextValue: AutomaticRequestTokenRequestPageContextValue = {
    AutomaticRequestTokenAuthenticatedRequestPageContainer: AutomaticRequestTokenAuthenticatedRequestPageContainer,
    AutomaticRequestTokenAnonymousRequestPageContainer: AutomaticRequestTokenAnonymousRequestPageContainer
};

export const AutomaticRequestTokenRequestPageContext = React.createContext<
    AutomaticRequestTokenRequestPageContextValue
>(defaultAutomaticRequestTokenRequestPageContextValue);
