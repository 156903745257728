import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RequestTokenRequestPageLayout } from '../RequestTokenRequestPageLayout';
import { RequestTokenRequestSuccessPageReason } from './RequestTokenRequestSuccessPage.constants';

type MessagesRender = ({
    email
}: {
    email: string;
}) => {
    title: React.ReactNode;
    text: React.ReactNode;
};

const reasonToMessagesRenderMap: Partial<Record<RequestTokenRequestSuccessPageReason, MessagesRender>> = {
    'application-mail': ({ email }) => ({
        title: <FormattedMessage id="REQUEST_TOKEN_REQUEST_SUCCESS_PAGE.application-mail.TITLE" />,
        text: <FormattedMessage id="REQUEST_TOKEN_REQUEST_SUCCESS_PAGE.application-mail.TEXT" values={{ email }} />
    })
};

const renderDefaultMessages: MessagesRender = ({ email }) => ({
    title: <FormattedMessage id="REQUEST_TOKEN_REQUEST_SUCCESS_PAGE.default.TITLE" />,
    text: <FormattedMessage id="REQUEST_TOKEN_REQUEST_SUCCESS_PAGE.default.TEXT" values={{ email }} />
});

export type RequestTokenRequestSuccessPageProps = {
    email: string;
    reason?: RequestTokenRequestSuccessPageReason;
};

export function RequestTokenRequestSuccessPage({ email, reason }: RequestTokenRequestSuccessPageProps) {
    const messageRenderer: MessagesRender = (reason && reasonToMessagesRenderMap[reason]) || renderDefaultMessages;

    const { title, text } = messageRenderer({
        email
    });

    return (
        <RequestTokenRequestPageLayout title={title} data-testid="request-token-request-success-page">
            <p className="text-center">{text}</p>
        </RequestTokenRequestPageLayout>
    );
}
