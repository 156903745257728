import * as React from 'react';
import { MenuItem, NavDropdown } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { legalLinks } from '../../../constants';
import { Recruiter } from '../../../modules/recruiters/types';
import { isSignedUp } from '../../../modules/recruiters/utils';
import { getContactRoute, getPricesRoute, getProfileSettingsRoute, getSignupRoute } from '../../../routes';

import MenuItemLink from '../../../components/MenuItemLink';

import TrialEndCountdownMenuItem from './TrialEndCountdownMenuItem';
import UserMenuDropdownToggle from './UserMenuDropdownToggle';

type Props = {
    open: boolean;

    recruiter: Recruiter;

    onToggle: (isOpen: boolean) => void;

    onClickLogout: () => void;
    onClickTutorial: () => void;
};

function UserMenuDropdown(
    {
        open,

        recruiter,

        onToggle,

        onClickLogout,
        onClickTutorial
    }: Props,
    ref: any
) {
    const legalLinkEntries = Object.entries(legalLinks);

    return (
        <NavDropdown
            open={open}
            onToggle={onToggle}
            title={<UserMenuDropdownToggle recruiter={recruiter} />}
            id="NavigationActions__user-dropdown"
            className="NavigationActions__dropdown"
            ref={ref}
        >
            <TrialEndCountdownMenuItem trialEndDate={recruiter.trial_end} />
            {isSignedUp(recruiter) && (
                <MenuItemLink to={getProfileSettingsRoute()}>
                    <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.SETTINGS" />
                </MenuItemLink>
            )}

            <MenuItem onClick={onClickTutorial}>
                <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.TUTORIAL" />
            </MenuItem>
            {!isSignedUp(recruiter) && (
                <MenuItemLink to={getSignupRoute()}>
                    <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.SIGNUP" />
                </MenuItemLink>
            )}

            <MenuItem divider />

            <MenuItemLink to={getContactRoute()} target="_blank">
                <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.CONTACT" />
            </MenuItemLink>
            <MenuItemLink to={getPricesRoute()}>
                <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.PRICES" />
            </MenuItemLink>
            {legalLinkEntries.map(([key, link]) => {
                return (
                    <MenuItem key={key} href={link} target="_blank" rel="noopener">
                        <FormattedMessage id={`NAVIGATION.ACTIONS.USER_MENU.LEGAL.${key}`} />
                    </MenuItem>
                );
            })}

            <MenuItem divider />

            <MenuItem onClick={onClickLogout}>
                <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.LOGOUT" />
            </MenuItem>
        </NavDropdown>
    );
}

export default React.forwardRef(UserMenuDropdown);
