import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, InjectedFormProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { FormActions } from '@truffls/design-system-react';

import SignupFormStepHeadline from '../../../components/SignupFormStepHeadline';
import SignupFormStepActions from '../SignupFormStepActions';
import FormFieldEmail from '../../../components/FormFieldEmail';

import { FORM_NAME, FormValues } from '../constants';

import './SignupFormStepEmailChange.style.scss';

type ConnectorProps = {};
type ConnectedStateProps = {
    email: string;
};
type ConnectedDispatchProps = {};

type ConnectProps = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type Props = ConnectProps & InjectedFormProps<FormValues, ConnectProps>;

type State = {
    submittedEmail: string;
};

class SignupFormStepEmailChange extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            submittedEmail: props.email
        };
    }

    render() {
        const { handleSubmit, submitting, valid } = this.props;
        const { submittedEmail } = this.state;

        return (
            <form onSubmit={handleSubmit} className="SignupFormStepEmailChange">
                <SignupFormStepHeadline>
                    <FormattedMessage
                        id="SIGNUP_FORM_STEP_EMAIL_CHANGE.TITLE"
                        values={{ email: <b>{submittedEmail}</b> }}
                    />
                </SignupFormStepHeadline>

                <div className="SignupFormStepEmailChange__content">
                    <FormActions>
                        <FormFieldEmail
                            name="email"
                            id="email"
                            label={<FormattedMessage id="SIGNUP_FORM_STEP_EMAIL_CHANGE.LABEL" />}
                            required
                            autoFocus
                        />
                    </FormActions>
                </div>

                <SignupFormStepActions
                    hideBack
                    submitStep
                    submitting={submitting}
                    valid={valid}
                    onNext={handleSubmit}
                />
            </form>
        );
    }
}

const withForm = reduxForm<FormValues, ConnectProps>({
    form: FORM_NAME,
    destroyOnUnmount: false
});

const getFieldValue = formValueSelector(FORM_NAME);

const mapStateToProps = (state): ConnectedStateProps => {
    return {
        email: getFieldValue(state, 'email')
    };
};

const withStore = connect<ConnectedStateProps, ConnectedDispatchProps, ConnectorProps>(mapStateToProps);

export default withStore(withForm(SignupFormStepEmailChange));
