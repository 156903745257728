export const COMPLETE_TUTORIAL_UPDATE_STATUS_NONE: 'none' = 'none';
export const COMPLETE_TUTORIAL_UPDATE_STATUS_UPDATING: 'updating' = 'updating';
export const COMPLETE_TUTORIAL_UPDATE_STATUS_UPDATED: 'updated' = 'updated';
export const COMPLETE_TUTORIAL_UPDATE_STATUS_FAILED: 'failed' = 'failed';

export const COMPLETE_TUTORIAL_UPDATE_STATUS = {
    NONE: COMPLETE_TUTORIAL_UPDATE_STATUS_NONE,
    UPDATING: COMPLETE_TUTORIAL_UPDATE_STATUS_UPDATING,
    UPDATED: COMPLETE_TUTORIAL_UPDATE_STATUS_UPDATED,
    FAILED: COMPLETE_TUTORIAL_UPDATE_STATUS_FAILED
};
