import * as React from 'react';
import classNames from 'classnames';
import { UseComboboxPropGetters } from 'downshift';
import { FormattedMessage, useIntl } from 'react-intl';

import { SEARCH_RESULT_TYPE_JOB, SEARCH_RESULT_TYPE_CANDIDATE } from '../../../modules/search/constants';

import SearchBarFormOpenIcon from './SearchBarFormOpenIcon';
import SearchBarFormLenseIcon from './SearchBarFormLenseIcon';

import './SearchBarForm.style.scss';

type Props = {
    getQueryLabelProps: UseComboboxPropGetters<any>['getLabelProps'];
    getQueryInputProps: UseComboboxPropGetters<any>['getInputProps'];
    getTypeLabelProps: UseComboboxPropGetters<any>['getLabelProps'];
    getTypeInputProps: UseComboboxPropGetters<any>['getInputProps'];

    isOpen: boolean;
    openMenu: () => void;
};

function SearchBarForm({
    getQueryLabelProps,
    getQueryInputProps,
    getTypeLabelProps,
    getTypeInputProps,

    isOpen,
    openMenu
}: Props) {
    const intl = useIntl();

    const onClickOpenIcon = () => {
        openMenu();
    };

    const onFocusForm = () => {
        if (!isOpen) {
            openMenu();
        }
    };

    const onSubmit = (event) => {
        event.preventDefault();
    };

    const computedClassName = classNames('SearchBarForm', {
        'SearchBarForm--expanded': isOpen
    });

    const id = 'search-bar-form';
    const queryLabelId = `${id}-label-query`;
    const queryInputId = `${id}-input-query`;
    const typeLabelId = `${id}-label-type`;
    const typeInputId = `${id}-input-type`;

    return (
        <div className={computedClassName}>
            <SearchBarFormOpenIcon onClick={onClickOpenIcon} />

            <form className="SearchBarForm__fields" onFocus={onFocusForm} onSubmit={onSubmit}>
                <label
                    {...getQueryLabelProps({
                        className: 'sr-only',
                        id: queryLabelId,
                        htmlFor: queryInputId
                    })}
                >
                    <FormattedMessage id="SEARCH_BAR_FORM.FIELD_QUERY.LABEL" />
                </label>
                <input
                    {...getQueryInputProps({
                        type: 'search',
                        name: 'query',
                        placeholder: intl.formatMessage({
                            id: 'SEARCH_BAR_FORM.FIELD_QUERY.PLACEHOLDER'
                        }),

                        autoComplete: 'off',
                        id: queryInputId,
                        className: 'SearchBarForm__input-query',
                        'aria-labelledby': queryLabelId
                    })}
                />

                <label
                    {...getTypeLabelProps({
                        id: typeLabelId,
                        className: 'sr-only',
                        htmlFor: typeInputId
                    })}
                >
                    <FormattedMessage id="SEARCH_BAR_FORM.FIELD_TYPE.LABEL" />
                </label>

                <div className="SearchBarForm__input-type">
                    <select
                        {...getTypeInputProps({
                            name: 'type',
                            id: typeInputId,
                            className: 'SearchBarForm__input-type-control',
                            'aria-labelledby': typeLabelId
                        })}
                    >
                        <option value={SEARCH_RESULT_TYPE_CANDIDATE}>
                            {intl.formatMessage({
                                id: 'SEARCH_BAR_FORM.FIELD_TYPE.VALUE_CANDIDATE'
                            })}
                        </option>
                        <option value={SEARCH_RESULT_TYPE_JOB}>
                            {intl.formatMessage({
                                id: 'SEARCH_BAR_FORM.FIELD_TYPE.VALUE_JOB'
                            })}
                        </option>
                    </select>

                    <svg
                        className="SearchBarForm__input-type-arrow-faux"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fillRule="evenodd"
                            d="M17.4797799,9.85355339 L12.0202201,15.3131133 C11.8249579,15.5083754 11.5083754,15.5083754 11.3131133,15.3131133 L5.85355339,9.85355339 C5.65829124,9.65829124 5.65829124,9.34170876 5.85355339,9.14644661 C5.94732158,9.05267842 6.07449854,9 6.20710678,9 L17.1262266,9 C17.4023689,9 17.6262266,9.22385763 17.6262266,9.5 C17.6262266,9.63260824 17.5735481,9.7597852 17.4797799,9.85355339 Z"
                        />
                    </svg>
                </div>

                {/* TODO: Can we move those into CSS – utilize background and ::before? */}
                <SearchBarFormLenseIcon state="inactive" />
                <SearchBarFormLenseIcon state="active" />
            </form>
        </div>
    );
}

export default SearchBarForm;
