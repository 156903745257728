import * as React from 'react';
import classnames from 'classnames';

import './style.scss';

class BottomActionBar extends React.Component {
    render() {
        const { children } = this.props;

        const classname = classnames('BottomActionBar', {
            'BottomActionBar--space-between': React.Children.toArray(children).length !== 1
        });

        return (
            <div className={classname}>
                <div className="BottomActionBar__flex-wrapper">{this.props.children}</div>
            </div>
        );
    }
}

export default BottomActionBar;
