import * as React from 'react';

import './CandidateResumeTagItem.style.scss';

type Props = {
    children: React.ReactNode;
};

const CandidateResumeTagItem = ({ children }: Props) => <li className="tag CandidateResumeTagItem">{children}</li>;

export default CandidateResumeTagItem;
