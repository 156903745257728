import { getCandidatesRoute, getCandidatesNotInterestingRoute } from '../../routes';

export function getLinkHref(listName: string, jobId: number) {
    if (listName === 'withoutNotInteresting') {
        return getCandidatesNotInterestingRoute(jobId);
    } else {
        return getCandidatesRoute(jobId);
    }
}

export function getLinkLabelMessageId(listName: string) {
    if (listName === 'withoutNotInteresting') {
        return 'CANDIDATE_LIST_PAGE_FOOTER.LINK_NOT_INTERESTING_CANDIDATES';
    } else if (listName === 'withNewMessages') {
        return 'CANDIDATE_LIST_PAGE_FOOTER.LINK_CANDIDATES';
    } else {
        return 'CANDIDATE_LIST_PAGE_FOOTER.LINK_BACK';
    }
}
