import { Keyframes } from 'react-spring/renderprops';
import { ANIMATION_STEP_INIT, ANIMATION_STEP_OPEN, ANIMATION_STEP_NEXT_STEP } from '../../constants';

const AnimationStates = {
    [ANIMATION_STEP_INIT]: {
        opacity: 0,
        wrapperTranslate: [238, 220, 0, 0],
        childTranslate: [0, 0, 100, 0]
    },
    [ANIMATION_STEP_OPEN]: {
        opacity: 1,
        wrapperTranslate: [238, 120, 0, 0],
        childTranslate: [0, 0, 100, 0],
        delay: 500
    },
    [ANIMATION_STEP_NEXT_STEP]: {
        wrapperTranslate: [17, 120, 0, 0],
        childTranslate: [0, 0, 0, 0],
        config: {
            tension: 200,
            friction: 20.17
        },
        delay: 0
    }
};

export const AnimationKeyframes = Keyframes.Spring({ // eslint-disable-line
    [ANIMATION_STEP_INIT]: AnimationStates[ANIMATION_STEP_INIT],
    [ANIMATION_STEP_OPEN]: {
        from: AnimationStates[ANIMATION_STEP_INIT],
        ...AnimationStates[ANIMATION_STEP_OPEN]
    },
    [ANIMATION_STEP_NEXT_STEP]: {
        from: AnimationStates[ANIMATION_STEP_OPEN],
        ...AnimationStates[ANIMATION_STEP_NEXT_STEP]
    }
});

export const AnimationLeaveKeyframes = {
    [ANIMATION_STEP_INIT]: {
        from: {
            opacity: 1
        },
        opacity: 0
    },
    [ANIMATION_STEP_NEXT_STEP]: {
        from: {
            opacity: 1,
            wrapperTranslate: AnimationStates[ANIMATION_STEP_NEXT_STEP].wrapperTranslate
        },
        opacity: 0,
        wrapperTranslate: [17, 20, 0, 0],

        // Ensure the child doesn't move
        childTranslate: AnimationStates[ANIMATION_STEP_NEXT_STEP].childTranslate
    }
};

export const emptyTransition = { visibility: 'visible' };
