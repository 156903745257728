import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Radio } from '@truffls/design-system-react';
import { FieldValidator } from 'final-form';
import { FUNCTIONS } from '../../../../modules/jobs/constants';
import useField from '../../../../utils/form/react-final-form/useField';
import { isRequired } from '../../../../utils/form/validators';
import { Field } from '../../../../utils/form/react-final-form';
import { onJobFormPageValidationLevel } from '../../JobFormPage.utils';
import { useJobFormPageHasError } from '../../JobFormPage.hooks';
import JobFormFieldAnchor from '../../JobFormFieldAnchor';
import { getContactRoute, makeWithPrefix } from '../../../../routes';

import styles from './JobFormFunctionsFormFields.module.scss';

export type JobFormFunctionsFormFieldsName = 'function';
export const JOB_EDIT_FUNCTIONS_FORM_FIELDS_NAME: JobFormFunctionsFormFieldsName = 'function';
export type JobFormFunctionsFormFieldsValue = string | null;
export type JobFormFunctionsFormFieldsFormValuesPartial = {
    [key in JobFormFunctionsFormFieldsName]: JobFormFunctionsFormFieldsValue;
};

const validate = onJobFormPageValidationLevel<JobFormFunctionsFormFieldsValue>(
    'full',
    isRequired as FieldValidator<JobFormFunctionsFormFieldsValue>
);

export type JobFormFunctionsFormFieldsProps = {
    isFunctionsEditable: boolean;
};

function JobFormFunctionsFormFields({ isFunctionsEditable }: JobFormFunctionsFormFieldsProps) {
    const contactUrl = makeWithPrefix(getContactRoute());

    const hasError = useJobFormPageHasError();
    const { input, meta } = useField<JobFormFunctionsFormFieldsValue, HTMLInputElement>(
        JOB_EDIT_FUNCTIONS_FORM_FIELDS_NAME,
        {
            type: 'radio',
            validate
        }
    );
    const selectedValue = input.value || null;

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_FUNCTIONS_FORM_FIELDS_NAME} />
            <div
                className={classNames('tf-form-field', {
                    'tf-has-error': hasError(selectedValue, meta)
                })}
            >
                <label className="control-label">
                    <FormattedMessage id="JOB_EDIT.FUNCTIONS_FORM_FIELDS.LABEL_TEXT" />
                </label>
                <span className="help-block">
                    <FormattedMessage id="JOB_EDIT.FUNCTIONS_FORM_FIELDS.INSTRUCTION_TEXT" />
                </span>
                <div className="row">
                    {FUNCTIONS.map((functionSlug) => (
                        <div key={functionSlug} className="col-sm-6 col-md-6 col-lg-4">
                            <Field name={JOB_EDIT_FUNCTIONS_FORM_FIELDS_NAME} type="radio" value={functionSlug}>
                                {({ input }) => {
                                    return (
                                        <Radio
                                            id={`job-form-field-input-functions-${functionSlug}`}
                                            {...input}
                                            disabled={!isFunctionsEditable}
                                        >
                                            <FormattedMessage id={`CONSTANT.FUNCTION.${functionSlug}`} />
                                        </Radio>
                                    );
                                }}
                            </Field>
                        </div>
                    ))}
                </div>
                <div className="help-block">
                    <FormattedMessage id="JOB_EDIT.FUNCTIONS_FORM_FIELDS.HELP_TEXT" />
                </div>
            </div>
            <div className={classNames('tf-form-field__help', styles.editableHelpText)}>
                <FormattedMessage
                    id="JOB_EDIT.FUNCTIONS_FORM_FIELDS.HELP_TEXT_EDITABLE"
                    values={{
                        contactLink: (chunks) => (
                            <a href={contactUrl} target="_blank">
                                {chunks}
                            </a>
                        )
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default JobFormFunctionsFormFields;
