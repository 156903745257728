import { Pagination } from './types';

// Legacy
export const STATUS_NONE = 'none';
export const STATUS_REQUESTING = 'requesting';
export const STATUS_REQUESTED = 'requested';
export const STATUS_FAILED = 'failed';

// New ones
export const FETCH_STATUS_NONE: 'none' = 'none';
export const FETCH_STATUS_LOADING: 'loading' = 'loading';
export const FETCH_STATUS_LOADED: 'loaded' = 'loaded';
export const FETCH_STATUS_FAILED: 'failed' = 'failed';

export const FETCH_STATUS = {
    NONE: FETCH_STATUS_NONE,
    LOADING: FETCH_STATUS_LOADING,
    LOADED: FETCH_STATUS_LOADED,
    FAILED: FETCH_STATUS_FAILED
};

export const CREATE_STATUS_NONE: 'none' = 'none';
export const CREATE_STATUS_CREATING: 'creating' = 'creating';
export const CREATE_STATUS_CREATED: 'created' = 'created';
export const CREATE_STATUS_FAILED: 'failed' = 'failed';

export const CREATE_STATUS = {
    NONE: CREATE_STATUS_NONE,
    CREATING: CREATE_STATUS_CREATING,
    CREATED: CREATE_STATUS_CREATED,
    FAILED: CREATE_STATUS_FAILED
};

export const UPDATE_STATUS_NONE: 'none' = 'none';
export const UPDATE_STATUS_UPDATING: 'updating' = 'updating';
export const UPDATE_STATUS_UPDATED: 'updated' = 'updated';
export const UPDATE_STATUS_FAILED: 'failed' = 'failed';

export const UPDATE_STATUS = {
    NONE: UPDATE_STATUS_NONE,
    UPDATING: UPDATE_STATUS_UPDATING,
    UPDATED: UPDATE_STATUS_UPDATED,
    FAILED: UPDATE_STATUS_FAILED
};

export const DELETE_STATUS_NONE: 'none' = 'none';
export const DELETE_STATUS_DELETING: 'deleting' = 'deleting';
export const DELETE_STATUS_DELETED: 'deleted' = 'deleted';
export const DELETE_STATUS_FAILED: 'failed' = 'failed';

export const DELETE_STATUS = {
    NONE: DELETE_STATUS_NONE,
    DELETING: DELETE_STATUS_DELETING,
    DELETED: DELETE_STATUS_DELETED,
    FAILED: DELETE_STATUS_FAILED
};

export const INITIAL_PAGINATION: Pagination = {
    count: 0,
    nextPage: null,
    previousPage: null
};
