import { combineReducers, Reducer } from 'redux';

import { FETCH_CANDIDATES, CANDIDATE_DELETE } from '../actions';

import { Action, FetchStatus } from '../../types';

import resetOnLogout from '../../authentication/reducer/resetOnLogout';
import { createMapIteratorReducer, createMapReducer } from '../../../utils/redux';

import byApplicantsReducer, { State as _ByApplicantsState } from './byApplicantsReducer';
import forwardsReducer, { State as _ForwardsState, ForwardStatus as _ForwardStatus } from './forwardsReducer';

// We want to mount our reducer here
export const MOUNT = 'candidates';

export type ByApplicantsState = _ByApplicantsState;
export type ForwardStatus = _ForwardStatus;
export type ForwardsState = _ForwardsState;

type FetchStatusState = FetchStatus;
type EntriesState = number[];
type TotalCountState = number;
type PageState = number;
type ErrorState = null | any;
// Contains candidate entries, error and fetchStatus of an job.
type JobState = {
    entries: EntriesState;
    totalCount: TotalCountState;
    page: PageState;
    error: ErrorState;
    fetchStatus: FetchStatusState;
};

type ListState = {
    [id: string]: JobState;
};

export type CandidatesState = {
    withoutNotInteresting: ListState;
    withNewMessages: ListState;
    notInteresting: ListState;
    nextNewForCurrentJob: ListState;

    byApplicants: ByApplicantsState;

    forwards: ForwardsState;
};

export type State = {
    candidates: CandidatesState;
};

export const entriesReducer = (state: EntriesState = [], action: Action): EntriesState => {
    switch (action.type) {
        case FETCH_CANDIDATES.SUCCESS:
            return action.payload.data.results.map((job) => job.id);

        case CANDIDATE_DELETE.SUCCESS:
            const candidateId = action.payload.candidateId;
            return state.filter((id: number) => id !== candidateId);

        default:
            return state;
    }
};

export const totalCountReducer = (state: TotalCountState = 0, action: Action): TotalCountState => {
    switch (action.type) {
        case FETCH_CANDIDATES.SUCCESS:
            return action.payload.data.count;

        default:
            return state;
    }
};

export const pageReducer = (state: PageState = 1, action: Action): PageState => {
    switch (action.type) {
        case FETCH_CANDIDATES.REQUEST:
        case FETCH_CANDIDATES.SUCCESS:
            return action.payload.page;

        default:
            return state;
    }
};

export const errorReducer = (state: ErrorState = null, action: Action): ErrorState => {
    switch (action.type) {
        case FETCH_CANDIDATES.SUCCESS:
            return null;

        case FETCH_CANDIDATES.FAILURE:
            return action.payload.errors || [];

        default:
            return state;
    }
};

export const fetchStatusReducer = (state: FetchStatusState = 'none', action: Action): FetchStatusState => {
    switch (action.type) {
        case FETCH_CANDIDATES.REQUEST:
            return 'loading';

        case FETCH_CANDIDATES.SUCCESS:
            return 'loaded';

        case FETCH_CANDIDATES.FAILURE:
            return 'failed';

        default:
            return state;
    }
};

const jobReducer = combineReducers<JobState>({
    entries: entriesReducer,
    totalCount: totalCountReducer,
    page: pageReducer,
    error: errorReducer,
    fetchStatus: fetchStatusReducer
});

export const createListReducer = <N extends string, A extends Action>(
    subReducer: Reducer<ListState[N]>,
    listName: N
) => {
    const actionTypesToPropagateToAllSubStates: string[] = [CANDIDATE_DELETE.SUCCESS];

    const hasCorrectListName = (action: A) => {
        return typeof action.payload === 'object' && action.payload.listName === listName;
    };

    const getKey = (action: A) => {
        if (
            !hasCorrectListName(action) ||
            typeof action.payload !== 'object' ||
            typeof action.payload.jobId !== 'number'
        ) {
            return null;
        }

        return String(action.payload.jobId);
    };

    const iterateSubStates = createMapIteratorReducer(subReducer);
    const reduceSubState = createMapReducer(subReducer, getKey);

    return (state: ListState = {}, action: A): ListState => {
        if (actionTypesToPropagateToAllSubStates.indexOf(action.type) !== -1) {
            return iterateSubStates(state, action);
        }

        return reduceSubState(state, action);
    };
};

const createJobMapReducer = (listName) => {
    return createListReducer(jobReducer, listName);
};

const candidatesReducer = combineReducers<CandidatesState>({
    withoutNotInteresting: createJobMapReducer('withoutNotInteresting'),
    withNewMessages: createJobMapReducer('withNewMessages'),
    notInteresting: createJobMapReducer('notInteresting'),
    nextNewForCurrentJob: createJobMapReducer('nextNewForCurrentJob'),

    byApplicants: byApplicantsReducer,

    forwards: forwardsReducer
});

export default resetOnLogout<CandidatesState>(candidatesReducer);
