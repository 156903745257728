import { IconInformationCircleBold } from '@truffls/icons-react';
import React from 'react';
import { Job } from '../../../modules/jobs/types';
import JobState from '../../JobState';
import JobDetailsMetaItem from '../JobDetailsMetaItem';
import JobDetailsMetaItemLabeledValue from '../JobDetailsMetaItemLabeledValue';

export type JobDetailsMetaStatusProps = {
    job: Job;
};

function JobDetailsMetaStatus({ job }: JobDetailsMetaStatusProps) {
    return (
        <JobDetailsMetaItem icon={<IconInformationCircleBold />}>
            <JobDetailsMetaItemLabeledValue labelId="JOB_DETAILS_META.STATUS_LABEL">
                <JobState job={job} />
            </JobDetailsMetaItemLabeledValue>
        </JobDetailsMetaItem>
    );
}

export default JobDetailsMetaStatus;
