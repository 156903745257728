import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export type FormErrorProps = {
    type?: 'danger' | 'info';
    messageId: string;
    messageValues?: any;
};

function FormError({ type = 'danger', messageId, messageValues }: FormErrorProps) {
    const errorAlertClassName = classNames('alert', {
        'alert-danger': type === 'danger',
        'alert-info': type === 'info'
    });

    return (
        <div className={errorAlertClassName} data-testid="form-error">
            <FormattedMessage id={messageId} values={messageValues} />
        </div>
    );
}

export default FormError;
