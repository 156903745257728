import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { isExpiring, getExpirationDaysLeft } from '../../modules/candidates/utils';
import CandidateAvatar from '../CandidateAvatar';
import Details from '../Details';
import DetailsPrimary from '../Details/DetailsPrimary';
import DetailsSecondary from '../Details/DetailsSecondary';

import './CandidateDetails.style.scss';

import { Candidate } from '../../modules/candidates/types';
type Props = {
    candidate: Candidate | null;
    showProgress?: boolean;
    showNewBadge?: boolean;
    showUnreadMessageBadge?: boolean;
    fadedWhenExpiring?: boolean;
    appendName?: React.ReactNode;
    skeleton?: boolean;
};

class CandidateDetails extends React.Component<Props> {
    renderExpiration() {
        const { candidate } = this.props;

        if (!!candidate && !candidate.expiration_date) {
            return null;
        } else if (!!candidate) {
            const expirationDaysLeft = getExpirationDaysLeft(candidate);

            return (
                <span className="CandidateDetails__expiration-label">
                    <FormattedMessage
                        id="CANDIDATE_DETAILS.EXPIRATION_LABEL"
                        values={{
                            days: expirationDaysLeft.toString()
                        }}
                    />
                </span>
            );
        }
    }

    renderOccupation() {
        const { candidate } = this.props;

        if (!!candidate) {
            const { resume } = candidate.applicant;
            const position = !!resume.work[0] ? resume.work[0].position : null;

            return (
                <span>
                    <FormattedMessage id="CANDIDATE_DETAILS.CURRENT_OCCUPATION_LABEL" />
                    <span className="CandidateDetails__occupation">{position}</span>
                </span>
            );
        }
    }

    renderExpirationOrOccupation() {
        const { candidate } = this.props;

        if (!!candidate && isExpiring(candidate)) {
            return this.renderExpiration();
        }

        return this.renderOccupation();
    }

    renderCandidateState() {
        const { candidate, showProgress } = this.props;

        if (!!candidate && showProgress) {
            return (
                <div className="CandidateDetails__state">
                    <span className={`CandidateDetails__state-dot CandidateDetails__state-dot--${candidate.state}`} />
                    <span>
                        <FormattedMessage id={`CONSTANT.CANDIDATE_STATE.${candidate.state}`} />
                    </span>
                </div>
            );
        }
    }

    render() {
        const {
            candidate,

            showProgress,
            showUnreadMessageBadge,
            showNewBadge,

            fadedWhenExpiring,
            appendName,

            skeleton
        } = this.props;

        let applicant;
        if (!!candidate) {
            applicant = candidate.applicant;
        }

        const className = classNames(
            'CandidateDetails',
            { 'CandidateDetails--skeleton': skeleton },
            { 'CandidateDetails--faded': fadedWhenExpiring && !!candidate && isExpiring(candidate) }
        );

        return (
            <div className={className}>
                <div className="CandidateDetails__avatar">
                    <CandidateAvatar
                        candidate={candidate}
                        showUnreadMessageBadge={showUnreadMessageBadge}
                        showNewBadge={showNewBadge}
                        skeleton={skeleton}
                    />
                </div>
                <div className="CandidateDetails__details-info">
                    <Details>
                        <DetailsPrimary className="CandidateDetails__primary" skeleton={skeleton} truncate>
                            {!!applicant && <span className="CandidateDetails__name">{applicant.full_name}</span>}
                            {appendName}
                        </DetailsPrimary>
                        <DetailsSecondary className="CandidateDetails__secondary" skeleton={skeleton}>
                            {this.renderExpirationOrOccupation()}
                        </DetailsSecondary>
                    </Details>
                    {!!skeleton && !!showProgress && <div className="CandidateDetails__state--skeleton" />}
                    <div className="CandidateDetails__state-rating-container">{this.renderCandidateState()}</div>
                </div>
            </div>
        );
    }
}

export default CandidateDetails;
