import * as React from 'react';

import './TemplatesDropdownItem.style.scss';

import { TEMPLATE_GERMAN_LANGUAGE } from '../../../modules/templates/constants';
import { LOCALE_DE, LOCALE_EN } from '../../../i18n';

import LanguageFlag from '../../LanguageFlag';

import { Template } from '../../../modules/templates/types';

type Props = {
    template: Template;
};

const TemplatesDropdownItem = ({ template }: Props) => {
    const isGermanTemplate = template.language === TEMPLATE_GERMAN_LANGUAGE;

    return (
        <div className="TemplatesDropdownItem">
            <LanguageFlag language={isGermanTemplate ? LOCALE_DE : LOCALE_EN} className="TemplatesDropdownItem__flag" />
            <span className="TemplatesDropdownItem__label">{template.name}</span>
        </div>
    );
};

export default TemplatesDropdownItem;
