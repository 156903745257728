import { createSelector } from 'reselect';

import { State } from '../../store/reducer';

import { FETCH_STATUS } from '../constants';
import { Pagination } from '../types';

import { getSearchResultEntities } from '../entities/selectors';

import { MOUNT, SearchState } from './reducer';
import { SearchResult } from './types';

// Root
const getState = (state: State): SearchState => state[MOUNT];

// Fields
export const getSearchResultIds: (state: State) => SearchState['entries'] = createSelector(
    getState,
    (state) => state.entries
);

export const getSearchFetchStatus: (state: State) => SearchState['fetchStatus'] = createSelector(
    getState,
    (state) => state.fetchStatus
);

export const getSearchResultsPagination: (state: State) => SearchState['pagination'] = createSelector(
    getState,
    (state) => state.pagination
);

// Recomposed
export const getSearchResults: (state: State) => Array<SearchResult> = createSelector(
    getSearchResultIds,
    getSearchResultEntities,
    (searchResultIds, searchResultEntities: { [id: string]: SearchResult }) =>
        searchResultIds.map((id) => searchResultEntities[id])
);

export const getSearchResultsPaginationCount: (state: State) => Pagination['count'] = createSelector(
    getSearchResultsPagination,
    (searchResultsPagination) => searchResultsPagination.count
);

export const getSearchResultsPaginationNextPage: (state: State) => Pagination['nextPage'] = createSelector(
    getSearchResultsPagination,
    (searchResultsPagination) => searchResultsPagination.nextPage
);

export const getSearchResultsPaginationPreviousPage: (state: State) => Pagination['previousPage'] = createSelector(
    getSearchResultsPagination,
    (searchResultsPagination) => searchResultsPagination.previousPage
);

export const isFetchingSearchResults: (state: State) => boolean = createSelector(
    getSearchFetchStatus,
    (searchResultsFetchStatus) => searchResultsFetchStatus === FETCH_STATUS.LOADING
);
