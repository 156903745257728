import { addDays, addMilliseconds, differenceInDays, format, parseISO } from 'date-fns';

export const DateInterval = Object.freeze({
    SECOND: 1,
    MINUTE: 60,
    HOUR: 3600,
    DAY: 86400,
    WEEK: 604800,

    SECOND_IN_MS: 1000,
    MINUTE_IN_MS: 60000,
    HOUR_IN_MS: 3600000,
    DAY_IN_MS: 86400000,
    WEEK_IN_MS: 604800000
} as const);

export const longNumericDateTimeFormatOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
};

export function formatToMonthInYear(date: string): string {
    return format(parseISO(date), 'MM/yyyy');
}

export function addDaysToDate(date: Date, days: number) {
    return addDays(date, days);
}

export function formatDateAsISO(input: string | number | Date): string {
    const date = typeof input === 'string' ? parseISO(input) : new Date(input);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export function formatISO(input: string | number | Date): string {
    const date = typeof input === 'string' ? parseISO(input) : new Date(input);

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

export function getDaysLeft(date: string | number | Date, now: string | number | Date): number {
    const safeDate = typeof date === 'string' ? parseISO(date) : new Date(date);
    const safeNow = typeof now === 'string' ? parseISO(now) : new Date(now);

    const daysDifference = differenceInDays(safeDate, safeNow);
    const daysLeft = Math.max(0, daysDifference);
    return daysLeft;
}

export function parseISOWithCeiledMilliseconds(input: string): Date {
    let date = parseISO(input);

    const match = input.match(/\d{2}:\d{2}:\d{2}(?:\.(\d+))[-+Z]/);
    if (!!match && match[1].length > 3) {
        date = addMilliseconds(date, 1);
    }

    return date;
}
