import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { JobStatistics } from '../../modules/jobs/types';

import './style.scss';

type Props = {
    statistics: JobStatistics;
};

class JobDetailsStats extends React.Component<Props> {
    render() {
        const { statistics } = this.props;

        const displayedKeys = [
            'candidates',
            'candidates_interesting',
            'candidates_not_interesting',
            'candidates_contacted',
            'candidates_forwarded'
        ];

        const listElements = displayedKeys.map((key) => {
            return (
                <li className="JobDetailsStats__item" key={key}>
                    <span className="JobDetailsStats__item-value">{statistics[key]}</span>
                    <span className="JobDetailsStats__item-label">
                        <FormattedMessage id={`JOB_DETAILS_STATS.LABEL.${key}`} />
                    </span>
                </li>
            );
        });

        return (
            <div>
                <ul className="JobDetailsStats__items">{listElements}</ul>
            </div>
        );
    }
}

export default JobDetailsStats;
