import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import HideCandidateView from '../../components/HideCandidateView';
import { updateCandidateState } from '../../modules/candidates/actions';
import { Candidate } from '../../modules/candidates/types';
import { canMarkAsNotInteresting } from '../../modules/candidates/utils';
import { getCandidate } from '../../modules/entities/selectors';
import * as localStorage from '../../services/localStorage';
import { State as ApplicationState } from '../../store/reducer';

type ConnectorProps = {
    candidateId: number;
    className?: string;
};

type ConnectedStateProps = {
    candidate: Candidate | null;
};

type ConnectedDispatchProps = {
    updateCandidateState: typeof updateCandidateState;
};

type Props = ConnectedStateProps & ConnectedDispatchProps & ConnectorProps;

type State = {
    showHideCandidateView: boolean;
};

export class CandidateActionHide extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showHideCandidateView: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClick() {
        const doNotShowAgain = localStorage.get('NotShowHideModalAgain');

        this.setState({
            showHideCandidateView: !doNotShowAgain
        });

        if (doNotShowAgain) {
            this.handleSubmit(true);
        }
    }

    handleClose() {
        this.setState({
            showHideCandidateView: false
        });
    }

    handleSubmit(doNotShowAgain: boolean) {
        const { candidateId, updateCandidateState } = this.props;

        if (doNotShowAgain) {
            localStorage.set('NotShowHideModalAgain', true);
        }

        this.handleClose();
        updateCandidateState(candidateId, 'not-interesting');
    }

    render() {
        const { candidate, className } = this.props;
        const { showHideCandidateView } = this.state;

        if (!candidate || !canMarkAsNotInteresting(candidate)) {
            return null;
        }

        return (
            <React.Fragment>
                <Button
                    type="button"
                    onClick={this.handleClick}
                    typeStyle="outlined"
                    variationStyle="destructive"
                    className={className}
                >
                    <FormattedMessage id="CANDIDATE_ACTION_HIDE.LABEL" />
                </Button>

                <HideCandidateView open={showHideCandidateView} onClose={this.handleClose} onHide={this.handleSubmit} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => ({
    candidate: getCandidate(state, props.candidateId)
});

const mapDispatchToProps = {
    updateCandidateState
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateActionHide);
