import * as React from 'react';

export type JobFormTasksListItemFormFieldProps = {
    onClick: (event: React.MouseEvent) => void;
};

function JobFormTasksListItemFormField({ onClick }: JobFormTasksListItemFormFieldProps) {
    return (
        <button
            type="button"
            onClick={onClick}
            className="JobFormTasksListItemFormField__action JobFormTasksListItemFormField__action--save"
            data-testid="job-tasks-list-item-save-button"
        >
            <svg
                className="JobFormTasksListItemFormField__action-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    fillRule="evenodd"
                    d="M21.2883334,6.49999997 L18.9616667,4.16666663 C18.8834965,4.08829854 18.7773561,4.04425514 18.6666667,4.04425514 C18.5559773,4.04425514 18.4498369,4.08829854 18.3716667,4.16666663 L8.54499998,14 C8.46682976,14.0783681 8.36068937,14.1224115 8.24999998,14.1224115 C8.1393106,14.1224115 8.0331702,14.0783681 7.95499998,14 L5.6283333,11.6666667 C5.55016308,11.5882986 5.44402268,11.5442551 5.3333333,11.5442551 C5.22264392,11.5442551 5.11650352,11.5882986 5.0383333,11.6666667 L2.71166662,14 C2.54900748,14.1627083 2.54900748,14.4264584 2.71166662,14.5891667 L7.95499998,19.8333334 C8.0331702,19.9117015 8.1393106,19.9557449 8.24999998,19.9557449 C8.36068937,19.9557449 8.46682976,19.9117015 8.54499998,19.8333334 L21.2883334,7.08333331 C21.4471429,6.92131518 21.4471429,6.6620181 21.2883334,6.49999997 Z"
                />
            </svg>
        </button>
    );
}

export default JobFormTasksListItemFormField;
