import { createSelector } from 'reselect';

import { getFetchStatus as getUserFetchStatus } from '../users/selectors';

import { AGREEMENT_CONTRACT_GTC } from './constants';

import { MOUNT } from './reducer';

// Types
import { State } from '../../store/reducer';
import { AgreementContract } from './types';
import { AgreementsState } from './reducer';

function getState(state: State): AgreementsState {
    return state[MOUNT];
}

export const getLegalAgreementEnabled = createSelector(getState, (state) => state.isEnabled);

function getAgreementContract(state: State, contract: AgreementContract) {
    return getState(state)[contract];
}

function getAgreementContractUpdateStatus(state: State, contract: AgreementContract) {
    return getAgreementContract(state, contract).updateStatus;
}

export const isAcceptingAgreementContracts = createSelector(
    (state: State) => getAgreementContractUpdateStatus(state, AGREEMENT_CONTRACT_GTC),
    getUserFetchStatus,

    (gtcUpdateStatus, userFetchStatus) => {
        if (gtcUpdateStatus === 'updating') {
            return true;
        }

        return gtcUpdateStatus === 'updated' && userFetchStatus === 'loading';
    }
);
