import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import CandidateResumeSection from '../CandidateResumeSection';
import CandidateResumeTagItem from '../CandidateResumeTagItem';

import { Applicant } from '../../../modules/candidates/types';

type Props = {
    applicant: Applicant;
};

const ResumeInterests = ({ applicant }: Props) => {
    const { resume } = applicant;
    const interests = resume.interests;

    if (interests.length === 0) {
        return null;
    }

    return (
        <CandidateResumeSection>
            <h1>
                <FormattedMessage id="RESUME_INTERESTS.TITLE" />
            </h1>

            <ul>
                {interests.map((interest, index) => (
                    <CandidateResumeTagItem key={index}>
                        <span>{interest.interest}</span>
                    </CandidateResumeTagItem>
                ))}
            </ul>
        </CandidateResumeSection>
    );
};

export default ResumeInterests;
