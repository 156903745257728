import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import Headline from '../../components/Headline';
import HeadlineSubtitle from '../../components/Headline/HeadlineSubtitle';
import HeadlineTitle from '../../components/Headline/HeadlineTitle';
import JobDetailsCreateAccount from '../../components/JobDetailsCreateAccount';
import JobDetailsMeta from '../../components/JobDetailsMeta';
import JobDetailsStats from '../../components/JobDetailsStats';
import { getJob } from '../../modules/entities/selectors';
import { loadJob } from '../../modules/jobs/actions';
import { Job } from '../../modules/jobs/types';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { Recruiter } from '../../modules/recruiters/types';
import { State as ApplicationState } from '../../store/reducer';
import JobDetailsActions from '../JobDetailsActions';

import PageContainer from '../../components/PageContainer';
import styles from './JobDetailsPage.module.scss';

type PossibleRouteParams = {
    jobId: string;
};

type ConnectorProps = RouteComponentProps<PossibleRouteParams>;

type ConnectedStateProps = {
    recruiter: Recruiter | null;

    jobId: number;
    job: Job | null;
};

type ConnectedDispatchProps = {
    loadJob: typeof loadJob;
};

type ConnectProps = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type Props = ConnectProps;

function JobDetailsPage({
    recruiter,
    jobId,
    job,

    loadJob
}: Props) {
    useEffect(() => {
        loadJob(jobId);
    }, [jobId]);

    if (!recruiter || !job) {
        return null;
    }

    return (
        <PageContainer className={styles.root}>
            <Headline className={styles.headline}>
                <HeadlineSubtitle>
                    <FormattedMessage id="JOB_DETAILS_PAGE.PRE_TITLE" />
                </HeadlineSubtitle>
                <HeadlineTitle>
                    {job.title || (
                        <span className={styles.fallbackTitle}>
                            <FormattedMessage id="JOB_DETAILS_PAGE.JOB_TITLE_FALLBACK" />
                        </span>
                    )}
                </HeadlineTitle>
            </Headline>
            <JobDetailsMeta job={job} />
            <div className={styles.actions}>
                <JobDetailsActions job={job} />
            </div>
            <div className={styles.statistics}>
                <JobDetailsStats statistics={job.statistics} />
            </div>
            <div className={styles.createAccount}>
                <JobDetailsCreateAccount recruiterIsSignedUp={recruiter.signed_up} />
            </div>
        </PageContainer>
    );
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => {
    const jobId = parseInt(props.match.params.jobId);

    return {
        jobId,
        job: getJob(state, jobId),
        recruiter: getRecruiter(state)
    };
};
const mapDispatchToProps: ConnectedDispatchProps = {
    loadJob: loadJob
};

const withStore = connect<ConnectedStateProps, ConnectedDispatchProps, ConnectorProps, ApplicationState>(
    mapStateToProps,
    mapDispatchToProps
);

export default withStore(JobDetailsPage);
