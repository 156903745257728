import * as React from 'react';
import classnames from 'classnames';

import './PortraitPicture.style.scss';

type Props = {
    firstName: string;
    lastName: string;

    size?: 'xs' | 'md' | 'lg';
    url: string | null;
};

const PortraitPicture = (props: Props) => {
    const { url, size, firstName, lastName } = props;

    if (!firstName || !lastName) {
        return null;
    }

    let portrait;
    if (!!url) {
        portrait = <img className="PortraitPicture__image" src={url} />;
    } else {
        const nameInitials = firstName.substr(0, 1) + lastName.substr(0, 1);
        portrait = (
            <div className="PortraitPicture__initials">
                <div>{nameInitials}</div>
            </div>
        );
    }

    const className = classnames('PortraitPicture', {
        [`PortraitPicture--${size}`]: !!size
    });

    return (
        <div className={className}>
            <div className="PortraitPicture__frame thumbnail">{portrait}</div>
        </div>
    );
};

export default PortraitPicture;
