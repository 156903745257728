import * as React from 'react';
import { connect } from 'react-redux';

import CandidateDetails from '../../components/CandidateDetails';
import ForwardCandidateView from '../../components/ForwardCandidateView';
import { isUnprocessedOrNotInteresting } from '../../modules/candidates/utils';
import { forwardCandidate } from '../../modules/candidates/actions';
import { getCandidate } from '../../modules/entities/selectors';
import { getForwardState } from '../../modules/candidates/selectors';
import { Candidate } from '../../modules/candidates/types';
import { ForwardStatus } from '../../modules/candidates/reducer';
import { State as ApplicationState } from '../../store/reducer';

import forwardArrowIcon from '../../assets/images/ic_forward-arrow.svg';

import './CandidateDetailsHeader.style.scss';

type ForwardEmailData = {
    email: string;
    subject: string;
    body: string;
};

type ConnectorProps = {
    candidateId: number;
};

type ConnectedStateProps = {
    candidate: Candidate | null;
    forwardStatus: ForwardStatus;
};

type ConnectedDispatchProps = {
    forwardCandidate: typeof forwardCandidate;
};

type Props = ConnectedStateProps & ConnectedDispatchProps & ConnectorProps;

type State = {
    showForwardView: boolean;
};

export class CandidateDetailsHeader extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showForwardView: false
        };

        this.handleClickForward = this.handleClickForward.bind(this);
        this.handleCancelForward = this.handleCancelForward.bind(this);
        this.handleSubmitForward = this.handleSubmitForward.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.forwardStatus === 'sending' && this.props.forwardStatus === 'sent') {
            this.setState({
                showForwardView: false
            });
        }
    }

    handleClickForward() {
        this.setState({
            showForwardView: true
        });
    }

    handleCancelForward() {
        this.setState({
            showForwardView: false
        });
    }

    handleSubmitForward({ email, subject, body }: ForwardEmailData) {
        const { forwardCandidate, candidate } = this.props;

        if (!!candidate) {
            forwardCandidate(candidate.id, email, subject, body);
        }
    }

    renderForwardButton() {
        const { candidate } = this.props;

        if (!candidate) {
            return null;
        }

        if (isUnprocessedOrNotInteresting(candidate)) {
            return null;
        }

        return (
            <button className="CandidateDetailsHeader__forward-button" onClick={this.handleClickForward}>
                <img
                    src={forwardArrowIcon}
                    role="presentation"
                    className="CandidateDetailsHeader__forward-button-icon"
                />
            </button>
        );
    }

    render() {
        const { candidate, forwardStatus } = this.props;
        const { showForwardView } = this.state;

        return (
            <div>
                <CandidateDetails candidate={candidate} skeleton={!candidate} appendName={this.renderForwardButton()} />

                <ForwardCandidateView
                    open={showForwardView}
                    onClose={this.handleCancelForward}
                    onForward={this.handleSubmitForward}
                    loading={forwardStatus === 'sending'}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => ({
    candidate: getCandidate(state, props.candidateId),
    forwardStatus: getForwardState(state, props.candidateId)
});

const mapDispatchToProps = {
    forwardCandidate
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailsHeader);
