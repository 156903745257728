import enTranslationMessages from '../../translations/en.json';
import deTranslationMessages from '../../translations/de.json';
import { Locale } from '../locale';

import type { Messages } from './types';
export type { Messages };

export const allMessages: Record<Locale, Messages> = {
    en: {
        ...enTranslationMessages
    },
    de: {
        ...deTranslationMessages
    }
};
