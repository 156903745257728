import React from 'react';
import classNames from 'classnames';
import { Button } from '@truffls/design-system-react';
import { FormattedMessage } from 'react-intl';

import { Job } from '../../../modules/jobs/types';
import { isActive, isArchived, isDraft, isNew } from '../../../modules/jobs/utils';
import LoaderButtonV2 from '../../../components/LoaderButtonV2';

import {
    JobFormPageSubmissionType,
    JOB_FORM_PAGE_SUBMISSION_TYPE_CHECKOUT,
    JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE,
    JOB_FORM_PAGE_SUBMISSION_TYPE_VIEW_IN_APP
} from '../JobFormPage.constants';

import './JobFormPageActions.scss';

export type JobFormPageActionsProps = {
    fixed?: boolean;
    job: Job;
    invalid: boolean;
    submitting: boolean;
    submissionType: JobFormPageSubmissionType | null;
    setSubmissionType: (submissionType: JobFormPageSubmissionType) => void;
    onCancel: () => void;
};

function JobFormPageActions({
    fixed = false,
    job,
    // invalid,
    submitting,
    submissionType,
    setSubmissionType,
    onCancel
}: JobFormPageActionsProps) {
    const active = isActive(job);
    const notActive = isNew(job) || isDraft(job) || isArchived(job);

    const canSaveForPublish = isNew(job) || isDraft(job) || isArchived(job) || isActive(job);

    const canSaveAndClose = notActive;
    const canSaveAndCheckout = notActive && canSaveForPublish;
    const canSaveAndViewInApp = isActive(job);
    const canSaveAndPreviewInApp = isNew(job) || isDraft(job);
    const canCancel = active;
    const canApplyChanges = active && canSaveForPublish;

    const isSaveAndCloseSubmitting = submitting && submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE;
    const isSaveAndCheckoutSubmitting = submitting && submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_CHECKOUT;
    const isSaveAndViewInAppSubmitting = submitting && submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_VIEW_IN_APP;
    const isApplyChangesSubmitting = submitting && submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE;

    const handleClickCheckoutSubmitButton = () => {
        if (submitting) {
            return;
        }

        setSubmissionType(JOB_FORM_PAGE_SUBMISSION_TYPE_CHECKOUT);
    };

    const handleClickCloseSubmitButton = () => {
        if (submitting) {
            return;
        }

        setSubmissionType(JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE);
    };

    const handleClickPreviewInAppSubmitButton = () => {
        if (submitting) {
            return false;
        }

        setSubmissionType(JOB_FORM_PAGE_SUBMISSION_TYPE_VIEW_IN_APP);
    };

    const handleClickViewInAppSubmitButton = () => {
        if (submitting) {
            return false;
        }

        setSubmissionType(JOB_FORM_PAGE_SUBMISSION_TYPE_VIEW_IN_APP);
    };

    const handleClickCancelButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (submitting) {
            return;
        }

        onCancel();
    };

    return (
        <div
            className={classNames('JobFormPageActions', {
                'JobFormPageActions--fixed': fixed
            })}
        >
            <div className="JobFormPageActions__content">
                {/*
                    This button has to be the first submit button because it's the one that gets used on submission via
                    `Enter`
                */}
                {canSaveAndClose && (
                    <LoaderButtonV2
                        type="submit"
                        onClick={handleClickCloseSubmitButton}
                        loading={isSaveAndCloseSubmitting}
                        typeStyle="raised"
                        variationStyle="normal"
                    >
                        <FormattedMessage id="JOB_FORM_PAGE.ACTIONS.BUTTON_SAVE_AND_CLOSE.LABEL_TEXT" />
                    </LoaderButtonV2>
                )}
                {canSaveAndCheckout && (
                    <LoaderButtonV2
                        type="submit"
                        onClick={handleClickCheckoutSubmitButton}
                        loading={isSaveAndCheckoutSubmitting}
                        typeStyle="raised"
                        variationStyle="brand"
                    >
                        <FormattedMessage id="JOB_FORM_PAGE.ACTIONS.BUTTON_SAVE_AND_CHECKOUT.LABEL_TEXT" />
                    </LoaderButtonV2>
                )}

                {canCancel && (
                    <Button type="button" onClick={handleClickCancelButton} typeStyle="raised" variationStyle="normal">
                        <FormattedMessage id="JOB_FORM_PAGE.ACTIONS.BUTTON_CANCEL.LABEL_TEXT" />
                    </Button>
                )}

                {canApplyChanges && (
                    <LoaderButtonV2
                        type="submit"
                        onClick={handleClickCloseSubmitButton}
                        loading={isApplyChangesSubmitting}
                        typeStyle="raised"
                        variationStyle="brand"
                    >
                        <FormattedMessage id="JOB_FORM_PAGE.ACTIONS.BUTTON_APPLY_CHANGES.LABEL_TEXT" />
                    </LoaderButtonV2>
                )}

                {canSaveAndPreviewInApp && (
                    <LoaderButtonV2
                        type="submit"
                        onClick={handleClickPreviewInAppSubmitButton}
                        loading={isSaveAndViewInAppSubmitting}
                        typeStyle="raised"
                        variationStyle="normal"
                        className="JobFormPageActions__action-preview"
                    >
                        <FormattedMessage id="JOB_FORM_PAGE.ACTIONS.BUTTON_SAVE_AND_PREVIEW_IN_APP.LABEL_TEXT" />
                    </LoaderButtonV2>
                )}
                {canSaveAndViewInApp && (
                    <LoaderButtonV2
                        type="submit"
                        onClick={handleClickViewInAppSubmitButton}
                        loading={isSaveAndViewInAppSubmitting}
                        typeStyle="raised"
                        variationStyle="normal"
                        className="JobFormPageActions__action-preview"
                    >
                        <FormattedMessage id="JOB_FORM_PAGE.ACTIONS.BUTTON_SAVE_AND_VIEW_IN_APP.LABEL_TEXT" />
                    </LoaderButtonV2>
                )}
            </div>
        </div>
    );
}

export default JobFormPageActions;
