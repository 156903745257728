require('./polyfills');

const { getClientConfig } = require('./runtime-config');

const { init: initErrorHandler } = require('./services/errorHandler/init');
const { initialize: initializeAnalytics } = require('./services/analytics');
const setupTracking = require('./services/analytics/setupTracking').default;

const browser = require('./browser');
const loadingOverlay = require('./loadingOverlay');

// Require stylesheets
require('./assets/styles/framework.scss');
require('./assets/styles/icons.scss');
require('./assets/styles/other.scss');
require('./assets/styles/app.scss');

// Require other static files
require('./robots.txt');
require('./VERSION');

main();

///////////////

function main() {
    const browserUnsupportedMessage = document.getElementById('browser-unsupported-message');

    if (browser.isSupported()) {
        // Remove the message because the browser is supported
        if (!!browserUnsupportedMessage) {
            browserUnsupportedMessage.remove();
        }

        loadingOverlay.show();
        run();
    } else {
        // Show message for unsupported browser;
        if (!!browserUnsupportedMessage) {
            browserUnsupportedMessage.style.display = 'block';
        }
    }
}

async function run() {
    initErrorHandler();
    initializeAnalytics();
    setupTracking(getClientConfig());

    require('./diagnostics');

    const { polyfill: polyfillIntl } = require('./polyfills/intl');

    await polyfillIntl();

    require('./i18n/index');

    const React = require('react');
    const ReactDOM = require('react-dom');

    const ApplicationRoot = require('./containers/Application/ApplicationRoot').default;

    const appElement = document.getElementById('app');
    ReactDOM.render(<ApplicationRoot />, appElement);
}

export {};
