import * as React from 'react';

import './MessageItemAttachment.style.scss';

import { ConversationParticipant } from '../../modules/conversations/types';
import { File } from '../../modules/files/types';
import { getFileTypeFromMimeType, getFormattedFileName } from '../../modules/files/utils';
import { formatFileSize } from '../../utils/formatter';

import MessageItemWrapper from '../MessageItemWrapper';
import DocumentTypeIcon from '../DocumentTypeIcon';

type Props = {
    file: File;
    fromMe?: boolean;
    owner: ConversationParticipant;
    onClickDownload: () => void;
};

class MessageItemAttachment extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleClickDownload = this.handleClickDownload.bind(this);
    }

    handleClickDownload(event: React.MouseEvent) {
        event.preventDefault();

        if (!!this.props.onClickDownload) {
            this.props.onClickDownload();
        }
    }

    render() {
        const { file, fromMe, owner } = this.props;

        const fileType = getFileTypeFromMimeType(file.mime_type);
        const formattedFileName = getFormattedFileName(file);
        const formattedFileSize = formatFileSize(file.size);

        return (
            <MessageItemWrapper className="MessageItemAttachment" fromMe={fromMe} owner={owner}>
                <a onClick={this.handleClickDownload} tabIndex={0}>
                    <div className="MessageItemAttachment__content">
                        <div className="MessageItemAttachment__file-icon">
                            <DocumentTypeIcon type={fileType} />
                        </div>

                        <div className="MessageItemAttachment__file-info">
                            <span className="MessageItemAttachment__file-info-name">{formattedFileName}</span>
                            <span className="MessageItemAttachment__file-info-meta">
                                <span className="MessageItemAttachment__file-info-size">{formattedFileSize}</span>
                            </span>
                        </div>
                    </div>
                </a>
            </MessageItemWrapper>
        );
    }
}

export default MessageItemAttachment;
