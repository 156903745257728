import { combineReducers } from 'redux';

import resetOnLogout from '../../authentication/reducer/resetOnLogout';

import {
    FETCH_CANDIDATES,
    FETCH_CANDIDATE,
    CANDIDATE_UPDATE,
    CANDIDATE_DELETE,
    CANDIDATES_BY_APPLICANT_ID_FETCH,
    FetchCandidatesActions$Success,
    CandidatesByApplicantIdFetchActions$Success,
    FetchCandidateActions$Success,
    CandidateUpdateActions$Success,
    CandidateDeleteActions$Success
} from '../../candidates/actions';
import { omit } from '../../../utils/object';

import {
    EntityTypeEntitesState,
    EntityTypeFetchStatusState,
    EntityTypeUpdateStatusState,
    EntityTypeDeleteStatusState,
    EntityTypeErrorsState,
    EntityTypeStateEntitiesPartial,
    EntityTypeStateFetchStatusPartial,
    EntityTypeStateUpdateStatusPartial,
    EntityTypeStateDeleteStatusPartial
} from './';
import { Action } from '../../types';
import { Candidate, CandidateId } from '../../candidates/types';
import { ApiError } from '../../../services/api/types';
import {
    ConversationFetchActions$Success,
    ConversationPollingActions$Success,
    ConversationUpdateActions$Success,
    CONVERSATION_FETCH,
    CONVERSATION_POLLING,
    CONVERSATION_UPDATE
} from '../../conversations/actions';

export type CandidateEntityTypeState = EntityTypeStateEntitiesPartial<Candidate> &
    EntityTypeStateFetchStatusPartial &
    EntityTypeStateUpdateStatusPartial &
    EntityTypeStateDeleteStatusPartial & {
        errors: EntityTypeErrorsState;
    };

export const entitiesReducer = (
    state: EntityTypeEntitesState<Candidate> = {},
    action:
        | FetchCandidatesActions$Success
        | CandidatesByApplicantIdFetchActions$Success
        | FetchCandidateActions$Success
        | CandidateUpdateActions$Success
        | CandidateDeleteActions$Success
        | ConversationFetchActions$Success
        | ConversationUpdateActions$Success
        | ConversationPollingActions$Success
): EntityTypeEntitesState<Candidate> => {
    switch (action.type) {
        case FETCH_CANDIDATES.SUCCESS:
        case CANDIDATES_BY_APPLICANT_ID_FETCH.SUCCESS: {
            const candidates: Candidate[] = action.payload.data.results;

            return candidates.reduce((state: EntityTypeEntitesState<Candidate>, candidate: Candidate) => {
                return {
                    // Create a weak copy of the current state
                    ...state,

                    // Insert/replace job by id
                    [candidate.id]: candidate
                };
            }, state);
        }

        case FETCH_CANDIDATE.SUCCESS:
        case CANDIDATE_UPDATE.SUCCESS: {
            const candidate = action.payload.data as Candidate;

            return {
                ...state,
                [candidate.id]: candidate
            };
        }

        case CANDIDATE_DELETE.SUCCESS: {
            return omit(state, '' + action.payload.candidateId);
        }

        case CONVERSATION_FETCH.SUCCESS:
        case CONVERSATION_UPDATE.SUCCESS:
        case CONVERSATION_POLLING.SUCCESS: {
            const { conversationId } = action.payload;

            const candidatesRelatedToConversation = Object.values(state).filter((candidate) => {
                return candidate.conversation_id === conversationId;
            });

            return candidatesRelatedToConversation.reduce(
                (state: EntityTypeEntitesState<Candidate>, candidateRelatedToConversation: Candidate) => {
                    return {
                        ...state,
                        [candidateRelatedToConversation.id]: entityReducer(candidateRelatedToConversation, action)
                    };
                },
                state
            );
        }

        default:
            return state;
    }
};

export function entityReducer(
    state: Candidate,
    action: ConversationFetchActions$Success | ConversationUpdateActions$Success | ConversationPollingActions$Success
): Candidate {
    switch (action.type) {
        case CONVERSATION_FETCH.SUCCESS:
        case CONVERSATION_UPDATE.SUCCESS:
        case CONVERSATION_POLLING.SUCCESS: {
            const { conversationId, response } = action.payload;
            const { data: conversation } = response;

            if (state.conversation_id !== conversationId) {
                return state;
            }

            return {
                ...state,
                unread_message_count: conversation.unread_message_count
            };
        }

        default: {
            return state;
        }
    }
}

export const fetchStatusReducer = (
    state: EntityTypeFetchStatusState = {},
    action: Action
): EntityTypeFetchStatusState => {
    let candidateId;

    switch (action.type) {
        case FETCH_CANDIDATE.REQUEST:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'loading'
            };

        case FETCH_CANDIDATE.SUCCESS:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'loaded'
            };

        case FETCH_CANDIDATE.FAILURE:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'failed'
            };

        case CANDIDATE_DELETE.SUCCESS:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'none'
            };

        default:
            return state;
    }
};

export const updateStatusReducer = (
    state: EntityTypeUpdateStatusState = {},
    action: Action
): EntityTypeUpdateStatusState => {
    let candidateId;

    switch (action.type) {
        case CANDIDATE_UPDATE.REQUEST:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'updating'
            };

        case CANDIDATE_UPDATE.SUCCESS:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'updated'
            };

        case CANDIDATE_UPDATE.FAILURE:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'failed'
            };

        case CANDIDATE_DELETE.SUCCESS:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'none'
            };

        default:
            return state;
    }
};

export const deleteStatusReducer = (
    state: EntityTypeDeleteStatusState = {},
    action: Action
): EntityTypeDeleteStatusState => {
    let candidateId;

    switch (action.type) {
        case CANDIDATE_DELETE.REQUEST:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'deleting'
            };

        case CANDIDATE_DELETE.SUCCESS:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'deleted'
            };

        case CANDIDATE_DELETE.FAILURE:
            candidateId = action.payload.candidateId;

            return {
                ...state,
                [candidateId]: 'failed'
            };

        default:
            return state;
    }
};

export const errorsReducer = (state: EntityTypeErrorsState = {}, action: Action): EntityTypeErrorsState => {
    switch (action.type) {
        case FETCH_CANDIDATE.REQUEST:
        case CANDIDATE_UPDATE.REQUEST:
        case CANDIDATE_DELETE.REQUEST: {
            const candidateId = action.payload.candidateId as CandidateId;

            return {
                ...state,
                [candidateId]: null
            };
        }

        case FETCH_CANDIDATE.FAILURE:
        case CANDIDATE_UPDATE.FAILURE:
        case CANDIDATE_DELETE.FAILURE: {
            const candidateId = action.payload.candidateId;
            const errors: ApiError[] = action.payload.errors;

            return {
                ...state,
                [candidateId]: errors
            };
        }

        default: {
            return state;
        }
    }
};

const candidatesReducer = combineReducers<CandidateEntityTypeState>({
    entities: entitiesReducer,
    fetchStatus: fetchStatusReducer,
    updateStatus: updateStatusReducer,
    deleteStatus: deleteStatusReducer,
    errors: errorsReducer
});

export default resetOnLogout(candidatesReducer);
