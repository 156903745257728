import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Headline from '../../components/Headline';
import HeadlineTitle from '../../components/Headline/HeadlineTitle';

function NotFoundPage() {
    return (
        <div className="container" data-testid="not-found-page">
            <Headline center>
                <HeadlineTitle>
                    <FormattedMessage id="NOT_FOUND_PAGE.TITLE" />
                </HeadlineTitle>
            </Headline>
        </div>
    );
}

export default NotFoundPage;
