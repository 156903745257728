import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { State as AppState } from '../../store/reducer';

import { isAuthenticated } from '../../modules/authentication/selectors';
import { logout } from '../../modules/authentication/actions';

import { showNavigation, hideNavigation } from '../../modules/ui/actions';

import SignupPageNavigation from './SignupPageNavigation';
import SignupPageLayout from './SignupPageLayout';
import SignupPageContent from './SignupPageContent';
import SignupPageReferenceCompanies from './SignupPageReferenceCompanies';
import SignupPageFooter from './SignupPageFooter';

import { getLoginRoute } from '../../routes';

import './SignupPage.style.scss';

type Props = RouteComponentProps & {
    authenticated: boolean;

    showNavigation: typeof showNavigation;
    hideNavigation: typeof hideNavigation;
    logout: typeof logout;
};

function SignupPage({
    history,

    authenticated,

    hideNavigation,
    showNavigation,
    logout
}: Props) {
    React.useEffect(() => {
        hideNavigation();

        return () => {
            showNavigation();
        };
    }, []);

    const handleOpenLogin = () => {
        if (authenticated) {
            logout();
        }

        history.push(getLoginRoute());
    };

    return (
        <div className="SignupPage">
            <SignupPageNavigation hideLinks={false} onOpenLogin={handleOpenLogin} />

            <SignupPageLayout>
                <div className="SignupPage__content">
                    <SignupPageContent />
                </div>
                <SignupPageReferenceCompanies />
                <SignupPageFooter />
            </SignupPageLayout>
        </div>
    );
}

const mapStateToProps = (state: AppState) => ({
    authenticated: isAuthenticated(state)
});

const mapDispatchToProps = {
    showNavigation,
    hideNavigation,

    logout
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);
