import { all, spawn, race, take, call, put, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { startSubmit, stopSubmit, formValueSelector } from 'redux-form';

import { getHistory } from '../../history';
import { getLoginRoute } from '../../routes';

import { createLogger } from '../../utils/logger';

import { ResponseError } from '../../services/api/types';
import { hasErrorWithCode } from '../../services/api/utils';

import { handleError } from '../../services/errorHandler';

import { signup, SIGNUP_REQUEST, requestRequestToken, logout } from '../../modules/authentication/actions';
import { isAuthenticated } from '../../modules/authentication/selectors';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { isSignedUp } from '../../modules/recruiters/utils';
import { Recruiter } from '../../modules/recruiters/types';

import { FORM_NAME, EMAIL_TAKEN_DECISION_REQUEST_TOKEN } from './constants';
import {
    START,
    CANCEL,
    SET_STEP,
    SUBMIT,
    SET_EMAIL_TAKEN_DECISION,
    resetEmailTakenDecision,
    OPEN_LOGIN
} from './actions';

import { trackSignupStart, trackSignupStep, trackSignupSuccess } from '../../services/analytics';

// Types
import { Step } from './constants';

const logger = createLogger('containers/SignupForm/saga');

export const STOP = Symbol();

export function* signupFormSaga(): SagaIterator<void> {
    while (true) {
        logger.debug('Wait for signup form cancel, step or submit ...');

        const { cancelAction, openLoginAction, stepAction, submitAction } = yield race({
            cancelAction: take(CANCEL),
            openLoginAction: take(OPEN_LOGIN),
            stepAction: take(SET_STEP),
            submitAction: take(SUBMIT)
        });

        if (!!openLoginAction) {
            logger.debug('... received open login aciton');

            yield call(handleOpenLogin);

            return;
        }

        // User canceled the signup
        if (!!cancelAction) {
            logger.debug('... received cancel action');
            return;
        }

        // User continued to the next step
        if (!!stepAction) {
            logger.debug('... received step action');

            yield call(handleStepSaga, stepAction.payload.step);
        }

        if (!!submitAction) {
            logger.debug('... received submit action');
            const result = yield call(handleSubmissionSaga, submitAction.payload.values);

            // Check if we should stop the flow
            if (result === STOP) {
                return;
            }
        }
    }
}

export function* handleOpenLogin(): SagaIterator<void> {
    const authenticated = select(isAuthenticated);

    if (authenticated) {
        yield put(logout(undefined, true));
    }

    const history = getHistory();
    history.push(getLoginRoute());
}

export function* handleStepSaga(step: Step): SagaIterator<void> {
    yield call(trackSignupStep, step);
}

export function* handleSubmissionSaga(values: Object): SagaIterator<typeof STOP | void> {
    logger.debug('Wait for submission completing or cancel ...');

    yield put(startSubmit(FORM_NAME));

    yield put(signup(values));
    const { successAction, failureAction, cancelAction } = yield race({
        successAction: take(SIGNUP_REQUEST.SUCCESS),
        failureAction: take(SIGNUP_REQUEST.FAILURE),
        cancelAction: take(CANCEL)
    });

    // If the user canceled the signup during submission we want to stop the execution
    if (!!cancelAction) {
        logger.debug('... received cancel action');
        return STOP;
    }

    /* If the signup was successful we want to stop the flow because the flow is done - otherwise we
     * want to watch for other actions.
     */
    if (!!successAction) {
        logger.debug('... submission completed');

        yield call(handleSuccessfulSubmissionSaga);

        return STOP;
    }

    if (!!failureAction) {
        logger.debug('... submission failed');

        yield call(handleFailedSubmissionSaga, failureAction.payload.errors);
    }
}

export function* handleSuccessfulSubmissionSaga(): SagaIterator<void> {
    yield put(stopSubmit(FORM_NAME));

    yield put(logout(undefined, true));

    yield call(trackSignupSuccess);
}

export function* handleFailedSubmissionSaga(errorsFromServer: ResponseError[]): SagaIterator<void> {
    if (hasErrorWithCode(errorsFromServer, '16010')) {
        yield put(stopSubmit(FORM_NAME, {}));

        yield call(handleEmailAlreadyTakenErrorSaga);
    } else {
        const errorFromServer = errorsFromServer[0];
        const errorFormServerTitle = !!errorFromServer.title ? errorFromServer.title.split(' ').join('') : 'Unknown';

        const errorToTrack: Error = new Error();
        errorToTrack.name = errorFormServerTitle + 'Error';
        errorToTrack.message = `${!!errorFromServer.code ? `${errorFromServer.code} - ` : ''}${
            errorFromServer.detail || errorFromServer.title
        }`;

        handleError(errorToTrack);

        yield put(
            stopSubmit(FORM_NAME, {
                _error: true
            })
        );
    }
}

export function* handleEmailAlreadyTakenErrorSaga(): SagaIterator<void> {
    yield put(resetEmailTakenDecision());

    const action = yield take(SET_EMAIL_TAKEN_DECISION);

    if (action.payload.decision === EMAIL_TAKEN_DECISION_REQUEST_TOKEN) {
        const getFieldValue = formValueSelector(FORM_NAME);
        const submittedEmail = yield select(getFieldValue, 'email');

        yield put(requestRequestToken(submittedEmail));
    }
}

export function* watchSignupFormSaga(): SagaIterator<void> {
    while (true) {
        logger.debug('Wait for signup form start ...');
        yield take(START);
        logger.debug('... receive start action');

        const recruiter: Recruiter | null = yield select(getRecruiter);
        const forTrial = !!recruiter && !isSignedUp(recruiter);

        yield call(trackSignupStart, forTrial);

        yield call(signupFormSaga);
    }
}

export default function* rootSaga(): SagaIterator<void> {
    yield all([spawn(watchSignupFormSaga)]);
}
