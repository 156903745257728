import * as React from 'react';
import classNames from 'classnames';

import './style.scss';

type Props = {
    leftTab: React.ReactNode;
    rightTab: React.ReactNode;
    activeTab: 'left' | 'right';
};

const MobileAppTabBar = (props: Props): React.ReactElement => {
    return (
        <div className="MobileAppTabBar">
            <div
                className={classNames('MobileAppTabBar__tab', {
                    'MobileAppTabBar__tab--selected': props.activeTab === 'left'
                })}
            >
                {props.leftTab}
            </div>
            <div
                className={classNames('MobileAppTabBar__tab', {
                    'MobileAppTabBar__tab--selected': props.activeTab === 'right'
                })}
            >
                {props.rightTab}
            </div>
        </div>
    );
};

export default MobileAppTabBar;
