import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Transition, animated } from 'react-spring/renderprops';

import './TutorialDesktopContentText.style.scss';

import { TransitionAnimationKeyframes, emptyTransition } from './animations';

type Props = {
    open: boolean;
    step: number;
    goingRight: boolean;
};

const TutorialDesktopContentText = ({ open, step, goingRight }: Props) => (
    <Transition
        native
        initial={null}
        items={step}
        from={step >= 1 || !goingRight ? TransitionAnimationKeyframes.from(goingRight) : emptyTransition}
        enter={step >= 1 || !goingRight ? TransitionAnimationKeyframes.enter : emptyTransition}
        leave={open ? TransitionAnimationKeyframes.leave(goingRight) : emptyTransition}
    >
        {() => ({ left, ...styleProps }) => (
            <animated.div
                className="TutorialDesktopContentText"
                style={{
                    ...styleProps,
                    transform: left.interpolate((x) => `translateX(${x}px)`)
                }}
            >
                <h1 className="TutorialDesktopContentText__title">
                    <FormattedMessage id={`TUTORIAL.STEP_${step}.TITLE`} />
                </h1>
                <p className="TutorialDesktopContentText__paragraph">
                    <FormattedMessage id={`TUTORIAL.STEP_${step}.PARAGRAPH`} />
                </p>
            </animated.div>
        )}
    </Transition>
);

export default TutorialDesktopContentText;
