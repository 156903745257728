import { combineReducers } from 'redux';

import resetOnLogout from '../../authentication/reducer/resetOnLogout';

import { Action } from '../../types';

import { SEARCH_RESULTS_FETCH } from '../../search/actions';
import { SearchResult } from '../../search/types';

import { EntityTypeEntitesState, EntityTypeStateEntitiesPartial } from './';

export type SearchResultEntityTypeState = EntityTypeStateEntitiesPartial<SearchResult>;

export const entitiesReducer = (
    state: EntityTypeEntitesState<SearchResult> = {},
    action: Action
): EntityTypeEntitesState<SearchResult> => {
    switch (action.type) {
        case SEARCH_RESULTS_FETCH.SUCCESS:
            const templates: SearchResult[] = action.payload.data.results;

            return templates.reduce((state, template) => {
                return {
                    ...state,
                    [template.id]: template
                };
            }, state);

        default:
            return state;
    }
};

const searchResultsReducer = combineReducers<SearchResultEntityTypeState>({
    entities: entitiesReducer
});

export default resetOnLogout(searchResultsReducer);
