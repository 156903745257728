import { Recruiter } from './types';

export function isSignedUp(recruiter: Recruiter): boolean {
    return recruiter.signed_up;
}

export function getInitials(recruiter: Recruiter): string {
    if (!!recruiter.first_name && !!recruiter.last_name) {
        return (recruiter.first_name[0] + recruiter.last_name[0]).toUpperCase();
    } else {
        // XXX: We use the the first letter of the recruiters email if he has no names provided (temp recruiter)
        return recruiter.email[0].toUpperCase();
    }
}

export function getFullNameOrEmail(recruiter: Recruiter): string {
    if (!!recruiter.first_name && !!recruiter.last_name) {
        return `${recruiter.first_name} ${recruiter.last_name}`;
    } else {
        return recruiter.email;
    }
}

export function isRecruiterPPH(recruiter: Recruiter): boolean {
    return recruiter.pph;
}

export function hasCompanyLogo(recruiter: Recruiter): boolean {
    return recruiter.company_logo_file !== null;
}

export function hasBackgroundImage(recruiter: Recruiter): boolean {
    return recruiter.background_file !== null;
}

export function hasGalleryImage(recruiter: Recruiter): boolean {
    return recruiter.gallery_images.length >= 1;
}
