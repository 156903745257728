function getElement(): HTMLElement | null {
    return document.querySelector('#initial-loading-overlay');
}

export function show() {
    const element = getElement();
    if (!!element) {
        element.style.display = 'block';
    }
}

export function hide() {
    const element = getElement();
    if (!!element) {
        element.style.display = 'none';
    }
}
