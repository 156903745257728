import { combineReducers } from 'redux';

import { createMapReducer } from '../../utils/redux';

import { CreateStatus } from '../types';

import resetOnLogout from '../authentication/reducer/resetOnLogout';
import { CHECKOUT_CREATE } from './actions';
import { CREATE_STATUS } from '../constants';

export const MOUNT: 'checkout' = 'checkout';

export type State = {
    createStatus: CreateStautesState;
};

type CreateStatusState = CreateStatus;

export const initialCreateStatus = CREATE_STATUS.NONE;
function createStatusReducer(state: CreateStatusState = initialCreateStatus, action): CreateStatusState {
    switch (action.type) {
        case CHECKOUT_CREATE.REQUEST:
            return CREATE_STATUS.CREATING;

        case CHECKOUT_CREATE.SUCCESS:
            return CREATE_STATUS.CREATED;

        case CHECKOUT_CREATE.FAILURE:
            return CREATE_STATUS.FAILED;

        default:
            return state;
    }
}

type CreateStautesState = {
    [key: string]: CreateStatusState;
};

const createStatusesReducer = createMapReducer(createStatusReducer, (action) => {
    switch (action.type) {
        case CHECKOUT_CREATE.REQUEST:
        case CHECKOUT_CREATE.SUCCESS:
        case CHECKOUT_CREATE.FAILURE:
            return action.payload.localId;

        default:
            return null;
    }
});

const checkoutReducer = combineReducers({
    createStatus: createStatusesReducer
});

export default resetOnLogout(checkoutReducer);
