import * as React from 'react';
import classNames from 'classnames';

import { WrappedFieldMetaProps } from 'redux-form';

type Props = {
    children: React.ReactNode;
    className?: string;

    meta: WrappedFieldMetaProps;

    component?: string | React.ComponentType<any>;
    labelComponent?: string | React.ComponentType<any>;

    value?: any;
    label?: any;
    labelAppendix?: any;
    helpText?: any;
    errors?: {
        [index: string]: React.ReactNode;
    };

    toggleElement?: React.ReactElement<any>;
};

const hasSuccess = (value: any, meta: WrappedFieldMetaProps): boolean => {
    return !!value && meta.valid;
};

const hasError = (value: any, meta: WrappedFieldMetaProps): boolean => {
    return (!meta.valid && meta.touched) || (!!value && !meta.valid);
};

const FormElement = (props: Props) => {
    const {
        children,
        toggleElement,
        className,

        meta,

        component: ElementComponent = 'div',
        label,
        labelComponent: LabelComponent = 'label',
        labelAppendix,
        value,
        helpText,
        errors
    } = props;

    let labelElement: React.ReactNode = null;
    if (!!label) {
        labelElement = <LabelComponent className="tf-form-element__label">{label}</LabelComponent>;
    }

    let control = children;

    if (!!toggleElement) {
        control = React.cloneElement(toggleElement, {
            children: control
        });
    }

    let helpOrErrorText;

    if (!!errors && !!meta.error && !!meta.touched && !!meta.dirty) {
        helpOrErrorText = errors[meta.error];
    } else {
        helpOrErrorText = helpText;
    }

    return (
        <ElementComponent
            className={classNames('tf-form-element', className, {
                'tf-has-success': hasSuccess(value, meta),
                'tf-has-error': hasError(value, meta)
            })}
        >
            {labelElement}
            {labelAppendix}
            <div className="tf-form-element__control">{control}</div>
            {helpOrErrorText && <div className="tf-form-element__help-text">{helpOrErrorText}</div>}
        </ElementComponent>
    );
};

export default FormElement;
