import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useFieldArray } from 'react-final-form-arrays';

import JobFormFieldAnchor from '../JobFormFieldAnchor';
import JobFormTasksFormFields, {
    JOB_EDIT_TASKS_FORM_FIELDS_NAME,
    JobFormTasksFormFieldsValue
} from './JobFormTasksFormFields';
import { hasError } from '../../../utils/form/state';

export type JobFormTasksFormFieldsetValues = {
    tasks: JobFormTasksFormFieldsValue;
};

export type JobFormTasksFormFieldsetProps = {};

function JobFormTasksFormFieldset({}: JobFormTasksFormFieldsetProps) {
    const tasksField = useFieldArray(JOB_EDIT_TASKS_FORM_FIELDS_NAME);

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_TASKS_FORM_FIELDS_NAME} />
            <fieldset
                className={classNames('panel tasks', {
                    'has-error': hasError(tasksField.fields.value, tasksField.meta)
                })}
            >
                <div className="panel-heading">
                    <h2 className="panel-title">
                        <span className="control-label">
                            <FormattedMessage id="JOB_EDIT.TASKS_FORM_FIELDSET.TITLE" />
                        </span>
                    </h2>
                    <span className="panel-subtitle">
                        <span className="control-label">
                            <FormattedMessage id="JOB_EDIT.TASKS_FORM_FIELDSET.SUBTITLE" />
                        </span>
                    </span>
                </div>
                <div className="panel-body">
                    <JobFormTasksFormFields />
                </div>
            </fieldset>
        </React.Fragment>
    );
}

export default JobFormTasksFormFieldset;
