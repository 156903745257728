import * as React from 'react';
import { useIntl } from 'react-intl';

import { ProductV2, ProductV2Id } from '../../modules/checkout/types';

import ProductInteractive from '../ProductInteractive';
import ProductPrice from '../ProductPrice';
import ProductPromoteText from '../ProductPromoteText';
import ProductTable from '../ProductTable';

export type ProductsProps = {
    products: ProductV2[];

    selectedProductId: ProductV2Id | null;
    onSelectProduct: (productId: ProductV2Id) => void;
    disableNotSelectedProducts?: boolean;
    actionType?: 'select' | 'continue';
    subtitleClassName?: string;
};

function Products({
    products,

    selectedProductId,
    onSelectProduct,
    disableNotSelectedProducts,
    actionType,
    subtitleClassName
}: ProductsProps) {
    const { locale } = useIntl();

    const productElements = products.map((product: ProductV2) => {
        const localizedContent = product.content(locale);

        const price = !!product.price ? parseInt(product.price, 10) : null;

        const disabled = !!disableNotSelectedProducts && selectedProductId !== null && selectedProductId !== product.id;

        return (
            <ProductInteractive
                key={product.id}
                id={product.id}
                title={localizedContent.title}
                subtitle={localizedContent.subtitle}
                price={!!price ? <ProductPrice value={price} /> : null}
                promote={product.promote && <ProductPromoteText />}
                sections={localizedContent.sections}
                onSelectProduct={onSelectProduct}
                actionType={actionType}
                disabled={disabled}
                forceTopSpacing={true}
                subtitleClassName={subtitleClassName}
            />
        );
    });

    return <ProductTable>{productElements}</ProductTable>;
}

export default Products;
