import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import Headline from '../../components/Headline';
import HeadlineTitle from '../../components/Headline/HeadlineTitle';
import JobCreateSwitcher from '../../components/JobCreateSwitcher';
import JobCreateSectionParse from '../JobCreateSectionParse';
import JobCreateSectionCopy from '../JobCreateSectionCopy';

export type JobCreatePageProps = {};

function JobCreatePage({}: JobCreatePageProps) {
    const match = useRouteMatch();

    return (
        <div className="container">
            <Headline center>
                <HeadlineTitle>
                    <Switch>
                        <Route exact path={`${match.path}`}>
                            <FormattedMessage id="JOB_CREATE_PAGE.TITLE.START" />
                        </Route>
                        <Route exact path={`${match.path}/parse/`}>
                            <FormattedMessage id="JOB_CREATE_PAGE.TITLE.PARSE" />
                        </Route>
                        <Route exact path={`${match.path}/copy/`}>
                            <FormattedMessage id="JOB_CREATE_PAGE.TITLE.COPY" />
                        </Route>
                    </Switch>
                </HeadlineTitle>
            </Headline>
            <JobCreateSwitcher />
            <Switch>
                <Route exact path={`${match.path}/parse/`} component={JobCreateSectionParse} />
                <Route exact path={`${match.path}/copy/`} component={JobCreateSectionCopy} />
            </Switch>
        </div>
    );
}

export default JobCreatePage;
