import { Values } from '../../../../../types/utilities';

import { ERRORS } from './JobFormTasksCreateFormField.constants';

type ValidationContext = {
    items: string[];
};

export function validate(value: string, context: ValidationContext): null | Values<typeof ERRORS> {
    const { items } = context;

    const exists = items.some((item) => {
        return item === value;
    });

    if (exists) {
        return ERRORS.DUPLICATE;
    }

    return null;
}
