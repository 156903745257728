import { SagaIterator } from 'redux-saga';
import { all, call, cancel, fork, put, spawn, take } from 'redux-saga/effects';

import { ERROR_CODE_VALIDATION_ERROR } from '../../services/api/constants';
import { ApiResponse } from '../../services/api/types';
import { hasErrorWithCode, isApiResponseError } from '../../services/api/utils';
import { handleError } from '../../services/errorHandler';
import { Deferred } from '../../utils/promise';

import * as clientInfoActions from './actions';
import * as clientInfoApi from './api';
import { ClientInfo } from './types';

export function* fetchClientInfoSaga(): SagaIterator<ClientInfo> {
    try {
        yield put(clientInfoActions.clientInfoFetch.request());

        const response = yield call(clientInfoApi.fetchClientInfo);
        const clientInfo: ClientInfo = response.data;

        yield put(clientInfoActions.clientInfoFetch.success(clientInfo));

        return clientInfo;
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(clientInfoActions.clientInfoFetch.failure(error.response.data.errors));
        } else {
            yield put(clientInfoActions.clientInfoFetch.failure());
        }

        throw error;
    }
}

export function* updateClientInfoSaga(
    clientInfo: Partial<ClientInfo>,
    { promise }: { promise?: Deferred<ClientInfo> } = {}
): SagaIterator {
    try {
        yield put(clientInfoActions.clientInfoUpdate.request());

        const { data: newClientInfo }: ApiResponse<ClientInfo> = yield call(clientInfoApi.updateClientInfo, clientInfo);

        yield put(clientInfoActions.clientInfoUpdate.success(newClientInfo));

        if (promise) {
            yield call(promise.resolve, newClientInfo);
        }

        return newClientInfo;
    } catch (error) {
        if (isApiResponseError(error)) {
            const errors = error.response.data.errors || [];

            yield put(clientInfoActions.clientInfoUpdate.failure(error.response.data.errors));

            if (promise) {
                yield call(promise.reject, errors);
            }

            const isErrorCausedByUser = hasErrorWithCode(errors, ERROR_CODE_VALIDATION_ERROR);
            const isErrorNotCausedByUser = isErrorCausedByUser === false;

            if (isErrorNotCausedByUser) {
                handleError(error);
            }
        } else {
            yield put(clientInfoActions.clientInfoUpdate.failure());

            if (promise) {
                yield call(promise.reject);
            }

            handleError(error);
        }
    }
}

export function* watchUpdateClientInfoSaga(): SagaIterator<void> {
    let task = null;

    while (true) {
        const action: clientInfoActions.UpdateClientInfoAction = yield take(clientInfoActions.UPDATE_CLIENT_INFO);
        const { clientInfo } = action.payload;
        const { promise } = action.meta;

        if (!!task) {
            yield cancel(task);
        }

        task = yield fork(updateClientInfoSaga, clientInfo, { promise });
    }
}

export default function* rootSaga(): SagaIterator<void> {
    yield all([spawn(watchUpdateClientInfoSaga)]);
}
