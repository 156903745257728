import { ResponseError } from './types';

export class ResponseErrorsCollectionError extends Error {
    errors: ResponseError[];

    constructor(errors: ResponseError[]) {
        super('Multiple response errors thrown');

        this.errors = errors;
    }
}
