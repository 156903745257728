import { createContext, useContext } from 'react';

export type CheckoutProductPageTabContextValue = {
    active: boolean;
    onSelect: () => void;
};

export const CheckoutProductPageTabContext = createContext<CheckoutProductPageTabContextValue | null>(null);

export function useCheckoutProductPageTabContext(): CheckoutProductPageTabContextValue {
    const context = useContext(CheckoutProductPageTabContext);

    if (context === null) {
        throw new Error('useCheckoutProductPageTabContext must be used within a <CheckoutProductPageTabs />');
    }

    return context;
}
