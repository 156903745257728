import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { links } from '../../../constants';
import { ProductV2, ProductV2Id } from '../../../modules/checkout/types';

import Product from '../../../components/Product';
import ProductActionButton from '../../../components/Product/ProductActionButton';
import ProductPrice from '../../../components/ProductPrice';
import ProductPromoteText from '../../../components/ProductPromoteText';
import Products from '../../../components/Products';
import CheckoutProductPageHeadline from '../CheckoutProductPageHeadline';

import classes from './CheckoutProductPageProductCategoryJobAds.module.scss';

export type CheckoutProductPageProductCategoryJobAdsProps = {
    bookableProducts: ProductV2[];
    nonBookableProducts?: ProductV2[];
    selectedProductId: ProductV2Id | null;
    onSelectProduct: (productId: ProductV2Id) => void;
    disableNotSelectedProducts?: boolean;
    actionType?: 'select' | 'continue';
};

function CheckoutProductPageProductCategoryJobAds({
    bookableProducts,
    nonBookableProducts = [],
    selectedProductId,
    onSelectProduct,
    disableNotSelectedProducts,
    actionType
}: CheckoutProductPageProductCategoryJobAdsProps) {
    const { locale } = useIntl();

    return (
        <React.Fragment>
            <CheckoutProductPageHeadline
                subtitle={<FormattedMessage id="CHECKOUT_PRODUCT_PAGE.JOB_AD_PRODUCTS.SUBTITLE" />}
            >
                <FormattedMessage id="CHECKOUT_PRODUCT_PAGE.JOB_AD_PRODUCTS.TITLE" />
            </CheckoutProductPageHeadline>

            <Products
                products={bookableProducts}
                selectedProductId={selectedProductId}
                onSelectProduct={onSelectProduct}
                disableNotSelectedProducts={disableNotSelectedProducts}
                actionType={actionType}
                subtitleClassName={classes.productSubtitle}
            />

            <div className={classes.productNotes}>
                <p className={classes.productNote}>
                    <FormattedMessage
                        id="CHECKOUT_PRODUCT_PAGE.JOB_AD_PRODUCTS.PRODUCT_NOTE"
                        values={{
                            ContactLink: (chunks) => {
                                return (
                                    <a href={links.calendly[locale]} target="_blank" rel="noopener noreferrer">
                                        {chunks}
                                    </a>
                                );
                            }
                        }}
                    />
                </p>
            </div>

            {nonBookableProducts.length > 0 && (
                <div className={classes.nonBookableProducts}>
                    {nonBookableProducts.map((product) => {
                        const localizedContent = product.content(locale);

                        const price = !!product.price ? parseInt(product.price, 10) : null;

                        return (
                            <Product
                                key={product.id}
                                title={localizedContent.title}
                                subtitle={localizedContent.subtitle}
                                price={!!price ? <ProductPrice value={price} /> : null}
                                promote={product.promote && <ProductPromoteText />}
                                sections={localizedContent.sections}
                                action={
                                    <ProductActionButton
                                        component="a"
                                        href={links.calendly[locale]}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <FormattedMessage id="CHECKOUT_CONTACT_BUTTON.LABEL" />
                                    </ProductActionButton>
                                }
                                targetOrientation="horizontal"
                            />
                        );
                    })}
                </div>
            )}
        </React.Fragment>
    );
}

export default CheckoutProductPageProductCategoryJobAds;
