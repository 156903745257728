import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps } from 'react-router-dom';
import { FormattedDate, FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import './CandidateDetailsPage.scss';

import { getCandidateConversationRoute, getCandidatesRoute } from '../../routes';
import { goBackOrFallback } from '../../utils/history';

import NextNewCandidateBanner from '../NextNewCandidateBanner';
import CandidateProfilePage from '../CandidateProfilePage';
import CandidateConversationPage from '../CandidateConversationPage';
import CandidateDocumentsPage from '../CandidateDocumentsPage';
import CandidateDetailsHeader from '../CandidateDetailsHeader';
import CandidateStatePicker from '../CandidateStatePicker';
import CandidateActionHide from '../CandidateActionHide';
import CandidateActionDelete from '../CandidateActionDelete';
import CandidateActionKeepExpiring from '../CandidateActionKeepExpiring';
import CandidateNotesItem from '../CandidateNotesItem';
import PageContainer from '../../components/PageContainer';
import CandidatePageHeader from '../../components/CandidatePageHeader';
import CandidateDetailsPageTabs from '../../components/CandidateDetailsPageTabs';

import CandidateRatingButtons from '../CandidateRatingButtons';
import CandidateSalaryExpecation from '../CandidateSalaryExpecation';
import CandidateAvailability from '../CandidateAvailability';
import CandidateAlsoAppliedFor from '../CandidateAlsoAppliedFor';

import CandidateDetailsRow from './CandidateDetailsRow';
import { candidateNeedsRead } from './CandidateDetailsPage.utils';

import { State as ApplicationState } from '../../store/reducer';

import { FetchStatus, UpdateStatus, DeleteStatus, Errors } from '../../modules/types';
import { FETCH_STATUS } from '../../modules/constants';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { Recruiter } from '../../modules/recruiters/types';
import {
    getJob,
    getJobFetchStatus,
    getCandidate,
    getCandidateFetchStatus,
    getCandidateUpdateStatus,
    getCandidateDeleteStatus,
    getCandidateErrors
} from '../../modules/entities/selectors';
import { Job } from '../../modules/jobs/types';
import { loadJob } from '../../modules/jobs/actions';
import { Candidate } from '../../modules/candidates/types';
import {
    getFetchStatus,
    getOtherCandidatesThatMatchApplicant,
    getOtherCandidatesThatMatchApplicantFetchStatus
} from '../../modules/candidates/selectors';
import {
    loadCandidates,
    loadCandidate,
    updateCandidateState,
    fetchOtherCandidatesThatMatchApplicant
} from '../../modules/candidates/actions';
import {
    isNew,
    isUnprocessedOrNotInteresting,
    canMarkAsNotInteresting,
    canDelete,
    canKeptFromExpiring,
    getCandidateConversationId
} from '../../modules/candidates/utils';
import { getConversation } from '../../modules/conversations/selectors';
import { Conversation } from '../../modules/conversations/types';

type PossibleRouteParams = {
    jobId: string;
    candidateId: string;
};

type ConnectorProps = RouteComponentProps<PossibleRouteParams>;

type ConnectedStateProps = {
    jobId: number;
    candidateId: number;

    recruiter: Recruiter | null;

    job: Job | null;
    jobFetchStatus: string;

    candidate: Candidate | null;
    candidateFetchStatus: FetchStatus;
    candidateUpdateStatus: UpdateStatus;
    candidateDeleteStatus: DeleteStatus;
    candidateErrors: Errors | null;

    conversation: Conversation | null;

    otherCandidatesThatMatchApplicant: Candidate[];
    otherCandidatesThatMatchApplicantFetchStatus: FetchStatus;

    nextCandidateFetchStatus: FetchStatus;
};
type ConnectedDispatchProps = {
    loadJob: typeof loadJob;
    loadCandidate: typeof loadCandidate;
    loadCandidates: typeof loadCandidates;
    fetchOtherCandidatesThatMatchApplicant: typeof fetchOtherCandidatesThatMatchApplicant;
    updateCandidateState: typeof updateCandidateState;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type State = {
    redirectToConversationNeeded: boolean;
};

class CandidateDetailsPage extends React.Component<Props, State> {
    state = {
        redirectToConversationNeeded: true
    };

    componentDidMount() {
        const {
            jobId,
            candidateId,
            jobFetchStatus,
            loadJob,
            candidateFetchStatus,
            loadCandidate,
            candidate,
            nextCandidateFetchStatus,
            loadCandidates
        } = this.props;

        if (jobFetchStatus === 'none') {
            loadJob(jobId);
        }

        if (candidateFetchStatus === 'none') {
            loadCandidate(candidateId);
        }

        this.handleCandidateMarkAsRead(null, this.props);
        this.handleRedirectToConversationPageIfCandidateHasApplicationMessage(this.props);

        if (!!candidate && !isNew(candidate) && nextCandidateFetchStatus !== 'loading') {
            loadCandidates('nextNewForCurrentJob', jobId);
        }

        this.handleFetchOtherCandidatesThatMatchApplicant(null, this.props);
    }

    componentDidUpdate(prevProps: Props) {
        this.handleCandidateMarkAsRead(prevProps, this.props);
        this.handleRedirectToConversationPageIfCandidateHasApplicationMessage(this.props);

        this.handleCandidateDeletionAndNotInteresting(prevProps, this.props);

        this.handleFetchingOfNextNewCandidate(prevProps, this.props);

        this.handleFetchOtherCandidatesThatMatchApplicant(prevProps, this.props);
    }

    handleCandidateMarkAsRead(prevProps: Props | null, props: Props) {
        const prevCandidateStatuses = prevProps
            ? {
                  candidateFetchStatus: prevProps.candidateFetchStatus,
                  candidateUpdateStatus: prevProps.candidateUpdateStatus,
                  candidateIsNew: !!prevProps.candidate ? isNew(prevProps.candidate) : false
              }
            : null;

        const candidateStatuses = {
            candidateFetchStatus: props.candidateFetchStatus,
            candidateUpdateStatus: props.candidateUpdateStatus,
            candidateIsNew: !!props.candidate ? isNew(props.candidate) : false
        };

        if (!candidateNeedsRead(prevCandidateStatuses) && candidateNeedsRead(candidateStatuses)) {
            this.props.updateCandidateState(this.props.candidateId, 'locked');
        }
    }

    handleCandidateDeletionAndNotInteresting(prevProps: Props, nextProps: Props) {
        const prevCandidate = prevProps.candidate;
        const nextCandidate = nextProps.candidate;

        if (
            (prevCandidate &&
                prevCandidate.state !== 'not-interesting' &&
                nextCandidate &&
                nextCandidate.state === 'not-interesting') ||
            (prevProps.candidateDeleteStatus === 'deleting' && nextProps.candidateDeleteStatus === 'deleted')
        ) {
            // redirect to candidates page
            goBackOrFallback(nextProps.history.push, getCandidatesRoute(nextProps.jobId));
        }
    }

    handleFetchingOfNextNewCandidate(prevProps: Props, nextProps: Props) {
        const prevCandidate = prevProps.candidate;
        const nextCandidate = nextProps.candidate;

        if (
            (!prevCandidate &&
                prevProps.candidateFetchStatus === 'loading' &&
                nextProps.candidateFetchStatus === 'loaded') ||
            (!!prevCandidate && isNew(prevCandidate))
        ) {
            if (!!nextCandidate && !isNew(nextCandidate) && nextProps.nextCandidateFetchStatus !== 'loading') {
                this.props.loadCandidates('nextNewForCurrentJob', this.props.jobId);
            }
        }
    }

    handleFetchOtherCandidatesThatMatchApplicant(prevProps: Props | null, nextProps: Props) {
        const { candidateFetchStatus: prevCandidateFetchStatus } = prevProps || {};
        const {
            candidateFetchStatus: nextCandidateFetchStatus,
            candidate: nextCandidate,
            otherCandidatesThatMatchApplicantFetchStatus: nextOtherCandidatesThatMatchApplicantFetchStatus,
            fetchOtherCandidatesThatMatchApplicant
        } = nextProps;

        // We only directly after the candidate was fetched ...
        if (prevCandidateFetchStatus === FETCH_STATUS.LOADING && nextCandidateFetchStatus === FETCH_STATUS.LOADED) {
            // ... and only if the other candidates aren't loading at the moment.
            if (nextOtherCandidatesThatMatchApplicantFetchStatus === FETCH_STATUS.LOADING) {
                return;
            }

            // We've to check this because TypeScript doesn't know that the candidate exists because the
            // candidateFetchStatus is loaded.
            if (!nextCandidate) {
                return;
            }

            const applicantId = nextCandidate.applicant.id;

            fetchOtherCandidatesThatMatchApplicant({ applicantId });
        }
    }

    handleRedirectToConversationPageIfCandidateHasApplicationMessage({ job, candidate, history }: Props) {
        if (!job || !candidate) {
            return;
        }

        if (!this.state.redirectToConversationNeeded) {
            return;
        }

        this.setState({
            redirectToConversationNeeded: false
        });

        if (!isUnprocessedOrNotInteresting(candidate) || !candidate.application_message) {
            return;
        }

        history.push(getCandidateConversationRoute(job.id, candidate.id));
    }

    render() {
        const {
            job,
            jobFetchStatus,

            candidate
        } = this.props;

        let nextCandidateBanner;
        if (!!job && !!candidate && !isNew(candidate)) {
            nextCandidateBanner = (
                <NextNewCandidateBanner className="CandidateDetailsPage__next-new-candidate-banner" jobId={job.id} />
            );
        }

        return (
            <div className="CandidateDetailsPage">
                {nextCandidateBanner}
                <PageContainer className="CandidateDetailsPage__container">
                    <div className="CandidateDetailsPage__title">
                        <CandidatePageHeader
                            skeleton={jobFetchStatus !== 'loaded'}
                            job={job}
                            messageId="CANDIDATE_DETAILS_PAGE.TITLE"
                        />
                    </div>
                    <div className="CandidateDetailsPage__columns">
                        {this.renderLeftColumn()}
                        {this.renderRightColumn()}
                    </div>
                </PageContainer>
            </div>
        );
    }

    renderLeftColumn() {
        const { candidate, job, recruiter, otherCandidatesThatMatchApplicant } = this.props;

        // If the candidate is not loaded we want to return the column frame to take the space
        if (!recruiter || !candidate || !job) {
            return <div className="CandidateDetailsPage__column CandidateDetailsPage__column--left" />;
        }

        const actions = [
            ...(canMarkAsNotInteresting(candidate)
                ? [
                      <div key="hide" className="CandidateDetailsPage__action-container">
                          <CandidateActionHide candidateId={candidate.id} className="CandidateDetailsPage__action" />
                      </div>
                  ]
                : []),
            ...(canDelete(candidate)
                ? [
                      <div key="delete" className="CandidateDetailsPage__action-container">
                          <CandidateActionDelete
                              jobId={job.id}
                              candidateId={candidate.id}
                              className="CandidateDetailsPage__action"
                          />
                      </div>
                  ]
                : []),
            ...(canKeptFromExpiring(candidate)
                ? [
                      <div key="keep-expiring" className="CandidateDetailsPage__action-container">
                          <CandidateActionKeepExpiring
                              jobId={job.id}
                              candidateId={candidate.id}
                              className="CandidateDetailsPage__action"
                          />
                      </div>
                  ]
                : [])
        ];

        return (
            <div className="CandidateDetailsPage__column CandidateDetailsPage__column--left">
                <div className="CandidateDetailsPage__summary">
                    <div className="CandidateDetailsPage__details">
                        <CandidateDetailsHeader candidateId={candidate.id} />
                    </div>
                </div>

                <div className="CandidateDetailsPage__column-content">
                    <div className="CandidateDetailsPage__state-picker">
                        <CandidateStatePicker jobId={job.id} candidateId={candidate.id} />
                    </div>

                    <div
                        className={classNames('CandidateDetailsPage__actions-and-rating', {
                            'CandidateDetailsPage__actions-and-rating--multiple-actions': actions.length > 1
                        })}
                    >
                        <div className="CandidateDetailsPage__actions">{actions}</div>

                        <div className="CandidateDetailsPage__rating">
                            <CandidateDetailsRow
                                label={<FormattedMessage id="CANDIDATE_DETAILS_PAGE.DETAILS.RATING_LABEL" />}
                                layout="one-line"
                            >
                                <CandidateRatingButtons candidateId={candidate.id} />
                            </CandidateDetailsRow>
                        </div>
                    </div>

                    <CandidateDetailsRow
                        label={<FormattedMessage id="CANDIDATE_DETAILS_PAGE.DETAILS.CREATED_LABEL" />}
                        layout="one-line"
                    >
                        <FormattedDate value={candidate.created} />
                    </CandidateDetailsRow>

                    <CandidateDetailsRow
                        label={<FormattedMessage id="CANDIDATE_DETAILS_PAGE.DETAILS.AVAILABILITY_LABEL" />}
                    >
                        <CandidateAvailability candidateId={candidate.id} />
                    </CandidateDetailsRow>

                    <CandidateDetailsRow
                        label={<FormattedMessage id="CANDIDATE_DETAILS_PAGE.DETAILS.SALARY_EXPECTATION_LABEL" />}
                    >
                        <CandidateSalaryExpecation candidateId={candidate.id} />
                    </CandidateDetailsRow>

                    <div className="CandidateDetailsPage__notes">
                        <CandidateNotesItem candidateId={candidate.id} />
                    </div>

                    {!!otherCandidatesThatMatchApplicant.length && (
                        <CandidateDetailsRow
                            label={<FormattedMessage id="CANDIDATE_DETAILS_PAGE.DETAILS.ALSO_APPLIED_FOR_LABEL" />}
                            border
                            stickyLabel
                        >
                            <CandidateAlsoAppliedFor candidateId={candidate.id} applicantId={candidate.applicant.id} />
                        </CandidateDetailsRow>
                    )}
                </div>
            </div>
        );
    }

    renderRightColumn() {
        const { match, jobId, candidateId, recruiter, job, candidate, conversation } = this.props;

        const unreadMessageCount =
            conversation !== null
                ? conversation.unread_message_count
                : candidate !== null
                ? candidate.unread_message_count
                : 0;

        return (
            <div className="CandidateDetailsPage__column CandidateDetailsPage__column--right">
                <div className="CandidateDetailsPage__item CandidateDetailsPage__tabs">
                    <CandidateDetailsPageTabs
                        jobId={jobId}
                        candidateId={candidateId}
                        unreadMessageCount={unreadMessageCount}
                    />
                </div>

                <div className="CandidateDetailsPage__column-content">
                    <Route exact path={match.path} component={CandidateProfilePage} />
                    <Route exact path={match.path + '/conversation'}>
                        <CandidateConversationPage
                            jobId={jobId}
                            candidateId={candidateId}
                            recruiter={recruiter}
                            job={job}
                            candidate={candidate}
                        />
                    </Route>
                    <Route exact path={match.path + '/documents'} component={CandidateDocumentsPage} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => {
    const match = props.match;

    const jobId = parseInt(match.params.jobId);
    const candidateId = parseInt(match.params.candidateId);

    const candidate = getCandidate(state, candidateId);
    const applicantId = !!candidate ? candidate.applicant.id : null;

    const otherCandidatesThatMatchApplicant = !!applicantId
        ? getOtherCandidatesThatMatchApplicant(state, applicantId, [candidateId])
        : [];
    const otherCandidatesThatMatchApplicantFetchStatus = !!applicantId
        ? getOtherCandidatesThatMatchApplicantFetchStatus(state, applicantId)
        : FETCH_STATUS.NONE;

    const conversationId = candidate ? getCandidateConversationId(candidate) : null;
    const conversation = conversationId !== null ? getConversation(state, conversationId) : null;

    return {
        jobId,
        candidateId,

        recruiter: getRecruiter(state),

        job: getJob(state, jobId),
        jobFetchStatus: getJobFetchStatus(state, jobId),

        candidate,
        candidateFetchStatus: getCandidateFetchStatus(state, candidateId),
        candidateUpdateStatus: getCandidateUpdateStatus(state, candidateId),
        candidateDeleteStatus: getCandidateDeleteStatus(state, candidateId),
        candidateErrors: getCandidateErrors(state, candidateId),

        conversation,

        otherCandidatesThatMatchApplicant,
        otherCandidatesThatMatchApplicantFetchStatus,

        nextCandidateFetchStatus: getFetchStatus(state, 'nextNewForCurrentJob', jobId)
    };
};

const mapDispatchToProps: ConnectedDispatchProps = {
    loadJob,
    loadCandidate,
    loadCandidates,
    fetchOtherCandidatesThatMatchApplicant,

    updateCandidateState
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailsPage);
