import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';

import { Job } from '../../modules/jobs/types';
import { isEditable } from '../../modules/jobs/utils';
import { getJobEditRoute } from '../../routes';

type Props = {
    job: Job;
    className?: string;
};

class JobActionEdit extends React.Component<Props> {
    render() {
        const { job, className } = this.props;
        const linkPath = getJobEditRoute(job.id);

        if (!isEditable(job)) {
            return null;
        }

        return (
            <Button typeStyle="outlined" variationStyle="normal" component={Link} to={linkPath} className={className}>
                <FormattedMessage id="JOB_ACTION_EDIT.LABEL" />
            </Button>
        );
    }
}

export default JobActionEdit;
