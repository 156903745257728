import { Keyframes } from 'react-spring/renderprops';
import { ANIMATION_STEP_INIT, ANIMATION_STEP_OPEN, ANIMATION_STEP_NEXT_STEP } from '../../constants';

const AnimationStatesInit = {
    wrapperTranslate: [0, 0, 100, 100],
    childTranslate: [0, 0, 100, 100]
};

const AnimationStates = {
    [ANIMATION_STEP_INIT]: AnimationStatesInit,
    [ANIMATION_STEP_OPEN]: AnimationStatesInit,
    [ANIMATION_STEP_NEXT_STEP]: {
        from: AnimationStatesInit,
        wrapperTranslate: [-1000, 0, 100, 100],
        childTranslate: [0, 0, 1, 100],
        config: {
            tension: 200.47,
            friction: 20.17
        }
    }
};

export const AnimationKeyframes = Keyframes.Spring({ // eslint-disable-line
    [ANIMATION_STEP_INIT]: AnimationStates[ANIMATION_STEP_INIT],
    [ANIMATION_STEP_OPEN]: AnimationStates[ANIMATION_STEP_OPEN],
    [ANIMATION_STEP_NEXT_STEP]: AnimationStates[ANIMATION_STEP_NEXT_STEP]
});
