import { Location, LocationDescriptorObject } from 'history';
import { omitNullValues } from '../../utils/object';
import { parseSearch, stringifySearch } from '../../utils/url';
import { splitOrdering, transformQueryParams } from '../../utils/params';

import { DEFAULT_PARAMS } from './constants';

import { Params, QueryParams } from './types';

export function resolveSearchParams(search: string): Params {
    const queryParams: QueryParams = transformQueryParams(parseSearch(search));

    return convertQueryParamsToParams(DEFAULT_PARAMS, queryParams);
}

/**
 * Merge the query parameters with the default params.
 */
export function convertQueryParamsToParams(defaultParams: Params, queryParams: QueryParams): Params {
    return {
        ...defaultParams,
        ...queryParams
    };
}

export function convertParamsToActionParams(params: Params): Object {
    const { ordering, page, ...filters } = params;

    const splittedOrdering = splitOrdering(ordering);

    let orderingList: string[] | null = null;

    if (!!splittedOrdering && splittedOrdering.field === 'candidates') {
        orderingList = ['applicant__first_name', 'applicant__last_name'];
    } else {
        if (!!ordering && splittedOrdering) {
            orderingList = [splittedOrdering.field];
        } else {
            orderingList = null;
        }
    }

    if (!!orderingList && !!splittedOrdering && splittedOrdering.value === 'desc') {
        orderingList = orderingList.map((field) => `-${field}`);
    }

    const filterEntries = Object.entries(filters).map(([key, value]) => {
        // Transform keys
        if (key === 'rating') {
            key = 'ratingByRecruiter';
        } else if (key === 'state') {
            const values = value as string[];
            if (values.indexOf('locked') >= 0) {
                values.push('new');
            }
        }

        // Transform values
        if (value instanceof Array) {
            value = !!value.length ? value : null;
        }

        return [key, value];
    });

    const mappedFilters = Object.fromEntries(filterEntries);

    return omitNullValues({
        ...mappedFilters,
        page,
        ordering: orderingList
    });
}

export function createPaginationLinkProps(location: Location, page: number): { to: LocationDescriptorObject } {
    const queryParams: QueryParams = parseSearch(location.search);

    const to = {
        pathname: location.pathname,
        hash: location.hash,
        search: stringifySearch({
            ...queryParams,
            page
        })
    };

    return { to };
}

export function shouldShowJobPlaceholder(jobFetchStatus: string): boolean {
    return jobFetchStatus !== 'loaded';
}

export function shouldShowCandidateListPlaceholder(initialDataLoaded: boolean): boolean {
    return !initialDataLoaded;
}

export function shouldShowCandidateState(listName: string): boolean {
    return listName !== 'notInteresting';
}
