import { buildClientConfig as buildClientConfigOnServer, ClientConfig } from './server/config/client';

export type { ClientConfig };

export const CLIENT_CONFIG_ELEMENT_ID = '__APP_CONFIG__';

let config: ClientConfig | null = null;

export function getClientConfig(): ClientConfig {
    if (config === null) {
        config = buildClientConfig();
    }

    return config;
}

function buildClientConfig(): ClientConfig {
    if (typeof window === 'undefined' || process.env.NODE_ENV === 'test' || process.env.STORYBOOK === 'true') {
        return buildClientConfigOnServer();
    }

    return readClientConfigOnClient();
}

function readClientConfigOnClient(): ClientConfig {
    const appConfigElement = document.getElementById(CLIENT_CONFIG_ELEMENT_ID);

    if (!appConfigElement) {
        throw new Error(`Couldn't find app config element`);
    }

    try {
        // XXX: We don't validate the config
        return JSON.parse(appConfigElement.textContent!);
    } catch (error) {
        throw new Error(`Couldn't parse app config`);
    }
}

/**
 * Can't use `setClientConfig` inside the application. It's only for testing purpose.
 */
export function setClientConfig(clientConfig: Partial<ClientConfig> = {}) {
    if (process.env.NODE_ENV !== 'test' && process.env.STORYBOOK !== 'true') {
        if (process.env.NODE_ENV !== 'production') {
            console.error(`Can't use \`setClientConfig\` inside the application. It's only for testing purpose.`);
        }

        return;
    }

    config = {
        ...buildClientConfig(),
        ...clientConfig
    };
}
