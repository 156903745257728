import * as React from 'react';

import { PackageSubset } from '../../modules/packages/types';
import ProductType from '../ProductType';

export type PackageTypeProps = {
    pkg: PackageSubset<'product'>;
};

function PackageType({ pkg }: PackageTypeProps) {
    return <ProductType product={pkg.product} />;
}

export default PackageType;
