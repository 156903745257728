import React from 'react';
import { Keyframes } from 'react-spring/renderprops';
import { ANIMATION_STEP_INIT, ANIMATION_STEP_OPEN, ANIMATION_STEP_NEXT_STEP } from '../../constants';

const AnimationStates = {
    [ANIMATION_STEP_INIT]: {
        opacity: 0,
        wrapperTranslate: [10000, 10000, 0, 0],
        childTranslate: [0, 14, 100, 100]
    },
    [ANIMATION_STEP_OPEN]: {
        opacity: 1,
        wrapperTranslate: [10000, 10000, 0, 0],
        childTranslate: [0, -16, 100, 100],
        delay: 500
    },
    [ANIMATION_STEP_NEXT_STEP]: {
        wrapperTranslate: [0, 0, 0, 0],
        childTranslate: [0, 16, 0, 0],
        config: {
            tension: 381.47,
            friction: 43.17
        },
        delay: 0
    }
};

// eslint-disable-next-line
export const AnimationKeyframes: React.ElementType = Keyframes.Spring({
    [ANIMATION_STEP_INIT]: AnimationStates[ANIMATION_STEP_INIT],
    [ANIMATION_STEP_OPEN]: {
        from: AnimationStates[ANIMATION_STEP_INIT],
        ...AnimationStates[ANIMATION_STEP_OPEN]
    },
    [ANIMATION_STEP_NEXT_STEP]: {
        from: AnimationStates[ANIMATION_STEP_OPEN],
        ...AnimationStates[ANIMATION_STEP_NEXT_STEP]
    }
}) as any;

export const AnimationLeaveKeyframes = {
    [ANIMATION_STEP_INIT]: { from: { opacity: 1 }, opacity: 0 },
    [ANIMATION_STEP_NEXT_STEP]: {
        from: {
            opacity: 1,
            wrapperTranslate: AnimationStates[ANIMATION_STEP_NEXT_STEP].wrapperTranslate,
            childTranslate: AnimationStates[ANIMATION_STEP_NEXT_STEP].childTranslate
        },
        opacity: 0,
        wrapperTranslate: AnimationStates[ANIMATION_STEP_NEXT_STEP].wrapperTranslate,
        childTranslate: [0, -14, 0, 0]
    }
};

export const emptyTransition = { visibility: 'visible' };
