import { FormField, Input } from '@truffls/design-system-react';
import * as React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { FormattedMessage } from 'react-intl';
import { containsChars } from '../../utils/string';
import BottomActionBar from '../BottomActionBar';
import Button from '../Button';
import LoaderButton from '../LoaderButtonV2';
import MobilePageContainer from '../MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../NavigationBar';
import scrolled, { ScrolledProps } from '../Scrolled';
import './ForwardCandidateView.style.scss';

type ForwardingMessage = {
    email: string;
    subject: string;
    body: string;
};

type Props = ScrolledProps & {
    open: boolean;
    loading: boolean;

    onForward: (message: ForwardingMessage) => void;
    onClose: () => void;
};

type State = {
    isViewportMobile: boolean;
    message: ForwardingMessage;
};

class ForwardCandidateView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isViewportMobile: false,
            message: {
                email: '',
                subject: '',
                body: ''
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (matchMedia('(max-width: 769px)').matches) {
            this.setState({ isViewportMobile: true });
        } else {
            this.setState({ isViewportMobile: false });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.open && !!this.props.open) {
            if (matchMedia('(max-width: 769px)').matches) {
                this.setState({ isViewportMobile: true });
            } else {
                this.setState({ isViewportMobile: false });
            }
        }
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const target = event.target;
        const { name, value } = target;

        this.setState((state) => ({
            message: {
                ...state.message,
                [name]: value
            }
        }));
    }

    handleKeyPress(event: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (event.ctrlKey && (event.keyCode === 13 || event.keyCode === 10)) {
            this.handleSubmit(event);
        }
    }

    handleSubmit(event: React.SyntheticEvent<HTMLElement>) {
        event.preventDefault();

        const { message } = this.state;

        if (!containsChars(message.email) || !containsChars(message.subject)) {
            return;
        }

        this.props.onForward(message);
    }

    render() {
        const title = <FormattedMessage id="FORWARD_CANDIDATE.TITLE" />;
        const forward = <FormattedMessage id="FORWARD_CANDIDATE.ACTION.FORWARD" />;

        const content = this.renderContent();

        if (this.state.isViewportMobile) {
            return this.renderMobileFrame(content, title, forward);
        } else {
            return this.renderDesktopFrame(content, title, forward);
        }
    }

    renderContent() {
        const messageValues = this.state.message;

        return (
            <React.Fragment>
                <FormField id="email" labelText={<FormattedMessage id="FORWARD_CANDIDATE.FORM.EMAIL.LABEL" />}>
                    <Input type="email" name="email" value={messageValues.email} onChange={this.handleChange} />
                </FormField>

                <FormField id="subject" labelText={<FormattedMessage id="FORWARD_CANDIDATE.FORM.SUBJECT.LABEL" />}>
                    <Input type="text" name="subject" value={messageValues.subject} onChange={this.handleChange} />
                </FormField>

                <FormField
                    id="body"
                    labelText={<FormattedMessage id="FORWARD_CANDIDATE.FORM.BODY.LABEL" />}
                    helpText={<FormattedMessage id="FORWARD_CANDIDATE.FORM.BODY.HELP_TEXT" />}
                >
                    <Input
                        name="body"
                        multiline
                        minRows={3}
                        maxRows={3}
                        value={messageValues.body}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyPress}
                    />
                </FormField>
            </React.Fragment>
        );
    }

    renderMobileFrame(content, title, forward) {
        const { scrolled, onScroll, open, onClose, loading } = this.props;

        return (
            <MobilePageContainer show={open}>
                <NavigationBar
                    noShadow={!scrolled}
                    centerElement={<NavigationBarText>{title}</NavigationBarText>}
                    rightElement={<NavigationBarCloseAction onClick={onClose} />}
                />
                <form onSubmit={this.handleSubmit} className="ForwardCandidateView__form">
                    <div className="ForwardCandidateView__overlay-container" onScroll={onScroll}>
                        {content}
                    </div>
                    <BottomActionBar>
                        <LoaderButton type="submit" loading={loading} typeStyle="raised" variationStyle="brand">
                            {forward}
                        </LoaderButton>
                    </BottomActionBar>
                </form>
            </MobilePageContainer>
        );
    }

    renderDesktopFrame(content, title, forward) {
        const { open, onClose, loading } = this.props;

        return (
            <Modal show={open} onHide={onClose}>
                <Modal.Header className="ForwardCandidateView__modal-header">
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <form onSubmit={this.handleSubmit}>
                    <Modal.Body>{content}</Modal.Body>
                    <Modal.Footer className="ForwardCandidateView__modal-footer">
                        <Button type="button" onClick={onClose} typeStyle="flat" variationStyle="brand">
                            <FormattedMessage id="FORWARD_CANDIDATE.ACTION.CANCEL" />
                        </Button>
                        <LoaderButton type="submit" loading={loading} typeStyle="flat" variationStyle="brand">
                            {forward}
                        </LoaderButton>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default scrolled(ForwardCandidateView);
