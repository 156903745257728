import React from 'react';
import classNames from 'classnames';

import './NavigationBarText.scss';

type Props = {
    children: React.ReactNode;
    className?: string;
};

function NavigationBarText({ children, className }: Props) {
    return <div className={classNames('NavigationBarText', className)}>{children}</div>;
}

export default NavigationBarText;
