import './element-matches';

if (!Element.prototype.closest) {
    // eslint-disable-next-line func-names
    Element.prototype.closest = function (s) {
        var el = this;

        do {
            if (el.matches(s)) {
                return el;
            }

            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}
