import * as React from 'react';
import classNames from 'classnames';

type Props = {
    state: 'active' | 'inactive';
};

const iconPaths = {
    active:
        'M10.9583333,14.7108333 C8.88726551,14.7108333 7.20833331,13.0319012 7.20833331,10.9608333 C7.20833331,8.88976551 8.88726551,7.21083331 10.9583333,7.21083331 C13.0294012,7.21083331 14.7083333,8.88976551 14.7083333,10.9608333 C14.7083333,13.0319012 13.0294012,14.7108333 10.9583333,14.7108333 Z M11.9999999,1.99999995 C17.5203725,2.00597066 21.9940293,6.47962751 21.9999999,11.9999999 C21.9999999,17.5228475 17.5228475,21.9999999 11.9999999,21.9999999 C6.47715247,21.9999999 1.99999995,17.5228475 1.99999995,11.9999999 C1.99999995,6.47715247 6.47715247,1.99999995 11.9999999,1.99999995 Z M18.2141667,17.0383334 L15.45,14.275 C15.3792323,14.2039876 15.3690782,14.0926419 15.4258334,14.01 C17.0117269,11.6915512 16.5699925,8.5466418 14.4067663,6.75479483 C12.24354,4.96294786 9.07137178,5.11438366 7.08867571,7.10415237 C5.10597964,9.09392108 4.96584031,12.2666085 6.76537878,14.4234405 C8.56491725,16.5802726 11.7113796,17.0108057 14.0241667,15.4166667 C14.096751,15.3668761 14.1945323,15.3759884 14.2566667,15.4383334 L17.0358334,18.2166667 C17.3640075,18.5352565 17.8859925,18.5352565 18.2141667,18.2166667 C18.539485,17.8912502 18.539485,17.3637499 18.2141667,17.0383334 Z',
    inactive:
        'M21.5312,19.2728 L17.8152,15.5568 C20.1593884,12.0477317 19.4594182,7.33273405 16.1969119,4.65601367 C12.9344056,1.9792933 8.17351216,2.21393036 5.1900173,5.19848021 C2.20652243,8.18303006 1.97356857,12.9440062 4.65144222,16.2055659 C7.32931586,19.4671257 12.0445607,20.1654288 15.5528,17.82 L19.2688,21.5352 C19.8980378,22.1490009 20.9019622,22.1490009 21.5312,21.5352 C22.1558112,20.9104003 22.1558112,19.8975998 21.5312,19.2728 Z M11,5.20399997 C14.2032516,5.20399997 16.8,7.80074843 16.8,11.004 C16.8,14.2072516 14.2032516,16.804 11,16.804 C7.79674843,16.804 5.19999997,14.2072516 5.19999997,11.004 C5.20352714,7.80221057 7.79821057,5.20752714 11,5.20399997 Z'
};

function SearchBarFormLenseIcon({ state }: Props) {
    return (
        <svg
            className={classNames('SearchBarForm__icon', {
                'SearchBarForm__icon--open': state === 'active',
                'SearchBarForm__icon--placeholder': state === 'inactive'
            })}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
        >
            <path fillRule="evenodd" d={iconPaths[state]} />
        </svg>
    );
}

export default SearchBarFormLenseIcon;
