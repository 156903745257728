import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Job } from '../../../modules/jobs/types';
import { isDraft } from '../../../modules/jobs/utils';
import { getCandidatesRoute } from '../../../routes';

import './JobListItemTitle.scss';

export type JobListItemTitleProps = {
    job: Job;
    className?: string;
    children: React.ReactNode;
};

export function JobListItemTitle({ job, children, className }: JobListItemTitleProps) {
    const titleClassName = classNames('JobListItemTitle', className);

    if (isDraft(job)) {
        return <span className={titleClassName}>{children}</span>;
    }

    return (
        <Link to={getCandidatesRoute(job.id)} className={titleClassName}>
            {children}
        </Link>
    );
}
