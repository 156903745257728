export const TEMPLATE_GERMAN_LANGUAGE: 'de' = 'de';
export const TEMPLATE_ENGLISH_LANGUAGE: 'en' = 'en';

export const TEMPLATE_VARIABLE_CANDIDATE_FIRST_NAME: 'candidate_first_name' = 'candidate_first_name';
export const TEMPLATE_VARIABLE_CANDIDATE_LAST_NAME: 'candidate_last_name' = 'candidate_last_name';
export const TEMPLATE_VARIABLE_JOB_TITLE: 'job_title' = 'job_title';

export const TEMPLATE_VARIABLES = {
    CANDIDATE_FIRST_NAME: TEMPLATE_VARIABLE_CANDIDATE_FIRST_NAME,
    CANDIDATE_LAST_NAME: TEMPLATE_VARIABLE_CANDIDATE_LAST_NAME,
    JOB_TITLE: TEMPLATE_VARIABLE_JOB_TITLE
};

export const TEMPLATE_ORDERING_QUERY_PARAM_NAME: 'name' = 'name';
export const TEMPLATE_ORDERING_QUERY_PARAM_TYPE: 'has_blueprint' = 'has_blueprint';
export const TEMPLATE_ORDERING_QUERY_PARAM_VISIBLE: 'visible' = 'visible';
export const TEMPLATE_ORDERING_QUERY_PARAM_UPDATE: 'updated' = 'updated';
export const TEMPLATE_ORDERING_QUERY_PARAM_LANGUAGE: 'language' = 'language';

export const TEMPLATE_ORDERING_QUERY_PARAM_NAME_BACKWARD: '-name' = '-name';
export const TEMPLATE_ORDERING_QUERY_PARAM_TYPE_BACKWARD: '-has_blueprint' = '-has_blueprint';
export const TEMPLATE_ORDERING_QUERY_PARAM_VISIBLE_BACKWARD: '-visible' = '-visible';
export const TEMPLATE_ORDERING_QUERY_PARAM_UPDATE_BACKWARD: '-updated' = '-updated';
export const TEMPLATE_ORDERING_QUERY_PARAM_LANGUAGE_BACKWARD: '-language' = '-language';

export const TEMPLATE_ORDERING_QUERY_PARAMS = {
    NAME: TEMPLATE_ORDERING_QUERY_PARAM_NAME,
    TYPE: TEMPLATE_ORDERING_QUERY_PARAM_TYPE,
    VISIBLE: TEMPLATE_ORDERING_QUERY_PARAM_VISIBLE,
    UPDATE: TEMPLATE_ORDERING_QUERY_PARAM_UPDATE,
    LANGUAGE: TEMPLATE_ORDERING_QUERY_PARAM_LANGUAGE,

    NAME_BACKWARD: TEMPLATE_ORDERING_QUERY_PARAM_NAME_BACKWARD,
    TYPE_BACKWARD: TEMPLATE_ORDERING_QUERY_PARAM_TYPE_BACKWARD,
    VISIBLE_BACKWARD: TEMPLATE_ORDERING_QUERY_PARAM_VISIBLE_BACKWARD,
    UPDATE_BACKWARD: TEMPLATE_ORDERING_QUERY_PARAM_UPDATE_BACKWARD,
    LANGUAGE_BACKWARD: TEMPLATE_ORDERING_QUERY_PARAM_LANGUAGE_BACKWARD
};
