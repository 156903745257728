import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    labelId: string;
    children: React.ReactNode;
};

function JobDetailsMetaItemLabeledValue(props: Props) {
    const { labelId, children } = props;

    return (
        <React.Fragment>
            <span className="JobDetailsMeta__item-label">
                <FormattedMessage id={labelId} />
            </span>{' '}
            <span className="JobDetailsMeta__item-value">{children}</span>
        </React.Fragment>
    );
}

export default JobDetailsMetaItemLabeledValue;
