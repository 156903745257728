import { Params } from './types';

export const DEFAULT_PARAMS: Params = {
    pageSize: 10,
    page: 1,
    rating: [],
    state: [],
    ordering: null
};

export const SKELETON_CANDIDATES = [null, null, null];
