import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import DialogPageContainer from '../../components/DialogPageContainer';
import LoadingWheel from '../../components/LoadingWheel';
import EmailVerificationRequestDialog from '../../components/EmailVerificationRequestDialog';

import { resendVerificationEmail } from '../../modules/authentication/actions';
import { getVerificationEmailResendStatus } from '../../modules/authentication/selectors';

import { parseSearch } from '../../utils/url';

import './style.scss';

// Types
import { RequestStatus } from '../../modules/types';

type ConnectorProps = RouteComponentProps;
type ConnectedProps = {
    email: string;

    status: RequestStatus;
};
type ConnectedActions = {
    resendVerificationEmail: typeof resendVerificationEmail;
};

type Props = ConnectorProps & ConnectedActions & ConnectedProps;

class EmailVerificationRequestPage extends React.Component<Props> {
    componentDidMount() {
        const {
            email,

            resendVerificationEmail
        } = this.props;

        resendVerificationEmail(email);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.email !== this.props.email && typeof this.props.email === 'string') {
            this.props.resendVerificationEmail(this.props.email);
        }
    }

    render() {
        const { status } = this.props;

        let content: React.ReactNode = null;
        if (status === 'none' || status === 'requesting') {
            content = this.renderLoadingWheel();
        } else {
            content = this.renderDialog();
        }

        return <div className="EmailVerificationRequestPage">{content}</div>;
    }

    renderLoadingWheel() {
        return (
            <div className="EmailVerificationRequestPage__loading-wheel">
                <LoadingWheel />
            </div>
        );
    }

    renderDialog() {
        const { status } = this.props;

        return (
            <DialogPageContainer>
                <EmailVerificationRequestDialog successful={status === 'requested'} />
            </DialogPageContainer>
        );
    }
}

function mapStateToProps(state: any, props: ConnectorProps) {
    const location = props.location;
    const query = parseSearch<{ email: string }>(location.search);

    return {
        email: query.email,

        status: getVerificationEmailResendStatus(state)
    };
}

const mapDispatchToProps = {
    resendVerificationEmail: resendVerificationEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationRequestPage);
