import { CheckoutProcessStateId } from '../../modules/checkout/types';
import { Deferred } from '../../utils/promise';
import { createAction, createActionTypeCreator } from '../../utils/redux';

const createActionType = createActionTypeCreator('UI/CHECKOUT_ADDRESS_PAGE');

export const CONTINUE_TO_NEXT_STEP = createActionType('CONTINUE_TO_NEXT_STEP');
export const continueToNextStep = createAction(
    CONTINUE_TO_NEXT_STEP,
    (checkoutProcessState, formValues, { deferred }: { deferred?: Deferred<CheckoutProcessStateId> } = {}) => {
        return {
            payload: {
                checkoutProcessState,
                formValues
            },
            meta: {
                deferred
            }
        };
    }
);
export type ContinueToNextStepAction = ReturnType<typeof continueToNextStep>;
