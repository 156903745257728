import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import Button from '../../components/Button';
import JobDialogDelete from '../../components/JobDialogDelete';
import { getJob } from '../../modules/entities/selectors';
import { deleteJob } from '../../modules/jobs/actions';
import { Job } from '../../modules/jobs/types';
import { addActionNotification } from '../../modules/notifications/actions';
import { ApplicationState } from '../../store/reducer';

import { shouldShow } from './utils';

type ConnectorProps = {
    jobId: number;
    className?: string;
};

type ConnectedStateProps = {
    job: Job | null;
};

type ConnectedDispatchProps = {
    deleteJob: typeof deleteJob;
    addActionNotification: typeof addActionNotification;
};

type Props = ConnectedStateProps & ConnectedDispatchProps & ConnectorProps;

type State = {
    showDialog: boolean;
};

export class JobActionDelete extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showDialog: false
        };

        this.closeDialog = this.closeDialog.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    openDialog() {
        this.setState({ showDialog: true });
    }

    closeDialog() {
        this.setState({ showDialog: false });
    }

    handleClick() {
        this.openDialog();
    }

    handleSubmit() {
        const { jobId, deleteJob, addActionNotification } = this.props;

        deleteJob(jobId);
        this.closeDialog();
        addActionNotification('JOB_ACTION_DELETE.NOTIFICATION');
    }

    render() {
        const { job, className } = this.props;
        const { showDialog } = this.state;

        if (!job || !shouldShow(job)) {
            return null;
        }

        return (
            <React.Fragment>
                <Button
                    type="button"
                    onClick={this.handleClick}
                    typeStyle="outlined"
                    variationStyle="normal"
                    className={className}
                >
                    <FormattedMessage id="JOB_ACTION_DELETE.LABEL" />
                </Button>
                <JobDialogDelete open={showDialog} onSubmit={this.handleSubmit} onClose={this.closeDialog} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => ({
    job: getJob(state, props.jobId)
});

const mapDispatchToProps: ConnectedDispatchProps = {
    deleteJob,
    addActionNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(JobActionDelete);
