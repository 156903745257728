import { combineReducers } from 'redux';

import { SIGNUP_REQUEST, RESET_SIGNUP_REQUEST } from '../actions';

// Types
import { Action } from '../../types';

export type ErrorsState = Object | Array<any>;
export type ResultState = {
    trial_end: string | null;
} | null;

export type StatusState = 'none' | 'signingUp' | 'signedUp' | 'failed';

export type State = {
    status: StatusState;
    result: ResultState;
    errors: ErrorsState;
};

export function errorsReducer(state: ErrorsState = [], action: Action): ErrorsState {
    switch (action.type) {
        case RESET_SIGNUP_REQUEST:
        case SIGNUP_REQUEST.REQUEST:
            return [];

        case SIGNUP_REQUEST.FAILURE:
            return action.payload.errors;

        default:
            return state;
    }
}

export function resultReducer(state: ResultState = null, action: Action): ResultState {
    switch (action.type) {
        case SIGNUP_REQUEST.REQUEST:
        case SIGNUP_REQUEST.FAILURE:
            return null;

        case SIGNUP_REQUEST.SUCCESS:
            return action.payload.data;

        default:
            return state;
    }
}

export function statusReducer(state: StatusState = 'none', action: Action): StatusState {
    switch (action.type) {
        case SIGNUP_REQUEST.REQUEST:
            return 'signingUp';

        case SIGNUP_REQUEST.SUCCESS:
            return 'signedUp';

        case SIGNUP_REQUEST.FAILURE:
            return 'failed';

        case RESET_SIGNUP_REQUEST:
            return 'none';

        default:
            return state;
    }
}

const rootReducer = combineReducers({
    result: resultReducer,
    status: statusReducer,
    errors: errorsReducer
});

export default rootReducer;
