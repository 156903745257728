import * as React from 'react';
import classNames from 'classnames';

import './StepsDots.style.scss';
import sassVariables from './StepsDots.style.scss';

const dotSize = parseInt(sassVariables.dotSize, 10);
const dotGap = parseInt(sassVariables.dotGap, 10);

type Props = {
    stepsCount: number;
    activeStep: number;
    onStepClick: (step: number) => void;
    className?: string;
};

const StepsDots = ({ stepsCount, activeStep, onStepClick, className }: Props) => {
    const stepsCountArray = Array(stepsCount).fill(null);
    return (
        <div className={classNames('StepsDots', className)}>
            {stepsCountArray.map((_, index) => (
                <button
                    key={index}
                    className="StepsDots__button"
                    onClick={() => {
                        if (onStepClick) {
                            onStepClick(index + 1);
                        }
                    }}
                />
            ))}

            <span
                className="StepsDots__active-indicator"
                style={{ left: (activeStep - 1) * (dotSize + dotGap) || 0 }}
            />
        </div>
    );
};

export default StepsDots;
