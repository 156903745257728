import React from 'react';

import { AutocompletionResult, getAutocompleteResults } from '../../../services/maps/places';
import { sleep } from '../../../utils/promise';

type UseAutocompletionResultsProps<InputValue, ResultItem> = {
    isOpen: boolean;
    inputValue: InputValue;
    getResults: (inputValue: InputValue) => Promise<ResultItem[]>;
};

function useAutocompletionResults<InputValue, ResultItem>({
    isOpen,
    inputValue,
    getResults
}: UseAutocompletionResultsProps<InputValue, ResultItem>) {
    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState<ResultItem[]>([]);

    const isOpenRef = React.useRef(isOpen);
    React.useEffect(() => {
        isOpenRef.current = isOpen;
    }, [isOpen]);

    React.useEffect(() => {
        let active = true;

        if (!isOpenRef.current) {
            setResults([]);
            return;
        }

        if (!inputValue) {
            setResults([]);
            return;
        }

        setLoading(true);

        sleep(500).then(() => {
            if (!active || !isOpenRef.current) {
                return;
            }

            getResults(inputValue).then((newResults) => {
                if (!active || !isOpenRef.current) {
                    return;
                }

                setLoading(false);
                setResults(newResults);
            });
        });

        return () => {
            active = false;
        };
    }, [inputValue]);

    return {
        loading,
        results
    };
}

type UsePlacesAutocompletionResultsInputValue = {
    country: string;
    query: string;
} | null;

function toInputValue(country: string, query: string): UsePlacesAutocompletionResultsInputValue {
    if (!country || !query) {
        return null;
    }

    return {
        country,
        query
    };
}

export function usePlacesAutocompletionResults(country, query, { isOpen }) {
    const [inputValue, setInputValue] = React.useState<UsePlacesAutocompletionResultsInputValue>(
        toInputValue(country, query)
    );

    React.useEffect(() => {
        setInputValue(toInputValue(country, query));
    }, [country, query]);

    return useAutocompletionResults<UsePlacesAutocompletionResultsInputValue, AutocompletionResult>({
        isOpen,
        inputValue,
        getResults: async (inputValue) => {
            if (!inputValue) {
                return [];
            }

            return await getAutocompleteResults(inputValue.country, inputValue.query);
        }
    });
}
