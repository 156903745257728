import { combineReducers } from 'redux';

import {
    PASSWORD_RESET_REQUEST,
    RESET_PASSWORD_RESET_REQUEST,
    PASSWORD_RESET_CONFIRM,
    RESET_PASSWORD_RESET_CONFIRM
} from '../actions';

// Types
import { Action, RequestStatus, ResetStatus } from '../../types';

export type State = {
    requestStatus: RequestStatus;
    resetStatus: ResetStatus;
};

// Reducers

export const requestStatusReducer = (state: RequestStatus = 'none', action: Action): RequestStatus => {
    switch (action.type) {
        case PASSWORD_RESET_REQUEST.REQUEST:
            return 'requesting';

        case PASSWORD_RESET_REQUEST.SUCCESS:
            return 'requested';

        case PASSWORD_RESET_REQUEST.FAILURE:
            return 'failed';

        case RESET_PASSWORD_RESET_REQUEST:
            return 'none';

        default:
            return state;
    }
};

export const resetStatusReducer = (state: ResetStatus = 'none', action: Action): ResetStatus => {
    switch (action.type) {
        case PASSWORD_RESET_CONFIRM.REQUEST:
            return 'resetting';

        case PASSWORD_RESET_CONFIRM.SUCCESS:
            return 'resetted';

        case PASSWORD_RESET_CONFIRM.FAILURE:
            return 'failed';

        case RESET_PASSWORD_RESET_CONFIRM:
            return 'none';

        default:
            return state;
    }
};

const rootReducer = combineReducers({
    requestStatus: requestStatusReducer,
    resetStatus: resetStatusReducer
});

export default rootReducer;
