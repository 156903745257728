import apiClientV4 from '../../services/api/v4';
import { toCommaSeparated } from '../../services/api/utils';

import { Template } from '../../modules/templates/types';

export type FetchTemplatesQueryParams = {
    page?: number | void;
    pageSize?: number | void;
    ordering?: Array<string> | void;
};

type ParsedQueryParams = {
    page?: number;
    page_size?: number;
    ordering?: string;
};

export function fetchTemplates(queryParams: FetchTemplatesQueryParams = {}) {
    const parsedQueryParams: ParsedQueryParams = {};

    const { page, pageSize, ordering } = queryParams;

    if (page) {
        parsedQueryParams.page = page;
    }
    if (pageSize) {
        parsedQueryParams.page_size = pageSize;
    }
    if (ordering) {
        parsedQueryParams.ordering = toCommaSeparated(ordering);
    }

    return apiClientV4.get('/templates/', {
        params: parsedQueryParams
    });
}

export function fetchTemplate(templateId: Template['id']) {
    return apiClientV4.get(`/templates/${templateId}/`);
}

export function createTemplate(newTemplate: Partial<Template>) {
    return apiClientV4.post('/templates/', newTemplate);
}

export function updateTemplate(templateId: Template['id'], newTemplate: Partial<Template>) {
    return apiClientV4.patch(`/templates/${templateId}/`, newTemplate);
}

export function deleteTemplate(templateId: Template['id']) {
    return apiClientV4.delete(`/templates/${templateId}/`);
}
