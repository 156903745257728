import React from 'react';
import { FormattedMessage } from 'react-intl';
import MobilePageContainer, { MobilePageContainerContent } from '../../../components/MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../../../components/NavigationBar';
import { Candidate } from '../../../modules/candidates/types';

type Props = {
    open: boolean;
    onClose: () => void;
    isLoading: boolean;
    hasFailed: boolean;
    candidates: Candidate[];
    children: React.ReactNode;
};

function CandidateAlsoAppliedForFullscreen({ open, onClose, children }: Props) {
    return (
        <MobilePageContainer show={open}>
            <NavigationBar
                centerElement={
                    <NavigationBarText>
                        <FormattedMessage id="CANDIDATE_ALSO_APPLIED_FOR_VIEW.FULLSCREEN.TITLE_TEXT" />
                    </NavigationBarText>
                }
                rightElement={
                    <NavigationBarCloseAction onClick={onClose}>
                        <FormattedMessage id="CANDIDATE_ALSO_APPLIED_FOR_VIEW.CLOSE_TEXT" />
                    </NavigationBarCloseAction>
                }
                noShadow={true}
            />
            <MobilePageContainerContent>{children}</MobilePageContainerContent>
        </MobilePageContainer>
    );
}

export default CandidateAlsoAppliedForFullscreen;
