import React from 'react';
import classNames from 'classnames';
import { FieldValidator } from 'final-form';
import { useFieldArray } from 'react-final-form-arrays';

import { createArrayMinLength } from '../../../utils/form/validators';
import { useComposeValidators } from '../../../utils/form/react-final-form';
import { hasError } from '../../../utils/form/state';

import { onJobFormPageValidationLevel } from '../../JobFormPage/JobFormPage.utils';

import JobFormRequirementsCreateFormField, {
    JobFormRequirementsCreateFormFieldProps
} from './JobFormRequirementsCreateFormField';
import JobFormRequirementsListFormFields from './JobFormRequirementsListFormFields';

import './JobFormRequirementsFormFields.scss';
import JobFormFieldAnchor from '../JobFormFieldAnchor';

export type JobFormRequirementsFormFieldValue = string;
export type JobFormRequirementsFormFieldsValue = JobFormRequirementsFormFieldValue[];

export type JobFormRequirementsFormFieldsProps = {
    name: string;

    labelText: React.ReactNode;
    helpText: React.ReactNode;
    inputPlaceholderText?: string;

    minLength?: number;
    errorMessages: JobFormRequirementsCreateFormFieldProps['errorMessages'];

    selectableItems?: string[];
};

function JobFormRequirementsFormFields({
    name,

    minLength,

    labelText,
    helpText,

    errorMessages,
    inputPlaceholderText,
    selectableItems
}: JobFormRequirementsFormFieldsProps) {
    const minLengthArrayValidator = React.useMemo((): FieldValidator<JobFormRequirementsFormFieldsValue> => {
        if (typeof minLength !== 'number' || minLength === 0) {
            return () => undefined;
        }

        return createArrayMinLength(minLength) as FieldValidator<JobFormRequirementsFormFieldsValue>;
    }, [minLength]);

    const validators = React.useMemo(() => {
        return [onJobFormPageValidationLevel<JobFormRequirementsFormFieldsValue>('full', minLengthArrayValidator)];
    }, [minLengthArrayValidator]);

    const validate = useComposeValidators(validators);

    const field = useFieldArray<JobFormRequirementsFormFieldValue>(name, {
        validate
    });

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={name} />
            <div
                className={classNames('JobFormRequirementsFormFields', {
                    'has-error': hasError(field.fields.value, field.meta)
                })}
            >
                <label className="control-label">{labelText}</label>
                <span className="help-block">{helpText}</span>

                <JobFormRequirementsCreateFormField
                    name={name}
                    placeholderText={inputPlaceholderText}
                    errorMessages={errorMessages}
                    selectableItems={selectableItems}
                    data-testid="job-form-requirements-create-form-field"
                />
                <JobFormRequirementsListFormFields name={name} data-testid="job-form-requirements-list-form-fields" />
            </div>
        </React.Fragment>
    );
}

export default JobFormRequirementsFormFields;
