import * as React from 'react';
import classNames from 'classnames';

import { Type, Variation } from './constants';

type Props = {
    typeStyle: Type;
    variationStyle: Variation;

    children: React.ReactNode;

    component?: string | React.ComponentType<any>;
    className?: string;

    [propName: string]: any;
};

function Button(props: Props) {
    const {
        children,
        typeStyle,
        variationStyle,

        component: Component = 'button',
        className,

        ...restProps
    } = props;

    const buttonClassName = classNames(
        'tf-button',
        `tf-button--${typeStyle}`,
        `tf-button--${variationStyle}`,
        className
    );

    return (
        <Component {...restProps} className={buttonClassName}>
            {children}
        </Component>
    );
}

export default Button;
