import React from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useLoadJob } from '../../hooks/useLoadJob';
import { UPDATE_STATUS } from '../../modules/constants';
import { startAdvertiseCheckout } from '../../modules/checkout/actions';
import { getJob, getJobUpdateErrors, getJobUpdateStatus } from '../../modules/entities/selectors';
import { updateJob } from '../../modules/jobs/actions';
import { Job } from '../../modules/jobs/types';
import { isActive, isDraft, isPropertyEditable } from '../../modules/jobs/utils';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { Recruiter } from '../../modules/recruiters/types';

import { parseIntOrNull } from '../../utils/number';
import { clone } from '../../utils/object';
import { waitForResolve } from '../../utils/redux/utils';
import { JobFormPageJobPartial, JobFormSubmissionError } from '../JobFormPage';
import { ApplicationState } from '../../store/types';
import {
    getJobsRoute,
    JobEditRouteQuery,
    getCompanyProfileSettingsRoute,
    GetCompanyProfileSettingsRouteOptions
} from '../../routes';
import useQuery from '../../hooks/useQuery';

import JobEditFormPage from './JobEditFormPage';
import {
    JobFormPageSubmissionType,
    JOB_FORM_PAGE_SUBMISSION_TYPE_CHECKOUT,
    JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE,
    JOB_FORM_PAGE_SUBMISSION_TYPE_VIEW_IN_APP
} from '../JobFormPage/JobFormPage.constants';
import { handleError } from '../../services/errorHandler';
import { ExtraDataError } from '../../utils/errors';

export type JobEditFormPageContainerProps = {};

function JobEditFormPageContainer({}: JobEditFormPageContainerProps) {
    const history = useHistory();

    const { jobId, isParsed, isUpgraded, initialValidation } = useJobEditFormPageContainerParams();

    const store = useStore();
    const wait = React.useMemo(() => {
        return waitForResolve(store);
    }, [store]);

    const recruiter = useSelector(getRecruiter) as Recruiter;
    const dispatch = useDispatch();

    const { job, loading, failed, errors } = useLoadJob({ jobId });

    function saveWithUpdate(jobPartial: JobFormPageJobPartial) {
        if (!job) {
            return Promise.reject(new Error('The job to be updated is null'));
        }

        const data: Partial<Job> = clone(jobPartial);

        if (!isPropertyEditable(job, 'title')) {
            delete data.title;
        }
        if (!isPropertyEditable(job, 'company')) {
            delete data.company;
        }
        if (!isPropertyEditable(job, 'location')) {
            delete data.location;
        }
        if (!isPropertyEditable(job, 'functions')) {
            delete data.functions;
        }

        const mapStateToState = (state: ApplicationState) => {
            return {
                jobUpdateStatus: getJobUpdateStatus(state, job.id),
                jobUpdateErrors: getJobUpdateErrors(state, job.id),
                job: getJob(state, job.id)
            };
        };

        const connector = wait(mapStateToState);

        const onInit = function onInit() {};
        const onChange = function onChange(this: any, prevState, nextState) {
            if (prevState.jobUpdateStatus === UPDATE_STATUS.UPDATING) {
                if (nextState.jobUpdateStatus === UPDATE_STATUS.UPDATED) {
                    this.resolve(nextState.job);
                } else if (nextState.jobUpdateStatus === UPDATE_STATUS.FAILED) {
                    const error = new JobFormSubmissionError(nextState.jobUpdateErrors);
                    this.reject(error);
                }
            }
        };

        const connectPromise = connector({ onInit, onChange });

        dispatch(updateJob(job.id, data));

        return connectPromise;
    }

    const handleSubmit = async (
        jobPartial: JobFormPageJobPartial,
        submissionType: JobFormPageSubmissionType | null
    ): Promise<void> => {
        const updatedJob: Job = await saveWithUpdate(jobPartial);

        if (submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE) {
            history.push(getJobsRoute());
        } else if (submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_VIEW_IN_APP) {
            if (isDraft(updatedJob)) {
                if (!!updatedJob.preview_url) {
                    window.open(updatedJob.preview_url, '_blank');
                } else {
                    handleError(
                        new ExtraDataError('Draft job is missing preview URL', {
                            jobId: updatedJob.id,
                            jobState: updatedJob.state,
                            jobPreviewUrl: updatedJob.preview_url
                        })
                    );
                }
            } else if (isActive(updatedJob)) {
                window.open(updatedJob.static_url, '_blank');
            }
        } else if (submissionType === JOB_FORM_PAGE_SUBMISSION_TYPE_CHECKOUT) {
            dispatch(startAdvertiseCheckout(updatedJob));
        }
    };

    const handleCancel = () => {
        history.push(getJobsRoute());
    };

    const handleOpenSettings = () => {
        const options: GetCompanyProfileSettingsRouteOptions = {};

        if (!!job) {
            options.backToJob = job.id;
        }

        history.push(getCompanyProfileSettingsRoute(options));
    };

    return (
        <JobEditFormPage
            recruiter={recruiter}
            jobId={jobId}
            job={job}
            loading={loading}
            failed={failed}
            errors={errors}
            isParsed={isParsed}
            isUpgraded={isUpgraded}
            initialValidation={initialValidation}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onOpenSettings={handleOpenSettings}
        />
    );
}

export type JobEditFormPageContainerParams = {
    jobId: number | null;
    isParsed: boolean;
    isUpgraded: boolean;
    initialValidation: boolean;
};

export type RawJobEditFormPageContainerParams = {
    jobId?: string;
};

function useJobEditFormPageContainerParams(): JobEditFormPageContainerParams {
    const params = useParams<RawJobEditFormPageContainerParams>();
    const { parsed = 0, upgraded = 0, initialValidation = 0 } = useQuery<JobEditRouteQuery>({
        parse: (key, value) => {
            if (key === 'parsed' || key === 'upgraded' || key === 'initialValidation') {
                return value === '1' ? 1 : 0;
            }
        }
    });

    return {
        jobId: parseIntOrNull(params.jobId),
        isParsed: parsed === 1,
        isUpgraded: upgraded === 1,
        initialValidation: initialValidation === 1
    };
}

export default JobEditFormPageContainer;
