import * as React from 'react';

type Props = {
    children: React.ReactNode;
};

const DialogTitle = ({ children }: Props) => {
    return <h1 className="Dialog__title">{children}</h1>;
};

export default DialogTitle;
