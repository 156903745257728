import * as React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-overlays';
import { animated } from 'react-spring/renderprops';

import './TutorialDesktop.style.scss';

import { STEPS_COUNT } from '../constants';
import { getAnimationState } from '../utils';

import { clamp } from '../../../utils/math';

import TutorialDesktopBigArrow from './TutorialDesktopBigArrow';
import TutorialDesktopVideo from './TutorialDesktopVideo';
import TutorialDesktopBackground from './TutorialDesktopBackground';
import TutorialDesktopContent from './TutorialDesktopContent/TutorialDesktopContent';

type State = {
    step: number;
    acceptedConditions: boolean;
    goingRight: boolean;
};

type Props = {
    open: boolean;
    showGtc: boolean;
    animationProps: any;
    acceptGtc: () => void;
    skipTutorial: () => void;
    completeTutorial: () => void;
};

class TutorialDesktop extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            step: 0,
            acceptedConditions: !props.showGtc || false,
            goingRight: true
        };

        this.goToStep = this.goToStep.bind(this);
        this.handleConditionsToggle = this.handleConditionsToggle.bind(this);
    }

    goToStep(targetStep: number) {
        if (targetStep > 5) {
            this.props.completeTutorial();
        }

        const step = clamp(targetStep, 1, STEPS_COUNT);

        this.setState((state) => ({ step, goingRight: state.step < step }));
    }

    handleConditionsToggle() {
        this.setState((state) => ({
            acceptedConditions: !state.acceptedConditions
        }));
    }

    render() {
        const { step, acceptedConditions, goingRight } = this.state;
        const { showGtc, acceptGtc, skipTutorial, open, animationProps } = this.props;

        const animationState = getAnimationState(open, step);

        const { scale, ...animationStyles } = animationProps;
        const interpolatedScale = scale.interpolate((scale) => `scale(${scale})`);

        return (
            <Modal
                show
                className={classNames('TutorialDesktop', {
                    'TutorialDesktop--step': step > 0
                })}
            >
                <animated.div
                    className="TutorialDesktop__max-width-container"
                    style={{ transform: interpolatedScale, ...animationStyles }}
                >
                    <div className="TutorialDesktop__container">
                        <TutorialDesktopContent
                            open={open}
                            step={step}
                            stepsCount={STEPS_COUNT}
                            animationState={animationState}
                            goingRight={goingRight}
                            showGtc={showGtc}
                            acceptedConditions={acceptedConditions}
                            acceptGtc={acceptGtc}
                            skipTutorial={skipTutorial}
                            goToStep={this.goToStep}
                            toggleConditions={this.handleConditionsToggle}
                        />

                        {step > 1 && (
                            <TutorialDesktopBigArrow
                                typeStyle="left"
                                onClick={() => {
                                    this.goToStep(step - 1);
                                }}
                            />
                        )}

                        {step > 0 && step < 5 && (
                            <TutorialDesktopBigArrow
                                typeStyle="right"
                                onClick={() => {
                                    this.goToStep(step + 1);
                                }}
                            />
                        )}

                        <TutorialDesktopVideo open={open} step={step} animationState={animationState} />
                        <TutorialDesktopBackground open={open} step={step} animationState={animationState} />
                    </div>
                </animated.div>
            </Modal>
        );
    }
}

export default TutorialDesktop;
