import * as React from 'react';

import { Value } from './constants';
import { icons } from './constants';
import { getNextValue } from './utils';

import './style.scss';

type Props = {
    children?: React.ReactNode;

    value: Value;
    onChange: (value: Value) => void;
};

class OrderControl extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event: React.MouseEvent) {
        event.preventDefault();

        this.props.onChange(getNextValue(this.props.value));
    }

    render() {
        const value = this.props.value === null ? 'default' : this.props.value;

        return (
            <button type="button" className="OrderControl" onClick={this.handleClick}>
                <span>{this.props.children}</span>

                <img className="OrderControl__icon" src={icons[value]} role="presentation" />
            </button>
        );
    }
}

export default OrderControl;
