import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { BREAKPOINT_MD } from '../../utils/viewport';

import { Candidate, CandidateId } from '../../modules/candidates/types';
import { isUnprocessed, isNotInteresting } from '../../modules/candidates/utils';
import { Job, JobId } from '../../modules/jobs/types';

import useBreakpointMatch from '../../hooks/useBreakpointMatch';

import MobilePageContainer from '../../components/MobilePageContainer';
import CandidateConversationNavigationBar from '../../components/CandidateConversationNavigationBar';
import ContentFrame from '../../components/ContentFrame';
import InfoBox from '../../components/InfoBox';

import CandidateConversation from '../CandidateConversation';

import './CandidateConversationPage.scss';
import MessageList from '../../components/MessageList';
import { ConversationParticipant, Message } from '../../modules/conversations/types';
import { Recruiter } from '../../modules/recruiters/types';
import CandidateApplicationMessageCallToActionText from '../../components/CandidateApplicationMessageCallToActionText';

export type CandidateConversationPageProps = {
    jobId: JobId;
    candidateId: CandidateId;
    recruiter: Recruiter | null;
    job: Job | null;
    candidate: Candidate | null;
};

function CandidateConversationPage({ jobId, candidateId, recruiter, job, candidate }: CandidateConversationPageProps) {
    const match = useBreakpointMatch(BREAKPOINT_MD);
    const isMobile = match === null;

    const nowIso = React.useMemo(() => {
        return new Date().toISOString();
    }, [recruiter]);

    let content: React.ReactElement | null = null;
    if (!!recruiter && !!candidate) {
        if (isUnprocessed(candidate) || isNotInteresting(candidate)) {
            const title = <FormattedMessage id="CANDIDATE_CONVERSATION_PAGE.NOT_UNLOCKED_NOTE.TITLE" />;

            if (candidate.application_message) {
                const fakeParticipants: ConversationParticipant[] = [
                    // Candidate
                    {
                        user_id: 2,
                        first_name: candidate.applicant.first_name,
                        last_name: candidate.applicant.last_name,
                        last_read: candidate.created,
                        portrait_url: candidate.applicant.portrait_file
                    },
                    // Recruiter
                    {
                        user_id: 1,
                        first_name: recruiter.first_name,
                        last_name: recruiter.last_name,
                        last_read: nowIso,
                        portrait_url: {
                            original: null,
                            small: null
                        }
                    }
                ];

                const fakeMessages: Message[] = [
                    {
                        id: 1,
                        conversation: 1,
                        created: candidate.created,
                        message_type: 'text',
                        owner: 2,
                        text: candidate.application_message,
                        private_for_owner: false
                    }
                ];

                content = (
                    <div className="CandidateConversationPage__message-list">
                        <MessageList
                            messages={fakeMessages}
                            participants={fakeParticipants}
                            currentUser={{
                                id: -2
                            }}
                            useLegacyTheme={true}
                        >
                            <CandidateApplicationMessageCallToActionText />
                        </MessageList>
                    </div>
                );
            }

            if (!content) {
                content = (
                    <ContentFrame>
                        <InfoBox type="success" title={title}>
                            <p>
                                <FormattedMessage id="CANDIDATE_CONVERSATION_PAGE.NOT_UNLOCKED_NOTE.PARA_1" />
                            </p>
                            <p>
                                <FormattedMessage id="CANDIDATE_CONVERSATION_PAGE.NOT_UNLOCKED_NOTE.PARA_2" />
                            </p>
                            <p>
                                <FormattedMessage id="CANDIDATE_CONVERSATION_PAGE.NOT_UNLOCKED_NOTE.PARA_3" />
                            </p>
                        </InfoBox>
                    </ContentFrame>
                );
            }
        } else {
            content = (
                <CandidateConversation
                    jobId={jobId}
                    candidateId={candidateId}
                    useDraft
                    useLegacyTheme
                    isMobile={isMobile}
                />
            );
        }
    }

    if (isMobile) {
        return (
            <MobilePageContainer show>
                <CandidateConversationNavigationBar candidate={candidate} job={job} />
                {content}
            </MobilePageContainer>
        );
    } else {
        return <div className="CandidateConversationPage CandidateConversationPage--desktop">{content}</div>;
    }
}

export default CandidateConversationPage;
