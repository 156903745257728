import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import CandidateResumeSection from '../CandidateResumeSection';

import { Applicant } from '../../../modules/candidates/types';

type Props = {
    applicant: Applicant;
};

const ResumeQualifications = ({ applicant }: Props) => {
    const { resume } = applicant;
    const qualifications = resume.qualifications;

    if (qualifications.length === 0) {
        return null;
    }

    return (
        <CandidateResumeSection>
            <h1>
                <FormattedMessage id="RESUME_QUALIFICATIONS.TITLE" />
            </h1>

            <ul>
                {qualifications.map((qualification, index) => (
                    <ResumeQualificationsListItem key={index} qualification={qualification} />
                ))}
            </ul>
        </CandidateResumeSection>
    );
};

const ResumeQualificationsListItem = ({ qualification }) => {
    return (
        <li>
            <span>{qualification.description}</span>
        </li>
    );
};

export default ResumeQualifications;
