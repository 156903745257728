import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { getLoginRoute } from '../../routes';

import PageContainer from '../../components/PageContainer';
import Headline from '../../components/Headline';
import HeadlineTitle from '../../components/Headline/HeadlineTitle';

import { State as ReduxState } from '../../store/reducer';

import { RequestStatus } from '../../modules/types';
import { requestPasswordReset, resetPasswordReset } from '../../modules/authentication/actions';
import { getResetPasswordRequestStatus } from '../../modules/authentication/selectors';

import ResetPasswordPageForm, { FormValues } from './ResetPasswordPageForm';

import './ResetPasswordPage.style.scss';

type Props = {
    requestStatus: RequestStatus;
    requestPasswordReset: (email: string) => void;
    resetPasswordReset: () => void;
};

export type State = {
    email: string;
};

class ResetPasswordPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            email: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.resetPasswordReset();
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ email: event.target.value });
    }

    handleSubmit(formValues: FormValues) {
        this.props.requestPasswordReset(formValues.email);
    }

    render() {
        const { requestStatus } = this.props;

        let titleId = '';
        let instructionsId = '';
        let instructionsValues = {};

        if (requestStatus !== 'requested') {
            titleId = 'RESET_PASSWORD.TITLE';
            instructionsId = 'RESET_PASSWORD.INSTRUCTIONS';
        } else if (requestStatus === 'requested') {
            const loginLink = (
                <Link to={getLoginRoute()}>
                    <FormattedMessage id="RESET_PASSWORD.LOGIN_LINK" />
                </Link>
            );

            titleId = 'RESET_PASSWORD.TITLE.SUBMITTED';
            instructionsId = 'RESET_PASSWORD.INSTRUCTIONS.SUBMITTED';
            instructionsValues = {
                link: loginLink
            };
        }

        return (
            <PageContainer>
                <div className="ResetPasswordPage__small-container">
                    <Headline center>
                        <HeadlineTitle>
                            <FormattedMessage id={titleId} />
                        </HeadlineTitle>
                    </Headline>

                    <hr className="ResetPasswordPage__separator" />

                    <div className="ResetPasswordPage__instructions">
                        <FormattedMessage id={instructionsId} values={instructionsValues} />
                    </div>

                    <hr className="ResetPasswordPage__separator" />

                    <ResetPasswordPageForm
                        submitting={requestStatus === 'requesting'}
                        submitted={requestStatus === 'requested'}
                        submitFailed={requestStatus === 'failed'}
                        onSubmit={this.handleSubmit}
                    />
                </div>
            </PageContainer>
        );
    }
}

const mapStateToProps = (state: ReduxState) => ({
    requestStatus: getResetPasswordRequestStatus(state)
});

const mapDispatchToProps = {
    requestPasswordReset,
    resetPasswordReset
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
