import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Headline from '../Headline';
import HeadlineTitle from '../Headline/HeadlineTitle';

import { Intl } from '../../types/intl';
import { Recruiter } from '../../modules/recruiters/types';

type Props = {
    recruiter: Recruiter;
    intl: Intl;
};

const JobListPageTitle = (props: Props) => {
    const { recruiter, intl } = props;

    let title;

    if (!!recruiter && !!recruiter.gender && !!recruiter.last_name) {
        title = (
            <FormattedMessage
                id="JOB_LIST_PAGE_TITLE.PERSOANLIZED"
                values={{
                    salutation: intl.formatMessage({ id: `SALUTATION.${recruiter.gender}` }),
                    lastName: recruiter.last_name
                }}
            />
        );
    } else {
        title = <FormattedMessage id="JOB_LIST_PAGE_TITLE.FALLBACK" />;
    }

    return (
        <Headline center>
            <HeadlineTitle>{title}</HeadlineTitle>
        </Headline>
    );
};

export default injectIntl(JobListPageTitle);
