import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import JobList from '../JobList';
import ListEmpty from '../ListEmpty';
import JobListHeader from '../JobListHeader';
import Pagination from '../Pagination';
import { isFetchStatusLoading } from '../../services/api/utils';

import { Job } from '../../modules/jobs/types';

import './style.scss';

type Props = {
    name: string;
    fetchStatus: 'none' | 'loading' | 'loaded' | 'failed';
    jobs: Job[];
    countOfPages: number;
    activePage: number;

    onChangeFilterAndOrdering: JobListHeader['props']['onChangeFilterAndOrdering'];

    filterOptions: JobListHeader['props']['filterOptions'];
    filterValues: JobListHeader['props']['filterValues'];
    onChangeFilters: JobListHeader['props']['onChangeFilters'];

    ordering: JobListHeader['props']['ordering'];
    onChangeOrdering: JobListHeader['props']['onChangeOrdering'];

    onClickCheckoutStart: (job: Job) => void;

    getPaginationLinkProps: Pagination['props']['getLinkProps'];
};

type State = {
    showPlaceholder: boolean;
};

const SKELETON_JOBS = [null, null, null];

class JobListPageSection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showPlaceholder: true
        };
    }

    componentDidMount() {
        this.setState({
            showPlaceholder: this.props.fetchStatus === 'none'
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.state.showPlaceholder) {
            return;
        }

        const wasLoading = isFetchStatusLoading(prevProps.fetchStatus);
        const isLoading = isFetchStatusLoading(this.props.fetchStatus);

        if (wasLoading !== isLoading) {
            this.setState({
                showPlaceholder: isLoading
            });
        }
    }

    render() {
        const {
            name,

            onChangeFilterAndOrdering,

            filterOptions,
            filterValues,
            onChangeFilters,

            ordering,
            onChangeOrdering
        } = this.props;

        return (
            <section className="JobListPageSection">
                <div className="JobListPageSection__header">
                    <h2 className="JobListPageSection__title">
                        <FormattedMessage id={`JOB_LIST_PAGE_SECTION.${name}.TITLE`} />
                    </h2>

                    <div className="JobListPageSection__list-header">
                        <JobListHeader
                            name={name}
                            onChangeFilterAndOrdering={onChangeFilterAndOrdering}
                            filterOptions={filterOptions}
                            filterValues={filterValues}
                            onChangeFilters={onChangeFilters}
                            ordering={ordering}
                            onChangeOrdering={onChangeOrdering}
                        />
                    </div>
                </div>
                {this.renderList(name)}
            </section>
        );
    }

    renderList(listName: string) {
        const { showPlaceholder } = this.state;

        if (showPlaceholder) {
            return <JobList skeleton={true} items={SKELETON_JOBS} />;
        }

        const { fetchStatus, jobs, countOfPages, activePage, onClickCheckoutStart } = this.props;

        if (!jobs.length) {
            return (
                <ListEmpty>
                    <FormattedMessage id={`JOB_LIST_PAGE_SECTION.${listName}.EMPTY_TEXT`} />
                </ListEmpty>
            );
        }

        let pagination: React.ReactNode = null;
        if (!showPlaceholder && countOfPages > 1) {
            pagination = (
                <Pagination
                    center
                    countOfPages={countOfPages}
                    activePage={activePage}
                    getLinkProps={this.props.getPaginationLinkProps}
                />
            );
        }

        return (
            <div>
                <JobList
                    items={jobs}
                    onClickCheckoutStart={onClickCheckoutStart}
                    isLoading={fetchStatus === 'loading'}
                />

                {pagination}
            </div>
        );
    }
}

export default JobListPageSection;
