export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg';

export const DEVICE_TYPE_MOBILE = 'mobile';
export const DEVICE_TYPE_DESKTOP = 'desktop';

export const BREAKPOINT_XS = 'xs';
export const BREAKPOINT_SM = 'sm';
export const BREAKPOINT_MD = 'md';
export const BREAKPOINT_LG = 'lg';

export const BREAKPOINTS = {
    [BREAKPOINT_XS]: '(min-width: 480px)',
    [BREAKPOINT_SM]: '(min-width: 768px)',
    [BREAKPOINT_MD]: '(min-width: 992px)',
    [BREAKPOINT_LG]: '(min-width: 1020px)'
};

export function getDeviceType() {
    return !matchesBreakpoint(BREAKPOINT_MD) ? DEVICE_TYPE_MOBILE : DEVICE_TYPE_DESKTOP;
}

export function getMediaQuery(breakpoint: Breakpoint): string {
    return BREAKPOINTS[breakpoint];
}

export function matchesBreakpoint(breakpoint: Breakpoint): boolean {
    const mq = getMediaQuery(breakpoint);
    return window.matchMedia(mq).matches;
}
