/* global google */
import { createSingleton as placesService } from '../../utils/singleton';
import { createQueue } from '../../utils/queue';
import { ensureIntialization } from './init';
import { handleError } from '../errorHandler';
import { GeocoderResult, resolvePlaceId } from './geocoder';

export type AutocompletionRequest = google.maps.places.AutocompletionRequest;

export type AutocompletionResult = google.maps.places.AutocompletePrediction;

export type AutocompletionResultWithGeocode = AutocompletionResult & GeocoderResult;

export enum AutocomplitionErrorCode {
    UNKNOWN = 'unknown'
}

const getGoogleMapsPlacesAutocompletionService = placesService(async () => {
    await ensureIntialization();

    return new window.google.maps.places.AutocompleteService();
});

const queue = createQueue<AutocompletionRequest, AutocompletionResult[]>({
    processData: async (data: AutocompletionRequest) => {
        try {
            return await autocompleteWithRetry(data);
        } catch (error) {
            handleError(error);
        }

        return [];
    }
});

export async function getAutocompleteResults(country, address) {
    const request: AutocompletionRequest = {
        input: address,
        componentRestrictions: {
            country: country
        },
        types: ['geocode', 'establishment']
    };

    return await queue.push(request);
}

export async function getAutocompleteResultsWithGeocode(country, address): Promise<AutocompletionResultWithGeocode[]> {
    const autocompleteResults = await getAutocompleteResults(country, address);

    const geocoderResults = await Promise.all(
        autocompleteResults.map((autocompleteResult) => {
            return resolvePlaceId(autocompleteResult.place_id).then((result) => {
                const geocoderResult = result as GeocoderResult;

                console.log({
                    autocompleteResult,
                    geocoderResult
                });

                const types = Array.from(new Set([...autocompleteResult.types, ...geocoderResult.types]));

                return {
                    ...autocompleteResult,
                    ...geocoderResult,
                    types
                };
            });
        })
    );

    return geocoderResults;
}

async function autocompleteWithRetry(request: AutocompletionRequest): Promise<AutocompletionResult[]> {
    const maxRetries = 3;
    for (let retries = 0; retries < maxRetries; retries++) {
        try {
            return await autocomplete(request);
        } catch (error) {
            if (isAutocompletionStatus(error)) {
                const status = error;

                if (retries < maxRetries && isRetryableAutocompletionStatus(status)) {
                    continue;
                }

                throw AutocompletionError.fromStatus(status);
            }

            throw error;
        }
    }

    return [];
}

async function autocomplete(request: AutocompletionRequest): Promise<AutocompletionResult[]> {
    const service = await getGoogleMapsPlacesAutocompletionService();

    return new Promise<AutocompletionResult[]>((resolve, reject) => {
        service.getPlacePredictions(
            request,
            (
                results: google.maps.places.AutocompletePrediction[] | null,
                status: google.maps.places.PlacesServiceStatus
            ) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    resolve(results as google.maps.places.AutocompletePrediction[]);
                } else if (status === window.google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
                    resolve([]);
                } else {
                    reject(status);
                }
            }
        );
    });
}

class AutocompletionError extends Error {
    constructor(public code: AutocomplitionErrorCode) {
        super(`Could not resolve address (${code})`);
    }

    static fromStatus(status: google.maps.places.PlacesServiceStatus) {
        // Map has to live inside the function because `window.google.maps`
        // isn't available during boot time.
        const code = {
            [window.google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR]: AutocomplitionErrorCode.UNKNOWN
        }[status];

        return new AutocompletionError(code);
    }
}

function isAutocompletionStatus(status: any): status is google.maps.places.PlacesServiceStatus {
    return Object.values(window.google.maps.places.PlacesServiceStatus).includes(status);
}

function isRetryableAutocompletionStatus(status: google.maps.places.PlacesServiceStatus) {
    // Array has to live inside the function because `window.google.maps`
    // isn't available during boot time.
    return [
        window.google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT,
        window.google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR
    ].includes(status);
}
