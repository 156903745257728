import { ApplicationState } from '../../../store/types';
import { CandidateId } from '../../candidates/types';
import { getCandidate, getConversationsEntities } from '../../entities/selectors';
import { Conversation, ConversationId } from '../types';

export {
    getConversationRootState,
    getConversationState,
    getCandidateMessagesState,
    getConversationFetchStatus,
    getConversationUpdateStatus,
    getConversationPollingStatus,
    getConversationMessagesIds,
    getConversationMessagesFetchStatus,
    getConversationMessagesPollingStatus,
    getConversationMessages,
    getConversationMessagesByCandidates
} from './legacy';

export function getConversation(state: ApplicationState, conversationId: ConversationId): Conversation | null {
    return getConversationsEntities(state)[conversationId] ?? null;
}

export function getConversationIdByCandidateId(state: ApplicationState, candidateId: CandidateId) {
    const candidate = getCandidate(state, candidateId);

    if (!candidate) {
        return null;
    }

    return candidate.conversation_id;
}
