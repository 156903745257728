import * as React from 'react';

import FormFieldInput from '../FormFieldInput';
import { toArray } from '../../utils/array';
import { createMinLength } from '../../utils/form/validators';

const minLength = createMinLength(6);

type Props = Omit<React.ComponentProps<typeof FormFieldInput>, 'type' | 'id'> & {
    id: string;
};

const FormFieldPassword = ({ validate, ...rest }: Props): React.ReactElement => {
    const fullValidate = React.useMemo(() => {
        return [...toArray(validate || []), minLength];
    }, [validate]);

    return <FormFieldInput type="password" validate={fullValidate} {...rest} />;
};

export default FormFieldPassword;
