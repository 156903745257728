import React from 'react';
import { FormattedMessage } from 'react-intl';
import JobFormFunctionsFormFields, { JobFormFunctionsFormFieldsValue } from './JobFormFunctionsFormFields';
import JobFormTitleFormField, { JobFormTitleFormFieldValue } from './JobFormTitleFormField';
import JobFormIsStartupFormField, { JobFormIsStartupFormFieldValue } from './JobFormIsStartupFormField';

export type JobFormAdDetailsFormFieldsetValues = {
    title: JobFormTitleFormFieldValue;
    is_startup: JobFormIsStartupFormFieldValue;
    function: JobFormFunctionsFormFieldsValue;
};

export type JobFormAdDetailsFormFieldsetProps = {
    isTitleEditable: boolean;
    isFunctionsEditable: boolean;
};

function JobFormAdDetailsFormFieldset({ isTitleEditable, isFunctionsEditable }: JobFormAdDetailsFormFieldsetProps) {
    return (
        <fieldset className="panel">
            <div className="panel-heading">
                <h2 className="panel-title">
                    <FormattedMessage id="JOB_EDIT.AD_DETAILS_FORM_FIELDSET.TITLE" />
                </h2>
            </div>

            <div className="panel-body">
                <JobFormTitleFormField isTitleEditable={isTitleEditable} />

                <JobFormIsStartupFormField />

                <JobFormFunctionsFormFields isFunctionsEditable={isFunctionsEditable} />
            </div>
        </fieldset>
    );
}

export default JobFormAdDetailsFormFieldset;
