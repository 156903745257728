import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Headline from '../Headline';
import HeadlineTitle from '../Headline/HeadlineTitle';
import Button from '../Button';
import { getSignupRoute } from '../../routes';

import './style.scss';

type Props = {
    recruiterIsSignedUp: boolean;
};

class JobDetailsCreateAccount extends React.Component<Props> {
    render() {
        const { recruiterIsSignedUp } = this.props;

        if (recruiterIsSignedUp) {
            return null;
        }

        return (
            <aside className="JobDetailsCreateAccount">
                <Headline>
                    <HeadlineTitle>
                        <FormattedMessage id="JOB_DETAILS_CREATE_ACCOUNT.TITLE" />
                    </HeadlineTitle>
                </Headline>

                <p className="JobDetailsCreateAccount__description">
                    <FormattedMessage id="JOB_DETAILS_CREATE_ACCOUNT.DESCRIPTION" />
                </p>

                <Button component={Link} to={getSignupRoute()} typeStyle="raised" variationStyle="brand">
                    <FormattedMessage id="JOB_DETAILS_CREATE_ACCOUNT.CTA" />
                </Button>
            </aside>
        );
    }
}

export default JobDetailsCreateAccount;
