import { toEntries } from '../../utils/object';

export function filtersOrOrderingActive(values) {
    const { ordering: orderingValue, ...filterValues } = values;

    if (!!orderingValue) {
        return true;
    }

    return toEntries(filterValues).some(({ value }) => !!value.length);
}
