import { createAction as createActionLegacy } from 'redux-actions';
import { createAction, createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';

import { Job } from '../jobs/types';
import { Errors } from '../types';

const createType = createActionTypeCreator('CHECKOUT');

export const INIT_CHECKOUT_PROCESS_STATES = createType('INIT/CHECKOUT_PROCESS_STATES');
export const initializeCheckoutProcessStates = createActionLegacy(
    INIT_CHECKOUT_PROCESS_STATES,
    (checkoutProcessStates) => ({
        checkoutProcessStates
    })
);

export const SAVE_CHECKOUT_PROCESS_STATE = createType('SAVE_CHECKOUT_PROCESS_STATE');
export const saveCheckoutProcessState = createActionLegacy(SAVE_CHECKOUT_PROCESS_STATE, (checkoutProcessState) => ({
    checkoutProcessState
}));

export const START_CHECKOUT = createType('START_CHECKOUT');
export const startCheckout = createAction(
    START_CHECKOUT,
    (method: string, args = {}, replaceLocation: boolean = false) => {
        return {
            payload: {
                method,
                args,
                replaceLocation
            }
        };
    }
);
export type StartCheckoutAction = ReturnType<typeof startCheckout>;

export const EXECUTE_CHECKOUT = createType('EXECUTE_CHECKOUT');
export const executeCheckout = createActionLegacy(EXECUTE_CHECKOUT, (checkoutProcessState) => {
    return {
        checkoutProcessState
    };
});

export const CHECKOUT_CREATE = createRequestActionTypes(createType('CHECKOUT_CREATE'));
export const checkoutCreate = {
    request: createActionLegacy(CHECKOUT_CREATE.REQUEST, (localId: string) => ({
        localId
    })),
    success: createActionLegacy(CHECKOUT_CREATE.SUCCESS, (localId: string) => ({
        localId
    })),

    failure: createActionLegacy(CHECKOUT_CREATE.FAILURE, (localId: string, errors: Errors = []) => ({
        localId,
        errors
    }))
};

export function startAdvertiseCheckout(job: Job): Object {
    return startCheckout(
        'advertise',
        {
            job
        },
        false
    );
}

export function startBulkUpCheckout(replaceLocation?: boolean) {
    return startCheckout('bulk-up', {}, replaceLocation);
}
