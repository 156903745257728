export type Params = {
    pageSize: number;
    page: number;
    state?: string[];
    ordering?: string[];
    hasMessagesUnreadByRecruiter?: boolean;
};

type ListName = 'withoutNotInteresting' | 'withNewMessages' | 'notInteresting' | 'nextNewForCurrentJob';

const PARAMS_BY_LIST_NAME: { [listName in ListName]: Params } = {
    withoutNotInteresting: {
        pageSize: 20,
        page: 1,
        state: ['new', 'locked', 'interesting', 'phone-interview', 'interview', 'hired'],
        ordering: ['-is_new', '-messages_unread_by_recruiter', '-last_interaction']
    },

    withNewMessages: {
        pageSize: 20,
        page: 1,
        state: ['new', 'locked', 'interesting', 'phone-interview', 'interview', 'hired'],
        hasMessagesUnreadByRecruiter: true,
        ordering: ['-is_new', '-messages_unread_by_recruiter', '-last_interaction']
    },

    notInteresting: {
        pageSize: 20,
        page: 1,
        state: ['not-interesting']
    },

    nextNewForCurrentJob: {
        pageSize: 1,
        page: 1,
        state: ['new']
    }
};

export function getParamsByListName(listName: string) {
    return PARAMS_BY_LIST_NAME[listName];
}
