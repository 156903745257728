import * as Sentry from '@sentry/browser';

import { isEnabled } from './lib/cookieStorage/cookie';

import { isLocalStorageSupported, isSessionStorageSupported } from './utils/storage';

Sentry.configureScope((scope) => {
    // Ensure that the values are primiteves so that they can't be trimmed by Sentry in the output.
    const diagnostics = {
        cookie: isEnabled() ? 'supported' : 'not supported',
        localStorage: isLocalStorageSupported ? 'supported' : 'not supported',
        sessionStorage: isSessionStorageSupported ? 'supported' : 'not supported',

        adbock: global.hasAdBlock
    };

    scope.setExtra('diagnostics', diagnostics);
});
