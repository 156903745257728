import * as React from 'react';
import classNames from 'classnames';
import range from 'just-range';
import { Link } from 'react-router-dom';
import { LocationDescriptor } from 'history';

import { getFirstPageToRenderBeforeActivePage, getLastPageToRenderBeforeActivePage } from './utils';

import './style.scss';

type Props = {
    activePage: number;
    countOfPages: number;

    getLinkProps: (page: number) => { to: LocationDescriptor };

    center?: boolean;
};

class Pagination extends React.Component<Props> {
    render() {
        const activePage = this.props.activePage;
        const countOfPages = this.props.countOfPages;

        const firstPageToRender = Math.max(
            // We want to skip the first page because it will be rendered separatly
            2,
            getFirstPageToRenderBeforeActivePage(countOfPages, activePage)
        );

        const lastPageToRender = Math.min(
            // We want to skip the last page because it will be rendered separatly
            countOfPages - 1,
            getLastPageToRenderBeforeActivePage(countOfPages, activePage)
        );

        const className = classNames('Pagination', {
            'Pagination--center': !!this.props.center
        });

        return (
            <div className={className}>
                <ul className="pagination">
                    {this.renderBackButton()}

                    {countOfPages >= 1 && this.renderButton(1, 'first')}

                    {firstPageToRender > 2 && this.renderSeparator()}

                    {/* Render all the clickable page items */}
                    {countOfPages > 2 && this.renderRange(firstPageToRender, lastPageToRender)}

                    {lastPageToRender < countOfPages - 1 && this.renderSeparator()}

                    {countOfPages > 1 && this.renderButton(countOfPages, 'last')}

                    {this.renderForwardButton()}
                </ul>
            </div>
        );
    }

    renderBackButton() {
        const activePage = this.props.activePage;

        let content = <span>{'\u00AB'}</span>;

        if (activePage > 1) {
            const linkProps = this.props.getLinkProps(activePage - 1);

            content = <Link {...linkProps}>{content}</Link>;
        }

        const className = classNames({
            disabled: activePage <= 1
        });

        return <li className={className || undefined}>{content}</li>;
    }

    renderForwardButton() {
        const activePage = this.props.activePage;
        const countOfPages = this.props.countOfPages;

        let content = <span>{'\u00BB'}</span>;

        if (activePage < countOfPages) {
            const linkProps = this.props.getLinkProps(activePage + 1);

            content = <Link {...linkProps}>{content}</Link>;
        }

        const className = classNames({
            disabled: activePage >= countOfPages
        });

        return <li className={className || undefined}>{content}</li>;
    }

    renderButton(page: number, id?: string) {
        const activePage = this.props.activePage;

        const className = classNames({ active: activePage === page });
        const linkProps = this.props.getLinkProps(page);

        return (
            <li key={page} id={id} className={className || undefined}>
                <Link {...linkProps}>{page}</Link>
            </li>
        );
    }

    renderRange(firstPageToRender: number, lastPageToRender: number) {
        const pagesToRender = range(firstPageToRender, lastPageToRender + 1);

        return pagesToRender.map((page) => this.renderButton(page));
    }

    renderSeparator() {
        return (
            <li className="disabled">
                <span>...</span>
            </li>
        );
    }
}

export default Pagination;
