import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import CandidateRating from '../CandidateRating';
import CandidateDetails from '../CandidateDetails';
import CandidateStateProgressBar from '../CandidateStateProgressBar';

import { getCandidateRoute } from '../../routes';
import { isNotLockedAndAtLeastInteresting, isExpiring } from '../../modules/candidates/utils';

import './CandidateListItem.style.scss';

import { Candidate } from '../../modules/candidates/types';
import FormattedLongNumericDate from '../FormattedLongNumericDate';

type Props = {
    jobId?: number;
    candidate: Candidate | null;
    skeleton?: boolean;
    showProgress?: boolean;
    onClickExpirationLink?: (candidateId: number) => void;
};

class CandidateListItem extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleClickExpirationLink = this.handleClickExpirationLink.bind(this);
    }

    handleClickExpirationLink(event: React.MouseEvent<HTMLElement, MouseEvent>) {
        event.preventDefault();
        const { candidate, onClickExpirationLink } = this.props;

        if (!!candidate && !!candidate.id && !!onClickExpirationLink) {
            onClickExpirationLink(candidate.id);
        }
    }

    renderRating() {
        const { skeleton, candidate } = this.props;

        return (
            <div className="CandidateListItem__rating">
                {!skeleton && !!candidate && <CandidateRating candidate={candidate} />}
            </div>
        );
    }

    renderCreatedDate() {
        const { skeleton, candidate } = this.props;

        return (
            <div className="CandidateListItem__created-date">
                {!skeleton && !!candidate && <FormattedLongNumericDate value={candidate.created} />}
            </div>
        );
    }

    renderDetails() {
        const { skeleton, candidate, showProgress } = this.props;

        return (
            <div className="CandidateListItem__details">
                <CandidateDetails
                    candidate={candidate}
                    showProgress={showProgress}
                    showNewBadge
                    showUnreadMessageBadge
                    skeleton={skeleton}
                    fadedWhenExpiring
                />
            </div>
        );
    }

    renderProgress() {
        const { showProgress, candidate } = this.props;

        if (showProgress) {
            return (
                <div className="CandidateListItem__progress">
                    <CandidateStateProgressBar candidate={candidate} />
                </div>
            );
        }

        return null;
    }

    renderExpirationExplanation() {
        const { jobId, candidate } = this.props;

        if (!!jobId && !!candidate && isExpiring(candidate)) {
            let link;
            if (isNotLockedAndAtLeastInteresting(candidate)) {
                link = (
                    <button
                        type="button"
                        className="btn btn-link CandidateListItem__expiration-explanation-link"
                        onClick={this.handleClickExpirationLink}
                    >
                        <FormattedMessage id="CANDIDATE_LIST_ITEM.EXPIRATION_EXPLANATION.LINK.UNLOCKED" />
                    </button>
                );
            } else {
                link = (
                    <button type="button" className="btn btn-link CandidateListItem__expiration-explanation-link">
                        <FormattedMessage id="CANDIDATE_LIST_ITEM.EXPIRATION_EXPLANATION.LINK.LOCKED" />
                    </button>
                );
            }

            return (
                <div className="CandidateListItem__expiration-explanation">
                    <FormattedMessage
                        id="CANDIDATE_LIST_ITEM.EXPIRATION_EXPLANATION"
                        values={{
                            link
                        }}
                    />
                </div>
            );
        }
    }

    render() {
        const { candidate, jobId, skeleton, showProgress } = this.props;

        const classname = classNames('CandidateListItem', {
            'CandidateListItem--skeleton': skeleton,
            'CandidateListItem--skeleton-without-progress': !showProgress,
            'CandidateListItem--faded': !!candidate && isExpiring(candidate)
        });

        if (!skeleton && !!candidate && !!jobId) {
            return (
                <Link to={getCandidateRoute(jobId, candidate.id)} className={classname}>
                    {this.renderRating()}
                    {this.renderCreatedDate()}
                    {this.renderDetails()}
                    {this.renderProgress()}
                    {this.renderExpirationExplanation()}
                </Link>
            );
        } else {
            return (
                <div className={classname}>
                    {this.renderRating()}
                    {this.renderCreatedDate()}
                    {this.renderDetails()}
                    {this.renderProgress()}
                    {this.renderExpirationExplanation()}
                </div>
            );
        }
    }
}

export default CandidateListItem;
