import React from 'react';
import { FormSpy } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

import { GeocoderResult } from '../../../services/maps/geocoder';
import JobFormFieldAnchor from '../JobFormFieldAnchor';

import JobFormLocationFormField, {
    JobFormLocationFormFieldValue,
    JOB_EDIT_LOCATION_FORM_FIELD_NAME
} from '../JobFormLocationFormField';
import JobFormLocationMap from '../JobFormLocationMap';
import JobFormRemotePossibleFormField from '../JobFormRemotePossibleFormField';

import './JobFormLocationFormFieldset.scss';
import JobFormLocationFormFieldsetContext from './JobFormLocationFormFieldsetContext';

export type JobFormLocationFormFieldsetValues = {
    location: JobFormLocationFormFieldValue;
};

export type JobFormLocationFormFieldsetProps = {
    isLocationEditable: boolean;
};

function JobFormLocationFormFieldset({ isLocationEditable }: JobFormLocationFormFieldsetProps) {
    const { JobFormLocationRegionDisplayFieldContainer, JobFormLocationRegionWarningFieldContainer } = React.useContext(
        JobFormLocationFormFieldsetContext
    );

    const [currentAddress, setCurrentAddress] = React.useState<GeocoderResult | null>(null);

    const onChangeAddress = (address: GeocoderResult | null) => {
        setCurrentAddress(address);
    };

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_LOCATION_FORM_FIELD_NAME} />
            <fieldset className="panel">
                <div className="panel-heading">
                    <h2 className="panel-title">
                        <FormattedMessage id="JOB_EDIT.LOCATION_FORM_FIELDSET.TITLE" />
                    </h2>
                    <span className="panel-subtitle">
                        <FormattedMessage id="JOB_EDIT.LOCATION_FORM_FIELDSET.SUBTITLE" />
                    </span>
                </div>

                <div className="panel-body">
                    <div className="tf-form-row JobFormLocationFormFieldset__location-field-group">
                        <div className="JobFormLocationFormFieldset__location-field-input">
                            <JobFormLocationFormField
                                isEditable={isLocationEditable}
                                onChangeAddress={onChangeAddress}
                            />
                        </div>
                        <div className="JobFormLocationFormFieldset__location-field-job-region">
                            <JobFormLocationRegionDisplayFieldContainer />
                        </div>
                    </div>
                    <div className="tf-form-row JobFormLocationFormFieldset__remote-possible-field">
                        <JobFormRemotePossibleFormField />
                    </div>
                    <FormSpy subscription={{ values: true }}>
                        {({ values }) => {
                            const location = values[JOB_EDIT_LOCATION_FORM_FIELD_NAME] as JobFormLocationFormFieldValue;

                            return <JobFormLocationMap location={location} currentAddress={currentAddress} />;
                        }}
                    </FormSpy>

                    <JobFormLocationRegionWarningFieldContainer />
                </div>
            </fieldset>
        </React.Fragment>
    );
}

export default JobFormLocationFormFieldset;
