import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatToMonthInYear } from '../../../utils/date';
import { deprefixHttp, prefixHttp } from '../../../utils/url';

import CandidateResumeSection from '../CandidateResumeSection';
import CandidateResumeItem from '../CandidateResumeItem';

import { Applicant } from '../../../modules/candidates/types';

type Props = {
    applicant: Applicant;
};

const ResumeVolunteers = ({ applicant }: Props) => {
    const { resume } = applicant;
    const volunteers = resume.volunteer;

    if (volunteers.length === 0) {
        return null;
    }

    return (
        <CandidateResumeSection>
            <h1>
                <FormattedMessage id="RESUME_VOLUNTEERS.TITLE" />
            </h1>

            <ul>
                {volunteers.map((volunteer, index) => (
                    <ResumeVolunteersListItem key={index} volunteer={volunteer} />
                ))}
            </ul>
        </CandidateResumeSection>
    );
};

const ResumeVolunteersListItem = ({ volunteer }) => {
    return (
        <CandidateResumeItem
            label={
                <span>
                    {!!volunteer.start_date && (
                        <time dateTime={volunteer.start_date}>
                            <span>{formatToMonthInYear(volunteer.start_date)}</span>
                        </time>
                    )}
                    {!volunteer.start_date ? '?' : null}
                    &ndash;
                    {!!volunteer.end_date && (
                        <time dateTime={volunteer.end_date}>
                            <span>{formatToMonthInYear(volunteer.end_date)}</span>
                        </time>
                    )}
                    {!volunteer.end_date && (
                        <time>
                            <FormattedMessage id="RESUME_VOLUNTEERS.DATE.VALUE_NOW" />
                        </time>
                    )}
                </span>
            }
        >
            <div>
                <strong>
                    <FormattedMessage
                        id="RESUME_VOLUNTEERS.POSITION_ORGANISATION.VALUE"
                        values={{
                            position: volunteer.position,
                            organisation: volunteer.organisation
                        }}
                    />
                </strong>
            </div>
            {!!volunteer.website && (
                <div>
                    <i>
                        <a href={prefixHttp(volunteer.website)} target="_blank" rel="noopener">
                            {deprefixHttp(volunteer.website)}
                        </a>
                    </i>
                </div>
            )}

            {!!volunteer.summary && (
                <div>
                    <span>{volunteer.summary}</span>
                </div>
            )}
        </CandidateResumeItem>
    );
};

export default ResumeVolunteers;
