import * as React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import classNames from 'classnames';

import downloadIcon from '../../assets/images/ic-download-thick-bottom-bold.svg';

import { FILE_CATEGORIES } from '../../modules/files/constants';
import { File } from '../../modules/files/types';
import { getFileTypeFromMimeType, getFormattedFileName, getFileCategoryTranslationId } from '../../modules/files/utils';

import { longNumericDateTimeFormatOptions } from '../../utils/date';

import DocumentTypeIcon from '../DocumentTypeIcon';

import './DocumentItem.style.scss';

type Props = {
    file: File;
    onClickDownload: (file: File) => void;
    intl: IntlShape;
};

class DocumentItem extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.downloadFile = this.downloadFile.bind(this);
    }

    downloadFile() {
        const { file, onClickDownload } = this.props;

        onClickDownload(file);
    }

    render() {
        const { file, intl } = this.props;

        const className = classNames('DocumentItem', {
            'DocumentItem--highlight': !!file.highlight
        });

        const fileType = getFileTypeFromMimeType(file.mime_type);
        const formattedFileName = getFormattedFileName(file);
        const formattedFileLastSharedDate = !!file.last_shared
            ? intl.formatDate(file.last_shared, longNumericDateTimeFormatOptions)
            : null;
        const fileCategory = intl.formatMessage({
            id: getFileCategoryTranslationId(file.category)
        });

        const formattedFileMeta =
            file.category === FILE_CATEGORIES.GENERATED_RESUME
                ? fileCategory
                : [formattedFileLastSharedDate, fileCategory].filter(Boolean).join(' | ');

        return (
            <div className={className}>
                <div className="DocumentItem__icon">
                    <DocumentTypeIcon type={fileType} />
                </div>

                <div className="DocumentItem__info">
                    <span className="DocumentItem__info__name">{formattedFileName}</span>
                    <span className="DocumentItem__info__meta">{formattedFileMeta}</span>
                </div>

                <button
                    className="DocumentItem__download-button"
                    onClick={this.downloadFile}
                    title={intl.formatMessage({
                        id: 'DOCUMENT_ITEM.ACTION.DOWNLOAD'
                    })}
                >
                    <img src={downloadIcon} role="presentation" />
                </button>
            </div>
        );
    }
}

export default injectIntl(DocumentItem);
