import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Input } from '@truffls/design-system-react';
import DatePicker from 'react-datepicker';

import './DateInput.scss';
import useBreakpointMatch from '../../hooks/useBreakpointMatch';

type Props = {
    bare?: boolean;

    className?: string;
    name?: string;
    id?: string;
    value: string | unknown;
    required?: boolean;
    disabled?: boolean;

    inputRef?: React.Ref<any>;

    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onFocus?: () => void;
    onBlur?: () => void;
};

const DateInput = React.forwardRef<HTMLInputElement, Props>(function DateInput(
    { className: ownClassName, bare, id, name, required, disabled, onChange, onFocus, onBlur, value }: Props,
    ref: React.Ref<HTMLInputElement>
) {
    const { locale } = useIntl();
    const breakpointMatch = useBreakpointMatch('sm');

    const valueAsDate = React.useMemo(() => {
        return typeof value === 'string' && !!value ? new Date(value) : null;
    }, [value]);

    const ownRef = React.useRef();
    const combinedRef = useCombinedRef(ref, ownRef);

    const className = classNames('DateInput', ownClassName);

    const handleChange = (date: Date) => {
        // We convert the date into a UTC date to ensure that we have the right date regardless of the timezone which
        // .toISOString() uses to output a UTC date string.
        const dateInUTC = new Date(
            Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
                date.getMilliseconds()
            )
        );

        // The design system expects to get an event but we don't have an event here so we construct the ciritical
        // structure here.
        const fakeEvent = {
            target: {
                name,
                value: dateInUTC.toISOString().split('T')[0]
            }
        } as React.ChangeEvent<HTMLInputElement>;

        onChange(fakeEvent);
    };

    const handleSelect = () => {
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <div className="DateInput">
            <DatePicker
                dateFormat="dd.MM.yyyy"
                locale={locale}
                id={id}
                name={name}
                required={required}
                disabled={disabled}
                selected={valueAsDate}
                onFocus={onFocus}
                onBlur={onBlur}
                onSelect={handleSelect}
                onChange={handleChange}
                customInput={<InputWrapper bare={bare} inputRef={combinedRef} />}
                calendarClassName="DateInput__calendar"
                popperClassName="DateInput__popper"
                popperModifiers={{
                    placements: 'left'
                }}
                wrapperClassName={className}
                withPortal={!breakpointMatch}
                portalId={`${id}-calendar-portal`}
            />
        </div>
    );
});

export default DateInput;

type InputWrapperProps = {
    bare?: boolean;
    inputRef: React.Ref<any>;
    className?: string;
};

const InputWrapper = React.forwardRef(({ inputRef, className, ...props }: InputWrapperProps, ref) => {
    const combinedInputRef = useCombinedRef(ref, inputRef);

    return (
        <Input
            ref={combinedInputRef}
            className={classNames('DateInput__input', className)}
            {...props}
            autoComplete="off"
        />
    );
});

if (process.env.NODE_ENV !== 'production') {
    InputWrapper.displayName = 'InputWrapper';
}

function useCombinedRef(...refs) {
    return React.useMemo(() => {
        const allNull = refs.every((ref) => ref === null);

        if (allNull) {
            return null;
        }

        return (refValue) => {
            refs.forEach((ref) => {
                setRef(ref, refValue);
            });
        };
    }, refs);
}

function setRef(ref, value) {
    if (typeof ref === 'function') {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
}
