export function loadImage(src): Promise<void> {
    const img = new Image();
    img.src = src;

    if (typeof img.decode === 'function') {
        return img.decode();
    }

    return new Promise<void>((resolve, reject) => {
        img.addEventListener('load', () => resolve());
        img.addEventListener('error', () => reject());
    });
}

export const load = loadImage;
