import * as React from 'react';
import classNames from 'classnames';

import './ButtonAsLink.style.scss';

type Props = {
    children: React.ReactNode;
    className?: string;
    onClick: () => void;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

function ButtonAsLink({ children, onClick, className, type = 'button' }: Props) {
    return (
        <button type={type} className={classNames('ButtonAsLink', className)} onClick={onClick}>
            {children}
        </button>
    );
}

export default ButtonAsLink;
