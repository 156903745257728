import * as Sentry from '@sentry/browser';
import { User } from '../../modules/users/types';

export function setUser(user: User) {
    Sentry.configureScope((scope) => {
        scope.setUser({
            id: String(user.id)
        });
    });
}

export function unsetUser() {
    Sentry.configureScope((scope) => {
        scope.setUser({});
    });
}
