import React from 'react';
import classNames from 'classnames';

import './Paper.scss';

type PaperProps = React.PropsWithChildren<{
    className?: string;
    elevation?: 0 | 1 | 2;
}>;

const Paper = React.forwardRef<HTMLDivElement, PaperProps>(function Paper(
    { children, className, elevation = 1 }: PaperProps,
    ref
) {
    return (
        <div
            ref={ref}
            className={classNames(
                'Paper',
                {
                    [`Paper--elevation-${elevation}`]: elevation > 0
                },
                className
            )}
        >
            {children}
        </div>
    );
});

export default Paper;
