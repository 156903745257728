function HTTPResponseError(data, status, headers, config) {
    this.name = 'HTTPResponseError';

    if (Error.captureStackTrace) {
        Error.captureStackTrace(this, HTTPResponseError);
    } else {
        this.stack = new Error().stack || '';
    }

    this.extra = {};

    const request = {};
    const response = {};

    request.method = config.method;
    request.url = config.url;
    request.params = config.params;
    request.headers = typeof config.headers === 'function' ? headers() : config.headers;
    request.data = config.data || null;

    response.status = status;
    response.headers = typeof headers === 'function' ? headers() : headers;
    response.data = data || null;

    this.message = `Status code ${response.status} from url ${request.url}`;

    this.extra = {
        'request.method': request.method,
        'request.url': request.url,
        'request.params': request.params,
        'request.headers': request.headers,
        'request.data': request.data,

        'response.status': response.status,
        'response.headers': response.headers,
        'response.data': response.data
    };
}

HTTPResponseError.prototype = Object.create(Error.prototype);
HTTPResponseError.prototype.constructor = HTTPResponseError;

module.exports = HTTPResponseError;
