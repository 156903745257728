import React from 'react';

export default function useCombinedRef<Type>(...refs: React.Ref<Type>[]): React.Ref<Type> {
    return React.useMemo(() => {
        const allNull = refs.every((ref) => ref === null);

        if (allNull) {
            return null;
        }

        return (refValue) => {
            refs.forEach((ref) => {
                setRef(ref, refValue);
            });
        };
    }, refs);
}

function setRef<Type>(ref: React.Ref<Type>, value: Type | null) {
    if (typeof ref === 'function') {
        ref(value);
    } else if (ref) {
        (ref as any).current = value;
    }
}
