import * as React from 'react';

import { JobSubset } from '../../modules/jobs/types';
import { isTrial } from '../../modules/jobs/utils';

import { FormattedMessage } from 'react-intl';
import ProductType from '../ProductType';

export type JobTypeProps = {
    job: JobSubset<'is_premium' | 'product'>;
};

function JobType({ job }: JobTypeProps) {
    if (isTrial(job)) {
        return <FormattedMessage id="CONTSTANT.JOB_TRIAL" />;
    }

    return <ProductType product={job.product} />;
}

export default JobType;
