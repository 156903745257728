import { useEffect, useMemo, useReducer } from 'react';
import { loadImage } from '../utils/image';

const ACTION_LOADING = 'LOADING';
const ACTION_LOADED = 'LOADED';
const ACTION_ERROR = 'ERROR';

const STATUS_LOADING = 'loading';
const STATUS_LOADED = 'loaded';
const STATUS_ERROR = 'error';

type UseImageProps = {
    src: string | null;
};

type UseImageState = {
    src: string | null;
    status: typeof STATUS_LOADING | typeof STATUS_LOADED | typeof STATUS_ERROR;
};

type UseImageReturn = {
    src: string | null;
    loading: boolean;
    loaded: boolean;
    error: boolean;
};

function useImage({ src }: UseImageProps): UseImageReturn {
    const initialState = useMemo((): UseImageState => {
        const initAction = !!src ? { type: ACTION_LOADING } : { type: ACTION_ERROR };

        return reducer(undefined, initAction);
    }, []);

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (!src) {
            dispatch({ type: ACTION_ERROR });

            return;
        }

        dispatch({ type: ACTION_LOADING });

        let active = true;
        loadImage(src).then(
            () => {
                if (!active) {
                    return;
                }

                dispatch({ type: ACTION_LOADED, src: src });
            },
            () => {
                if (!active) {
                    return;
                }

                dispatch({ type: ACTION_ERROR });
            }
        );

        return () => {
            active = false;
        };
    }, [src]);

    return {
        src: state.src,
        loading: state.status === STATUS_LOADING,
        loaded: state.status === STATUS_LOADED,
        error: state.status === STATUS_ERROR
    } as UseImageReturn;
}

const defaultInitialState: UseImageState = {
    status: STATUS_LOADING,
    src: null
};

function reducer(state: UseImageState = defaultInitialState, action): UseImageState {
    switch (action.type) {
        case ACTION_LOADING:
            return {
                ...state,
                status: STATUS_LOADING
            };

        case ACTION_LOADED:
            return {
                ...state,
                status: STATUS_LOADED,
                src: action.src
            };

        case ACTION_ERROR:
            return {
                ...state,
                status: STATUS_ERROR,
                src: null
            };

        default:
            return state;
    }
}

export default useImage;
