import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';

import { isEnabled } from '../lib/cookieStorage/cookie';
import { createLogger } from '../utils/logger';
import { cookieStorageKeyPrefix } from '../constants';

export const COOKIE_NAME = `${cookieStorageKeyPrefix}accessToken`;
export const EXPIRATION_IN_DAYS: number = 28;

type CookieOptions = {
    expires?: typeof EXPIRATION_IN_DAYS;
};

type State = {
    cookiesEnabled: boolean;
    apiToken: string | null;
};

// XXX The state is not testable
const state: State = {
    cookiesEnabled: isEnabled(),
    apiToken: null
};

const logger = createLogger('services/apiTokenStorage');

function serialize(value) {
    return JSON.stringify(value);
}

function deserialize(value) {
    return JSON.parse(value);
}

export function get(): string | null {
    // logger.debug('get api token');

    if (!state.cookiesEnabled) {
        return state.apiToken;
    }

    const value = Cookies.get(COOKIE_NAME);

    if (!value) {
        Sentry.addBreadcrumb({
            level: 'debug',
            category: 'api-token-storage',
            message: `No API Token was retrieved from cookies (${document.cookie})`
        });

        return null;
    }

    return deserialize(value);
}

export function set(apiToken: string, sessionOnly: boolean = false) {
    logger.debug(`set api token: ${apiToken} (session only: ${sessionOnly ? 'true' : 'false'})`);

    if (!state.cookiesEnabled) {
        state.apiToken = apiToken;
        return;
    }

    const value = serialize(apiToken);
    const options: CookieOptions = {};

    if (!sessionOnly) {
        options.expires = EXPIRATION_IN_DAYS;
    }

    Sentry.addBreadcrumb({
        level: 'debug',
        category: 'api-token-storage',
        message: `API Token was set (length: ${value.length})`
    });

    Cookies.set(COOKIE_NAME, value, options);
}

export function remove() {
    logger.debug('remove api token');

    Sentry.addBreadcrumb({
        level: 'debug',
        category: 'api-token-storage',
        message: 'API Token was removed'
    });

    Cookies.remove(COOKIE_NAME);
}

export function updateExpiration() {
    logger.debug('update api token expiration');

    const apiToken = get();
    if (!apiToken) {
        return;
    }

    const value = serialize(apiToken);

    const options = {
        expires: EXPIRATION_IN_DAYS
    };

    Cookies.set(COOKIE_NAME, value, options);
}
