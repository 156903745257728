import { IntlShape } from 'react-intl';

export type SimpleDurationUnit = 'years' | 'days';

const UNIT_MESSAGE_IDS: Record<SimpleDurationUnit, string> = {
    years: 'CONSTANT.SIMPLE_DURATION.years',
    days: 'CONSTANT.SIMPLE_DURATION.days'
};

export function formatSimpleDuration(intl: IntlShape, duration: number, unit: SimpleDurationUnit): string {
    const unitMessageId = UNIT_MESSAGE_IDS[unit];

    return intl.formatMessage({ id: unitMessageId }, { duration });
}
