import { toArray } from './array';

export function count(obj: Object) {
    return Object.keys(obj).length;
}

/**
 * Returns a new object with omitted properties.
 * @method omit
 * @param  {Object} obj   The source object
 * @param  {(*|*[])}    props An array of properties which should be omitted
 * @return {Object}       The new object with omitted properties
 */
export function omit<Input extends object, Key extends keyof Input>(
    input: Input,
    key: Key | Key[]
): Pick<Input, Exclude<keyof Input, Key>> {
    const keysToExclude = toArray(key);

    const keys = Object.keys(input) as Key[];

    const keysToKeep = keys.filter((key) => !keysToExclude.includes(key));

    const output: any = {};

    for (let i = 0; i < keysToKeep.length; i++) {
        const keyToKeep = keysToKeep[i];
        output[keyToKeep] = input[keyToKeep];
    }

    return output;
}

export function omitNullValues(obj: Object) {
    return Object.keys(obj)
        .filter((k) => obj[k] !== null)
        .reduce((p, k) => ({ ...p, [k]: obj[k] }), {});
}

export function pick(obj: Object, props: string | string[]) {
    if (!(props instanceof Array)) {
        props = [props];
    }

    return Object.keys(obj)
        .filter((propName) => {
            return !!~props.indexOf(propName);
        })
        .reduce((newObj, propName) => {
            newObj[propName] = obj[propName];

            return newObj;
        }, {});
}

export function clone<Type extends { [key: string]: any }>(object: Type): Type;
export function clone<Type extends { [key: string]: any }>(deep: boolean, object: Type): Type;
export function clone(arg1, arg2?) {
    let input = arg1;
    let deep = false;

    if (typeof arg1 === 'boolean' && typeof arg2 === 'object') {
        input = arg2;
        deep = true;
    }

    if (deep) {
        return JSON.parse(JSON.stringify(input));
    }

    return { ...input };
}

type Entry = {
    key: string;
    value: any;
};

export function toEntries(obj: Object): Entry[] {
    return Object.keys(obj).map((key) => ({
        key,
        value: obj[key]
    }));
}

export function fromEntries(entries: Entry[]): Object {
    return entries.reduce(
        (obj, entry) => ({
            ...obj,
            [entry.key]: entry.value
        }),

        {}
    );
}

export function getPropertyOrFallback(target: any, fallback: any) {
    return typeof target === 'undefined' ? fallback : target;
}
