import { all, spawn, put, race, take, takeEvery, delay } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { ADD_ACTION_NOTIFICATION, REMOVE_ACTION_NOTIFICATION, removeActionNotification } from './actions';
import { ACTION_NOTIFICATION_TIMEOUT_IN_MILLISECONDS } from './constants';

import { Action } from '../types';

export function createActionTypeRemoveActionNotificationAndIdMatcher(id: string): (action: Action) => boolean {
    return (action: Action): boolean => action.type === REMOVE_ACTION_NOTIFICATION && action.payload.id === id;
}

export function matchesActionTypeRemoveActionNotificationeAndId(id: string) {
    if (!matchesActionTypeRemoveActionNotificationeAndId._matchers[id]) {
        matchesActionTypeRemoveActionNotificationeAndId._matchers[
            id
        ] = createActionTypeRemoveActionNotificationAndIdMatcher(id);
    }

    return matchesActionTypeRemoveActionNotificationeAndId._matchers[id];
}

matchesActionTypeRemoveActionNotificationeAndId._matchers = {};

export function* startNotificationTimeout(action: Action): SagaIterator {
    const { id } = action.payload;

    const { timeout } = yield race({
        remove: take(matchesActionTypeRemoveActionNotificationeAndId(id)),
        timeout: delay(ACTION_NOTIFICATION_TIMEOUT_IN_MILLISECONDS)
    });

    if (!!timeout) {
        yield put(removeActionNotification(id));
    }
}

export function* watchAddActionNotification(): SagaIterator {
    yield takeEvery(ADD_ACTION_NOTIFICATION, startNotificationTimeout);
}

export default function* root(): SagaIterator {
    yield all([spawn(watchAddActionNotification)]);
}
