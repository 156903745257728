import { createSelector } from 'reselect';
import { ApplicationState } from '../../store/reducer';
import { VERSION_MOUNT, VersionState } from './reducer';

function getVersionState(state: ApplicationState): VersionState {
    return state[VERSION_MOUNT];
}

export const getLatestVersion = createSelector(getVersionState, (version) => {
    return version.latestVersion;
});
