import * as React from 'react';
import classNames from 'classnames';
import Avatar from '../../Avatar';

import './style.scss';

export type MobileAppChatMessageProps = {
    fromUserType: 'recruiter' | 'applicant';
    portraitImageSrc: string | null;
    portraitFallBack: React.ReactNode;
    messageText: React.ReactNode;
};

const MobileAppChatMessage = React.forwardRef(
    (props: MobileAppChatMessageProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
        return (
            <div
                ref={ref}
                className={classNames(
                    'MobileAppChatMessage',
                    { 'MobileAppChatMessage--from-recruiter': props.fromUserType === 'recruiter' },
                    { 'MobileAppChatMessage--from-applicant': props.fromUserType === 'applicant' }
                )}
            >
                <div className="MobileAppChatMessage__portrait-image">
                    <Avatar src={props.portraitImageSrc} fallback={<span>{props.portraitFallBack}</span>} />
                </div>
                <div className="MobileAppChatMessage__chat-bubble">
                    <div className="MobileAppChatMessage__chat-bubble__message">{props.messageText}</div>
                    <div className="MobileAppChatMessage__chat-bubble__timestamp">16:20</div>
                </div>
            </div>
        );
    }
);

export default MobileAppChatMessage;
