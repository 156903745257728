import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import './MessageFormTemplates.style.scss';

import TemplatesDropdown from '../../../components/TemplatesDropdown';
import Button from '../../../components/Button';

import { Template } from '../../../modules/templates/types';

type TemplateId = Template['id'];

type Props = {
    templates: Template[];
    selectedTemplateId: TemplateId | null;
    onSelectTemplate: (arg1: TemplateId) => void;
    insertTemplate: (templateId: TemplateId | null) => void;
};

const MessageFormTemplates = ({ templates, selectedTemplateId, onSelectTemplate, insertTemplate }: Props) => {
    return (
        <div className="MessageFormTemplates">
            <span className="MessageFormTemplates__label">
                <FormattedMessage id="MESSAGE_FORM.TEMPLATES.LABEL" />
            </span>
            <div className="MessageFormTemplates__dropdown">
                <TemplatesDropdown
                    templates={templates}
                    direction="up"
                    selectedTemplateId={selectedTemplateId}
                    onSelect={onSelectTemplate}
                />
            </div>
            <Button
                className="MessageFormTemplates__insert-button"
                type="button"
                typeStyle="flat"
                variationStyle="brand"
                onClick={() => {
                    insertTemplate(selectedTemplateId);
                }}
            >
                <FormattedMessage id="MESSAGE_FORM.TEMPLATES.PREVIEW_BUTTON.LABEL" />
            </Button>
        </div>
    );
};

export default MessageFormTemplates;
