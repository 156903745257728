import React from 'react';
import { FormattedMessage } from 'react-intl';

import { companyContact } from '../../../constants';
import { RequestTokenRequestPageLayout } from '../RequestTokenRequestPageLayout';

export type RequestTokenRequestFailurePageProps = {};

export function RequestTokenRequestFailurePage({}: RequestTokenRequestFailurePageProps) {
    return (
        <RequestTokenRequestPageLayout
            title={<FormattedMessage id="REQUEST_TOKEN_REQUEST_FAILURE_PAGE.TITLE" />}
            data-testid="request-token-request-failure-page"
        >
            <p className="text-center">
                <FormattedMessage
                    id="REQUEST_TOKEN_REQUEST_FAILURE_PAGE.TEXT"
                    values={{
                        CompanyContactEmailLink: (chunks) => <a href={`mailto:${companyContact.email}}`}>{chunks}</a>
                    }}
                />
            </p>
        </RequestTokenRequestPageLayout>
    );
}
