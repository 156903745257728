import { ParsedQs } from 'qs';
import { ParsedUrlQuery } from 'querystring';
import { createRouteString, extractParams } from './utils';

export const CHECKOUT_STEP_PRODUCT = 'product';
export const CHECKOUT_STEP_ADDRESS = 'address';
export const CHECKOUT_STEP_FINISH = 'finish';
export const CHECKOUT_STEP_SUCCESS = 'success';
export const CHECKOUT_STEPS = [
    CHECKOUT_STEP_PRODUCT,
    CHECKOUT_STEP_ADDRESS,
    CHECKOUT_STEP_FINISH,
    CHECKOUT_STEP_SUCCESS
];
export const CHECKOUT_PROCESS_STEPS = [CHECKOUT_STEP_PRODUCT, CHECKOUT_STEP_ADDRESS, CHECKOUT_STEP_FINISH];

export type CheckoutStep$Product = 'product';
export type CheckoutStep$Address = 'address';
export type CheckoutStep$Finish = 'finish';
export type CheckoutStep$Sucess = 'success';
export type CheckoutStep = CheckoutStep$Product | CheckoutStep$Address | CheckoutStep$Finish | CheckoutStep$Sucess;
export type CheckoutProcessStep = CheckoutStep$Product | CheckoutStep$Address | CheckoutStep$Finish;

export const CHECKOUT_PATH_PATTERN = '/checkout/:checkoutProcessId';
export const CHECKOUT_STEP_PATH_PATTERN = `${CHECKOUT_PATH_PATTERN}/:checkoutStep`;
export const CHECKOUT_PRODUCT_PATH_PATTERN = `${CHECKOUT_PATH_PATTERN}/${CHECKOUT_STEP_PRODUCT}`;
export const CHECKOUT_ADDRESS_PATH_PATTERN = `${CHECKOUT_PATH_PATTERN}/${CHECKOUT_STEP_ADDRESS}`;
export const CHECKOUT_FINISH_PATH_PATTERN = `${CHECKOUT_PATH_PATTERN}/${CHECKOUT_STEP_FINISH}`;
export const CHECKOUT_SUCCESS_PATH_PATTERN = `${CHECKOUT_PATH_PATTERN}/${CHECKOUT_STEP_SUCCESS}`;

export type CheckoutRouteParams = {
    checkoutProcessId: string;
};

export function getCheckoutRoute(checkoutProcessId: CheckoutRouteParams['checkoutProcessId']): string {
    return createRouteString(CHECKOUT_PATH_PATTERN, { checkoutProcessId });
}

export type ParseCheckoutRouteParamsOptions = {
    exact?: boolean;
};

export function parseCheckoutRouteParams(
    input: string | ParsedUrlQuery | ParsedQs,
    { exact = true }: ParseCheckoutRouteParamsOptions = {}
): CheckoutRouteParams {
    const rawParams = typeof input === 'string' ? extractParams(CHECKOUT_PATH_PATTERN, input, { exact }) : input;

    const params: Partial<CheckoutRouteParams> = {};
    if (typeof rawParams.checkoutProcessId === 'string') {
        params.checkoutProcessId = rawParams.checkoutProcessId;

        if (!params.checkoutProcessId.length) {
            throw new Error(`Invalid value for property 'checkoutProcessId' for route '${CHECKOUT_PATH_PATTERN}'`);
        }
    }

    return params as CheckoutRouteParams;
}

export type CheckoutStepRouteParams = {
    checkoutProcessId: string;
    checkoutStep: CheckoutStep;
};

export function getCheckoutStepRoute(
    checkoutProcessId: CheckoutRouteParams['checkoutProcessId'],
    checkoutStep: CheckoutStepRouteParams['checkoutStep']
): string {
    return createRouteString(CHECKOUT_STEP_PATH_PATTERN, { checkoutProcessId, checkoutStep });
}

export function parseCheckoutStepRouteParams(input: string | ParsedUrlQuery | ParsedQs): CheckoutStepRouteParams {
    const rawParams = typeof input === 'string' ? extractParams(CHECKOUT_STEP_PATH_PATTERN, input) : input;

    const params: Partial<CheckoutStepRouteParams> = {};
    if (typeof rawParams.checkoutProcessId === 'string' && !!rawParams.checkoutProcessId.length) {
        params.checkoutProcessId = rawParams.checkoutProcessId;
    } else {
        throw new Error(`Invalid value for property 'checkoutProcessId' for route '${CHECKOUT_STEP_PATH_PATTERN}'`);
    }

    if (typeof rawParams.checkoutStep === 'string' && CHECKOUT_STEPS.includes(rawParams.checkoutStep)) {
        params.checkoutStep = rawParams.checkoutStep as CheckoutStep;
    } else {
        throw new Error(
            `Invalid value '${rawParams.checkoutStep}' for property 'checkoutStep' for route '${CHECKOUT_STEP_PATH_PATTERN}'`
        );
    }

    return params as CheckoutStepRouteParams;
}

export type CheckoutProductRouteParams = CheckoutRouteParams;

export function getCheckoutProductRoute(checkoutProcessId: CheckoutRouteParams['checkoutProcessId']): string {
    return createRouteString(CHECKOUT_PRODUCT_PATH_PATTERN, { checkoutProcessId });
}

export function parseCheckoutProductRouteParams(input: string | ParsedUrlQuery | ParsedQs): CheckoutProductRouteParams {
    return parseCheckoutRouteParams(input);
}

export type CheckoutAddressRouteParams = CheckoutRouteParams;

export function getCheckoutAddressRoute(checkoutProcessId: CheckoutRouteParams['checkoutProcessId']): string {
    return createRouteString(CHECKOUT_ADDRESS_PATH_PATTERN, { checkoutProcessId });
}

export function parseCheckoutAddressRouteParams(input: string | ParsedUrlQuery | ParsedQs): CheckoutAddressRouteParams {
    return parseCheckoutRouteParams(input);
}

export type CheckoutFinishRouteParams = CheckoutRouteParams;

export function getCheckoutFinishRoute(checkoutProcessId: CheckoutRouteParams['checkoutProcessId']): string {
    return createRouteString(CHECKOUT_FINISH_PATH_PATTERN, { checkoutProcessId });
}

export function parseCheckoutFinishRouteParams(input: string | ParsedUrlQuery | ParsedQs): CheckoutFinishRouteParams {
    return parseCheckoutRouteParams(input);
}

export type CheckoutSuccessRouteParams = CheckoutRouteParams;

export function getCheckoutSuccessRoute(checkoutProcessId: CheckoutRouteParams['checkoutProcessId']): string {
    return createRouteString(CHECKOUT_SUCCESS_PATH_PATTERN, { checkoutProcessId });
}

export function parseCheckoutSuccessRouteParams(input: string | ParsedUrlQuery | ParsedQs): CheckoutSuccessRouteParams {
    return parseCheckoutRouteParams(input);
}
