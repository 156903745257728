import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FlipMove from 'react-flip-move';

import MobileApp from '../';
import MobileAppStatusBar from '../MobileAppStatusBar';
import MobileAppNavBar from '../MobileAppNavBar';
import MobileAppNavBarIcon from '../MobileAppNavBar/MobileAppNavBarIcon';
import MobileAppNavBarTitle from '../MobileAppNavBar/MobileAppNavBarTitle';
import MobileAppTabBar from '../MobileAppTabBar';

import Avatar from '../../Avatar';

import { getFullNameOrEmail as getRecruiterFullNameOrEmail, getInitials } from '../../../modules/recruiters/utils';

import './style.scss';

import { Job } from '../../../modules/jobs/types';
import { Recruiter } from '../../../modules/recruiters/types';

type Props = {
    scale: number;
    job: Job | null;
    recruiter: Recruiter | null;
    children?: React.ReactNode;
};

const MobileAppMessenger = (props: Props) => {
    const { scale, job, recruiter, children } = props;

    if (!job || !recruiter) {
        return null;
    }

    let appPreviewSubtitle;
    if (!!recruiter.first_name && !!recruiter.last_name) {
        appPreviewSubtitle = getRecruiterFullNameOrEmail(recruiter);

        if (!!recruiter.company) {
            appPreviewSubtitle = `${getRecruiterFullNameOrEmail(recruiter)}, ${recruiter.company}`;
        }
    } else if (!!recruiter.company) {
        appPreviewSubtitle = recruiter.company;
    } else {
        appPreviewSubtitle = recruiter.email;
    }

    const recruiterPortraitSrc: string | null = !!recruiter.portrait_file ? recruiter.portrait_file.small : null;

    return (
        <MobileApp scale={scale}>
            <MobileAppStatusBar />
            <MobileAppNavBar
                leftElement={<MobileAppNavBarIcon type="back" />}
                centerElement={<MobileAppNavBarTitle title={job.title} subtitle={appPreviewSubtitle} greenTitle />}
                rightElement={
                    <div className="MobileAppMessenger__avatar-wrapper">
                        <Avatar src={recruiterPortraitSrc} fallback={getInitials(recruiter)} />
                    </div>
                }
            />

            <MobileAppTabBar
                leftTab={<FormattedMessage id="MOBILE_APP.TAB.MESSAGES" />}
                rightTab={<FormattedMessage id="MOBILE_APP.TAB.INFOS" />}
                activeTab="left"
            />

            <FlipMove duration={300} appearAnimation="fade" enterAnimation="elevator" leaveAnimation="elevator">
                {children}
            </FlipMove>
        </MobileApp>
    );
};

export default MobileAppMessenger;
