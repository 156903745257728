export type Deferred<Type = void, Error = any> = {
    promise: Promise<Type>;
    resolve: (data: Type | PromiseLike<Type>) => void;
    reject: (error?: Error) => void;
};

export function createDeferred<Type = void, Error = any>() {
    const deferred: Partial<Deferred<Type, Error>> = {};

    const promise = new Promise<Type>((resolve, reject) => {
        deferred.resolve = resolve;
        deferred.reject = reject;
    });

    deferred.promise = promise;

    return deferred as Deferred<Type, Error>;
}

export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
}

export type PollOptions = {
    pollPeriodInMs?: number;
    timeoutInMs?: number;
};

/**
 * Tries to poll the value as long as the callbacks throws an error and resolves with the returned value.
 */
export function poll<ReturnType = void>(
    callback: () => ReturnType,
    { pollPeriodInMs = 10, timeoutInMs = 500 }: PollOptions = {}
): Promise<ReturnType> {
    return new Promise((resolve, reject) => {
        let complete = false;
        let interval;
        let timeout;
        let lastError: any = null;

        interval = setInterval(() => {
            if (complete) {
                clearInterval(interval);
                return;
            }

            let value;

            lastError = null;
            try {
                value = callback();
            } catch (error) {
                lastError = error;
            }

            if (!lastError) {
                complete = true;
                clearInterval(interval);
                clearTimeout(timeout);

                resolve(value);
            }
        }, pollPeriodInMs);

        timeout = setTimeout(() => {
            if (complete) {
                clearTimeout(timeout);
                return;
            }

            complete = true;
            clearInterval(interval);

            reject(new Error(`Polling timed out`));
        }, timeoutInMs);
    });
}
