import { combineReducers } from 'redux';

import resetOnLogout from '../../authentication/reducer/resetOnLogout';

import { INIT_CHECKOUT_PROCESS_STATES, SAVE_CHECKOUT_PROCESS_STATE } from '../../checkout/actions';

import { EntityTypeEntitesState, EntityTypeStateEntitiesPartial } from './';
import { Action } from '../../types';
import { CheckoutProcessState } from '../../checkout/types';

export type CheckoutProcessStateEntityTypeState = EntityTypeStateEntitiesPartial<CheckoutProcessState>;

export function entitiesReducer(state: EntityTypeEntitesState<CheckoutProcessState> = {}, action: Action) {
    switch (action.type) {
        case INIT_CHECKOUT_PROCESS_STATES:
            return action.payload.checkoutProcessStates;

        case SAVE_CHECKOUT_PROCESS_STATE:
            const checkoutProcessState = action.payload.checkoutProcessState;

            return {
                ...state,
                [checkoutProcessState.id]: checkoutProcessState
            };

        default:
            return state;
    }
}

const checkoutProcessStatesReducer = combineReducers<CheckoutProcessStateEntityTypeState>({
    entities: entitiesReducer
});

export default resetOnLogout(checkoutProcessStatesReducer);
