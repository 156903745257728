import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Recruiter } from '../../../modules/recruiters/types';
import { getFullNameOrEmail as getRecruiterFullNameOrEmail, getInitials } from '../../../modules/recruiters/utils';
import MobileAppChatMessage from '../../MobileApp/MobileAppChatMessage';
import MobileAppInfoMessage from '../../MobileApp/MobileAppInfoMessage';

export type DeleteCandidateViewMobileAppMessagesProps = {
    recruiter: Recruiter;
    message: string;
};

export default function DeleteCandidateViewMobileAppMessages({
    recruiter,
    message
}: DeleteCandidateViewMobileAppMessagesProps) {
    const recruiterPortraitSrc = !!recruiter.portrait_file ? recruiter.portrait_file.small : null;
    let nameInInfoMessage;

    if (!!recruiter.first_name && !!recruiter.last_name) {
        nameInInfoMessage = getRecruiterFullNameOrEmail(recruiter);
    } else if (!!recruiter.company) {
        nameInInfoMessage = recruiter.company;
    } else {
        nameInInfoMessage = <FormattedMessage id="DELETE_CANDIDATE.APP_PREVIEW.INFO_MESSAGE_NAME_FALLBACK" />;
    }

    let optionalChatMessage;
    if (!!message) {
        optionalChatMessage = (
            <div key="delete-candidate-view-optional-chat-message">
                <MobileAppChatMessage
                    fromUserType="recruiter"
                    portraitImageSrc={recruiterPortraitSrc}
                    portraitFallBack={<span>{getInitials(recruiter)}</span>}
                    messageText={message}
                />
            </div>
        );
    }

    const infoMessage = (
        <div key="delete-candidate-view-info-message">
            <MobileAppInfoMessage
                messageText={
                    <FormattedMessage
                        id="DELETE_CANDIDATE.APP_PREVIEW.INFO_MESSAGE"
                        values={{ name: nameInInfoMessage }}
                    />
                }
            />
        </div>
    );

    return (
        <React.Fragment>
            {optionalChatMessage}
            {infoMessage}
        </React.Fragment>
    );
}
