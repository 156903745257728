import { createAction } from 'redux-actions';
import { createActionTypeCreator } from '../../utils/redux';

const createActionType = createActionTypeCreator('UI');

export const SHOW_NAVIGATION: string = createActionType('SHOW_NAVIGATION');
export const HIDE_NAVIGATION: string = createActionType('HIDE_NAVIGATION');

export const showNavigation = createAction(SHOW_NAVIGATION);
export const hideNavigation = createAction(HIDE_NAVIGATION);
