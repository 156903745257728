import * as React from 'react';

import './CandidateResume.style.scss';

import ResumeAboutMe from './ResumeAboutMe';
import ResumeWorks from './ResumeWorks';
import ResumeEducations from './ResumeEducations';
import ResumeSkills from './ResumeSkills';
import ResumeLanguages from './ResumeLanguages';
import ResumeQualifications from './ResumeQualifications';
import ResumeVolunteers from './ResumeVolunteers';
import ResumeAwards from './ResumeAwards';
import ResumePublications from './ResumePublications';
import ResumeInterests from './ResumeInterests';

import { Candidate } from '../../modules/candidates/types';

type Props = {
    candidate: Candidate;
};

const CandidateResume = ({ candidate }: Props) => {
    const { applicant } = candidate;

    return (
        <div className="CandidateResume">
            <ResumeAboutMe applicant={applicant} />

            <ResumeWorks applicant={applicant} />

            <ResumeEducations applicant={applicant} />

            <ResumeSkills applicant={applicant} />

            <ResumeLanguages applicant={applicant} />

            <ResumeQualifications applicant={applicant} />

            <ResumeVolunteers applicant={applicant} />

            <ResumeAwards applicant={applicant} />

            <ResumePublications applicant={applicant} />

            <ResumeInterests applicant={applicant} />
        </div>
    );
};

export default CandidateResume;
