import React from 'react';
import { FormField, Input } from '@truffls/design-system-react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FetchStatus } from '../../../modules/types';
import { JobRegion } from '../../../modules/jobRegions/types';

export type JobFormLocationRegionDisplayFieldProps = {
    jobRegionsFetchStatus: FetchStatus;
    jobRegions: JobRegion[];
};

function JobFormLocationRegionDisplayField({
    jobRegionsFetchStatus,
    jobRegions
}: JobFormLocationRegionDisplayFieldProps) {
    const intl = useIntl();

    let text = jobRegions.map((entry) => entry.name).join(', ');

    if (jobRegionsFetchStatus === 'loaded' && jobRegions.length === 0) {
        text = intl.formatMessage({
            id: 'JOB_EDIT.LOCATION_REGION_DISPLAY_FIELD.NO_VALUE_TEXT'
        });
    }

    return (
        <FormField
            id="job-form-field-location-region-display"
            labelText={<FormattedMessage id="JOB_EDIT.LOCATION_REGION_DISPLAY_FIELD.LABEL_TEXT" />}
        >
            <Input
                value={text}
                readOnly
                disabled
                title={intl.formatMessage({
                    id: 'JOB_EDIT.LOCATION_REGION_DISPLAY_FIELD.TOOLTIP_TEXT'
                })}
            />
        </FormField>
    );
}

export default JobFormLocationRegionDisplayField;
