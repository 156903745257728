import * as React from 'react';
import Dropdown from 'react-bootstrap/lib/Dropdown';

import FilterControlToggle from './FilterControlToggle';
import FilterControlMenu from './FilterControlMenu';

import { Options, Values } from './constants';

import './style.scss';

type Props = {
    children?: React.ReactNode;

    id: string;
    title: React.ReactNode;

    options: Options;
    values: Values;

    onChange: (values: Values) => void;
};

type State = {
    open: boolean;
};

class FilterControl extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            open: false
        };

        this.handleToggle = this.handleToggle.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleToggle(isOpen: boolean) {
        this.setState({
            open: isOpen
        });
    }

    handleChange(values: Values) {
        this.setState({
            open: false
        });

        this.props.onChange(values);
    }

    render() {
        const { children, id, title, options, values } = this.props;
        const { open } = this.state;

        return (
            <Dropdown
                id={id}
                className="FilterControl"
                componentClass="span"
                rootCloseEvent="click"
                open={open}
                onToggle={this.handleToggle}
            >
                <FilterControlToggle bsRole="toggle" active={!!values.length}>
                    {children}
                </FilterControlToggle>

                <FilterControlMenu
                    bsRole="menu"
                    id={id}
                    className="FilterControl__dropdown-menu"
                    title={title}
                    options={options}
                    values={values}
                    onChange={this.handleChange}
                />
            </Dropdown>
        );
    }
}

export default FilterControl;
