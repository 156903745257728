import { GENDERS } from '../../modules/genders';

import { targetCountries } from '../../constants';
import { Recruiter } from '../../modules/recruiters/types';

import { FormValues } from './constants';

const DEFAULT_GENDER = GENDERS[0];
const DEFAULT_COUNTRY = targetCountries[0];

export function convertRecruiterToFormValues(recruiter: Recruiter): FormValues {
    return {
        gender: recruiter.gender || DEFAULT_GENDER,
        first_name: recruiter.first_name || undefined,
        last_name: recruiter.last_name || undefined,
        position: recruiter.position || undefined,

        company: recruiter.company || undefined,
        phone: recruiter.phone || undefined,

        address: {
            street: recruiter.address.street || undefined,
            zip_code: recruiter.address.zip_code || undefined,
            city: recruiter.address.city || undefined,
            country: recruiter.address.country || DEFAULT_COUNTRY
        },

        invoice_email_address: recruiter.invoice_email_address || undefined,

        custom_invoice_text: undefined,

        ust_id: recruiter.ust_id || undefined,

        differing_billing_address: recruiter.differing_billing_address || false,
        billing_address: {
            first_name: recruiter.billing_address.first_name || undefined,
            last_name: recruiter.billing_address.last_name || undefined,
            company: recruiter.billing_address.company || undefined,

            street: recruiter.billing_address.street || undefined,
            zip_code: recruiter.billing_address.zip_code || undefined,
            city: recruiter.billing_address.city || undefined,
            country: recruiter.billing_address.country || DEFAULT_COUNTRY
        }
    };
}

export function showFieldsetPersonalInformation(recruiter: Recruiter): boolean {
    return !recruiter.gender || !recruiter.first_name || !recruiter.last_name || !recruiter.position;
}
