import * as React from 'react';
import classNames from 'classnames';

import './SignupFormStepHeadline.style.scss';

type Props = {
    children: React.ReactNode;
    light?: boolean;
    className?: string;
};

const SignupFormStepHeadline = (props: Props): React.ReactElement<'h1' | 'h2'> => {
    const className = classNames('SignupFormStepHeadline', props.className, {
        'SignupFormStepHeadline--light': props.light
    });

    return <h1 className={className}>{props.children}</h1>;
};

export default SignupFormStepHeadline;
