export const FORM_NAME = 'checkoutAddressForm';

export type FormValues = {
    gender: string | void;
    first_name: string | void;
    last_name: string | void;
    position: string | void;

    company: string | void;
    phone: string | void;

    address: {
        street: string | void;
        zip_code: string | void;
        city: string | void;
        country: string | void;
    };

    invoice_email_address: string | void;

    custom_invoice_text: string | void;

    ust_id: string | void;

    differing_billing_address: boolean;

    billing_address: {
        first_name: string | void;
        last_name: string | void;
        company: string | void;

        street: string | void;
        zip_code: string | void;
        city: string | void;
        country: string | void;
    };
};

export const NO_VAT_ID_NEEDED_COUNTRY = 'DE';
