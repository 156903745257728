import apiClientV3 from '../../services/api/v3';
import apiClientV4 from '../../services/api/v4';
import { toBooleanString, toCommaSeparated } from '../../services/api/utils';

import { Job } from '../jobs/types';
import { ApplicantId, Candidate } from './types';
import { ApiListResponse } from '../../services/api/types';

type JobId = Job['id'];
type CandidateId = Candidate['id'];

type QueryParams = {
    pageSize?: number;
    page?: number | 'last';
    ordering?: string | string[];
    job?: JobId;
    applicant?: ApplicantId;
    ratingByRecruiter?: string | string[];
    state?: string | string[];
    hasMessagesUnreadByRecruiter?: boolean;
    search?: string;
};

type Params = {
    page_size?: number;
    page?: number | 'last';
    ordering?: string;
    job?: number;
    applicant?: number;
    rating_by_recruiter?: string | string[];
    state?: string;
    has_messages_unread_by_recruiter?: string;
    search?: string;
};

export function fetchCandidates(queryParams: QueryParams): Promise<ApiListResponse<Candidate>> {
    const params: Params = {};

    const {
        pageSize,
        page,
        job,
        applicant,
        ordering,
        ratingByRecruiter,
        state,
        hasMessagesUnreadByRecruiter,
        search
    } = queryParams;

    if (pageSize) {
        params.page_size = pageSize;
    }
    if (page) {
        params.page = page;
    }
    if (job) {
        params.job = job;
    }
    if (applicant) {
        params.applicant = applicant;
    }
    if (state) {
        params.state = toCommaSeparated(state);
    }
    if (ratingByRecruiter) {
        params.rating_by_recruiter = toCommaSeparated(ratingByRecruiter);
    }
    if (ordering) {
        params.ordering = toCommaSeparated(ordering);
    }
    if (typeof hasMessagesUnreadByRecruiter === 'boolean') {
        params.has_messages_unread_by_recruiter = toBooleanString(hasMessagesUnreadByRecruiter);
    }
    if (search) {
        params.search = search;
    }

    return apiClientV4.get('/candidates/', { params });
}

export function fetchCandidate(candidateId: CandidateId) {
    return apiClientV4.get(`/candidates/${candidateId}/`);
}

export function updateCandidate(candidateId: CandidateId, newCandidate: Partial<Candidate>) {
    return apiClientV4.patch(`/candidates/${candidateId}/`, newCandidate);
}

export function forwardCandidate(
    candidateId: CandidateId,
    body: { to_email: string; subject: string; message: string }
) {
    return apiClientV3.post(`/candidates/${candidateId}/forwards/`, body);
}

export function deleteCandidate(candidateId: CandidateId) {
    return apiClientV4.delete(`/candidates/${candidateId}/`);
}
