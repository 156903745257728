export const ERROR_INVALID_PAGE = 'Invalid page';

export const ERROR_TEMP_RECRUITER_CAPPED = '10110';
export const ERROR_TEMP_JOB_CAPPED = '10120';

export const ERROR_INVALID_CREDENTIALS = '11120';

export const ERROR_REQUEST_TOKEN_INVALID = '11050';
export const ERROR_REQUEST_TOKEN_EXPIRED = '11070';

export const ERROR_TEMP_TOKEN_INVALID = '10020';
export const ERROR_TEMP_TOKEN_NOT_FOUND = '10030';

export const ERROR_GENERIC_NOT_FOUND = '18000';

export const ERROR_VALIDATION_ERROR = '32100';

// NEW
export const ERROR_CODE_EMAIL_NOT_CONFIRMED = '11130';
export const ERROR_CODE_INVALID_PASSWORD = '11140';

export const ERROR_JOB_PROPERTIES_NOT_EDITABLE = '10240';

export const ERROR_CODE_VALIDATION_ERROR = '32100';

export const ERROR_CODE_MIN_RESOLUTION_ERROR = '11000';
export const ERROR_CODE_MAX_RESOLUTION_ERROR = '11005';
export const ERROR_CODE_MAX_UPLOAD_SIZE_ERROR = '11010';
export const ERROR_CODE_BAD_CONTENT_TYPE_ERROR = '11020';
