import * as React from 'react';
import { useCheckoutProductPageTabContext } from './CheckoutProductPageTabContext';

import classes from './CheckoutProductPageTabs.module.scss';

export type CheckoutProductPageTabProps<Id extends string> = {
    /**
     * This property is needed by `CheckoutProductPageTabs` to identify the tab.
     */
    id: Id;
    children: React.ReactNode;
};

function CheckoutProductPageTab<Id extends string>({ children }: CheckoutProductPageTabProps<Id>) {
    const { onSelect } = useCheckoutProductPageTabContext();

    return (
        <button type="button" onClick={onSelect} className={classes.tabButton}>
            {children}
        </button>
    );
}

export default CheckoutProductPageTab;
