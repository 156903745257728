import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '../../../components/Alert';
import { getContactRoute, makeWithPrefix } from '../../../routes';

export type JobFormPageAlertsProps = {
    initialShowInformationThatJobFunctionsLengthChanged: boolean;
    initialShowInformationThatJobWasParsedBefore: boolean;
    initialShowInformationThatActiveJobIsInEditMode: boolean;
    initialShowInformationThatJobWasUpgradedBefore: boolean;

    showErrorRequired: boolean;
    onCloseErrorRequired: () => void;

    showErrorFieldsNotAllowedToBeEdited: boolean;
    onCloseErrorFieldsNotAllowedToBeEdited: () => void;
};

function JobFormPageAlerts({
    initialShowInformationThatJobFunctionsLengthChanged,
    initialShowInformationThatJobWasParsedBefore,
    initialShowInformationThatActiveJobIsInEditMode,
    initialShowInformationThatJobWasUpgradedBefore,

    showErrorRequired,
    onCloseErrorRequired,

    showErrorFieldsNotAllowedToBeEdited,
    onCloseErrorFieldsNotAllowedToBeEdited
}: JobFormPageAlertsProps) {
    const contactUrl = makeWithPrefix(getContactRoute());

    return (
        <React.Fragment>
            <Alert type="info" dismissible initialIsOpen={initialShowInformationThatJobFunctionsLengthChanged}>
                <FormattedMessage id="JOB_FORM_PAGE.ALERTS.INFORMATION_JOB_FUNCTION_MIGRATION" />
            </Alert>

            <Alert type="info" dismissible initialIsOpen={initialShowInformationThatJobWasParsedBefore}>
                <FormattedMessage id="JOB_FORM_PAGE.ALERTS.INFORMATION_PARSING" />
            </Alert>

            <Alert type="info" dismissible initialIsOpen={initialShowInformationThatActiveJobIsInEditMode}>
                <FormattedMessage id="JOB_FORM_PAGE.ALERTS.INFORMATION_UPDATING" />
            </Alert>

            <Alert type="info" dismissible initialIsOpen={initialShowInformationThatJobWasUpgradedBefore}>
                <FormattedMessage id="JOB_FORM_PAGE.ALERTS.INFORMATION_UPGRADED" />
            </Alert>

            <Alert type="danger" dismissible isOpen={showErrorRequired} onClose={onCloseErrorRequired}>
                <FormattedMessage id="JOB_FORM_PAGE.ALERTS.ERROR_REQUIRED" />
            </Alert>

            <Alert
                type="danger"
                dismissible
                isOpen={showErrorFieldsNotAllowedToBeEdited}
                onClose={onCloseErrorFieldsNotAllowedToBeEdited}
            >
                <FormattedMessage
                    id="JOB_FORM_PAGE.ALERTS.ERROR_SOME_FIELDS_NOT_ALLOWED_TO_BE_EDITED"
                    values={{
                        contactUrl
                    }}
                />
            </Alert>
        </React.Fragment>
    );
}

export default JobFormPageAlerts;
