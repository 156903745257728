import * as React from 'react';
import { getRecruiterRating } from '../../modules/candidates/utils';
import { Candidate } from '../../modules/candidates/types';

import './style.scss';

type Props = {
    candidate: Candidate;
};

const CandidateRating = (props: Props) => {
    return <div className="CandidateRating">{!!props.candidate && getRecruiterRating(props.candidate)}</div>;
};

export default CandidateRating;
