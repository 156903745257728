import { ActionNotificationsState } from '../../modules/notifications/reducer';
import { ActionNotificationWithId } from './constants';

export function compareByCreationDate(a: ActionNotificationWithId, b: ActionNotificationWithId): number {
    const difference: number = a.created - b.created;

    return difference;
}

export function getLatestActionNotificationWithId(
    actionNotifications: ActionNotificationsState
): ActionNotificationWithId {
    const notificationIds: string[] = Object.keys(actionNotifications);

    const notificationsArray: ActionNotificationWithId[] = notificationIds.map((notificationId) => {
        return {
            id: notificationId,
            ...actionNotifications[notificationId]
        };
    });

    const sortedNotificationsArray = notificationsArray.slice().sort(compareByCreationDate).reverse();
    const latestNotification = sortedNotificationsArray[0];

    return latestNotification;
}
