import * as HTTP_STATUS from 'http-status-codes';
import { Saga, SagaIterator } from 'redux-saga';
import { all, call, put, race, select, spawn, take } from 'redux-saga/effects';

import { getHistory } from '../../history';
import { getJobsRoute, getLoginRoute, getSignupRoute } from '../../routes';

import * as analyticsService from '../../services/analytics';
import * as ERROR_CODES from '../../services/api/constants';
import { ResponseErrorsCollectionError } from '../../services/api/errors';
import { ApiError } from '../../services/api/types';
import { hasErrorWithCode, hasErrorWithStatus, isApiResponseError } from '../../services/api/utils';
import * as apiTokenStorage from '../../services/apiTokenStorage';
import * as conversationsDraftService from '../../services/conversation/draft';
import * as errorHandler from '../../services/errorHandler';
import { handleError } from '../../services/errorHandler';
import { createLogger } from '../../utils/logger';
import { Deferred } from '../../utils/promise';

import { updateClientInfoSaga } from '../clientInfo/saga';
import { FLAG_HAS_SEEN_TRIAL_EXPIRED_DIALOG } from '../flags/constants';
import { fetchFlagSaga } from '../flags/saga';
import { fetchRecruiter } from '../recruiters/saga';
import { getRecruiter } from '../recruiters/selectors';
import { isSignedUp } from '../recruiters/utils';
import { fetchUser } from '../users/saga';
import { getLocale } from '../i18n/selectors';

import * as authenticationActions from './actions';
import * as authenticationApi from './api';
import * as authenticationSelectors from './selectors';
import { RequestTokenTokenType } from './type';
import { extractApiTokenFromResponse } from './utils';

const logger = createLogger('modules/authentication/saga');

export function* authenticationSaga(): SagaIterator {
    logger.debug('start authentication routine ...');

    yield call(initializeAuthenticationStateSaga);

    yield call(runAuthenticationRoutineSaga);
}

export function* initializeAuthenticationStateSaga(): SagaIterator {
    let apiToken = yield call(apiTokenStorage.get);

    if (!!apiToken) {
        try {
            yield put(authenticationActions.authentication.start());

            yield call(verifyApiTokenSaga, apiToken);

            yield call(apiTokenStorage.updateExpiration);

            yield call(postAuthenticationSaga);

            yield put(authenticationActions.authentication.success());

            logger.debug('api token verification was successful');
        } catch (error) {
            if (!isApiResponseError(error)) {
                handleError(error);
            }

            yield call(apiTokenStorage.remove);

            yield put(authenticationActions.authentication.failure());

            logger.debug('api token verification was failed');
        }
    } else {
        yield put(authenticationActions.setLastAuthenticationCheck());
    }
}

export function* runAuthenticationRoutineSaga(): SagaIterator {
    while (true) {
        const authenticated = yield select(authenticationSelectors.isAuthenticated);

        if (!authenticated) {
            yield call(watchAuthenticationSaga);
        } else {
            yield call(watchReauthenticationOrDeauthenticationSaga);
        }
    }
}

export function* watchAuthenticationSaga(): SagaIterator {
    logger.debug('wait for login');

    const result = yield race({
        loginWithEmailAndPasswordAction: take(authenticationActions.LOGIN_WITH_EMAIL_AND_PASSWORD),
        loginWithRequestTokenAction: take(authenticationActions.LOGIN_WITH_REQUEST_TOKEN),
        loginWithTempTokenAction: take(authenticationActions.LOGIN_WITH_TEMP_TOKEN)
    });

    const { loginWithEmailAndPasswordAction, loginWithRequestTokenAction, loginWithTempTokenAction } = result;

    try {
        let replaceUrl = false;
        let destinationUrl = null;

        yield put(authenticationActions.authentication.start());

        if (!!loginWithEmailAndPasswordAction) {
            logger.debug('login with email and password');

            const payload = loginWithEmailAndPasswordAction.payload;
            const { email, password } = payload;

            if (!!payload.destinationUrl) {
                destinationUrl = payload.destinationUrl;
            }

            yield call(authenticateWithEmailAndPasswordSaga, email, password);
        } else if (!!loginWithRequestTokenAction) {
            logger.debug('login with request token');

            const payload = loginWithRequestTokenAction.payload;
            const { requestToken } = payload;

            replaceUrl = true;
            if (!!payload.destinationUrl) {
                destinationUrl = payload.destinationUrl;
            }

            yield call(authenticateWithRequestTokenSaga, requestToken);
        } else if (!!loginWithTempTokenAction) {
            logger.debug('login with temp token');

            const payload = loginWithTempTokenAction.payload;
            const { tempToken } = payload;

            replaceUrl = true;
            if (!!payload.destinationUrl) {
                destinationUrl = payload.destinationUrl;
            }

            yield call(authenticateWithTempTokenSaga, tempToken);
        }

        yield call(postAuthenticationSaga);

        yield put(authenticationActions.authentication.success());

        yield call(handleSuccessfulAuthentication as Saga, replaceUrl, destinationUrl);
    } catch (error) {
        if (!isApiResponseError(error)) {
            handleError(error);
        }

        yield put(authenticationActions.authentication.failure());
    }
}

export function* watchReauthenticationOrDeauthenticationSaga(): SagaIterator {
    logger.debug('wait for login or logut');

    const {
        loginWithRequestTokenAction,
        loginWithTempTokenAction,

        logoutAction
    } = yield race({
        loginWithRequestTokenAction: take(authenticationActions.LOGIN_WITH_REQUEST_TOKEN),
        loginWithTempTokenAction: take(authenticationActions.LOGIN_WITH_TEMP_TOKEN),

        logoutAction: take(authenticationActions.LOGOUT)
    });

    if (!!logoutAction) {
        const payload = logoutAction.payload;
        const destinationUrl = payload.loginDestinationUrl;
        const preventRedirect = payload.preventRedirect;

        yield call(logoutAndRedirectSaga, destinationUrl, preventRedirect);
        return;
    }

    try {
        let replaceUrl = false;
        let destinationUrl = null;

        yield put(authenticationActions.authentication.start());

        if (!!loginWithRequestTokenAction) {
            logger.debug('login with request token');

            const payload = loginWithRequestTokenAction.payload;
            const { requestToken } = payload;

            replaceUrl = true;
            if (!!payload.destinationUrl) {
                destinationUrl = payload.destinationUrl;
            }

            yield call(authenticateWithRequestTokenSaga, requestToken);
        } else if (!!loginWithTempTokenAction) {
            logger.debug('login with temp token');

            const payload = loginWithTempTokenAction.payload;
            const { tempToken } = payload;

            replaceUrl = true;
            if (!!payload.destinationUrl) {
                destinationUrl = payload.destinationUrl;
            }

            yield call(authenticateWithTempTokenSaga, tempToken);
        }

        yield call(postAuthenticationSaga);

        yield put(authenticationActions.authentication.success());

        yield call(handleSuccessfulAuthentication as Saga, replaceUrl, destinationUrl);
    } catch (error) {
        if (!isApiResponseError(error)) {
            handleError(error);
        }

        yield put(authenticationActions.authentication.failure());
        yield call(logoutSaga);
    }
}

export function* authenticateWithEmailAndPasswordSaga(email: string, password: string): SagaIterator {
    try {
        yield put(authenticationActions.emailAndPasswordAuthentication.request());

        const response = yield call(authenticationApi.authenticateWithEmailAndPassword, email, password);
        const apiToken = extractApiTokenFromResponse(response);

        yield call(apiTokenStorage.set, apiToken);

        yield put(authenticationActions.emailAndPasswordAuthentication.success());
    } catch (error) {
        let errors: ApiError[] = [];

        if (isApiResponseError(error)) {
            const { data } = error.response;
            errors = data.errors || [];

            yield put(authenticationActions.emailAndPasswordAuthentication.failure(errors));
        } else {
            yield put(authenticationActions.emailAndPasswordAuthentication.failure(error));
        }

        if (!hasErrorWithStatus(errors, HTTP_STATUS.BAD_REQUEST)) {
            yield call(handleError, error);
        }

        throw error;
    }
}

export function* authenticateWithRequestTokenSaga(requestToken: string): SagaIterator {
    try {
        yield put(authenticationActions.requestTokenAuthentication.request());

        const response = yield call(authenticationApi.authenticateWithRequestToken, requestToken);
        const apiToken = extractApiTokenFromResponse(response);

        yield call(apiTokenStorage.set, apiToken);

        yield put(authenticationActions.requestTokenAuthentication.success());
    } catch (error) {
        let errors: ApiError[] = [];

        if (isApiResponseError(error)) {
            const { data } = error.response;
            errors = data.errors || [];

            yield put(authenticationActions.requestTokenAuthentication.failure(errors || data));
        } else {
            yield put(authenticationActions.requestTokenAuthentication.failure(error));
        }

        if (
            !hasErrorWithStatus(errors, HTTP_STATUS.BAD_REQUEST) &&
            !hasErrorWithCode(errors, ERROR_CODES.ERROR_REQUEST_TOKEN_INVALID) &&
            !hasErrorWithCode(errors, ERROR_CODES.ERROR_REQUEST_TOKEN_EXPIRED)
        ) {
            yield call(handleError, error);
        }

        throw error;
    }
}

export function* authenticateWithTempTokenSaga(tempToken: string): SagaIterator {
    try {
        yield put(authenticationActions.tempTokenAuthentication.request());

        const response = yield call(authenticationApi.authenticateWithTempToken, tempToken);
        const apiToken = extractApiTokenFromResponse(response);

        yield call(apiTokenStorage.set, apiToken);

        yield put(authenticationActions.tempTokenAuthentication.success());
    } catch (error) {
        let errors: ApiError[] = [];

        if (isApiResponseError(error)) {
            const { data } = error.response;
            errors = data.errors || [];

            yield put(authenticationActions.tempTokenAuthentication.failure(errors || data));
        } else {
            yield put(authenticationActions.tempTokenAuthentication.failure(error));
        }

        if (
            !hasErrorWithStatus(errors, HTTP_STATUS.FORBIDDEN) &&
            !hasErrorWithCode(errors, ERROR_CODES.ERROR_TEMP_TOKEN_INVALID) &&
            !hasErrorWithCode(errors, ERROR_CODES.ERROR_TEMP_TOKEN_NOT_FOUND)
        ) {
            yield call(handleError, error);
        }

        throw error;
    }
}

export function* logoutSaga(): SagaIterator {
    try {
        yield call(apiTokenStorage.remove);

        yield call(conversationsDraftService.clearDrafts);
        errorHandler.unsetUser();

        analyticsService.unsetUserId();

        yield put(authenticationActions.logoutSuccess());
    } catch (error) {
        handleError(error);

        throw error;
    }
}

export function* logoutAndRedirectSaga(
    destinationUrlAfterLogin: string,
    preventRedirect?: boolean | null
): SagaIterator {
    yield call(logoutSaga);

    if (!preventRedirect) {
        const toParams = getLoginRoute({
            destinationUrl: destinationUrlAfterLogin
        });

        const history = getHistory();
        yield call(history.push, toParams);
    }
}

export function* handleSuccessfulAuthentication(replaceUrl: boolean = false, to: string): SagaIterator {
    const recruiter = yield select(getRecruiter);

    to = to || getJobsRoute();

    if (!isSignedUp(recruiter)) {
        to = getSignupRoute({ destinationUrl: to });
    }

    const history = getHistory();
    if (replaceUrl) {
        yield call(history.replace, to);
    } else {
        yield call(history.push, to);
    }
}

export function* verifyApiTokenSaga(apiToken: string): SagaIterator<string> {
    try {
        yield put(authenticationActions.apiTokenVerification.start());

        yield call(authenticationApi.verifyApiToken, apiToken);

        yield put(authenticationActions.apiTokenVerification.success());

        return apiToken;
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(authenticationActions.apiTokenVerification.failure(error.response.data.errors || []));
        } else {
            yield put(authenticationActions.apiTokenVerification.failure());
        }

        throw error;
    }
}

function* signupSaga({ formData }): SagaIterator<boolean> {
    try {
        yield put(authenticationActions.signupRequest.request());

        const { email, password, firstName, lastName, company, gender, acceptLatestGTC } = formData;

        const locale = yield select(getLocale);
        const profile = {
            first_name: firstName,
            last_name: lastName,
            company: company,
            gender: gender
        };

        const response = yield call(authenticationApi.signup, email, password, profile, locale, acceptLatestGTC);

        yield put(authenticationActions.signupRequest.success(response.data));

        return true;
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(authenticationActions.signupRequest.failure(error.response.data.errors || []));

            return false;
        }

        handleError(error);

        yield put(authenticationActions.signupRequest.failure());

        return false;
    }
}

export function* deleteAccountSaga({ promise }: { promise?: Deferred<void> } = {}): SagaIterator {
    try {
        yield put(authenticationActions.accountDelete.request());

        yield call(authenticationApi.deleteAccount);

        yield put(authenticationActions.accountDelete.success());

        if (promise) {
            yield call(promise.resolve);
        }

        yield put(authenticationActions.logout());
    } catch (error) {
        yield put(authenticationActions.accountDelete.failure());

        if (promise) {
            yield call(promise.reject);
        }

        handleError(error);
    }
}

function* changePasswordSaga(
    oldPassword: string,
    newPassword: string,
    { promise }: { promise?: Deferred<void, ResponseErrorsCollectionError> } = {}
): SagaIterator {
    try {
        yield put(authenticationActions.changePasswordRequest.request());

        yield call(authenticationApi.changePassword, oldPassword, newPassword);

        yield put(authenticationActions.changePasswordRequest.success());

        if (promise) {
            yield call(promise.resolve);
        }
    } catch (error) {
        if (isApiResponseError(error)) {
            const errors = error.response.data.errors || [];

            yield put(authenticationActions.changePasswordRequest.failure(errors));

            if (promise) {
                yield call(promise.reject, new ResponseErrorsCollectionError(errors));
            }

            const isErrorCausedByUser = hasErrorWithCode(errors, ERROR_CODES.ERROR_CODE_INVALID_PASSWORD);
            const isErrorNotCausedByUser = isErrorCausedByUser === false;

            if (isErrorNotCausedByUser) {
                handleError(error);
            }
        } else {
            yield put(authenticationActions.changePasswordRequest.failure());

            if (promise) {
                yield call(promise.reject);
            }

            handleError(error);
        }
    }
}

function* requestPasswordResetSaga(email): SagaIterator {
    try {
        yield put(authenticationActions.passwordResetRequest.request());

        yield call(authenticationApi.requestPasswordReset, email);

        yield put(authenticationActions.passwordResetRequest.success());
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(authenticationActions.passwordResetRequest.failure(error.response.data.errors || []));
        } else {
            throw error;
        }
    }
}

function* confirmPasswordResetSaga(payload): SagaIterator {
    try {
        yield put(authenticationActions.passwordResetConfirm.request());

        yield call(authenticationApi.confirmPasswordReset, payload);

        yield put(authenticationActions.passwordResetConfirm.success());
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(authenticationActions.passwordResetConfirm.failure(error.response.data.errors || []));
        } else {
            throw error;
        }
    }
}

export function* postAuthenticationSaga(): SagaIterator {
    const locale: ReturnType<typeof getLocale> = yield select(getLocale);

    const { user, recruiter } = yield all({
        user: call(fetchUser),
        recruiter: call(fetchRecruiter),
        clientInfo: call(updateClientInfoSaga, { language: locale }),
        hasSeen: call(fetchFlagSaga, FLAG_HAS_SEEN_TRIAL_EXPIRED_DIALOG)
    });

    errorHandler.setUser(user);

    analyticsService.setUserId(user.id);
    analyticsService.setProfileType(recruiter.signed_up ? 'premium-recruiter' : 'temporary-recruiter');
}

export function* requestRequestToken(email: string, tokenType?: RequestTokenTokenType): SagaIterator {
    try {
        yield put(authenticationActions.requestTokenRequest.request());

        yield call(authenticationApi.requestRequestToken, email, tokenType);

        yield put(authenticationActions.requestTokenRequest.success(email));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(authenticationActions.requestTokenRequest.failure(error.response.data.errors || []));
        } else {
            yield put(authenticationActions.requestTokenRequest.failure());
        }
    }
}

function* verifyEmail(verificationToken: string): SagaIterator {
    try {
        yield put(authenticationActions.emailVerification.request());

        yield call(authenticationApi.verifyEmail, verificationToken);

        yield put(authenticationActions.emailVerification.success());
    } catch (error) {
        let errors;

        if (isApiResponseError(error)) {
            errors = error.response.data.errors;
        }

        if (!errors || !hasErrorWithStatus(errors, HTTP_STATUS.NOT_FOUND)) {
            handleError(error);
        }

        yield put(authenticationActions.emailVerification.failure(errors));

        throw error;
    }
}

function* resendVerificationEmail(email: string | null): SagaIterator {
    try {
        yield put(authenticationActions.verficationEmailResend.request());

        if (typeof email !== 'string') {
            throw new TypeError('Email have to be a string');
        }

        yield call(authenticationApi.resendVerificationEmail, email);

        yield put(authenticationActions.verficationEmailResend.success());
    } catch (error) {
        yield put(authenticationActions.verficationEmailResend.failure());
    }
}

function* watchSignupSaga(): SagaIterator {
    while (true) {
        const action = yield take(authenticationActions.SIGNUP);

        yield call(signupSaga, action.payload);
    }
}

function* watchDeleteAccountSage(): SagaIterator {
    while (true) {
        yield take(authenticationActions.DELETE_ACCOUNT);

        try {
            yield call(deleteAccountSaga);
        } catch (error) {
            handleError(error);
        }
    }
}

function* watchChangePasswordSaga(): SagaIterator {
    while (true) {
        const action: authenticationActions.ChangePasswordAction = yield take(authenticationActions.CHANGE_PASSWORD);
        const { oldPassword, newPassword } = action.payload;
        const { promise } = action.meta;

        yield call(changePasswordSaga, oldPassword, newPassword, { promise });
    }
}

function* watchRequestPasswordResetSaga(): SagaIterator {
    while (true) {
        const action = yield take(authenticationActions.REQUEST_PASSWORD_RESET);

        yield call(requestPasswordResetSaga, action.payload.email);
    }
}

function* watchConfirmPasswordResetSaga(): SagaIterator {
    while (true) {
        const action = yield take(authenticationActions.CONFIRM_PASSWORD_RESET);

        yield call(confirmPasswordResetSaga, action.payload);
    }
}

function* watchRequestRequestToken(): SagaIterator {
    while (true) {
        const action: authenticationActions.RequestRequestTokenAction = yield take(
            authenticationActions.REQUEST_REQUEST_TOKEN
        );
        const { email, tokenType } = action.payload;

        yield call(requestRequestToken, email, tokenType);
    }
}

function* watchVerifyEmail(): SagaIterator {
    while (true) {
        const action = yield take(authenticationActions.VERIFY_EMAIL);
        const payload = action.payload;
        const verificationToken = payload.verificationToken;

        let successful = false;
        try {
            yield call(verifyEmail, verificationToken);

            successful = true;
        } catch (error) {
            // We don't have to handle anything
        } finally {
            const path = getLoginRoute({
                verificationSuccessful: successful
            });

            const history = getHistory();
            yield call(history.push, path);
        }
    }
}

function* watchResendVerificationEmail(): SagaIterator {
    while (true) {
        const action = yield take(authenticationActions.RESEND_VERIFICATION_EMAIL);
        const payload = action.payload;
        const email: string | null = payload.email;

        yield call(resendVerificationEmail, email);
    }
}

export default function* root(): SagaIterator {
    yield all([
        spawn(authenticationSaga),

        spawn(watchSignupSaga),
        spawn(watchDeleteAccountSage),
        spawn(watchChangePasswordSaga),
        spawn(watchRequestPasswordResetSaga),
        spawn(watchConfirmPasswordResetSaga),

        spawn(watchRequestRequestToken),
        spawn(watchVerifyEmail),
        spawn(watchResendVerificationEmail)
    ]);
}
