import { CheckoutProcessState } from '../types';
import { getAdvertisementFromCheckoutProcessState } from './helpers';

/**
 * Determines with the given checkout process state if it's allowed to select a product for checkout. It's only
 * allowed to select a product for the given job if the job advertisement will be started or restarted.
 * @param {CheckoutProcessState} checkoutProcessState the state to run the check on
 * @returns {boolean}
 */
export function canSelectProduct(checkoutProcessState: CheckoutProcessState): boolean {
    if (checkoutProcessState.method === 'bulk-up') {
        return true;
    }

    const advertisement = getAdvertisementFromCheckoutProcessState(checkoutProcessState);

    if (!!advertisement && (advertisement.action === 'start' || advertisement.action === 'restart')) {
        return true;
    }

    return false;
}

/**
 * Determines if an already booked package can be selected for this checkout.
 * @param {CheckoutProcessState} checkoutProcessState the state to run the check on
 * @returns {boolean}
 */
export function canSelectPackage(checkoutProcessState: CheckoutProcessState): boolean {
    return checkoutProcessState.method === 'advertise';
}
