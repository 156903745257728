import { createAction as createActionLegacy } from 'redux-actions';
import { createActionTypeCreator, createRequestActionTypes, createAction } from '../../utils/redux';
import { Deferred } from '../../utils/promise';
import { RequestTokenTokenType } from './type';

const createType = createActionTypeCreator('AUTHENTICATION');

export const SET_LAST_AUTHENTICATION_CHECK = createType('SET_LAST_AUTHENTICATION_CHECK');
export const setLastAuthenticationCheck = createActionLegacy(SET_LAST_AUTHENTICATION_CHECK, () => {
    return { lastCheck: Date.now() };
});

const createAuthenticationType = createActionTypeCreator(createType('AUTHENTICATION'));
export const AUTHENTICATION = {
    START: createAuthenticationType('START'),
    SUCCESS: createAuthenticationType('SUCCESS'),
    FAILURE: createAuthenticationType('FAILURE')
};
export const authentication = {
    start: createActionLegacy(AUTHENTICATION.START),
    success: createActionLegacy(AUTHENTICATION.SUCCESS, () => {
        return { lastCheck: Date.now() };
    }),
    failure: createActionLegacy(AUTHENTICATION.FAILURE, () => {
        return { lastCheck: Date.now() };
    })
};

const createApiTokenVerificationType = createActionTypeCreator(createType('API_TOKEN_VERIFICATION'));
export const API_TOKEN_VERIFICATION = {
    START: createApiTokenVerificationType('START'),
    SUCCESS: createApiTokenVerificationType('SUCCESS'),
    FAILURE: createApiTokenVerificationType('FAILURE')
};
export const apiTokenVerification = {
    start: createActionLegacy(API_TOKEN_VERIFICATION.START),
    success: createActionLegacy(API_TOKEN_VERIFICATION.SUCCESS),
    failure: createActionLegacy(API_TOKEN_VERIFICATION.FAILURE, (errors) => {
        return {
            errors
        };
    })
};

export const SIGNUP = createType('SIGNUP');
export const signup = createActionLegacy(SIGNUP, (formData) => {
    return {
        formData
    };
});

export const SIGNUP_REQUEST = createRequestActionTypes(createType('SIGNUP_REQUEST'));
export const signupRequest = {
    request: createActionLegacy(SIGNUP_REQUEST.REQUEST),
    success: createActionLegacy(SIGNUP_REQUEST.SUCCESS, (data) => {
        return { data };
    }),
    failure: createActionLegacy(SIGNUP_REQUEST.FAILURE, (errors) => {
        return { errors };
    })
};

export const RESET_SIGNUP_REQUEST = createType('RESET_SIGNUP_REQUEST');
export const resetSignupRequest = createActionLegacy(RESET_SIGNUP_REQUEST);

export const LOGIN_WITH_EMAIL_AND_PASSWORD = createType('LOGIN_WITH_EMAIL_AND_PASSWORD');
export const loginWithEmailAndPassword = createActionLegacy(
    LOGIN_WITH_EMAIL_AND_PASSWORD,
    (email: string, password: string, destinationUrl?: string) => {
        return {
            email,
            password,
            destinationUrl
        };
    }
);

export const EMAIL_AND_PASSWORD_AUTHENTICATION = createRequestActionTypes(
    createType('EMAIL_AND_PASSWORD_AUTHENTICATION')
);
export const emailAndPasswordAuthentication = {
    request: createActionLegacy(EMAIL_AND_PASSWORD_AUTHENTICATION.REQUEST),
    success: createActionLegacy(EMAIL_AND_PASSWORD_AUTHENTICATION.SUCCESS),
    failure: createActionLegacy(EMAIL_AND_PASSWORD_AUTHENTICATION.FAILURE, (errors) => {
        return { errors };
    })
};

export const LOGIN_WITH_REQUEST_TOKEN = createType('LOGIN_WITH_REQUEST_TOKEN');
export const loginWithRequestToken = createActionLegacy(
    LOGIN_WITH_REQUEST_TOKEN,
    (requestToken: string, destinationUrl?: string) => {
        return {
            requestToken,
            destinationUrl
        };
    }
);

export const REQUEST_TOKEN_AUTHENTICATION = createRequestActionTypes(createType('REQUEST_TOKEN_AUTHENTICATION'));
export const requestTokenAuthentication = {
    request: createActionLegacy(REQUEST_TOKEN_AUTHENTICATION.REQUEST),
    success: createActionLegacy(REQUEST_TOKEN_AUTHENTICATION.SUCCESS),
    failure: createActionLegacy(REQUEST_TOKEN_AUTHENTICATION.FAILURE, (errors) => {
        return { errors };
    })
};

export const LOGIN_WITH_TEMP_TOKEN = createType('LOGIN_WITH_TEMP_TOKEN');
export const loginWithTempToken = createActionLegacy(
    LOGIN_WITH_TEMP_TOKEN,
    (tempToken: string, destinationUrl?: string) => {
        return {
            tempToken,
            destinationUrl
        };
    }
);

export const TEMP_TOKEN_AUTHENTICATION = createRequestActionTypes(createType('TEMP_TOKEN_AUTHENTICATION'));
export const tempTokenAuthentication = {
    request: createActionLegacy(TEMP_TOKEN_AUTHENTICATION.REQUEST),
    success: createActionLegacy(TEMP_TOKEN_AUTHENTICATION.SUCCESS),
    failure: createActionLegacy(TEMP_TOKEN_AUTHENTICATION.FAILURE, (errors) => {
        return { errors };
    })
};

export const DELETE_ACCOUNT = createType('DELETE_ACCOUNT');
export const deleteAccount = createAction(DELETE_ACCOUNT, ({ promise }: { promise?: Deferred<void> } = {}) => {
    return {
        payload: {},
        meta: {
            promise
        }
    };
});
export type DeleteAccountAction = ReturnType<typeof deleteAccount>;

export const ACCOUNT_DELETE = createRequestActionTypes(createType('PASSWORD_RESET_REQUEST'));
export const accountDelete = {
    request: createAction(ACCOUNT_DELETE.REQUEST),
    success: createAction(ACCOUNT_DELETE.SUCCESS),
    failure: createAction(ACCOUNT_DELETE.FAILURE)
};
export type AccountDeleteRequestAction = ReturnType<typeof accountDelete['request']>;
export type AccountDeleteSuccessAction = ReturnType<typeof accountDelete['success']>;
export type AccountDeleteFailureAction = ReturnType<typeof accountDelete['failure']>;

export const LOGOUT = createType('LOGOUT');
export const logout = createActionLegacy(LOGOUT, (loginDestinationUrl, preventRedirect?: boolean | null) => ({
    loginDestinationUrl,
    preventRedirect
}));

export const LOGOUT_SUCCESS = createType('LOGOUT_SUCCESS');
export const logoutSuccess = createActionLegacy(LOGOUT_SUCCESS);

export const CHANGE_PASSWORD = createType('CHANGE_PASSWORD');
export const changePassword = createAction(
    CHANGE_PASSWORD,
    (oldPassword: string, newPassword: string, { promise }: { promise?: Deferred<void> } = {}) => {
        return {
            payload: {
                oldPassword,
                newPassword
            },
            meta: {
                promise
            }
        };
    }
);
export type ChangePasswordAction = ReturnType<typeof changePassword>;

export const CHANGE_PASSWORD_REQUEST = createRequestActionTypes(createType('CHANGE_PASSWORD_REQUEST'));
export const changePasswordRequest = {
    request: createActionLegacy(CHANGE_PASSWORD_REQUEST.REQUEST),
    success: createActionLegacy(CHANGE_PASSWORD_REQUEST.SUCCESS),
    failure: createActionLegacy(CHANGE_PASSWORD_REQUEST.FAILURE, (errors: unknown[] = []) => {
        return { errors };
    })
};

export const RESET_CHANGE_PASSWORD_REQUEST = createType('RESET_CHANGE_PASSWORD_REQUEST');
export const resetChangePasswordRequest = createActionLegacy(RESET_CHANGE_PASSWORD_REQUEST);

export const REQUEST_PASSWORD_RESET = createType('REQUEST_PASSWORD_RESET');
export const requestPasswordReset = createActionLegacy(REQUEST_PASSWORD_RESET, (email) => ({ email }));

export const PASSWORD_RESET_REQUEST = createRequestActionTypes(createType('PASSWORD_RESET_REQUEST'));
export const passwordResetRequest = {
    request: createActionLegacy(PASSWORD_RESET_REQUEST.REQUEST),
    success: createActionLegacy(PASSWORD_RESET_REQUEST.SUCCESS),
    failure: createActionLegacy(PASSWORD_RESET_REQUEST.FAILURE, (errors) => ({ errors }))
};

export const RESET_PASSWORD_RESET_REQUEST = createType('RESET_PASSWORD_RESET_REQUEST');
export const resetPasswordReset = createActionLegacy(RESET_PASSWORD_RESET_REQUEST);

export const CONFIRM_PASSWORD_RESET = createType('CONFIRM_PASSWORD_RESET');
export const confirmPasswordReset = createActionLegacy(CONFIRM_PASSWORD_RESET, (newPassword, uid, token) => ({
    newPassword,
    uid,
    token
}));

export const PASSWORD_RESET_CONFIRM = createRequestActionTypes(createType('PASSWORD_RESET_CONFIRM'));
export const passwordResetConfirm = {
    request: createActionLegacy(PASSWORD_RESET_CONFIRM.REQUEST),
    success: createActionLegacy(PASSWORD_RESET_CONFIRM.SUCCESS),
    failure: createActionLegacy(PASSWORD_RESET_CONFIRM.FAILURE, (errors) => ({ errors }))
};

export const RESET_PASSWORD_RESET_CONFIRM = createType('RESET_PASSWORD_RESET_CONFIRM');
export const resetPasswordResetConfirm = createActionLegacy(RESET_PASSWORD_RESET_CONFIRM);

export const REQUEST_REQUEST_TOKEN = createType('REQUEST_REQUEST_TOKEN');
export const requestRequestToken = createAction(
    REQUEST_REQUEST_TOKEN,
    (email: string, tokenType?: RequestTokenTokenType) => {
        return {
            payload: {
                email,
                tokenType
            }
        };
    }
);
export type RequestRequestTokenAction = ReturnType<typeof requestRequestToken>;

export const REQUEST_TOKEN_REQUEST = createRequestActionTypes(createType('REQUEST_TOKEN_REQUEST'));
export const requestTokenRequest = {
    request: createActionLegacy(REQUEST_TOKEN_REQUEST.REQUEST),
    success: createActionLegacy(REQUEST_TOKEN_REQUEST.SUCCESS, (email) => {
        return { email };
    }),
    failure: createActionLegacy(REQUEST_TOKEN_REQUEST.FAILURE, (errors = null) => {
        return { errors };
    })
};

export const RESET_REQUEST_TOKEN_REQUEST = createType('RESET_REQUEST_TOKEN_REQUEST');
export const resetRequestTokenRequest = createAction(RESET_REQUEST_TOKEN_REQUEST);
export type ResetRequestTokenRequestAction = ReturnType<typeof resetRequestTokenRequest>;

export const VERIFY_EMAIL = createType('VERIFY_EMAIL');
export const verifyEmail = createActionLegacy(VERIFY_EMAIL, (verificationToken: string) => {
    return { verificationToken };
});

export const EMAIL_VERIFICATION = createRequestActionTypes(createType('EMAIL_VERIFICATION'));
export const emailVerification = {
    request: createActionLegacy(EMAIL_VERIFICATION.REQUEST),
    success: createActionLegacy(EMAIL_VERIFICATION.SUCCESS),
    failure: createActionLegacy(EMAIL_VERIFICATION.FAILURE, (errors = null) => {
        return { errors };
    })
};

export const RESEND_VERIFICATION_EMAIL = createType('RESEND_VERIFICATION_EMAIL');
export const resendVerificationEmail = createActionLegacy(RESEND_VERIFICATION_EMAIL, (email: string) => {
    return { email };
});

export const VERIFICATION_EMAIL_RESEND = createRequestActionTypes(createType('VERIFICATION_EMAIL_RESEND'));
export const verficationEmailResend = {
    request: createActionLegacy(VERIFICATION_EMAIL_RESEND.REQUEST),
    success: createActionLegacy(VERIFICATION_EMAIL_RESEND.SUCCESS),
    failure: createActionLegacy(VERIFICATION_EMAIL_RESEND.FAILURE, (errors = null) => {
        return { errors };
    })
};
