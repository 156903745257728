import React from 'react';
import classNames from 'classnames';

import './ListItemText.scss';

export type ListItemTextProps = {
    children?: React.ReactNode;
    className?: string;
    component?: React.ElementType;

    [prop: string]: any;
};

const ListItemText = React.forwardRef<HTMLElement, ListItemTextProps>(function ListItemText(
    { className, children, component: Component = 'div', ...rest },
    ref
) {
    return (
        <Component ref={ref} className={classNames('ListItemText', className)} {...rest}>
            {children}
        </Component>
    );
});

export default ListItemText;
