import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FormField, Input } from '@truffls/design-system-react';

import { getLoginRoute, getResetPasswordRoute } from '../../../routes';

import LoaderButton from '../../../components/LoaderButtonV2';
import FormError from '../../../components/FormError';

import { arePasswordsEmpty, doPasswordsMatch, isPasswordTooShort } from './ResetPasswordConfirmPageForm.utils';
import { FormValues } from './ResetPasswordConfirmPageForm.constants';

import { ResetStatus } from '../../../modules/types';

type Props = {
    values: FormValues;
    resetStatus: ResetStatus;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};

const ErrorMessage = () => {
    const resetPasswordLink = (
        <Link to={getResetPasswordRoute()}>
            <FormattedMessage id="RESET_PASSWORD_CONFIRM.ERROR.RESET_LINK" />
        </Link>
    );

    return <FormError messageId="RESET_PASSWORD_CONFIRM.ERROR" messageValues={{ link: resetPasswordLink }} />;
};

const SuccessMessage = () => {
    const loginLink = (
        <Link to={getLoginRoute()}>
            <FormattedMessage id="RESET_PASSWORD_CONFIRM.LOGIN_LINK" />
        </Link>
    );

    return (
        <div className="ResetPasswordConfirmPage__instructions">
            <FormattedMessage
                id="RESET_PASSWORD_CONFIRM.INSTRUCTIONS.SUBMITTED"
                values={{
                    link: loginLink
                }}
            />
        </div>
    );
};

const ResetPasswordConfirmPageForm = ({ values, resetStatus, onChange, onSubmit }: Props) => {
    if (resetStatus === 'resetted') {
        return <SuccessMessage />;
    }

    const passwordsEmpty = arePasswordsEmpty(values.newPassword, values.newPasswordConfirm);
    const passwordsDoNotMatch = !doPasswordsMatch(values.newPassword, values.newPasswordConfirm);
    const newPasswordTooShort = isPasswordTooShort(values.newPassword);
    const newPasswordConfirmTooShort = isPasswordTooShort(values.newPasswordConfirm);
    const passwordsTooShort = newPasswordTooShort || newPasswordConfirmTooShort;

    const isResetting = resetStatus === 'resetting';
    const failedReset = resetStatus === 'failed';

    return (
        <form onSubmit={onSubmit}>
            {failedReset && <ErrorMessage />}

            <FormField
                id="new-password"
                labelText={<FormattedMessage id="RESET_PASSWORD_CONFIRM.INPUT.PASSWORD.LABEL" />}
                helpText={newPasswordTooShort && <FormattedMessage id="RESET_PASSWORD_CONFIRM.ERROR_MAX_LENGTH" />}
                hasError={newPasswordTooShort || (!passwordsTooShort && passwordsDoNotMatch)}
            >
                <Input
                    type="password"
                    name="newPassword"
                    autoComplete="new-password"
                    required
                    value={values.newPassword}
                    onChange={onChange}
                />
            </FormField>

            <FormField
                id="new-password-confirm"
                labelText={<FormattedMessage id="RESET_PASSWORD_CONFIRM.INPUT.PASSWORD_CONFIRM.LABEL" />}
                helpText={passwordsDoNotMatch && <FormattedMessage id="RESET_PASSWORD_CONFIRM.ERROR_NO_MATCH" />}
                hasError={newPasswordConfirmTooShort || (!passwordsTooShort && passwordsDoNotMatch)}
            >
                <Input
                    type="password"
                    name="newPasswordConfirm"
                    autoComplete="new-password"
                    required
                    value={values.newPasswordConfirm}
                    onChange={onChange}
                />
            </FormField>

            <div className="tf-form-actions text-center">
                <LoaderButton
                    type="submit"
                    disabled={passwordsDoNotMatch || passwordsTooShort || passwordsEmpty}
                    typeStyle="raised"
                    variationStyle="brand"
                    loading={isResetting}
                >
                    <FormattedMessage id="RESET_PASSWORD_CONFIRM.BUTTON.LABEL" />
                </LoaderButton>
            </div>
        </form>
    );
};

export default ResetPasswordConfirmPageForm;
