import * as React from 'react';
import classNames from 'classnames';

import useImage from '../../hooks/useImage';

import './Avatar.style.scss';
type Props = {
    src: string | null;
    fallback?: React.ReactNode;
    border?: boolean;
    highlight?: boolean;
    square?: boolean;
    className?: string;
};

function Avatar({
    src: srcToLoad,
    highlight,
    fallback,
    border,
    square,

    className
}: Props) {
    const { src, loaded, error } = useImage({
        src: srcToLoad
    });

    const shouldShowImage = loaded && !!src;
    const shouldShowFallback = error && fallback;

    const computedClassName = classNames('Avatar', className, {
        'Avatar--bordered': border,
        'Avatar--highlighted': highlight,
        'Avatar--squared': square
    });

    return (
        <div className={computedClassName}>
            {shouldShowImage && !!src && (
                <div className="Avatar__image" style={{ backgroundImage: `url(${src})` }}>
                    <img src={src} className="Avatar__image-faux" />
                </div>
            )}

            {shouldShowFallback && !!fallback && <div className="Avatar__fallback">{fallback}</div>}
        </div>
    );
}

export default Avatar;
