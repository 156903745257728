import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import DialogPageContainer from '../../components/DialogPageContainer';
import LoadingWheel from '../../components/LoadingWheel';
import RequestTokenExpiredDialog from '../../components/RequestTokenExpiredDialog';
import RequestTokenNotFoundDialog from '../../components/RequestTokenNotFoundDialog';
import RequestTokenRequestSuccessDialog from '../../components/RequestTokenRequestSuccessDialog';

import { loginWithRequestToken, requestRequestToken } from '../../modules/authentication/actions';
import {
    getRequestTokenAuthenticationErrors,
    getRequestTokenRequestEmail,
    getRequestTokenRequestStatus
} from '../../modules/authentication/selectors';
import { isRequestTokenRequestRequesting, isRequestTokenRequestSucceed } from '../../modules/authentication/utils';

import { RequestStatus } from '../../modules/types';
import { RequestTokenLoginRouteParams, parseRequestTokenLoginRouteQuery } from '../../routes/authentication';
import { ResponseError } from '../../services/api/types';
import { getErrorWithCode, hasErrorWithCode, hasErrorWithStatus } from '../../services/api/utils';
import { handleError } from '../../services/errorHandler';
import { parseSearch } from '../../utils/url';

import './style.scss';

type ConnectorProps = RouteComponentProps<RequestTokenLoginRouteParams>;

type ConnectedStateProps = {
    token: string;
    email: string;
    requestStatus: RequestStatus;
    errors: ResponseError[];
};

type ConnectedDispatchProps = {
    loginWithRequestToken: typeof loginWithRequestToken;
    requestRequestToken: typeof requestRequestToken;
};

type RequestTokenLoginPageProps = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type RequestTokenLoginPageState = {
    requested: boolean;
};

class RequestTokenLoginPage extends React.Component<RequestTokenLoginPageProps, RequestTokenLoginPageState> {
    constructor(props: RequestTokenLoginPageProps) {
        super(props);

        this.state = {
            requested: false
        };

        this.handleClickButtonResend = this.handleClickButtonResend.bind(this);
    }

    componentDidMount() {
        const { location, token, loginWithRequestToken } = this.props;

        const { destinationUrl } = parseRequestTokenLoginRouteQuery(parseSearch(location.search));

        loginWithRequestToken(token, destinationUrl);
    }

    handleClickButtonResend() {
        const { errors, requestRequestToken } = this.props;

        if (!errors.length || !hasErrorWithCode(errors, '11070')) {
            return;
        }

        const error = getErrorWithCode(errors, '11070');

        if (!error || !error.meta || !error.meta.email) {
            const exception = new Error('Unkown error on requesting request token') as Error & {
                extra: { errors: ResponseError[] };
            };

            exception.extra = {
                errors
            };

            return handleError(exception);
        }

        this.setState({ requested: true });

        if (!!error.meta && !!error.meta.email) {
            requestRequestToken(error.meta.email);
        }
    }

    render() {
        return (
            <div className="RequestTokenLoginPage">
                {renderLoadingWheel(this.props)}
                {renderErrorDialog(this.handleClickButtonResend, this.props, this.state)}
            </div>
        );
    }
}

function renderLoadingWheel({ errors }: RequestTokenLoginPageProps) {
    if (!!errors.length) {
        return null;
    }

    return (
        <div className="RequestTokenLoginPage__loading-wheel">
            <LoadingWheel />
        </div>
    );
}

function renderErrorDialog(
    handleClickButtonResend: (event: React.MouseEvent) => void,
    { email, requestStatus, errors }: RequestTokenLoginPageProps,
    { requested }: RequestTokenLoginPageState
) {
    if (!errors.length) {
        return null;
    }

    const succeed = isRequestTokenRequestSucceed(requestStatus);
    if (requested && succeed) {
        return (
            <DialogPageContainer>
                <RequestTokenRequestSuccessDialog email={email} />
            </DialogPageContainer>
        );
    }

    if (hasErrorWithStatus(errors, 404)) {
        return (
            <DialogPageContainer>
                <RequestTokenNotFoundDialog />
            </DialogPageContainer>
        );
    }

    const isRequestingToken = isRequestTokenRequestRequesting(requestStatus);
    return (
        <DialogPageContainer>
            <RequestTokenExpiredDialog isRequesting={isRequestingToken} onClickButtonResend={handleClickButtonResend} />
        </DialogPageContainer>
    );
}

const mapStateToProps = (state, props: ConnectorProps): ConnectedStateProps => {
    return {
        token: props.match.params.requestToken,
        email: getRequestTokenRequestEmail(state) as string,
        requestStatus: getRequestTokenRequestStatus(state),
        errors: getRequestTokenAuthenticationErrors(state)
    };
};

const mapDispatchToProps: ConnectedDispatchProps = {
    loginWithRequestToken,
    requestRequestToken
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestTokenLoginPage);
