import { differenceInHours, differenceInMinutes, isBefore, isEqual, parseISO } from 'date-fns';
import extend from 'extend';
import { v4 as uuidV4 } from 'uuid';

import {
    INTERNAL_MESSAGE_TYPES,
    MESSAGES_BIG_SEPARATOR_SIZE,
    MESSAGES_SMALL_SEPARATOR_SIZE,
    USER_MESSAGE_TYPES
} from './constants';

import {
    ConversationParticipant,
    EnhancedMessage,
    InternalDateMessage,
    InternalMessage,
    InternalReadStatusMessage,
    InternalSeparatorMessage,
    Message,
    PlaceholderAtachmentMessage,
    PlaceholderMessage,
    PlaceholderTextMessage,
    UserMessageTypes
} from '../../modules/conversations/types';
import { formatISO } from '../../utils/date';
import { User } from '../users/types';

function createPlaceholderMessage(
    ownerId: Message['owner'],
    messageType: UserMessageTypes,
    created: Date = new Date()
): PlaceholderMessage {
    return {
        id: uuidV4(),
        message_type: messageType,
        owner: ownerId,
        created: formatISO(created),
        sending: true
    };
}

export function createPlaceholderTextMessage(ownerId: Message['owner'], messageText: string): PlaceholderTextMessage {
    const message = createPlaceholderMessage(ownerId, USER_MESSAGE_TYPES.TEXT);

    return {
        ...message,
        text: messageText
    };
}

export function createPlaceholderAttachmentMessage(
    ownerId: Message['owner'],
    attachment: File
): PlaceholderAtachmentMessage {
    const message = createPlaceholderMessage(ownerId, USER_MESSAGE_TYPES.ATTACHMENT);

    return {
        ...message,
        extra_data: { file: attachment }
    };
}

export function createInternalMessageSeparator(size: number): InternalSeparatorMessage {
    return {
        message_type: INTERNAL_MESSAGE_TYPES.SEPARATOR,
        size
    };
}

export function createInternalMessageDate(date: string): InternalDateMessage {
    return {
        message_type: INTERNAL_MESSAGE_TYPES.DATE,
        date
    };
}

export function createInternalMessageReadStatus(
    sending: Message['sending'],
    lastReadParticipants: ConversationParticipant[],
    lastUnreadParticipants: ConversationParticipant[]
): InternalReadStatusMessage {
    return {
        message_type: INTERNAL_MESSAGE_TYPES.READ_STATUS,
        sending: !!sending ? sending : false,
        lastReadParticipants,
        lastUnreadParticipants
    };
}

export function enhanceMessages(
    messages: Message[],
    participants: ConversationParticipant[],
    currentUserId: User['id']
): Array<Message | EnhancedMessage | InternalMessage> {
    // TODO: are multiple messages and participants necessary ?

    // A list of message which are written by the logged in user
    const messagesFromMe = messages.filter((message) => {
        return isMessageSentByUser(message) && message.owner === currentUserId;
    });

    // Get a list of participants where the logged in user is not included
    const participantsWithoutMe = participants.filter((participant) => {
        return participant.user_id !== currentUserId;
    });

    // Create a map where the user ids are the keys and the participant the value
    const participantsByUserId = participantsWithoutMe.reduce((accu, participant) => {
        accu[participant.user_id] = extend(true, {}, participant);

        return accu;
    }, {});

    // Get maps which contains participant user ids and which was the last read and/or first unread messages. The
    // base data is a list of participants which doesn't contain the current logged in user.
    const {
        lastReadMessageIdsByParticipantUserIds,
        lastUnreadMessageIdsByParticipantUserIds
    } = participantsWithoutMe.reduce(
        (accu, participant) => {
            // We only pass the message from a self because we want to attach the read status only under
            // our messages.
            const { lastReadMessageId, lastUnreadMessageId } = resolveMessageIdsForLastReadAndLastUnreadOfParticipant(
                participant,
                messagesFromMe
            );

            // Put the message ids in the specific objects
            accu.lastReadMessageIdsByParticipantUserIds[participant.user_id] = lastReadMessageId;
            accu.lastUnreadMessageIdsByParticipantUserIds[participant.user_id] = lastUnreadMessageId;

            return accu;
        },
        {
            lastReadMessageIdsByParticipantUserIds: {},
            lastUnreadMessageIdsByParticipantUserIds: {}
        }
    );

    // Convert the participant user ids - message id maps to message ids - participant lists map
    const participantsByLastReadMessageIds = messageIdsByParticipantUserIdsToParticipantsByMessageId(
        lastReadMessageIdsByParticipantUserIds,
        participantsByUserId
    );

    const participantsByLastUnreadMessageIds = messageIdsByParticipantUserIdsToParticipantsByMessageId(
        lastUnreadMessageIdsByParticipantUserIds,
        participantsByUserId
    );

    function getParticipantsByLastReadMessageIds(id) {
        return participantsByLastReadMessageIds[id] || [];
    }
    function getParticipantsByLastUnreadMessageIds(id) {
        return participantsByLastUnreadMessageIds[id] || [];
    }

    // Enhance the messages and add additional internal message items
    return messages.reduce((accu, message, index, messages) => {
        const lastReadParticipants = getParticipantsByLastReadMessageIds(message.id);
        const lastUnreadParticipants = getParticipantsByLastUnreadMessageIds(message.id);

        // Create enhanced message
        const enhancedMessage = enhanceMessage(message, participants, currentUserId);

        const prevMessage = messages[index - 1] || false;
        const prevDisplayedMessage = accu[accu.length - 1] || false;

        if (!prevMessage) {
            // Create date message before first message
            accu.push(createInternalMessageDate(message.created));
        } else if (!!prevMessage && !!prevDisplayedMessage) {
            const createdDate = parseISO(message.created);
            const prevMessageCreatedDate = parseISO(prevMessage.created);

            const lastMessageWasMoreThanOneHourAgo = differenceInHours(createdDate, prevMessageCreatedDate) > 1;

            if (lastMessageWasMoreThanOneHourAgo) {
                // Add date message separator
                accu.push(createInternalMessageDate(message.created));
            } else if (
                isMessageSentByUser(prevDisplayedMessage) &&
                isMessageSentByUser(message) &&
                'owner' in prevDisplayedMessage &&
                !!prevDisplayedMessage.owner
            ) {
                if (
                    typeof prevDisplayedMessage.owner !== 'number' &&
                    prevDisplayedMessage.owner.user_id === message.owner
                ) {
                    const lastMessageWasMoreThanFiveMinutesAgo =
                        differenceInMinutes(createdDate, prevMessageCreatedDate) > 5;

                    if (lastMessageWasMoreThanFiveMinutesAgo) {
                        // Same sender but more than 5 minutes ago required extra spacing
                        accu.push(createInternalMessageSeparator(MESSAGES_SMALL_SEPARATOR_SIZE));
                    }
                } else {
                    // Different sender separation
                    accu.push(createInternalMessageSeparator(MESSAGES_BIG_SEPARATOR_SIZE));
                }
            }
        }

        // Add enhanced message to resulting array
        accu.push(enhancedMessage);

        // Add message's read status
        const isSending = isMessageSending(message);
        const isLastReadMessage = lastReadParticipants.length > 0;
        const isLastDeliveredMessage = lastUnreadParticipants.length > 0;

        if (isSending || isLastReadMessage || isLastDeliveredMessage) {
            accu.push(createInternalMessageReadStatus(isSending, lastReadParticipants, lastUnreadParticipants));
        }

        return accu;
    }, [] as Array<Message | EnhancedMessage | InternalMessage>);
}

export function enhanceMessage(
    message: Message,
    participants: ConversationParticipant[],
    currentUserId: User['id']
): Message | EnhancedMessage {
    if (!isMessageSentByUser(message)) {
        return message;
    }

    const ownerId = message.owner;

    const owner = participants.find((participant) => {
        return participant.user_id === ownerId;
    });

    const fromMe = ownerId === currentUserId;

    return extend(true, {}, message, {
        owner,
        fromMe
    });
}

export function messageIdsByParticipantUserIdsToParticipantsByMessageId(
    messageIdsByParticipantUserIds: {
        [index in ConversationParticipant['user_id']]: Message['id'];
    },

    participantsByUserId: {
        [index in User['id']]: ConversationParticipant;
    }
): {
    [index in Message['id']]: Array<ConversationParticipant>;
} {
    return Object.keys(messageIdsByParticipantUserIds).reduce((accu, participantUserId) => {
        const parsedParticipantUserId = parseInt(participantUserId, 10); // Object.keys() returns strings

        const messageId = messageIdsByParticipantUserIds[parsedParticipantUserId];

        // TODO: this looks useless as an object map cannot have different values on the same key
        if (!accu[messageId]) {
            accu[messageId] = [];
        }

        const participant = extend(true, {}, participantsByUserId[parsedParticipantUserId]);

        accu[messageId].push(participant);

        return accu;
    }, {});
}

export function isMessageReadByParticipant(message: Message, participant: ConversationParticipant): boolean {
    if (!participant.last_read) {
        return false;
    }

    const messageCreated = parseISO(message.created);
    const participantLastRead = parseISO(participant.last_read);

    return isBefore(messageCreated, participantLastRead) || isEqual(messageCreated, participantLastRead);
}

export function isMessageSentByUser(message: EnhancedMessage | Message | InternalMessage): boolean {
    const messageTypes = Object.values(USER_MESSAGE_TYPES) as Array<string>;
    return messageTypes.includes(message.message_type);
}

export function areAllMessagesNotSentByUser(messages: Message[]): boolean {
    return !messages.some((message) => isMessageSentByUser(message));
}

export function resolveMessageIdsForLastReadAndLastUnreadOfParticipant(
    participant: ConversationParticipant,
    messages: Message[]
): {
    lastReadMessageId: Message['id'];
    lastUnreadMessageId: Message['id'];
} {
    let lastReadMessageId = -1;
    let lastUnreadMessageId = -1;

    for (let i = 0, l = messages.length; i < l; i++) {
        const message = messages[i];

        if (isMessageReadByParticipant(message, participant)) {
            lastReadMessageId = message.id;
        } else if (!isMessageSending(message)) {
            lastUnreadMessageId = message.id;
        }
    }

    return {
        lastReadMessageId,
        lastUnreadMessageId
    };
}

export function isMessageSending(message: Message | PlaceholderMessage) {
    return !!message.sending; // !! is needed because .sending is optional
}
