import * as React from 'react';
import classNames from 'classnames';
// import { MenuItem } from 'react-bootstrap';
// import DropdownMenu from 'react-bootstrap/lib/DropdownMenu';

import { /*highlightPattern,*/ filterByMatcher, match } from './Autocomplete.utils';
import './AutoComplete.style.scss';
import Downshift, { PropGetters, DownshiftState, StateChangeOptions, DownshiftProps } from 'downshift';

type ChildrenRender = (arg1: {
    getInputProps: PropGetters<any>['getInputProps'];
    getLabelProps: Function;
}) => React.ReactNode;

type Props = {
    selectableItems: string[];

    onChange: (selection: string) => void;
    children: ChildrenRender;
} & Omit<DownshiftProps<string>, 'stateReducer' | 'onChange'>;

function Autocomplete({
    selectableItems,
    onChange,
    children: renderInput,

    ...props
}: Props) {
    return (
        <Downshift
            stateReducer={stateReducer}
            onChange={(selection: string | null) => {
                selection && onChange(selection);
            }}
            {...props}
        >
            {({ isOpen, inputValue, getInputProps, getLabelProps, getMenuProps, getItemProps, highlightedIndex }) => {
                const stringInputValue = inputValue || '';

                const matchingSelectableItems = filterByMatcher(match, selectableItems, stringInputValue);
                const matchingSelectableItemsContainsExactInput = matchingSelectableItems.includes(stringInputValue);

                const matchingSelectableItemsWithCustom = [
                    ...(!!stringInputValue && !matchingSelectableItemsContainsExactInput ? [stringInputValue] : []),
                    ...matchingSelectableItems
                ];

                const showResults = isOpen && matchingSelectableItemsWithCustom.length > 0;

                const computedClassName = classNames('Autocomplete', {
                    'Autocomplete--open': showResults
                });

                const menuProps = getMenuProps({
                    className: 'Autocomplete__menu dropdown-menu'
                });

                return (
                    <div className={computedClassName}>
                        {renderInput({
                            getInputProps,
                            getLabelProps
                        })}

                        <ul {...menuProps}>
                            {matchingSelectableItemsWithCustom.map((selectableItem, index) => {
                                const itemProps = getItemProps({
                                    key: index,
                                    index,
                                    item: selectableItem,
                                    className: classNames({
                                        active: highlightedIndex === index
                                    })
                                });

                                return (
                                    <li {...itemProps}>
                                        {/* Some weird way how bootstrap handles menu items in a dropdown. */}
                                        <a>{selectableItem}</a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            }}
        </Downshift>
    );
}

function stateReducer(
    state: DownshiftState<string>,
    changes: StateChangeOptions<string>
): Partial<StateChangeOptions<string>> {
    // If no item is selected on close keep the value instead of clearing it
    if (state.isOpen && !changes.isOpen && !changes.selectedItem) {
        return {
            ...changes,
            inputValue: state.inputValue
        };
    }

    switch (changes.type) {
        case Downshift.stateChangeTypes.changeInput:
            const hasInputValue = !!changes.inputValue && changes.inputValue.length > 0;

            return {
                ...changes,
                isOpen: hasInputValue ? changes.isOpen : false
            };

        default:
            return { ...changes };
    }
}

export default Autocomplete;
