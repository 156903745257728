import { Keyframes } from 'react-spring/renderprops';
import { ANIMATION_STEP_INIT, ANIMATION_STEP_OPEN, ANIMATION_STEP_NEXT_STEP } from '../../constants';

const AnimationStates = {
    [ANIMATION_STEP_INIT]: {
        opacity: 0,
        top: -30
    },
    [ANIMATION_STEP_OPEN]: {
        opacity: 1,
        top: 0,
        delay: 700
    },
    [ANIMATION_STEP_NEXT_STEP]: [
        {
            opacity: 0,
            top: 206,
            delay: 0,
            config: {
                duration: 0.01
            }
        },
        {
            opacity: 1,
            top: 176,
            delay: 300,
            config: {
                tension: 200,
                friction: 20.17
            }
        }
    ]
};

export const AnimationKeyframes = Keyframes.Spring({ // eslint-disable-line
    [ANIMATION_STEP_INIT]: AnimationStates[ANIMATION_STEP_INIT],
    [ANIMATION_STEP_OPEN]: {
        from: AnimationStates[ANIMATION_STEP_INIT],
        ...AnimationStates[ANIMATION_STEP_OPEN]
    },
    [ANIMATION_STEP_NEXT_STEP]: AnimationStates[ANIMATION_STEP_NEXT_STEP]
});

export const AnimationLeaveKeyframes = {
    [ANIMATION_STEP_INIT]: {
        from: {
            opacity: 1,
            top: AnimationStates[ANIMATION_STEP_OPEN].top
        },
        opacity: 0,
        top: AnimationStates[ANIMATION_STEP_OPEN].top + 30
    },
    [ANIMATION_STEP_NEXT_STEP]: {
        from: {
            opacity: 1,
            top: AnimationStates[ANIMATION_STEP_NEXT_STEP][1].top
        },
        opacity: 0,
        top: AnimationStates[ANIMATION_STEP_NEXT_STEP][1].top + 30
    }
};

export const emptyTransition = { visibility: 'visible' };
