import React from 'react';
import { Button } from '@truffls/design-system-react';
import { FormattedMessage } from 'react-intl';

import LoaderButtonV2 from '../../../components/LoaderButtonV2';

import DialogV2 from '../../../components/DialogV2';
import DialogTitle from '../../../components/DialogV2/DialogTitle';
import DialogContent from '../../../components/DialogV2/DialogContent';
import DialogActions from '../../../components/DialogV2/DialogActions';

export type JobFormConfirmGotoSettingsModalProps = {
    isActiveJob: boolean;
    submitting: boolean;
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
};

function JobFormConfirmGotoSettingsModal({
    isActiveJob,
    submitting,
    open,
    onConfirm,
    onCancel
}: JobFormConfirmGotoSettingsModalProps) {
    const messageIdSuffix = isActiveJob ? 'active' : 'not-active';

    const handleClose = () => {
        if (submitting) {
            return;
        }

        onCancel();
    };

    const handleClickConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (submitting) {
            return;
        }

        onConfirm();
    };

    const handleClickCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (submitting) {
            return;
        }

        onCancel();
    };

    return (
        <DialogV2 open={open} onClose={handleClose} restoreFocus={false}>
            <DialogTitle>
                <FormattedMessage id={`JOB_EDIT.CONFIRM_GOTO_SETTINGS_MODAL.TITLE.${messageIdSuffix}`} />
            </DialogTitle>

            <DialogContent>
                <FormattedMessage id={`JOB_EDIT.CONFIRM_GOTO_SETTINGS_MODAL.CONTENT_TEXT.${messageIdSuffix}`} />
            </DialogContent>

            <DialogActions>
                <Button typeStyle="flat" variationStyle="brand" type="button" onClick={handleClickCancel}>
                    <FormattedMessage id="JOB_EDIT.CONFIRM_GOTO_SETTINGS_MODAL.ACTION_CANCEL" />
                </Button>
                <LoaderButtonV2
                    typeStyle="flat"
                    variationStyle="brand"
                    type="button"
                    loading={submitting}
                    onClick={handleClickConfirm}
                >
                    <FormattedMessage id={`JOB_EDIT.CONFIRM_GOTO_SETTINGS_MODAL.ACTION_CONFIRM.${messageIdSuffix}`} />
                </LoaderButtonV2>
            </DialogActions>
        </DialogV2>
    );
}

export default JobFormConfirmGotoSettingsModal;
