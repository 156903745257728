import { createAction } from 'redux-actions';

import { createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';

const createActionType = createActionTypeCreator('PACKAGES');

export const FETCH_PACKAGES = createActionType('FETCH_PACKAGES');
export const PACKAGES_FETCH = createRequestActionTypes(createActionType('PACKAGES_FETCH'));

export const FETCH_PACKAGE = createActionType('FETCH_PACKAGE');
export const PACKAGE_FETCH = createRequestActionTypes(createActionType('PACKAGE_FETCH'));

export const CREATE_PACKAGE_ADVERTISEMENT = createActionType('CREATE_PACKAGE_ADVERTISEMENT');
export const PACKAGE_ADVERTISEMENT_CREATE = createRequestActionTypes(createActionType('PACKAGE_ADVERTISEMENT_CREATE'));

export const fetchPackages = createAction(FETCH_PACKAGES, (params) => ({ params }));
export const packagesFetch = {
    request: createAction(PACKAGES_FETCH.REQUEST),
    success: createAction(PACKAGES_FETCH.SUCCESS, (data) => ({ data })),
    failure: createAction(PACKAGES_FETCH.FAILURE, (errors = []) => ({ errors }))
};

export const fetchPackage = createAction(FETCH_PACKAGE, (packageId) => ({ packageId }));
export const packageFetch = {
    request: createAction(PACKAGE_FETCH.REQUEST, (packageId) => ({ packageId })),
    success: createAction(PACKAGE_FETCH.SUCCESS, (packageId, data) => ({ packageId, data })),
    failure: createAction(PACKAGE_FETCH.FAILURE, (packageId, errors = []) => ({ packageId, errors }))
};

export const createPackageAdvertisement = createAction(FETCH_PACKAGE, (packageId, advertisement) => ({
    packageId,
    advertisement
}));

export const packageAdvertisementCreate = {
    request: createAction(PACKAGE_ADVERTISEMENT_CREATE.REQUEST, (packageId) => ({ packageId })),
    success: createAction(PACKAGE_ADVERTISEMENT_CREATE.SUCCESS, (packageId) => ({ packageId })),
    failure: createAction(PACKAGE_ADVERTISEMENT_CREATE.FAILURE, (packageId, errors = []) => ({ packageId, errors }))
};
