import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { currency } from '../../constants';

export type FormattedCurrencyNumberProps = Omit<React.ComponentProps<typeof FormattedNumber>, 'style'>;

function FormattedCurrencyNumber(props: FormattedCurrencyNumberProps) {
    return (
        <FormattedNumber
            // eslint-disable-next-line react/style-prop-object
            style="currency"
            currency={currency}
            {...props}
        />
    );
}

export default FormattedCurrencyNumber;
