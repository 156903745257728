import * as React from 'react';
import { DownshiftState, PropGetters } from 'downshift';

import { SearchResult } from '../../../modules/search/types';

import SearchBarResultsListItem from '../SearchBarResultsListItem';

import './SearchBarResultsList.style.scss';

type Props = {
    items: SearchResult[];
    highlightedIndex: DownshiftState<any>['highlightedIndex'];
    getItemProps: PropGetters<any>['getItemProps'];
    search: string;
};

function SearchBarResultsList({ items, highlightedIndex, getItemProps, search }: Props) {
    return (
        <div className="SearchBarResultsList">
            {items.map((searchResult: SearchResult, index) => {
                const highlighted = highlightedIndex === index;

                return (
                    <div
                        {...getItemProps({
                            key: searchResult.id,
                            item: searchResult,
                            index,
                            className: 'SearchBarResultsList__item'
                        })}
                    >
                        <div className="SearchBarResultsList__item-inner">
                            <SearchBarResultsListItem item={searchResult} highlighted={highlighted} search={search} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default SearchBarResultsList;
