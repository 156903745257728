import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { registerHistoryListener } from '../../history';
import * as loadingOverlay from '../../loadingOverlay';
import ActionNotifications from '../ActionNotifications';
import LegalAgreement from '../LegalAgreement';
import Navigation from '../Navigation';
import TrialExpiredView from '../TrialExpiredView';
import TrialStatusBanner from '../TrialStatusBanner';
import Tutorial from '../Tutorial';
import UpdateAvailableNotifier from '../UpdateAvailableNotifier';
import { ApplicationRoutes } from './ApplicationRoutes';
import { ApplicationShell } from './ApplicationShell';

function Application() {
    const history = useHistory();

    React.useEffect(() => {
        // TODO: This should be moved into suspense as soon as it's supported by our application
        loadingOverlay.hide();
    }, []);

    React.useEffect(() => {
        return registerHistoryListener(history);
    }, [history]);

    return (
        <ApplicationShell navigation={Navigation}>
            <ActionNotifications />

            <UpdateAvailableNotifier />

            <LegalAgreement />

            <TrialStatusBanner />
            <TrialExpiredView />
            <Tutorial />

            <ApplicationRoutes />
        </ApplicationShell>
    );
}

export default Application;
