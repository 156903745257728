import * as React from 'react';
import { IntlProvider, IntlShape } from 'react-intl';
import IntlEnhancer from './IntlEnhancer';

export type EnhancedIntlProviderProps = React.ComponentProps<typeof IntlProvider> & {
    enhancer?: (intl: IntlShape) => IntlShape;
};

function EnhancedIntlProvider(props: EnhancedIntlProviderProps) {
    const { children, enhancer, ...intlProps } = props;

    return (
        <IntlProvider {...intlProps}>
            <IntlEnhancer enhancer={enhancer}>{React.Children.only(children)}</IntlEnhancer>
        </IntlProvider>
    );
}

export default EnhancedIntlProvider;
