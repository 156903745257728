import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    requestRequestToken as requestRequestTokenAction,
    resetRequestTokenRequest as resetRequestTokenRequestAction
} from '../../../modules/authentication/actions';
import { getRequestTokenRequestStatus } from '../../../modules/authentication/selectors';
import {
    AutomaticRequestTokenAnonymousRequestPage,
    AutomaticRequestTokenAnonymousRequestPageProps
} from './AutomaticRequestTokenAnonymousRequestPage';

export type AutomaticRequestTokenAnonymousRequestPageContainerProps = Omit<
    AutomaticRequestTokenAnonymousRequestPageProps,
    'requestTokenRequestStatus' | 'resetRequestTokenRequest' | 'requestRequestToken'
>;

export function AutomaticRequestTokenAnonymousRequestPageContainer({
    email,
    reason
}: AutomaticRequestTokenAnonymousRequestPageContainerProps) {
    const requestTokenRequestStatus = useSelector(getRequestTokenRequestStatus);
    const dispatch = useDispatch();

    const resetRequestTokenRequest: typeof resetRequestTokenRequestAction = (...args) => {
        return dispatch(resetRequestTokenRequestAction(...args));
    };

    const requestRequestToken: typeof requestRequestTokenAction = (...args) => {
        return dispatch(requestRequestTokenAction(...args));
    };

    return (
        <AutomaticRequestTokenAnonymousRequestPage
            email={email}
            reason={reason}
            requestTokenRequestStatus={requestTokenRequestStatus}
            resetRequestTokenRequest={resetRequestTokenRequest}
            requestRequestToken={requestRequestToken}
        />
    );
}
