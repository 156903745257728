import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { links } from '../../../../../constants';

import './TutorialMobileContentGtc.style.scss';

import Checkbox from '../../../../../components/Checkbox';

type Props = {
    step: number;
    acceptedConditions: boolean;
    toggleConditions: () => void;
    intl: IntlShape;
};

const TutorialMobileContentGtc = ({ step, acceptedConditions, toggleConditions, intl }: Props) => (
    <div className="TutorialMobileContentGtc">
        <p className="TutorialMobileContentGtc__paragraph">
            <FormattedMessage id={`TUTORIAL.STEP_${step}.TERMS_AND_CONDITIONS.PARAGRAPH`} />
        </p>
        <Checkbox
            required
            id="checkbox-privacy-policy"
            className="TutorialMobileContentGtc__conditions-checkbox"
            checked={acceptedConditions}
            onChange={toggleConditions}
        >
            <FormattedMessage
                id={`TUTORIAL.STEP_${step}.TERMS_AND_CONDITIONS.CHECKBOX`}
                values={{
                    link: (
                        <a href={links.gtc[intl.locale]} target="_blank" rel="noopener">
                            <FormattedMessage id={`TUTORIAL.STEP_${step}.TERMS_AND_CONDITIONS.CHECKBOX.LINK`} />
                        </a>
                    )
                }}
            />
        </Checkbox>
    </div>
);

export default injectIntl(TutorialMobileContentGtc);
