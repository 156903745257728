import { SagaIterator, Task } from 'redux-saga';
import { all, call, cancel, fork, select, spawn, take } from 'redux-saga/effects';

import { Locale } from '../../i18n';
import { createBrowserPreferredLanguageStorage } from '../../i18n/preferredLanguageStorage/browser';
import { Deferred, createDeferred } from '../../utils/promise';
import { isAuthenticated } from '../authentication/selectors';
import { updateClientInfoSaga } from '../clientInfo/saga';
import { ClientInfo } from '../clientInfo/types';
import { SET_LOCALE, SetLocaleAction } from './actions';
import { getLocale } from './selectors';

export function* refreshLocaleSaga() {
    const locale: ReturnType<typeof getLocale> = yield select(getLocale);

    const preferredLanguageStorage: ReturnType<typeof createBrowserPreferredLanguageStorage> = yield call(
        createBrowserPreferredLanguageStorage
    );
    yield call(preferredLanguageStorage.set, locale);
}

export function* setLocaleSage(
    locale: Locale,
    { deferred: targetDeferred }: { deferred?: Deferred<void> } = {}
): SagaIterator<void> {
    const preferredLanguageStorage: ReturnType<typeof createBrowserPreferredLanguageStorage> = yield call(
        createBrowserPreferredLanguageStorage
    );
    yield call(preferredLanguageStorage.set, locale);

    const authenticated: ReturnType<typeof isAuthenticated> = yield select(isAuthenticated);
    if (!authenticated) {
        return;
    }

    let deferred: Deferred<ClientInfo> | undefined = undefined;
    if (targetDeferred) {
        deferred = createDeferred<ClientInfo>();
        deferred.promise.then(() => {
            // We ensure that no value is passed to the promise
            return targetDeferred.resolve();
        }, targetDeferred.reject);
    }

    yield spawn(
        updateClientInfoSaga,
        {
            language: locale
        },
        {
            promise: deferred
        }
    );
}

export function* watchSetLocaleSaga(): SagaIterator<void> {
    let task: Task | null = null;

    while (true) {
        const action: SetLocaleAction = yield take(SET_LOCALE);

        if (task) {
            yield cancel(task);
        }

        task = yield fork(setLocaleSage, action.payload.locale, {
            deferred: action.meta.promise
        });
    }
}

export function* i18nSaga(): SagaIterator {
    yield all([spawn(watchSetLocaleSaga)]);

    yield spawn(refreshLocaleSaga);
}
