import * as React from 'react';

type Props = {
    children?: React.ReactNode;

    src: string;
};

const DialogIcon = ({ src, children }: Props) => {
    const style: React.CSSProperties = {};

    if (src && !React.Children.count(children)) {
        style.backgroundImage = `url(${src})`;
    }

    return (
        <div className="Dialog__icon" style={style}>
            {children}
        </div>
    );
};

export default DialogIcon;
