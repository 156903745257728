import * as React from 'react';
import classNames from 'classnames';

import './style.scss';

export type HeadlineProps = {
    children: React.ReactNode;
    className?: string;
    center?: boolean;
};

function Headline(props: HeadlineProps) {
    return (
        <div className={classNames('Headline', props.className, { 'Headline--center': props.center })}>
            {props.children}
        </div>
    );
}

export default Headline;
