import * as React from 'react';
import { connect } from 'react-redux';

import { getUser } from '../../modules/users/selectors';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { isAuthenticated as getIsAuthenticated } from '../../modules/authentication/selectors';
import { acceptAgreementContracts } from '../../modules/agreements/actions';
import { getLegalAgreementEnabled, isAcceptingAgreementContracts } from '../../modules/agreements/selectors';

import LegalAgreementDialog from '../../components/LegalAgreementDialog';

// Types
import { State as ApplicationState } from '../../store/reducer';
import { User } from '../../modules/users/types';
import { Recruiter } from '../../modules/recruiters/types';
import { AgreementContract } from '../../modules/agreements/types';
import { getCompleteTutorialUpdateStatus, getTutorialOpen } from '../Tutorial/redux/selectors';
import { CompleteTutorialUpdateStatus } from '../Tutorial/redux/types';

type ConnectorProps = {};
type ConnectedStateProps = {
    user: User | null;
    recruiter: Recruiter | null;
    acceptingAgreementContracts: boolean;
    isAuthenticated: boolean;
    isLegalAgreementEnabled: boolean;
    isTutorialOpen: boolean;
    completeTutorialUpdateStatus: CompleteTutorialUpdateStatus;
};

type ConnectedDispatchProps = {
    acceptAgreementContracts: typeof acceptAgreementContracts;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

class LegalAgreement extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleAcceptAgreementContracts = this.handleAcceptAgreementContracts.bind(this);
    }

    handleAcceptAgreementContracts(contracts: AgreementContract[]) {
        // TODO: We should move it into the modules because it's logic
        const initialValues = this.getInitialValues();
        const initialContracts = Object.keys(initialValues).filter((contract) => initialValues[contract]);
        const missingContracts = contracts.filter((contract) => !~initialContracts.indexOf(contract));

        this.props.acceptAgreementContracts(missingContracts);
    }

    getInitialValues() {
        const { user } = this.props;

        return {
            gtc: !!user && !!user.accepted_latest_gtc
        };
    }

    render() {
        const {
            user,
            recruiter,
            acceptingAgreementContracts,
            isTutorialOpen,
            completeTutorialUpdateStatus,
            isLegalAgreementEnabled,
            isAuthenticated
        } = this.props;

        if (
            isTutorialOpen ||
            (!isTutorialOpen && completeTutorialUpdateStatus === 'updating') ||
            (!isTutorialOpen && completeTutorialUpdateStatus === 'updated')
        ) {
            return null;
        }

        if (
            !user ||
            !recruiter ||
            user.accepted_latest_gtc ||
            !recruiter.signed_up ||
            !isLegalAgreementEnabled ||
            !isAuthenticated
        ) {
            return null;
        }

        const initialValues = this.getInitialValues();

        return (
            <LegalAgreementDialog
                open={true}
                initialValues={initialValues}
                accepting={acceptingAgreementContracts}
                onAcceptContracts={this.handleAcceptAgreementContracts}
            />
        );
    }
}

function mapStateToProps(state: ApplicationState): ConnectedStateProps {
    return {
        user: getUser(state),
        recruiter: getRecruiter(state),
        acceptingAgreementContracts: isAcceptingAgreementContracts(state),
        isAuthenticated: getIsAuthenticated(state),
        isLegalAgreementEnabled: getLegalAgreementEnabled(state),
        isTutorialOpen: getTutorialOpen(state),
        completeTutorialUpdateStatus: getCompleteTutorialUpdateStatus(state)
    };
}

const mapDispatchToProps: ConnectedDispatchProps = {
    acceptAgreementContracts
};

export default connect(mapStateToProps, mapDispatchToProps)(LegalAgreement);
