import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Headline from '../Headline';
import HeadlineTitle from '../Headline/HeadlineTitle';

export type UnknownErrorPageProps = {};

function UnknownErrorPage({}: UnknownErrorPageProps) {
    return (
        <div className="container" data-testid="unknown-error-page">
            <Headline center>
                <HeadlineTitle>
                    <FormattedMessage id="UNKNOWN_ERROR_PAGE.TITLE" />
                </HeadlineTitle>
            </Headline>
        </div>
    );
}

export default UnknownErrorPage;
