import { combineReducers } from 'redux';

import { conditionalReducer } from '../../utils/redux';
import resetOnLogout from '../authentication/reducer/resetOnLogout';

import { AGREEMENT_CONTRACT_GTC } from './constants';

import { ENABLE_LEGAL_AGREEMENTS, DISABLE_LEGAL_AGREEMENTS, AGREEMENT_CONTRACT_ACCEPTANCE } from './actions';

// Types
import { Action } from '../types';
import { AgreementContract } from './types';

// We want to mount our reducer here
export const MOUNT = 'agreements';

type AgreementContractUpdateStatusState = 'none' | 'updating' | 'updated' | 'failed';

type AgreementContractState = {
    updateStatus: AgreementContractUpdateStatusState;
};

export type AgreementsState = {
    isEnabled: boolean;
    gtc: AgreementContractState;
};

export type State = {
    agreements: AgreementsState;
};

function isEnabledReducer(state: boolean = true, action: Action): boolean {
    switch (action.type) {
        case ENABLE_LEGAL_AGREEMENTS:
            return true;

        case DISABLE_LEGAL_AGREEMENTS:
            return false;

        default:
            return state;
    }
}

function agreementUpdateStatusReducer(
    state: AgreementContractUpdateStatusState = 'none',
    action: Action
): AgreementContractUpdateStatusState {
    switch (action.type) {
        case AGREEMENT_CONTRACT_ACCEPTANCE.REQUEST:
            return 'updating';

        case AGREEMENT_CONTRACT_ACCEPTANCE.SUCCESS:
            return 'updated';

        case AGREEMENT_CONTRACT_ACCEPTANCE.FAILURE:
            return 'failed';

        default:
            return state;
    }
}

const agreementContractReducer = combineReducers({
    updateStatus: agreementUpdateStatusReducer
});

function createReducerCondition(agreementContract: AgreementContract) {
    return (state, action) => {
        return !!action.payload && action.payload.agreementContract === agreementContract;
    };
}

const agreementsReducer = combineReducers({
    isEnabled: isEnabledReducer,
    gtc: conditionalReducer(createReducerCondition(AGREEMENT_CONTRACT_GTC))(agreementContractReducer)
});

export default resetOnLogout(agreementsReducer);
