import * as React from 'react';
import LogoNav from './LogoNav';
import classes from './Navigation.module.scss';

export type NavigationShellProps = {
    showBack?: boolean;
    onClickBack?: () => void;
    children?: React.ReactNode;
    shouldLinkLogo?: boolean;
};

export function NavigationShell({ showBack, onClickBack, shouldLinkLogo, children }: NavigationShellProps) {
    return (
        <div className={classes.root}>
            <div className={classes.navigation}>
                <LogoNav showBack={showBack} onClickBack={onClickBack} shouldLinkLogo={shouldLinkLogo} />
                {children}
            </div>
        </div>
    );
}
