import React from 'react';
import { RenderableProps as BaseRenderableProps } from 'react-final-form';
import useField, { FieldRenderProps, UseFieldConfig } from './useField';

// Generic type definition
export type RenderableProps<RenderProps extends {}> = {
    component?: BaseRenderableProps<RenderProps>['component'];
    render?: BaseRenderableProps<RenderProps>['render'];
    children?: BaseRenderableProps<RenderProps>['children'];
};

export type FieldRenderableProps<FieldValue, RenderProps> = RenderableProps<
    FieldRenderProps<FieldValue> & {
        autoFocus?: boolean;
    } & RenderProps
>;

type FieldProps<
    // Comment only for formatting
    FieldValue,
    RenderProps extends { [key: string]: any },
    HTMLElementType extends HTMLElement
> = {
    // Comment only for formatting
} & UseFieldConfig<FieldValue> &
    RenderableProps<RenderProps & FieldRenderProps<FieldValue, HTMLElementType>> & {
        autoFocus?: boolean;
        name: string;
    };

function Field<
    FieldValue,
    RenderProps extends { [key: string]: any } = {},
    HTMLElementType extends HTMLElement = HTMLElement
>(
    {
        afterSubmit,
        allowNull,
        beforeSubmit,
        children,
        component,
        data,
        defaultValue,
        format,
        formatOnBlur,
        initialValue,
        isEqual,
        multiple,
        name,
        parse,
        render,
        required,
        subscription,
        type,
        validate,
        validateFields,
        value,
        ...rest
    }: FieldProps<FieldValue, RenderProps, HTMLElementType> & RenderProps,
    ref: React.Ref<HTMLElementType>
) {
    if (!name) {
        throw new Error('prop name cannot be undefined in <Field> component');
    }

    const field = useField(name, {
        afterSubmit,
        allowNull,
        beforeSubmit,
        data,
        defaultValue,
        format,
        formatOnBlur,
        initialValue,
        isEqual,
        multiple,
        parse,
        required,
        subscription,
        type,
        validate,
        validateFields,
        value
    });

    const allRenderProps: RenderProps &
        FieldRenderProps<FieldValue, HTMLElementType> & { ref: React.Ref<HTMLElementType> } = {
        ...field,
        ...rest,
        ref
    } as any;

    if (typeof children === 'function') {
        return children(allRenderProps);
    }

    if (typeof component === 'string') {
        // ignore meta, combine input with any other props
        return React.createElement(component, {
            ...field.input,
            children,
            ref,
            ...rest
        });
    } else if (component) {
        return React.createElement(component, allRenderProps);
    }

    if (render) {
        return render(children === undefined ? allRenderProps : { ...allRenderProps, children });
    }
}

const FieldWithRef = React.forwardRef<any, any>(Field) as typeof Field;

export default FieldWithRef;
