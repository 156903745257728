import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import LOGO_MOBILE from '../../../assets/images/logo-green.svg';
import ICON_ARROW_LEFT from '../../../assets/images/ic_arrow_left.svg';

import './LogoNav.style.scss';

export type LogoNavProps = {
    showBack?: boolean;
    onClickBack?: () => void;
    shouldLinkLogo?: boolean;
};

function LogoNav({ showBack, onClickBack, shouldLinkLogo = true }: LogoNavProps) {
    return (
        <div className="LogoNav">
            <div className="LogoNav__logo-container">
                {shouldLinkLogo ? (
                    <Link to="/" className="LogoNav__logo-link">
                        <img src={LOGO_MOBILE} alt="Logo" className="LogoNav__logo-image" />
                    </Link>
                ) : (
                    <span className="LogoNav__logo-link">
                        <img src={LOGO_MOBILE} alt="Logo" className="LogoNav__logo-image" />
                    </span>
                )}
            </div>

            {!!showBack && !!onClickBack && (
                <button className="LogoNav__back-button" type="button" onClick={onClickBack}>
                    <img className="LogoNav__back-button-arrow" src={ICON_ARROW_LEFT} role="presentation" />

                    <span className="LogoNav__back-button-text">
                        <FormattedMessage id="NAVIGATION.LOGONAV.BACK_BUTTON" />
                    </span>
                </button>
            )}
        </div>
    );
}

export default LogoNav;
