import { combineReducers } from 'redux';
import { resetReducer } from '../../utils/redux';

import resetOnLogout from '../authentication/reducer/resetOnLogout';

import { SET_USER, RESET_USER, USER_FETCH } from './actions';

// We want to mount our reducer here
export const MOUNT = 'user';

import { Action } from '../types';
import { User } from './types';

type FetchStatusState = 'none' | 'loading' | 'loaded' | 'failed';
type EntityState = User | null;

export type UserState = {
    fetchStatus: FetchStatusState;
    entity: EntityState;
};

export type State = {
    user: UserState;
};

export function fetchStatusReducer(state: FetchStatusState = 'none', action: Action) {
    switch (action.type) {
        case USER_FETCH.REQUEST:
            return 'loading';

        case USER_FETCH.SUCCESS:
            return 'loaded';

        case USER_FETCH.FAILURE:
            return 'failed';

        default:
            return state;
    }
}

export function entityReducer(state: EntityState = null, action: Action) {
    switch (action.type) {
        case SET_USER:
        case USER_FETCH.SUCCESS:
            return action.payload.user;

        default:
            return state;
    }
}

const resetOnUserReset = resetReducer((state, action) => action.type === RESET_USER);

const usersReducer = combineReducers({
    fetchStatus: fetchStatusReducer,
    entity: entityReducer
});

export default resetOnLogout(resetOnUserReset(usersReducer));
