import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import { getDaysLeft } from '../../../../utils/date';

import './TrialEndCountdownMenuItem.style.scss';

type Props = {
    trialEndDate: string | null;
};

function TrialEndCountdownMenuItem(props: Props) {
    if (!props.trialEndDate) {
        return null;
    }

    const daysLeft = getDaysLeft(props.trialEndDate, Date.now());

    if (daysLeft === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <li className="TrialEndCountdownMenuItem" role="presentation">
                <span className="TrialEndCountdownMenuItem__countdown">
                    <FormattedMessage
                        id="NAVIGATION_ACTIONS_TRIAL_END_COUNTDOWN_MENU_ITEM.TEXT"
                        values={{
                            days: daysLeft
                        }}
                    />
                </span>
            </li>

            <MenuItem divider />
        </React.Fragment>
    );
}

export default TrialEndCountdownMenuItem;
