import { useSelector } from 'react-redux';
import {
    getLastAuthenticationCheck,
    isAuthenticated as getIsAuthenticated
} from '../../modules/authentication/selectors';
import { ApplicationState } from '../../store/types';
import { calculateAuthenticationState, CalculateAuthenticationStateResult } from './AuthenticationState.utils';

export type UseAuthenticationStateResult = CalculateAuthenticationStateResult;

export function useAuthenticationState(): UseAuthenticationStateResult {
    const { authenticationChecked, isAuthenticated } = useSelector(selector);

    return calculateAuthenticationState({
        authenticationChecked,
        isAuthenticated
    });
}

function selector(state: ApplicationState) {
    const lastAuthenticationCheck = getLastAuthenticationCheck(state);
    const authenticationChecked = lastAuthenticationCheck !== null;

    const isAuthenticated = getIsAuthenticated(state);

    return {
        authenticationChecked,
        isAuthenticated
    };
}
