import { Placement } from '@popperjs/core';
import { FormFieldGroup, FormFieldGroupItem, Input, Select } from '@truffls/design-system-react';
import { useCombobox } from 'downshift';
import { FieldValidator } from 'final-form';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePopper } from 'react-popper';

import { targetCountries } from '../../../constants';
import { getContactRoute, makeWithPrefix } from '../../../routes';

import { GeocoderResult, resolvePlaceId } from '../../../services/maps/geocoder';

import { JobLocation } from '../../../modules/jobs/types';
import { mapGeocoderResultToJobLocation } from '../../../modules/jobs/utils';

import useField from '../../../utils/form/react-final-form/useField';
import { hasError } from '../../../utils/form/state';
import { sameWidth } from '../../../utils/popperjs/modifiers';

import List, { ListItem, ListItemText } from '../../../components/List';
import Paper from '../../../components/Paper';

import { onJobFormPageValidationLevel } from '../JobFormPage.utils';
import { usePlacesAutocompletionResults } from './JobFormLocationFormField.hooks';

import './JobFormLocationFormField.scss';

export type JobFormLocationFormFieldName = 'location';
export const JOB_EDIT_LOCATION_FORM_FIELD_NAME: JobFormLocationFormFieldName = 'location';
export type JobFormLocationFormFieldValue = JobLocation | null;

const validate = onJobFormPageValidationLevel('full', ((value: JobFormLocationFormFieldValue) => {
    if (!value) {
        return 'required';
    }

    return undefined;
}) as FieldValidator<JobFormLocationFormFieldValue>);

export type JobFormLocationFormFieldProps = {
    isEditable?: boolean;
    onChangeAddress?: (address: GeocoderResult | null) => void;
};

function JobFormLocationFormField({ isEditable = true, onChangeAddress }: JobFormLocationFormFieldProps) {
    const intl = useIntl();
    const field = useField<JobFormLocationFormFieldValue, HTMLInputElement>(JOB_EDIT_LOCATION_FORM_FIELD_NAME, {
        allowNull: true,
        validate
    });

    const initialValue = field.input.value;
    const initialQuery = initialValue?.raw_source ?? '';
    const initialCountry = initialValue?.country ?? 'de';

    const [baseIsOpen, setBaseIsOpen] = React.useState(false);

    const [searchCountry, setSearchCountry] = React.useState(initialCountry);
    const [searchQuery, setSearchQuery] = React.useState(initialQuery);

    const { results: searchQueryResults } = usePlacesAutocompletionResults(searchCountry, searchQuery, {
        isOpen: baseIsOpen
    });

    const updateByPlaceId = (placeId) => {
        resolvePlaceId(placeId).then((address: GeocoderResult | null) => {
            if (!address) {
                field.input.onChange(null);
                setSearchQuery('');

                if (onChangeAddress) {
                    onChangeAddress(null);
                }
            } else {
                const location = mapGeocoderResultToJobLocation(address);

                field.input.onChange(location);
                setSearchQuery(location.raw_source);

                if (onChangeAddress) {
                    onChangeAddress(address);
                }
            }
        });
    };

    const { isOpen, getComboboxProps, getInputProps, getMenuProps, getItemProps, highlightedIndex } = useCombobox({
        isOpen: baseIsOpen,
        onIsOpenChange: (changes) => {
            setBaseIsOpen(changes.isOpen ?? false);
        },
        inputValue: searchQuery,
        items: searchQueryResults,
        getItemId: (index) => {
            const searchQueryResult = searchQueryResults[index];

            if (!searchQueryResult) {
                return `index-${index}`;
            }

            return `id-${searchQueryResult.place_id}`;
        },
        itemToString: (searchQueryResult) => {
            return searchQueryResult ? searchQueryResult.description : '';
        },
        onSelectedItemChange: (changes) => {
            const selectedItem = changes.selectedItem;

            if (!selectedItem) {
                return;
            }

            const placeId = selectedItem.place_id;

            updateByPlaceId(placeId);
        }
    });

    const comboboxRef = React.useRef<HTMLDivElement>();

    const queryInputRef = React.useRef();
    const queryInputProps = getInputProps({
        ref: queryInputRef as any,

        'aria-labelledby': undefined,

        onFocus: field.input.onFocus,
        onBlur: field.input.onBlur,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(event.target.value);
        },

        // We have to use a different value than `off` because some browser ignore this value
        autoComplete: 'none'
    });

    const countryInputProps = {
        value: searchCountry,
        onChange: (event: React.ChangeEvent<HTMLSelectElement>) => {
            event.preventDefault();

            const { value } = event.target;
            setSearchCountry(value);

            if (searchCountry !== value) {
                setSearchQuery('');
            }
        }
    };

    const menuRef = React.useRef<HTMLDivElement>();

    const { styles: popperStyles, attributes: popperAttributes } = usePopper(queryInputRef.current, menuRef.current, {
        placement: `bottom-start` as Placement,
        modifiers: [sameWidth]
    });

    const menuProps = getMenuProps({
        ref: menuRef as any,
        style: popperStyles.popper,
        ...popperAttributes.popper
    });

    const contactUrl = makeWithPrefix(getContactRoute());

    const countryLabel = <FormattedMessage id="JOB_EDIT.LOCATION_FORM_FIELD.COUNTRY.LABEL_TEXT" />;
    const queryLabel = <FormattedMessage id="JOB_EDIT.LOCATION_FORM_FIELD.QUERY.LABEL_TEXT" />;
    const helpText = (
        <FormattedMessage
            id="JOB_EDIT.LOCATION_FORM_FIELD.HELP_TEXT"
            values={{
                contactLink: (chunks) => (
                    <a href={contactUrl} target="_blank">
                        {chunks}
                    </a>
                )
            }}
        />
    );

    return (
        <React.Fragment>
            <div {...getComboboxProps({ ref: comboboxRef as any })}>
                <FormFieldGroup
                    labelText={queryLabel}
                    helpText={helpText}
                    disabled={!isEditable}
                    hasError={hasError(field.input.value, field.meta)}
                >
                    <FormFieldGroupItem id="job-form-field-location-country" labelText={countryLabel}>
                        <Select {...countryInputProps}>
                            {targetCountries.map((targetCountry) => {
                                const label = intl.formatMessage({
                                    id: `CONSTANT.COUNTRY.${targetCountry}`
                                });

                                return (
                                    <option key={targetCountry} value={targetCountry}>
                                        {label}
                                    </option>
                                );
                            })}
                        </Select>
                    </FormFieldGroupItem>
                    <FormFieldGroupItem primary id="job-form-field-location-query" labelText={queryLabel}>
                        <Input {...queryInputProps} />
                    </FormFieldGroupItem>
                </FormFieldGroup>
            </div>
            <div {...menuProps} className="JobFormLocationFormField__menu">
                {isOpen && searchQueryResults.length > 0 && (
                    <Paper>
                        <List>
                            {searchQueryResults.map((autocompletionResult, index) => {
                                return (
                                    <ListItem
                                        key={autocompletionResult.place_id}
                                        {...getItemProps({ item: autocompletionResult, index })}
                                        highlighted={highlightedIndex === index}
                                        className="JobFormLocationFormField__menu-item"
                                    >
                                        <ListItemText>{autocompletionResult.description}</ListItemText>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Paper>
                )}
            </div>
        </React.Fragment>
    );
}

export default JobFormLocationFormField;
