import * as React from 'react';
import { injectIntl } from 'react-intl';
import { animated } from 'react-spring/renderprops';

import { Intl } from '../../../../types/intl';

import './TutorialMobileVideo.style.scss';

import welcomeImage from '../../../../assets/videos/tutorial/Dashboard_WelcomeImage.jpg';
import { getVideo } from '../../videos';

import { getPropertyOrFallback } from '../../../../utils/object';

import {
    VIDEO_AUTOPLAY_DELAY,
    VIDEO_LOOP_DELAY,
    ANIMATION_STEP_INIT,
    ANIMATION_STEP_NEXT_STEP,
    AnimationStep
} from '../../constants';

import ToggleAnimation from '../../../../components/ToggleAnimation';

import { emptyTransition, AnimationKeyframes, AnimationLeaveKeyframes } from './animations';

type Props = {
    open: boolean;
    step: number;
    animationState: AnimationStep;
    intl: Intl;
};

class TutorialMobileVideo extends React.Component<Props> {
    videoElement: HTMLVideoElement | null = null;
    autoplayTimeout: number = -1;

    constructor(props: Props) {
        super(props);

        this.videoRef = this.videoRef.bind(this);
        this.setAutoplayTimeout = this.setAutoplayTimeout.bind(this);
        this.setLoopTimeout = this.setLoopTimeout.bind(this);
        this.playVideo = this.playVideo.bind(this);
    }

    componentWillUnmount() {
        window.clearTimeout(this.autoplayTimeout);

        if (!!this.videoElement) {
            this.videoElement.removeEventListener('canplay', this.setAutoplayTimeout);
            this.videoElement.removeEventListener('ended', this.setLoopTimeout);
        }
    }

    videoRef(videoElement: HTMLVideoElement | null) {
        if (!!this.videoElement) {
            // Clean up listeners because unmounted component
            this.videoElement.removeEventListener('canplay', this.setAutoplayTimeout);
            this.videoElement.removeEventListener('ended', this.setLoopTimeout);
            this.videoElement = null;
        }

        if (!!videoElement) {
            this.videoElement = videoElement;
            this.videoElement.addEventListener('canplay', this.setAutoplayTimeout);
            this.videoElement.addEventListener('ended', this.setLoopTimeout);
        }
    }

    setLoopTimeout() {
        if (!!this.videoElement) {
            this.autoplayTimeout = window.setTimeout(this.playVideo, VIDEO_LOOP_DELAY);
        }
    }

    setAutoplayTimeout() {
        if (!!this.videoElement) {
            this.autoplayTimeout = window.setTimeout(this.playVideo, VIDEO_AUTOPLAY_DELAY);
        }
    }

    playVideo() {
        if (!!this.videoElement) {
            this.videoElement.play();
        }
    }

    render() {
        const { open, step, animationState, intl } = this.props;
        return (
            <ToggleAnimation
                reset
                native
                open={open}
                from={emptyTransition}
                enter={emptyTransition}
                leave={
                    step === 0
                        ? AnimationLeaveKeyframes[ANIMATION_STEP_INIT]
                        : AnimationLeaveKeyframes[ANIMATION_STEP_NEXT_STEP]
                }
            >
                {(leaveProps: any) => {
                    return (
                        <AnimationKeyframes native state={animationState}>
                            {({ wrapperTranslate, childTranslate, ...styleProps }) => (
                                <animated.div
                                    className="TutorialMobileVideoAnimationWrapper"
                                    style={{
                                        ...styleProps,
                                        opacity: getPropertyOrFallback(leaveProps.opacity, styleProps.opacity),
                                        transform: getPropertyOrFallback(
                                            leaveProps.wrapperTranslate,
                                            wrapperTranslate
                                        ).interpolate(
                                            (x, y, selfX, selfY) =>
                                                `translate(
                                          calc(${x}% + ${selfX}%),
                                          calc(${y}% + ${selfY}%)
                                      )`
                                        )
                                    }}
                                >
                                    <animated.div
                                        className="TutorialMobileVideo"
                                        style={{
                                            transform: getPropertyOrFallback(
                                                leaveProps.childTranslate,
                                                childTranslate
                                            ).interpolate(
                                                (x, y, selfX, selfY) =>
                                                    `translate(
                                              calc(${x}px - ${selfX}%),
                                              calc(${y}px - ${selfY}%)
                                          )`
                                            )
                                        }}
                                    >
                                        {step === 0 ? (
                                            <img
                                                src={welcomeImage}
                                                alt="Woman holding a mobile phone and 'Mobile recruiting' green text"
                                            />
                                        ) : (
                                            <video ref={this.videoRef} key={step} muted>
                                                <source
                                                    src={getVideo(step - 1, intl.locale, 'webm')}
                                                    type="video/webm"
                                                />
                                                <source src={getVideo(step - 1, intl.locale, 'mp4')} type="video/mp4" />
                                                Your browser does not support the
                                                <code>video</code> tag.
                                            </video>
                                        )}
                                    </animated.div>
                                </animated.div>
                            )}
                        </AnimationKeyframes>
                    );
                }}
            </ToggleAnimation>
        );
    }
}

export default injectIntl(TutorialMobileVideo);
