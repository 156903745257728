import { combineReducers } from 'redux';

import { EMAIL_AND_PASSWORD_AUTHENTICATION } from '../actions';

// Types
import { Action } from '../../types';

export type ErrorsState = Object | Array<any>;

export type State = {
    errors: ErrorsState;
};

export function errorsReducer(state: ErrorsState = [], action: Action): ErrorsState {
    switch (action.type) {
        case EMAIL_AND_PASSWORD_AUTHENTICATION.REQUEST:
            return [];

        case EMAIL_AND_PASSWORD_AUTHENTICATION.FAILURE:
            return action.payload.errors || [];

        default:
            return state;
    }
}

const rootReducer = combineReducers({
    errors: errorsReducer
});

export default rootReducer;
