import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import config from '../../config';
import { getLatestVersion } from '../../modules/version/selectors';
import { isUpdateAvailable } from '../../modules/version/utils';

import classes from './UpdateAvailableNotifier.module.scss';

export type UpdateAvailableNotifierProps = {};

function UpdateAvailableNotifier({}: UpdateAvailableNotifierProps) {
    const latestVersion = useSelector(getLatestVersion);
    const updateAvailable = latestVersion !== null && isUpdateAvailable(config.appVersion, latestVersion);

    const handleClickReload = () => {
        window.location.reload();
    };

    return (
        <React.Fragment>
            {updateAvailable && (
                <div className={classes.root}>
                    <div className={classNames('alert alert-warning', classes.item)}>
                        <div>
                            <strong>
                                <FormattedMessage id="UPDATE_AVAILABLE_NOTIFIER.TITLE" />
                            </strong>
                        </div>
                        <div>
                            <FormattedMessage id="UPDATE_AVAILABLE_NOTIFIER.TEXT" />
                        </div>

                        <button
                            type="button"
                            className={classNames('btn btn-link', classes.button)}
                            onClick={handleClickReload}
                        >
                            <FormattedMessage id="UPDATE_AVAILABLE_NOTIFIER.LINK" />
                        </button>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default UpdateAvailableNotifier;
