import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './OverlayUserMenuLogout.scss';

const OverlayUserMenuLogout = ({ recruiter, onClick }) => {
    let accountInfo;

    const shouldDisplayFullInfo = !!recruiter.first_name && !!recruiter.last_name && !!recruiter.company;
    if (shouldDisplayFullInfo) {
        accountInfo = (
            <span>
                <b className="OverlayUserMenu__nav-logout__name">
                    {recruiter.first_name} {recruiter.last_name}
                </b>
                <span className="OverlayUserMenu__nav-recruiter-subname">{recruiter.company}</span>
            </span>
        );
    } else {
        accountInfo = <b className="OverlayUserMenu__nav-logout__name">{recruiter.email}</b>;
    }

    return (
        <div className="OverlayUserMenu__nav-logout">
            {accountInfo}
            <a
                href="#"
                onClick={onClick}
                className={classNames(
                    'OverlayUserMenu__nav-button OverlayUserMenu__nav-button--no-underline OverlayUserMenu__nav-button--logout',
                    {
                        'OverlayUserMenu__nav-button--with-dot': shouldDisplayFullInfo
                    }
                )}
            >
                <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.LOGOUT" />
            </a>
        </div>
    );
};

export default OverlayUserMenuLogout;
