import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatToMonthInYear } from '../../../utils/date';
import { deprefixHttp, prefixHttp } from '../../../utils/url';

import CandidateResumeSection from '../CandidateResumeSection';
import CandidateResumeItem from '../CandidateResumeItem';

import { Applicant } from '../../../modules/candidates/types';

type Props = {
    applicant: Applicant;
};

const ResumePublications = ({ applicant }: Props) => {
    const { resume } = applicant;
    const publications = resume.publications;

    if (publications.length === 0) {
        return null;
    }

    return (
        <CandidateResumeSection>
            <h1>
                <FormattedMessage id="RESUME_PUBLICATIONS.TITLE" />
            </h1>

            <ul>
                {publications.map((publication, index) => (
                    <ResumePublicationsListItem key={index} publication={publication} />
                ))}
            </ul>
        </CandidateResumeSection>
    );
};

const ResumePublicationsListItem = ({ publication }) => {
    return (
        <CandidateResumeItem
            label={
                !!publication.release_date && (
                    <time dateTime={publication.release_date}>
                        <span>{formatToMonthInYear(publication.release_date)}</span>
                    </time>
                )
            }
        >
            <div>
                <strong>{`${publication.name}, ${publication.publisher}`}</strong>
            </div>
            {!!publication.website && (
                <div>
                    <i>
                        <a href={prefixHttp(publication.website)} target="_blank" rel="noopener">
                            {deprefixHttp(publication.website)}
                        </a>
                    </i>
                </div>
            )}

            {!!publication.summary && (
                <div>
                    <span>{publication.summary}</span>
                </div>
            )}
        </CandidateResumeItem>
    );
};

export default ResumePublications;
