import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { companyContact, links } from '../../constants';
import Headline from '../Headline';
import HeadlineTitle from '../Headline/HeadlineTitle';
import Map from '../Map';
import MapMarker from '../Map/MapMarker';
import PageContainer from '../PageContainer';

import businessCardIcon from '../../assets/images/ic_business-card.svg';
import mailIcon from '../../assets/images/ic_mail.svg';
import phoneIcon from '../../assets/images/ic_phone.svg';
import webIcon from '../../assets/images/ic_web.svg';

import './style.scss';

type Props = {
    intl: IntlShape;
};

const contactInfos = (locale) => [
    {
        icon: mailIcon,
        description: (
            <a className="ContactPage__contact-info-item__link" href={`mailto:${companyContact.email}`}>
                {companyContact.email}
            </a>
        )
    },

    {
        icon: phoneIcon,
        description: companyContact.phone
    },

    {
        icon: webIcon,
        description: (
            <a
                className="ContactPage__contact-info-item__link"
                href={links.website[locale]}
                target="_blank"
                rel="noopener"
            >
                {links.website[locale]}
            </a>
        )
    },

    {
        icon: businessCardIcon,
        description: companyContact.address
    }
];

const ContactPage = ({ intl }: Props) => {
    const contactInfoElements = contactInfos(intl.locale).map((contactInfo, index) => {
        return (
            <div className="ContactPage__contact-info-item" key={index}>
                <img className="ContactPage__contact-info-item__icon" src={contactInfo.icon} role="presentation" />
                <span className="ContactPage__contact-info-item__text">{contactInfo.description}</span>
            </div>
        );
    });

    return (
        <PageContainer className="ContactPage">
            <Headline center className="ContactPage__title">
                <HeadlineTitle>
                    <FormattedMessage id="CONTACT_PAGE.TITLE" />
                </HeadlineTitle>
            </Headline>

            <div className="ContactPage__description">
                <FormattedMessage id="CONTACT_PAGE.DESCRIPTION" />
            </div>

            <div className="ContactPage__secondary-title">
                <FormattedMessage id="CONTACT_PAGE.SECONDARY_TITLE" />
            </div>

            <div className="ContactPage__contact-infos">{contactInfoElements}</div>

            <div className="ContactPage__map-container">
                <Map lat={52.5374258} long={13.3727848} zoom={12}>
                    <MapMarker lat={52.5374258} long={13.3727848} />
                </Map>
            </div>
        </PageContainer>
    );
};

export default injectIntl(ContactPage);
