import React from 'react';
import ReactDOM from 'react-dom';
import useCombinedRef from '../../../hooks/useCombinedRefs';
import ListItem, { ListItemProps } from '../ListItem';

export type ListItemButtonProps = ListItemProps;

const ListItemButton = React.forwardRef<HTMLElement, ListItemButtonProps>(function ListItemButton(
    { component, disabled, onClick, ...rest }: ListItemButtonProps,
    ref
) {
    const buttonRef = React.useRef<HTMLElement>(null);
    const getButtonNode = () => {
        // #StrictMode ready
        return ReactDOM.findDOMNode(buttonRef.current);
    };

    const isNonNativeButton = () => {
        const button = getButtonNode() as Element;

        return (
            (!component || component !== 'button') && !(button.tagName === 'A' && !!(button as HTMLAnchorElement).href)
        );
    };

    const handleKeyDown = (event) => {
        // Keyboard accessibility for non interactive elements
        if (event.target === event.currentTarget && isNonNativeButton() && event.key === 'Enter' && !disabled) {
            event.preventDefault();

            if (onClick) {
                onClick(event);
            }
        }
    };

    const handleKeyUp = (event) => {
        // Keyboard accessibility for non interactive elements
        if (
            onClick &&
            event.target === event.currentTarget &&
            isNonNativeButton() &&
            event.key === ' ' &&
            !event.defaultPrevented
        ) {
            onClick(event);
        }
    };

    const combinedRef = useCombinedRef(ref, buttonRef);

    return (
        <ListItem
            ref={combinedRef}
            component={component}
            disabled={disabled}
            onClick={onClick}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            {...rest}
        />
    );
});

export default ListItemButton;
