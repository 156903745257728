import React from 'react';
import { FormattedMessage } from 'react-intl';

import Headline from '../Headline';
import HeadlineTitle from '../Headline/HeadlineTitle';
import UnknownErrorPage from '../UnknownErrorPage';

export type JobErrorPageErrorType = 'not-found' | 'unknown';

export type JobErrorPageProps = {
    errorType: JobErrorPageErrorType;
};

function JobErrorPage({ errorType }: JobErrorPageProps) {
    if (errorType === 'not-found') {
        return (
            <div className="container">
                <Headline center>
                    <HeadlineTitle>
                        <FormattedMessage id="JOB_ERROR_PAGE.TITLE" />
                    </HeadlineTitle>
                </Headline>
            </div>
        );
    }

    return <UnknownErrorPage />;
}

export default JobErrorPage;
