import { FetchStatus, UpdateStatus } from '../../modules/types';

export type CandidateStatuses = {
    candidateFetchStatus: FetchStatus;
    candidateUpdateStatus: UpdateStatus;
    candidateIsNew: boolean;
};

export function candidateNeedsRead(candidateStatuses: CandidateStatuses | null): boolean {
    return (
        !!candidateStatuses &&
        candidateStatuses.candidateFetchStatus === 'loaded' &&
        candidateStatuses.candidateUpdateStatus !== 'updating' &&
        candidateStatuses.candidateIsNew === true
    );
}
