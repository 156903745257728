import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { legalLinks } from '../../constants';
import { isAuthenticated as getIsAuthenticated } from '../../modules/authentication/selectors';
import { getSignupRoute } from '../../routes';

import './LoggedOutPageFooter.scss';

export type LoggedOutPageFooterProps = {
    className?: string;
};

function LoggedOutPageFooter({ className }: LoggedOutPageFooterProps) {
    const isAuthenticated = useSelector(getIsAuthenticated);
    const location = useLocation();

    if (isAuthenticated || location.pathname === getSignupRoute()) {
        return null;
    }

    return (
        <div className={classNames('footer', className)}>
            <div className="container text-center">
                <ul className="footer__list">
                    {Object.entries(legalLinks).map(([slug, href]) => (
                        <li key={slug} className="footer__list-item">
                            <a href={href} target="_blank" className="footer__link">
                                <FormattedMessage id={`FOOTER.LINK.${slug}`} />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default LoggedOutPageFooter;
