import * as React from 'react';

export const icons = {
    none: require('../../assets/images/ic_filter.svg'),
    active: require('../../assets/images/ic_filter-active.svg')
};

export type Option = {
    value: string;
    label: React.ReactNode;
};

export type Options = Option[];
export type Value = string;
export type Values = Value[];
