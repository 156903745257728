/**
 * Does nothing except returning the value
 * @method noop
 * @param  {*} value The value
 * @return {*}       The value
 */
export const noop = (value) => {
    return value;
};

/**
 * Negates the return value of passed value.
 * @method negate
 * @param  {Function} fn The function which return value will be negated
 * @return {Function}    The wrapper function
 */
export const negate = (fn) => {
    return (...args) => !fn(...args);
};

/**
 * Runs the passed functions and pass the value to the next function. The last passed function
 * will be executed as the first.
 * @method compose
 * @param  {...Function} fns The functions to execute
 * @return {*}               The result
 */
export const compose = (...fns) => {
    return (result) => {
        return fns.reduceRight((result, fn) => fn(result), result);
    };
};

/**
 * Runs the functions in the opposite order than {@link compose}.
 * @method sequence
 * @param  {...Function} fns The functions to execute
 * @return {*}               The result
 */
export const sequence = (...fns) => {
    return compose(...Array.prototype.slice.call(fns).reverse());
};

/**
 * Run the function only once
 * @param  {Function} fn            The function to execute
 * @return {(...args) => *}         The wrapper function – returns the value of the first execution of the given function
 */
export function once(fn) {
    let didRun = false;
    let ret;

    return (...args) => {
        if (!didRun) {
            didRun = true;
            ret = fn(...args);
        }

        return ret;
    };
}
