import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckoutProcessState } from '../../modules/checkout/types';
import { Job } from '../../modules/jobs/types';
import { addActionNotification } from '../../modules/notifications/actions';
import { getRecruiter } from '../../modules/recruiters/selectors';
import CheckoutSuccessPage from './CheckoutSuccessPage';
import { Recruiter } from '../../modules/recruiters/types';

export type CheckoutSuccessPageContainerProps = {
    recruiter: Recruiter | null;
    checkoutProcessState: CheckoutProcessState;
    job: Job | null;
    jobIsLoading: boolean;
};

export default function CheckoutSuccessPageContainer({
    checkoutProcessState,
    job,
    jobIsLoading
}: CheckoutSuccessPageContainerProps) {
    const recruiter = useSelector(getRecruiter);
    const dispatch = useDispatch();

    const onCopyComplete = () => {
        dispatch(addActionNotification('CHECKOUT_SUCCESS_PAGE.COPY_LINK_TO_CLIPBOARD.SUCCES_NOTIFICATION'));
    };

    return (
        <CheckoutSuccessPage
            recruiter={recruiter}
            checkoutProcessState={checkoutProcessState}
            job={job}
            jobIsLoading={jobIsLoading}
            onCopyComplete={onCopyComplete}
        />
    );
}
