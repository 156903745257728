import { AxiosResponse } from 'axios';

export default function makeApiTokenRefresherResponseInterceptor(refreshToken: () => void) {
    // Create the real interceptor
    return (response: AxiosResponse) => {
        const { config } = response;

        if (!!config.headers) {
            // If a authorization header was set for this request and the API succeeded we are safe to refresh the token
            if (!!config.headers.authorization || !!config.headers.Authorization) {
                refreshToken();
            }
        }

        return Promise.resolve(response);
    };
}
