import React from 'react';
import { FormattedDate } from 'react-intl';
import { longNumericDateTimeFormatOptions } from '../../utils/date';

export type FormattedLongNumericDateProps = {
    value: string | number | Date | undefined;
};

function FormattedLongNumericDate({ value }: FormattedLongNumericDateProps) {
    return <FormattedDate value={value} {...longNumericDateTimeFormatOptions} />;
}

export default FormattedLongNumericDate;
