import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import config, { getClientConfig } from '../../config';
import { getServices } from '../analytics/services';

export function init() {
    Sentry.init({
        enabled: process.env.NODE_ENV === 'production',
        dsn: config.sentryDsn ?? undefined,
        environment: config.appEnvironment,
        release: config.sentryRelease ?? undefined,

        debug: config.debug || false,

        normalizeDepth: 6,
        maxBreadcrumbs: 100,

        integrations: [new BrowserTracing()],

        tracesSampleRate: config.sentryTracesSampleRate ?? 0,

        beforeBreadcrumb: onBeforeBreadcrumb
    });

    if (window.TrufflsApp) {
        const App = window.TrufflsApp;

        Sentry.configureScope((scope) => {
            !!App.deviceID && scope.setTag('device.id', App.deviceID);
            !!App.sessionID && scope.setTag('session.id', App.sessionID);
            !!App.windowID && scope.setTag('window.id', App.windowID);
        });
    }
}

function onBeforeBreadcrumb(breadcrumb: Sentry.Breadcrumb): Sentry.Breadcrumb | null {
    if (
        breadcrumb.type === 'http' &&
        typeof breadcrumb.data?.url === 'string' &&
        typeof breadcrumb.data?.status_code === 'number'
    ) {
        const url = breadcrumb.data.url;
        const statusCode = breadcrumb.data.status_code;
        const method = typeof breadcrumb.data?.method === 'string' ? breadcrumb.data.method : null;

        const isSuccessStatusCode = statusCode >= 200 && statusCode < 300;

        const isSuccessfulAnalyticsRequests =
            isSuccessStatusCode &&
            getServices(getClientConfig()).some(({ apiUrlPattern }) => {
                if (apiUrlPattern instanceof RegExp) {
                    return apiUrlPattern.test(url);
                }

                return false;
            });

        const isSuccessfulVersionGetRequest =
            isSuccessStatusCode && method?.toLowerCase() === 'get' && !!url.match(/\/VERSION\/?(?:[\?#].*)?$/);

        // Drop Hotjar requests
        if (isSuccessfulAnalyticsRequests || isSuccessfulVersionGetRequest) {
            return null;
        }
    }

    return breadcrumb;
}
