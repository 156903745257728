import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import CheckoutActionBar from '../../../components/CheckoutActionBar';
import CheckoutActionBarButton from '../../../components/CheckoutActionBar/CheckoutActionBarButton';
import { ProductV2Id } from '../../../modules/checkout/types';

type CheckoutProductPageActionsProps = {
    selectedProductId: ProductV2Id | null;
    selectedPackageId: number | null;
    onClickContinue: (event: Event) => void;
};

function CheckoutProductPageActions({
    selectedProductId,
    selectedPackageId,
    onClickContinue
}: CheckoutProductPageActionsProps) {
    const valid = !!selectedProductId || !!selectedPackageId;

    return (
        <CheckoutActionBar
            rightAction={
                <CheckoutActionBarButton primary disabled={!valid} onClick={onClickContinue}>
                    <FormattedMessage id="CHECKOUT_PRODUCT_PAGE.ACTION.CONTINUE" />
                </CheckoutActionBarButton>
            }
        />
    );
}

export default CheckoutProductPageActions;
