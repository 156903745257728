import { getJobs } from '../entities/selectors';

import { JobsState, ListName } from './reducer';
import { State } from '../../store/reducer';

const getRoot = (state: State): JobsState => state.jobs;

export const getList = (state: State, listName: ListName) => getRoot(state)[listName];

export const getFetchStatusByList = (state: State, listName: ListName) => getList(state, listName).fetchStatus;
export const getEntriesByList = (state: State, listName: ListName) => getList(state, listName).entries;
export const getTotalCountByList = (state: State, listName: ListName) => getList(state, listName).totalCount;
export const getPageByList = (state: State, listName: ListName) => getList(state, listName).page;
export const getErrorsByList = (state: State, listName: ListName) => getList(state, listName).errors;

export const getJobsByList = (state: State, listName: ListName) => {
    const jobs = getJobs(state);
    const entries = getEntriesByList(state, listName);

    return entries.map((id) => {
        if (typeof jobs[id] === 'undefined') {
            throw new Error(`Cannot map job id to job entity - missing job entity for job id ${id}`);
        }

        return jobs[id];
    });
};
