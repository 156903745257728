import * as React from 'react';
import FlipMove from 'react-flip-move';
import { connect } from 'react-redux';

import Snackbar from '../../components/Snackbar';
import { getActionNotifications } from '../../modules/notifications/selectors';
import { removeActionNotification } from '../../modules/notifications/actions';

import { getLatestActionNotificationWithId } from './utils';
import { APPEAR_AND_ENTER_ANIMATION, LEAVE_ANIMATION } from './constants';

import './style.scss';

import { ActionNotificationsState } from '../../modules/notifications/reducer';
import { ActionNotificationWithId } from './constants';
import { Dispatch } from 'redux';
import { Action } from '../../modules/types';

type ConnectedProps = {
    dispatch: Dispatch<Action>;
    actionNotifications: ActionNotificationsState;
};

type Props = ConnectedProps;

class ActionNotifications extends React.Component<Props> {
    renderSnackbar(actionNotification: ActionNotificationWithId): React.ReactElement<typeof Snackbar> | null {
        const { dispatch } = this.props;

        if (!!actionNotification && !actionNotification.action) {
            return (
                <Snackbar key={`action-notification-${actionNotification.id}`} content={actionNotification.content} />
            );
        } else if (!!actionNotification && !!actionNotification.action.action && !!actionNotification.action.label) {
            const customActionWithClose = () => {
                dispatch(actionNotification.action.action);
                dispatch(removeActionNotification(actionNotification.id));
            };
            const action = {
                label: actionNotification.action.label,
                onAction: customActionWithClose
            };

            return (
                <Snackbar
                    key={`action-notification-${actionNotification.id}`}
                    content={actionNotification.content}
                    action={action}
                />
            );
        }

        return null;
    }

    renderLatestSnackbar(): React.ReactElement<typeof Snackbar> | null {
        const { actionNotifications } = this.props;
        const latestNotification = getLatestActionNotificationWithId(actionNotifications);

        return this.renderSnackbar(latestNotification);
    }

    render() {
        return (
            <div className="ActionNotifications">
                <div className="ActionNotifications__animation-container">
                    <FlipMove
                        duration={250}
                        easing="ease-out"
                        appearAnimation={APPEAR_AND_ENTER_ANIMATION}
                        enterAnimation={APPEAR_AND_ENTER_ANIMATION}
                        leaveAnimation={LEAVE_ANIMATION}
                        staggerDelayBy={200}
                        maintainContainerHeight
                    >
                        {this.renderLatestSnackbar()}
                    </FlipMove>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    actionNotifications: getActionNotifications(state)
});

export default connect(mapStateToProps)(ActionNotifications);
