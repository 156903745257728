import { SagaIterator } from 'redux-saga';
import { all, take, cancel, fork, spawn, put, call } from 'redux-saga/effects';

import { hasErrorWithTitle, isApiResponseError } from '../../../services/api/utils';
import { ERROR_INVALID_PAGE } from '../../../services/api/constants';
import * as candidatesApi from '../api';
import {
    LOAD_CANDIDATES,
    LOAD_CANDIDATE,
    UPDATE_CANDIDATE,
    UPDATE_CANDIDATE_STATE,
    FORWARD_CANDIDATE,
    fetchCandidates,
    fetchCandidate,
    candidateUpdate,
    candidateForward
} from '../actions';
import { Params, getParamsByListName } from '../params';

import { Candidate, CandidateState } from '../types';

import fetchCandidatesByApplicantIdSaga from './fetchCandidatesByApplicantIdSaga';
import deleteCandidateSaga from './deleteCandidateSaga';

type LoadCandidatesSagaParams = Omit<Params, 'page'> & {
    page: number | 'last';
};

// Routines
function* loadCandidatesSaga(
    listName: string,
    jobId: number,
    params: LoadCandidatesSagaParams,
    countOfRun: number = 0
): SagaIterator {
    try {
        // Fetch candidates with given job id and query params
        const response = yield call(candidatesApi.fetchCandidates, {
            ...params,
            job: jobId
        });

        let currentPage: number;
        // If the param `page` is last we have the calculate the page
        if (params.page === 'last') {
            const pageSize = params.pageSize;
            const totalCount = response.data.count;

            currentPage = Math.max(1, Math.ceil(totalCount / pageSize));
        } else {
            currentPage = params.page;
        }

        yield put(fetchCandidates.success(listName, jobId, currentPage, response));
    } catch (err) {
        // If it's not a response error stop here
        if (!isApiResponseError(err)) {
            throw err;
        }

        const errors = err.response.data.errors || [];

        // If ran this before stop here and dispatch fail action
        if (countOfRun >= 1) {
            return yield put(fetchCandidates.failure(listName, jobId, err.response.data.errors));
        }

        /* If the the errors array contains an error about an invalid page and the page is not last we want to fetch
         * the last page.
         */
        if (hasErrorWithTitle(errors, ERROR_INVALID_PAGE) && params.page !== 'last') {
            const paramsForLastPage: LoadCandidatesSagaParams = {
                ...params,
                page: 'last'
            };

            return yield call(loadCandidatesSaga, listName, jobId, paramsForLastPage, countOfRun + 1);
        }

        return yield put(fetchCandidates.failure(listName, jobId, err.response.data.errors));
    }
}

function* startLoadCandidatesSaga(listName: string, jobId: number, params: Object) {
    const defaultParams = yield call(getParamsByListName, listName);
    const combinedParams = { ...defaultParams, ...params };

    yield put(fetchCandidates.request(listName, jobId, combinedParams.page));

    yield call(loadCandidatesSaga, listName, jobId, combinedParams);
}

function* loadCandidateSaga({ payload }) {
    const candidateId = payload.candidateId;

    try {
        yield put(fetchCandidate.request(candidateId));

        const response = yield call(candidatesApi.fetchCandidate, candidateId);

        yield put(fetchCandidate.success(candidateId, response));
    } catch (err) {
        if (isApiResponseError(err)) {
            yield put(fetchCandidate.failure(candidateId, err.response.data.errors));
        } else {
            throw err;
        }
    }
}

function* updateCandidateSaga(candidateId: number, newCandidate: Partial<Candidate>) {
    try {
        yield put(candidateUpdate.request(candidateId));

        const response = yield call(candidatesApi.updateCandidate, candidateId, newCandidate);

        yield put(candidateUpdate.success(candidateId, response));
    } catch (err) {
        if (isApiResponseError(err)) {
            yield put(candidateUpdate.failure(candidateId, err.response.data.errors));
        } else {
            throw err;
        }
    }
}

function* updateCandidateStateSaga(candidateId: number, newState: CandidateState) {
    const newCandidate = {
        state: newState
    };

    yield call(updateCandidateSaga, candidateId, newCandidate);
}

function* forwardCandidateSaga({ payload }) {
    const candidateId = payload.candidateId;

    try {
        yield put(candidateForward.request(candidateId));

        const body = {
            to_email: payload.email,
            subject: payload.subject,
            message: payload.message
        };

        yield call(candidatesApi.forwardCandidate, candidateId, body);

        yield put(candidateForward.success(candidateId));
    } catch (error) {
        if (error instanceof Error) {
            yield put(candidateForward.failure(candidateId));
        } else {
            throw error;
        }
    }
}

// Watchers
function* watchLoadCandidatesSaga() {
    const tasks = {};

    while (true) {
        const action = yield take(LOAD_CANDIDATES);
        const listName = action.payload.listName;
        const jobId = action.payload.jobId;

        const taskId = `${listName}:${jobId}`;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        const params = action.payload.params;

        tasks[taskId] = yield fork(startLoadCandidatesSaga, listName, jobId, params);
    }
}

function* watchLoadCandidate() {
    const tasks = {};

    while (true) {
        const action = yield take(LOAD_CANDIDATE);
        const payload = action.payload;

        const taskId = payload.candidateId;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        tasks[taskId] = yield fork(loadCandidateSaga, action);
    }
}

function* watchUpdateCandidateSaga() {
    const tasks = {};

    while (true) {
        const action = yield take(UPDATE_CANDIDATE);
        const payload = action.payload;

        const taskId = payload.candidateId;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        tasks[taskId] = yield fork(updateCandidateSaga, payload.candidateId, payload.newCandidate);
    }
}

function* watchUpdateCandidateStateSaga() {
    const tasks = {};

    while (true) {
        const action = yield take(UPDATE_CANDIDATE_STATE);
        const payload = action.payload;

        const taskId = payload.candidateId;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        tasks[taskId] = yield fork(updateCandidateStateSaga, payload.candidateId, payload.newState);
    }
}

function* watchForwardCandidateSaga() {
    const tasks = {};

    while (true) {
        const action = yield take(FORWARD_CANDIDATE);
        const payload = action.payload;

        const taskId = payload.candidateId;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        tasks[taskId] = yield fork(forwardCandidateSaga, action);
    }
}

// Root
function* rootSaga(): Generator<Object, any, any> {
    yield all([
        spawn(watchLoadCandidatesSaga),
        spawn(watchLoadCandidate),

        spawn(watchUpdateCandidateSaga),
        spawn(watchUpdateCandidateStateSaga),

        spawn(deleteCandidateSaga),
        spawn(fetchCandidatesByApplicantIdSaga),

        spawn(watchForwardCandidateSaga)
    ]);
}

export default rootSaga;
