import { createAction } from 'redux-actions';

import { createActionTypeCreator } from '../../utils/redux';

const createActionType = createActionTypeCreator('UI/EMAIL_AND_PASSWORD_LOGIN_PAGE');

export const SUBMIT_FORM = createActionType('SUBMIT_FORM');

export const submitForm = createAction(SUBMIT_FORM, (values, destinationUrl) => {
    return {
        values,
        destinationUrl
    };
});
