import * as React from 'react';
import classNames from 'classnames';

import { PolymorphicComponentProps } from '../../../types/react';

import { ProductContext } from '../ProductContext';

import classes from './ProductActionButton.module.scss';

export type ProductActionButtonProps<Type extends React.ElementType = 'button'> = PolymorphicComponentProps<
    Type,
    {
        children: React.ReactNode;
    },
    'component'
>;

function ProductActionButton<Type extends React.ElementType = 'button'>({
    component,
    ...restProps
}: ProductActionButtonProps<Type>) {
    const Component = component || 'button';

    const { promote, targetOrientation } = React.useContext(ProductContext);

    const buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement> = {};
    if (Component === 'button') {
        buttonProps.type = 'button';
    }

    return (
        <Component
            {...buttonProps}
            {...restProps}
            className={classNames(classes.root, {
                [classes.promote]: promote,
                [classes.horizontal]: targetOrientation === 'horizontal'
            })}
        />
    );
}

export default ProductActionButton;
