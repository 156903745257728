import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Headline from '../Headline';
import HeadlineTitle from '../Headline/HeadlineTitle';
import Icon from '../Icon';

import { Job } from '../../modules/jobs/types';

import './style.scss';

type Props = {
    skeleton?: boolean;

    job: Job | null;
    messageId: string;
};

const CandidatePageHeader = (props: Props) => {
    const skeleton = !!props.skeleton;
    const job = props.job;
    const messageId = props.messageId;

    let titleElm: React.ReactNode = null;

    if (!skeleton && !!job) {
        titleElm = (
            <span className="CandidatePageHeader__title">
                <span className="CandidatePageHeader__title-content">{job.title}</span>

                {'\u00A0'}
                <Link to={`/job/${job.id}`} className="CandidatePageHeader__icon-link">
                    <Icon icon="pen" className="CandidatePageHeader__icon" />
                </Link>
            </span>
        );
    } else {
        titleElm = <span className="CandidatePageHeader__title" />;
    }

    const className = classNames('CandidatePageHeader', { 'CandidatePageHeader--skeleton': skeleton });

    return (
        <Headline center className={className}>
            <HeadlineTitle>
                <span className="CandidatePageHeader__prefix">
                    <FormattedMessage id={messageId} />
                </span>

                {titleElm}
            </HeadlineTitle>
        </Headline>
    );
};

export default CandidatePageHeader;
