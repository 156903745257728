import * as React from 'react';

import classes from './ProductSection.module.scss';

export type ProductSectionProps =
    | {
          children?: React.ReactNode;
          html?: never;
      }
    | {
          html?: string;
          children?: never;
      };

function ProductSection({ children, html }: ProductSectionProps) {
    const contentProps: React.HTMLAttributes<HTMLElement> = {};

    if (html) {
        contentProps.dangerouslySetInnerHTML = { __html: html };
    } else {
        contentProps.children = children;
    }

    return (
        <section className={classes.root}>
            <div className={classes.content} {...contentProps} />
        </section>
    );
}

export default ProductSection;
