import * as React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';

import SignupFormStepHeadline from '../../../components/SignupFormStepHeadline';
import SignupFormStepActions from '../SignupFormStepActions';
import FormFieldInput from '../../../components/FormFieldInput';
import FormFieldSelect from '../../../components/FormFieldSelect';

import { FORM_NAME, FormValues } from '../constants';

import './SignupFormStepProfile.style.scss';

// Types
import { Intl } from '../../../types/intl';

type ConnectorProps = {
    onBack: () => void;
    forTrial: boolean;
};

type PropsWithForm = ConnectorProps & InjectedFormProps<FormValues, ConnectorProps>;

type InjectedIntlProps = {
    intl: Intl;
};

type Props = PropsWithForm & InjectedIntlProps;

class SignupFormStepProfile extends React.Component<Props> {
    render() {
        const { intl, forTrial, handleSubmit, submitting, valid, onBack } = this.props;

        let titleMessageId = 'SIGNUP_FORM_STEP_PROFILE.TITLE';
        let descriptionMessageId = 'SIGNUP_FORM_STEP_PROFILE.DESCRIPTION';

        if (forTrial) {
            titleMessageId = 'SIGNUP_FORM_STEP_PROFILE.TITLE_FOR_TRIAL';
            descriptionMessageId = 'SIGNUP_FORM_STEP_PROFILE.DESCRIPTION_FOR_TRIAL';
        }

        return (
            <form onSubmit={handleSubmit}>
                <SignupFormStepHeadline>
                    <FormattedMessage id={titleMessageId} />
                </SignupFormStepHeadline>

                <p className="SignupFormStepProfile__paragraph">
                    <FormattedMessage id={descriptionMessageId} />
                </p>

                <div className="SignupFormStepProfile__content">
                    <FormFieldSelect
                        name="gender"
                        id="gender"
                        required
                        autoFocus
                        labelText={<FormattedMessage id="SIGNUP_FORM_STEP_PROFILE.FIELD_GENDER.LABEL" />}
                    >
                        <option value="" disabled></option>
                        <option value="f">
                            {intl.formatMessage({ id: 'SIGNUP_FORM_STEP_PROFILE.FIELD_GENDER_OPTION_FEMALE.LABEL' })}
                        </option>
                        <option value="m">
                            {intl.formatMessage({ id: 'SIGNUP_FORM_STEP_PROFILE.FIELD_GENDER_OPTION_MALE.LABEL' })}
                        </option>
                    </FormFieldSelect>
                    <FormFieldInput
                        type="text"
                        name="firstName"
                        id="first-name"
                        label={<FormattedMessage id="SIGNUP_FORM_STEP_PROFILE.FIELD_FIRST_NAME.LABEL" />}
                        required
                        autoComplete="given-name"
                    />
                    <FormFieldInput
                        type="text"
                        name="lastName"
                        id="last-name"
                        label={<FormattedMessage id="SIGNUP_FORM_STEP_PROFILE.FIELD_LAST_NAME.LABEL" />}
                        required
                        autoComplete="family-name"
                    />
                    <FormFieldInput
                        name="company"
                        type="text"
                        id="company"
                        label={<FormattedMessage id="SIGNUP_FORM_STEP_PROFILE.FIELD_COMPANY.LABEL" />}
                        required
                        autoComplete="organization"
                    />

                    <SignupFormStepActions
                        submitStep
                        submitting={submitting}
                        valid={valid}
                        onBack={onBack}
                        onNext={handleSubmit}
                        forTrial={forTrial}
                    />
                </div>
            </form>
        );
    }
}

const SignupFormStepProfileWithIntl = injectIntl(SignupFormStepProfile);

const withForm = reduxForm<FormValues, ConnectorProps>({
    form: FORM_NAME,
    destroyOnUnmount: false
});

export default withForm(SignupFormStepProfileWithIntl);
