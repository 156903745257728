import * as React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import Textarea from 'react-textarea-autosize';

import './MessageFormTextarea.style.scss';

export type MessageFormTextareaProps = {
    className?: string;
    id: string;
    content?: React.ReactNode;
    message: string;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    useLegacyTheme?: boolean;
};

function MessageFormTextarea({
    className,
    id,
    content,
    message,
    onChange,
    onKeyDown,
    useLegacyTheme,
    ...textareaProps
}: MessageFormTextareaProps) {
    const intl = useIntl();
    return (
        <div
            className={classNames(
                {
                    'MessageFormTextarea--legacy-theme': useLegacyTheme
                },
                className
            )}
            data-testid="root"
        >
            {content && (
                <div className="MessageFormTextarea__content" data-testid="textarea-content">
                    {content}
                </div>
            )}

            <Textarea
                id={id}
                name="text"
                minRows={1}
                className="MessageFormTextarea__input"
                placeholder={intl.formatMessage({
                    id: 'MESSAGE_FORM.PLACEHOLDER'
                })}
                value={message}
                onChange={onChange}
                onKeyDown={onKeyDown}
                data-testid="textarea"
                {...textareaProps}
            />
        </div>
    );
}

export default MessageFormTextarea;
