import * as React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { FormattedMessage } from 'react-intl';
import BottomActionBar from '../BottomActionBar';
import Button from '../Button';
import MobilePageContainer from '../MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../NavigationBar';
import scrolled, { ScrolledProps } from '../Scrolled';
import './style.scss';

type Props = ScrolledProps & {
    open: boolean;
    onHide: (doNotShowAgain: boolean) => void;
    onClose: () => void;
};

type State = {
    isViewportMobile: boolean;
    doNotShowAgain: boolean;
};

class HideCandidateView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isViewportMobile: false,
            doNotShowAgain: false
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleHide = this.handleHide.bind(this);
    }

    handleCheckboxChange(event) {
        this.setState({ doNotShowAgain: event.target.checked });
    }

    handleHide() {
        this.props.onHide(this.state.doNotShowAgain);
    }

    componentDidMount() {
        if (matchMedia('(max-width: 769px)').matches) {
            this.setState({ isViewportMobile: true });
        } else {
            this.setState({ isViewportMobile: false });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.open && !!this.props.open) {
            if (matchMedia('(max-width: 769px)').matches) {
                this.setState({ isViewportMobile: true });
            } else {
                this.setState({ isViewportMobile: false });
            }
        }
    }

    render() {
        const title = <FormattedMessage id="HIDE_CANDIDATE.TITLE" />;

        const hide = <FormattedMessage id="HIDE_CANDIDATE.ACTION.HIDE" />;

        const notAgain = (
            <span className="tf-checkbox pull-left HideCandidateView__checkbox">
                <input
                    type="checkbox"
                    id="hide-candidate_not-show-again"
                    onChange={this.handleCheckboxChange}
                    checked={this.state.doNotShowAgain}
                />

                <label
                    className="tf-checkbox__label HideCandidateView__checkbox-label"
                    htmlFor="hide-candidate_not-show-again"
                >
                    <span className="tf-checkbox__faux" />
                    <span className="tf-checkbox__label-text">
                        <FormattedMessage id="HIDE_CANDIDATE.ACTION.NOT_AGAIN" />
                    </span>
                </label>
            </span>
        );

        const content = (
            <div className="HideCandidateView__content">
                <p>
                    <FormattedMessage id="HIDE_CANDIDATE.DESCRIPTION.FIRST" />
                </p>
                <p>
                    <FormattedMessage id="HIDE_CANDIDATE.DESCRIPTION.SECOND" />
                </p>
            </div>
        );

        if (this.state.isViewportMobile) {
            return this.renderMobileFrame(content, title, hide, notAgain);
        } else {
            return this.renderDesktopFrame(content, title, hide, notAgain);
        }
    }

    renderMobileFrame(content, title, hide, notAgain) {
        const { scrolled, onScroll, open, onClose } = this.props;

        return (
            <MobilePageContainer show={open}>
                <NavigationBar
                    noShadow={!scrolled}
                    centerElement={<NavigationBarText>{title}</NavigationBarText>}
                    rightElement={<NavigationBarCloseAction onClick={onClose} />}
                />

                <div className="HideCandidateView__overlay-container" onScroll={onScroll}>
                    {content}
                    {notAgain}
                </div>
                <BottomActionBar>
                    <Button type="submit" onClick={this.handleHide} typeStyle="flat" variationStyle="normal">
                        {hide}
                    </Button>
                </BottomActionBar>
            </MobilePageContainer>
        );
    }

    renderDesktopFrame(content, title, hide, notAgain) {
        const { open, onClose } = this.props;

        return (
            <Modal show={open} onHide={onClose}>
                <Modal.Header className="HideCandidateView__modal-header">
                    <Modal.Title className="HideCandidateView__modal-title">{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{content}</Modal.Body>
                <Modal.Footer className="HideCandidateView__modal-footer">
                    {notAgain}
                    <Button type="button" onClick={onClose} typeStyle="flat" variationStyle="normal">
                        <FormattedMessage id="HIDE_CANDIDATE.ACTION.CANCEL" />
                    </Button>
                    <Button type="button" onClick={this.handleHide} typeStyle="flat" variationStyle="normal">
                        {hide}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default scrolled(HideCandidateView);
