import { AxiosError, AxiosResponse } from 'axios';
import { ResponseError, ErrorResponseData } from '../types';

function normalizeResponseError(error: AxiosError & { response?: AxiosResponse<ErrorResponseData> }) {
    if (!!error.response) {
        const { response } = error;
        const { status, statusText, data } = response;

        if (status === 404 || status === 504 || !!data.detail) {
            // We use the detail as title and the status text as fallback
            let title = data.detail || statusText;
            // We remove the `.` at the end
            title = title.substr(-1) === '.' ? title.substr(0, title.length - 1) : title;

            const errors: ResponseError[] = [
                {
                    status: status,
                    title: title
                }
            ];

            response.data = { errors };
        }
    }

    return Promise.reject(error);
}

export default normalizeResponseError;
