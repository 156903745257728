import React from 'react';

export type RequestTokenRequestPageLayoutProps = {
    title: React.ReactNode;
    children: React.ReactNode;
    'data-testid'?: string;
};

export function RequestTokenRequestPageLayout({
    title,
    children,
    'data-testid': dataTestId
}: RequestTokenRequestPageLayoutProps) {
    return (
        <div className="container" data-testid={dataTestId}>
            <div className="row">
                <div className="col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4">
                    <h2 className="text-center">{title}</h2>

                    <hr />

                    {children}
                </div>
            </div>
        </div>
    );
}
