import { MOUNT } from './reducer';
import { initialCandidateIdListState } from './reducer/byCandidateId';
import { getFile } from '../entities/selectors';

import { State } from '../../store/reducer';
import { FilesState } from './reducer';
import {
    CandidateIdListsState,
    CandidateIdListState,
    FetchStatusState,
    EntriesState,
    ErrorState,
    PaginationState
} from './reducer/byCandidateId';
import { File } from './types';

import {
    CreateStatusState,
    FileRequestsState,
    FileRequestsByCandidateIdListState
} from './reducer/fileRequestsByCandidateId';
import { initialFileRequestsListState } from './reducer/fileRequestsByCandidateId';

/**
 * Returns the root
 */
function getFilesState(state: State): FilesState {
    return state[MOUNT];
}

/**
 * Returns the sub state `byCandidateId`.
 */
export function getCandidateIdLists(state: State): CandidateIdListsState {
    return getFilesState(state).byCandidateId;
}

/**
 * Returns the files state of the candidate id or if there is no state yet - retuns the initial files state.
 */
export function getCandidateIdList(state: State, candidateId: number): CandidateIdListState {
    return getCandidateIdLists(state)[candidateId] || initialCandidateIdListState;
}

export function getCandidateIdListEntries(state: State, candidateId: number): EntriesState {
    return getCandidateIdList(state, candidateId).entries;
}

export function getCandidateIdListError(state: State, candidateId: number): ErrorState {
    return getCandidateIdList(state, candidateId).error;
}

export function getCandidateIdListFetchStatus(state: State, candidateId: number): FetchStatusState {
    return getCandidateIdList(state, candidateId).fetchStatus;
}

export function getCandidateIdListPagination(state: State, candidateId: number): PaginationState {
    return getCandidateIdList(state, candidateId).pagination;
}

export function getCandidateIdListFiles(state: State, candidateId: number): File[] {
    const entries = getCandidateIdListEntries(state, candidateId);

    return entries.map((fileId) => {
        const file = getFile(state, fileId);

        if (!file) {
            throw new Error(`Cannot map file id to file entity - missing file entity for file id ${fileId}`);
        }

        return file;
    });
}

export function getCandidateIdListPaginationNextPage(state: State, candidateId: number): PaginationState['nextPage'] {
    return getCandidateIdListPagination(state, candidateId).nextPage;
}

export function hasCandidateMoreFiles(state: State, candidateId: number): boolean {
    return getCandidateIdListPaginationNextPage(state, candidateId) !== null;
}

/**
 * Returns the sub state `requestsByCandidateId`.
 */
export function getFileRequestsByCandidateIdList(state: State): FileRequestsByCandidateIdListState {
    return getFilesState(state).fileRequestsByCandidateId;
}

/**
 * Returns the request state of the candidate id or if there is no state yet - retuns the initial files state.
 */
export function getFileRequestByCandidateId(state: State, candidateId: number): FileRequestsState {
    return getFileRequestsByCandidateIdList(state)[candidateId] || initialFileRequestsListState;
}

export function getFileRequestByCandidateIdErrors(state: State, candidateId: number): ErrorState {
    return getFileRequestByCandidateId(state, candidateId).errors;
}

export function getFileRequestByCandidateIdCreateStatus(state: State, candidateId: number): CreateStatusState {
    return getFileRequestByCandidateId(state, candidateId).createStatus;
}
