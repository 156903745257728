import { createSelector } from 'reselect';

import {
    getTemplatesEntities,
    getTemplatesEntitiesFetchStatus,
    getTemplatesEntitiesUpdateStatus
} from '../entities/selectors';
import { FETCH_STATUS, UPDATE_STATUS } from '../constants';

import { MOUNT } from './reducer';

import { State } from '../../store/reducer';
import { FetchStatus, UpdateStatus, Pagination } from '../types';
import { Template } from './types';
import { TemplatesState } from './reducer';

// Root
const getState = (state: State): TemplatesState => state[MOUNT];

// Fields
export const getTemplatesIds: (state: State) => TemplatesState['entries'] = createSelector(
    getState,
    (state) => state.entries
);

export const getTemplatesFetchStatus: (state: State) => TemplatesState['fetchStatus'] = createSelector(
    getState,
    (state) => state.fetchStatus
);

export const getTemplatesPagination: (state: State) => TemplatesState['pagination'] = createSelector(
    getState,
    (state) => state.pagination
);

// Recomposed
export const getTemplates: (state: State) => Array<Template> = createSelector(
    getTemplatesIds,
    getTemplatesEntities,
    (templatesIds, templatesEntities: { [id: string]: Template }) => templatesIds.map((id) => templatesEntities[id])
);

export const getVisibleTemplates: (state: State) => Array<Template> = createSelector(
    getTemplatesIds,
    getTemplatesEntities,
    (templatesIds, templatesEntities: { [id: string]: Template }) =>
        templatesIds.map((id) => templatesEntities[id]).filter((template) => template.visible)
);

export const getTemplatesPaginationCount: (state: State) => Pagination['count'] = createSelector(
    getTemplatesPagination,
    (templatesPagination) => templatesPagination.count
);

export const getTemplatesPaginationNextPage: (state: State) => Pagination['nextPage'] = createSelector(
    getTemplatesPagination,
    (templatesPagination) => templatesPagination.nextPage
);

export const getTemplatesPaginationPreviousPage: (state: State) => Pagination['previousPage'] = createSelector(
    getTemplatesPagination,
    (templatesPagination) => templatesPagination.previousPage
);

export const isFetchingTemplates: (state: State) => boolean = createSelector(
    getTemplatesFetchStatus,
    (templatesFetchStatus) => templatesFetchStatus === FETCH_STATUS.LOADING
);

// Utils
export const getTemplate = (state: State, id: Template['id']): Template | null => {
    return getTemplatesEntities(state)[id] || null;
};
export const getTemplateFetchStatus = (state: State, id: Template['id']): FetchStatus => {
    return getTemplatesEntitiesFetchStatus(state)[id] || FETCH_STATUS.NONE;
};
export const getTemplateUpdateStatus = (state: State, id: Template['id']): UpdateStatus => {
    return getTemplatesEntitiesUpdateStatus(state)[id] || UPDATE_STATUS.NONE;
};
export const isUpdatingTemplate = (state: State, id: Template['id']): boolean => {
    return getTemplateUpdateStatus(state, id) === UPDATE_STATUS.UPDATING;
};
