export type JobState = 'new' | 'processing' | 'draft' | 'active' | 'archived';

export type JobLocation = {
    raw_source: string;
    country: string;
    region: string;
    locality: string;
    postal_code: string;
    street_address: string;
    latitude: number | null;
    longitude: number | null;
};

export type JobExperience =
    | 'with-experience'
    | 'without-experience'
    | 'with-staff-responsibility'
    | 'without-staff-responsibility';

export type JobContact = {
    first_name: string;
    last_name: string;
    position: string;
    email: string;
    phone: string;
    portrait_url: string | null;
};

export type JobBackground = {
    original: string;
    medium: string;
    large: string;
};

export enum JobHiringBudgetScale {
    Year = 'yr',
    Month = 'mo',
    Hour = 'hr'
}

export type JobHiringBudget = {
    budget_currency: 'EUR';
    budget_higher: string;
    budget_lower: string;
    scale: JobHiringBudgetScale;
};

export enum JobStartDateStart {
    Flex = 'flex',
    Date = 'date',
    Asap = 'asap'
}

export type JobStartDate =
    | {
          start: JobStartDateStart.Asap | JobStartDateStart.Flex;
          date: null;
      }
    | {
          start: JobStartDateStart.Date;
          date: string;
      };

export type JobStatistics = {
    candidates: number;
    candidates_new: number;
    candidates_locked: number;
    candidates_not_interesting: number;
    candidates_interesting: number;
    candidates_phone_interview: number;
    candidates_interview: number;
    candidates_hired: number;
    candidates_deleted: number;
    candidates_contacted: number;
    candidates_forwarded: number;

    messages_unread_by_recruiter: number;
};

export type JobCompanyLogo = {
    original: string;
    medium: string;
};

export type JobAboutUs = {
    title: string;
    body: string;
};

export type JobGalleryImage = {
    order: number;
    url: string;
    file: string;
};

export type JobId = number;

export type Job = {
    id: JobId;

    created: string | null;
    is_premium: boolean;
    is_platinum_premium: boolean;

    state: JobState;
    is_complete: boolean;
    provider: string;

    active_until: string | null;
    product: string | null;

    title: string;
    ad_language: string;
    location: JobLocation;
    remote_possible: boolean;

    is_startup: boolean;
    functions: string[];
    career_statutes: string[];
    experiences: JobExperience[];
    worktypes: string[];
    sectors: string[];
    tasks: string[];
    talents: string[];
    skills: string[];
    languages: string[];

    hiring_budget: JobHiringBudget | null;
    start_date: JobStartDate | null;

    company: string;

    company_logo_url: string | null;
    company_logo_original: string | null;
    company_logo: JobCompanyLogo;

    about_us: JobAboutUs;

    background_url: string | null;
    background_original: string | null;
    background: JobBackground;

    gallery_images: JobGalleryImage[];

    benefits: string;

    has_expiring_candidates: boolean;

    statistics: JobStatistics;

    url: string;
    static_url: string;
    preview_url: string | null;
};

export type JobSubset<K extends keyof Job> = Pick<Job, K>;
