import Mustache from 'mustache';

import { Template } from './types';

export function isDefaultTemplate(template: Template): boolean {
    return template.has_blueprint;
}

export function isCustomTemplate(template: Template): boolean {
    return !isDefaultTemplate(template);
}

export function isDefaultEditedTemplate(template: Template): boolean {
    return isDefaultTemplate(template) && template.content !== template.blueprint_content;
}

export function getTemplateBlueprintContent(template: Template): Template['blueprint_content'] | null {
    if (!isDefaultTemplate(template)) {
        return null;
    }

    return template.blueprint_content;
}

export function getResetedTemplate(template: Template): Partial<Template> | null {
    const blueprintContent = getTemplateBlueprintContent(template);

    if (!!blueprintContent) {
        return { ...template, content: blueprintContent };
    } else {
        return null;
    }
}

export function compileTemplate(template: Template, tokens: Object = {}): string {
    return Mustache.render(template.content, tokens);
}
