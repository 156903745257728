import { Breakpoint as Breakpoint_, getMediaQuery } from '../utils/viewport';
import useMediaQueryMatches from './useMediaQuery';

export type Breakpoint = Breakpoint_;
export type Match = {
    breakpoint: Breakpoint;
};

function useBreakpointMatch(breakpoint: Breakpoint): Match | null {
    const matches = useMediaQueryMatches(getMediaQuery(breakpoint));

    return matches ? { breakpoint } : null;
}

export default useBreakpointMatch;
