import apiClientV4 from '../../services/api/v4';
import { Response } from '../types';

import { Flag, FlagSlug, FlagValue } from './types';

export function createFlag(slug: FlagSlug, value: FlagValue): Promise<Response<Flag>> {
    return apiClientV4.post('/flags/', {
        slug,
        value
    });
}

export function fetchFlag(slug: FlagSlug): Promise<Response<Flag>> {
    return apiClientV4.get(`/flags/${slug}/`);
}

export function updateFlag(slug: FlagSlug, value: FlagValue): Promise<Response<Flag>> {
    return apiClientV4.patch(`/flags/${slug}/`, {
        value
    });
}
