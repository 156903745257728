import apiClientV4 from '../../services/api/v4';

import { GalleryImageId, Recruiter } from './types';

export function fetchRecruiter() {
    return apiClientV4.get('/recruiters/me/');
}

export function updateRecruiter(recruiter: Partial<Recruiter>) {
    return apiClientV4.patch('/recruiters/me/', recruiter);
}

export function updateRecruiterPortrait(file: Blob) {
    const formData = new FormData();
    formData.append('portrait_file', file);

    return apiClientV4.patch('/recruiters/me/', formData);
}

export function updateRecruiterCompanyLogo(file: Blob) {
    const formData = new FormData();
    formData.append('company_logo_file', file);

    return apiClientV4.patch('/recruiters/me/', formData);
}

export function updateRecruiterCompanyBackground(file: Blob) {
    const formData = new FormData();
    formData.append('background_file', file);

    return apiClientV4.patch('/recruiters/me/', formData);
}

export function createRecruiterCompanyGalleryImage(file: Blob, order: number) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('order', String(order));

    return apiClientV4.post('/gallery-images/', formData);
}

export function deleteRecruiterCompanyGalleryImage(id: GalleryImageId) {
    return apiClientV4.delete(`/gallery-images/${id}/`);
}
