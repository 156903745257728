import * as React from 'react';
import classNames from 'classnames';

import './SignupPageContainer.style.scss';

type Props = {
    children: React.ReactNode;

    spacerChildren?: React.ReactNode;

    className?: string;
    spacerClassName?: string;
    contentClassName?: string;
};

function SignupPageContainer({ children, className, contentClassName, spacerChildren, spacerClassName }: Props) {
    return (
        <div className={classNames('SignupPageContainer', className)}>
            <div className={classNames('SignupPageContainer__spacer', spacerClassName)}>{spacerChildren}</div>
            <div className={classNames('SignupPageContainer__content', contentClassName)}>{children}</div>
        </div>
    );
}

export default SignupPageContainer;
