import * as React from 'react';
import classNames from 'classnames';

import './MessageItemWrapper.style.scss';

import PortraitPicture from '../PortraitPicture';

import { ConversationParticipant } from '../../modules/conversations/types';

type Props = {
    className?: string;
    fromMe?: boolean;
    owner: ConversationParticipant;
    children: React.ReactNode;
};

const MessageItemWrapper = ({ className, fromMe, owner, children }: Props) => {
    const portrait = !fromMe && (
        <div data-testid="portait" className="MessageItemWrapper__sender-portrait">
            <PortraitPicture
                url={!!owner.portrait_url ? owner.portrait_url.original : null}
                firstName={owner.first_name}
                lastName={owner.last_name}
            />
        </div>
    );

    return (
        <div
            className={classNames('MessageItemWrapper', {
                'MessageItemWrapper--from-me': fromMe,
                'MessageItemWrapper--not-from-me': !fromMe
            })}
        >
            {portrait}
            <div className={classNames('MessageItemWrapper__content', className)}>{children}</div>
        </div>
    );
};

export default MessageItemWrapper;
