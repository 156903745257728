import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dispatch } from 'redux';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';

import FormFieldEmail from '../../components/FormFieldEmail';
import FormFieldPassword from '../../components/FormFieldPassword';

import FormError from '../../components/FormError';
import LoaderButton from '../../components/LoaderButtonV2';

import { stringifySearch } from '../../utils/url';

import { submitForm } from './actions';
import { FORM_ERROR_EMAIL_NOT_CONFIRMED, FORM_NAME, FormValues } from './constants';

import { companyContact } from '../../constants';

import './style.scss';

type ConnectorProps = {
    verificationSuccessful: null | boolean;
    destinationUrl: string | null;
};
type ConnectedStateProps = {
    email: string;
};
type ConnectedDispatchProps = {};

type ConnectProps = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type Props = ConnectProps & InjectedFormProps<FormValues, ConnectProps>;

type State = {
    submittedEmail: string;
};

class EmailAndPasswordLoginForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            submittedEmail: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        if (!!prevProps.submitting && !this.props.submitting) {
            this.setState({
                submittedEmail: this.props.email
            });
        }
    }

    handleSubmit(values: FormValues, dispatch: Dispatch<any>) {
        dispatch(submitForm(values, this.props.destinationUrl));
    }

    render() {
        const { submitting, handleSubmit } = this.props;

        const messages = this.renderMessages();

        const resetPasswordLink = (
            <Link to="/password-reset">
                <FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_FORM.LINK_RESET_PASSWORD" />
            </Link>
        );

        const requestTokenLoginLink = (
            <Link to="/magic-link">
                <FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_FORM.LINK_REQUEST_TOKEN_LOGIN" />
            </Link>
        );

        const signupLink = (
            <Link to="/signup">
                <FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_FORM.LINK_SIGNUP" />
            </Link>
        );

        return (
            <form onSubmit={handleSubmit(this.handleSubmit)} className="EmailAndPasswordLoginForm">
                <h2 className="h4">
                    <FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_FORM.TITLE" />
                </h2>

                {messages}

                <div className="tf-form-row">
                    <FormFieldEmail
                        name="email"
                        required
                        id="email"
                        autoComplete="email"
                        label={<FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_FORM.FIELD_EMAIL.LABEL" />}
                    />
                </div>

                <div className="tf-form-row">
                    <FormFieldPassword
                        name="password"
                        required
                        id="password"
                        autoComplete="current-password"
                        label={<FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_FORM.FIELD_PASSWORD.LABEL" />}
                        errors={{
                            minLength: (
                                <FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_FORM.FIELD_PASSWORD.ERROR_MIN_LENGTH" />
                            )
                        }}
                    />
                </div>

                <div className="tf-form-actions text-center">
                    <LoaderButton
                        type="submit"
                        loading={submitting}
                        typeStyle="raised"
                        variationStyle="brand"
                        className="EmailAndPasswordLoginForm__button"
                    >
                        <FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_FORM.BUTTON_SUBMIT" />
                    </LoaderButton>
                </div>

                <p className="EmailAndPasswordLoginForm__notes">
                    {resetPasswordLink}{' '}
                    <FormattedMessage
                        id="EMAIL_AND_PASSWORD_LOGIN_FORM.TEXT_REQUEST_TOKEN_LOGIN"
                        values={{ requestTokenLoginLink }}
                    />
                </p>

                <p className="EmailAndPasswordLoginForm__notes">
                    <FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_FORM.TEXT_CREATE_ACCOUNT" />
                    <br />
                    {signupLink}
                </p>
            </form>
        );
    }

    renderMessages() {
        const verificationStatusMessage = this.renderVerificationStatusMessage();
        const errorMessage = this.renderErrorMessage();

        if (!verificationStatusMessage && !errorMessage) {
            return null;
        }

        return (
            <div>
                {verificationStatusMessage}
                {errorMessage}
            </div>
        );
    }

    renderVerificationStatusMessage() {
        const { verificationSuccessful } = this.props;

        if (typeof verificationSuccessful !== 'boolean') {
            return null;
        }

        const contactLink = (
            <a href={`mailto:${companyContact.email}`} target="_blank" rel="noopener noreferrer">
                {companyContact.email}
            </a>
        );

        const verificationStatus = verificationSuccessful ? 'success' : 'failure';
        const verificationStatusMessage = (
            <FormattedMessage
                id={`EMAIL_AND_PASSWORD_LOGIN_FORM.INFO_VERIFICATION_${verificationStatus}`}
                values={{
                    contactLink
                }}
            />
        );

        return <div className="alert alert-info">{verificationStatusMessage}</div>;
    }

    renderErrorMessage() {
        const { error } = this.props;

        if (!error) {
            return null;
        }

        if (error === FORM_ERROR_EMAIL_NOT_CONFIRMED) {
            const { submittedEmail } = this.state;

            const resendVerificationLinkTo = {
                pathname: '/resend-verification',
                search: stringifySearch({
                    email: submittedEmail
                })
            };

            return (
                <FormError
                    messageId="EMAIL_AND_PASSWORD_LOGIN_FORM.ERROR_email-not-confirmed"
                    messageValues={{
                        resendVerificationLink: (
                            <Link to={resendVerificationLinkTo}>
                                <FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_FORM.LINK_RESEND_VERIFICATION" />
                            </Link>
                        )
                    }}
                />
            );
        }

        return <FormError messageId={`EMAIL_AND_PASSWORD_LOGIN_FORM.ERROR_${error}`} />;
    }
}

const withForm = reduxForm<FormValues, ConnectProps>({
    form: FORM_NAME,
    enableReinitialize: true
});

const getFieldValue = formValueSelector(FORM_NAME);

const mapStateToProps = (state) => ({
    email: getFieldValue(state, 'email')
});

const withStore = connect<ConnectedStateProps, ConnectedDispatchProps, ConnectorProps>(mapStateToProps);

export default withStore(withForm(EmailAndPasswordLoginForm));
