import * as Sentry from '@sentry/react';
import { PreloadedState, StoreEnhancer, applyMiddleware, createStore, compose as defaultCompose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { createLogger } from '../utils/logger';
import { omit } from '../utils/object';

import { createCheckoutProcessStatesPersistorMiddleware } from '../modules/checkout/middlewares';
import createApplicationReducer from './reducer';

const logger = createLogger('store');

let compose: typeof defaultCompose = defaultCompose;
if (
    process.env.NODE_ENV !== 'production' &&
    typeof window !== 'undefined' &&
    !!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) {
    compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}

const sagaMiddleware = createSagaMiddleware({
    onError: (error) => {
        Sentry.captureException(error);
        logger.debug(error);
    }
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer(action) {
        if (typeof action.type === 'string' && action.type.startsWith('@@')) {
            return null;
        }

        return action;
    },
    stateTransformer(state) {
        const transformedState = omit(state, 'form');

        return transformedState;
    }
});

export function setupStore(preloadState?: () => PreloadedState<ReturnType<typeof createApplicationReducer>>) {
    const middlewares = [
        ...(typeof window !== 'undefined' ? [createCheckoutProcessStatesPersistorMiddleware()] : []),
        sagaMiddleware
    ];

    const enhancer: StoreEnhancer = compose(applyMiddleware(...middlewares), sentryReduxEnhancer);

    const preloadedState = preloadState ? preloadState() : {};

    const store = createStore(createApplicationReducer(), preloadedState, enhancer);

    // Enable Webpack hot module replacement for reducers
    if ((module as any).hot) {
        (module as any).hot.accept('./reducer', () => {
            const nextReducer = require('./reducer').default;
            store.replaceReducer(nextReducer());
        });
    }

    return {
        store,
        runSaga: sagaMiddleware.run
    };
}
