import type { FormatXMLElementFn, Options as IntlMessageFormatOptions } from 'intl-messageformat';
import { ReactNode } from 'react';
import { IntlShape, MessageDescriptor, PrimitiveType } from 'react-intl';

type FormatterValues = Record<string, PrimitiveType | ReactNode | FormatXMLElementFn<ReactNode>>;

export function createIntlEnhancer(commonValues: FormatterValues | undefined) {
    return (intl: IntlShape): IntlShape => {
        const enhancedFormatMessage = ((
            messageDescriptor: MessageDescriptor,
            values?: FormatterValues,
            opts?: IntlMessageFormatOptions
        ) => {
            return intl.formatMessage(
                messageDescriptor,
                {
                    ...values,
                    ...commonValues
                },
                opts
            );
        }) as IntlShape['formatMessage'];

        return {
            ...intl,
            formatMessage: enhancedFormatMessage
        };
    };
}
