import { AxiosResponse } from 'axios';

import HTTPResponseError from './HTTPResponseError';

export function convertToResponseError(error: Error & { response: AxiosResponse<any> }) {
    const response = error.response;

    const data = response.data;
    const status = response.status;
    const headers = response.headers;
    const config = response.config;

    const newError = new HTTPResponseError(data, status, headers, config);
    // Copy stack
    newError.stack = error.stack;

    return newError;
}
