import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatToMonthInYear } from '../../../utils/date';

import CandidateResumeSection from '../CandidateResumeSection';
import CandidateResumeItem from '../CandidateResumeItem';

import { Applicant } from '../../../modules/candidates/types';
import { deprefixHttp, prefixHttp } from '../../../utils/url';

type Props = {
    applicant: Applicant;
};

const ResumeWorks = ({ applicant }: Props) => {
    const { resume } = applicant;
    const works = resume.work;

    if (works.length === 0) {
        return null;
    }

    return (
        <CandidateResumeSection>
            <h1>
                <FormattedMessage id="RESUME_WORKS.TITLE" />
            </h1>

            <ul>
                {works.map((work, index) => (
                    <ResumeWorksListItem key={index} work={work} />
                ))}
            </ul>
        </CandidateResumeSection>
    );
};

const ResumeWorksListItem = ({ work }) => {
    return (
        <CandidateResumeItem
            label={
                <span>
                    {!!work.start_date ? (
                        <time dateTime={work.start_date}>
                            <span>{formatToMonthInYear(work.start_date)}</span>
                        </time>
                    ) : (
                        '?'
                    )}
                    &ndash;
                    {!!work.end_date ? (
                        <time dateTime={work.end_date}>
                            <span>{formatToMonthInYear(work.end_date)}</span>
                        </time>
                    ) : (
                        <time>
                            <FormattedMessage id="RESUME_WORKS.DATE.VALUE_NOW" />
                        </time>
                    )}
                </span>
            }
        >
            <div>
                <span>
                    <FormattedMessage
                        id="RESUME_WORKS.POSITION_COMPANY.VALUE"
                        values={{
                            position: work.position,
                            company: work.company
                        }}
                    />
                </span>
            </div>

            {!!work.website && (
                <div>
                    <i>
                        <a href={prefixHttp(work.website)} target="_blank" rel="noopener">
                            {deprefixHttp(work.website)}
                        </a>
                    </i>
                </div>
            )}

            {!!work.summary && (
                <div>
                    <span>{work.summary}</span>
                </div>
            )}
        </CandidateResumeItem>
    );
};

export default ResumeWorks;
