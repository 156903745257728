import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Recruiter } from '../../../../modules/recruiters/types';
import { hasBackgroundImage, hasCompanyLogo, hasGalleryImage } from '../../../../modules/recruiters/utils';

import './JobFormCallToActions.scss';

export type JobFormCallToActionsProps = {
    recruiter: Recruiter;
    onClickCallToAction: () => void;
};

function JobFormCallToActions({ recruiter, onClickCallToAction }: JobFormCallToActionsProps) {
    const shouldShowCompanyLogoCallToAction = !hasCompanyLogo(recruiter);
    const shouldShowBackgroundImageCallToAction = !hasBackgroundImage(recruiter);
    const shouldShowGalleryImageCallToAction = !hasGalleryImage(recruiter);
    const shouldShowCallToActions =
        shouldShowCompanyLogoCallToAction ||
        shouldShowBackgroundImageCallToAction ||
        shouldShowGalleryImageCallToAction;

    if (!shouldShowCallToActions) {
        return null;
    }

    return (
        <div className="JobFormCallToActions">
            {shouldShowCompanyLogoCallToAction && (
                <button
                    type="button"
                    className="JobFormCallToActions__call-to-action btn btn-default"
                    onClick={onClickCallToAction}
                >
                    <span className="JobFormCallToActions__call-to-action-title">
                        <FormattedMessage id="JOB_EDIT.CALL_TO_ACTIONS.SET_LOGO_IMAGE.TITLE" />
                    </span>

                    <small>
                        <FormattedMessage id="JOB_EDIT.CALL_TO_ACTIONS.SET_LOGO_IMAGE.SUBTITLE" />
                    </small>
                </button>
            )}
            {shouldShowBackgroundImageCallToAction && (
                <button
                    type="button"
                    className="JobFormCallToActions__call-to-action btn btn-default"
                    onClick={onClickCallToAction}
                >
                    <span className="JobFormCallToActions__call-to-action-title">
                        <FormattedMessage id="JOB_EDIT.CALL_TO_ACTIONS.SET_BACKGROUND_IMAGE.TITLE" />
                    </span>

                    <small>
                        <FormattedMessage id="JOB_EDIT.CALL_TO_ACTIONS.SET_BACKGROUND_IMAGE.SUBTITLE" />
                    </small>
                </button>
            )}
            {shouldShowGalleryImageCallToAction && (
                <button
                    type="button"
                    className="JobFormCallToActions__call-to-action btn btn-default"
                    onClick={onClickCallToAction}
                >
                    <span className="JobFormCallToActions__call-to-action-title">
                        <FormattedMessage id="JOB_EDIT.CALL_TO_ACTIONS.ADD_GALLERY_IMAGE.TITLE" />
                    </span>

                    <small>
                        <FormattedMessage id="JOB_EDIT.CALL_TO_ACTIONS.ADD_GALLERY_IMAGE.SUBTITLE" />
                    </small>
                </button>
            )}
        </div>
    );
}

export default JobFormCallToActions;
