import { combineReducers } from 'redux';

import signupReducer, { State as SignupState } from './signup';
import emailAndPasswordAuthenticationReducer, {
    State as EmailAndPasswordAuthenticationState
} from './emailAndPasswordAuthentication';
import requestTokenAuthenticationReducer, {
    State as RequestTokenAuthenticationState
} from './requestTokenAuthentication';
import tempTokenAuthenticationReducer, { State as TempTokenAuthenticationState } from './tempTokenAuthentication';
import lastAuthenticationCheckReducer, { State as LastAuthenticationCheckState } from './lastAuthenticationCheck';
import isAuthenticatedReducer, { State as IsAuthenticatedState } from './isAuthenticated';
import isAuthenticatingReducer, { State as IsAuthenticatingState } from './isAuthenticating';
import resetPasswordReducer, { State as ResetPasswordState } from './resetPassword';
import requestTokenRequestReducer, { State as RequestTokenRequestState } from './requestTokenRequest';
import verificationEmailResendReducer, { State as VerificationEmailResendState } from './verificationEmailResend';
import changePasswordReducer, { State as ChangePasswordState } from './changePassword';

/**
 * Complete type of the state of the module
 */
export type AuthenticationState = {
    signup: SignupState;
    emailAndPasswordAuthentication: EmailAndPasswordAuthenticationState;
    requestTokenAuthentication: RequestTokenAuthenticationState;
    tempTokenAuthentication: TempTokenAuthenticationState;
    lastAuthenticationCheck: LastAuthenticationCheckState;
    isAuthenticated: IsAuthenticatedState;
    isAuthenticating: IsAuthenticatingState;
    resetPassword: ResetPasswordState;
    requestTokenRequest: RequestTokenRequestState;
    verificationEmailResend: VerificationEmailResendState;
    changePassword: ChangePasswordState;
};

export type State = {
    authentication: AuthenticationState;
};

// We export the sub states for easier usage
export type { State as SignupState } from './signup';
export type { State as EmailAndPasswordAuthenticationState } from './emailAndPasswordAuthentication';
export type { State as RequestTokenAuthenticationState } from './requestTokenAuthentication';
export type { State as TempTokenAuthenticationState } from './tempTokenAuthentication';
export type { State as LastAuthenticationCheckState } from './lastAuthenticationCheck';
export type { State as IsAuthenticatedState } from './isAuthenticated';
export type { State as IsAuthenticatingState } from './isAuthenticating';
export type { State as ResetPasswordState } from './resetPassword';
export type { State as RequestTokenRequestState } from './requestTokenRequest';
export type { State as VerificationEmailResendState } from './verificationEmailResend';

export const MOUNT: 'authentication' = 'authentication';

const rootReducer = combineReducers<AuthenticationState>({
    signup: signupReducer,
    emailAndPasswordAuthentication: emailAndPasswordAuthenticationReducer,
    requestTokenAuthentication: requestTokenAuthenticationReducer,
    tempTokenAuthentication: tempTokenAuthenticationReducer,
    lastAuthenticationCheck: lastAuthenticationCheckReducer,
    isAuthenticated: isAuthenticatedReducer,
    isAuthenticating: isAuthenticatingReducer,
    resetPassword: resetPasswordReducer,
    requestTokenRequest: requestTokenRequestReducer,
    verificationEmailResend: verificationEmailResendReducer,
    changePassword: changePasswordReducer
});

export default rootReducer;
