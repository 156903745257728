import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Options, Value } from '../constants';

type Props = {
    name: string;
    title: React.ReactNode;

    options: Options;

    id?: string;
    value?: Value;
    onChange?: (value: unknown) => void;
};

type Handlers = {
    handleChange: {
        _default: (event: React.ChangeEvent) => void;
        [ordering: string]: (event: React.ChangeEvent) => void;
    };
};

class OrderingSection extends React.Component<Props> {
    handlers: Handlers;

    constructor(props: Props) {
        super(props);

        this.handlers = {
            handleChange: {
                _default: this.handleChange.bind(this, '_default')
            }
        };
    }

    componentDidMount() {
        this.updateHandlers(this.props.options);
    }

    componentDidUpdate() {
        this.updateHandlers(this.props.options);
    }

    updateHandlers(options: Options) {
        this.handlers.handleChange = options.reduce((handlers, option) => {
            const ordering = option.value;

            // We don't want to recreate existing handlers
            if (!!handlers[ordering]) {
                return handlers;
            }

            return {
                ...handlers,
                [ordering]: this.handleChange.bind(this, ordering)
            };
        }, this.handlers.handleChange);
    }

    handleChange(ordering: '_default' | Value) {
        const value = this.props.value;
        const onChange = this.props.onChange;

        if (typeof value === 'undefined' || typeof onChange === 'undefined') {
            return;
        }

        if (ordering === '_default') {
            return onChange(null);
        }

        onChange(ordering);
    }

    render() {
        const { title } = this.props;

        return (
            <div className="FilterAndOrderingPageMobile__section">
                <div className="FilterAndOrderingPageMobile__section-title">{title}</div>

                {this.renderOptions()}
            </div>
        );
    }

    renderOptions() {
        const { id = '', options, value = null } = this.props;
        const { handleChange } = this.handlers;

        const optionsElements = options.map((option) => {
            const ordering = option.value;

            const checked = value === ordering;

            return (
                <span key={`option-${ordering}`} className="tf-radio">
                    <input
                        type="radio"
                        id={`${id}_option_${ordering}`}
                        checked={checked}
                        onChange={handleChange[ordering]}
                    />

                    <label
                        className="tf-radio__label FilterAndOrderingPageMobile__option"
                        htmlFor={`${id}_option_${ordering}`}
                        role="option"
                    >
                        <span className="tf-radio__faux FilterAndOrderingPageMobile__option-control" />
                        <span className="tf-radio__label-text FilterAndOrderingPageMobile__option-label">
                            {option.label}
                        </span>
                    </label>
                </span>
            );
        });

        return [
            <span key="default" className="tf-radio">
                <input
                    type="radio"
                    id={`${id}_option__default`}
                    checked={value === null}
                    onChange={handleChange._default}
                />

                <label
                    className="tf-radio__label FilterAndOrderingPageMobile__option"
                    htmlFor={`${id}_option__default`}
                    role="option"
                >
                    <span className="tf-radio__faux FilterAndOrderingPageMobile__option-control" />
                    <span className="tf-radio__label-text FilterAndOrderingPageMobile__option-label">
                        <FormattedMessage id="FILTER_AND_ORDERING_PAGE_MOBILE.ORDERING_DEFAULT" />
                    </span>
                </label>
            </span>,
            ...optionsElements
        ];
    }
}

export default OrderingSection;
