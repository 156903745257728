import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import LoadingWheel from '../../components/LoadingWheel';
import SessionExpiredPage from '../../components/SessionExpiredPage';

import { getTempTokenAuthenticationErrors } from '../../modules/authentication/selectors';
import { loginWithTempToken } from '../../modules/authentication/actions';

import './style.scss';

import { ResponseError } from '../../services/api/types';

type PossibleRouterParams = {
    tempToken: string;
};

type ConnectorProps = RouteComponentProps<PossibleRouterParams>;

type ConnectedStateProps = {
    tempToken: string;
    errors: ResponseError[];
};

type ConnectedDispatchProps = {
    loginWithTempToken: typeof loginWithTempToken;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

class TempTokenLoginPage extends React.Component<Props> {
    componentDidMount() {
        const { tempToken, loginWithTempToken } = this.props;

        loginWithTempToken(tempToken);
    }

    render() {
        const { errors } = this.props;

        let content: React.ReactNode = null;
        if (!errors.length) {
            content = (
                <div className="TempTokenLoginPage__loading-wheel">
                    <LoadingWheel />
                </div>
            );
        } else {
            content = <SessionExpiredPage />;
        }

        return <div className="TempTokenLoginPage">{content}</div>;
    }
}

const mapStateToProps = (state, props: ConnectorProps): ConnectedStateProps => {
    return {
        tempToken: props.match.params.tempToken,
        errors: getTempTokenAuthenticationErrors(state)
    };
};

const mapDispatchToProps: ConnectedDispatchProps = {
    loginWithTempToken
};

export default connect(mapStateToProps, mapDispatchToProps)(TempTokenLoginPage);
