import { isSupportedLocale } from '../locale';
import { PreferredLanguageStorageData } from './specification';

export function serialize(data: PreferredLanguageStorageData): string | null {
    return encodeURIComponent(data);
}

export function deserialize(input: string): PreferredLanguageStorageData | null {
    const rawData = decodeURIComponent(input);

    if (!isSupportedLocale(rawData)) {
        return null;
    }

    return rawData;
}
