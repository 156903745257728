import { createContext, ComponentType } from 'react';

import {
    JobFormLocationRegionDisplayFieldContainer,
    JobFormLocationRegionDisplayFieldContainerProps
} from '../JobFormLocationRegionDisplayField';
import {
    JobFormLocationRegionWarningFieldContainer,
    JobFormLocationRegionWarningFieldContainerProps
} from '../JobFormLocationRegionWarningField';

export type JobFormLocationFormFieldsetContextValue = {
    JobFormLocationRegionDisplayFieldContainer: ComponentType<JobFormLocationRegionDisplayFieldContainerProps>;
    JobFormLocationRegionWarningFieldContainer: ComponentType<JobFormLocationRegionWarningFieldContainerProps>;
};

export const defaultJobFormLocationFormFieldsetContextValue: JobFormLocationFormFieldsetContextValue = {
    JobFormLocationRegionDisplayFieldContainer: JobFormLocationRegionDisplayFieldContainer,
    JobFormLocationRegionWarningFieldContainer: JobFormLocationRegionWarningFieldContainer
};

/**
 * This context is actually only made for easier testing and story creation. It allows us to replace the connected
 * container components with something else to make it possible to render a specific state without the need to provide
 * a application store.
 */
const JobFormLocationFormFieldsetContext = createContext<JobFormLocationFormFieldsetContextValue>(
    defaultJobFormLocationFormFieldsetContextValue
);

export default JobFormLocationFormFieldsetContext;
