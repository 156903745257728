import { AxiosError, AxiosResponse } from 'axios';
import { ErrorResponseData, ApiError, ApiResponseError } from './types';
import { FetchStatus } from '../../modules/types';

export function toCommaSeparated(value: any) {
    return [].concat(value).join(',');
}

export function toBooleanString(value: any) {
    return !!value ? 'True' : 'False';
}

export function isFetchStatusLoading(fetchStatus: FetchStatus): boolean {
    return fetchStatus === 'none' || fetchStatus === 'loading';
}

/**
 * @deprecated Use `isApiResponseError` instead.
 */
export function isResponseError(error: unknown): error is Error & { response: AxiosResponse<ErrorResponseData> } {
    return error instanceof Error && 'response' in error;
}

// TODO: Rename to `isResponseError` as soon as current implementation of `isResponseError` isn't used anymore.
export function isPlainResponseError(error: unknown): error is AxiosError<unknown> {
    return error instanceof Error && 'response' in error;
}

export function isApiResponseError(error: unknown): error is ApiResponseError {
    return (
        isPlainResponseError(error) &&
        !!error.response &&
        'data' in error.response &&
        typeof error.response.data === 'object' &&
        !!error.response.data &&
        'errors' in error.response.data
    );
}

export function getErrorsWithCode(errors: ApiError[], code: string): ApiError[] {
    return errors.filter((error) => error.code === code);
}

export function getErrorWithCode(errors: ApiError[], code: string): ApiError | null {
    return getErrorsWithCode(errors, code)[0] ?? null;
}

export function hasErrorWithCode(errors: ApiError[], code: string): boolean {
    return getErrorsWithCode(errors, code).length > 0;
}

export function getErrorWithStatus(errors: ApiError[], status: number): ApiError | null {
    return errors.filter((error) => error.status === status).pop() || null;
}

export function getErrorWithTitle(errors: ApiError[], title: string): ApiError | null {
    return errors.filter((error) => error.title === title).pop() || null;
}
export function hasErrorWithStatus(errors: ApiError[], status: number) {
    return !!getErrorWithStatus(errors, status);
}

export function hasErrorWithTitle(errors: ApiError[], title: string) {
    return !!getErrorWithTitle(errors, title);
}
