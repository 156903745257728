import * as React from 'react';
import classNames from 'classnames';

import './MessageFormToolbar.style.scss';

type Props = {
    className?: string;
    buttons: Array<React.ReactElement<any>>;
    children?: React.ReactNode;
};

const MessageFormToolbar = ({ className, buttons, children }: Props) => {
    const styledButtons = buttons.map((button, index) =>
        React.cloneElement(button, {
            key: index,
            className: classNames(button.props.className, 'MessageFormToolbar__button')
        })
    );

    return (
        <div className={classNames('MessageFormToolbar', className)}>
            <div data-testid="toolbar-buttons" className="MessageFormToolbar__buttons">
                {styledButtons}
            </div>
            <div className="MessageFormToolbar__content">{children}</div>
        </div>
    );
};

export default MessageFormToolbar;
