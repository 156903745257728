import { SET_LAST_AUTHENTICATION_CHECK, AUTHENTICATION } from '../actions';

// Types
import { Action } from '../../types';

export type State = number | null;

// Reducer
function lastAuthenticationCheck(state: State = null, action: Action): State {
    switch (action.type) {
        case SET_LAST_AUTHENTICATION_CHECK:
        case AUTHENTICATION.SUCCESS:
        case AUTHENTICATION.FAILURE:
            return action.payload.lastCheck;

        default:
            return state;
    }
}

export default lastAuthenticationCheck;
