import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Package } from '../../../modules/packages/types';
import PackageType from '../../PackageType/PackageType';

import './SelectablePackageListItem.style.scss';

type Props = {
    package: Package;

    selected: boolean;
    onSelect: (arg1: number) => void;
};

function SelectablePackageListItem(props: Props) {
    const { package: pkg, selected, onSelect } = props;

    const className = classNames('SelectablePackageListItem', {
        'SelectablePackageListItem--selected': selected
    });

    return (
        <label className={className}>
            <span className="SelectablePackageListItem__radio tf-radio">
                <input
                    type="radio"
                    name="package"
                    value={pkg.id}
                    checked={selected}
                    onChange={() => onSelect(pkg.id)}
                />

                <span className="SelectablePackageListItem__radio-label tf-radio__label">
                    <span className="tf-radio__faux"></span>
                </span>
            </span>

            <span className="SelectablePackageListItem__text">
                <FormattedMessage
                    id="SELECTABLE_PACKAGE_LIST_ITEM.LABEL"
                    values={{
                        current: pkg.remaining_contingent,
                        total: pkg.total_contingent
                    }}
                />
            </span>

            <span className="SelectablePackageListItem__indicator">
                <span className="SelectablePackageListItem__indicator-inner">
                    <PackageType pkg={pkg} />
                </span>
            </span>
        </label>
    );
}

export default SelectablePackageListItem;
