import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormField, Input } from '@truffls/design-system-react';
import { getContactRoute, makeWithPrefix } from '../../../../routes';
import { useField } from '../../../../utils/form/react-final-form';
import { isRequired } from '../../../../utils/form/validators';
import { useJobFormPageHasError } from '../../JobFormPage.hooks';
import { onJobFormPageValidationLevel } from '../../JobFormPage.utils';
import { FieldValidator } from 'final-form';
import JobFormFieldAnchor from '../../JobFormFieldAnchor';

export type JobFormTitleFormFieldValue = string;
export type JobFormTitleFormFieldName = 'title';
export const JOB_EDIT_TITLE_FORM_FIELD_NAME: JobFormTitleFormFieldName = 'title';

const validate = onJobFormPageValidationLevel<JobFormTitleFormFieldValue>(
    'full',
    isRequired as FieldValidator<JobFormTitleFormFieldValue>
);

export type JobFormTitleFormFieldProps = {
    isTitleEditable: boolean;
};

function JobFormTitleFormField({ isTitleEditable }: JobFormTitleFormFieldProps) {
    const contactUrl = makeWithPrefix(getContactRoute());

    const { input, meta } = useField<JobFormTitleFormFieldValue, HTMLInputElement>(JOB_EDIT_TITLE_FORM_FIELD_NAME, {
        validate
    });
    const hasError = useJobFormPageHasError();

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_TITLE_FORM_FIELD_NAME} />
            <FormField
                id="job-form-field-input-title"
                labelText={<FormattedMessage id="JOB_EDIT.TITLE_FORM_FIELD.LABEL_TEXT" />}
                helpText={
                    <FormattedMessage
                        id="JOB_EDIT.TITLE_FORM_FIELD.HELP_TEXT"
                        values={{
                            contactLink: (chunks) => (
                                <a href={contactUrl} target="_blank">
                                    {chunks}
                                </a>
                            )
                        }}
                    />
                }
                hasError={hasError(input.value, meta)}
            >
                <Input {...input} disabled={!isTitleEditable} />
            </FormField>
        </React.Fragment>
    );
}

export default JobFormTitleFormField;
