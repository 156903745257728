import React from 'react';
import { FormattedMessage } from 'react-intl';

import './SearchBarEmptyState.style.scss';

function SearchBarEmptyState() {
    return (
        <div className="SearchBarEmptyState">
            <FormattedMessage id="SEARCH_BAR_EMPTY_STATE.TEXT" />
        </div>
    );
}

export default SearchBarEmptyState;
