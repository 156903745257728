const ELEMENT_TO_PATCH = [Element.prototype, CharacterData.prototype, DocumentType.prototype];

ELEMENT_TO_PATCH.forEach((item) => {
    if (item.hasOwnProperty('remove')) {
        return;
    }
    Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
            this.parentNode.removeChild(this);
        }
    });
});
