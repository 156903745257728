export default {
    native: true,
    from: {
        scale: 0,
        opacity: 0
    },
    enter: {
        scale: 1,
        opacity: 1
    },
    leave: {
        scale: 0,
        opacity: 0,
        delay: 650
    },
    config: {
        tension: 381.47,
        friction: 43.17
    }
};
