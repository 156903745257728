import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Locale, appLocales } from '../../i18n';

import MobilePageContainer from '../MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../NavigationBar';

import './style.scss';

export type OverlayLanguageMenuProps = {
    open: boolean;
    onClose: () => void;
    onChangeLanguage: (langCode: Locale) => void;
};

function OverlayLanguageMenu({ open, onClose, onChangeLanguage }: OverlayLanguageMenuProps) {
    return (
        <MobilePageContainer show={open} transitionName="scale">
            <NavigationBar
                centerElement={
                    <NavigationBarText>
                        <FormattedMessage id="NAVIGATION.ACTIONS.LANGUAGE_MENU.TITLE" />
                    </NavigationBarText>
                }
                rightElement={<NavigationBarCloseAction onClick={onClose} />}
            />

            <div className="OverlayLanguageMenu__nav">
                {appLocales.map((locale) => {
                    return (
                        <button
                            key={locale}
                            className="OverlayLanguageMenu__nav-button"
                            value={locale}
                            onClick={onChangeLanguage.bind(null, locale)}
                        >
                            <FormattedMessage id={`NAVIGATION.ACTIONS.LANGUAGE_MENU.LANGUAGE.${locale}`} />
                        </button>
                    );
                })}
            </div>
        </MobilePageContainer>
    );
}

export default OverlayLanguageMenu;
