import { createSelector } from 'reselect';

import { MOUNT, initialCompanyGalleryImagesCreationState, initialCompanyGalleryImagesDeletionState } from './reducer';

import { RecruiterState as State, State as ApplicationState } from './reducer';
import { GalleryImage, GalleryImageId } from './types';

function getRoot(state: ApplicationState): State {
    return state[MOUNT];
}

export const getEntity = createSelector(getRoot, (state) => {
    return state.entity;
});
export const getRecruiter = getEntity;

export const getUpdateStatus = createSelector(getRoot, (state) => {
    return state.updateStatus;
});

export const getPortraitUpdateStatus = createSelector(getRoot, (state) => {
    return state.portraitUpdateStatus;
});

export const getPortraitUpdateErrors = createSelector(getRoot, (state) => {
    return state.portraitUpdateErrors;
});

export const getCompanyLogoUpdateStatus = createSelector(getRoot, (state) => {
    return state.companyLogoUpdateStatus;
});

export const getCompanyLogoUpdateErrors = createSelector(getRoot, (state) => {
    return state.companyLogoUpdateErrors;
});

export const getCompanyBackgroundUpdateStatus = createSelector(getRoot, (state) => {
    return state.companyBackgroundUpdateStatus;
});

export const getCompanyBackgroundUpdateErrors = createSelector(getRoot, (state) => {
    return state.companyBackgroundUpdateErrors;
});

const getCompanyGalleryImagesCreations = createSelector(getRoot, (state) => {
    return state.companyGalleryImagesCreations;
});

function getCompanyGalleryImageCreation(state, localCreateId: string) {
    return getCompanyGalleryImagesCreations(state)[localCreateId] || initialCompanyGalleryImagesCreationState;
}

function getCompanyGalleryImageIdByLocalCreateId(state, localCreateId: string) {
    return getCompanyGalleryImageCreation(state, localCreateId).id;
}

export function getCompanyGalleryImageByLocalCreateId(state, localCreateId: string): GalleryImage | null {
    const id = getCompanyGalleryImageIdByLocalCreateId(state, localCreateId);

    if (id === null) {
        return null;
    }

    const recruiter = getRecruiter(state);

    if (recruiter === null) {
        return null;
    }

    const galleryImage = recruiter.gallery_images.find((galleryImage) => {
        return galleryImage.id === id;
    });

    if (galleryImage === undefined) {
        return null;
    }

    return galleryImage;
}

export function getCompanyGalleryImageCreateStatus(state, localCreateId: string) {
    return getCompanyGalleryImageCreation(state, localCreateId).status;
}

export function getCompanyGalleryImageCreateErrors(state, localCreateId: string) {
    return getCompanyGalleryImageCreation(state, localCreateId).errors;
}

const getCompanyGalleryImagesDeletions = createSelector(getRoot, (state) => {
    return state.companyGalleryImagesDeletions;
});

function getCompanyGalleryImageDeletion(state, id: GalleryImageId) {
    const idAsString = String(id);
    return getCompanyGalleryImagesDeletions(state)[idAsString] || initialCompanyGalleryImagesDeletionState;
}

export function getCompanyGalleryImageDeleteStatus(state, id: GalleryImageId) {
    return getCompanyGalleryImageDeletion(state, id).status;
}

export function getCompanyGalleryImageDeleteErrors(state, id: GalleryImageId) {
    return getCompanyGalleryImageDeletion(state, id).errors;
}
