import { STEPS } from './constants';

import { Step } from './constants';

export function getStepIndex(currentStep: Step): number {
    return STEPS.indexOf(currentStep);
}

export function getStepNumber(currentStep: Step): number {
    return getStepIndex(currentStep) + 1;
}

export function getPreviousStep(currentStep: Step): Step {
    const currentStepIndex = getStepIndex(currentStep);
    const previousStepIndex = Math.max(0, currentStepIndex - 1);

    return STEPS[previousStepIndex];
}

export function getNextStep(currentStep: Step): Step {
    const currentStepIndex = getStepIndex(currentStep);
    const nextStepIndex = Math.min(STEPS.length - 1, currentStepIndex + 1);

    return STEPS[nextStepIndex];
}
