import { omitNullValues } from '../../utils/object';
import {
    convertParamsToQueryParams as convertListParamsToListQueryParams,
    transformQueryParams
} from '../../utils/params';
import { Ordering } from '../../components/JobListHeader/constants';
import { LIST_NAMES, ListName, Params, QueryParams, ListParams, ListQueryParams } from './constants';

type UnnormalizedQueryParams = {
    [K in ListName]?: {
        [P in keyof ListQueryParams]?: any;
    };
};
/*
 * Normalize the properties in list query params.
 */
export function normalizeQueryParams(unnormalizedQueryParams: UnnormalizedQueryParams): QueryParams {
    return LIST_NAMES.reduce((queryParams, listName: ListName) => {
        return {
            ...queryParams,
            [listName]: transformQueryParams(unnormalizedQueryParams[listName] as object)
        };
    }, {} as QueryParams);
}

/**
 * Merge the query parameters with the default params.
 */
export function convertQueryParamsToParams(defaultParams: Params, queryParams: QueryParams): Params {
    return LIST_NAMES.reduce((params, listName) => {
        const listParams = {
            ...defaultParams[listName],
            ...queryParams[listName]
        } as ListParams;

        return {
            ...params,
            [listName]: listParams
        };
    }, {} as Params);
}

/**
 * Create the query params based on the diff of the default parameters and the parameters.
 */
export function convertParamsToQueryParams(defaultParams: Params, params: Params): QueryParams {
    return LIST_NAMES.reduce((queryParams, listName) => {
        return {
            ...queryParams,
            [listName]: convertListParamsToListQueryParams(
                defaultParams[listName] as ListParams,
                params[listName] as ListParams
            )
        };
    }, {} as QueryParams);
}

/**
 * We translate ordering fields from to the field names which are used by the api
 */
export function convertParamsToActionParams(params: ListParams): Object {
    let ordering = params.ordering;

    if (!!ordering) {
        let orderingField: Ordering = ordering;
        let orderingDirection = 'asc';

        if (orderingField.substr(0, 1) === '-') {
            orderingField = orderingField.substr(1) as Ordering;
            orderingDirection = 'desc';
        }

        if (orderingField === 'candidates') {
            ordering = ((orderingDirection === 'desc' ? '-' : '') + 'candidate') as Ordering;
        } else if (orderingField === 'messages') {
            ordering = ((orderingDirection === 'desc' ? '-' : '') + 'messages_unread_by_recruiter') as Ordering;
        }
    }

    const newParams = Object.keys(params).reduce((newParams, key) => {
        let value = params[key];

        if (value instanceof Array) {
            value = !!value.length ? value : null;
        }

        return {
            ...newParams,
            [key]: value
        };
    }, {});

    return omitNullValues({
        ...newParams,
        ordering
    });
}
