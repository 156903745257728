import { combineReducers } from 'redux';

import { TEMP_TOKEN_AUTHENTICATION } from '../actions';
import { ResponseError } from '../../../services/api/types';

// Types
import { Action } from '../../types';

export type ErrorsState = ResponseError[];

export type State = {
    errors: ErrorsState;
};

export function errorsReducer(state: ErrorsState = [], action: Action): ErrorsState {
    switch (action.type) {
        case TEMP_TOKEN_AUTHENTICATION.REQUEST:
            return [];

        case TEMP_TOKEN_AUTHENTICATION.FAILURE:
            return action.payload.errors || [];

        default:
            return state;
    }
}

const rootReducer = combineReducers({
    errors: errorsReducer
});

export default rootReducer;
