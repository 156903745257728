export const LEAFLET_URL_TEMPLATE =
    'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}{r}?access_token={accessToken}';

export const LEAFLET_ATTRIBUTION =
    'Map data &copy; <a href="http://openstreetmap.org" target="_blank" rel="noopener">OpenStreetMap</a> contributors, <a href="http://creativecommons.org/licenses/by-sa/2.0/" target="_blank" rel="noopener">CC-BY-SA</a>, Imagery © <a href="http://mapbox.com" target="_blank" rel="noopener">Mapbox</a>';

export const DEFAULT_LATITUDE_CENTER = 51.163375;
export const DEFAULT_LONGITUDE_CENTER = 10.447683;
export const DEFAULT_ZOOM = 4;
export const MAX_ZOOM = 18;
