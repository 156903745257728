import * as React from 'react';
import { connect } from 'react-redux';

import { RequestStatus } from '../../modules/types';
import { requestRequestToken, resetRequestTokenRequest } from '../../modules/authentication/actions';
import { getRequestTokenRequestStatus } from '../../modules/authentication/selectors';
import {
    isRequestTokenRequestRequesting,
    isRequestTokenRequestSucceed,
    isRequestTokenRequestFailure
} from '../../modules/authentication/utils';

import { RequestTokenRequestFormPage, RequestTokenRequestFormValues } from './RequestTokenRequestFormPage';
import { RequestTokenRequestSuccessPage } from './RequestTokenRequestSuccessPage';

export type RequestTokenRequestPageProps = {};

type ConnectedDispatchProps = {
    requestRequestToken: typeof requestRequestToken;
    resetRequestTokenRequest: typeof resetRequestTokenRequest;
};
type ConnectedStateProps = {
    requestStatus: RequestStatus;
};

type RequestTokenRequestPageAllProps = RequestTokenRequestPageProps & ConnectedDispatchProps & ConnectedStateProps;

function RequestTokenRequestPage({
    requestStatus,
    requestRequestToken,
    resetRequestTokenRequest
}: RequestTokenRequestPageAllProps) {
    const [email, setEmail] = React.useState('');

    React.useEffect(() => {
        resetRequestTokenRequest();
    }, []);

    const onSubmitForm = (formValues: RequestTokenRequestFormValues) => {
        setEmail(formValues.email);

        requestRequestToken(formValues.email);
    };

    const isRequesting = isRequestTokenRequestRequesting(requestStatus);
    const isSuccess = isRequestTokenRequestSucceed(requestStatus);
    const isFailure = isRequestTokenRequestFailure(requestStatus);

    if (!isSuccess) {
        return (
            <RequestTokenRequestFormPage
                email={email}
                submitting={isRequesting}
                submitFailed={isFailure}
                onSubmit={onSubmitForm}
            />
        );
    }

    return <RequestTokenRequestSuccessPage email={email} />;
}

const mapStateToProps = (state) => ({
    requestStatus: getRequestTokenRequestStatus(state)
});

const mapDispatchToProps = {
    requestRequestToken,
    resetRequestTokenRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestTokenRequestPage);
