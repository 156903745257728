import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Candidate, CandidateState } from '../../modules/candidates/types';
import BottomActionBar from '../BottomActionBar';
import Button from '../Button';
import CandidateDetails from '../CandidateDetails';
import MobilePageContainer from '../MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../NavigationBar';
import scrolled, { ScrolledProps } from '../Scrolled';
import './CandidateStateChangeViewMobile.style.scss';

type Props = ScrolledProps & {
    open: boolean;
    candidate: Candidate;
    onClose: () => void;
    onChangeState: (state: CandidateState) => void;
};

type State = {
    selectedState: CandidateState;
};

class CandidateStateChangeViewMobile extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedState: props.candidate.state
        };

        this.handleStateSelect = this.handleStateSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleStateSelect(event: React.ChangeEvent<HTMLInputElement>) {
        const stateName = (event.target.value as any) as CandidateState;

        this.setState({ selectedState: stateName });
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        this.props.onChangeState(this.state.selectedState);
    }

    render() {
        const { candidate, open, onClose } = this.props;

        return (
            <MobilePageContainer show={open} transitionName="fadeIn">
                <NavigationBar
                    noShadow={!this.props.scrolled}
                    centerElement={
                        <NavigationBarText>
                            <FormattedMessage id="CANDIDATE_STATE_CHANGE.MOBILE.TITLE" />
                        </NavigationBarText>
                    }
                    rightElement={<NavigationBarCloseAction onClick={onClose} />}
                />

                <form className="CandidateStateChangeViewMobile__form" onSubmit={this.handleSubmit}>
                    <div className="CandidateStateChangeViewMobile__content" onScroll={this.props.onScroll}>
                        <div className="CandidateStateChangeViewMobile__candidate">
                            <CandidateDetails candidate={candidate} showNewBadge showUnreadMessageBadge />
                        </div>
                        {this.renderStates()}
                    </div>
                    <BottomActionBar>
                        <Button type="submit" typeStyle="raised" variationStyle="brand">
                            <FormattedMessage id="CANDIDATE_STATE_CHANGE.MOBILE.ACTION.APPLY" />
                        </Button>
                    </BottomActionBar>
                </form>
            </MobilePageContainer>
        );
    }

    renderStates() {
        const { candidate } = this.props;
        let states: CandidateState[] = ['interesting', 'phone-interview', 'interview', 'hired'];

        if (!candidate) {
            return null;
        }

        if (!!candidate && candidate.state === 'hired') {
            states = ['hired'];
        }

        return states.map((stateName: CandidateState, index: number) => {
            return (
                <span className="CandidateStateChangeViewMobile__state-container" key={index}>
                    <input
                        type="radio"
                        onChange={this.handleStateSelect}
                        value={stateName}
                        className="CandidateStateChangeViewMobile__state-radio"
                        name="state"
                        id={`CandidateStateChangeViewMobile__state-radio--${stateName}`}
                        checked={this.state.selectedState === stateName}
                    />

                    <label
                        htmlFor={`CandidateStateChangeViewMobile__state-radio--${stateName}`}
                        className={classNames(
                            'CandidateStateChangeViewMobile__state',
                            `CandidateStateChangeViewMobile__state--${stateName}`,
                            { 'CandidateStateChangeViewMobile__state--current': candidate.state === stateName }
                        )}
                    >
                        <span className="CandidateStateChangeViewMobile__state-indicator"></span>
                        <span className="CandidateStateChangeViewMobile__state-name">
                            <FormattedMessage id={`CONSTANT.CANDIDATE_STATE.${stateName}`} />
                        </span>
                    </label>
                </span>
            );
        });
    }
}

export default scrolled(CandidateStateChangeViewMobile);
