import { PreloadedState } from 'redux';
import { ApplicationState } from './reducer';
import { I18N_MOUNT } from '../modules/i18n/reducer';
import { preloadI18NState } from '../modules/i18n/preload';
import { VERSION_MOUNT } from '../modules/version/reducer';
import { preloadVersionState } from '../modules/version/preload';

export function preloadApplicationState(): PreloadedState<ApplicationState> {
    return {
        [I18N_MOUNT]: preloadI18NState(),
        [VERSION_MOUNT]: preloadVersionState()
    } as PreloadedState<ApplicationState>;
}
