import * as React from 'react';
import escapeStringRegexp from 'escape-string-regexp';

export function match(text: string, pattern: string): Array<string> | null {
    const escapedPattern = escapeStringRegexp(pattern);
    const patternRegexp = new RegExp(escapedPattern, 'gi');

    return text.match(patternRegexp);
}

type MatcherFn = (text: string, pattern: string) => Array<string> | null;

export function filterByMatcher(matcher: MatcherFn, items: string[], pattern: string): string[] {
    return items.filter((item) => {
        return !!matcher(item, pattern);
    });
}

export const highlightPattern = (text: string, pattern: string) => {
    const escapedPattern = escapeStringRegexp(pattern);
    const searchRegex = new RegExp(escapedPattern, 'gi');
    const splitText = text.split(searchRegex);

    if (splitText.length <= 1) {
        return text;
    }

    const matches = text.match(searchRegex);

    if (!matches) {
        return [];
    }

    return splitText.reduce((arr: React.ReactNode[], element: string, index: number) => {
        if (!!matches[index]) {
            return [...arr, element];
        }

        return [...arr, <b>{matches[index]}</b>];
    }, []);
};
