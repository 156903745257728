import * as React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector as createFormValueSelector, InjectedFormProps } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';

import { targetCountries } from '../../constants';
import { GENDERS } from '../../modules/genders';

import FormFieldCheckbox from '../../components/FormFieldCheckbox';
import FormFieldInput from '../../components/FormFieldInput';
import FormFieldTextarea from '../../components/FormFieldTextarea';
import FormFieldSelect from '../../components/FormFieldSelect';
import FormFieldEmail from '../../components/FormFieldEmail';
import FormFieldPhoneNumber from '../../components/FormFieldPhoneNumber';

import { FORM_NAME, NO_VAT_ID_NEEDED_COUNTRY, FormValues } from './constants';

import { Intl } from '../../types/intl';

import './style.scss';

type ConnectorProps = {
    fieldsetPersonalInformationVisible: boolean;

    children: React.ReactNode;
};

type ConnectedStateProps = {
    countryOfInvoice: string;
    hasDifferingBillingAddress: boolean;
};

type ConnectProps = ConnectorProps & ConnectedStateProps;

type PropsWithForm = ConnectProps & InjectedFormProps<FormValues, ConnectProps>;

type PropsWithIntl = PropsWithForm & {
    intl: Intl;
};

type Props = PropsWithIntl;

class CheckoutAddressForm extends React.Component<Props> {
    render() {
        const {
            handleSubmit,

            children
        } = this.props;

        return (
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        {this.renderFieldsetPersonalInformation()}
                        {this.renderFieldsetAddress()}
                        {this.renderFieldsetBillingAddress()}
                    </div>
                </div>

                {children}
            </form>
        );
    }

    renderFieldsetPersonalInformation() {
        const { fieldsetPersonalInformationVisible } = this.props;

        if (!fieldsetPersonalInformationVisible) {
            return null;
        }

        const genderOptions = this.createGenderOptions();

        return (
            <fieldset className="CheckoutAddressForm__fieldset">
                <legend>
                    <FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELDSET.PERSONAL_INFORMATION.LABEL" />
                </legend>

                <div className="CheckoutAddressForm__form-row row">
                    <div className="CheckoutAddressForm__form-column col-md-2">
                        <FormFieldSelect
                            id="personal-information-gender"
                            name="gender"
                            required
                            options={genderOptions}
                            label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.SALUTATION.LABEL" />}
                        />
                    </div>

                    <div className="CheckoutAddressForm__form-column col-md-5">
                        <FormFieldInput
                            id="personal-information-first-name"
                            name="first_name"
                            type="text"
                            required
                            label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.FIRST_NAME.LABEL" />}
                        />
                    </div>

                    <div className="CheckoutAddressForm__form-column col-md-5">
                        <FormFieldInput
                            id="personal-information-last-name"
                            name="last_name"
                            type="text"
                            required
                            label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.LAST_NAME.LABEL" />}
                        />
                    </div>
                </div>

                <FormFieldInput
                    id="personal-information-position"
                    name="position"
                    type="text"
                    required
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.POSITION.LABEL" />}
                />
            </fieldset>
        );
    }

    renderFieldsetAddress() {
        const { countryOfInvoice } = this.props;

        const countryOptions = this.createCountryOptions();

        return (
            <fieldset className="CheckoutAddressForm__fieldset">
                <legend>
                    <FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELDSET.ADDRESS.LABEL" />
                </legend>

                <FormFieldInput
                    id="address-company"
                    name="company"
                    type="text"
                    required
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.COMPANY.LABEL" />}
                />
                <FormFieldPhoneNumber
                    id="address-phone"
                    name="phone"
                    required
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.PHONE.LABEL" />}
                    helpText={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.PHONE.HELP_TEXT" />}
                />
                <FormFieldInput
                    id="address-street"
                    name="address.street"
                    type="text"
                    required
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.ADDRESS_STREET.LABEL" />}
                />

                <div className="CheckoutAddressForm__form-row row">
                    <div className="CheckoutAddressForm__form-column col-sm-4">
                        <FormFieldInput
                            id="address-zip-code"
                            name="address.zip_code"
                            type="text"
                            required
                            label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.ADDRESS_ZIP_CODE.LABEL" />}
                        />
                    </div>
                    <div className="CheckoutAddressForm__form-column col-sm-8">
                        <FormFieldInput
                            id="address-zip-city"
                            name="address.city"
                            type="text"
                            required
                            label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.ADDRESS_CITY.LABEL" />}
                        />
                    </div>
                </div>

                <FormFieldSelect
                    id="address-country"
                    name="address.country"
                    options={countryOptions}
                    required
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.ADDRESS_COUNTRY.LABEL" />}
                />
                <FormFieldEmail
                    id="address-invoice-email-address"
                    name="invoice_email_address"
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.INVOICE_EMAIL_ADDRESS.LABEL" />}
                />
                <FormFieldTextarea
                    id="address-custom-invoice-text"
                    name="custom_invoice_text"
                    minRows={3}
                    maxRows={3}
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.CUSTOM_INVOICE_TEXT.LABEL" />}
                />
                <FormFieldInput
                    id="address-ust-id"
                    name="ust_id"
                    type="text"
                    required={countryOfInvoice !== NO_VAT_ID_NEEDED_COUNTRY}
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.UST_ID.LABEL" />}
                    helpText={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.UST_ID.HELP_TEXT" />}
                />
                <FormFieldCheckbox
                    id="address-different-billing-address"
                    name="differing_billing_address"
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.DIFFERING_BILLING_ADDRESS.LABEL" />}
                />
            </fieldset>
        );
    }

    renderFieldsetBillingAddress() {
        const { hasDifferingBillingAddress } = this.props;

        if (!hasDifferingBillingAddress) {
            return null;
        }

        const countryOptions = this.createCountryOptions();

        return (
            <fieldset className="CheckoutAddressForm__fieldset">
                <legend>
                    <FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELDSET.BILLING_ADDRESS.LABEL" />
                </legend>

                <FormFieldInput
                    id="billing-address-company"
                    name="billing_address.company"
                    type="text"
                    required
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.COMPANY.LABEL" />}
                />

                <div className="CheckoutAddressForm__form-row row">
                    <div className="CheckoutAddressForm__form-column col-md-6">
                        <FormFieldInput
                            id="billing-address-first-name"
                            name="billing_address.first_name"
                            type="text"
                            required
                            label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.FIRST_NAME.LABEL" />}
                        />
                    </div>

                    <div className="CheckoutAddressForm__form-column col-md-6">
                        <FormFieldInput
                            id="billing-address-last-name"
                            name="billing_address.last_name"
                            type="text"
                            required
                            label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.LAST_NAME.LABEL" />}
                        />
                    </div>
                </div>

                <FormFieldInput
                    id="billing-address-street"
                    name="billing_address.street"
                    type="text"
                    required
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.ADDRESS_STREET.LABEL" />}
                />

                <div className="CheckoutAddressForm__form-row row">
                    <div className="CheckoutAddressForm__form-column col-sm-4">
                        <FormFieldInput
                            id="billing-address-zip-code"
                            name="billing_address.zip_code"
                            type="text"
                            required
                            label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.ADDRESS_ZIP_CODE.LABEL" />}
                        />
                    </div>
                    <div className="CheckoutAddressForm__form-column col-sm-8">
                        <FormFieldInput
                            id="billing-address-city"
                            name="billing_address.city"
                            type="text"
                            required
                            label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.ADDRESS_CITY.LABEL" />}
                        />
                    </div>
                </div>

                <FormFieldSelect
                    id="billing-address-country"
                    name="billing_address.country"
                    options={countryOptions}
                    required
                    label={<FormattedMessage id="CHECKOUT_ADDRESS_FORM.FIELD.ADDRESS_COUNTRY.LABEL" />}
                />
            </fieldset>
        );
    }

    createGenderOptions() {
        const { intl } = this.props;

        return GENDERS.map((genderCode: string) => {
            return {
                value: genderCode,
                label: intl.formatMessage({
                    id: `CONSTANT.SALUTATION.${genderCode}`
                })
            };
        });
    }

    createCountryOptions() {
        const { intl } = this.props;

        return targetCountries.map((countryCode: string) => {
            return {
                value: countryCode,
                label: intl.formatMessage({
                    id: `CONSTANT.COUNTRY.${countryCode}`
                })
            };
        });
    }
}

const getFormValue = createFormValueSelector(FORM_NAME);

function mapStateToProps(state) {
    const countryOfAddress = getFormValue(state, 'address.country');
    const countryOfBillingAddress = getFormValue(state, 'billing_address.country');

    const hasDifferingBillingAddress = getFormValue(state, 'differing_billing_address') || false;

    const countryOfInvoice = hasDifferingBillingAddress ? countryOfBillingAddress : countryOfAddress;

    return {
        countryOfInvoice,
        hasDifferingBillingAddress
    };
}

const withStore = connect<ConnectedStateProps, {}, ConnectorProps>(mapStateToProps);

const withForm = reduxForm<FormValues, ConnectProps>({
    form: FORM_NAME,
    enableReinitialize: true
});

export default withStore(withForm(injectIntl(CheckoutAddressForm)));
