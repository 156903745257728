import * as React from 'react';
import FlipMove from 'react-flip-move';

import ListLoadingIndicator from '../ListLoadingIndicator';
import JobListItem from '../JobListItem';

import './style.scss';

import { Job } from '../../modules/jobs/types';

type Props =
    | {
          skeleton: true;

          isLoading?: boolean;

          items: null[];
      }
    | {
          skeleton?: false | void;

          isLoading?: boolean;

          items: Job[];
          onClickCheckoutStart: (job: Job) => void;
      };

const JobList = (props: Props): React.ReactElement => {
    let listItems: React.ReactNode[] = [];

    if (props.skeleton === true) {
        listItems = props.items.map((_, index: number) => (
            <li key={`index-${index}`} className="JobList__item">
                <JobListItem skeleton />
            </li>
        ));
    } else {
        const onClickCheckoutStart = props.onClickCheckoutStart;

        listItems = props.items.map((job: Job) => {
            /*
             * This is a non perfomant way - a better way would be to generate the handler one time and save them in a
             * look up by key and reuse them during a render execution
             */
            const handleClickCheckoutStart = () => onClickCheckoutStart(job);

            return (
                <li key={`job-${job.id}`} className="JobList__item">
                    <JobListItem skeleton={false} job={job} onClickCheckoutStart={handleClickCheckoutStart} />
                </li>
            );
        });
    }

    return (
        <div className="JobList">
            <FlipMove
                typeName="ul"
                className="JobList__list"
                duration={500}
                easing="ease-out"
                appearAnimation="fade"
                enterAnimation="fade"
                leaveAnimation="fade"
            >
                {listItems}
            </FlipMove>

            {props.isLoading && <ListLoadingIndicator />}
        </div>
    );
};

export default JobList;
