import * as React from 'react';
import { useHistory } from 'react-router-dom';
import messengerIcon from '../../assets/images/ic-messenger-filled.svg';
import { Candidate } from '../../modules/candidates/types';
import { Job } from '../../modules/jobs/types';
import { getJobsRoute } from '../../routes';
import { goBackOrFallback } from '../../utils/history';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../NavigationBar';
import './CandidateConversationNavigationBar.style.scss';

export type CandidateConversationNavigationBarProps = {
    candidate: Candidate | null;
    job: Job | null;
};

const CandidateConversationNavigationBar = ({ candidate, job }: CandidateConversationNavigationBarProps) => {
    const history = useHistory();

    const onClickClose = () => {
        goBackOrFallback(history.push, getJobsRoute());
    };

    let title: React.ReactNode = null;
    if (!!candidate && !!job) {
        title = (
            <NavigationBarText className="CandidateConversationNavigationBar__title">
                <h3 className="CandidateConversationNavigationBar__title__candidate-name">
                    {candidate.applicant.full_name}
                </h3>
                <span className="CandidateConversationNavigationBar__title__job-title">{job.title}</span>
            </NavigationBarText>
        );
    }

    return (
        <NavigationBar
            leftElement={<img src={messengerIcon} alt="Two stacked bubbles" />}
            centerElement={title}
            rightElement={<NavigationBarCloseAction onClick={onClickClose} />}
        />
    );
};

export default CandidateConversationNavigationBar;
