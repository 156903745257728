import {
    LOGIN_REQUEST_TOKEN_PATH,
    RequestTokenLoginRouteParams,
    RequestTokenLoginRouteQuery,
    RequestTokenLoginRouteRawQuery,
    parseRequestTokenLoginRouteParams,
    parseRequestTokenLoginRouteQuery
} from '../authentication';
import {
    CHECKOUT_ADDRESS_PATH_PATTERN,
    CHECKOUT_FINISH_PATH_PATTERN,
    CHECKOUT_PATH_PATTERN,
    CHECKOUT_PRODUCT_PATH_PATTERN,
    CHECKOUT_STEP_PATH_PATTERN
} from '../checkout';
import { PATH_PATTERNS } from '../index';
import { findTrackableRouteWithExcludes } from '../routesAnalytics';
import { RouteRegistrySpecification, createRouteRegistrySpecification, createRoutesRegistry } from './registry';

export const routeRegistrySpecifications: readonly RouteRegistrySpecification[] = [
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.LOGIN
    }),
    createRouteRegistrySpecification<
        RequestTokenLoginRouteParams,
        RequestTokenLoginRouteQuery,
        RequestTokenLoginRouteRawQuery
    >({
        pattern: LOGIN_REQUEST_TOKEN_PATH,
        parseParams: parseRequestTokenLoginRouteParams,
        parseQuery: parseRequestTokenLoginRouteQuery,
        analytics: {
            queryInProps: {
                destinationUrl(value, routesRegistry) {
                    if (!value) {
                        return value;
                    }

                    return findTrackableRouteWithExcludes(routesRegistry, value);
                }
            }
        }
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.LOGIN_TEMP_TOKEN
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.SIGNUP
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.PASSWORD_RESET
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.PASSWORD_RESET_CONFIRM
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.REQUEST_TOKEN
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.AUTOMATIC_REQUEST_TOKEN
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.VERIFY_EMAIL
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.VERIFY_EMAIL_REQUEST
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.UNSUBSCRIBE_TEMP_RECRUITER
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.JOBS
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.JOB
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.JOB_EDIT
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.JOB_CREATE
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.JOB_NEW
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.JOB_CREATE_PARSE
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.JOB_CREATE_COPY
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.CANDIDATES
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.CANDIDATES_NEW_MESSAGES
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.CANDIDATES_NOT_INTERESTING
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.CANDIDATE
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.CANDIDATE_CONVERSATION
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.CANDIDATE_DOCUMENTS
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.PRICES
    }),
    createRouteRegistrySpecification({
        pattern: CHECKOUT_PATH_PATTERN
    }),
    createRouteRegistrySpecification({
        pattern: CHECKOUT_STEP_PATH_PATTERN
    }),
    createRouteRegistrySpecification({
        pattern: CHECKOUT_PRODUCT_PATH_PATTERN
    }),
    createRouteRegistrySpecification({
        pattern: CHECKOUT_ADDRESS_PATH_PATTERN
    }),
    createRouteRegistrySpecification({
        pattern: CHECKOUT_FINISH_PATH_PATTERN
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.CHECKOUT_SUCCESS
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.SETTINGS_PROFILE
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.SETTINGS_COMPANY_PROFILE
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.SETTINGS_ADDRESS
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.SETTINGS_ACCOUNT
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.SETTINGS_TEMPLATES
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.SETTINGS_TEMPLATES_CREATE
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.SETTINGS_TEMPLATES_EDIT
    }),
    createRouteRegistrySpecification({
        pattern: PATH_PATTERNS.CONTACT
    })
];

export const routesRegistry = createRoutesRegistry(routeRegistrySpecifications);
