import { combineReducers } from 'redux';

import { VERIFICATION_EMAIL_RESEND } from '../actions';

// Types
import { Action, RequestStatus } from '../../types';

export type State = {
    status: RequestStatus;
};

export function statusReducer(state: RequestStatus = 'none', action: Action): RequestStatus {
    switch (action.type) {
        case VERIFICATION_EMAIL_RESEND.REQUEST:
            return 'requesting';

        case VERIFICATION_EMAIL_RESEND.SUCCESS:
            return 'requested';

        case VERIFICATION_EMAIL_RESEND.FAILURE:
            return 'failed';

        default:
            return state;
    }
}

const rootReducer = combineReducers({
    status: statusReducer
});

export default rootReducer;
