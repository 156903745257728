import React from 'react';
import { connect } from 'react-redux';

import { ApplicantId, Candidate, CandidateId } from '../../modules/candidates/types';
import {
    getOtherCandidatesThatMatchApplicant,
    getOtherCandidatesThatMatchApplicantFetchStatus
} from '../../modules/candidates/selectors';

import { State as ApplicationState } from '../../store/reducer';
import { BREAKPOINT_MD } from '../../utils/viewport';

import useBreakpointMatch from '../../hooks/useBreakpointMatch';

import CandidateAlsoAppliedForFullscreen from './CandidateAlsoAppliedForFullscreen';
import CandidateAlsoAppliedForModal from './CandidateAlsoAppliedForModal';
import { FETCH_STATUS } from '../../modules/constants';
import CandidatesJobList from '../../components/CandidatesJobList';

type ConnectorProps = {
    open: boolean;
    onClose: () => void;
    candidateId: CandidateId;
    applicantId: ApplicantId;
};

type ConnectedStateProps = {
    isLoading: boolean;
    hasFailed: boolean;
    candidates: Candidate[];
};

type ConnectedDispatchProps = {};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

function CandidateAlsoAppliedForView({ open, onClose, isLoading, hasFailed, candidates }: Props) {
    const match = useBreakpointMatch(BREAKPOINT_MD);

    const content = <CandidatesJobList candidates={candidates} />;

    if (!match) {
        return (
            <CandidateAlsoAppliedForFullscreen
                open={open}
                onClose={onClose}
                isLoading={isLoading}
                hasFailed={hasFailed}
                candidates={candidates}
            >
                {content}
            </CandidateAlsoAppliedForFullscreen>
        );
    }

    return (
        <CandidateAlsoAppliedForModal
            open={open}
            onClose={onClose}
            isLoading={isLoading}
            hasFailed={hasFailed}
            candidates={candidates}
        >
            {content}
        </CandidateAlsoAppliedForModal>
    );
}

function mapStateToProps(state: ApplicationState, props: ConnectorProps): ConnectedStateProps {
    const { applicantId, candidateId } = props;

    const fetchStatus = getOtherCandidatesThatMatchApplicantFetchStatus(state, applicantId);

    const isLoading = fetchStatus === FETCH_STATUS.NONE || fetchStatus === FETCH_STATUS.LOADING;
    const hasFailed = fetchStatus === FETCH_STATUS.FAILED;

    const candidates = getOtherCandidatesThatMatchApplicant(state, applicantId, [candidateId]);

    return {
        isLoading,
        hasFailed,
        candidates
    };
}

export default connect(mapStateToProps)(CandidateAlsoAppliedForView);
