import * as posthog from './posthog';

export function initialize() {
    posthog.initialize();
}

export function setUserId(userId: number) {
    if (process.env.NODE_ENV !== 'production') {
        console.groupCollapsed('Identify user');
        console.log('ID: ' + userId);
        console.groupEnd();
    }

    posthog.identify(userId);
}

export function unsetUserId() {
    if (process.env.NODE_ENV !== 'production') {
        console.groupCollapsed('Unidentify user');
        console.groupEnd();
    }

    posthog.unidentify();
}

export function setProfileType(profileType: 'premium-recruiter' | 'temporary-recruiter') {
    if (process.env.NODE_ENV !== 'production') {
        console.groupCollapsed('Identify profile type');
        console.log('Profile type: ' + profileType);
        console.groupEnd();
    }

    posthog.setUserPropertiesOnce({
        profileType
    });
}

function trackEvent(category: string, action: string, label?: string, value?: number) {
    if (process.env.NODE_ENV !== 'production') {
        console.groupCollapsed('Track event: ' + category + ' - ' + action);
        console.log('Category: ' + category);
        console.log('Action: ' + action);
        if (typeof label !== 'undefined') {
            console.log('Label: ' + label);
        }
        if (typeof value !== 'undefined') {
            console.log('Value: ' + value);
        }
        console.groupEnd();
    }
}

export function trackPageview(pathname: string) {
    if (process.env.NODE_ENV !== 'production') {
        console.groupCollapsed('Track page view: ' + pathname);
        console.log('Pathname: ' + pathname);
        console.groupEnd();
    }

    posthog.trackPageview(pathname);
}

export function trackSignupStart(forTrial: boolean = false) {
    trackEvent('signup', 'start');
    trackEvent('signup', forTrial ? 'start-for-trial' : 'start-for-non-trial');
}

export function trackSignupStep(step: string) {
    trackEvent('signup', `step-${step}`);
}

export function trackSignupSuccess() {
    trackEvent('signup', 'success');
}
