import React from 'react';
import { connect } from 'react-redux';

import { State as ApplicationState } from '../../store/reducer';

import { Candidate } from '../../modules/candidates/types';
import { getCandidate } from '../../modules/entities/selectors';
import { updateCandidate } from '../../modules/candidates/actions';

import './CandidateRatingButtons.scss';

enum RatingNumber {
    One = 1,
    Two = 2,
    Three = 3
}

type ConnectorProps = {
    candidateId: number;
};

type ConnectedStateProps = {
    candidate: Candidate | null;
};

type ConnectedDispatchProps = {
    updateCandidate: typeof updateCandidate;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

export function CandidateRatingButtons({ candidate, updateCandidate }: Props) {
    const [selectedRange, setSelectedRange] = React.useState<RatingNumber | null>(null);

    React.useEffect(() => {
        if (!candidate) {
            return;
        }

        if (selectedRange !== candidate.rating_by_recruiter) {
            setSelectedRange(candidate.rating_by_recruiter);
        }
    }, [candidate]);

    const handleChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const rating: RatingNumber = parseInt(event.target.value) as any;

            if (!candidate) {
                return null;
            }

            setSelectedRange(rating);

            updateCandidate(candidate.id, { rating_by_recruiter: rating });
        },
        [candidate, setSelectedRange, updateCandidate]
    );

    return (
        <div className="CandidateRatingButtons">
            <div className="CandidateRatingButtons__item">
                <input
                    type="radio"
                    className="CandidateRatingItem__item-input sr-only"
                    id="CandidateRatingItem__a"
                    value={RatingNumber.Three}
                    checked={selectedRange === RatingNumber.Three}
                    onChange={handleChange}
                />

                <label className="CandidateRatingButtons__item-input-label" htmlFor="CandidateRatingItem__a">
                    A
                </label>
            </div>

            <div className="CandidateRatingButtons__item">
                <input
                    type="radio"
                    className="CandidateRatingItem__item-input sr-only"
                    id="CandidateRatingItem__b"
                    value={RatingNumber.Two}
                    checked={selectedRange === RatingNumber.Two}
                    onChange={handleChange}
                />

                <label className="CandidateRatingButtons__item-input-label" htmlFor="CandidateRatingItem__b">
                    B
                </label>
            </div>

            <div className="CandidateRatingButtons__item">
                <input
                    type="radio"
                    className="CandidateRatingItem__item-input sr-only"
                    id="CandidateRatingItem__c"
                    value={RatingNumber.One}
                    checked={selectedRange === RatingNumber.One}
                    onChange={handleChange}
                />

                <label className="CandidateRatingButtons__item-input-label" htmlFor="CandidateRatingItem__c">
                    C
                </label>
            </div>
        </div>
    );
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => ({
    candidate: getCandidate(state, props.candidateId)
});

const mapDispatchToProps = {
    updateCandidate
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateRatingButtons);
