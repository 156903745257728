import { createBrowserPreferredLanguageStorage } from '../preferredLanguageStorage/browser';

export const LOCALE_DE = 'de' as const;
export const LOCALE_EN = 'en' as const;

type Language = typeof LOCALE_DE | typeof LOCALE_EN;
export type Locale = Language & {};

/**
 * The list of locales the app supports. More specific locales should come before the generic ones.
 * @example <caption>Specific locales before generic ones.</caption>
 * const appLocales = [ 'en-US', 'en-GB', 'en', 'de-AT', 'de' ];
 */
export const appLocales = [LOCALE_EN, LOCALE_DE] as Locale[];
export const fallbackLocale: Locale = LOCALE_DE;

/*
 * We override the locale property of the Intl shape so that we only get typed values every time we use it via `useIntl`
 * or similar.
 */
declare module 'react-intl' {
    interface IntlShape {
        locale: Locale;
    }
}

export function detectLocale() {
    const preferredLanguageClientStorage = createBrowserPreferredLanguageStorage();
    const preferredLanguageFromClientStorage = preferredLanguageClientStorage.get();

    let preferredLanguagesFromNavigator: string[] = [];
    if (Array.isArray(navigator.languages)) {
        preferredLanguagesFromNavigator = [...navigator.languages];
    } else if (!!navigator.language) {
        preferredLanguagesFromNavigator = [navigator.language];
    } else if (typeof !!(navigator as any).userLanguage === 'string') {
        preferredLanguagesFromNavigator = [(navigator as any).userLanguage];
    }

    return [
        ...(!!preferredLanguageFromClientStorage ? [preferredLanguageFromClientStorage] : []),
        ...preferredLanguagesFromNavigator
    ];
}

/**
 * Takes a list of locales that should be used to determine the locale that should be used – if no locale was found it
 * uses the app's fallback locale.
 * @param locales the requested locales that will be matched to the available app locales
 * @returns an object that contains the resolved locale and a promise for the corresponding messages.
 */
export function resolveLocale(locales: string[], supportedLocales: readonly Locale[] = appLocales): Locale {
    let resolvedLocale = locales.find((locale): locale is Locale => isSupportedLocale(locale, supportedLocales));

    if (!resolvedLocale) {
        resolvedLocale = locales
            .map((locale) => locale.split(/[-_]/)[0])
            .find((language): language is Locale => isSupportedLocale(language, supportedLocales));
    }

    return resolvedLocale ?? fallbackLocale;
}

export function isSupportedLocale(locale: string, supportedLocales: readonly Locale[] = appLocales): locale is Locale {
    return supportedLocales.includes(locale as any);
}
