import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { isEditable } from '../../modules/jobs/utils';
import { getJobEditRoute } from '../../routes';

import classes from './CheckoutInfoBar.module.scss';

import { Job } from '../../modules/jobs/types';

export type CheckoutInfoBarProps = {
    advertisementAction: string;

    job: Job;
};

function CheckoutInfoBar(props: CheckoutInfoBarProps) {
    const { advertisementAction, job } = props;

    let jobEditLink;

    if (isEditable(job)) {
        jobEditLink = (
            <span className={classes.editLink}>
                (
                <Link to={getJobEditRoute(job.id)}>
                    <FormattedMessage id="CHECKOUT_INFO_BAR.LINK_EDIT" />
                </Link>
                )
            </span>
        );
    }

    return (
        <div className={classNames('navbar', 'navbar-default', classes.root)}>
            <div className={classNames('container', 'text-center', classes.content)}>
                <span className={classes.text}>
                    <FormattedMessage id={`CHECKOUT_INFO_BAR.TEXT.${advertisementAction}`} />
                </span>{' '}
                <strong className={classes.jobTitle} title={job.title}>
                    {job.title}
                </strong>
                {jobEditLink && ' '}
                {jobEditLink}
            </div>
        </div>
    );
}

export default CheckoutInfoBar;
