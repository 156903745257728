import apiClientV3 from '../../services/api/v3';

import { AgreementContract, Agreement } from './types';

export function acceptAgreementContract(contract: AgreementContract) {
    const agreement: Agreement = {
        contract: contract,
        accepted: true
    };

    return apiClientV3.post('/agreements/', agreement);
}
