import config from '../../config';

export function initialize() {
    const callbackName = '_googleMapsApiLoaded';

    return new Promise<void>((resolve, reject) => {
        let processed = false;

        const timeout = window.setTimeout(() => {
            if (!processed) {
                processed = true;
                reject();
            }
        }, 3000);

        window[callbackName] = () => {
            if (!processed) {
                processed = true;

                clearTimeout(timeout);
                resolve();
            }
        };

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsApiKey}&callback=${callbackName}&libraries=places&v=weekly`;
        script.async = true;
        document.body.appendChild(script);
    });
}

export async function ensureIntialization() {
    if (typeof window.google !== 'undefined' && typeof window.google.maps !== 'undefined') {
        return Promise.resolve();
    }

    await initialize();
}
