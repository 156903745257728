import * as React from 'react';
import classNames from 'classnames';

import './NavigationBar.style.scss';

type Props = {
    centerElement?: React.ReactNode;
    leftElement?: React.ReactNode;
    rightElement?: React.ReactNode;

    noShadow?: boolean;
};

const NavigationBar = (props: Props) => {
    const centerElement = <div className="NavigationBar__center-element">{props.centerElement}</div>;

    const leftElement = <div className="NavigationBar__left-element">{props.leftElement}</div>;

    const rightElement = <div className="NavigationBar__right-element">{props.rightElement}</div>;

    const className = classNames('NavigationBar', {
        'NavigationBar--shadow': !props.noShadow
    });

    return (
        <div className={className}>
            {leftElement}

            {centerElement}

            {rightElement}
        </div>
    );
};

export default NavigationBar;
