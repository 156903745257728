import * as React from 'react';
import classNames from 'classnames';
import deepEqual from 'deep-equal';

import './MessageList.style.scss';

import { enhanceMessages } from '../../modules/conversations/utils';
import { MESSAGE_TYPES } from '../../modules/conversations/constants';

import MessageListScroll from '../MessageListScroll';
import MessageItemGroupSeparator from '../MessageItemGroupSeparator/MessageItemGroupSeparator';
import MessageItemDate from '../MessageItemDate';
import MessageItemReadStatus from '../MessageItemReadStatus';
import MessageItemAttachment from '../MessageItemAttachment';
import MessageItemInfo from '../MessageItemInfo';
import MessageItemStatus from '../MessageItemStatus';
import MessageItemText from '../MessageItemText';

import { Message, EnhancedMessage, InternalMessage, ConversationParticipant } from '../../modules/conversations/types';

type Props = {
    messages: Array<Message>;
    participants: Array<ConversationParticipant>;
    currentUser: {
        id: number;
    };
    onClickDownloadAttachmentOfMessage?: (arg1: { message: Message | EnhancedMessage }) => void;
    useLegacyTheme?: boolean;
    children?: React.ReactElement | false | null | undefined;
};

type State = {
    enhancedMessages: Array<Message | EnhancedMessage | InternalMessage>;
};

class MessageList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            enhancedMessages: []
        };

        this.renderMessageItems = this.renderMessageItems.bind(this);
        this.handleClickDownloadAttachmentOfMessage = this.handleClickDownloadAttachmentOfMessage.bind(this);
    }

    componentDidMount() {
        this.updateEnhancedMessages();
    }

    componentDidUpdate(prevProps: Props) {
        const { messages, participants, currentUser } = this.props;

        if (
            (!!currentUser && !deepEqual(prevProps.currentUser, currentUser)) ||
            (!!participants.length && !deepEqual(prevProps.participants, participants)) ||
            (!!messages.length && !deepEqual(prevProps.messages, messages))
        ) {
            this.updateEnhancedMessages();
        }
    }

    handleClickDownloadAttachmentOfMessage(message: Message | EnhancedMessage) {
        const { onClickDownloadAttachmentOfMessage } = this.props;
        if (!!onClickDownloadAttachmentOfMessage) {
            onClickDownloadAttachmentOfMessage({ message });
        }
    }

    updateEnhancedMessages() {
        const { messages, participants, currentUser } = this.props;

        this.setState({
            enhancedMessages: enhanceMessages(messages, participants, currentUser.id)
        });
    }

    renderMessageItems() {
        const { useLegacyTheme } = this.props;
        const { enhancedMessages } = this.state;

        // TODO: `any` should be removed once we have reduced objects for text and attachement message types
        return enhancedMessages.map((message: any, index) => {
            switch (message.message_type) {
                case MESSAGE_TYPES.TEXT:
                    return (
                        <li key={index}>
                            <MessageItemText text={message.text} fromMe={message.fromMe} owner={message.owner} />
                        </li>
                    );

                case MESSAGE_TYPES.ATTACHMENT:
                    return (
                        <li key={index}>
                            <MessageItemAttachment
                                file={!!message.extra_data && message.extra_data.file}
                                fromMe={message.fromMe}
                                owner={message.owner}
                                onClickDownload={() => {
                                    this.handleClickDownloadAttachmentOfMessage(message);
                                }}
                            />
                        </li>
                    );

                case MESSAGE_TYPES.INFO:
                case MESSAGE_TYPES.DOCUMENT_REQUEST:
                    return (
                        <li key={index}>
                            <MessageItemInfo text={message.text} useLegacyTheme={useLegacyTheme} />
                        </li>
                    );

                case MESSAGE_TYPES.STATUS:
                    return (
                        <li key={index}>
                            <MessageItemStatus text={message.text} />
                        </li>
                    );

                case MESSAGE_TYPES.SEPARATOR:
                    return (
                        <li key={index}>
                            <MessageItemGroupSeparator size={message.size} />
                        </li>
                    );

                case MESSAGE_TYPES.DATE:
                    return (
                        <li key={index}>
                            <MessageItemDate date={message.date} />
                        </li>
                    );

                case MESSAGE_TYPES.READ_STATUS:
                    return (
                        <li key={index}>
                            <MessageItemReadStatus
                                sending={message.sending}
                                lastReadParticipants={message.lastReadParticipants}
                                lastUnreadParticipants={message.lastUnreadParticipants}
                            />
                        </li>
                    );
            }
        });
    }

    render() {
        const { children, useLegacyTheme } = this.props;

        return (
            <MessageListScroll messages={this.state.enhancedMessages} useLegacyTheme={useLegacyTheme}>
                <ul
                    className={classNames('MessageList', {
                        'MessageList--legacy-theme': useLegacyTheme
                    })}
                >
                    {this.renderMessageItems()}
                </ul>
                {children}
            </MessageListScroll>
        );
    }
}

export default MessageList;
