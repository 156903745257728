import React from 'react';
import { FieldValidator } from 'final-form';
import { FormField, Input } from '@truffls/design-system-react';
import { useField } from '../../../../utils/form/react-final-form';
import { isRequired } from '../../../../utils/form/validators';
import { onJobFormPageValidationLevel } from '../../../JobFormPage/JobFormPage.utils';
import { FormattedMessage } from 'react-intl';
import { getContactRoute, makeWithPrefix } from '../../../../routes';
import { useJobFormPageHasError } from '../../../JobFormPage/JobFormPage.hooks';
import JobFormFieldAnchor from '../../JobFormFieldAnchor';

export type JobFormCompanyFormFieldValue = string;
export type JobFormCompanyFormFieldName = 'company';
export const JOB_EDIT_COMPANY_FORM_FIELD_NAME: JobFormCompanyFormFieldName = 'company';

const validate = onJobFormPageValidationLevel('full', isRequired as FieldValidator<JobFormCompanyFormFieldValue>);

export type JobFormCompanyFormFieldProps = {
    isCompanyEditable: boolean;
};

function JobFormCompanyFormField({ isCompanyEditable }: JobFormCompanyFormFieldProps) {
    const contactUrl = makeWithPrefix(getContactRoute());

    const { input, meta } = useField<JobFormCompanyFormFieldValue, HTMLInputElement>(JOB_EDIT_COMPANY_FORM_FIELD_NAME, {
        validate
    });
    const hasError = useJobFormPageHasError();

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_COMPANY_FORM_FIELD_NAME} />
            <FormField
                id="job-form-field-input-company"
                labelText={<FormattedMessage id="JOB_EDIT.COMPANY_FORM_FIELD.LABEL_TEXT" />}
                helpText={
                    <FormattedMessage
                        id="JOB_EDIT.COMPANY_FORM_FIELD.HELP_TEXT"
                        values={{
                            contactLink: (chunks) => (
                                <a href={contactUrl} target="_blank">
                                    {chunks}
                                </a>
                            )
                        }}
                    />
                }
                hasError={hasError(input.value, meta)}
            >
                <Input {...input} disabled={!isCompanyEditable} />
            </FormField>
        </React.Fragment>
    );
}

export default JobFormCompanyFormField;
