import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import './style.scss';

const ListLoadingIndicator = () => {
    return (
        <div className="ListLoadingIndicator">
            <div className="ListLoadingIndicator__text">
                <FormattedMessage id="LIST_LOADING_INDICATOR.TEXT" />
            </div>
        </div>
    );
};

export default ListLoadingIndicator;
