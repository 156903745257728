import * as React from 'react';
import { Input } from '@truffls/design-system-react';

import { useField } from '../../../../../../utils/form/react-final-form';

import { useJobFormTasksEditMode } from '../../JobFormTasksEditModeContext';
import JobFormTasksListItemFormFieldDragHandle from './JobFormTasksListItemFormFieldDragHandle';
import JobFormTasksListItemFormFieldEditButton from './JobFormTasksListItemFormFieldEditButton';
import JobFormTasksListItemFormFieldSaveButton from './JobFormTasksListItemFormFieldSaveButton';
import JobFormTasksListItemFormFieldDeleteButton from './JobFormTasksListItemFormFieldDeleteButton';

import './JobFormTasksListItemFormField.scss';

export type JobFormTasksListItemFormFieldProps = {
    name: string;

    draggingDisabled?: boolean;

    onDelete: () => void;
};

const JobFormTasksListItemFormField = React.forwardRef<HTMLDivElement, JobFormTasksListItemFormFieldProps>(
    function JobFormTasksListItemFormField(
        { name, draggingDisabled = false, onDelete }: JobFormTasksListItemFormFieldProps,
        ref
    ) {
        const { input } = useField<string, HTMLInputElement>(name, {});
        const [editValue, setEditValue] = React.useState(input.value);

        const { fieldsInEditMode, enableEditMode, disableEditMode } = useJobFormTasksEditMode();
        const editMode = fieldsInEditMode.includes(name);

        React.useEffect(() => {
            return () => {
                disableEditMode(name);
            };
        }, []);

        const activateEditMode = () => {
            enableEditMode(name);

            setEditValue(input.value);
        };

        const deactivateEditMode = () => {
            if (editMode) {
                disableEditMode(name);
            }
        };

        const submit = () => {
            const cleanEditValue = editValue.trim();

            deactivateEditMode();

            if (cleanEditValue !== input.value) {
                input.onChange(cleanEditValue);
            }
        };

        const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setEditValue(event.target.value);
        };

        const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                submit();
            }
        };

        const onClickEdit = (event: React.MouseEvent) => {
            event.preventDefault();

            activateEditMode();
        };

        const onClickSave = (event: React.MouseEvent) => {
            event.preventDefault();

            submit();
        };

        const onClickDelete = (event: React.MouseEvent) => {
            event.preventDefault();

            deactivateEditMode();

            onDelete();
        };

        return (
            <div className="JobFormTasksListItemFormField" ref={ref} data-testid="job-tasks-test-item">
                <JobFormTasksListItemFormFieldDragHandle disabled={draggingDisabled} />
                <div className="JobFormTasksListItemFormField__content">
                    {!editMode && (
                        <div
                            className="JobFormTasksListItemFormField__display"
                            data-testid="job-tasks-list-item-display"
                        >
                            {input.value}
                        </div>
                    )}

                    {editMode && (
                        <Input
                            id={`task-${name}`}
                            name={name}
                            multiline
                            className="JobFormTasksListItemFormField__input"
                            value={editValue}
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                            data-testid="job-tasks-list-item-input"
                        />
                    )}
                </div>
                <div className="JobFormTasksListItemFormField__actions">
                    {!editMode && <JobFormTasksListItemFormFieldEditButton onClick={onClickEdit} />}
                    {editMode && <JobFormTasksListItemFormFieldSaveButton onClick={onClickSave} />}
                    <JobFormTasksListItemFormFieldDeleteButton onClick={onClickDelete} />
                </div>
            </div>
        );
    }
);

export default JobFormTasksListItemFormField;
