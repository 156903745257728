import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';

import './MessageForm.style.scss';

import { hasFileValidSize, getFileTypeFromMimeType } from '../../modules/files/utils';

import Button from '../Button';
import Tag from '../Tag';
import TagGroup from '../TagGroup';
import DocumentTypeIcon from '../DocumentTypeIcon';
import MessageFormToolbar from './MessageFormToolbar';

import MessageFormTextarea from './MessageFormTextarea';
import MessageFormTemplates from './MessageFormTemplates/MessageFormTemplates';

import { Template } from '../../modules/templates/types';
import { Intl } from '../../types/intl';

type TemplateId = Template['id'];

export type MessageFormProps = {
    message?: string;
    onChangeMessage: (message: string) => void;
    onSendMessage: () => void;

    attachments: Array<File>;
    onSelectAttachDocuments: (arg1: Array<File>) => void;
    removeAttachment: (arg1: number) => void;

    showRequestDocuments: boolean;
    onClickRequestDocumentsButton: () => void;
    isMobile: boolean;
    onClickTemplatesButton: () => void;

    templates: Template[];
    selectedTemplateId: TemplateId | null;
    onSelectTemplate: (arg1: TemplateId) => void;
    insertTemplate: (templateId: TemplateId | null) => void;

    useLegacyTheme?: boolean;

    intl: Intl;
};

export class MessageForm extends React.Component<MessageFormProps> {
    constructor(props: MessageFormProps) {
        super(props);

        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleKeyPress(event: React.KeyboardEvent) {
        if (event.ctrlKey && (event.keyCode === 10 || event.keyCode === 13)) {
            this.handleSubmit(event);
        }
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        this.props.onChangeMessage(event.target.value);
    }

    handleSubmit(event: React.SyntheticEvent) {
        event.preventDefault();

        this.props.onSendMessage();
    }

    render() {
        const {
            message,
            attachments,
            templates,
            selectedTemplateId,
            showRequestDocuments,
            isMobile,
            onClickRequestDocumentsButton,
            onClickTemplatesButton,
            removeAttachment,
            onSelectTemplate,
            insertTemplate,
            useLegacyTheme,
            intl
        } = this.props;

        const dirty = !!message;

        const className = classNames('MessageForm', {
            'MessageForm--dirty': dirty,
            'MessageForm--legacy-theme': useLegacyTheme
        });

        const messageFormTextareaId = 'MessageForm-textarea';

        const toolbarButtons: React.ReactElement[] = [];

        /*
                                   TODO: add back this button when recruiters attachments are supported in the whole product
                                    import FileUploadButton from '../FileUploadButton';
                                   const attachDocumentsButton = (
                                       <FileUploadButton
                                           multiple
                                           className="MessageForm__toolbar__buttons__attach-documents"
                                           onSelect={this.props.onSelectAttachDocuments}
                                           title={intl.formatMessage({
                                               id: 'MESSAGE_FORM.ATTACH_DOCUMENTS.TOOLTIP'
                                           })}
                                       />
                                   );
                                   toolbarButtons.push(attachDocumentsButton);
                               */

        if (showRequestDocuments) {
            const requestDocumentsButton = (
                <button
                    type="button"
                    className="MessageForm__toolbar__buttons__request-documents"
                    onClick={onClickRequestDocumentsButton}
                    title={intl.formatMessage({
                        id: 'MESSAGE_FORM.REQUEST_DOCUMENTS.TOOLTIP'
                    })}
                />
            );

            toolbarButtons.push(requestDocumentsButton);
        }

        if (isMobile) {
            const templatesButton = (
                <button
                    type="button"
                    className="MessageForm__toolbar__buttons__templates"
                    onClick={onClickTemplatesButton}
                    title={intl.formatMessage({
                        id: 'MESSAGE_FORM.TEMPLATE.TOOLTIP'
                    })}
                />
            );

            toolbarButtons.push(templatesButton);
        }

        let tags: React.ReactElement | null = null;
        if (attachments.length > 0) {
            tags = (
                <TagGroup>
                    {attachments.map((attachment, index) => (
                        <Tag
                            key={`${attachment.name}-${index}`}
                            icon={<DocumentTypeIcon type={getFileTypeFromMimeType(attachment.type)} />}
                            label={attachment.name}
                            onDelete={() => removeAttachment(index)}
                            hasError={!hasFileValidSize(attachment)}
                        />
                    ))}
                </TagGroup>
            );
        }

        return (
            <form onSubmit={this.handleSubmit} className={className}>
                <label className="sr-only" htmlFor={messageFormTextareaId}>
                    <FormattedMessage id="MESSAGE_FORM.LABEL" />
                </label>

                <div className="MessageForm__main">
                    <MessageFormTextarea
                        id={messageFormTextareaId}
                        className="MessageForm__main__input"
                        content={tags}
                        message={message ?? ''}
                        onKeyDown={this.handleKeyPress}
                        onChange={this.handleChange}
                        useLegacyTheme={useLegacyTheme}
                    />

                    <Button
                        className="MessageForm__main__submit"
                        type="submit"
                        title={intl.formatMessage({
                            id: 'MESSAGE_FORM.SUBMIT.TOOLTIP'
                        })}
                        typeStyle="raised"
                        variationStyle="brand"
                    >
                        <span />
                    </Button>
                </div>

                <MessageFormToolbar className="MessageForm__toolbar" buttons={toolbarButtons}>
                    {!isMobile && (
                        <MessageFormTemplates
                            templates={templates}
                            selectedTemplateId={selectedTemplateId}
                            onSelectTemplate={onSelectTemplate}
                            insertTemplate={insertTemplate}
                        />
                    )}
                </MessageFormToolbar>
            </form>
        );
    }
}

export default injectIntl(MessageForm);
