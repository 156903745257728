import { ActionNotification } from '../../modules/notifications/types';

export type ActionNotificationWithId = ActionNotification & {
    id: string;
};

const visibleStyle = {
    opacity: '1',
    transform: ''
};

export const APPEAR_AND_ENTER_ANIMATION = {
    from: { opacity: '0', transform: 'translateY(20px) rotateX(-70deg)' },
    to: visibleStyle
};

export const LEAVE_ANIMATION = {
    from: visibleStyle,
    to: { opacity: '0', transform: 'translateY(-20px) rotateX(70deg)' }
};
