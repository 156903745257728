import * as React from 'react';
import classNames from 'classnames';

import { CheckoutProductPageTabProps } from './CheckoutProductPageTab';
import { CheckoutProductPageTabContext } from './CheckoutProductPageTabContext';

import classes from './CheckoutProductPageTabs.module.scss';

export type CheckoutProductPageTabsProps<TabId extends string> = {
    children:
        | React.ReactElement<CheckoutProductPageTabProps<TabId>>
        | React.ReactElement<CheckoutProductPageTabProps<TabId>>[];

    activeTab: TabId;
    onClickTab: (id: TabId) => void;
};

function CheckoutProductPageTabs<TabId extends string>({
    activeTab,
    onClickTab,
    children
}: CheckoutProductPageTabsProps<TabId>) {
    return (
        <ul className={classes.root}>
            {React.Children.map(children, (child) => {
                const id = child.props.id;
                const key = child.key ?? id;

                return (
                    <CheckoutProductPageTabsItem key={key} id={id} activeTab={activeTab} onSelect={onClickTab}>
                        {child}
                    </CheckoutProductPageTabsItem>
                );
            })}
        </ul>
    );
}

export default CheckoutProductPageTabs;

type CheckoutProductPageTabsItemProps<TabId extends string> = {
    id: TabId;
    children: React.ReactElement<CheckoutProductPageTabProps<TabId>>;
    activeTab: TabId;
    onSelect: (id: TabId) => void;
};

function CheckoutProductPageTabsItem<TabId extends string>({
    id,
    children,
    activeTab,
    onSelect
}: CheckoutProductPageTabsItemProps<TabId>) {
    const active = activeTab === id;
    const handleSelect = () => {
        onSelect(id);
    };

    return (
        <CheckoutProductPageTabContext.Provider value={{ active, onSelect: handleSelect }}>
            <li
                className={classNames(classes.tab, {
                    [classes.tabActive]: active
                })}
            >
                {children}
            </li>
        </CheckoutProductPageTabContext.Provider>
    );
}
