import * as React from 'react';

import FormFieldInput from '../FormFieldInput';
import { toArray } from '../../utils/array';
import { isPhoneNumber } from '../../utils/form/validators';

type Props = Omit<React.ComponentProps<typeof FormFieldInput>, 'type' | 'id'> & {
    id: string;
};

const FormFieldPhoneNumber = ({ validate, ...rest }: Props): React.ReactElement => {
    const fullValidate = React.useMemo(() => {
        return [...toArray(validate || []), isPhoneNumber];
    }, [validate]);

    return <FormFieldInput type="tel" validate={fullValidate} {...rest} />;
};

export default FormFieldPhoneNumber;
