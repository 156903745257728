export const PARSING_POLL_INTERVAL = 2;

export const PARSING_TIMEOUT_LIMIT = 65;

export const JOB_PARSING_STATUS = {
    PARSING: 'parsing',
    PARSED: 'parsed',
    FAILED: 'failed',
    NONE: 'none',

    /**
     * @deprecated Use `JOB_PARSING_STATUS.FAILED` instead.
     */
    TIMEOUT: 'timeout'
} as const;
export type JobParsingStatus = typeof JOB_PARSING_STATUS[keyof typeof JOB_PARSING_STATUS];

export const JOB_PARSING_ERROR = {
    UNKNOWN: 'unknown',
    SERVER: 'server',
    TIMEOUT: 'timeout'
} as const;
export type JobParsingError = typeof JOB_PARSING_ERROR[keyof typeof JOB_PARSING_ERROR];
