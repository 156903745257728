import { getIn } from 'final-form';
import Big from 'big.js';

import {
    JOB_EDIT_HIRING_BUDGET_BUDGET_HIGHER_FORM_FIELD_NAME,
    JOB_EDIT_HIRING_BUDGET_BUDGET_LOWER_FORM_FIELD_NAME
} from './JobFormHiringBudgetFormFields.contstants';

export function parseBudget(value: string): string | null {
    if (!value) {
        return null;
    }

    const onlyNumbers = value.replace(/[^\d]/g, '');

    if (!onlyNumbers) {
        return null;
    }

    return onlyNumbers;
}

export function validateBudgetLowerSetIfBudgetHigher(budgetLower, allValues): 'required' | undefined {
    const budgetHigher = getIn(allValues, JOB_EDIT_HIRING_BUDGET_BUDGET_HIGHER_FORM_FIELD_NAME) ?? null;

    if (!!budgetHigher && !budgetLower) {
        return 'required';
    }

    return undefined;
}

export function validateBudgetHigherLowerThanBudgetLower(budgetHigher, allValues): 'greater-than' | undefined {
    const budgetLower = getIn(allValues, JOB_EDIT_HIRING_BUDGET_BUDGET_LOWER_FORM_FIELD_NAME) ?? null;

    if (!!budgetLower && !!budgetHigher && new Big(budgetLower).gte(budgetHigher)) {
        // TODO: Change to `lower-than` together with translation message key
        return 'greater-than';
    }

    return undefined;
}
