import { combineReducers } from 'redux';
import { SET_LATEST_VERSION, SetLatestVersionAction } from './actions';

export const VERSION_MOUNT = 'version';

export type LatestVersionState = string | null;
const initialLatestVersionState: LatestVersionState = null;

function latestVersionReducer(
    state: LatestVersionState = initialLatestVersionState,
    action: SetLatestVersionAction
): LatestVersionState {
    switch (action.type) {
        case SET_LATEST_VERSION: {
            return action.payload.version;
        }
        default: {
            return state;
        }
    }
}

export type VersionState = {
    latestVersion: LatestVersionState;
};

const versionReducer = combineReducers({
    latestVersion: latestVersionReducer
});

export default versionReducer;
