import { registerLocale } from 'react-datepicker';

export { LOCALE_DE, LOCALE_EN, appLocales, fallbackLocale, resolveLocale } from './locale';
export type { Locale } from './locale';

import { dateFnsLocales } from './locale/date-fns';

export { allMessages } from './messages';
export type { Messages } from './messages';

export { enhanceIntl } from './intlEnhancer';

registerLocale('de', dateFnsLocales.de);
