import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormField, Input } from '@truffls/design-system-react';

import { useSimpleField } from '../../../utils/form/hooks';
import { hasError } from '../../../utils/form/state';
import { isEmail } from '../../../utils/form/validators';

import LoaderButton from '../../../components/LoaderButtonV2';
import FormError from '../../../components/FormError';

export type FormValues = {
    email: string;
};

type Props = {
    onSubmit: (formValues: FormValues) => void;
    submitting: boolean;
    submitted: boolean;
    submitFailed: boolean;
};

const initialFormValues: FormValues = {
    email: ''
};

const ResetPasswordPageForm = ({ onSubmit, submitting, submitted, submitFailed }: Props) => {
    const [formValues, setFormValues] = React.useState<FormValues>(initialFormValues);

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormValues((state) => {
            return {
                ...state,
                [name]: value
            };
        });
    };

    const emailField = useSimpleField({
        value: formValues.email,
        initialValue: initialFormValues.email,
        onChange: handleChange,
        submitFailed,
        validate: isEmail
    });

    const emailFieldHasError = hasError(emailField.input.value, emailField.meta);

    const formMeta = {
        valid: emailField.meta.valid,
        invalid: !emailField.meta.valid
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (formMeta.valid) {
            onSubmit(formValues);
        }
    };

    if (submitted) {
        // Don't show form if the password has already been resetted
        return null;
    }

    return (
        <form onSubmit={handleSubmit}>
            {submitFailed && <FormError messageId="RESET_PASSWORD.ERROR" />}

            <div className="ResetPasswordPage__input">
                <FormField
                    id="email"
                    labelText={<FormattedMessage id="RESET_PASSWORD.INPUT.LABEL" />}
                    hasError={emailFieldHasError}
                >
                    <Input type="text" name="email" required autoComplete="email" {...emailField.input} />
                </FormField>
            </div>

            <div className="tf-form-actions text-center">
                <LoaderButton type="submit" loading={submitting} typeStyle="raised" variationStyle="brand">
                    <FormattedMessage id="RESET_PASSWORD.BUTTON.LABEL" />
                </LoaderButton>
            </div>
        </form>
    );
};

export default ResetPasswordPageForm;
