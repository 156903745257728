import axios from 'axios';
import { SagaIterator } from 'redux-saga';
import { all, call, cancel, delay, fork, put, spawn, take } from 'redux-saga/effects';

import { START_CHECKER, STOP_CHECKER, setLatestVersion, startChecker } from './actions';

const oneMinute = 60 * 1000;

export function* startChecking(): SagaIterator {
    while (true) {
        yield delay(oneMinute);

        try {
            const response = yield call(axios.get, `/VERSION?${Date.now()}`);
            const latestVersion = response.data.trim();
            yield put(setLatestVersion(latestVersion));
        } catch (error) {
            // Not needed
        }
    }
}

export function* watchStartCheckerSaga(): SagaIterator {
    while (true) {
        yield take(START_CHECKER);
        const checkTask = yield fork(startChecking);

        yield take(STOP_CHECKER);
        yield cancel(checkTask);
    }
}

export default function* root(): SagaIterator {
    yield all([spawn(watchStartCheckerSaga)]);

    yield put(startChecker());
}
