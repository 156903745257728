import React from 'react';
import EnhancedIntlProvider from '../../components/EnhancedIntlProvider';
import { useSelector } from 'react-redux';
import { getLocale } from '../../modules/i18n/selectors';
import { allMessages } from '../messages';
import { enhanceIntl } from '../intlEnhancer';
import { handleError } from '../../services/errorHandler';
import { Locale } from '../locale';

function onError(error: Error) {
    handleError(error);
}

export type IntlManagerProviderProps = {
    children: React.ReactNode;
};

export function IntlManagerProvider({ children }: IntlManagerProviderProps) {
    const locale = useSelector(getLocale);

    return (
        <StatelessIntlManagerProvider locale={locale} onError={onError}>
            {children}
        </StatelessIntlManagerProvider>
    );
}

export type IntlManagerProviderBaseProps = {
    locale: Locale;
    onError?: (error: Error) => void;
    children: React.ReactNode;
};

export function StatelessIntlManagerProvider({ locale, onError, children }: IntlManagerProviderBaseProps) {
    const messages = allMessages[locale];

    return (
        <EnhancedIntlProvider locale={locale} messages={messages} enhancer={enhanceIntl} onError={onError}>
            {children}
        </EnhancedIntlProvider>
    );
}
