import { bindActionCreators, Unsubscribe } from 'redux';

import equal from 'deep-equal';

import { compose } from '../function';

import { Store } from 'redux';

type Actions = {
    [actionName: string]: Function;
};

type Handlers<MS> = {
    onInit: (state: MS, actions: Actions) => {};
    onChange: (prevState: MS, nextState: MS, actions: Actions) => {};
};

export function _createStateMapper<S extends Object, MS extends Object>(
    getState: () => S,
    mapStateToState: (arg1: S) => MS
): () => MS {
    return () => {
        return mapStateToState(getState());
    };
}

export function _enhanceHandlers<MS extends Object, T>(
    handlers: Handlers<MS>,
    cleanup: (arg: T) => T,
    resolve: (result: T) => void,
    reject: (err: any) => void
): Handlers<MS> {
    const thisOfHandlers = {
        resolve: compose(resolve, cleanup),
        reject: compose(reject, cleanup)
    };

    const onInit = handlers.onInit.bind(thisOfHandlers);
    const onChange = handlers.onChange.bind(thisOfHandlers);

    return {
        onInit,
        onChange
    };
}

export function _executeHandlers<MS extends Object, A extends Actions>(
    getState: () => MS,
    subscribe: (listener: () => void) => Unsubscribe,
    actions: A,
    handlers: Handlers<MS>
): Unsubscribe {
    let state = getState();

    const unsubscribe: Unsubscribe = subscribe(() => {
        const prevState = state;
        const nextState = getState();

        state = nextState;

        if (equal(prevState, nextState)) {
            return;
        }

        handlers.onChange(prevState, nextState, actions);
    });

    handlers.onInit(state, actions);

    return unsubscribe;
}

export function _createSubscription() {
    let unsubscriber: Function = () => {};

    return {
        // We only expose it for testing purpose
        _getUnsubscriber /* istanbul ignore next */() {
            return unsubscriber;
        },

        setup(fn: Function) {
            unsubscriber = fn;
        },
        unsubscribe<T>(arg: T): T {
            unsubscriber();

            return arg;
        }
    };
}

export function waitForResolve(store: Store<any, any>) {
    function createResolverConnector<S extends Object, MS extends Object>(
        mapStateToState: (state: S) => MS,
        mapDispatchToActions: Parameters<typeof bindActionCreators>['0'] = {}
    ): Function {
        const getMappedState = _createStateMapper(store.getState, mapStateToState);
        const actions = bindActionCreators(mapDispatchToActions, store.dispatch);

        const createResolver = (handlers: Handlers<MS>) => {
            const subscription = _createSubscription();

            const handlePromise = (resolve, reject) => {
                const enhancedHandlers = _enhanceHandlers(handlers, subscription.unsubscribe, resolve, reject);

                const unsubscribe = _executeHandlers(getMappedState, store.subscribe, actions, enhancedHandlers);

                subscription.setup(unsubscribe);
            };

            return new Promise(handlePromise);
        };

        return createResolver;
    }

    return createResolverConnector;
}
