import { getMessagesEntities } from '../../entities/selectors';

import { MOUNT } from '../reducer';
import { getCandidateConversationId } from '../../candidates/utils';

// Root
const getState = (state) => state[MOUNT];

// Root fields
export const getConversationRootState = (state, conversationId) => {
    return getState(state)[conversationId] || {};
};
export const getConversationState = (state, conversationId) => {
    return getConversationRootState(state, conversationId).conversation || {};
};
export const getCandidateMessagesState = (state, conversationId) => {
    return getConversationRootState(state, conversationId).messages || {};
};

// Conversation fields
export const getConversationFetchStatus = (state, conversationId) => {
    return getConversationState(state, conversationId).fetchStatus || 'none';
};
export const getConversationUpdateStatus = (state, conversationId) => {
    return getConversationState(state, conversationId).updateStatus || 'none';
};
export const getConversationPollingStatus = (state, conversationId) => {
    return getConversationState(state, conversationId).pollingStatus || 'none';
};

// Messages fields
export const getConversationMessagesIds = (state, conversationId) => {
    return getCandidateMessagesState(state, conversationId).entries || [];
};
export const getConversationMessagesFetchStatus = (state, conversationId) => {
    return getCandidateMessagesState(state, conversationId).fetchStatus || 'none';
};
export const getConversationMessagesPollingStatus = (state, conversationId) => {
    return getCandidateMessagesState(state, conversationId).pollingStatus || 'none';
};

// Utils
export const getConversationMessages = (state, conversationId) => {
    return getConversationMessagesIds(state, conversationId).map((messageId) => {
        return getMessagesEntities(state)[messageId];
    });
};

export const getConversationMessagesByCandidates = (state, candidates) => {
    let conversationMessagesByCandidates = {};

    candidates.forEach((candidate) => {
        const conversationId = getCandidateConversationId(candidate);
        const candidateConversationMessages = getConversationMessages(state, conversationId);
        conversationMessagesByCandidates[candidate.id] = candidateConversationMessages;
    });

    return conversationMessagesByCandidates;
};
