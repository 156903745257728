import * as Sentry from '@sentry/browser';
import { SagaIterator } from 'redux-saga';
import { all, call, put, spawn, take } from 'redux-saga/effects';

import { getHistory } from '../../history';
import { CheckoutStep, getCheckoutStepRoute, getCheckoutSuccessRoute, getJobEditRoute } from '../../routes';
import { ApiEntityResponse } from '../../services/api/types';
import { isResponseError } from '../../services/api/utils';
import { Job } from '../jobs/types';
import { isComplete } from '../jobs/utils';
import { createPackageAdvertisementSaga } from '../packages/saga';

import {
    EXECUTE_CHECKOUT,
    START_CHECKOUT,
    StartCheckoutAction,
    checkoutCreate,
    saveCheckoutProcessState
} from './actions';
import * as checkoutApi from './api';
import { ApiCheckout, ApiCheckoutCreateData } from './api/types';
import JobIncompleteError from './errors/JobIncompleteError';
import { Advertisement, CheckoutProcessState } from './types';
import {
    createAdvertisement,
    createCheckoutProcessStateSekeleton,
    isCheckoutProcessStateAdvertiseProduct,
    isCheckoutProcessStateBulkUp
} from './utils';

export function* redirectToCheckout(
    checkoutProcessStateId: string,
    replaceLocation: boolean,
    step: CheckoutStep
): SagaIterator {
    const nextLocation = getCheckoutStepRoute(checkoutProcessStateId, step);

    const history = getHistory();
    if (replaceLocation) {
        yield call(history.replace, nextLocation);
    } else {
        yield call(history.push, nextLocation);
    }
}

export function* startCheckoutAdvertiseSaga(job: Job): SagaIterator<CheckoutProcessState> {
    if (!isComplete(job)) {
        throw new JobIncompleteError(job.id);
    }

    const advertisement: Advertisement = yield call(createAdvertisement, job);

    const checkoutProcessStateSkeleton: any = yield call(createCheckoutProcessStateSekeleton, 'advertise');
    checkoutProcessStateSkeleton.data.product = null;
    checkoutProcessStateSkeleton.data.advertisement = advertisement;

    return checkoutProcessStateSkeleton;
}

export function* startCheckoutBulkUpSaga(): SagaIterator<Advertisement> {
    return yield call(createCheckoutProcessStateSekeleton, 'bulk-up');
}

export function* startCheckoutSaga(method: string, args: { job?: Job }, replaceLocation: boolean): SagaIterator {
    try {
        let checkoutProcessState;

        if (method === 'advertise') {
            if (!args.job) {
                throw new Error("Can't start checkout process for advertisement without job");
            }

            checkoutProcessState = yield call(startCheckoutAdvertiseSaga, args.job);
        } else if (method === 'bulk-up') {
            checkoutProcessState = yield call(startCheckoutBulkUpSaga);
        }

        if (!checkoutProcessState) {
            throw new Error(`Unknown checkout method: ${method}`);
        }

        yield put(saveCheckoutProcessState(checkoutProcessState));

        yield call(redirectToCheckout, checkoutProcessState.id, replaceLocation, 'product');
    } catch (err) {
        if (err instanceof JobIncompleteError && !!args.job) {
            const history = getHistory();
            yield call(history.push, getJobEditRoute(args.job.id, { initialValidation: true }));
        } else {
            Sentry.captureException(err);
        }
    }
}

export function* createCheckoutSaga(
    localId: string,
    productId: string,
    customInvoiceText?: string
): SagaIterator<ApiCheckout> {
    try {
        yield put(checkoutCreate.request(localId));

        const checkoutData: ApiCheckoutCreateData = {
            product: productId,
            quantity: 1,
            custom_invoice_text: customInvoiceText
        };

        const response: ApiEntityResponse<ApiCheckout> = yield call(checkoutApi.createCheckout, checkoutData);
        const checkout = response.data;

        yield put(checkoutCreate.success(localId));

        return checkout;
    } catch (error) {
        if (isResponseError(error)) {
            yield put(checkoutCreate.failure(localId, error.response.data.errors));
        } else {
            yield put(checkoutCreate.failure(localId));
        }

        throw error;
    }
}

export function* executeCheckoutSaga(checkoutProcessState: CheckoutProcessState): SagaIterator {
    try {
        if (isCheckoutProcessStateBulkUp(checkoutProcessState)) {
            yield call(
                createCheckoutSaga,
                checkoutProcessState.id,
                checkoutProcessState.data.product,
                checkoutProcessState.data.customInvoiceText
            );
        } else if (isCheckoutProcessStateAdvertiseProduct(checkoutProcessState)) {
            const checkout: ApiCheckout = yield call(
                createCheckoutSaga,
                checkoutProcessState.id,
                checkoutProcessState.data.product,
                checkoutProcessState.data.customInvoiceText
            );

            yield call(createPackageAdvertisementSaga, {
                package: checkout.package,
                action: checkoutProcessState.data.advertisement.action,
                job: checkoutProcessState.data.advertisement.job
            });
        } else {
            yield call(createPackageAdvertisementSaga, {
                package: checkoutProcessState.data.packageToUse,
                action: checkoutProcessState.data.advertisement.action,
                job: checkoutProcessState.data.advertisement.job
            });
        }

        const history = getHistory();
        yield call(history.push, getCheckoutSuccessRoute(checkoutProcessState.id));
    } catch (error) {
        Sentry.captureException(error);
    }
}

export function* watchStartCheckoutSaga(): SagaIterator {
    while (true) {
        const action: StartCheckoutAction = yield take(START_CHECKOUT);
        const { method, args, replaceLocation } = action.payload;

        yield call(startCheckoutSaga, method, args, replaceLocation);
    }
}

export function* watchExecuteCheckoutSaga(): SagaIterator {
    while (true) {
        const action = yield take(EXECUTE_CHECKOUT);
        const { checkoutProcessState } = action.payload;

        yield call(executeCheckoutSaga, checkoutProcessState);
    }
}

function* rootSaga(): SagaIterator {
    yield all([spawn(watchStartCheckoutSaga), spawn(watchExecuteCheckoutSaga)]);
}

export default rootSaga;
