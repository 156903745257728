import { all, spawn, take, cancel, fork, put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import * as Sentry from '@sentry/browser';

import { saveCheckoutProcessState } from '../../modules/checkout/actions';
import { redirectToCheckout } from '../../modules/checkout/saga';
import { CheckoutProcessState } from '../../modules/checkout/types';
import { isCheckoutProcessStateAdvertisePackage } from '../../modules/checkout/utils';

import { CONTINUE_TO_NEXT_STEP } from './actions';
import { CheckoutProcessStep } from '../../routes';

export function* continueToNextStepSaga(checkoutProcessState: CheckoutProcessState): SagaIterator {
    try {
        yield put(saveCheckoutProcessState(checkoutProcessState));

        let nextStep: CheckoutProcessStep;
        if (isCheckoutProcessStateAdvertisePackage(checkoutProcessState)) {
            nextStep = 'finish';
        } else {
            nextStep = 'address';
        }

        yield call(redirectToCheckout, checkoutProcessState.id, false, nextStep);
    } catch (err) {
        Sentry.captureException(err);
    }
}

export function* watchContinueToNextStepSaga(): SagaIterator {
    let task = null;

    while (true) {
        const action = yield take(CONTINUE_TO_NEXT_STEP);

        const checkoutProcessState: CheckoutProcessState = action.payload.checkoutProcessState;

        if (!!task) {
            yield cancel(task);
        }

        task = yield fork(continueToNextStepSaga, checkoutProcessState);
    }
}

export default function* rootSage(): SagaIterator {
    yield all([spawn(watchContinueToNextStepSaga)]);
}
