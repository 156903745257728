import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatToMonthInYear } from '../../../utils/date';

import CandidateResumeSection from '../CandidateResumeSection';
import CandidateResumeItem from '../CandidateResumeItem';

import { Applicant } from '../../../modules/candidates/types';

type Props = {
    applicant: Applicant;
};

const ResumeEducations = ({ applicant }: Props) => {
    const { resume } = applicant;
    const educations = resume.education;

    if (educations.length === 0) {
        return null;
    }

    return (
        <CandidateResumeSection>
            <h1>
                <FormattedMessage id="RESUME_EDUCATIONS.TITLE" />
            </h1>

            <ul>
                {educations.map((education, index) => (
                    <ResumeEducationsListItem key={index} education={education} />
                ))}
            </ul>
        </CandidateResumeSection>
    );
};

const ResumeEducationsListItem = ({ education }) => {
    return (
        <CandidateResumeItem
            label={
                <span>
                    {!!education.start_date ? (
                        <time dateTime={education.start_date}>
                            <span>{formatToMonthInYear(education.start_date)}</span>
                        </time>
                    ) : (
                        '?'
                    )}
                    &ndash;
                    {!!education.end_date ? (
                        <time dateTime={education.end_date}>
                            <span>{formatToMonthInYear(education.end_date)}</span>
                        </time>
                    ) : (
                        <time>
                            <FormattedMessage id="RESUME_EDUCATIONS.DATE.VALUE_NOW" />
                        </time>
                    )}
                </span>
            }
        >
            <div>
                <strong>
                    <FormattedMessage
                        id="RESUME_EDUCATIONS.SUBJECT_INSTITUTION.VALUE"
                        values={{
                            subject: education.subject,
                            institution: education.institution
                        }}
                    />
                </strong>
            </div>
            {!!education.grade && (
                <div>
                    <i>
                        <FormattedMessage
                            id="RESUME_EDUCATIONS.GRADE.VALUE"
                            values={{
                                grade: education.grade
                            }}
                        />
                    </i>
                </div>
            )}

            {!!education.summary && (
                <div>
                    <span>{education.summary}</span>
                </div>
            )}
        </CandidateResumeItem>
    );
};

export default ResumeEducations;
