import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Candidate } from '../../modules/candidates/types';

import { getCandidateRoute } from '../../routes';

import './CandidatesJobList.scss';

type Props = {
    candidates: Candidate[];
};

function CandidatesJobList({ candidates }: Props) {
    return (
        <ul className="CandidatesJobList">
            {candidates.map((candidate) => {
                const job = candidate.job;
                return (
                    <li key={candidate.id} className="CandidatesJobList__item">
                        <div className="CandidatesJobList__job-item">
                            <Link
                                to={getCandidateRoute(job.id, candidate.id)}
                                className="CandidatesJobList__job-link"
                                target="_blank"
                            >
                                {candidate.job.title}
                            </Link>

                            <div className="CandidatesJobList__job-location">{job.locality}</div>
                            <div className="CandidatesJobList__link-note">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    className="CandidatesJobList__link-note-icon"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M21.9496591,2.45295455 C22.2694563,2.45295455 22.5304841,2.70457652 22.545562,3.02064009 L22.54625,3.04954545 L22.54625,8.41886364 C22.54625,8.7483517 22.2791472,9.01545455 21.9496591,9.01545455 C21.6298619,9.01545455 21.3688341,8.76383257 21.3537561,8.447769 L21.3530682,8.41886364 L21.3529545,3.64609091 L16.5803409,3.64613636 C16.2605437,3.64613636 15.9995159,3.39451439 15.984438,3.07845082 L15.98375,3.04954545 C15.98375,2.72974822 16.235372,2.46872044 16.5514355,2.45364251 L16.5803409,2.45295455 L21.9496591,2.45295455 Z M21.5278056,2.62769198 C21.7607889,2.39470873 22.1385293,2.39470873 22.3715126,2.62769198 C22.5969802,2.85315963 22.6042534,3.21419597 22.393332,3.44840999 L22.3715126,3.47139893 L10.4396944,15.4032171 C10.2067111,15.6362004 9.82897067,15.6362004 9.59598743,15.4032171 C9.37051978,15.1777495 9.36324663,14.8167131 9.57416798,14.5824991 L9.59598743,14.5595102 L21.5278056,2.62769198 Z M12.4042045,6.0325 C12.7336926,6.0325 13.0007955,6.29960285 13.0007955,6.62909091 C13.0007955,6.94888814 12.7491735,7.20991592 12.4331099,7.22499385 L12.4042045,7.22568182 L5.24511364,7.22568182 C4.9253164,7.22568182 4.66428863,7.47730379 4.64921069,7.79336736 L4.64852273,7.82227273 L4.64852273,19.7540909 C4.64852273,20.0738881 4.9001447,20.3349159 5.21620827,20.3499939 L5.24511364,20.3506818 L17.1769318,20.3506818 C17.4967291,20.3506818 17.7577568,20.0990598 17.7728348,19.7829963 L17.7735227,19.7540909 L17.7735227,12.595 C17.7735227,12.2655119 18.0406256,11.9984091 18.3701136,11.9984091 C18.6899109,11.9984091 18.9509386,12.2500311 18.9660166,12.5660946 L18.9667045,12.595 L18.9667045,19.7540909 C18.9667045,20.7284342 18.188127,21.5209309 17.2191782,21.5433748 L17.1769318,21.5438636 L5.24511364,21.5438636 C4.27077037,21.5438636 3.47827363,20.7652861 3.45582972,19.7963373 L3.45534091,19.7540909 L3.45534091,7.82227273 C3.45534091,6.84792946 4.23391846,6.05543272 5.20286726,6.03298881 L5.24511364,6.0325 L12.4042045,6.0325 Z"
                                    />
                                </svg>
                                <FormattedMessage id="CANDIDATES_JOB_LIST.LINK_NOTE" />
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
}

export default CandidatesJobList;
