import * as React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-overlays';

import SlideUpTransition from '../SlideUpTransition';

import './PartialOverlay.style.scss';

type Props = {
    show: boolean;
    coverPercentage: number;
    onHide: () => void;
    className?: string;
    children: React.ReactNode;
};

const ParticalOverlay = ({ show, coverPercentage, onHide, className, children }: Props) => (
    <Modal
        show={show}
        renderBackdrop={() => <div className="PartialOverlay__backdrop" onClick={onHide} />}
        onHide={onHide}
        containerClassName="no-scroll"
        transition={SlideUpTransition}
    >
        <div className={classNames('PartialOverlay', className)} style={{ top: `${100 - coverPercentage}%` }}>
            {children}
        </div>
    </Modal>
);

ParticalOverlay.defaultProps = {
    coverPercentage: 100
};

export default ParticalOverlay;
