import { useReducer, useMemo, useCallback } from 'react';
import {
    createDragMoveOnDragEndStateReducer,
    DragMoveOnDragEndState,
    startModification as startModificationAction,
    stopModifcation as stopModifcationAction,
    updateTemporaryModificationList as updateTemporaryModificationListAction
} from './dragMoveOnDragEndStateReducer';

type ModifcationActions<Type> = {
    startModification: (items: Type[]) => void;
    stopModification: () => void;
    updateTemporaryModificationList: (fromIndex: number, toIndex: number) => void;
};

export function useDragMoveOnDragEndState<Type>(): DragMoveOnDragEndState<Type> & ModifcationActions<Type> {
    const modificationStateReducer = useMemo(() => {
        return createDragMoveOnDragEndStateReducer<Type>();
    }, []);

    const initialDragMoveOnDragEndState = useMemo(() => {
        return modificationStateReducer(undefined, undefined);
    }, [modificationStateReducer]);

    const [state, dispatch] = useReducer(modificationStateReducer, initialDragMoveOnDragEndState);

    const startModification = useCallback(
        (items) => {
            dispatch(startModificationAction(items));
        },
        [dispatch]
    );

    const stopModification = useCallback(() => {
        dispatch(stopModifcationAction());
    }, [dispatch]);

    const updateTemporaryModificationList = useCallback(
        (fromIndex: number, toIndex: number) => {
            dispatch(updateTemporaryModificationListAction(fromIndex, toIndex));
        },
        [dispatch]
    );

    return {
        ...state,
        startModification,
        stopModification,
        updateTemporaryModificationList
    };
}
