import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { normalizeCandidateState } from './utils';

import stateHintArrow from '../../assets/images/state_hint_arrow.svg';

import './style.scss';

import { CandidateState } from '../../modules/candidates/types';

type Props = {
    state: CandidateState;
    onChangeState: (state: CandidateState) => void;
};

type State = {
    arrowContainerWidth: number;
    arrowMarginLeft: number;
    arrowWidth: number;
};

type MakeRefs = {
    [stateName: string]: (element: HTMLElement | null) => void;
};

type Cells = {
    [stateName: string]: HTMLElement;
};

class CandidateStateProgress extends React.Component<Props, State> {
    cells: Cells;
    makeRefs: MakeRefs;

    constructor(props: Props) {
        super(props);

        this.state = {
            arrowContainerWidth: 0,
            arrowMarginLeft: 0,
            arrowWidth: 0
        };

        this.handleChange = this.handleChange.bind(this);
        this.cells = {};
        this.makeRefs = {
            locked: this.makeRef.bind(this, 'locked'),
            interesting: this.makeRef.bind(this, 'interesting')
        };
    }

    makeRef(stateName: CandidateState, element: HTMLElement | null) {
        if (!element) {
            delete this.cells[stateName];
            return;
        }

        this.cells[stateName] = element;

        if (!!this.cells.locked && !!this.cells.interesting) {
            this.setState({
                arrowContainerWidth: this.cells.locked.offsetWidth + this.cells.interesting.offsetWidth,
                arrowMarginLeft: this.cells.locked.offsetWidth / 2 + 5,
                arrowWidth: this.cells.locked.offsetWidth / 2 + this.cells.interesting.offsetWidth / 2 - 20
            });
        }
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const target: HTMLInputElement = event.target as any;
        const state: CandidateState = target.value as any;

        if (this.props.state !== state) {
            this.props.onChangeState(state);
        }
    }

    renderStateCells(): React.ReactNode {
        const states: CandidateState[] = ['locked', 'interesting', 'phone-interview', 'interview', 'hired'];
        const { state } = this.props;
        const normalizedState = normalizeCandidateState(state);

        const stateElements = states.map((stateName: CandidateState) => {
            return (
                <React.Fragment key={stateName}>
                    <input
                        type="radio"
                        className="CandidateStateProgress__radio"
                        id={`CandidateStateProgress__radio--${stateName}`}
                        onChange={this.handleChange}
                        value={stateName}
                        checked={normalizedState === stateName}
                    />
                    <div
                        className={`CandidateStateProgress__cell CandidateStateProgress__cell--${stateName}`}
                        ref={this.makeRefs[stateName]}
                    >
                        <div className="CandidateStateProgress__state-name">
                            <FormattedMessage id={`CONSTANT.CANDIDATE_STATE.${stateName}`} />
                        </div>
                        <div className="CandidateStateProgress__state-indicator">
                            <label
                                className="CandidateStateProgress__state-indicator-circle"
                                htmlFor={`CandidateStateProgress__radio--${stateName}`}
                            />
                        </div>
                    </div>
                </React.Fragment>
            );
        });

        return stateElements;
    }

    render() {
        const { state } = this.props;
        const normalizedState = normalizeCandidateState(state);

        return (
            <div className="CandidateStateProgress">
                <div
                    className={classNames(
                        'CandidateStateProgress__bar',
                        `CandidateStateProgress__bar--${normalizedState}`
                    )}
                >
                    {this.renderStateCells()}
                </div>

                <div
                    className={classNames('CandidateStateProgress__arrow-container', {
                        'CandidateStateProgress__arrow-container--hidden': normalizedState !== 'locked'
                    })}
                    style={{
                        width: this.state.arrowContainerWidth + 'px'
                    }}
                >
                    <img
                        className="CandidateStateProgress__arrow"
                        src={stateHintArrow}
                        role="presentation"
                        style={{
                            left: this.state.arrowMarginLeft + 'px',
                            width: this.state.arrowWidth + 'px'
                        }}
                    />

                    <div
                        className="CandidateStateProgress__arrow-head"
                        style={{
                            left: this.state.arrowMarginLeft + this.state.arrowWidth + 'px'
                        }}
                    />
                </div>

                <div className="CandidateStateProgress__description">
                    <FormattedMessage id="CANDIDATE_STATE_PROGRESS.DESCRIPTION" />
                </div>
            </div>
        );
    }
}

export default CandidateStateProgress;
