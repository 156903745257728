import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './RequestDocumentsViewMobile.style.scss';

import PartialOverlay from '../../../components/PartialOverlay';
import DialogV2Actions from '../../../components/DialogV2/DialogActions';
import Button from '../../../components/Button';
import LoaderButtonV2 from '../../../components/LoaderButtonV2';

type Props = {
    open: boolean;
    submittingRequest: boolean;
    submitEnabled: boolean;
    scrollTop: number;
    onClose: () => void;
    onSubmit: (event: React.FormEvent) => void;
    makeRef: (element: HTMLElement | null) => void;
    children: React.ReactNode;
};

class RequestDocumentsViewMobile extends React.Component<Props> {
    _scrollContainer: HTMLElement | null = null;

    constructor(props: Props) {
        super(props);

        this.scrollContainerRef = this.scrollContainerRef.bind(this);
        this.isScrollContainerScrollable = this.isScrollContainerScrollable.bind(this);
    }

    scrollContainerRef(element: HTMLElement | null) {
        const { makeRef } = this.props;

        this._scrollContainer = element;

        makeRef(element);
    }

    isScrollContainerScrollable() {
        if (!this._scrollContainer) {
            return false;
        }

        return (
            this._scrollContainer.scrollTop > 0 ||
            this._scrollContainer.scrollHeight > this._scrollContainer.clientHeight
        );
    }

    // Class needed for ref
    render() {
        const { open, submittingRequest, submitEnabled, scrollTop, onClose, onSubmit, children } = this.props;

        return (
            <PartialOverlay show={open} onHide={onClose} coverPercentage={80}>
                <form className="RequestDocumentsViewMobile__form" onSubmit={onSubmit}>
                    <div className="RequestDocumentsViewMobile__container" ref={this.scrollContainerRef}>
                        {scrollTop > 1 && <div className="RequestDocumentsViewMobile__scroll-indicator" />}
                        <span className="RequestDocumentsViewMobile__container__title">
                            <FormattedMessage id="REQUEST_DOCUMENTS_VIEW.TITLE.SHORT" />:
                        </span>

                        {children}
                    </div>
                    <DialogV2Actions
                        className={classNames('RequestDocumentsViewMobile__form__actions', {
                            'RequestDocumentsViewMobile__form__actions--in-scroll-area': this.isScrollContainerScrollable()
                        })}
                    >
                        <Button type="button" onClick={onClose} typeStyle="flat" variationStyle="brand">
                            <FormattedMessage id="REQUEST_DOCUMENTS_VIEW.CANCEL" />
                        </Button>
                        <LoaderButtonV2
                            type="submit"
                            disabled={submitEnabled}
                            loading={submittingRequest}
                            typeStyle="flat"
                            variationStyle="brand"
                        >
                            <FormattedMessage id="REQUEST_DOCUMENTS_VIEW.SUBMIT" />
                        </LoaderButtonV2>
                    </DialogV2Actions>
                </form>
            </PartialOverlay>
        );
    }
}

export default RequestDocumentsViewMobile;
