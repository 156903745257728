import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';

import { formatSimpleDuration } from '../../utils/intl';
import FormattedCurrencyNumber from '../FormattedCurrencyNumber';

import classes from './OrderSummaryTable.module.scss';

export type OrderSummaryTablePosition = {
    identifier: string;
    quantity: number;
    duration: [number, 'years' | 'days'] | null;

    unitPrice: number;
    totalPrice: number;
};

export type OrderSummaryTableProps = {
    positions: OrderSummaryTablePosition[];

    netAmount: number;
    vatRate: number;
    vatAmount: number;
    grossAmount: number;

    className?: string;
};

function OrderSummaryTable({
    positions,
    className,
    netAmount,
    vatRate,
    vatAmount,
    grossAmount
}: OrderSummaryTableProps) {
    return (
        <section className={classNames(classes.root, className)}>
            <div className={classes.data}>
                <OrderSummaryTableHeader />

                <ul className={classes.positions}>
                    {positions.map((item, index) => {
                        return <OrderSummaryTableItem key={index} item={item} />;
                    })}
                </ul>
            </div>

            <OrderSummaryTableResults
                netAmount={netAmount}
                vatRate={vatRate}
                vatAmount={vatAmount}
                grossAmount={grossAmount}
            />
        </section>
    );
}

export default OrderSummaryTable;

function OrderSummaryTableHeader() {
    return (
        <header className={classes.header}>
            <div className={classes.headerItem}>
                <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.DESCRIPTION" />
            </div>
            <div className={classNames(classes.headerItem, classes.headerItemDetail)}>
                <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.UNIT_PRICE" />
            </div>
            <div className={classNames(classes.headerItem, classes.headerItemDetail)}>
                <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.QUANTITY" />
            </div>
            <div className={classNames(classes.headerItem, classes.headerItemDetail)}>
                <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.TOTAL" />
            </div>
        </header>
    );
}

type OrderSummaryTableItemProps = {
    item: OrderSummaryTablePosition;
};

function OrderSummaryTableItem({ item }: OrderSummaryTableItemProps) {
    const intl = useIntl();

    const formattedDuration = !!item.duration ? formatSimpleDuration(intl, item.duration[0], item.duration[1]) : null;

    return (
        <li className={classes.position}>
            <div className={classes.positionDescription}>
                <div className={classes.positionDescriptionMain}>
                    <FormattedMessage
                        id="ORDER_SUMMARY_TABLE.POSITION_DESCRIPTION"
                        values={{
                            type: <FormattedMessage id={`CONSTANT.PRODUCT.${item.identifier}`} />
                        }}
                    />
                </div>
                {formattedDuration && (
                    <div className={classes.positionDescriptionSub}>
                        <FormattedMessage
                            id="CONSTANT.PRODUCT_PERIOD_OF_PERFORMANCE"
                            values={{
                                duration: formattedDuration
                            }}
                        />
                    </div>
                )}
            </div>

            <div className={classes.positionDetail}>
                <div className={classes.positionDetailLabel}>
                    <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.UNIT_PRICE" />
                </div>

                <div className={classes.positionDetailValue}>
                    <FormattedCurrencyNumber
                        value={item.unitPrice}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                    />
                </div>
            </div>
            <div className={classes.positionDetail}>
                <div className={classes.positionDetailLabel}>
                    <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.QUANTITY" />
                </div>

                <div className={classes.positionDetailValue}>{item.quantity}</div>
            </div>
            <div className={classes.positionDetail}>
                <div className={classes.positionDetailLabel}>
                    <FormattedMessage id="ORDER_SUMMARY_TABLE.COLUMN.TOTAL" />
                </div>

                <div className={classes.positionDetailValue}>
                    <FormattedCurrencyNumber
                        value={item.totalPrice}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                    />
                </div>
            </div>
        </li>
    );
}

type OrderSummaryTableResultsProps = {
    netAmount: number;
    vatRate: number;
    vatAmount: number;
    grossAmount: number;
};

function OrderSummaryTableResults({ netAmount, vatRate, vatAmount, grossAmount }: OrderSummaryTableResultsProps) {
    return (
        <div className={classes.summary}>
            <div className={classes.summaryItem}>
                <div className={classes.summaryItemLabel}>
                    <FormattedMessage id="ORDER_SUMMARY_TABLE.SUBTOTAL" />
                </div>

                <div className={classes.summaryItemValue}>
                    <FormattedCurrencyNumber value={netAmount} minimumFractionDigits={2} maximumFractionDigits={2} />
                </div>
            </div>

            <div className={classes.summaryItem}>
                <div className={classes.summaryItemLabel}>
                    <FormattedMessage
                        id="ORDER_SUMMARY_TABLE.VAT_RATE"
                        values={{
                            rate: (
                                <FormattedNumber
                                    // eslint-disable-next-line react/style-prop-object
                                    style="percent"
                                    value={vatRate}
                                />
                            )
                        }}
                    />
                </div>

                <div className={classes.summaryItemValue}>
                    <FormattedCurrencyNumber value={vatAmount} minimumFractionDigits={2} maximumFractionDigits={2} />
                </div>
            </div>

            <div className={classNames(classes.summaryItem, classes.summaryTotal)}>
                <div className={classes.summaryItemLabel}>
                    <FormattedMessage id="ORDER_SUMMARY_TABLE.GRAND_TOTAL" />
                </div>

                <div className={classes.summaryItemValue}>
                    <FormattedCurrencyNumber value={grossAmount} minimumFractionDigits={2} maximumFractionDigits={2} />
                </div>
            </div>
        </div>
    );
}
