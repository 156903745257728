import apiClientV3 from '../../services/api/v3';
import apiClientV4 from '../../services/api/v4';
import { RequestTokenTokenType } from './type';

export function verifyApiToken(apiToken: string) {
    return apiClientV4.head('/auth/user/', {
        headers: { Authorization: `Token ${apiToken}` }
    });
}

export function authenticateWithEmailAndPassword(email: string, password: string) {
    const data = {
        profile_type: 'recruiter',
        email,
        password
    };

    return apiClientV4.post('/auth/login/', data);
}

export function authenticateWithRequestToken(requestToken: string) {
    return apiClientV3.get(`/request-tokens/${requestToken}/`);
}

export function authenticateWithTempToken(tempToken: string) {
    const data = {
        temp_token: tempToken
    };

    return apiClientV3.post('/auth/temp-login/', data);
}

type RecruiterProfile = {
    first_name: string;
    last_name: string;
    company: string;
    gender: 'm' | 'f';
};

type SignupRequestData = {
    email: string;
    profile_type: 'recruiter';
    password: string;
    language: string;
    profile: RecruiterProfile;
    accept_latest_gtc: boolean;
};

export function signup(
    email: string,
    password: string,
    profile: RecruiterProfile,
    language: string,
    acceptLatestGTC: boolean
) {
    const data: Partial<SignupRequestData> = {};

    data.email = email;
    data.profile_type = 'recruiter';
    data.password = password;
    data.language = language;
    data.profile = profile;
    data.accept_latest_gtc = acceptLatestGTC;

    return apiClientV4.post('/auth/registration/', data);
}

export function deleteAccount() {
    return apiClientV4.delete('/auth/user/');
}

export function changePassword(oldPassword: string, newPassword: string) {
    const data = {
        old_password: oldPassword,
        new_password: newPassword
    };

    return apiClientV4.post('/auth/password/change/', data);
}

export function requestPasswordReset(email: string) {
    const data = {
        profile_type: 'premium-recruiter',
        email
    };

    return apiClientV3.post('/auth/password/reset/', data);
}

type ConfirmPasswordResetFormData = {
    uid: string;
    token: string;
    newPassword: string;
};

export function confirmPasswordReset(formData: ConfirmPasswordResetFormData) {
    const data = {
        uid: formData.uid,
        token: formData.token,
        new_password: formData.newPassword
    };

    return apiClientV3.post('/auth/password/reset/confirm/', data);
}

export function requestRequestToken(email: string, tokenType: RequestTokenTokenType = 'dashboard') {
    const data = {
        token_type: tokenType,
        email: email
    };

    return apiClientV3.post('/request-tokens/', data);
}

export function verifyEmail(verificationToken: string) {
    const data = {
        key: verificationToken
    };

    return apiClientV3.post('/auth/registration/verify-email/', data);
}

export function resendVerificationEmail(email: string) {
    const data = {
        email: email,
        token_type: 'email-verification-recruiter'
    };

    return apiClientV3.post('/request-tokens/', data);
}
