import { createAction } from 'redux-actions';
import { createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';

// Types
import { AgreementContract } from './types';

const createActionType = createActionTypeCreator('AGREEMENTS');

export const ENABLE_LEGAL_AGREEMENTS = createActionType('ENABLE_LEGAL_AGREEMENTS');
export const DISABLE_LEGAL_AGREEMENTS = createActionType('DISABLE_LEGAL_AGREEMENTS');

export const AGREEMENT_CONTRACT_ACCEPTANCE = createRequestActionTypes(
    createActionType('AGREEMENT_CONTRACT_ACCEPTANCE')
);

export const ACCEPT_AGREEMENT_CONTRACTS = createActionType('ACCEPT_CONTRACTS');

export const enableLegalAgreements = createAction(ENABLE_LEGAL_AGREEMENTS);
export const disableLegalAgreements = createAction(DISABLE_LEGAL_AGREEMENTS);

export const agreementContractAcceptance = {
    request: createAction(AGREEMENT_CONTRACT_ACCEPTANCE.REQUEST, (agreementContract: AgreementContract) => {
        return {
            agreementContract
        };
    }),
    success: createAction(AGREEMENT_CONTRACT_ACCEPTANCE.SUCCESS, (agreementContract: AgreementContract) => {
        return {
            agreementContract
        };
    }),
    failure: createAction(AGREEMENT_CONTRACT_ACCEPTANCE.FAILURE, (agreementContract: AgreementContract, errors) => {
        return {
            agreementContract,
            errors
        };
    })
};

export const acceptAgreementContracts = createAction(
    ACCEPT_AGREEMENT_CONTRACTS,
    (agreementContracts: AgreementContract[]) => {
        return {
            agreementContracts
        };
    }
);
