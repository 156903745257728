import { ApiError } from '../../services/api/types';
import { createAction, createActionTypeCreator, createSetActionTypes } from '../../utils/redux';
import { JobParsingError } from './constants';

const createActionType = createActionTypeCreator('JOB_PARSING');

export const PARSE_JOB = createActionType('PARSE_JOB');
export const parseJob = createAction(PARSE_JOB, (urlToParse: string) => {
    return {
        payload: {
            urlToParse
        }
    };
});
export type ParseJobAction = ReturnType<typeof parseJob>;

export const CANCEL_JOB_PARSING = createActionType('CANCEL_JOB_PARSING');
export const cancelJobParsing = createAction(CANCEL_JOB_PARSING);
export type CancelJobParsingAction = ReturnType<typeof cancelJobParsing>;

export const JOB_PARSING = createSetActionTypes(createActionType('JOB_PARSING'), [
    'START',
    'SUCCESS',
    'FAILURE'
] as const);
export const jobParsing = {
    start: createAction(JOB_PARSING.START),
    success: createAction(JOB_PARSING.SUCCESS),
    failure: createAction(
        JOB_PARSING.FAILURE,
        ({ error, serverErrors }: { error?: JobParsingError; serverErrors?: ApiError[] }) => {
            return {
                payload: {
                    error,
                    serverErrors
                }
            };
        }
    )
};
export type JobParsing$StartAction = ReturnType<typeof jobParsing.start>;
export type JobParsing$SuccessAction = ReturnType<typeof jobParsing.success>;
export type JobParsing$FailureAction = ReturnType<typeof jobParsing.failure>;
