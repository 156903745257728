import { ProductV2ProductSlug } from './types';

export const productsWithOwnProductName: ProductV2ProductSlug[] = [
    'small_1',
    'medium_1',
    'large_1',
    'small_5',
    'medium_5',
    'large_5',
    'small_10',
    'medium_10',
    'large_10',
    'small_100',
    'medium_100',
    'large_100',
    'truffls_small_1_2016-05',
    'truffls_medium_1_2016-05',
    'truffls_medium_5_2016-05',
    'truffls_medium_10_2016-05',
    'truffls_medium_100_2016-05',
    'truffls_small_1_2017-03',
    'truffls_medium_1_2017-03',
    'truffls_medium_5_2017-03',
    'truffls_medium_10_2017-03',
    'truffls_medium_100_2017-03',
    'truffls_medium_dynamic_2017-04',
    'truffls_professional_dynamic_2017-07',
    'truffls_expert_dynamic_2017-07',
    'truffls_professional_dynamic_2017-10',
    'truffls_professional_select_dynamic_2017-10',
    'truffls_expert_dynamic_2017-10',
    'truffls_professional_dynamic_2018-10',
    'truffls_professional_select_dynamic_2018-10',
    'truffls_professional_dynamic_2019-09',
    'truffls_select_dynamic_2019-09',
    'truffls_exclusive_2019-09',
    'truffls_pro_dynamic_2021-07',
    'truffls_pro_plus_dynamic_2021-07',
    'truffls_flatrate_2021-09',
    'truffls_flatrate_test_2021-09',
    'truffls_free_trial_ad_2021-09',
    'truffls_flexi_pro_plus_2022-08',
    'truffls_flexi_pro_plus_2022-09',
    'truffls_free_pro_ad_2022-09',
    'truffls_free_proplus_ad_2022-09',
    'truffls_pro_dynamic_2022-10',
    'truffls_pro_plus_dynamic_2022-10',
    'truffls_ad_upgrade_2022-10',
    'truffls_trainee_2022-10',
    'truffls_trainee_plus_2023-01',
    'truffls_new_customer_test_2023-09',
    'truffls_pro_2023-09',
    'truffls_pro_plus_2023-09',
    'truffls_pro_ultimate_2023-09',
    'prolong_truffls_pro_2023-09',
    'prolong_truffls_pro_plus_2023-09',
    'prolong_truffls_pro_ultimate_2023-09',
    'truffls_pro_1yr_2023-09',
    'truffls_pro_plus_1yr_2023-09',
    'truffls_pro_ultimate_1yr_2023-09',
    'truffls_ad_upgrade_pro_plus_2023-10',
    'truffls_ad_upgrade_ultimate_2023-10',
    'truffls_trainee_2023-10',
    'truffls_trainee_plus_2023-10',
    'truffls_trainee_ultimate_2023-10',
    'truffls_free_trial_ad_short_2023-10',
    'truffls_free_trial_ad_2023-10',
    'truffls_pro_3m-2024-04',
    'truffls_pro_plus_3m-2024-04',
    'truffls_pro_ultimate_3m-2024-04',
    'truffls_free_pro_plus_2024-04',
    'truffls_premium_3m_2024-12',
    'truffls_premium_2024-12',
    'truffls_premium_1yr_2024-12',
    'truffls_premium_add_loc_2024-12',
    'truffls_premium_combo_3m_2024-12',
    'truffls_premium_combo_2024-12',
    'truffls_premium_combo_add_loc_2024-12',
    'truffls_premium_combo_1yr_2024-12',
    'truffls_premium_combo_1yr_add_loc_2024-12',
    'truffls_premium_slot_combo_1yr_2024-12'
];

export function isProductWithProductName(productSlug: ProductV2ProductSlug) {
    return productsWithOwnProductName.includes(productSlug);
}

export function getProductNameMessageId(productSlug: ProductV2ProductSlug): string {
    return `CONSTANT.PRODUCT.${productSlug}`;
}
