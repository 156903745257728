import * as React from 'react';

import './JobFormRequirementsListItemPlaceholder.scss';

export type JobFormRequirementsListItemPlaceholderProps = {};

function JobFormRequirementsListItemPlaceholder({}: JobFormRequirementsListItemPlaceholderProps) {
    return <div className="JobFormRequirementsListItemPlaceholder"></div>;
}

export default JobFormRequirementsListItemPlaceholder;
