import React from 'react';
import classNames from 'classnames';
import { Checkbox } from '@truffls/design-system-react';
import { FormattedMessage } from 'react-intl';
import { FieldValidator } from 'final-form';
import useField from '../../../../utils/form/react-final-form/useField';
import { WORKTYPES } from '../../../../modules/jobs/constants';
import { Field } from '../../../../utils/form/react-final-form';
import { createArrayMinLength } from '../../../../utils/form/validators';
import { useJobFormPageHasError } from '../../../JobFormPage/JobFormPage.hooks';
import { onJobFormPageValidationLevel } from '../../../JobFormPage/JobFormPage.utils';
import JobFormFieldAnchor from '../../JobFormFieldAnchor';

export type JobFormWorktypesFormFieldsValue = string[];
export type JobFormWorktypesFormFieldsName = 'worktypes';
export const JOB_EDIT_WORKTYPES_FORM_FIELDS_NAME: JobFormWorktypesFormFieldsName = 'worktypes';

const MIN_LENGTH = 1;

const minLengthValidator = createArrayMinLength(MIN_LENGTH) as FieldValidator<JobFormWorktypesFormFieldsValue>;
const validate = onJobFormPageValidationLevel<JobFormWorktypesFormFieldsValue>('full', minLengthValidator);

export type JobFormWorktypesFormFieldsProps = {};

function JobFormWorktypesFormFields({}: JobFormWorktypesFormFieldsProps) {
    const { input, meta } = useField<JobFormWorktypesFormFieldsValue, HTMLInputElement>(
        JOB_EDIT_WORKTYPES_FORM_FIELDS_NAME,
        {
            type: 'checkbox',
            validate
        }
    );

    const hasError = useJobFormPageHasError();

    const selectedValue = input.value || null;

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_WORKTYPES_FORM_FIELDS_NAME} />
            <div
                className={classNames('tf-form-field', {
                    'tf-has-error': hasError(selectedValue, meta)
                })}
            >
                <label className="control-label">
                    <FormattedMessage id="JOB_EDIT.WORKTYPES_FORM_FIELDS.LABEL_TEXT" />
                </label>
                <span className="help-block">
                    <FormattedMessage id="JOB_EDIT.WORKTYPES_FORM_FIELDS.HELP_TEXT" />
                </span>
                <div className="row">
                    {WORKTYPES.map((worktypeSlug) => (
                        <div key={worktypeSlug} className="col-sm-6 col-md-6 col-lg-4">
                            <Field name={JOB_EDIT_WORKTYPES_FORM_FIELDS_NAME} type="checkbox" value={worktypeSlug}>
                                {({ input }) => {
                                    return (
                                        <Checkbox id={`job-form-field-input-worktypes-${worktypeSlug}`} {...input}>
                                            <FormattedMessage id={`CONSTANT.WORKTYPE.${worktypeSlug}`} />
                                        </Checkbox>
                                    );
                                }}
                            </Field>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default JobFormWorktypesFormFields;
