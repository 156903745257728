import * as React from 'react';

import classes from './ProductTable.module.scss';

type Props = {
    children: React.ReactElement[];
};

const ProductTable = (props: Props) => {
    return (
        <div className={classes.root}>
            {React.Children.map(props.children, (child, index) => {
                return (
                    <div className={classes.item} key={child.key || index}>
                        {child}
                    </div>
                );
            })}
        </div>
    );
};

export default ProductTable;
