import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import FullScreenMenu, { MenuItemDescriptor } from '../../../../components/FullScreenMenu';
import MobilePageContainer from '../../../../components/MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../../../../components/NavigationBar';
import { links } from '../../../../constants';

import './SignupPageNavigationOverlayMenu.style.scss';

type Props = {
    open: boolean;
    onClose: () => void;
    onOpenLogin: () => void;
};

function SignupPageNavigationOverlayMenu({ open, onClose, onOpenLogin }: Props) {
    const { locale } = useIntl();

    const primaryMenuItemDescriptors: MenuItemDescriptor[] = [
        {
            component: 'a',
            href: links.website[locale],
            target: '_blank',
            rel: 'noopener noreferrer',
            label: <FormattedMessage id="SIGNUP_PAGE.NAVIGATION.LINK_ABOUT" />
        }
    ];

    const secondaryMenuItemDescriptors: MenuItemDescriptor[] = [
        {
            component: 'button',
            onClick: onOpenLogin,
            label: <FormattedMessage id="SIGNUP_PAGE.NAVIGATION.LINK_LOGIN" />
        }
    ];

    const menuDescriptors: MenuItemDescriptor[][] = [primaryMenuItemDescriptors, secondaryMenuItemDescriptors];

    return (
        <MobilePageContainer show={open} transitionName="scale">
            <NavigationBar
                noShadow={true}
                centerElement={
                    <NavigationBarText>
                        <FormattedMessage id="SIGNUP_PAGE.NAVIGATION.MENU_TITLE" />
                    </NavigationBarText>
                }
                rightElement={<NavigationBarCloseAction onClick={onClose} />}
            />

            <FullScreenMenu menus={menuDescriptors} />
        </MobilePageContainer>
    );
}

export default SignupPageNavigationOverlayMenu;
