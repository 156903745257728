import React from 'react';
import classNames from 'classnames';
import { FormField, Select } from '@truffls/design-system-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FieldValidator, getIn } from 'final-form';

import useField from '../../../../utils/form/react-final-form/useField';
import { isRequired } from '../../../../utils/form/validators';

import { JobStartDateStart } from '../../../../modules/jobs/types';

import DateInput from '../../../../components/DateInput';

import { useJobFormPageHasError } from '../../../JobFormPage/JobFormPage.hooks';
import { onJobFormPageValidationLevel } from '../../../JobFormPage/JobFormPage.utils';

import {
    JOB_EDIT_AVAILABILITY_FORM_FIELDS_NAME,
    JOB_EDIT_AVAILABILITY_START_FORM_FIELD_NAME,
    JOB_EDIT_AVAILABILITY_DATE_FORM_FIELD_NAME,
    JobFormAvailabilityFormFieldsValue
} from './JobFormAvailabilityFormFields.constants';

import './JobFormAvailabilityFormFields.scss';
import JobFormFieldAnchor from '../../JobFormFieldAnchor';

const validateStart = onJobFormPageValidationLevel<JobFormAvailabilityFormFieldsValue['start'] | ''>(
    'full',
    isRequired as FieldValidator<JobFormAvailabilityFormFieldsValue['start'] | ''>
);

const validateDate = onJobFormPageValidationLevel('minimal', ((value, allValues) => {
    if (JobStartDateStart.Date === getIn(allValues, JOB_EDIT_AVAILABILITY_START_FORM_FIELD_NAME)) {
        return isRequired(value, allValues);
    }

    return undefined;
}) as FieldValidator<JobFormAvailabilityFormFieldsValue['date'] | ''>);

export type JobFormAvailabilityFormFieldsProps = {};

function JobFormAvailabilityFormFields({}: JobFormAvailabilityFormFieldsProps) {
    const { formatMessage } = useIntl();

    const startField = useField<JobFormAvailabilityFormFieldsValue['start'] | '', HTMLSelectElement>(
        JOB_EDIT_AVAILABILITY_START_FORM_FIELD_NAME,
        {
            initialValue: '',
            validate: validateStart
        }
    );
    const selectedStartValue: JobStartDateStart | null = startField.input.value || null;

    const dateField = useField<JobFormAvailabilityFormFieldsValue['date'], HTMLInputElement>(
        JOB_EDIT_AVAILABILITY_DATE_FORM_FIELD_NAME,
        {
            allowNull: true,
            initialValue: null,
            validate: validateDate
        }
    );
    const selectedDateValue = dateField.input.value || null;

    const handleChangeStart = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        startField.input.onChange(event);

        if (event.target.value !== JobStartDateStart.Date) {
            dateField.input.onChange(null);
        }
    };

    const hasError = useJobFormPageHasError();
    const hasStartError = hasError(selectedStartValue, startField.meta);
    const hasDateError = hasError(selectedDateValue, dateField.meta);

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_AVAILABILITY_FORM_FIELDS_NAME} />
            <div
                className={classNames('JobFormAvailabilityFormFields form-group', {
                    'has-error': hasStartError || hasDateError
                })}
            >
                <label className="control-label">
                    <FormattedMessage id="JOB_EDIT.AVAILABILITY_FORM_FIELDS.LABEL_TEXT" />
                </label>
                <div className="help-block">
                    <FormattedMessage id="JOB_EDIT.AVAILABILITY_FORM_FIELDS.HELP_TEXT" />
                </div>
                <div className="JobFormAvailabilityFormFields__fields">
                    <FormField
                        id="job-form-field-start_date-start"
                        labelText={<FormattedMessage id="JOB_EDIT.AVAILABILITY_FORM_FIELDS.FIELD_START.LABEL_TEXT" />}
                        className="JobFormAvailabilityFormFields__field-start"
                        hasError={hasStartError}
                    >
                        <Select {...startField.input} onChange={handleChangeStart}>
                            <option hidden disabled value=""></option>
                            <option value={JobStartDateStart.Flex}>
                                {formatMessage({
                                    id: 'JOB_EDIT.AVAILABILITY_FORM_FIELDS.FIELD_START.OPTION_FLEX.LABEL_TEXT'
                                })}
                            </option>
                            <option value={JobStartDateStart.Date}>
                                {formatMessage({
                                    id: 'JOB_EDIT.AVAILABILITY_FORM_FIELDS.FIELD_START.OPTION_DATE.LABEL_TEXT'
                                })}
                            </option>
                            <option value={JobStartDateStart.Asap}>
                                {formatMessage({
                                    id: 'JOB_EDIT.AVAILABILITY_FORM_FIELDS.FIELD_START.OPTION_ASAP.LABEL_TEXT'
                                })}
                            </option>
                        </Select>
                    </FormField>
                    {startField.input.value === JobStartDateStart.Date && (
                        <FormField
                            id="job-form-field-start_date-date"
                            labelText={
                                <FormattedMessage id="JOB_EDIT.AVAILABILITY_FORM_FIELDS.FIELD_DATE.LABEL_TEXT" />
                            }
                            className="JobFormAvailabilityFormFields__field-date"
                            hasError={hasDateError}
                        >
                            <DateInput {...dateField.input} />
                        </FormField>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}

export default JobFormAvailabilityFormFields;
