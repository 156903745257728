import { createContext } from 'react';

export type ProductContextValue = {
    promote: boolean;
    targetOrientation: 'horizontal' | 'vertical';
};

export const ProductContext = createContext<ProductContextValue>({
    promote: false,
    targetOrientation: 'vertical'
});
