import React, { forwardRef } from 'react';
import classNames from 'classnames';

import './SearchBarCloseButton.style.scss';
import { FormattedMessage } from 'react-intl';

type Props = {
    closeMenu: () => void;
    [key: string]: any;
};

function SearchBarCloseButton({ closeMenu, className, ...props }: Props, ref: any) {
    const onClick = (event: React.MouseEvent) => {
        event.preventDefault();

        closeMenu();
    };

    return (
        <button
            {...props}
            type="button"
            className={classNames('SearchBarCloseButton', className)}
            onClick={onClick}
            ref={ref}
        >
            <span className="sr-only">
                <FormattedMessage id="SEARCH_BAR.CLOSE_BUTTON" />
            </span>

            <svg className="SearchBarCloseButton__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M13.44,11.7432 C13.4023833,11.7056783 13.3812425,11.6547309 13.3812425,11.6016 C13.3812425,11.5484691 13.4023833,11.4975217 13.44,11.46 L20.8504,4.05039996 C21.3189584,3.58117883 21.3184211,2.82095835 20.8492,2.35239997 C20.3799789,1.88384159 19.6197584,1.88437884 19.1512,2.35359995 L11.7416,9.75999999 C11.7040783,9.79761667 11.6531309,9.8187575 11.6,9.8187575 C11.5468691,9.8187575 11.4959217,9.79761667 11.4584,9.75999999 L4.04879996,2.35359995 C3.58024158,1.88482065 2.82037925,1.88464157 2.35159995,2.35319995 C1.88282065,2.82175834 1.88264157,3.58162066 2.35119995,4.05039996 L9.75999999,11.46 C9.79761667,11.4975217 9.8187575,11.5484691 9.8187575,11.6016 C9.8187575,11.6547309 9.79761667,11.7056783 9.75999999,11.7432 L2.35119995,19.1536 C2.04809582,19.4568471 1.92979643,19.898763 2.040864,20.3128824 C2.15193157,20.7270018 2.4754923,21.0504101 2.889664,21.1612824 C3.3038357,21.2721548 3.74569583,21.1536471 4.04879996,20.8504 L11.4584,13.44 C11.4959217,13.4023833 11.5468691,13.3812425 11.6,13.3812425 C11.6531309,13.3812425 11.7040783,13.4023833 11.7416,13.44 L19.1512,20.8504 C19.6197584,21.3191793 20.3796207,21.3193584 20.8484,20.8508 C21.3171793,20.3822417 21.3173584,19.6223793 20.8488,19.1536 L13.44,11.7432 Z" />
            </svg>
        </button>
    );
}

export default forwardRef(SearchBarCloseButton);
