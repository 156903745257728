import { createStorage, isLocalStorageSupported } from '../utils/storage';

import { localStorageKeyPrefix } from '../constants';

const { get, set, remove } = createStorage({
    isStorageSupported: isLocalStorageSupported,
    getStorage: () => window.localStorage,
    storageKeyPrefix: localStorageKeyPrefix
});

export { get, remove, set };
