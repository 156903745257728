import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { State as ApplicationState } from '../../store/reducer';

import CheckoutActionBar from '../../components/CheckoutActionBar';
import CheckoutActionBarButton from '../../components/CheckoutActionBar/CheckoutActionBarButton';
import CheckoutConfirmAdvertisementForm from '../CheckoutConfirmAdvertisementForm';
import CheckoutConfirmPackageUsageForm from '../CheckoutConfirmPackageUsageForm';

import { executeCheckout } from '../../modules/checkout/actions';
import {
    isCheckoutProcessStateAdvertise,
    isCheckoutProcessStateAdvertisePackage
} from '../../modules/checkout/utils/helpers';

// Types
import CheckoutHeader from '../../components/CheckoutHeader/CheckoutHeader';
import { getCheckoutProcessStateProcessStatus } from '../../modules/checkout/selectors';
import {
    CheckoutProcessState,
    CheckoutProcessState$Advertise$Product,
    CheckoutProcessState$BulkUp,
    ProcessStatus as CheckoutProcessStateProcessStatus
} from '../../modules/checkout/types';
import { Job } from '../../modules/jobs/types';
import { Recruiter } from '../../modules/recruiters/types';

type ConnectorProps = RouteComponentProps & {
    recruiter: Recruiter;
    checkoutProcessState: CheckoutProcessState;
    job: Job | null;
};

type ConnectedStateProps = {
    checkoutProcessStateProcessStatus: CheckoutProcessStateProcessStatus;
};
type ConnectedDispatchProps = {
    executeCheckout: typeof executeCheckout;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

class CheckoutConfirmPage extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        this.props.executeCheckout(this.props.checkoutProcessState);
    }

    render() {
        const { history, recruiter, checkoutProcessState, checkoutProcessStateProcessStatus, job } = this.props;

        if (!recruiter || !checkoutProcessState) {
            return null;
        }

        let submitButtonLabel: React.ReactNode = null;
        if (isCheckoutProcessStateAdvertise(checkoutProcessState)) {
            if (isCheckoutProcessStateAdvertisePackage(checkoutProcessState)) {
                submitButtonLabel = <FormattedMessage id="CHECKOUT_PAGE.ACTION.CONFIRM_PACKAGE_USAGE" />;
            } else {
                submitButtonLabel = <FormattedMessage id="CHECKOUT_PAGE.ACTION.CONFIRM_ADVERTISEMENT" />;
            }
        } else {
            submitButtonLabel = (
                <FormattedMessage
                    id="CHECKOUT_PAGE.ACTION.CONFIRM_BULK_UP"
                    values={{
                        packageTermSingular: <FormattedMessage id={'CONSTANT.PACKAGE_TERM.SINGULAR'} />
                    }}
                />
            );
        }

        let form: React.ReactNode = null;
        const isSubmitting = checkoutProcessStateProcessStatus === 'processing';
        if (isCheckoutProcessStateAdvertisePackage(checkoutProcessState)) {
            form = this.renderPackageUsageForm({
                history,
                checkoutProcessState,
                submitButtonLabel,
                isSubmitting
            });
        } else {
            console.log('renderAdvertisementForm');
            form = this.renderAdvertisementForm({
                history,
                recruiter,
                checkoutProcessState,
                submitButtonLabel,
                isSubmitting
            });
        }

        return (
            <React.Fragment>
                <CheckoutHeader
                    currentStep="finish"
                    recruiter={recruiter}
                    checkoutProcessState={checkoutProcessState}
                    job={job}
                />
                <div className="container">{form}</div>;
            </React.Fragment>
        );
    }

    renderAdvertisementForm({
        history,
        recruiter,
        checkoutProcessState,
        submitButtonLabel,
        isSubmitting
    }: {
        history: RouteComponentProps['history'];
        recruiter: Recruiter;
        checkoutProcessState: CheckoutProcessState$BulkUp | CheckoutProcessState$Advertise$Product;
        submitButtonLabel: React.ReactNode;
        isSubmitting: boolean;
    }) {
        return (
            <CheckoutConfirmAdvertisementForm
                checkoutProcessState={checkoutProcessState}
                vatRate={recruiter.applicable_tax_rate}
                submitButtonLabel={submitButtonLabel}
                onSubmit={this.handleSubmit}
                actions={this.renderActionBar({
                    history,
                    submitButtonLabel,
                    isSubmitting
                })}
            />
        );
    }

    renderPackageUsageForm({
        history,
        checkoutProcessState,
        submitButtonLabel,
        isSubmitting
    }: {
        history: RouteComponentProps['history'];
        checkoutProcessState: CheckoutProcessState;
        submitButtonLabel: React.ReactNode;
        isSubmitting: boolean;
    }) {
        return (
            <CheckoutConfirmPackageUsageForm
                checkoutProcessState={checkoutProcessState}
                onSubmit={this.handleSubmit}
                actions={this.renderActionBar({
                    history,
                    submitButtonLabel,
                    isSubmitting
                })}
            />
        );
    }

    renderActionBar({
        history,
        submitButtonLabel,
        isSubmitting
    }: {
        history: RouteComponentProps['history'];
        submitButtonLabel: React.ReactNode;
        isSubmitting: boolean;
    }) {
        const backAction = (
            <CheckoutActionBarButton onClick={() => history.goBack()}>
                <FormattedMessage id="CHECKOUT_PAGE.ACTION.BACK" />
            </CheckoutActionBarButton>
        );

        const submitAction = (
            <CheckoutActionBarButton type="submit" primary loading={isSubmitting}>
                {submitButtonLabel}
            </CheckoutActionBarButton>
        );

        return <CheckoutActionBar leftAction={backAction} rightAction={submitAction} />;
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => {
    const checkoutProcessStateId = props.checkoutProcessState.id;

    return {
        checkoutProcessStateProcessStatus: getCheckoutProcessStateProcessStatus(state, checkoutProcessStateId)
    };
};

const mapDispatchToProps: ConnectedDispatchProps = {
    executeCheckout
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutConfirmPage));
