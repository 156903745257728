import * as React from 'react';

import { connect } from 'react-redux';

import { getUser } from '../../modules/users/selectors';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { isAuthenticated as getIsAuthenticated } from '../../modules/authentication/selectors';
import { acceptAgreementContracts } from '../../modules/agreements/actions';
import { getLegalAgreementEnabled } from '../../modules/agreements/selectors';

import { AGREEMENT_CONTRACT_GTC } from '../../modules/agreements/constants';

import { openTutorial, completeTutorial } from './redux/actions';
import { getTutorialOpen, getCompleteTutorialUpdateStatus } from './redux/selectors';

import { shouldOpenTutorial, shouldShowGtc } from './utils';

import Breakpoint from '../../components/Breakpoint';
import ToggleAnimation from '../../components/ToggleAnimation';

import TutorialDesktop from './TutorialDesktop';
import TutorialMobile from './TutorialMobile';

import appearAnimation from './appearAnimation';

// Types
import { State as ApplicationState } from '../../store/reducer';
import { User } from '../../modules/users/types';
import { Recruiter } from '../../modules/recruiters/types';
import { CompleteTutorialUpdateStatus } from './redux/types';

type ConnectedStateProps = {
    user: User | null;
    recruiter: Recruiter | null;
    isAuthenticated: boolean;
    isLegalAgreementEnabled: boolean;

    isTutorialOpen: boolean;
    completeTutorialUpdateStatus: CompleteTutorialUpdateStatus;
};

type ConnectedDispatchProps = {
    acceptAgreementContracts: typeof acceptAgreementContracts;
    completeTutorial: typeof completeTutorial;
    openTutorial: typeof openTutorial;
};

type Props = ConnectedStateProps & ConnectedDispatchProps;

class Tutorial extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleAcceptGtc = this.handleAcceptGtc.bind(this);
    }

    componentDidUpdate() {
        const {
            user,
            isAuthenticated,
            recruiter,
            openTutorial,
            isTutorialOpen,
            completeTutorialUpdateStatus
        } = this.props;

        if (!isTutorialOpen && shouldOpenTutorial(user, isAuthenticated, recruiter, completeTutorialUpdateStatus)) {
            openTutorial();
        }
    }

    handleAcceptGtc() {
        this.props.acceptAgreementContracts([AGREEMENT_CONTRACT_GTC]);
    }

    render() {
        const { isTutorialOpen, user, isLegalAgreementEnabled, completeTutorial } = this.props;

        const showGtc = shouldShowGtc(user, isLegalAgreementEnabled);

        return (
            <Breakpoint
                on="sm"
                children={({ match }) => {
                    if (match) {
                        return (
                            <ToggleAnimation open={isTutorialOpen} {...appearAnimation}>
                                {(animationProps) => (
                                    <TutorialDesktop
                                        open={isTutorialOpen}
                                        animationProps={animationProps}
                                        showGtc={showGtc}
                                        acceptGtc={this.handleAcceptGtc}
                                        skipTutorial={completeTutorial}
                                        completeTutorial={completeTutorial}
                                    />
                                )}
                            </ToggleAnimation>
                        );
                    } else {
                        return (
                            <ToggleAnimation open={isTutorialOpen} {...appearAnimation}>
                                {(animationProps) => (
                                    <TutorialMobile
                                        open={isTutorialOpen}
                                        animationProps={animationProps}
                                        showGtc={showGtc}
                                        acceptGtc={this.handleAcceptGtc}
                                        skipTutorial={completeTutorial}
                                        completeTutorial={completeTutorial}
                                    />
                                )}
                            </ToggleAnimation>
                        );
                    }
                }}
            />
        );
    }
}

function mapStateToProps(state: ApplicationState): ConnectedStateProps {
    return {
        user: getUser(state),
        recruiter: getRecruiter(state),
        isAuthenticated: getIsAuthenticated(state),
        isLegalAgreementEnabled: getLegalAgreementEnabled(state),

        isTutorialOpen: getTutorialOpen(state),
        completeTutorialUpdateStatus: getCompleteTutorialUpdateStatus(state)
    };
}

const mapDispatchToProps: ConnectedDispatchProps = {
    acceptAgreementContracts,
    openTutorial,
    completeTutorial
};

export default connect(mapStateToProps, mapDispatchToProps)(Tutorial);
