import * as React from 'react';

import './MessageItemText.style.scss';

import MessageItemWrapper from '../MessageItemWrapper';

import { ConversationParticipant } from '../../modules/conversations/types';
import { textToHtml } from '../../utils/html';

type Props = {
    text: string;
    fromMe?: boolean;
    owner: ConversationParticipant;
};

const MessageItemText = ({ text, owner, fromMe }: Props) => {
    const formattedText = textToHtml(text);

    return (
        <MessageItemWrapper className="MessageItemText" fromMe={fromMe} owner={owner}>
            <div
                className="MessageItemText__content"
                dangerouslySetInnerHTML={{
                    __html: formattedText
                }}
            />
        </MessageItemWrapper>
    );
};

export default MessageItemText;
