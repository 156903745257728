import * as React from 'react';
import classNames from 'classnames';

import useBreakpointMatch, { Match } from '../../hooks/useBreakpointMatch';

import classes from './CheckoutActionBar.module.scss';

export type CheckoutActionBarProps = {
    leftAction?: React.ReactElement;
    rightAction?: React.ReactElement;
};

function CheckoutActionBar({ leftAction, rightAction }: CheckoutActionBarProps) {
    const match: Match | null = useBreakpointMatch('sm');

    if (!match) {
        return <CheckoutActionBarSmallScreen leftAction={leftAction} rightAction={rightAction} />;
    }

    return <CheckoutActionBarLargeScreen leftAction={leftAction} rightAction={rightAction} />;
}

export default CheckoutActionBar;

type CheckoutActionBarSmallScreenProps = {
    leftAction?: React.ReactElement;
    rightAction?: React.ReactElement;
};

function CheckoutActionBarSmallScreen({ leftAction, rightAction }: CheckoutActionBarSmallScreenProps) {
    return (
        <React.Fragment>
            <div className={classNames(classes.root, classes.fixed)}>
                <div className="container clearfix">
                    {!!leftAction && <div className="pull-left">{leftAction}</div>}
                    {!!rightAction && <div className="pull-right">{rightAction}</div>}
                </div>
            </div>
            <div className={classes.spacer}></div>
        </React.Fragment>
    );
}

type CheckoutActionBarLargeScreenProps = {
    leftAction?: React.ReactElement;
    rightAction?: React.ReactElement;
};

function CheckoutActionBarLargeScreen({ leftAction, rightAction }: CheckoutActionBarLargeScreenProps) {
    return (
        <div className={classNames(classes.root, classes.inline)}>
            <div className="row">
                <div className="col-md-8 col-md-offset-2">
                    {!!leftAction && <div className="pull-left">{leftAction}</div>}
                    {!!rightAction && <div className="pull-right">{rightAction}</div>}
                </div>
            </div>
        </div>
    );
}
