import equal from 'deep-equal';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomActionBar from '../BottomActionBar';
import Button from '../Button';
import MobilePageContainer from '../MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../NavigationBar';
import scrolled, { ScrolledProps } from '../Scrolled';
import FilterSection from './FilterSection';
import OrderingSection from './OrderingSection';
import './style.scss';

type Values = { [name: string]: any };

type Props = ScrolledProps & {
    id: string;

    open: boolean;
    onClose: () => void;

    children: Array<React.ReactElement<typeof FilterSection> | React.ReactElement<typeof OrderingSection> | null>;

    values: Values;
    onChange: (values: Values) => void;
};

type State = {
    values: Values;
};

type Handlers = {
    handleChangeSection: { [name: string]: (value: unknown) => void };
};

class FilterAndOrderingPageMobile extends React.Component<Props, State> {
    handlers: Handlers;

    constructor(props: Props) {
        super(props);

        this.state = {
            values: {}
        };

        this.handlers = {
            handleChangeSection: {}
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.updateHandlers(this.props.children);

        this.setState({ values: this.props.values });
    }

    componentDidUpdate(prevProps: Props) {
        const nextChildren = this.props.children;

        this.updateHandlers(nextChildren);

        const prevOpen = prevProps.open;
        const nextOpen = this.props.open;

        const prevValues = prevProps.values;
        const nextValues = this.props.values;

        if (!equal(prevValues, nextValues) || (!prevOpen && nextOpen)) {
            this.setState({ values: nextValues });
        }
    }

    updateHandlers(children: React.ReactNode) {
        const childNodes = React.Children.toArray(children) as React.ReactElement[];

        this.handlers.handleChangeSection = childNodes.reduce((handlers, element) => {
            const name = element.props.name;

            if (!!handlers[name]) {
                return handlers;
            }

            return {
                ...handlers,
                [name]: this.handleChangeSection.bind(this, name)
            };
        }, this.handlers.handleChangeSection);
    }

    handleSubmit(event: React.FormEvent) {
        event.preventDefault();

        this.props.onChange(this.state.values);
    }

    handleChangeSection(name: string, value: any) {
        this.setState({
            values: {
                ...this.state.values,
                [name]: value
            }
        });
    }

    render() {
        const {
            id,

            open,
            onClose,

            children,

            scrolled,
            onScroll
        } = this.props;
        const { values } = this.state;
        const { handleChangeSection } = this.handlers;

        const childNodes = React.Children.toArray(children) as React.ReactElement[];

        const sectionElements = childNodes.map((element) => {
            const name = element.props.name;

            return React.cloneElement(element as React.ReactElement, {
                id: `${id}_section-${name}`,
                value: values[name],
                onChange: handleChangeSection[name]
            });
        });

        return (
            <MobilePageContainer show={open}>
                <NavigationBar
                    noShadow={!scrolled}
                    centerElement={
                        <NavigationBarText>
                            <FormattedMessage id="FILTER_AND_ORDERING_PAGE_MOBILE.TITLE" />
                        </NavigationBarText>
                    }
                    rightElement={<NavigationBarCloseAction onClick={onClose} />}
                />

                <form className="FilterAndOrderingPageMobile__form" onSubmit={this.handleSubmit}>
                    <div className="FilterAndOrderingPageMobile__content" onScroll={onScroll}>
                        {sectionElements}
                    </div>

                    <BottomActionBar>
                        <Button type="submit" typeStyle="flat" variationStyle="brand">
                            <FormattedMessage id="FILTER_AND_ORDERING_PAGE_MOBILE.ACTION_SUBMIT" />
                        </Button>
                    </BottomActionBar>
                </form>
            </MobilePageContainer>
        );
    }
}

export default scrolled(FilterAndOrderingPageMobile);
