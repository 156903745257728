import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import notification2IllustrationSrc from '../../assets/images/illustrations/notifications-2.svg';
import Button from '../../components/Button';
import UnknownErrorPage from '../../components/UnknownErrorPage';
import { CheckoutProcessState } from '../../modules/checkout/types';
import { isCheckoutProcessStateAdvertise, isCheckoutProcessStateBulkUp } from '../../modules/checkout/utils';
import { Job } from '../../modules/jobs/types';
import { Recruiter } from '../../modules/recruiters/types';
import { getJobsRoute } from '../../routes';
import { hasClipboard } from '../../utils/clipboard';
import LoadingPage from '../LoadingPage';

import styles from './CheckoutSuccessPage.module.scss';

export type CheckoutSuccessPageProps = {
    recruiter: Recruiter | null;
    checkoutProcessState: CheckoutProcessState;
    job: Job | null;
    jobIsLoading: boolean;
    onCopyComplete?: () => void;
};

function CheckoutSuccessPage({
    recruiter,
    checkoutProcessState,
    job,
    jobIsLoading,
    onCopyComplete
}: CheckoutSuccessPageProps) {
    const handleClickCopyLink = (event: React.MouseEvent) => {
        event.preventDefault();

        if (!hasClipboard() || !job) {
            return;
        }

        navigator.clipboard.writeText(job.static_url);
        onCopyComplete?.();
    };

    const jobLoadingFailed = !job && !jobIsLoading;

    if (!recruiter || (isCheckoutProcessStateAdvertise(checkoutProcessState) && jobLoadingFailed)) {
        return <UnknownErrorPage />;
    }

    if (isCheckoutProcessStateAdvertise(checkoutProcessState) && (!job || jobIsLoading)) {
        return <LoadingPage />;
    }

    return (
        <div
            className={classNames(styles.root, {
                [styles.noPreview]: isCheckoutProcessStateBulkUp(checkoutProcessState)
            })}
        >
            <div className={styles.content}>
                <h1 className={styles.headline}>
                    {!!job && <FormattedMessage id={`CHECKOUT_SUCCESS_PAGE.HEADLINE.job`} />}
                    {!job && <FormattedMessage id={`CHECKOUT_SUCCESS_PAGE.HEADLINE.generic`} />}
                </h1>

                {!job && isCheckoutProcessStateBulkUp(checkoutProcessState) && (
                    <p className={styles.paragraph}>
                        <FormattedMessage id="CHECKOUT_SUCCESS_PAGE.BULK_UP_TEXT" />
                    </p>
                )}

                {job && !isCheckoutProcessStateBulkUp(checkoutProcessState) && (
                    <img
                        src={notification2IllustrationSrc}
                        className={styles.illustration}
                        alt=""
                        data-testid="illustration"
                    />
                )}

                <div className={styles.footer}>
                    {job && !isCheckoutProcessStateBulkUp(checkoutProcessState) && (
                        <React.Fragment>
                            <p className={styles.footerText}>
                                <FormattedMessage
                                    id="CHECKOUT_SUCCESS_PAGE.OPEN_JOB_TEXT"
                                    values={{
                                        AppJobLink: (chunks) => (
                                            <a
                                                href={job.static_url}
                                                target="_blank"
                                                rel="noopener"
                                                data-testid="job-link"
                                            >
                                                {chunks}
                                            </a>
                                        )
                                    }}
                                />
                            </p>
                            {hasClipboard() && (
                                <p className={styles.footerText}>
                                    <FormattedMessage
                                        id="CHECKOUT_SUCCESS_PAGE.COPY_JOB_URL_TEXT"
                                        values={{
                                            CopyJobUrlButton: (chunks) => (
                                                <button
                                                    type="button"
                                                    className={styles.copyUrlButton}
                                                    onClick={handleClickCopyLink}
                                                    data-testid="copy-job-url-button"
                                                >
                                                    {chunks}
                                                </button>
                                            )
                                        }}
                                    />
                                </p>
                            )}
                        </React.Fragment>
                    )}
                    <Button
                        component={Link}
                        to={getJobsRoute()}
                        typeStyle="raised"
                        variationStyle="brand"
                        className={styles.goToStartPageLink}
                        data-testid="start-page-link"
                    >
                        <FormattedMessage id="CHECKOUT_SUCCESS_PAGE.BACK_TO_START_PAGE_LINK.LABEL_TEXT" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default CheckoutSuccessPage;
