import { all, take, put, call, cancel, fork, spawn, race, select, delay } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getConversationMessages } from './selectors';
import {
    START_MESSAGES_POLLING,
    STOP_MESSAGES_POLLING,
    UPDATE_CONVERSATION,
    SEND_CONVERSATION_MESSAGE,
    FETCH_CONVERSATION,
    sendConversationText,
    sendConversationAttachment,
    conversationFetch,
    fetchConversationMessages,
    conversationUpdate,
    conversationMessageSend,
    conversationAttachmentUpload,
    conversationPolling,
    conversationMessagesPolling
} from './actions';

import * as errorService from '../../services/errorHandler';
import { ApiEntityResponse, ApiError, ApiListResponse } from '../../services/api/types';

import * as conversationsApi from './api';
import { MessagesQueryParams } from './api';
import * as filesApi from '../files/api';

import * as conversationsDraftService from '../../services/conversation/draft';

import createConcurrentTaskQueue from './queue';
import { createPlaceholderTextMessage, createPlaceholderAttachmentMessage } from './utils';
import { Conversation, ConversationId, Message, PlaceholderTextMessage, PlaceholderAtachmentMessage } from './types';

import { MESSAGE_TYPES } from './constants';
import { isApiResponseError } from '../../services/api/utils';
import { ActionCreatorWithPayload } from '../../utils/redux';

type LoadConversationRequestActionCreators = {
    request: ActionCreatorWithPayload<unknown, unknown, [ConversationId], string>;
    success: ActionCreatorWithPayload<unknown, unknown, [ConversationId, ApiEntityResponse<Conversation>], string>;
    failure: ActionCreatorWithPayload<unknown, unknown, [ConversationId, ApiError[]], string>;
};

function* loadConversation(
    conversationId: ConversationId,
    requestActionCreators: LoadConversationRequestActionCreators = conversationFetch
): SagaIterator {
    try {
        yield put(requestActionCreators.request(conversationId));

        const response: ApiEntityResponse<Conversation> = yield call(
            conversationsApi.fetchConversation,
            conversationId
        );

        yield put(requestActionCreators.success(conversationId, response));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(requestActionCreators.failure(conversationId, error.response.data.errors || []));
        } else {
            throw error;
        }
    }
}

function* pollConversation(conversationId: ConversationId): SagaIterator {
    return yield call(loadConversation, conversationId, conversationPolling);
}

function* loadConversationMessages(
    conversationId: ConversationId,
    requestActionCreators = fetchConversationMessages
): SagaIterator {
    try {
        yield put(requestActionCreators.request(conversationId));

        const queryParams: MessagesQueryParams = {
            ordering: '-created'
        };

        const isPolling = requestActionCreators === conversationMessagesPolling;
        if (isPolling) {
            const messages = yield select(getConversationMessages, conversationId);
            if (messages.length > 0) {
                queryParams.newerThan = messages[messages.length - 1].created;
            }
        }

        const response: ApiListResponse<Conversation> = yield call(
            conversationsApi.fetchConversationMessages,
            conversationId,
            queryParams
        );

        yield put(requestActionCreators.success(conversationId, response));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(requestActionCreators.failure(conversationId, error.response.data.errors || []));
        } else {
            throw error;
        }
    }
}

function* pollConversationMessages(conversationId: ConversationId): SagaIterator {
    return yield call(loadConversationMessages, conversationId, conversationMessagesPolling);
}

function* startMessagesPolling(conversationId: ConversationId) {
    let errorsCatched = 0;

    // Initial loading
    try {
        yield call(loadConversation, conversationId);
        yield call(loadConversationMessages, conversationId);
    } catch (error) {
        throw error;
    }

    // Polling
    while (errorsCatched < 3) {
        yield delay(5000);

        try {
            yield call(pollConversation, conversationId);
            yield call(pollConversationMessages, conversationId);
            errorsCatched = 0;
        } catch (error) {
            errorsCatched++;
        }
    }
}

export function* updateConversation(
    conversationId: ConversationId,
    newConversation: Partial<Conversation>
): SagaIterator {
    try {
        yield put(conversationUpdate.request(conversationId, newConversation));

        const response: ApiEntityResponse<Conversation> = yield call(
            conversationsApi.updateConversation,
            conversationId,
            newConversation
        );

        yield put(conversationUpdate.success(conversationId, response));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(conversationUpdate.failure(conversationId, error.response.data.errors || []));
        } else {
            errorService.handleError(error);
        }
    }
}

export function* sendMessage({
    conversationId,
    message,
    updateLastRead = false
}: {
    conversationId: ConversationId;
    message: Partial<PlaceholderTextMessage | PlaceholderAtachmentMessage>;
    updateLastRead?: boolean;
}): SagaIterator {
    try {
        yield put(conversationMessageSend.request(conversationId, message));

        let response: ApiEntityResponse<Message> | null = null;
        if (message.message_type === MESSAGE_TYPES.TEXT) {
            const textMessage = message as PlaceholderTextMessage;
            response = yield call(conversationsApi.sendConversationMessage, conversationId, {
                text: textMessage.text
            });
        } else if (message.message_type === MESSAGE_TYPES.ATTACHMENT) {
            // TODO: We have to redo this part with more specific types
            const attachmentMessage = message as PlaceholderAtachmentMessage;
            const file: File = (attachmentMessage.extra_data as Exclude<
                PlaceholderAtachmentMessage['extra_data'],
                undefined
            >).file;
            const attachmentId = yield call(uploadAttachment, file);
            response = yield call(conversationsApi.sendConversationMessage, conversationId, {
                attachment: attachmentId
            });
        }

        yield call(conversationsDraftService.removeDraft, conversationId);

        if (updateLastRead && !!response) {
            const createdMessage: Message = response.data;
            const conversationUpdates: Partial<Conversation> = {
                last_read: createdMessage.created
            };

            yield call(updateConversation, conversationId, conversationUpdates);
        }

        yield put(conversationMessageSend.success(conversationId, message.id, response));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(conversationMessageSend.failure(conversationId, error.response.data.errors || []));
        } else {
            throw error;
        }
    }
}

export function* uploadAttachment(file: File): SagaIterator<number> {
    try {
        yield put(conversationAttachmentUpload.request(file));

        const response = yield call(filesApi.uploadFile, file);

        yield put(conversationAttachmentUpload.success(file, response));

        const attachmentId: number = response.data.id;

        return attachmentId;
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(conversationAttachmentUpload.failure(file, error.response.data.errors || []));
        }

        throw error;
    }
}

/*
 *   Watchers
 */

function* watchStartMessagesPolling(): SagaIterator {
    const tasks = {};

    while (true) {
        const { startAction, stopAction } = yield race({
            startAction: take(START_MESSAGES_POLLING),
            stopAction: take(STOP_MESSAGES_POLLING)
        });

        if (!!startAction) {
            const { conversationId } = startAction.payload;
            const taskId = conversationId;

            if (!tasks[taskId]) {
                tasks[taskId] = yield fork(startMessagesPolling, conversationId);
            }
        } else {
            const { conversationId } = stopAction.payload;
            const taskId = conversationId;

            if (!!tasks[taskId]) {
                yield cancel(tasks[taskId]);
                tasks[taskId] = null;
            }
        }
    }
}

function* watchFetchConversation(): SagaIterator {
    while (true) {
        const action = yield take(FETCH_CONVERSATION);

        const { conversationId } = action.payload;

        yield call(loadConversation, conversationId);
    }
}

function* watchUpdateConversation(): SagaIterator {
    while (true) {
        try {
            const action = yield take(UPDATE_CONVERSATION);
            const { conversationId, newConversation } = action.payload;

            yield call(updateConversation, conversationId, newConversation);
        } catch (error) {
            errorService.handleError(error);
        }
    }
}

export function* watchSendConversationMessage(): SagaIterator {
    // Create queue for handling sending of messages
    const QUEUE_WORKERS_COUNT = 1;
    const { watcher, queueChannel } = yield createConcurrentTaskQueue(sendMessage, QUEUE_WORKERS_COUNT);

    // Start listening to tasks
    yield fork(watcher);

    // Enqueue incoming messages
    while (true) {
        const action = yield take(SEND_CONVERSATION_MESSAGE);

        const { conversationId, ownerId, messageText, attachments, updateLastRead } = action.payload;

        // Queue up text message
        if (messageText.length > 0) {
            const textMessage = createPlaceholderTextMessage(ownerId, messageText);
            const textAction = sendConversationText(conversationId, textMessage, updateLastRead);

            yield put(queueChannel, textAction);
        }

        // Queue up all attachments messages
        const attachmentActions = attachments.map((attachment: File) => {
            const attachmentMessage = createPlaceholderAttachmentMessage(ownerId, attachment);
            const attachmentAction = sendConversationAttachment(conversationId, attachmentMessage, updateLastRead);

            return put(queueChannel, attachmentAction);
        });
        yield all(attachmentActions);
    }
}

export default function* root(): SagaIterator {
    try {
        yield all([
            spawn(watchFetchConversation),
            spawn(watchStartMessagesPolling),
            spawn(watchUpdateConversation),
            spawn(watchSendConversationMessage)
        ]);
    } catch (error) {
        errorService.handleError(error);
    }
}
