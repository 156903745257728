import * as React from 'react';
import { connect } from 'react-redux';
import { loadJobs, copyJob } from '../../modules/jobs/actions';
import { getFetchStatusByList, getTotalCountByList, getPageByList, getJobsByList } from '../../modules/jobs/selectors';
import { parseSearch, stringifySearch } from '../../utils/url';
import CopyJobList from '../../components/CopyJobList';
import Pagination from '../../components/Pagination';
import { isFetchStatusLoading } from '../../services/api/utils';

import './style.scss';

const SKELETON_JOBS = [null, null, null];
const DEFAULT_PAGE_SIZE = 10;

class JobCreateSectionCopy extends React.Component {
    constructor() {
        super();

        this.state = {
            showPlaceholder: true
        };

        this.handleCopyJob = this.handleCopyJob.bind(this);
        this.getPaginationLinkProps = this.getPaginationLinkProps.bind(this);
        this.getSearchParamPage = this.getSearchParamPage.bind(this);
        this.loadJobs = this.loadJobs.bind(this);
    }

    componentDidMount() {
        this.props.loadJobs('copyable');
        this.setState({
            showPlaceholder: isFetchStatusLoading(this.props.fetchStatus)
        });
    }

    componentDidUpdate(prevProps) {
        const wasLoading = isFetchStatusLoading(prevProps.fetchStatus);
        const isLoading = isFetchStatusLoading(this.props.fetchStatus);

        if (prevProps.fetchStatus !== this.props.fetchStatus) {
            this.setState({
                showPlaceholder: wasLoading && isLoading
            });
        }

        if (prevProps.location.search !== this.props.location.search) {
            this.loadJobs();
        }
    }

    loadJobs() {
        const newParams = parseSearch(this.props.location.search);

        this.props.loadJobs('copyable', newParams);
    }

    handleCopyJob(jobId) {
        this.props.copyJob(jobId);
    }

    getSearchParamPage(props) {
        if (!!props.location.search) {
            return parseInt(parseSearch(props.location.search).page);
        } else {
            return 1;
        }
    }

    getPaginationLinkProps(page) {
        const location = this.props.location;
        const searchString = stringifySearch({ page });
        const to = {
            pathname: location.pathname,
            search: searchString,
            hash: location.hash
        };

        return { to };
    }

    render() {
        const { totalCount, fetchStatus } = this.props;
        const loading = fetchStatus === 'loading';

        const copyJobList = this.state.showPlaceholder ? (
            <CopyJobList skeleton jobs={SKELETON_JOBS} />
        ) : (
            <CopyJobList jobs={this.props.jobs} onClickCopy={this.handleCopyJob} isLoading={loading} />
        );

        const countOfPages = Math.ceil(totalCount / DEFAULT_PAGE_SIZE);
        const activePage = this.getSearchParamPage(this.props);

        const pagination =
            !this.state.showPlaceholder && countOfPages > 1 ? (
                <Pagination
                    center
                    countOfPages={countOfPages}
                    activePage={activePage}
                    getLinkProps={this.getPaginationLinkProps}
                />
            ) : null;

        return (
            <div className="JobCreateSectionCopy">
                {copyJobList}
                {pagination}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    fetchStatus: getFetchStatusByList(state, 'copyable'),
    jobs: getJobsByList(state, 'copyable'),
    totalCount: getTotalCountByList(state, 'copyable'),
    currentPage: getPageByList(state, 'copyable')
});

const mapDispatchToProps = {
    loadJobs,
    copyJob
};

export default connect(mapStateToProps, mapDispatchToProps)(JobCreateSectionCopy);
