import * as React from 'react';
import classNames from 'classnames';
import { Input } from '@truffls/design-system-react';
import { useFieldArray } from 'react-final-form-arrays';

import { Values } from '../../../../types/utilities';

import Button from '../../../../components/Button';
import Autocomplete from '../../../../components/Autocomplete';

import { ERRORS } from './JobFormRequirementsCreateFormField.constants';
import { validate } from './JobFormRequirementsCreateFormField.utils';

import './JobFormRequirementsCreateFormField.scss';

export type JobFormRequirementsCreateFormFieldProps = {
    name: string;

    errorMessages: {
        [K in Values<typeof ERRORS>]?: React.ReactNode;
    };

    placeholderText?: string;
    selectableItems?: string[];

    [propName: string]: any;
};

function JobFormRequirementsCreateFormField({
    name,

    errorMessages,
    placeholderText,
    selectableItems,

    className,
    ...restProps
}: JobFormRequirementsCreateFormFieldProps) {
    const { fields } = useFieldArray(name);

    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState<Values<typeof ERRORS> | null>(null);

    const submit = () => {
        const cleanValue = value.trim();

        if (cleanValue.length === 0) {
            return;
        }

        const error = validate(cleanValue, {
            items: fields.value ?? []
        });

        if (!!error) {
            setError(error);
            return;
        }

        fields.push(cleanValue);

        setValue('');
    };

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();

        setError(null);
        setValue(event.target.value);
    };

    const onKeyPressInput = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            submit();
        }
    };

    const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        submit();
    };

    const onChangeAutocompleteInputValue = (value: string) => {
        setError(null);
        setValue(value);
    };

    const onChangeAutocomplete = () => {
        submit();
    };

    const renderInput = ({ getInputProps }) => {
        const inputProps = getInputProps({
            type: 'text',
            id: `job-form-field-input-${name}`,
            'data-testid': 'job-requirements-form-input',
            placeholder: placeholderText
        });

        return <Input {...inputProps} />;
    };

    let input: React.ReactNode = null;
    if (!!selectableItems) {
        input = (
            <Autocomplete
                selectableItems={selectableItems}
                onChange={onChangeAutocomplete}
                defaultHighlightedIndex={0}
                inputValue={value}
                onInputValueChange={onChangeAutocompleteInputValue}
                selectedItem={null}
            >
                {renderInput}
            </Autocomplete>
        );
    } else {
        const getInputProps = (propsToMerge = {}) => ({
            value: value,
            onChange: onChangeInput,
            onKeyPress: onKeyPressInput,
            ...propsToMerge
        });

        input = renderInput({
            getInputProps
        });
    }

    return (
        <React.Fragment>
            <div className={classNames('JobFormRequirementsCreateFormField', className)} {...restProps}>
                <div className="JobFormRequirementsCreateFormField__input">{input}</div>
                <Button
                    type="button"
                    typeStyle="raised"
                    variationStyle="brand"
                    onClick={onClickButton}
                    className="JobFormRequirementsCreateFormField__submit-button"
                    data-testid="job-requirements-form-submit"
                >
                    <svg
                        className="JobFormRequirementsCreateFormField__submit-button-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fillRule="evenodd"
                            d="M1.99999995,11.9999999 C1.99999995,12.6903559 2.55964402,13.25 3.24999996,13.25 L10.5416667,13.25 C10.656726,13.25 10.75,13.343274 10.75,13.4583333 L10.75,20.75 C10.75,21.440356 11.3096441,21.9999999 11.9999999,21.9999999 C12.6903559,21.9999999 13.25,21.440356 13.25,20.75 L13.25,13.4583333 C13.25,13.343274 13.343274,13.25 13.4583333,13.25 L20.75,13.25 C21.440356,13.25 21.9999999,12.6903559 21.9999999,11.9999999 C21.9999999,11.3096441 21.440356,10.75 20.75,10.75 L13.4583333,10.75 C13.343274,10.75 13.25,10.656726 13.25,10.5416667 L13.25,3.24999996 C13.25,2.55964402 12.6903559,1.99999995 11.9999999,1.99999995 C11.3096441,1.99999995 10.75,2.55964402 10.75,3.24999996 L10.75,10.5416667 C10.75,10.656726 10.656726,10.75 10.5416667,10.75 L3.24999996,10.75 C2.55964402,10.75 1.99999995,11.3096441 1.99999995,11.9999999 Z"
                        />
                    </svg>
                </Button>
            </div>
            {error === ERRORS.DUPLICATE && (
                <div className="alert alert-danger" data-testid="job-requirements-form-error">
                    {errorMessages[error]}
                </div>
            )}
        </React.Fragment>
    );
}

export default JobFormRequirementsCreateFormField;
