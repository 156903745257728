import { combineReducers } from 'redux';
import resetOnLogout from '../authentication/reducer/resetOnLogout';
import {
    SET_RECRUITER,
    RECRUITER_FETCH,
    RECRUITER_UPDATE,
    RECRUITER_PORTRAIT_UPDATE,
    RECRUITER_COMPANY_LOGO_UPDATE,
    RECRUITER_COMPANY_BACKGROUND_UPDATE,
    RECRUITER_COMPANY_GALLERY_IMAGE_CREATE,
    REMOVE_CREATE_RECRUITER_COMPANY_GALLERY_IMAGE_LOCAL_ID,
    RECRUITER_COMPANY_GALLERY_IMAGE_DELETE,
    REMOVE_DELETE_RECRUITER_COMPANY_GALLERY_IMAGE_ID
} from './actions';

// We want to mount our reducer here
export const MOUNT: 'recruiter' = 'recruiter';

import { CREATE_STATUS, DELETE_STATUS, UPDATE_STATUS } from '../constants';
import { Action, CreateStatus, DeleteStatus, UpdateStatus } from '../types';
import { GalleryImageId, Recruiter } from './types';
import { createMapReducer } from '../../utils/redux';
import { omit } from '../../utils/object';

type EntityState = Recruiter | null;
type UpdateStatusState = UpdateStatus;
type PortraitUpdateStatusState = UpdateStatus;
type PortraitUpdateErrorsState = unknown[]; // TODO: Specify errors
type CompanyLogoUpdateStatusState = UpdateStatus;
type CompanyLogoUpdateErrorsState = unknown[]; // TODO: Specify errors
type CompanyBackgroundUpdateStatusState = UpdateStatus;
type CompanyBackgroundUpdateErrorsState = unknown[]; // TODO: Specify errors
type CompanyGalleryImageIdState = GalleryImageId | null;
type CompanyGalleryImageCreateStatusState = CreateStatus;
type CompanyGalleryImageCreateErrorsState = unknown[]; // TODO: Specify errors
type CompanyGalleryImagesCreationState = {
    id: CompanyGalleryImageIdState;
    status: CompanyGalleryImageCreateStatusState;
    errors: CompanyGalleryImageCreateErrorsState;
};
type CompanyGalleryImagesCreationsState = {
    [localCreateId: string]: CompanyGalleryImagesCreationState;
};
type CompanyGalleryImageDeleteStatusState = DeleteStatus;
type CompanyGalleryImageDeleteErrorsState = unknown[]; // TODO: Specify errors
type CompanyGalleryImagesDeletionState = {
    status: CompanyGalleryImageDeleteStatusState;
    errors: CompanyGalleryImageDeleteErrorsState;
};
type CompanyGalleryImagesDeletionsState = {
    [id: string]: CompanyGalleryImagesDeletionState;
};

export type RecruiterState = {
    entity: EntityState;
    updateStatus: UpdateStatusState;
    portraitUpdateStatus: PortraitUpdateStatusState;
    portraitUpdateErrors: PortraitUpdateErrorsState;
    companyLogoUpdateStatus: CompanyLogoUpdateStatusState;
    companyLogoUpdateErrors: CompanyLogoUpdateErrorsState;
    companyBackgroundUpdateStatus: CompanyBackgroundUpdateStatusState;
    companyBackgroundUpdateErrors: CompanyBackgroundUpdateErrorsState;
    companyGalleryImagesCreations: CompanyGalleryImagesCreationsState;
    companyGalleryImagesDeletions: CompanyGalleryImagesDeletionsState;
};

export type State = {
    recruiter: RecruiterState;
};

export function entityReducer(state: EntityState = null, action: Action) {
    switch (action.type) {
        case SET_RECRUITER:
        case RECRUITER_FETCH.SUCCESS:
        case RECRUITER_UPDATE.SUCCESS:
        case RECRUITER_PORTRAIT_UPDATE.SUCCESS:
        case RECRUITER_COMPANY_LOGO_UPDATE.SUCCESS:
        case RECRUITER_COMPANY_BACKGROUND_UPDATE.SUCCESS:
        case RECRUITER_COMPANY_GALLERY_IMAGE_CREATE.SUCCESS:
        case RECRUITER_COMPANY_GALLERY_IMAGE_DELETE.SUCCESS:
            return action.payload.recruiter;

        default:
            return state;
    }
}

export function updateStatusReducer(state: UpdateStatusState = 'none', action: Action): UpdateStatusState {
    switch (action.type) {
        case RECRUITER_UPDATE.REQUEST:
            return 'updating';
        case RECRUITER_UPDATE.SUCCESS:
            return 'updated';
        case RECRUITER_UPDATE.FAILURE:
            return 'failed';

        default:
            return state;
    }
}

export const initialPortraitUpdateStatusState: PortraitUpdateStatusState = UPDATE_STATUS.NONE;
export function portraitUpdateStatusReducer(
    state: PortraitUpdateStatusState = initialPortraitUpdateStatusState,
    action: Action
): PortraitUpdateStatusState {
    switch (action.type) {
        case RECRUITER_PORTRAIT_UPDATE.REQUEST:
            return UPDATE_STATUS.UPDATING;

        case RECRUITER_PORTRAIT_UPDATE.SUCCESS:
            return UPDATE_STATUS.UPDATED;

        case RECRUITER_PORTRAIT_UPDATE.FAILURE:
            return UPDATE_STATUS.FAILED;

        default:
            return state;
    }
}

export const initialPortraitUpdateErrorsState: PortraitUpdateErrorsState = [];
export function portraitUpdateErrorsReducer(
    state: PortraitUpdateErrorsState = initialPortraitUpdateErrorsState,
    action: Action
): PortraitUpdateErrorsState {
    switch (action.type) {
        case RECRUITER_PORTRAIT_UPDATE.REQUEST:
            return initialPortraitUpdateErrorsState;

        case RECRUITER_PORTRAIT_UPDATE.FAILURE:
            return action.payload.errors;

        default:
            return state;
    }
}

export const initialCompanyLogoUpdateStatusState: CompanyLogoUpdateStatusState = UPDATE_STATUS.NONE;
export function companyLogoUpdateStatusReducer(
    state: CompanyLogoUpdateStatusState = initialCompanyLogoUpdateStatusState,
    action: Action
): CompanyLogoUpdateStatusState {
    switch (action.type) {
        case RECRUITER_COMPANY_LOGO_UPDATE.REQUEST:
            return UPDATE_STATUS.UPDATING;

        case RECRUITER_COMPANY_LOGO_UPDATE.SUCCESS:
            return UPDATE_STATUS.UPDATED;

        case RECRUITER_COMPANY_LOGO_UPDATE.FAILURE:
            return UPDATE_STATUS.FAILED;

        default:
            return state;
    }
}

export const initialCompanyLogoUpdateErrorsState: CompanyLogoUpdateErrorsState = [];
export function companyLogoUpdateErrorsReducer(
    state: CompanyLogoUpdateErrorsState = initialCompanyLogoUpdateErrorsState,
    action: Action
): CompanyLogoUpdateErrorsState {
    switch (action.type) {
        case RECRUITER_COMPANY_LOGO_UPDATE.REQUEST:
            return initialCompanyLogoUpdateErrorsState;

        case RECRUITER_COMPANY_LOGO_UPDATE.FAILURE:
            return action.payload.errors;

        default:
            return state;
    }
}

export const initialCompanyBackgroundUpdateStatusState: CompanyBackgroundUpdateStatusState = UPDATE_STATUS.NONE;
export function companyBackgroundUpdateStatusReducer(
    state: CompanyBackgroundUpdateStatusState = initialCompanyBackgroundUpdateStatusState,
    action: Action
): CompanyBackgroundUpdateStatusState {
    switch (action.type) {
        case RECRUITER_COMPANY_BACKGROUND_UPDATE.REQUEST:
            return UPDATE_STATUS.UPDATING;

        case RECRUITER_COMPANY_BACKGROUND_UPDATE.SUCCESS:
            return UPDATE_STATUS.UPDATED;

        case RECRUITER_COMPANY_BACKGROUND_UPDATE.FAILURE:
            return UPDATE_STATUS.FAILED;

        default:
            return state;
    }
}

export const initialCompanyBackgroundUpdateErrorsState: CompanyBackgroundUpdateErrorsState = [];
export function companyBackgroundUpdateErrorsReducer(
    state: CompanyBackgroundUpdateErrorsState = initialCompanyBackgroundUpdateErrorsState,
    action: Action
): CompanyBackgroundUpdateErrorsState {
    switch (action.type) {
        case RECRUITER_COMPANY_BACKGROUND_UPDATE.REQUEST:
            return initialCompanyBackgroundUpdateErrorsState;

        case RECRUITER_COMPANY_BACKGROUND_UPDATE.FAILURE:
            return action.payload.errors;

        default:
            return state;
    }
}

export const initialCompanyGalleryImageCreateIdState: CompanyGalleryImageIdState = null;
export function companyGalleryImageCreateIdReducer(
    state: CompanyGalleryImageIdState = initialCompanyGalleryImageCreateIdState,
    action: Action
): CompanyGalleryImageIdState {
    switch (action.type) {
        case RECRUITER_COMPANY_GALLERY_IMAGE_CREATE.SUCCESS:
            return action.payload.galleryImage.id;

        default:
            return state;
    }
}

export const initialCompanyGalleryImageCreateStatusState: CompanyGalleryImageCreateStatusState = CREATE_STATUS.NONE;
export function companyGalleryImageCreateStatusReducer(
    state: CompanyGalleryImageCreateStatusState = initialCompanyGalleryImageCreateStatusState,
    action: Action
): CompanyGalleryImageCreateStatusState {
    switch (action.type) {
        case RECRUITER_COMPANY_GALLERY_IMAGE_CREATE.REQUEST:
            return CREATE_STATUS.CREATING;

        case RECRUITER_COMPANY_GALLERY_IMAGE_CREATE.SUCCESS:
            return CREATE_STATUS.CREATED;

        case RECRUITER_COMPANY_GALLERY_IMAGE_CREATE.FAILURE:
            return CREATE_STATUS.FAILED;

        default:
            return state;
    }
}

export const initialCompanyGalleryImageCreateErrorsState: CompanyGalleryImageCreateErrorsState = [];
export function companyGalleryImageCreateErrorsReducer(
    state: CompanyGalleryImageCreateErrorsState = initialCompanyGalleryImageCreateErrorsState,
    action: Action
): CompanyGalleryImageCreateErrorsState {
    switch (action.type) {
        case RECRUITER_COMPANY_GALLERY_IMAGE_CREATE.REQUEST:
            return initialCompanyBackgroundUpdateErrorsState;

        case RECRUITER_COMPANY_GALLERY_IMAGE_CREATE.FAILURE:
            return action.payload.errors;

        default:
            return state;
    }
}

export const initialCompanyGalleryImagesCreationState: CompanyGalleryImagesCreationState = {
    id: initialCompanyGalleryImageCreateIdState,
    status: initialCompanyGalleryImageCreateStatusState,
    errors: initialCompanyGalleryImageCreateErrorsState
};
export const companyGalleryImagesCreationReducer = combineReducers<CompanyGalleryImagesCreationState>({
    id: companyGalleryImageCreateIdReducer,
    status: companyGalleryImageCreateStatusReducer,
    errors: companyGalleryImageCreateErrorsReducer
});

export function getLocalCreateGalleryImageIdFromAction(action: Action) {
    return action.payload?.localCreateId ?? null;
}

export const initialCompanyGalleryImagesCreationsState = {};
export const companyGalleryImagesCreationsReducer = createMapReducer(
    companyGalleryImagesCreationReducer,
    getLocalCreateGalleryImageIdFromAction
);

export function companyGalleryImagesCreationsWithCleanupReducer(
    state: CompanyGalleryImagesCreationsState = initialCompanyGalleryImagesCreationsState,
    action: Action
): CompanyGalleryImagesCreationsState {
    if (action.type === REMOVE_CREATE_RECRUITER_COMPANY_GALLERY_IMAGE_LOCAL_ID) {
        const localCreateId = getLocalCreateGalleryImageIdFromAction(action);

        if (localCreateId !== null) {
            return omit(state, localCreateId);
        }
    }

    return companyGalleryImagesCreationsReducer(state, action);
}

export const initialCompanyGalleryImageDeleteStatusState: CompanyGalleryImageDeleteStatusState = DELETE_STATUS.NONE;
export function companyGalleryImageDeleteStatusReducer(
    state: CompanyGalleryImageDeleteStatusState = initialCompanyGalleryImageDeleteStatusState,
    action: Action
): CompanyGalleryImageDeleteStatusState {
    switch (action.type) {
        case RECRUITER_COMPANY_GALLERY_IMAGE_DELETE.REQUEST:
            return DELETE_STATUS.DELETING;

        case RECRUITER_COMPANY_GALLERY_IMAGE_DELETE.SUCCESS:
            return DELETE_STATUS.DELETED;

        case RECRUITER_COMPANY_GALLERY_IMAGE_DELETE.FAILURE:
            return CREATE_STATUS.FAILED;

        default:
            return state;
    }
}

export const initialCompanyGalleryImageDeleteErrorsState: CompanyGalleryImageDeleteErrorsState = [];
export function companyGalleryImageDeleteErrorsReducer(
    state: CompanyGalleryImageDeleteErrorsState = initialCompanyGalleryImageDeleteErrorsState,
    action: Action
): CompanyGalleryImageDeleteErrorsState {
    switch (action.type) {
        case RECRUITER_COMPANY_GALLERY_IMAGE_DELETE.REQUEST:
            return initialCompanyBackgroundUpdateErrorsState;

        case RECRUITER_COMPANY_GALLERY_IMAGE_DELETE.FAILURE:
            return action.payload.errors;

        default:
            return state;
    }
}

export const initialCompanyGalleryImagesDeletionState: CompanyGalleryImagesDeletionState = {
    status: initialCompanyGalleryImageCreateStatusState,
    errors: initialCompanyGalleryImageCreateErrorsState
};
export const companyGalleryImagesDeletionReducer = combineReducers<CompanyGalleryImagesDeletionState>({
    status: companyGalleryImageDeleteStatusReducer,
    errors: companyGalleryImageDeleteErrorsReducer
});

export function getGalleryImageIdFromAction(action: Action) {
    return action.payload?.id ?? null;
}

export const initialCompanyGalleryImagesDeletionsState = {};
export const companyGalleryImagesDeletionsReducer = createMapReducer(
    companyGalleryImagesDeletionReducer,
    getGalleryImageIdFromAction
);

export function companyGalleryImagesDeletionsWithCleanupReducer(
    state: CompanyGalleryImagesDeletionsState = initialCompanyGalleryImagesDeletionsState,
    action: Action
): CompanyGalleryImagesDeletionsState {
    if (action.type === REMOVE_DELETE_RECRUITER_COMPANY_GALLERY_IMAGE_ID) {
        const id = getGalleryImageIdFromAction(action);

        if (id !== null) {
            return omit(state, String(id));
        }
    }

    return companyGalleryImagesDeletionsReducer(state, action);
}

const reducer = combineReducers({
    entity: entityReducer,
    updateStatus: updateStatusReducer,
    portraitUpdateStatus: portraitUpdateStatusReducer,
    portraitUpdateErrors: portraitUpdateErrorsReducer,
    companyLogoUpdateStatus: companyLogoUpdateStatusReducer,
    companyLogoUpdateErrors: companyLogoUpdateErrorsReducer,
    companyBackgroundUpdateStatus: companyBackgroundUpdateStatusReducer,
    companyBackgroundUpdateErrors: companyBackgroundUpdateErrorsReducer,
    companyGalleryImagesCreations: companyGalleryImagesCreationsWithCleanupReducer,
    companyGalleryImagesDeletions: companyGalleryImagesDeletionsWithCleanupReducer
});

export default resetOnLogout(reducer);
