import React from 'react';

import {
    AuthenticationState,
    AuthenticationStateStatus,
    AuthenticationStateType
} from './AuthenticationState.constants';
import { calculateAuthenticationStateStatus } from './AuthenticationState.utils';

export type AuthenticationStateRenderProps = {
    status: AuthenticationStateStatus;
};

export type AuthenticationStateProps = {
    type: AuthenticationStateType | null;
    loading?: React.ReactNode;
    failure?: React.ReactNode;
    children: React.ReactNode | ((props: AuthenticationStateRenderProps) => React.ReactNode);
    state: AuthenticationState;
};

function AuthenticationStateComponent({ type, loading, failure, children, state }: AuthenticationStateProps) {
    const status = calculateAuthenticationStateStatus({ type, state });

    if (typeof children === 'function') {
        return children({
            status: status
        });
    }

    if (status === 'loading') {
        return loading ?? null;
    }

    if (status === 'failed') {
        return failure ?? null;
    }

    return children;
}

export default AuthenticationStateComponent;
