import * as React from 'react';

import './DocumentList.style.scss';

import DocumentItem from '../DocumentItem';

import { File } from '../../modules/files/types';

type DocumentListProps = {
    files: File[];
    onClickDownload: (file: File) => void;
};

function DocumentList({ files, onClickDownload }: DocumentListProps) {
    const items = files.map((file) => (
        <li key={file.id} className="DocumentList__item">
            <DocumentItem file={file} onClickDownload={onClickDownload} />
        </li>
    ));

    return <ol className="DocumentList">{items}</ol>;
}

export default DocumentList;
