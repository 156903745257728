import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../../components/Button';

type Props = {
    onBack?: () => void;
};

function SignupFormStepActionBack(props: Props): React.ReactElement {
    return (
        <Button type="button" onClick={props.onBack} typeStyle="flat" variationStyle="normal">
            <FormattedMessage id="SIGNUP_FORM_ACTIONS.BACK" />
        </Button>
    );
}

export default SignupFormStepActionBack;
