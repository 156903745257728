import { all, take, put, call, spawn, cancel, fork } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { getHistory } from '../../history';
import { getTemplatesListRoute, getEditTemplateRoute } from '../../routes';

import { isApiResponseError } from '../../services/api/utils';
import * as errorService from '../../services/errorHandler';

import {
    FETCH_TEMPLATES,
    templatesFetch,
    FETCH_TEMPLATE,
    templateFetch,
    CREATE_TEMPLATE,
    templateCreate,
    UPDATE_TEMPLATE,
    templateUpdate,
    DELETE_TEMPLATE,
    templateDelete,
    DUPLICATE_TEMPLATE
} from './actions';

import * as templatesApi from './api';

import { Template } from './types';

export function* fetchTemplatesSaga(
    page: number | void,
    pageSize: number | void,
    ordering: Array<string> | void
): SagaIterator<void> {
    try {
        yield put(templatesFetch.request());

        const queryParams: templatesApi.FetchTemplatesQueryParams = {
            page,
            pageSize,
            ordering
        };

        const response = yield call(templatesApi.fetchTemplates, queryParams);

        yield put(templatesFetch.success(response));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(templatesFetch.failure(error.response.data.errors || []));
        } else {
            throw error;
        }
    }
}

export function* fetchTemplateSaga(templateId: Template['id']): SagaIterator<void> {
    try {
        yield put(templateFetch.request(templateId));

        const response = yield call(templatesApi.fetchTemplate, templateId);

        yield put(templateFetch.success(templateId, response));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(templateFetch.failure(templateId, error.response.data.errors || []));
        } else {
            throw error;
        }
    }
}

export function* createTemplateSaga(
    newTemplate: Partial<Template>,
    isDuplicating: boolean = false
): SagaIterator<Template | void> {
    try {
        yield put(templateCreate.request());

        const response = yield call(templatesApi.createTemplate, newTemplate);

        yield put(templateCreate.success(response));

        if (!isDuplicating) {
            const history = getHistory();
            yield call(history.push, getTemplatesListRoute());
        }

        const createdTemplate: Template = response.data;

        return createdTemplate;
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(templateCreate.failure(error.response.data.errors || []));
        } else {
            throw error;
        }
    }
}

export function* duplicateTemplateSaga(newTemplate: Partial<Template>): SagaIterator<void> {
    try {
        const isDuplicating = true;
        const createdTemplate = yield call(createTemplateSaga, newTemplate, isDuplicating);

        const history = getHistory();
        yield call(history.push, getEditTemplateRoute(createdTemplate.id));
    } catch (error) {
        errorService.handleError(error);
    }
}

export function* updateTemplateSaga(templateId: Template['id'], newTemplate: Partial<Template>): SagaIterator<void> {
    try {
        yield put(templateUpdate.request(templateId));

        const response = yield call(templatesApi.updateTemplate, templateId, newTemplate);

        yield put(templateUpdate.success(templateId, response));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(templateUpdate.failure(templateId, error.response.data.errors));
        } else {
            throw error;
        }
    }
}

export function* deleteTemplateSaga(templateId: Template['id']): SagaIterator<void> {
    try {
        yield put(templateDelete.request(templateId));

        const response = yield call(templatesApi.deleteTemplate, templateId);

        yield put(templateDelete.success(templateId, response));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(templateDelete.failure(templateId, error.response.data.errors));
        } else {
            throw error;
        }
    }
}

function* watchFetchTemplatesSaga(): SagaIterator<void> {
    while (true) {
        const action = yield take(FETCH_TEMPLATES);

        const { page, pageSize, ordering } = action.payload;

        yield call(fetchTemplatesSaga, page, pageSize, ordering);
    }
}

function* watchFetchTemplateSaga(): SagaIterator<void> {
    while (true) {
        const action = yield take(FETCH_TEMPLATE);

        const { templateId } = action.payload;

        yield call(fetchTemplateSaga, templateId);
    }
}

function* watchCreateTemplateSaga(): SagaIterator<void> {
    while (true) {
        const action = yield take(CREATE_TEMPLATE);

        const { newTemplate } = action.payload;

        yield call(createTemplateSaga, newTemplate);
    }
}

function* watchDuplicateTemplateSaga(): SagaIterator<void> {
    while (true) {
        const action = yield take(DUPLICATE_TEMPLATE);

        const { newTemplate } = action.payload;

        yield call(duplicateTemplateSaga, newTemplate);
    }
}

function* watchUpdateTemplateSaga(): SagaIterator<void> {
    const tasks = {};

    while (true) {
        const action = yield take(UPDATE_TEMPLATE);
        const payload = action.payload;

        const taskId = payload.templateId;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        tasks[taskId] = yield fork(updateTemplateSaga, payload.templateId, payload.newTemplate);
    }
}

function* watchDeleteTemplateSaga(): SagaIterator<void> {
    const tasks = {};

    while (true) {
        const action = yield take(DELETE_TEMPLATE);
        const payload = action.payload;

        const taskId = payload.templateId;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        tasks[taskId] = yield fork(deleteTemplateSaga, payload.templateId);
    }
}

export default function* root(): SagaIterator<void> {
    yield all([
        spawn(watchFetchTemplatesSaga),
        spawn(watchFetchTemplateSaga),
        spawn(watchCreateTemplateSaga),
        spawn(watchDuplicateTemplateSaga),
        spawn(watchUpdateTemplateSaga),
        spawn(watchDeleteTemplateSaga)
    ]);
}
