import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { isNew, isProcessing, isDraft, isActive, isArchived } from '../../modules/jobs/utils';

import './style.scss';

import { Job } from '../../modules/jobs/types';

type Props = {
    job: Job;
};

const generateMessageId = (job: Job) => {
    if (isActive(job)) {
        return 'JOB_STATE.active';
    } else if (isArchived(job)) {
        return 'JOB_STATE.archived';
    }

    return 'JOB_STATE.draft';
};

const JobState = ({ job }: Props) => {
    const className = classNames('JobState', {
        'JobState--draft': isNew(job) || isProcessing(job) || isDraft(job),
        'JobState--active': isActive(job),
        'JobState--archived': isArchived(job)
    });

    return (
        <span className={className}>
            <FormattedMessage id={generateMessageId(job)} />
        </span>
    );
};

export default JobState;
