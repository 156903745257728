import { State as ApplicationState } from '../../store/reducer';
import { FetchStatus, UpdateStatus } from '../types';

import { FlagSlug } from './types';
import { MOUNT, FlagsState, FlagState, ValueState, ExistsRemotelyState } from './reducer';

function getState(state: ApplicationState): FlagsState {
    return state[MOUNT];
}

function getValue(state: FlagState): ValueState {
    return state.value;
}

function getExistsRemotely(state: FlagState): ExistsRemotelyState {
    return state.existsRemotely;
}

function getFetchStatus(state: FlagState): FetchStatus {
    return state.fetchStatus;
}

function getUpdateStatus(state: FlagState): UpdateStatus {
    return state.updateStatus;
}

function getFlag(state: ApplicationState, slug: FlagSlug): FlagState {
    const flagsState = getState(state);

    if (!flagsState[slug]) {
        throw new Error(`State doesn't have a flag called "${slug}".`);
    }

    return flagsState[slug];
}

export function getFlagValue(state: ApplicationState, slug: FlagSlug): ValueState {
    const flagState = getFlag(state, slug);
    return getValue(flagState);
}

export function getFlagExistsRemotely(state: ApplicationState, slug: FlagSlug): ExistsRemotelyState {
    const flagState = getFlag(state, slug);
    return getExistsRemotely(flagState);
}

export function getFlagFetchStatus(state: ApplicationState, slug: FlagSlug): FetchStatus {
    const flagState = getFlag(state, slug);
    return getFetchStatus(flagState);
}

export function getFlagUpdateStatus(state: ApplicationState, slug: FlagSlug): UpdateStatus {
    const flagState = getFlag(state, slug);
    return getUpdateStatus(flagState);
}
