import { JobHiringBudgetScale } from '../../../../modules/jobs/types';

export type JobFormHiringBudgetFormFieldsName = 'hiring_budget';
export const JOB_EDIT_HIRING_BUDGET_FORM_FIELDS_NAME: 'hiring_budget' = 'hiring_budget';

export type JobFormHiringBudgetBudgetLowerFormFieldName = 'hiring_budget.budget_lower';
export const JOB_EDIT_HIRING_BUDGET_BUDGET_LOWER_FORM_FIELD_NAME: JobFormHiringBudgetBudgetLowerFormFieldName = `hiring_budget.budget_lower`;
export type JobFormHiringBudgetBudgetHigherFormFieldName = 'hiring_budget.budget_higher';
export const JOB_EDIT_HIRING_BUDGET_BUDGET_HIGHER_FORM_FIELD_NAME: JobFormHiringBudgetBudgetHigherFormFieldName = `hiring_budget.budget_higher`;
export type JobFormHiringBudgetScaleFormFieldName = 'hiring_budget.scale';
export const JOB_EDIT_HIRING_BUDGET_SCALE_FORM_FIELD_NAME: JobFormHiringBudgetScaleFormFieldName = `hiring_budget.scale`;

export type JobFormHiringBudgetFormFieldsValue = {
    budget_lower: string | null;
    budget_higher: string | null;
    scale: JobHiringBudgetScale;
};

export const initialJobFormHiringBudgetFormFieldsValue = {
    budget_lower: null,
    budget_higher: null,
    scale: JobHiringBudgetScale.Year
};
