import React from 'react';
import classNames from 'classnames';

import useCombinedRef from '../../../hooks/useCombinedRefs';

import './ListItemIcon.scss';

export type ListItemIconProps = {
    children: React.ReactElement;
    className?: string;
};

const ListItemIcon = React.forwardRef<HTMLDivElement, ListItemIconProps>(function ListItemIcon(
    { children, className },
    ref
) {
    const child = React.Children.only(children);

    const mergedRef = useCombinedRef(ref, child.props.key ?? null);

    return React.cloneElement(children, {
        ref: mergedRef,
        className: classNames('ListItemIcon', className)
    });
});

export default ListItemIcon;
