import { Button } from '@truffls/design-system-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { copyJob } from '../../modules/jobs/actions';
import { JobId } from '../../modules/jobs/types';
import { FormattedMessage } from 'react-intl';

export type JobActionDuplicateProps = {
    jobId: JobId;
};

function JobActionDuplicate({ jobId }: JobActionDuplicateProps) {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(copyJob(jobId));
    };

    return (
        <Button typeStyle="outlined" variationStyle="normal" type="button" onClick={handleClick}>
            <FormattedMessage id="JOB_ACTION_DUPLICATE.LABEL" />
        </Button>
    );
}

export default JobActionDuplicate;
