import { JobStartDateStart } from '../../../../modules/jobs/types';

export type JobFormAvailabilityFormFieldsName = 'start_date';
export const JOB_EDIT_AVAILABILITY_FORM_FIELDS_NAME: JobFormAvailabilityFormFieldsName = 'start_date';

export type JobFormAvailabilityStartFormFieldName = 'start_date.start';
export const JOB_EDIT_AVAILABILITY_START_FORM_FIELD_NAME: JobFormAvailabilityStartFormFieldName = `start_date.start`;
export type JobFormAvailabilityDateFormFieldName = 'start_date.date';
export const JOB_EDIT_AVAILABILITY_DATE_FORM_FIELD_NAME: JobFormAvailabilityDateFormFieldName = `start_date.date`;

export type JobFormAvailabilityFormFieldsValue =
    | {
          start: JobStartDateStart.Asap | JobStartDateStart.Flex;
          date: null;
      }
    | {
          start: JobStartDateStart.Date;
          date: string;
      };

export const initialJobFormAvailabilityFormFieldsValue: JobFormAvailabilityFormFieldsValue = {
    start: JobStartDateStart.Asap,
    date: null
};
