import * as React from 'react';
import classNames from 'classnames';
import { useFieldArray } from 'react-final-form-arrays';

import {
    DragAndDropContainer,
    DragLayer,
    Draggable,
    horizontalDragStrategy,
    useDragEndItemMove
} from '../../../../components/DragAndDrop';

import JobFormRequirementsListItemFormField from './JobFormRequirementsListItemFormField';
import JobFormRequirementsListItemPlaceholder from './JobFormRequirementsListItemPlaceholder';

import './JobFormRequirementsListFormFields.scss';

export type JobFormRequirementsListFormFieldsProps = {
    name: string;

    [propName: string]: any;
};

function JobFormRequirementsListFormFields({ name, className, ...restProps }: JobFormRequirementsListFormFieldsProps) {
    const { fields } = useFieldArray<string>(name);

    const fieldNames = fields.map((name) => name);

    const onMove = (fromIndex: number, toIndex: number) => {
        fields.move(fromIndex, toIndex);
    };

    const getIdFromItem = (fieldName: string): string => {
        return fieldName;
    };

    const { items: fieldNamesToRender, ...handlers } = useDragEndItemMove<string>({
        items: fieldNames,
        onMoveItem: onMove,
        getIdFromItem
    });

    const placeholder = <JobFormRequirementsListItemPlaceholder />;

    return (
        <div className={classNames('JobFormRequirementsListFormFields', className)} {...restProps}>
            <DragAndDropContainer>
                <DragLayer type={name} />
                {fieldNamesToRender.map((fieldName, fieldIndex) => {
                    const id = getIdFromItem(fieldName);

                    const handleDeleteItem = () => {
                        fields.remove(fieldIndex);
                    };

                    return (
                        <Draggable
                            key={id}
                            type={name}
                            dragStrategy={horizontalDragStrategy}
                            id={id}
                            placeholder={placeholder}
                            {...handlers}
                            className="JobFormRequirementsListFormFields__item-container"
                        >
                            <JobFormRequirementsListItemFormField name={fieldName} onDelete={handleDeleteItem} />
                        </Draggable>
                    );
                })}
            </DragAndDropContainer>
        </div>
    );
}

export default JobFormRequirementsListFormFields;
