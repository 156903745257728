import { getClientConfig } from '../../runtime-config';
import { getCookieBanner } from '../../utils/cookieBanner';
import { once } from '../../utils/function';
import { getServices } from './services';

export default async function setupTracking() {
    const cookieBanner = await getCookieBanner();

    const injectScriptsOnce = once(injectScripts);

    const applyDecision = () => {
        const optionalEnabled = cookieBanner.get();

        if (optionalEnabled) {
            injectScriptsOnce();
        }

        const services = getServices(getClientConfig());

        services.forEach((service) => {
            service.toggle?.(optionalEnabled);
        });
    };

    cookieBanner.subscribe(applyDecision);
    applyDecision();
}

function injectScripts() {
    const services = getServices(getClientConfig());

    const parent = document.head;
    services.forEach((service) => {
        const foundScript = parent.querySelector(`script[src="${service.scriptSrc}"]`);

        if (foundScript) {
            return;
        }

        const script = document.createElement('script');
        script.async = true;
        script.src = service.scriptSrc;

        parent.appendChild(script);
    });
}
