import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import './CandidateDocumentsPage.style.scss';

import config from '../../config';

import { matchesBreakpoint, BREAKPOINT_MD } from '../../utils/viewport';
import { shouldFetchConversation } from './CandidateDocumentsPage.utils';

import { getRecruiter } from '../../modules/recruiters/selectors';
import { getCandidate, getCandidateFetchStatus } from '../../modules/entities/selectors';
import { loadCandidate } from '../../modules/candidates/actions';
import { getConversation, getConversationFetchStatus } from '../../modules/conversations/selectors';
import { fetchConversation } from '../../modules/conversations/actions';
import { getFileRequestByCandidateIdCreateStatus } from '../../modules/files/selectors';

import RequestDocumentsCTA from '../../components/RequestDocumentsCTA';

import CandidateDocuments from '../CandidateDocuments';
import RequestDocumentsView from '../RequestDocumentsView';

import CandidateDocumentsPageMobile from './CandidateDocumentsPageMobile/CandidateDocumentsPageMobile';

import { Job } from '../../modules/jobs/types';
import { Recruiter } from '../../modules/recruiters/types';
import { Candidate } from '../../modules/candidates/types';
import { Conversation } from '../../modules/conversations/types';
import { FetchStatus, CreateStatus } from '../../modules/types';
import { State as ApplicationState } from '../../store/reducer';
import { getCandidateConversationId } from '../../modules/candidates/utils';

type PossibleRouteParams = {
    jobId: string;
    candidateId: string;
};

type ConnectorProps = RouteComponentProps<PossibleRouteParams>;

type ConnectedStateProps = {
    jobId: Job['id'];

    recruiter: Recruiter | null;

    candidate: Candidate | null;
    candidateId: Candidate['id'];
    candidateFetchStatus: FetchStatus;

    conversation: Conversation | null;
    conversationId: Conversation['id'] | null;
    conversationFetchStatus: FetchStatus;

    fileRequestCreateStatus: CreateStatus;
};

type ConnectedDispatchProps = {
    loadCandidate: typeof loadCandidate;
    fetchConversation: typeof fetchConversation;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type State = {
    showRequestDocumentsDialog: boolean;
};

class CandidateDocumentsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showRequestDocumentsDialog: false
        };

        this.openRequestDocumentsDialog = this.openRequestDocumentsDialog.bind(this);
        this.closeRequestDocumentsDialog = this.closeRequestDocumentsDialog.bind(this);
    }

    componentDidMount() {
        const {
            recruiter,
            candidate,
            candidateId,
            candidateFetchStatus,
            loadCandidate,
            conversationId,
            conversationFetchStatus,
            fetchConversation
        } = this.props;

        const candidateHasNeverBeenFetched = candidateFetchStatus === 'none';

        if (candidateHasNeverBeenFetched) {
            loadCandidate(candidateId);
        } else if (
            shouldFetchConversation(conversationId, conversationFetchStatus, recruiter, candidate) &&
            conversationId !== null
        ) {
            fetchConversation(conversationId);
        }
    }

    componentDidUpdate(prevProps: Props) {
        // TODO: use CreateStatus constants
        const justCreatedFileRequest =
            prevProps.fileRequestCreateStatus === 'creating' && this.props.fileRequestCreateStatus === 'created';

        if (justCreatedFileRequest) {
            this.setState({
                showRequestDocumentsDialog: false
            });
        }

        // TODO: use FetchStatus constants
        const justFetchedCandidate =
            prevProps.candidateFetchStatus === 'loading' && this.props.candidateFetchStatus === 'loaded';

        const { conversationId, conversationFetchStatus, recruiter, candidate, fetchConversation } = this.props;

        const conversationShouldBeFetched = shouldFetchConversation(
            conversationId,
            conversationFetchStatus,
            recruiter,
            candidate
        );

        if (justFetchedCandidate && conversationShouldBeFetched && conversationId !== null) {
            fetchConversation(conversationId);
        }
    }

    openRequestDocumentsDialog() {
        this.setState({
            showRequestDocumentsDialog: true
        });
    }

    closeRequestDocumentsDialog() {
        this.setState({
            showRequestDocumentsDialog: false
        });
    }

    render() {
        const { candidate, conversation, jobId, candidateId } = this.props;

        // Wait for candidate load
        if (!candidate) {
            return null;
        }

        const shouldShowRequestDocumentsCTA = !!config.showRequestDocuments && !!conversation && !conversation.closed;

        const content = (
            <div className="CandidateDocumentsPage">
                {shouldShowRequestDocumentsCTA && (
                    <RequestDocumentsCTA onClickRequestDocuments={this.openRequestDocumentsDialog} />
                )}

                <div className="CandidateDocumentsPage__scroll-zone">
                    <CandidateDocuments jobId={jobId} candidateId={candidateId} />
                </div>
                <RequestDocumentsView
                    jobId={jobId}
                    candidateId={candidateId}
                    open={this.state.showRequestDocumentsDialog}
                    onClose={this.closeRequestDocumentsDialog}
                />
            </div>
        );

        const isMobileViewport = !matchesBreakpoint(BREAKPOINT_MD);

        if (isMobileViewport) {
            return <CandidateDocumentsPageMobile>{content}</CandidateDocumentsPageMobile>;
        } else {
            return content;
        }
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => {
    const match = props.match;

    const jobId = parseInt(match.params.jobId, 10);
    const candidateId = parseInt(match.params.candidateId, 10);

    const candidate = getCandidate(state, candidateId);
    const conversationId = candidate !== null ? getCandidateConversationId(candidate) : null;
    const conversation = conversationId !== null ? getConversation(state, conversationId) : null;

    return {
        jobId,
        candidateId,

        recruiter: getRecruiter(state),

        candidate,
        candidateFetchStatus: getCandidateFetchStatus(state, candidateId),

        fileRequestCreateStatus: getFileRequestByCandidateIdCreateStatus(state, candidateId),

        conversationId,
        conversation,
        conversationFetchStatus: getConversationFetchStatus(state, conversationId)
    };
};

const mapDispatchToProps = {
    loadCandidate,
    fetchConversation
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDocumentsPage);
