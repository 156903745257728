import React from 'react';
import classNames from 'classnames';

import './CandidateDetailsRow.scss';

type Props = {
    label: React.ReactNode;
    children: React.ReactNode;
    layout?: 'one-line' | 'stacked';
    stickyLabel?: boolean;
    border?: boolean;
};

function CandidateDetailsRow({ label, children, layout, stickyLabel = false, border = false }: Props) {
    return (
        <div
            className={classNames('CandidateDetailsRow', {
                'CandidateDetailsRow--one-line': layout === 'one-line',
                'CandidateDetailsRow--stacked': layout === 'stacked',
                'CandidateDetailsRow--sticky-label': stickyLabel,
                'CandidateDetailsRow--border': border
            })}
        >
            <div className="CandidateDetailsRow__label">{label}</div>
            <div className="CandidateDetailsRow__value">{children}</div>
        </div>
    );
}

export default CandidateDetailsRow;
