import { combineReducers } from 'redux';

import { hasErrorWithCode } from '../../services/api/utils';
import { ERROR_GENERIC_NOT_FOUND } from '../../services/api/constants';
import { conditionalReducer } from '../../utils/redux';
import { FETCH_STATUS, UPDATE_STATUS } from '../constants';
import { Action, FetchStatus, UpdateStatus } from '../types';

import { FLAGS } from './constants';
import { FLAG_FETCH, FLAG_UPDATE } from './actions';
import { FlagValue } from './types';

export type ValueState = FlagValue;
export type ExistsRemotelyState = boolean;
export type FlagState = {
    value: ValueState;
    existsRemotely: ExistsRemotelyState;
    fetchStatus: FetchStatus;
    updateStatus: UpdateStatus;
};

export type FlagsState = {
    [index: string]: FlagState;
};

export const MOUNT: 'flags' = 'flags';

// Exported for testing
export function valueReducer(state: ValueState = false, action: Action): ValueState {
    switch (action.type) {
        case FLAG_FETCH.SUCCESS:
        case FLAG_UPDATE.SUCCESS:
            return action.payload.data.value;

        case FLAG_FETCH.FAILURE:
        case FLAG_UPDATE.FAILURE:
            const errors = action.payload.errors;
            if (hasErrorWithCode(errors, ERROR_GENERIC_NOT_FOUND)) {
                return false;
            }

        default:
            return state;
    }
}

// Exported for testing
export function existsRemotelyReducer(state: ExistsRemotelyState = false, action: Action): ExistsRemotelyState {
    switch (action.type) {
        case FLAG_FETCH.SUCCESS:
        case FLAG_UPDATE.SUCCESS:
            return true;

        case FLAG_FETCH.FAILURE:
        case FLAG_UPDATE.FAILURE:
            const errors = action.payload.errors;
            if (hasErrorWithCode(errors, ERROR_GENERIC_NOT_FOUND)) {
                return false;
            }

        default:
            return state;
    }
}

export function fetchStatusReducer(state: FetchStatus = FETCH_STATUS.NONE, action: Action): FetchStatus {
    switch (action.type) {
        case FLAG_FETCH.REQUEST:
            return FETCH_STATUS.LOADING;

        case FLAG_FETCH.SUCCESS:
            return FETCH_STATUS.LOADED;

        case FLAG_FETCH.FAILURE:
            const errors = action.payload.errors;

            if (hasErrorWithCode(errors, ERROR_GENERIC_NOT_FOUND)) {
                return FETCH_STATUS.LOADED;
            }
            return FETCH_STATUS.FAILED;

        default:
            return state;
    }
}

export function updateStatusReducer(state: UpdateStatus = UPDATE_STATUS.NONE, action: Action): UpdateStatus {
    switch (action.type) {
        case FLAG_UPDATE.REQUEST:
            return UPDATE_STATUS.UPDATING;

        case FLAG_UPDATE.SUCCESS:
            return UPDATE_STATUS.UPDATED;

        case FLAG_UPDATE.FAILURE:
            return UPDATE_STATUS.FAILED;

        default:
            return state;
    }
}

// Exported for testing
export const flagReducer = combineReducers({
    value: valueReducer,
    existsRemotely: existsRemotelyReducer,
    fetchStatus: fetchStatusReducer,
    updateStatus: updateStatusReducer
});

const reducers = FLAGS.reduce((reducers, slug) => {
    const meetsCondition = <FlagState>(state: FlagState, action: Action) => {
        return !!action.payload && action.payload.slug === slug;
    };

    reducers[slug] = conditionalReducer(meetsCondition)(flagReducer);
    return reducers;
}, {});

export default combineReducers(reducers);
