import * as React from 'react';

import { companyName } from '../../../constants';

import iphoneSignalIcon from '../../../assets/images/MobileApp/ic_iphone-signal.svg';
import iphoneWifiIcon from '../../../assets/images/MobileApp/ic_iphone-wifi.svg';
import iphoneBluetoothIcon from '../../../assets/images/MobileApp/ic_iphone-bluetooth.svg';
import iphoneBatteryIcon from '../../../assets/images/MobileApp/ic_iphone-battery.svg';

import './style.scss';

const MobileApp = (): React.ReactElement => {
    return (
        <div className="MobileAppStatusBar">
            <div className="MobileAppStatusBar__left-section">
                <img className="MobileAppStatusBar__element-signal" src={iphoneSignalIcon} role="presentation" />
                <span className="MobileAppStatusBar__element-carrier">{companyName.lowerCase}</span>
                <img className="MobileAppStatusBar__element-wifi" src={iphoneWifiIcon} role="presentation" />
            </div>
            <div className="MobileAppStatusBar__clock">4:20 PM</div>
            <div className="MobileAppStatusBar__right-section">
                <img className="MobileAppStatusBar__element-bluetooth" src={iphoneBluetoothIcon} role="presentation" />
                <img className="MobileAppStatusBar__element-battery" src={iphoneBatteryIcon} role="presentation" />
            </div>
        </div>
    );
};

export default MobileApp;
