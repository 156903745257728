import * as React from 'react';

import './MessageItemStatus.style.scss';

type Props = {
    text: string;
};

const MessageItemStatus = ({ text }: Props) => {
    return (
        <div className="MessageItemStatus">
            <div className="MessageItemStatus__body">
                <span>{text}</span>
            </div>
        </div>
    );
};

export default MessageItemStatus;
