import {
    UseFieldConfig as BaseUseFieldConfig,
    useField as useBaseField,
    FieldRenderProps as BaseFieldRenderProps
} from 'react-final-form';
import useValidate from './useValidate';
import { FieldMeta, useFieldMeta } from '../hooks';

export type UseFieldConfig<FieldValue> = BaseUseFieldConfig<FieldValue> & {
    required?: boolean;
};

export type FieldRenderProps<FieldValue, HTMLElementType extends HTMLElement = HTMLElement> = {
    input: BaseFieldRenderProps<FieldValue, HTMLElementType>['input'];
    meta: FieldMeta<BaseFieldRenderProps<FieldValue, HTMLElementType>['meta']>;
};

export default function useField<FieldValue, HTMLElementType extends HTMLElement>(
    name,
    {
        afterSubmit,
        allowNull,
        beforeSubmit,
        data,
        defaultValue,
        format,
        formatOnBlur,
        initialValue,
        isEqual,
        multiple,
        parse: instanceParse,
        required,
        subscription,
        type,
        validate: instanceValidate,
        validateFields,
        value
    }: UseFieldConfig<FieldValue>
): FieldRenderProps<FieldValue, HTMLElementType> {
    const validate = useValidate<FieldValue>({
        validate: instanceValidate,
        required
    });

    const parse = instanceParse || ((value) => value);

    const baseField = useBaseField<FieldValue, HTMLElement>(name, {
        afterSubmit,
        allowNull,
        beforeSubmit,
        data,
        defaultValue,
        format,
        formatOnBlur,
        initialValue,
        isEqual,
        multiple,
        parse,
        subscription,
        type,
        validate,
        validateFields,
        value
    });

    const input = {
        ...baseField.input
    };

    if (typeof required === 'boolean') {
        input['aria-required'] = required;
    }

    const meta = useFieldMeta(baseField.meta);

    return {
        input,
        meta
    };
}
