import { combineReducers } from 'redux';
import resetOnLogout from '../../authentication/reducer/resetOnLogout';

export const MOUNT = 'files';

import byCandidateIdReducer from './byCandidateId';
import { CandidateIdListsState } from './byCandidateId';

import fileRequestsByCandidateIdReducer from './fileRequestsByCandidateId';
import { FileRequestsByCandidateIdListState } from './fileRequestsByCandidateId';

export type FilesState = {
    byCandidateId: CandidateIdListsState;
    fileRequestsByCandidateId: FileRequestsByCandidateIdListState;
};

const rootReducer = combineReducers({
    byCandidateId: byCandidateIdReducer,
    fileRequestsByCandidateId: fileRequestsByCandidateIdReducer
});

export default resetOnLogout(rootReducer);
