import { SagaIterator } from 'redux-saga';
import { all, call, delay, put, race, spawn, take, takeLatest } from 'redux-saga/effects';
import { getHistory } from '../../history';
import { getJobEditRoute } from '../../routes';
import { isApiResponseError } from '../../services/api/utils';
import { handleError } from '../../services/errorHandler';
import { createJobSaga, loadJobSaga } from '../jobs/saga';
import { Job, JobId } from '../jobs/types';
import { isDraft } from '../jobs/utils';
import {
    CANCEL_JOB_PARSING,
    CancelJobParsingAction,
    JobParsing$FailureAction,
    PARSE_JOB,
    ParseJobAction,
    jobParsing
} from './actions';
import { JOB_PARSING_ERROR, PARSING_POLL_INTERVAL, PARSING_TIMEOUT_LIMIT } from './constants';

function* parseJobSaga({ payload }: ParseJobAction): SagaIterator<void> {
    const { urlToParse } = payload;

    let jobId: JobId;
    try {
        yield put(jobParsing.start());

        const job: Job = yield call(createJobSaga, { url: urlToParse });
        jobId = job.id;
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(
                jobParsing.failure({
                    serverErrors: error.response.data.errors
                })
            );
        } else {
            yield put(
                jobParsing.failure({
                    error: JOB_PARSING_ERROR.UNKNOWN
                })
            );
            handleError(error);
        }

        return;
    }

    try {
        const {
            pollingComplete,
            timeoutComplete
        }: {
            pollingComplete?: boolean;
            timeoutComplete?: JobParsing$FailureAction;
            cancelAction?: CancelJobParsingAction;
        } = yield race({
            pollingComplete: call(pollParsedJobSaga, jobId),
            timeoutComplete: delay(PARSING_TIMEOUT_LIMIT * 1000, true),
            cancelAction: take(CANCEL_JOB_PARSING)
        });

        if (pollingComplete) {
            yield put(jobParsing.success());
            const history = getHistory();
            yield call(history.push, getJobEditRoute(jobId, { isParsed: true }));
        } else if (!!timeoutComplete) {
            yield put(
                jobParsing.failure({
                    error: JOB_PARSING_ERROR.TIMEOUT
                })
            );
        }
    } catch (error) {
        yield put(
            jobParsing.failure({
                error: JOB_PARSING_ERROR.UNKNOWN
            })
        );
        handleError(error);
    }
}

function* pollParsedJobSaga(id: JobId): SagaIterator<boolean> {
    while (true) {
        const job: Job | null = yield call(loadJobSaga, id);

        if (!job) {
            throw new Error('No job');
        }

        if (isDraft(job)) {
            return true;
        }

        yield delay(PARSING_POLL_INTERVAL * 1000);
    }
}

function* watchParseJobSaga(): SagaIterator<void> {
    yield takeLatest(PARSE_JOB, parseJobSaga);
}

export default function* root(): SagaIterator<void> {
    yield all([spawn(watchParseJobSaga)]);
}
