import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ListHeaderFilterToggle from '../ListHeaderFilterToggle';
import FilterControl from '../FilterControl';
import OrderControl from '../OrderControl';
import FilterAndOrderingPageMobile from '../FilterAndOrderingPageMobile';
import FilterAndOrderingPageMobileFilterSection from '../FilterAndOrderingPageMobile/FilterSection';
import FilterAndOrderingPageMobileOrderingSection from '../FilterAndOrderingPageMobile/OrderingSection';
import CandidateListHeaderColumnState from './CandidateListHeaderColumnState';

import {
    convertParamsToFilterValues,
    convertParamsToOrderingValues,
    convertFilterAndOrderingValuesToParams,
    FilterValues,
    OrderingValues
} from '../../utils/params';

import { filtersOrOrderingActive } from './utils';

import './style.scss';

type CandidateListValues = {
    rating: null | string[];
    state: null | string[];
    ordering: null | string;
    page: number;
};

type Props = {
    showState: boolean;
    values: CandidateListValues;
    onChange: (values: CandidateListValues) => void;
};

type State = {
    isMobileOpen: boolean;
};
type Option = {
    value: string;
    label: React.ReactNode;
};
type Options = {
    [name: string]: Option[];
};
type Handlers = {
    handleChangeFilterControl: { [name: string]: (value: string[]) => void };
    handleChangeOrderingControl: { [name: string]: (value: null | 'asc' | 'desc') => void };
};

class CandidateListHeader extends React.Component<Props, State> {
    options: Options;
    handlers: Handlers = {
        handleChangeFilterControl: {
            rating: this.handleChangeFilterControl.bind(this, 'rating'),
            state: this.handleChangeFilterControl.bind(this, 'state')
        },

        handleChangeOrderingControl: {
            created: this.handleChangeOrderingControl.bind(this, 'created'),
            candidates: this.handleChangeOrderingControl.bind(this, 'candidates')
        }
    };

    orderingFields: string[];

    constructor(props: Props) {
        super(props);

        this.state = {
            isMobileOpen: false
        };

        this.orderingFields = ['candidates', 'created'];

        this.options = {
            rating: [
                { value: '3', label: 'A' },
                { value: '2', label: 'B' },
                { value: '1', label: 'C' },
                { value: 'none', label: '-' }
            ],

            state: [
                { value: 'locked', label: <FormattedMessage id="CONSTANT.CANDIDATE_STATE.locked" /> },
                { value: 'interesting', label: <FormattedMessage id="CONSTANT.CANDIDATE_STATE.interesting" /> },
                { value: 'phone-interview', label: <FormattedMessage id="CONSTANT.CANDIDATE_STATE.phone-interview" /> },
                { value: 'interview', label: <FormattedMessage id="CONSTANT.CANDIDATE_STATE.interview" /> },
                { value: 'hired', label: <FormattedMessage id="CONSTANT.CANDIDATE_STATE.hired" /> }
            ],

            ordering: [
                {
                    value: 'created-asc',
                    label: <FormattedMessage id="CANDIDATE_LIST_HEADER.OPTION_ordering.created-asc" />
                },

                {
                    value: 'created-desc',
                    label: <FormattedMessage id="CANDIDATE_LIST_HEADER.OPTION_ordering.created-desc" />
                },

                {
                    value: 'candidates-asc',
                    label: <FormattedMessage id="CANDIDATE_LIST_HEADER.OPTION_ordering.candidates-asc" />
                },

                {
                    value: 'candidates-desc',
                    label: <FormattedMessage id="CANDIDATE_LIST_HEADER.OPTION_ordering.candidates-desc" />
                }
            ]
        };

        this.handleClickToggle = this.handleClickToggle.bind(this);
        this.handleCloseMobile = this.handleCloseMobile.bind(this);
        this.handleChangeFilterAndOrdering = this.handleChangeFilterAndOrdering.bind(this);
    }

    handleClickToggle(event: React.MouseEvent) {
        event.preventDefault();

        this.setState({
            isMobileOpen: true
        });
    }

    handleCloseMobile() {
        this.setState({
            isMobileOpen: false
        });
    }

    handleChangeFilterAndOrdering(values: any) {
        this.setState({
            isMobileOpen: false
        });

        this.props.onChange(values);
    }

    handleChangeFilterControl(name: string, value: string[]) {
        const page = this.props.values.page;

        const filterValues: FilterValues = {
            ...convertParamsToFilterValues(this.props.values),
            [name]: value
        };

        const orderingValues = convertParamsToOrderingValues(this.orderingFields, this.props.values);

        const params = convertFilterAndOrderingValuesToParams(
            filterValues,
            orderingValues,
            page
        ) as CandidateListValues;

        this.props.onChange(params);
    }

    handleChangeOrderingControl(name: string, value: null | 'asc' | 'desc') {
        const page = this.props.values.page;

        const filterValues = convertParamsToFilterValues(this.props.values);

        const orderingValues: OrderingValues = {
            [name]: value
        };

        const params = convertFilterAndOrderingValuesToParams(
            filterValues,
            orderingValues,
            page
        ) as CandidateListValues;

        this.props.onChange(params);
    }

    handleChangeControl(name: string, value: any) {
        if (name === 'ordering' && value !== null) {
            value = `candidates-${value}`;
        }

        const values = {
            ...this.props.values,
            [name]: value
        };

        this.props.onChange(values);
    }

    render() {
        const {
            showState,

            values
        } = this.props;
        const { isMobileOpen } = this.state;
        const { handleChangeFilterControl, handleChangeOrderingControl } = this.handlers;

        const filterValues = convertParamsToFilterValues(values);
        const orderingValues = convertParamsToOrderingValues(this.orderingFields, values);

        const ratingLabel = <FormattedMessage id="CANDIDATE_LIST_HEADER.COLUMN_rating.LABEL" />;

        const candidateLabel = <FormattedMessage id="CANDIDATE_LIST_HEADER.COLUMN_candidate.LABEL" />;

        const createdLabel = <FormattedMessage id="CANDIDATE_LIST_HEADER.COLUMN_created.LABEL" />;

        let stateColumn: React.ReactNode = null;
        let stateFilterSection: React.ReactElement<typeof FilterAndOrderingPageMobileFilterSection> | null = null;
        if (showState) {
            const stateLabel = <FormattedMessage id="CANDIDATE_LIST_HEADER.COLUMN_state.LABEL" />;

            stateColumn = (
                <div className="CandidateListHeader__column CandidateListHeader__column--state">
                    <span className="CandidateListHeader__column-control">
                        <FilterControl
                            id="candidate-list-header_filter-state"
                            title={stateLabel}
                            options={this.options.state}
                            values={filterValues.state}
                            onChange={handleChangeFilterControl.state}
                        />
                    </span>

                    <CandidateListHeaderColumnState />
                </div>
            );

            stateFilterSection = (
                <FilterAndOrderingPageMobileFilterSection
                    name="state"
                    title={stateLabel}
                    options={this.options.state}
                />
            );
        }

        return (
            <div className="CandidateListHeader">
                <div className="CandidateListHeader__columns">
                    <div className="CandidateListHeader__column CandidateListHeader__column--rating">
                        <span className="CandidateListHeader__column-label">{ratingLabel}</span>

                        <span className="CandidateListHeader__column-control">
                            <FilterControl
                                id="candidate-list-header_filter-rating"
                                title={ratingLabel}
                                options={this.options.rating}
                                values={filterValues.rating}
                                onChange={handleChangeFilterControl.rating}
                            >
                                {ratingLabel}
                            </FilterControl>
                        </span>
                    </div>
                    <div className="CandidateListHeader__column CandidateListHeader__column--created">
                        <span className="CandidateListHeader__column-label">{createdLabel}</span>
                        <span className="CandidateListHeader__column-control">
                            <OrderControl value={orderingValues.created} onChange={handleChangeOrderingControl.created}>
                                {createdLabel}
                            </OrderControl>
                        </span>
                    </div>
                    <div className="CandidateListHeader__column CandidateListHeader__column--candidate">
                        <span className="CandidateListHeader__column-label">{candidateLabel}</span>
                        <span className="CandidateListHeader__column-control">
                            <OrderControl
                                value={orderingValues.candidates}
                                onChange={handleChangeOrderingControl.candidates}
                            >
                                {candidateLabel}
                            </OrderControl>
                        </span>
                    </div>
                    {stateColumn}
                </div>

                <div className="CandidateListHeader__toggle">
                    <ListHeaderFilterToggle active={filtersOrOrderingActive(values)} onClick={this.handleClickToggle} />
                </div>

                <FilterAndOrderingPageMobile
                    id="candidate-list-header"
                    open={isMobileOpen}
                    onClose={this.handleCloseMobile}
                    values={values}
                    onChange={this.handleChangeFilterAndOrdering}
                >
                    <FilterAndOrderingPageMobileFilterSection
                        name="rating"
                        title={ratingLabel}
                        options={this.options.rating}
                    />

                    {stateFilterSection}
                    <FilterAndOrderingPageMobileOrderingSection
                        name="ordering"
                        title={candidateLabel}
                        options={this.options.ordering}
                    />
                </FilterAndOrderingPageMobile>
            </div>
        );
    }
}

export default CandidateListHeader;
