import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';

import { Localization } from '../../modules/types';
type BannerAction = { label: Localization | Localization['id']; onAction: (event: React.MouseEvent) => void };
type Props = {
    type: 'neutral' | 'info' | 'warning' | 'error';
    content: Localization | Localization['id'];
    actions: BannerAction[];
    illustration?: string | null;
    disableDismiss?: boolean;
    onDismissAction?: (event: React.MouseEvent) => void;
};

class Banner extends React.Component<Props> {
    renderIllustration(): React.ReactNode {
        const { illustration } = this.props;

        if (typeof illustration !== 'string' || illustration.length === 0) {
            return null;
        }

        return (
            <div className="tf-banner__illustration">
                <img src={illustration} role="presentation" />
            </div>
        );
    }

    renderContent() {
        const { content } = this.props as Props;

        if (typeof content === 'string' || typeof content === 'number') {
            return <FormattedMessage id={content} />;
        } else {
            return <FormattedMessage {...content} />;
        }
    }

    renderDismissAction(): React.ReactNode {
        const { disableDismiss, onDismissAction } = this.props;

        if (disableDismiss) {
            return null;
        }

        return (
            <Button type="button" typeStyle="flat" variationStyle="normal" onClick={onDismissAction}>
                <FormattedMessage id="NOTIFICATION_BANNER.ACTION.DISMISS" />
            </Button>
        );
    }

    renderCustomActions(): React.ReactNode {
        const { actions } = this.props;

        return actions.map((action, index) => {
            let label;
            if (typeof action.label === 'string' || typeof action.label === 'number') {
                label = <FormattedMessage id={action.label} />;
            } else {
                label = <FormattedMessage {...action.label} />;
            }

            return (
                <Button
                    key={`NotificationBannerAction-${index}`}
                    type="button"
                    typeStyle="flat"
                    variationStyle="normal"
                    onClick={action.onAction}
                >
                    {label}
                </Button>
            );
        });
    }

    render() {
        const { type } = this.props;

        const className = classNames('tf-banner', `tf-banner--${type}`);

        return (
            <div className={className}>
                <div className="tf-banner__content">
                    {this.renderIllustration()}
                    {this.renderContent()}
                </div>
                <div className="tf-banner__actions">
                    {this.renderDismissAction()}
                    {this.renderCustomActions()}
                </div>
            </div>
        );
    }
}

export default Banner;
