import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Tag.styles.scss';

const Tag = ({ label, icon, onDelete, hasError }) => (
    <div
        className={classNames('Tag', {
            'Tag--error': hasError
        })}
    >
        {icon && <div className="Tag__icon">{icon}</div>}
        <span className="Tag__label">{label}</span>
        {onDelete && <button type="button" className="Tag__delete-button" onClick={onDelete} />}
    </div>
);

Tag.propTypes = {
    label: PropTypes.string.isRequired,
    icon: PropTypes.node,
    onDelete: PropTypes.func,
    hasError: PropTypes.bool
};
Tag.defaultProps = {
    hasError: false
};

export default Tag;
