import { FieldMeta } from './hooks';

type HasSuccessMeta = { valid: boolean };

export function hasSuccess(value: any, meta: HasSuccessMeta): boolean {
    return !!value && meta.valid;
}

export type HasErrorMeta =
    | { active?: boolean; dirty?: boolean; invalid?: boolean; submitFailed?: boolean }
    | FieldMeta<{ active?: boolean; dirty?: boolean; invalid?: boolean; submitFailed?: boolean }>;

export function hasError(value: any, meta: HasErrorMeta): boolean {
    const invalid = meta.invalid ?? false;

    if (meta.submitFailed) {
        return invalid;
    }

    if ('onInactive' in meta) {
        /* We check if the field was invalid and dirty before it became inactive. We use this state because it allows us to
         * indicate that there is an error when the field was left – the moment when onInactive will be updated – and it
         * was left invalid.
         *
         * The other case in which we want to show that there is an error is as soon as the user submitted the form with
         * invalid values.
         */
        if (meta.onInactive.dirty && meta.onInactive.invalid) {
            return invalid;
        }
    } else {
        if (meta.dirty) {
            return invalid;
        }
    }

    return false;
}
