import apiClientV4 from '../../services/api/v4';
import { Response } from '../types';
import { ClientInfo } from './types';

export function fetchClientInfo() {
    return apiClientV4.get('/auth/user/client-info/');
}

export function updateClientInfo(clientInfo: Partial<ClientInfo>): Promise<Response<ClientInfo>> {
    return apiClientV4.patch<ClientInfo>('/auth/user/client-info/', clientInfo);
}
