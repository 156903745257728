import * as React from 'react';
import { animated } from 'react-spring/renderprops';
import { FormattedMessage } from 'react-intl';

import './TutorialMobileContent.style.scss';

import { ANIMATION_STEP_INIT, ANIMATION_STEP_NEXT_STEP, AnimationStep } from '../../constants';

import { getPropertyOrFallback } from '../../../../utils/object';

import DecorationLine from '../../../../components/DecorationLine';
import Button from '../../../../components/Button';

import TutorialMobileContentText from './TutorialMobileContentText';
import TutorialMobileContentGtc from './TutorialMobileContentGtc';

import ToggleAnimation from '../../../../components/ToggleAnimation';

import { emptyTransition, AnimationKeyframes, AnimationLeaveKeyframes } from './animations';

type Props = {
    open: boolean;
    step: number;
    animationState: AnimationStep;
    goingRight: boolean;
    showGtc: boolean;
    acceptedConditions: boolean;
    acceptGtc: () => void;
    skipTutorial: () => void;
    goToStep: (arg1: number) => void;
    toggleConditions: () => void;
};

const TutorialMobileContent = ({
    open,
    step,
    animationState,
    goingRight,
    showGtc,
    acceptedConditions,
    acceptGtc,
    skipTutorial,
    goToStep,
    toggleConditions
}: Props) => (
    <ToggleAnimation
        native
        open={open}
        from={emptyTransition}
        enter={emptyTransition}
        leave={
            step === 0
                ? AnimationLeaveKeyframes[ANIMATION_STEP_INIT]
                : AnimationLeaveKeyframes[ANIMATION_STEP_NEXT_STEP]
        }
    >
        {(leaveProps: any) => (
            <AnimationKeyframes native state={animationState}>
                {({ top, ...styleProps }) => (
                    <animated.div
                        className="TutorialMobileContent"
                        style={{
                            ...styleProps,
                            opacity: getPropertyOrFallback(leaveProps.opacity, styleProps.opacity),
                            transform: getPropertyOrFallback(leaveProps.top, top).interpolate(
                                (y) => `translateY(${y}px)`
                            )
                        }}
                    >
                        {step === 0 && (
                            <DecorationLine className="TutorialMobileContent__decoration-line" width={40} height={4} />
                        )}

                        <div className="TutorialMobileContent__text">
                            <TutorialMobileContentText open={open} step={step} goingRight={goingRight} />
                        </div>

                        {showGtc && step === 0 && (
                            <TutorialMobileContentGtc
                                step={step}
                                acceptedConditions={acceptedConditions}
                                toggleConditions={toggleConditions}
                            />
                        )}

                        <div className="TutorialMobileContent__actions">
                            {step === 0 && (
                                <Button
                                    data-testid="skip-tutorial-button"
                                    disabled={!acceptedConditions}
                                    typeStyle="flat"
                                    variationStyle="normal"
                                    onClick={() => {
                                        acceptGtc();
                                        skipTutorial();
                                    }}
                                >
                                    <FormattedMessage id={`TUTORIAL.STEP_${step}.SKIP_TUTORIAL`} />
                                </Button>
                            )}

                            <Button
                                data-testid="next-step-button"
                                key={`button-step-${step}`}
                                disabled={!acceptedConditions}
                                typeStyle="raised"
                                variationStyle="brand"
                                onClick={() => {
                                    if (step === 0) {
                                        acceptGtc();
                                    }
                                    goToStep(step + 1);
                                }}
                            >
                                <FormattedMessage
                                    id={
                                        step === 0
                                            ? `TUTORIAL.STEP_${step}.START_TUTORIAL`
                                            : `TUTORIAL.STEP_${step}.BUTTON_LABEL`
                                    }
                                />
                            </Button>
                        </div>
                    </animated.div>
                )}
            </AnimationKeyframes>
        )}
    </ToggleAnimation>
);

export default TutorialMobileContent;
