import * as React from 'react';

import './FullScreenMenu.style.scss';

export type MenuItemDescriptor = {
    component: string | React.ComponentType<any>;
    label: React.ReactNode;
    [propName: string]: any;
};

type Props = {
    menus: MenuItemDescriptor[][];
};

function FullScreenMenu({ menus: menuDescriptors, ...rest }: Props, ref: any) {
    const menuElements = menuDescriptors.reduce(
        (
            menuElements: React.ReactNode[],
            menuDescriptor: MenuItemDescriptor[],
            menuIndex: number
        ): React.ReactNode[] => {
            const nextMenuElements = menuDescriptor.map(
                (menuItemDescriptor: MenuItemDescriptor, itemIndex: number): React.ReactElement => {
                    const { component: Component, label, ...props } = menuItemDescriptor;

                    return (
                        <Component {...props} key={`item-${menuIndex}-${itemIndex}`} className="FullScreenMenu__item">
                            {label}
                        </Component>
                    );
                }
            );

            return [
                ...menuElements,
                menuIndex === 0 ? null : (
                    <div key={`separator-${menuIndex}`} className="FullScreenMenu__separator"></div>
                ),
                ...nextMenuElements
            ];
        },
        []
    );

    return (
        <div className="FullScreenMenu" ref={ref} {...rest}>
            {menuElements}
        </div>
    );
}

export default React.forwardRef(FullScreenMenu);
