import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../Button';

import './RequestDocumentsCTA.style.scss';

type Props = {
    onClickRequestDocuments: () => void;
};

function RequestDocumentsCTA({ onClickRequestDocuments }: Props) {
    return (
        <div className="RequestDocumentsCTA">
            <span className="RequestDocumentsCTA__text">
                <FormattedMessage id="REQUEST_DOCUMENTS_CTA.TEXT" />
            </span>
            <Button
                className="RequestDocumentsCTA__button"
                type="button"
                typeStyle="raised"
                variationStyle="brand"
                onClick={onClickRequestDocuments}
            >
                <FormattedMessage id="REQUEST_DOCUMENTS_CTA.BUTTON" />
            </Button>
        </div>
    );
}

export default RequestDocumentsCTA;
