import { Checkbox } from '@truffls/design-system-react';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useField } from '../../../../utils/form/react-final-form';
import JobFormFieldAnchor from '../../JobFormFieldAnchor';

import styles from './JobFormIsStartupFormField.module.scss';

export type JobFormIsStartupFormFieldValue = boolean;
export type JobFormIsStartupFormFieldName = 'is_startup';
export const JOB_EDIT_IS_STARTUP_FORM_FIELD_NAME: JobFormIsStartupFormFieldName = 'is_startup';

export type JobFormIsStartupFormFieldProps = {};

function JobFormIsStartupFormField({}: JobFormIsStartupFormFieldProps) {
    const {
        input: {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
            value: _,
            ...input
        }
    } = useField<JobFormIsStartupFormFieldValue, HTMLInputElement>(JOB_EDIT_IS_STARTUP_FORM_FIELD_NAME, {
        type: 'checkbox'
    });

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_IS_STARTUP_FORM_FIELD_NAME} />
            <Checkbox {...input} id="job-form-field-input-is_startup">
                <FormattedMessage id="JOB_EDIT.IS_STARTUP_FORM_FIELD.LABEL_TEXT" />
                <small className={classNames('help-block', styles.inlineHelp)}>
                    <FormattedMessage id="JOB_EDIT.IS_STARTUP_FORM_FIELD.HELP_TEXT" />
                </small>
            </Checkbox>
        </React.Fragment>
    );
}

export default JobFormIsStartupFormField;
