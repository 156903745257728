import * as React from 'react';

import {
    DragAndDropContainer,
    DragLayer,
    Draggable,
    DragStrategy,
    verticalDragStrategy,
    useDragEndItemMove
} from '../DragAndDrop';

export type SortableListRenderProps<Type> = {
    item: Type;
    index: number;
};

export type SortableListProps<Type = any> = {
    children: (props: SortableListRenderProps<Type>) => React.ReactElement;
    className?: string;
    disableSorting?: boolean;
    items: Type[];
    getIdFromItem?: (item: Type) => string;
    onMoveItem: (fromIndex: number, toIndex: number) => void;
    placeholder: React.ReactNode;
    strategy?: DragStrategy;
    type: string;
};

function SortableList<Type>({
    children,
    className,
    disableSorting,
    items,
    getIdFromItem = (item: Type) => String(item),
    onMoveItem,
    placeholder,
    strategy = verticalDragStrategy,
    type
}: SortableListProps<Type>) {
    const { items: itemsToRender, getIndexByItemId, onDragMove, onDragStart, onDragEnd } = useDragEndItemMove({
        items,
        onMoveItem,
        getIdFromItem
    });

    return (
        <div className={className} data-testid="sortable-list">
            <DragAndDropContainer>
                <DragLayer type={type} />

                {itemsToRender.map((item, index) => {
                    const id = getIdFromItem(item);

                    return (
                        <Draggable
                            key={id}
                            type={type}
                            dragStrategy={strategy}
                            id={id}
                            placeholder={placeholder}
                            getIndexByItemId={getIndexByItemId}
                            onDragStart={onDragStart}
                            onDragEnd={onDragEnd}
                            onDragMove={onDragMove}
                            disable={disableSorting}
                        >
                            {children({ item, index })}
                        </Draggable>
                    );
                })}
            </DragAndDropContainer>
        </div>
    );
}
export default SortableList;
