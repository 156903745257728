import { take, cancel, fork, put, call } from 'redux-saga/effects';
import { ApiError } from '../../../services/api/types';
import { isApiResponseError } from '../../../services/api/utils';
import { handleError } from '../../../services/errorHandler';
import { sendMessage as sendMessageSaga } from '../../conversations/saga';
import { ConversationId } from '../../conversations/types';
import { createPlaceholderTextMessage } from '../../conversations/utils';
import { candidateDelete, DeleteCandidateAction, DELETE_CANDIDATE } from '../actions';
import * as candidatesApi from '../api';

export function* deleteCandidateSaga(
    candidateId: number,
    conversationId: ConversationId,
    messageOwnerId: number,
    messageText: string
) {
    try {
        yield put(candidateDelete.request(candidateId));

        if (!!messageText) {
            const message = createPlaceholderTextMessage(messageOwnerId, messageText);

            yield call(sendMessageSaga, {
                conversationId,
                message
            });
        }

        yield call(candidatesApi.deleteCandidate, candidateId);

        yield put(candidateDelete.success(candidateId));
    } catch (error) {
        let errors: ApiError[] = [];

        if (isApiResponseError(error)) {
            errors = error.response.data.errors || [];
        } else {
            handleError(error);
        }

        yield put(candidateDelete.failure(candidateId, errors));
    }
}

export default function* watchDeleteCandidateSaga() {
    const tasks = {};

    while (true) {
        const action: DeleteCandidateAction = yield take(DELETE_CANDIDATE);
        const payload = action.payload;

        const taskId = payload.candidateId;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        tasks[taskId] = yield fork(
            deleteCandidateSaga,
            payload.candidateId,
            payload.conversationId,
            payload.messageOwnerId,
            payload.messageText
        );
    }
}
