import * as React from 'react';
import { LocationDescriptor } from 'history';

import Pagination from '../../../components/Pagination';

type Props = {
    totalCount: number;
    pageSize: number;
    activePage?: number;
    getLinkProps: (page: number) => { to: LocationDescriptor };
};

const CandidateListPagePagination = ({ totalCount, pageSize, activePage = 1, getLinkProps }: Props) => {
    const countOfPages = Math.max(1, Math.ceil(totalCount / pageSize));

    if (countOfPages === 1) {
        return null;
    }

    return <Pagination center countOfPages={countOfPages} activePage={activePage} getLinkProps={getLinkProps} />;
};

export default CandidateListPagePagination;
