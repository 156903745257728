import { Deferred } from '../../utils/promise';
import { createAction, createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';
import { ClientInfo } from './types';

const createActionType = createActionTypeCreator('CLIENT_INFO');

export const SET_CLIENT_INFO = createActionType('SET_CLIENT_INFO');
export const setClientInfo = createAction(SET_CLIENT_INFO, (clientInfo: ClientInfo) => {
    return {
        payload: {
            clientInfo
        }
    };
});
export type SetClientInfoAction = ReturnType<typeof setClientInfo>;

export const RESET_CLIENT_INFO = createActionType('RESET_CLIENT_INFO');
export const resetClientInfo = createAction(RESET_CLIENT_INFO);
export type ResetClientInfoAction = ReturnType<typeof resetClientInfo>;

export const CLIENT_INFO_FETCH = createRequestActionTypes(createActionType('CLIENT_INFO_FETCH'));
export const clientInfoFetch = {
    request: createAction(CLIENT_INFO_FETCH.REQUEST),
    success: createAction(CLIENT_INFO_FETCH.SUCCESS, (clientInfo: ClientInfo) => {
        return {
            payload: {
                clientInfo
            }
        };
    }),
    failure: createAction(CLIENT_INFO_FETCH.FAILURE, (errors = []) => {
        return {
            payload: {
                errors
            }
        };
    })
};
export type ClientInfoFetchActions$Request = ReturnType<typeof clientInfoFetch['request']>;
export type ClientInfoFetchActions$Success = ReturnType<typeof clientInfoFetch['success']>;
export type ClientInfoFetchActions$Failure = ReturnType<typeof clientInfoFetch['failure']>;

export const UPDATE_CLIENT_INFO = createActionType('UPDATE_CLIENT_INFO');
export const updateClientInfo = createAction(
    UPDATE_CLIENT_INFO,
    (clientInfoPartial: Partial<ClientInfo>, { promise }: { promise?: Deferred<ClientInfo> } = {}) => {
        return {
            payload: {
                clientInfo: clientInfoPartial
            },
            meta: {
                promise
            }
        };
    }
);
export type UpdateClientInfoAction = ReturnType<typeof updateClientInfo>;

export const CLIENT_INFO_UPDATE = createRequestActionTypes(createActionType('CLIENT_INFO_UPDATE'));
export const clientInfoUpdate = {
    request: createAction(CLIENT_INFO_UPDATE.REQUEST),
    success: createAction(CLIENT_INFO_UPDATE.SUCCESS, (clientInfo: ClientInfo) => {
        return {
            payload: {
                clientInfo
            }
        };
    }),
    failure: createAction(CLIENT_INFO_UPDATE.FAILURE, (errors = []) => {
        return {
            payload: {
                errors
            }
        };
    })
};
export type ClientInfoUpdateActions$Request = ReturnType<typeof clientInfoUpdate['request']>;
export type ClientInfoUpdateActions$Success = ReturnType<typeof clientInfoUpdate['success']>;
export type ClientInfoUpdateActions$Failure = ReturnType<typeof clientInfoUpdate['failure']>;
