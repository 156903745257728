import { createSelector } from 'reselect';

import { MOUNT } from './reducer';

import { Job, JobId } from '../jobs/types';
import { Candidate } from '../candidates/types';
import { Conversation, Message } from '../conversations/types';
import { File } from '../files/types';
import { CheckoutProcessState, CheckoutProcessStateId } from '../checkout/types';
import { Package } from '../packages/types';
import { SearchResult } from '../search/types';
import { Template } from '../templates/types';
import { State } from '../../store/reducer';
import {
    EntitiesState,
    EntityTypeStateEntitiesPartial,
    EntityTypeStateFetchStatusPartial,
    EntityTypeStateFetchErrorsPartial,
    EntityTypeStateCreateStatusPartial,
    EntityTypeStateCreateErrorsPartial,
    EntityTypeStateUpdateStatusPartial,
    EntityTypeStateUpdateErrorsPartial,
    EntityTypeStateDeleteStatusPartial,
    EntityTypeStateErrorsPartial,
    EntityTypeEntitesState,
    EntityTypeFetchStatusState,
    EntityTypeCreateStatusState,
    EntityTypeUpdateStatusState,
    EntityTypeDeleteStatusState,
    EntityTypeErrorsState
} from './reducer';
import { FetchStatus, CreateStatus, UpdateStatus, DeleteStatus, Errors } from '../types';
import { CREATE_STATUS } from '../constants';
import { JobEntityTypeState } from './reducer/jobs';
import { CandidateEntityTypeState } from './reducer/candidates';
import { FileEntityTypeState } from './reducer/files';
import { CheckoutProcessStateEntityTypeState } from './reducer/checkoutProcessStates';
import { PackageEntityTypeState } from './reducer/packages';
import { SearchResultEntityTypeState } from './reducer/searchResults';
import { TemplateEntityTypeState } from './reducer/templates';

// Typings
type EntityTypeEntitesStateGetter<T> = (state: State) => EntityTypeEntitesState<T>;
type EntityTypeFetchStatusStateGetter = (state: State) => EntityTypeFetchStatusState;
type EntityTypeFetchErrorsStateGetter = (state: State) => EntityTypeErrorsState;
type EntityTypeCreateStatusStateGetter = (state: State) => EntityTypeCreateStatusState;
type EntityTypeCreateErrorsStateGetter = (state: State) => EntityTypeErrorsState;
type EntityTypeUpdateStatusStateGetter = (state: State) => EntityTypeUpdateStatusState;
type EntityTypeUpdateErrorsStateGetter = (state: State) => EntityTypeErrorsState;
type EntityTypeDeleteStatusStateGetter = (state: State) => EntityTypeDeleteStatusState;
type EntityTypeErrorsStateGetter = (state: State) => EntityTypeErrorsState;

// To get the root state of the module
function getRoot(state: State): EntitiesState {
    return state[MOUNT];
}

// Helper to get the entities of an entity type state
function getEntityTypeEntites<T>(entityType: EntityTypeStateEntitiesPartial<T>): EntityTypeEntitesState<T> {
    return entityType.entities;
}
// Helper to get the fetch status of an entity type state
function getEntityTypeFetchStatus(entityType: EntityTypeStateFetchStatusPartial): EntityTypeFetchStatusState {
    return entityType.fetchStatus;
}
// Helper to get the create error of an entity type state
function getEntityTypeFetchErrors(entityType: EntityTypeStateFetchErrorsPartial): EntityTypeErrorsState {
    return entityType.fetchErrors;
}
// Helper to get the create status of an entity type state
function getEntityTypeCreateStatus(entityType: EntityTypeStateCreateStatusPartial): EntityTypeCreateStatusState {
    return entityType.createStatus;
}
// Helper to get the create error of an entity type state
function getEntityTypeCreateErrors(entityType: EntityTypeStateCreateErrorsPartial): EntityTypeErrorsState {
    return entityType.createErrors;
}
// Helper to get the update status of an entity type state
function getEntityTypeUpdateStatus(entityType: EntityTypeStateUpdateStatusPartial): EntityTypeUpdateStatusState {
    return entityType.updateStatus;
}
// Helper to get the update error of an entity type state
function getEntityTypeUpdateErrors(entityType: EntityTypeStateUpdateErrorsPartial): EntityTypeErrorsState {
    return entityType.updateErrors;
}
// Helper to get the delete status of an entity type state
function getEntityTypeDeleteStatus(entityType: EntityTypeStateDeleteStatusPartial): EntityTypeDeleteStatusState {
    return entityType.deleteStatus;
}
// Helper to get the delete status of an entity type state
function getEntityTypeErrors(entityType: EntityTypeStateErrorsPartial): EntityTypeErrorsState {
    return entityType.errors;
}

// Selectors for entity type job
const getEntityTypeJob = createSelector(getRoot, (entityTypes: EntitiesState): JobEntityTypeState => entityTypes.jobs);

// Selector for entities of entity type job
const getJobsEntities: EntityTypeEntitesStateGetter<Job> = createSelector(getEntityTypeJob, getEntityTypeEntites);

// Selector for fetch status of entity type job
const getJobsFetchStatus: EntityTypeFetchStatusStateGetter = createSelector(getEntityTypeJob, getEntityTypeFetchStatus);

// Selector for create error of entity type job
const getJobsFetchErrors: EntityTypeFetchErrorsStateGetter = createSelector(getEntityTypeJob, getEntityTypeFetchErrors);

// Selector for create status of entity type job
const getJobsCreateStatus: EntityTypeCreateStatusStateGetter = createSelector(
    getEntityTypeJob,
    getEntityTypeCreateStatus
);

// Selector for create error of entity type job
const getJobsCreateErrors: EntityTypeCreateErrorsStateGetter = createSelector(
    getEntityTypeJob,
    getEntityTypeCreateErrors
);

// Selector for update status of entity type job
const getJobsUpdateStatus: EntityTypeUpdateStatusStateGetter = createSelector(
    getEntityTypeJob,
    getEntityTypeUpdateStatus
);

// Selector for create error of entity type job
const getJobsUpdateErrors: EntityTypeUpdateErrorsStateGetter = createSelector(
    getEntityTypeJob,
    getEntityTypeUpdateErrors
);

// Selector for delete status of entity type job
const getJobsDeleteStatus: EntityTypeDeleteStatusStateGetter = createSelector(
    getEntityTypeJob,
    getEntityTypeDeleteStatus
);

// Export it with different name
export const getJobs = getJobsEntities;

// Get a single job by id
export function getJob(state: State, id: number): Job | null {
    return getJobsEntities(state)[id] || null;
}
// Get a single job by a local create id
export function getJobByLocalCreateId(state: State, localCreateId: string): Job | null {
    return getJobsEntities(state)[localCreateId] || null;
}
// Get a fetch status of a job
export function getJobFetchStatus(state: State, id: number): FetchStatus {
    return getJobsFetchStatus(state)[id] || 'none';
}
export function getJobFetchErrors(state: State, id: number): Errors | null {
    return getJobsFetchErrors(state)[id] || null;
}
// Get a create status of a job by a local createId
export function getJobCreateStatus(state: State, localCreateId: string): CreateStatus {
    return getJobsCreateStatus(state)[localCreateId] || CREATE_STATUS.NONE;
}
// Get a create status of a job by a local createId
export function getJobCreateErrors(state: State, localCreateId: string): Errors | null {
    return getJobsCreateErrors(state)[localCreateId] || null;
}
// Get a update status of a job
export function getJobUpdateStatus(state: State, id: JobId): UpdateStatus {
    return getJobsUpdateStatus(state)[id] || 'none';
}
// Get a create status of a job by a local createId
export function getJobUpdateErrors(state: State, id: JobId): Errors | null {
    return getJobsUpdateErrors(state)[id] || null;
}
// Get a delete status of a job
export function getJobDeleteStatus(state: State, id: number): DeleteStatus {
    return getJobsDeleteStatus(state)[id] || 'none';
}

// Selectors for entity type candidate
const getEntityTypeCandidate = createSelector(
    getRoot,
    (entityTypes: EntitiesState): CandidateEntityTypeState => entityTypes.candidates
);

// Selector for entities of entity type candidate
const getCandidatesEntities: EntityTypeEntitesStateGetter<Candidate> = createSelector(
    getEntityTypeCandidate,
    getEntityTypeEntites
);

// Selector for fetch status of entity type candidate
const getCandidatesFetchStatus: EntityTypeFetchStatusStateGetter = createSelector(
    getEntityTypeCandidate,
    getEntityTypeFetchStatus
);

// Selector for update status of entity type candidate
const getCandidatesUpdateStatus: EntityTypeUpdateStatusStateGetter = createSelector(
    getEntityTypeCandidate,
    getEntityTypeUpdateStatus
);

// Selector for delete status of entity type candidate
const getCandidatesDeleteStatus: EntityTypeDeleteStatusStateGetter = createSelector(
    getEntityTypeCandidate,
    getEntityTypeDeleteStatus
);

// Selector for errors of entity type candidate
const getCandidatesErrors: EntityTypeErrorsStateGetter = createSelector(getEntityTypeCandidate, getEntityTypeErrors);

// Export it with different name
export const getCandidates = getCandidatesEntities;

// Get a single candidate by id
export function getCandidate(state: State, id: number): Candidate | null {
    return getCandidatesEntities(state)[id] || null;
}
// Get a fetch status of a candidate
export function getCandidateFetchStatus(state: State, id: number): FetchStatus {
    return getCandidatesFetchStatus(state)[id] || 'none';
}
// Get a update status of a candidate
export function getCandidateUpdateStatus(state: State, id: number): UpdateStatus {
    return getCandidatesUpdateStatus(state)[id] || 'none';
}
// Get a delete status of a candidate
export function getCandidateDeleteStatus(state: State, id: number): DeleteStatus {
    return getCandidatesDeleteStatus(state)[id] || 'none';
}
// Get errors of a candidate
export function getCandidateErrors(state: State, id: number): Errors | null {
    return getCandidatesErrors(state)[id] || null;
}

// Selectors for entity type file
const getEntityTypeFile = createSelector(
    getRoot,
    (entityTypes: EntitiesState): FileEntityTypeState => entityTypes.files
);

// Selector for entities of entity type file
const getFilesEntities: EntityTypeEntitesStateGetter<File> = createSelector(getEntityTypeFile, getEntityTypeEntites);

// Export it with different name
export const getFiles = getFilesEntities;

// Get a single file by id
export function getFile(state: State, id: number): File | null {
    return getFilesEntities(state)[id] || null;
}

// Selectors for entity type conversation
export const getConversationsEntities: (state: State) => EntityTypeEntitesState<Conversation> = createSelector(
    getRoot,
    (entities: EntitiesState): EntityTypeEntitesState<Conversation> => entities.conversations
);

// Selectors for entity type messages
export const getMessagesEntities: (state: State) => EntityTypeEntitesState<Message> = createSelector(
    getRoot,
    (entities: EntitiesState): EntityTypeEntitesState<Message> => entities.messages
);

// Selectors for entity type checkoutProcessState
const getEntityTypeCheckoutProcessState = createSelector(
    getRoot,
    (entityTypes: EntitiesState): CheckoutProcessStateEntityTypeState => entityTypes.checkoutProcessStates
);

// Selector for entities of entity type checkoutProcessState
const getCheckoutProcessStateEntities: EntityTypeEntitesStateGetter<CheckoutProcessState> = createSelector(
    getEntityTypeCheckoutProcessState,
    getEntityTypeEntites
);

// Export it with different name
export const getCheckoutProcessStates = getCheckoutProcessStateEntities;

export function getCheckoutProcessState(state: State, id: CheckoutProcessStateId): CheckoutProcessState | null {
    return getCheckoutProcessStates(state)[id] || null;
}

// Selectors for entity type package
const getEntityTypePackages = createSelector(
    getRoot,
    (entityTypes: EntitiesState): PackageEntityTypeState => entityTypes.packages
);

// Selector for entities of entity type package
const getPackagesEntities: EntityTypeEntitesStateGetter<Package> = createSelector(
    getEntityTypePackages,
    getEntityTypeEntites
);

// Selector for fetch status of entity type candidate
const getPackagesFetchStatus: EntityTypeFetchStatusStateGetter = createSelector(
    getEntityTypePackages,
    getEntityTypeFetchStatus
);

// Export it with different name
export const getPackages = getPackagesEntities;

export function getPackage(state: State, id: number): Package | null {
    return getPackagesEntities(state)[id] || null;
}
// Get a fetch status of a package
export function getPackageFetchStatus(state: State, id: number): FetchStatus {
    return getPackagesFetchStatus(state)[id] || 'none';
}

// Selectors for entity type search result
const getEntityTypeSearchResult = createSelector(
    getRoot,
    (entityTypes: EntitiesState): SearchResultEntityTypeState => entityTypes.searchResults
);

// Selector for entities of entity search result
export const getSearchResultEntities: EntityTypeEntitesStateGetter<SearchResult> = createSelector(
    getEntityTypeSearchResult,
    getEntityTypeEntites
);

// Selectors for entity type templates
const getEntityTypeTemplate = createSelector(
    getRoot,
    (entityTypes: EntitiesState): TemplateEntityTypeState => entityTypes.templates
);

// Selector for entities of entity template file
export const getTemplatesEntities: EntityTypeEntitesStateGetter<Template> = createSelector(
    getEntityTypeTemplate,
    getEntityTypeEntites
);

export const getTemplatesEntitiesFetchStatus: EntityTypeFetchStatusStateGetter = createSelector(
    getEntityTypeTemplate,
    getEntityTypeFetchStatus
);

export const getTemplatesEntitiesUpdateStatus: EntityTypeUpdateStatusStateGetter = createSelector(
    getEntityTypeTemplate,
    getEntityTypeUpdateStatus
);

export const getTemplatesEntitiesDeleteStatus: EntityTypeDeleteStatusStateGetter = createSelector(
    getEntityTypeTemplate,
    getEntityTypeDeleteStatus
);
