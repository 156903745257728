import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import CandidateResumeSection from '../CandidateResumeSection';
import CandidateResumeTagItem from '../CandidateResumeTagItem';

import { Applicant } from '../../../modules/candidates/types';

type Props = {
    applicant: Applicant;
};

const ResumeSkills = ({ applicant }: Props) => {
    const { resume } = applicant;
    const skills = resume.skills;

    if (skills.length === 0) {
        return null;
    }

    return (
        <CandidateResumeSection>
            <h1>
                <FormattedMessage id="RESUME_SKILLS.TITLE" />
            </h1>

            <ul>
                {skills.map((skill, index) => (
                    <CandidateResumeTagItem key={index}>
                        <span>{skill.skill}</span>
                    </CandidateResumeTagItem>
                ))}
            </ul>
        </CandidateResumeSection>
    );
};

export default ResumeSkills;
