import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import DialogV2 from '../../../components/DialogV2';
import DialogV2Title from '../../../components/DialogV2/DialogTitle';
import DialogV2Content from '../../../components/DialogV2/DialogContent';
import DialogV2Actions from '../../../components/DialogV2/DialogActions';
import Button from '../../../components/Button';
import LoaderButtonV2 from '../../../components/LoaderButtonV2';

type Props = {
    open: boolean;
    submittingRequest: boolean;
    submitEnabled: boolean;
    onClose: () => void;
    onSubmit: (event: React.FormEvent) => void;
    children: React.ReactNode;
};

const RequestDocumentsViewDesktop = ({
    open,
    submittingRequest,
    submitEnabled,
    onClose,
    onSubmit,
    children
}: Props) => (
    <DialogV2 open={open} onClose={onClose}>
        <DialogV2Title>
            <FormattedMessage id="REQUEST_DOCUMENTS_VIEW.TITLE.SHORT" />
        </DialogV2Title>
        <form onSubmit={onSubmit}>
            <DialogV2Content>{children}</DialogV2Content>
            <DialogV2Actions>
                <Button type="button" onClick={onClose} typeStyle="flat" variationStyle="brand">
                    <FormattedMessage id="REQUEST_DOCUMENTS_VIEW.CANCEL" />
                </Button>
                <LoaderButtonV2
                    type="submit"
                    disabled={submitEnabled}
                    loading={submittingRequest}
                    typeStyle="flat"
                    variationStyle="brand"
                >
                    <FormattedMessage id="REQUEST_DOCUMENTS_VIEW.SUBMIT" />
                </LoaderButtonV2>
            </DialogV2Actions>
        </form>
    </DialogV2>
);

export default RequestDocumentsViewDesktop;
