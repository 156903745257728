import * as React from 'react';
import classNames from 'classnames';

type Props = {
    icon: React.ReactElement<React.HTMLAttributes<'svg'>, 'svg'>;
    children: React.ReactNode;
};

function JobDetailsMetaItem(props: Props) {
    const { icon, children } = props;

    return (
        <li className="JobDetailsMeta__item">
            {React.cloneElement(icon, {
                className: classNames(icon.props.className, 'JobDetailsMeta__item-icon')
            })}

            <span className="JobDetailsMeta__item-content">{children}</span>
        </li>
    );
}

export default JobDetailsMetaItem;
