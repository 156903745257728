import React from 'react';
import { Job } from '../../modules/jobs/types';
import JobDetailsMetaActiveUntil from './JobDetailsMetaActiveUntil';
import JobDetailsMetaAdType from './JobDetailsMetaAdType';
import JobDetailsMetaLocation from './JobDetailsMetaLocation';
import JobDetailsMetaStatus from './JobDetailsMetaStatus';
import './JobDetailsMeta.style.scss';

export type JobDetailsMetaProps = {
    job: Job;
};

function JobDetailsMeta({ job }: JobDetailsMetaProps) {
    return (
        <section>
            <ul className="JobDetailsMeta__list">
                <JobDetailsMetaStatus job={job} />
                <JobDetailsMetaLocation job={job} />
                <JobDetailsMetaActiveUntil job={job} />
                <JobDetailsMetaAdType job={job} />
            </ul>
        </section>
    );
}

export default JobDetailsMeta;
