import { createBrowserPreferredLanguageStorage } from '../preferredLanguageStorage/browser';
import { Locale, resolveLocale } from './index';

export function getBrowserRequestedLocales(): string[] {
    const preferredLanguageClientStorage = createBrowserPreferredLanguageStorage();
    const preferredLanguageFromClientStorage = preferredLanguageClientStorage.get();

    let preferredLanguagesFromNavigator: string[] = [];
    if (Array.isArray(navigator.languages)) {
        preferredLanguagesFromNavigator = [...navigator.languages];
    } else if (!!navigator.language) {
        preferredLanguagesFromNavigator = [navigator.language];
    } else if (typeof !!(navigator as any).userLanguage === 'string') {
        preferredLanguagesFromNavigator = [(navigator as any).userLanguage];
    }

    return [
        ...(!!preferredLanguageFromClientStorage ? [preferredLanguageFromClientStorage] : []),
        ...preferredLanguagesFromNavigator
    ];
}

export function getBrowserRequestedResolvedLocale(): Locale {
    const requestedLocales = getBrowserRequestedLocales();
    return resolveLocale(requestedLocales);
}
