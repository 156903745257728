/* global NodeJS */

export function instanceOfNodeError<T extends new (...args: any) => Error>(
    value: unknown,
    errorType: T
): value is InstanceType<T> & NodeJS.ErrnoException {
    return value instanceof errorType;
}

export type ExtraDataErrorExtra = Record<string, unknown>;

export class ExtraDataError extends Error {
    extra?: ExtraDataErrorExtra;

    constructor(message: string, extra?: ExtraDataErrorExtra) {
        super(message);
        this.extra = extra;
    }
}
