import * as React from 'react';
import { connect } from 'react-redux';
import { initialize as initializeForm, destroy as destroyForm, formValueSelector } from 'redux-form';

import { handleError } from '../../services/errorHandler';

import SignupFormStepLogin from './SignupFormStepLogin';
import SignupFormStepProfile from './SignupFormStepProfile';
import SignupFormStepEmailChange from './SignupFormStepEmailChange';
import SignupFormStepIndicator from '../../components/SignupFormStepIndicator';
import SignupFormEmailInUse from '../../components/SignupFormEmailInUse';

import { getRecruiter } from '../../modules/recruiters/selectors';
import { getSignupStatus, getRequestTokenRequestStatus } from '../../modules/authentication/selectors';
import { isRequestTokenRequestRequesting } from '../../modules/authentication/utils';

import {
    STEP_LOGIN,
    STEP_PROFILE,
    STEPS,
    FORM_NAME,
    EMAIL_TAKEN_DECISION_REQUEST_TOKEN,
    EMAIL_TAKEN_DECISION_DIFFERENT_EMAIL,
    FormValues
} from './constants';
import { start, setStep, cancel, openLogin, submit, reset, setEmailTakenDecision } from './actions';
import { getStep, getEmailTakenDecision } from './selectors';
import { getPreviousStep, getNextStep } from './utils';

// We map every step component to their corresponding step identifier
const STEP_COMPONENTS = {
    [STEP_LOGIN]: SignupFormStepLogin,
    [STEP_PROFILE]: SignupFormStepProfile
};

// Types
import { StatusState as SignupStatus } from '../../modules/authentication/reducer/signup';
import { Recruiter } from '../../modules/recruiters/types';
import { RequestStatus } from '../../modules/types';
import { Step, EmailTakenDecision } from './constants';

type ConnectedProps = {
    recruiter: Recruiter | null;
    signupStatus: SignupStatus;

    step: Step;
    email: string;

    emailTakenDecision: EmailTakenDecision;

    requestTokenRequestStatus: RequestStatus;
};

type ConnectedActions = {
    initializeForm: typeof initializeForm;
    destroyForm: typeof destroyForm;

    start: typeof start;
    setStep: typeof setStep;
    submit: typeof submit;
    cancel: typeof cancel;
    openLogin: typeof openLogin;
    reset: typeof reset;

    setEmailTakenDecision: typeof setEmailTakenDecision;
};

type Props = ConnectedProps & ConnectedActions & {};

class SignupForm extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleBack = this.handleBack.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRequestToken = this.handleRequestToken.bind(this);
        this.handleDifferentEmail = this.handleDifferentEmail.bind(this);
        this.handleOpenLogin = this.handleOpenLogin.bind(this);
    }

    componentDidMount() {
        const { recruiter, initializeForm, start } = this.props;

        /*
         * We initialize the form values with values from the recruiter if the recruiter is logged in
         */
        const values: Partial<FormValues> = {};
        if (!!recruiter) {
            values.email = recruiter.email;
            values.company = recruiter.company;
        }

        initializeForm(FORM_NAME, values);
        start();
    }

    componentWillUnmount() {
        const { signupStatus, destroyForm, reset, cancel } = this.props;

        if (signupStatus !== 'signedUp') {
            cancel();
        }

        /*
         * We destroy the form because the child components don't destory the form by themself.
         */
        destroyForm(FORM_NAME);

        reset();
    }

    handleBack() {
        const { step: currentStep, setStep } = this.props;

        const previousStep = getPreviousStep(currentStep);

        if (currentStep === previousStep) {
            // We do nothing because there is no previous step
            return;
        }

        setStep(previousStep);
    }

    handleSubmit(formData: any) {
        const { step: currentStep, setStep, submit } = this.props;

        const nextStep = getNextStep(currentStep);

        if (currentStep === nextStep) {
            submit(formData);
            return;
        }

        setStep(nextStep);
    }

    handleRequestToken() {
        this.props.setEmailTakenDecision(EMAIL_TAKEN_DECISION_REQUEST_TOKEN);
    }

    handleDifferentEmail() {
        this.props.setEmailTakenDecision(EMAIL_TAKEN_DECISION_DIFFERENT_EMAIL);
    }

    handleOpenLogin() {
        this.props.openLogin();
    }

    render() {
        const { signupStatus, step, email, emailTakenDecision, requestTokenRequestStatus, recruiter } = this.props;

        if (signupStatus === 'failed' && emailTakenDecision !== EMAIL_TAKEN_DECISION_DIFFERENT_EMAIL) {
            const loading = isRequestTokenRequestRequesting(requestTokenRequestStatus);
            return (
                <SignupFormEmailInUse
                    email={email}
                    loading={loading}
                    onClickRequestToken={this.handleRequestToken}
                    onClickChangeEmail={this.handleDifferentEmail}
                />
            );
        } else if (signupStatus !== 'signedUp' && emailTakenDecision === EMAIL_TAKEN_DECISION_DIFFERENT_EMAIL) {
            return <SignupFormStepEmailChange onSubmit={this.handleSubmit} />;
        }

        const StepComponent = STEP_COMPONENTS[step] || null;

        if (StepComponent === null) {
            handleError(new Error(`No component registered for step "${step}"`));
            return null;
        }

        const forTrial = !!recruiter && !recruiter.signed_up;

        return (
            <React.Fragment>
                {!forTrial && <SignupFormStepIndicator steps={STEPS} currentStep={step} />}
                <StepComponent forTrial={forTrial} onBack={this.handleBack} onSubmit={this.handleSubmit} />
            </React.Fragment>
        );
    }
}

const getFieldValue = formValueSelector(FORM_NAME);

function mapStateToProps(state: any): ConnectedProps {
    return {
        step: getStep(state),

        recruiter: getRecruiter(state),
        email: getFieldValue(state, 'email'),

        signupStatus: getSignupStatus(state),

        emailTakenDecision: getEmailTakenDecision(state),

        requestTokenRequestStatus: getRequestTokenRequestStatus(state)
    };
}

const mapDispatchToProps: ConnectedActions = {
    initializeForm,
    destroyForm,

    start,
    setStep,
    submit,
    cancel,
    openLogin,
    reset,

    setEmailTakenDecision
};

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm);
