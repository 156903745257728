import isEmpty from 'validator/lib/isEmpty';
import isEmail from 'validator/lib/isEmail';
import baseValidators from 'validator';

import { sequence, negate } from '../function';
import { toString, convertFalseToEmptyString, trimString } from './transformers';

const PHONENUMBER_PATTERN = /^\+[0-9]{1,3}[0-9_\- \(\)]+$/;

/**
 * A factory method which returns a function which use the passed validate function to return
 * the passed name if the value is not valid. redux-form use the returned string as indication
 * which validation failed.
 * @method createFormValidator
 * @param  {String}            name     The name of the form validator
 * @param  {Function}          validate The function which will be used to validate
 * @return {Function}                   The form validation function
 */
export const createFormValidator = (name, validate) => {
    return (value, allValues, props) => (validate(value, allValues, props) ? undefined : name);
};

export const createMinLength = (minLength) => {
    const validate = (value) => value.length >= minLength;

    return createFormValidator('minLength', sequence(toString, validate));
};

export const createMaxLength = (maxLength) => {
    const validate = (value) => value.length <= maxLength;

    return createFormValidator('maxLength', sequence(toString, validate));
};

export function createArrayMinLength(minLength) {
    const validate = (value) => value.length >= minLength;

    return createFormValidator('minLength', validate);
}

export function createArrayMaxLength(maxLength) {
    const validate = (value) => value.length <= maxLength;

    return createFormValidator('maxLength', validate);
}

export const isGreaterThan = (field) => {
    const validate = (value, allValues) => {
        if (!~Object.keys(allValues).indexOf(field)) {
            throw new Error(`Field ${field} doesn't exist in values`);
        }

        return allValues[field] === null || value > allValues[field];
    };

    return createFormValidator('greaterThan', validate);
};

export const isWebUrl = createFormValidator('web-url', (value) => {
    return baseValidators.isURL(value, {
        protocols: ['http', 'https'],
        require_protocol: true
    });
});

const isPhoneNumber = (number) => {
    const length = number.replace(/[^0-9]/g, '').length;
    const correctLength = length >= 4 && length <= 15;
    const correctPattern = PHONENUMBER_PATTERN.test(number);

    return correctLength && correctPattern;
};

// Create form validator functions
const required = createFormValidator(
    'required',
    sequence(convertFalseToEmptyString, toString, trimString, negate(isEmpty))
);

const email = createFormValidator('email', sequence(toString, isEmail));

const phoneNumber = createFormValidator('phoneNumber', isPhoneNumber);

// Export form validators
export { required as isRequired, email as isEmail, phoneNumber as isPhoneNumber };
