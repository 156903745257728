type FactoryReturnType<T extends () => any | (() => PromiseLike<any>)> = T extends () => infer R
    ? R
    : T extends PromiseLike<infer R>
    ? R
    : never;

export function createSingleton<Factory extends () => any>(factory: Factory) {
    let instance: FactoryReturnType<Factory>;

    return (): FactoryReturnType<Factory> => {
        if (!instance) {
            instance = factory();
        }

        return instance;
    };
}
