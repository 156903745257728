import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './MessageItemInfo.style.scss';

export type MessageItemInfoProps = {
    text: string;
    useLegacyTheme?: boolean;
};

function MessageItemInfo({ text, useLegacyTheme }: MessageItemInfoProps) {
    return (
        <div
            className={classNames('MessageItemInfo', {
                'MessageItemInfo--legacy-theme': useLegacyTheme
            })}
        >
            <div className="MessageItemInfo__body">
                <div className="MessageItemInfo__note">
                    <FormattedMessage id="MESSAGE_ITEM.INFO.NOTE" />
                </div>
                <span className="MessageItemInfo__content">{text}</span>
            </div>
        </div>
    );
}

export default MessageItemInfo;
