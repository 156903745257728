import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ListHeaderFilterToggle from '../ListHeaderFilterToggle';
import FilterControl from '../FilterControl';
import OrderControl from '../OrderControl';
import FilterAndOrderingPageMobile from '../FilterAndOrderingPageMobile';
import FilterAndOrderingPageMobileFilterSection from '../FilterAndOrderingPageMobile/FilterSection';
import FilterAndOrderingPageMobileOrderingSection from '../FilterAndOrderingPageMobile/OrderingSection';

import {
    determinateOrder,
    determinateFieldNameWithOrder,
    createOrdering,
    createOrderingByFieldNameWithOrder
} from './utils';

import { FilterOptions, FilterValues, OrderingField, Ordering, OrderValue } from './constants';

import './style.scss';

type Props = {
    name: string;

    onChangeFilterAndOrdering: (filterValues: FilterValues, ordering: Ordering) => void;

    filterOptions: FilterOptions;
    filterValues: FilterValues;
    onChangeFilters: (filterValues: FilterValues) => void;

    ordering: Ordering;
    onChangeOrdering: (ordering: Ordering) => void;
};

type State = {
    isMobileOpen: boolean;
};

class JobListHeader extends React.Component<Props, State> {
    orderingOptions: { value: string; label: React.ReactNode }[];

    handleChangeTitleOrdering: (value: OrderValue) => void;
    handleChangeCandidatesOrdering: (value: OrderValue) => void;
    handleChangeMessagesOrdering: (value: OrderValue) => void;

    constructor(props: Props) {
        super(props);

        this.state = {
            isMobileOpen: false
        };

        this.orderingOptions = [
            { value: 'title-asc', label: <FormattedMessage id="JOB_LIST_HEADER.ORDERING_OPTION.TITLE_ASC" /> },
            { value: 'title-desc', label: <FormattedMessage id="JOB_LIST_HEADER.ORDERING_OPTION.TITLE_DESC" /> },
            {
                value: 'candidates-desc',
                label: <FormattedMessage id="JOB_LIST_HEADER.ORDERING_OPTION.CANDIDATES_DESC" />
            },

            { value: 'candidates-asc', label: <FormattedMessage id="JOB_LIST_HEADER.ORDERING_OPTION.CANDIDATES_ASC" /> }
        ];

        if (props.name === 'news') {
            this.orderingOptions.push(
                {
                    value: 'messages-desc',
                    label: <FormattedMessage id="JOB_LIST_HEADER.ORDERING_OPTION.MESSAGES_DESC" />
                },

                { value: 'messages-asc', label: <FormattedMessage id="JOB_LIST_HEADER.ORDERING_OPTION.MESSAGES_ASC" /> }
            );
        }

        this.handleClickToggle = this.handleClickToggle.bind(this);
        this.handleCloseMobile = this.handleCloseMobile.bind(this);

        this.handleChangeFilterAndOrdering = this.handleChangeFilterAndOrdering.bind(this);
        this.handleChangeTitleOrdering = this.handleChangeOrdering.bind(this, 'title');
        this.handleChangeCandidatesOrdering = this.handleChangeOrdering.bind(this, 'candidates');
        this.handleChangeMessagesOrdering = this.handleChangeOrdering.bind(this, 'messages');
    }

    handleClickToggle(event: React.MouseEvent) {
        event.preventDefault();

        this.setState({
            isMobileOpen: true
        });
    }

    handleCloseMobile() {
        this.setState({
            isMobileOpen: false
        });
    }

    handleChangeFilterAndOrdering(params: { [name: string]: any }) {
        const { state: filterValues, ordering: orderingValue } = params;

        let ordering = createOrderingByFieldNameWithOrder(orderingValue);

        this.setState({
            isMobileOpen: false
        });

        this.props.onChangeFilterAndOrdering(filterValues, ordering);
    }

    handleChangeOrdering(fieldName: OrderingField, value: OrderValue) {
        this.props.onChangeOrdering(createOrdering(fieldName, value));
    }

    render() {
        const {
            name,

            filterOptions,
            filterValues,
            onChangeFilters,

            ordering
        } = this.props;

        const { isMobileOpen } = this.state;

        const stateLabel = <FormattedMessage id="JOB_LIST_HEADER.COLUMN.state" />;

        let messagesLabel = <FormattedMessage id="JOB_LIST_HEADER.COLUMN.messages" />;
        if (name !== 'management') {
            messagesLabel = (
                <OrderControl
                    value={determinateOrder('messages', ordering)}
                    onChange={this.handleChangeMessagesOrdering}
                >
                    {messagesLabel}
                </OrderControl>
            );
        }

        return (
            <div className="JobListHeader">
                <div className="JobListHeader__toggle">
                    <ListHeaderFilterToggle
                        active={!!filterValues.length || !!ordering}
                        onClick={this.handleClickToggle}
                    />
                </div>

                <div className="JobListHeader__columns">
                    <div className="JobListHeader__column JobListHeader__column--state">
                        <FilterControl
                            title={stateLabel}
                            id={`job-list-header-filter_${name}`}
                            options={filterOptions}
                            values={filterValues}
                            onChange={onChangeFilters}
                        >
                            {stateLabel}
                        </FilterControl>
                    </div>
                    <div className="JobListHeader__column JobListHeader__column--title">
                        <OrderControl
                            value={determinateOrder('title', ordering)}
                            onChange={this.handleChangeTitleOrdering}
                        >
                            <FormattedMessage id="JOB_LIST_HEADER.COLUMN.title" />
                        </OrderControl>
                    </div>
                    <div className="JobListHeader__column JobListHeader__column--candidates">
                        <OrderControl
                            value={determinateOrder('candidates', ordering)}
                            onChange={this.handleChangeCandidatesOrdering}
                        >
                            <FormattedMessage id="JOB_LIST_HEADER.COLUMN.candidates" />
                        </OrderControl>
                    </div>
                    <div className="JobListHeader__column JobListHeader__column--messages">{messagesLabel}</div>
                </div>

                <FilterAndOrderingPageMobile
                    id={`job-list-header-filter-ordering_${name}`}
                    open={isMobileOpen}
                    onClose={this.handleCloseMobile}
                    values={{
                        state: filterValues,
                        ordering: determinateFieldNameWithOrder(ordering)
                    }}
                    onChange={this.handleChangeFilterAndOrdering}
                >
                    <FilterAndOrderingPageMobileFilterSection
                        name="state"
                        title={<FormattedMessage id="JOB_LIST_HEADER.FILTER_AND_ORDERING.STATE_TITLE" />}
                        options={filterOptions}
                    />

                    <FilterAndOrderingPageMobileOrderingSection
                        name="ordering"
                        title={<FormattedMessage id="JOB_LIST_HEADER.FILTER_AND_ORDERING.ORDERING_TITLE" />}
                        options={this.orderingOptions}
                    />
                </FilterAndOrderingPageMobile>
            </div>
        );
    }
}

export default JobListHeader;
