import Cookies, { CookieAttributes } from 'js-cookie';

const CookiesWithoutEncoding = Cookies.withConverter({
    read: (value: string): string => {
        return value;
    },
    write: (value): string => {
        if (typeof value === 'object') {
            return JSON.stringify(value);
        }

        return value;
    }
});

export type CookieOptions = CookieAttributes;

export function get(name: string): string | undefined {
    return CookiesWithoutEncoding.get(name);
}

export function set(name: string, value: string, options: CookieOptions = {}): string | undefined {
    return CookiesWithoutEncoding.set(name, value, options);
}

export function remove(name: string, options: CookieOptions = {}): void {
    CookiesWithoutEncoding.remove(name, options);
}

export function isEnabled(): boolean {
    if (typeof navigator === 'undefined') {
        return false;
    }

    let cookieEnabled = !!navigator.cookieEnabled;

    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
        const testCookie = 'test_' + Date.now();

        document.cookie = testCookie;
        cookieEnabled = document.cookie.indexOf(testCookie) >= 0;

        if (cookieEnabled) {
            document.cookie = testCookie + '; expires=Thu, 01 Jan 1970 00:00:01 GMT';
        }
    }

    return cookieEnabled;
}
