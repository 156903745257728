import * as React from 'react';
import classNames from 'classnames';

import Badge from '../Badge';
import Icon from '../Icon';

import './style.scss';
type Props = { icon: string; primaryBadge?: React.ReactNode; secondaryBadge?: React.ReactNode; faded?: boolean };

const BadgeIcon = (props: Props) => {
    const className = classNames('BadgeIcon', {
        'BadgeIcon--faded': props.faded
    });

    return (
        <span className={className}>
            <Icon icon={props.icon} />

            {!!props.primaryBadge ? (
                <span className="BadgeIcon__primary-badge">
                    <Badge danger>{props.primaryBadge}</Badge>
                </span>
            ) : null}

            {!!props.secondaryBadge ? (
                <span className="BadgeIcon__secondary-badge">
                    <Badge>{props.secondaryBadge}</Badge>
                </span>
            ) : null}
        </span>
    );
};

export default BadgeIcon;
