import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createChainedFunction from 'react-bootstrap/lib/utils/createChainedFunction';
import { omit } from '../../utils/object';

type Props = {
    children: React.ReactNode;
    to: string;

    active?: boolean;
    disabled?: boolean;
    className?: string;
    style?: React.CSSProperties;

    eventKey?: string;

    onClick?: (event: React.MouseEvent) => void;
    onSelect?: (eventKey: any, event: any) => {};

    [prop: string]: any;
};

function MenuItemLink({
    to,

    active,
    disabled,
    className,
    style,

    eventKey,
    onClick,
    onSelect,

    ...props
}: Props) {
    const handleClick = (event) => {
        if (!to || disabled) {
            event.preventDefault();
        }

        if (disabled) {
            return;
        }

        if (onSelect) {
            onSelect(eventKey, event);
        }
    };

    const elementProps = omit(props, ['header', 'divider']);

    return (
        <li role="presentation" className={classNames(className, { active, disabled })} style={style}>
            <Link
                {...elementProps}
                to={to}
                role="menuitem"
                tabIndex={-1}
                onClick={createChainedFunction(onClick, handleClick)}
            />
        </li>
    );
}

MenuItemLink.defaultProps = {
    divider: false,
    disabled: false,
    header: false
};

export default MenuItemLink;
