import * as React from 'react';
import { Modal } from 'react-overlays';

import ScaleTransition from '../ScaleTransition';
import SlideUpTransition from '../SlideUpTransition';
import FadeInTransition from '../FadeInTransition';

import './MobilePageContainer.scss';

type TransitionName = 'scale' | 'slideUp' | 'fadeIn';

type Props = {
    show: boolean;
    children: React.ReactNode;
    transitionName?: TransitionName;
};

const MobilePageContainer = (props: Props) => {
    const { show, children, transitionName } = props;

    let Transition: React.ComponentProps<typeof Modal>['transition'] = undefined;

    switch (transitionName) {
        case 'scale':
            Transition = ScaleTransition;
            break;
        case 'slideUp':
            Transition = SlideUpTransition;
            break;
        case 'fadeIn':
            Transition = FadeInTransition;
            break;
    }

    return (
        <Modal show={show} backdrop={false} containerClassName="no-scroll" transition={Transition}>
            <div className="MobilePageContainer">{children}</div>
        </Modal>
    );
};

export default MobilePageContainer;
