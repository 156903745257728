import * as React from 'react';

import './DocumentTypeIcon.style.scss';

import docIcon from './images/ic-file-doc.svg';
import jpgIcon from './images/ic-file-jpg.svg';
import pdfIcon from './images/ic-file-pdf.svg';
import pngIcon from './images/ic-file-png.svg';
import xlsIcon from './images/ic-file-xls.svg';
import zipIcon from './images/ic-file-zip.svg';

import genericIcon from './images/ic-file-generic.svg';

import { FILE_TYPES } from '../../modules/files/constants';

type Props = {
    type: string;
    size?: 'xs' | 'sm' | 'md' | 'lg';
};

const DocumentTypeIcon = ({ type, size }: Props) => {
    const baseClassname = 'DocumentTypeIcon';

    const className = !!size ? baseClassname + `DocumentTypeIcon--${size}` : baseClassname;

    const alt = `${type.toUpperCase()} document icon`;

    switch (type) {
        case FILE_TYPES.DOC:
            return <img src={docIcon} className={className} alt={alt} />;
        case FILE_TYPES.JPG:
            return <img src={jpgIcon} className={className} alt={alt} />;
        case FILE_TYPES.PDF:
            return <img src={pdfIcon} className={className} alt={alt} />;
        case FILE_TYPES.PNG:
            return <img src={pngIcon} className={className} alt={alt} />;
        case FILE_TYPES.XLS:
            return <img src={xlsIcon} className={className} alt={alt} />;
        case FILE_TYPES.ZIP:
            return <img src={zipIcon} className={className} alt={alt} />;
        default:
            return <img src={genericIcon} className={className} alt={alt} />;
    }
};

export default DocumentTypeIcon;
