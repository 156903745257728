import React from 'react';

export type JobFormTasksEditModeContextValue = {
    fieldsInEditMode: string[];

    enableEditMode: (fieldName: string) => void;
    disableEditMode: (fieldName: string) => void;
};

const JobFormTasksEditModeContext = React.createContext<JobFormTasksEditModeContextValue>({
    fieldsInEditMode: [],

    enableEditMode: () => {},
    disableEditMode: () => {}
});

export default JobFormTasksEditModeContext;
