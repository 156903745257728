import { createSelector } from 'reselect';

import { MOUNT } from './reducer';

import { NotificationsState, SystemNotificationsState, ActionNotificationsState } from './reducer';

import { State } from '../../store/reducer';

function getBase(state): NotificationsState {
    return state[MOUNT];
}

export const getSystemNotifications: (state: State) => SystemNotificationsState = createSelector(
    getBase,
    (notificationsState) => notificationsState.systemNotifications
);

export const getActionNotifications: (state: State) => ActionNotificationsState = createSelector(
    getBase,
    (notificationsState) => notificationsState.actionNotifications
);
