import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ContactPage from '../../components/ContactPage';
import { PATH_PATTERNS, REDIRECTS, getJobsRoute, getLoginRoute } from '../../routes';
import { AUTOMATIC_REQUEST_TOKEN_REQUEST_PATH_PATTERN } from '../../routes/automatic-request-token-request';
import {
    CHECKOUT_ADDRESS_PATH_PATTERN,
    CHECKOUT_FINISH_PATH_PATTERN,
    CHECKOUT_PATH_PATTERN,
    CHECKOUT_PRODUCT_PATH_PATTERN,
    CHECKOUT_SUCCESS_PATH_PATTERN,
    getCheckoutProductRoute
} from '../../routes/checkout';
import AuthenticationState from '../AuthenticationState';
import AutomaticRequestTokenRequestPage from '../AutomaticRequestTokenRequestPage';
import CandidateDetailsPage from '../CandidateDetailsPage';
import CandidateListPage, { ConnectorProps as CandidateListPageConnectorProps } from '../CandidateListPage';
import CheckoutPage from '../CheckoutPage';
import EmailAndPasswordLoginPage from '../EmailAndPasswordLoginPage';
import EmailVerificationPage from '../EmailVerificationPage';
import EmailVerificationRequestPage from '../EmailVerificationRequestPage';
import JobCreateFormPage from '../JobCreateFormPage';
import JobCreatePage from '../JobCreatePage';
import JobDetailsPage from '../JobDetailsPage';
import JobEditFormPage from '../JobEditFormPage';
import JobListPage from '../JobListPage';
import LoadingPage from '../LoadingPage';
import NotFoundPage from '../NotFoundPage';
import PricesPage from '../PricesPage';
import RequestTokenLoginPage from '../RequestTokenLoginPage';
import RequestTokenRequestPage from '../RequestTokenRequestPage';
import ResetPasswordConfirmPage from '../ResetPasswordConfirmPage';
import ResetPasswordPage from '../ResetPasswordPage';
import SignupPage from '../SignupPage';
import TempTokenLoginPage from '../TempTokenLoginPage';

const SettingsProfilePage = React.lazy(() => import(/* webpackChunkName: "settings-pages" */ '../SettingsProfilePage'));
const SettingsCompanyProfilePage = React.lazy(() =>
    import(/* webpackChunkName: "settings-pages" */ '../SettingsCompanyProfilePage')
);
const SettingsAddressPage = React.lazy(() => import(/* webpackChunkName: "settings-pages" */ '../SettingsAddressPage'));
const SettingsAccountPage = React.lazy(() => import(/* webpackChunkName: "settings-pages" */ '../SettingsAccountPage'));
const SettingsTemplatesPage = React.lazy(() =>
    import(/* webpackChunkName: "settings-pages" */ '../SettingsTemplatesPage')
);

const createCandidateListPage = (listName: string) => {
    const Component = (props: Omit<CandidateListPageConnectorProps, 'listName'>) => (
        <CandidateListPage {...props} listName={listName} />
    );

    Component.displayName = `CandidateListPage(${listName})`;

    return Component;
};

const CandidateDefaultListPage = createCandidateListPage('withoutNotInteresting');
const CandidateNewMessagesListPage = createCandidateListPage('withNewMessages');
const CandidateNotInterestingListPage = createCandidateListPage('notInteresting');

const redirectToJobsListPage = <Redirect to={getJobsRoute()} />;

const redirectToLoginPage = (
    <Route
        render={({ match }) => {
            return (
                <Redirect
                    to={getLoginRoute({
                        destinationUrl: match.url ?? undefined
                    })}
                />
            );
        }}
    />
);

export type ApplicationRoutesProps = {};

export function ApplicationRoutes({}: ApplicationRoutesProps) {
    return (
        <Switch>
            {/* Redirect */}
            <Route path="/" exact>
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    {redirectToJobsListPage}
                </AuthenticationState>
            </Route>

            {REDIRECTS.map(([path, target]) => (
                <Route key={path} path={path} exact>
                    <Redirect to={target} />
                </Route>
            ))}

            {/* Pages */}
            <Route path="/signup" exact component={SignupPage} />

            <Route path="/login" exact>
                <AuthenticationState type="anonymous" loading={<LoadingPage />} failure={redirectToJobsListPage}>
                    <Route component={EmailAndPasswordLoginPage} />
                </AuthenticationState>
            </Route>

            <Route path="/login/:requestToken" exact>
                <AuthenticationState type={null} loading={<LoadingPage />}>
                    <Route component={RequestTokenLoginPage} />
                </AuthenticationState>
            </Route>
            <Route path="/temp-login/:tempToken" exact>
                <AuthenticationState loading={<LoadingPage />} type={null}>
                    <Route component={TempTokenLoginPage} />
                </AuthenticationState>
            </Route>

            <Route exact path="/magic-link">
                <AuthenticationState type="anonymous" loading={<LoadingPage />} failure={redirectToJobsListPage}>
                    <Route component={RequestTokenRequestPage} />
                </AuthenticationState>
            </Route>

            <Route exact path={AUTOMATIC_REQUEST_TOKEN_REQUEST_PATH_PATTERN}>
                <AutomaticRequestTokenRequestPage />
            </Route>

            <Route exact path="/password-reset">
                <AuthenticationState type="anonymous" loading={<LoadingPage />} failure={redirectToJobsListPage}>
                    <Route component={ResetPasswordPage} />
                </AuthenticationState>
            </Route>

            <Route exact path="/password-reset/confirm/:uid/:token" component={ResetPasswordConfirmPage} />

            <Route exact path="/verify-email/:verificationToken" component={EmailVerificationPage} />

            <Route exact path="/resend-verification">
                <AuthenticationState type="anonymous" loading={<LoadingPage />} failure={redirectToJobsListPage}>
                    <Route component={EmailVerificationRequestPage} />
                </AuthenticationState>
            </Route>

            <Route exact path="/jobs">
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <Route component={JobListPage} />
                </AuthenticationState>
            </Route>

            <Route exact path="/job/:jobId(\d+)">
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <Route component={JobDetailsPage} />
                </AuthenticationState>
            </Route>

            <Route path="/job/new">
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <JobCreateFormPage />
                </AuthenticationState>
            </Route>

            <Route path="/job/:jobId(\d+)/edit">
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <JobEditFormPage />
                </AuthenticationState>
            </Route>

            <Route exact path="/jobs/:jobId/candidates">
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <Route component={CandidateDefaultListPage} />
                </AuthenticationState>
            </Route>

            <Route exact path="/jobs/:jobId/candidates/messages">
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <Route component={CandidateNewMessagesListPage} />
                </AuthenticationState>
            </Route>

            <Route exact path="/jobs/:jobId/candidates/hidden">
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <Route component={CandidateNotInterestingListPage} />
                </AuthenticationState>
            </Route>

            <Route path="/jobs/:jobId/candidates/:candidateId">
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <Route
                        // We have the pass in here as well so that we have proper type defintions of the params
                        path="/jobs/:jobId/candidates/:candidateId"
                        render={(props) => {
                            const { candidateId } = props.match.params;
                            return <CandidateDetailsPage {...props} key={`CandidateDetailsPage[${candidateId}]`} />;
                        }}
                    />
                </AuthenticationState>
            </Route>

            <Route path="/job-create">
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <Route component={JobCreatePage} />
                </AuthenticationState>
            </Route>

            <Route path="/prices">
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <Route component={PricesPage} />
                </AuthenticationState>
            </Route>

            <Route
                exact
                path={CHECKOUT_PATH_PATTERN}
                render={({ match }) => {
                    return <Redirect to={getCheckoutProductRoute(match.params.checkoutProcessId)} />;
                }}
            />

            <Route
                path={[
                    CHECKOUT_PRODUCT_PATH_PATTERN,
                    CHECKOUT_ADDRESS_PATH_PATTERN,
                    CHECKOUT_FINISH_PATH_PATTERN,
                    CHECKOUT_SUCCESS_PATH_PATTERN
                ]}
            >
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <Route component={CheckoutPage} />
                </AuthenticationState>
            </Route>

            <Route path={PATH_PATTERNS.SETTINGS_PROFILE}>
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <React.Suspense fallback={<LoadingPage />}>
                        <SettingsProfilePage />
                    </React.Suspense>
                </AuthenticationState>
            </Route>

            <Route path={PATH_PATTERNS.SETTINGS_COMPANY_PROFILE}>
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <React.Suspense fallback={<LoadingPage />}>
                        <SettingsCompanyProfilePage />
                    </React.Suspense>
                </AuthenticationState>
            </Route>

            <Route path={PATH_PATTERNS.SETTINGS_ADDRESS}>
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <React.Suspense fallback={<LoadingPage />}>
                        <SettingsAddressPage />
                    </React.Suspense>
                </AuthenticationState>
            </Route>

            <Route path={PATH_PATTERNS.SETTINGS_ACCOUNT}>
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <React.Suspense fallback={<LoadingPage />}>
                        <SettingsAccountPage />
                    </React.Suspense>
                </AuthenticationState>
            </Route>

            <Route path={PATH_PATTERNS.SETTINGS_TEMPLATES}>
                <AuthenticationState type="authenticated" loading={<LoadingPage />} failure={redirectToLoginPage}>
                    <React.Suspense fallback={<LoadingPage />}>
                        <SettingsTemplatesPage />
                    </React.Suspense>
                </AuthenticationState>
            </Route>

            <Route path="/contact" component={ContactPage} />

            <Route component={NotFoundPage} />
        </Switch>
    );
}
