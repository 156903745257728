import { all, spawn, take, cancel, fork, put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { isApiResponseError } from '../../services/api/utils';
import { FETCH_JOB_REGIONS_BY_COORDINATES, jobRegionsByCoordinatesFetch } from './actions';
import { convertCoordinatesToCoordinatesString } from './utils';
import { Coordinates } from './types';
import * as jobRegionsApi from './api';

export default function* rootSaga(): SagaIterator {
    yield all([spawn(watchFetchJobRegionsByCoordinates)]);
}

function* watchFetchJobRegionsByCoordinates(): SagaIterator {
    const tasks = {};

    while (true) {
        const action = yield take(FETCH_JOB_REGIONS_BY_COORDINATES);
        const { coordinates } = action.payload;
        const coordinatesAsString = convertCoordinatesToCoordinatesString(coordinates);

        if (!!tasks[coordinatesAsString]) {
            yield cancel(tasks[coordinatesAsString]);
        }

        tasks[coordinatesAsString] = yield fork(fetchJobRegionsByCoordinates, coordinates);
    }
}

function* fetchJobRegionsByCoordinates(coordinates: Coordinates): SagaIterator {
    try {
        yield put(jobRegionsByCoordinatesFetch.request(coordinates));

        const response = yield call(jobRegionsApi.fetchJobRegions, {
            containsCoordinates: coordinates
        });

        yield put(jobRegionsByCoordinatesFetch.success(coordinates, response));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(jobRegionsByCoordinatesFetch.failure(coordinates, error.response.data.errors));
        } else {
            throw error;
        }
    }
}
