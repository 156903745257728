import { useRef, useCallback } from 'react';
import { DragObject } from '../types';

import { useDragMoveOnDragEndState } from './useDragMoveOnDragEndState';

type UseDragMoveOnDragEndProps<Type> = {
    items: Type[];
    onMoveItem: (fromIndex: number, toIndex: number) => void;
    getIdFromItem: (item: Type) => string;
};

export function useDragMoveOnDragEnd<Type>({
    items,
    onMoveItem,
    getIdFromItem
}: UseDragMoveOnDragEndProps<Type>): {
    items: Type[];
    getIndexByItemId: (id: DragObject['id']) => number;
    onDragStart: () => void;
    onDragEnd: () => void;
    onDragMove: (fromIndex: number, toIndex: number) => void;
} {
    const fromIndexForFinalPropagationRef = useRef(-1);
    const toIndexForFinalPropagationRef = useRef(-1);

    const {
        activeModifications,
        temporaryModificationList,
        startModification,
        stopModification,
        updateTemporaryModificationList
    } = useDragMoveOnDragEndState<Type>();

    const onDragStart = useCallback(() => {
        fromIndexForFinalPropagationRef.current = -1;
        toIndexForFinalPropagationRef.current = -1;

        startModification(items);
    }, [startModification, items]);

    const onDragMove = useCallback(
        (fromIndex: number, toIndex: number) => {
            // We only set the from index if it's the first move
            if (fromIndexForFinalPropagationRef.current === -1) {
                fromIndexForFinalPropagationRef.current = fromIndex;
            }
            toIndexForFinalPropagationRef.current = toIndex;

            updateTemporaryModificationList(fromIndex, toIndex);
        },
        [updateTemporaryModificationList]
    );

    const onDragEnd = useCallback(() => {
        stopModification();

        if (toIndexForFinalPropagationRef.current !== -1 && fromIndexForFinalPropagationRef.current !== -1) {
            onMoveItem(fromIndexForFinalPropagationRef.current, toIndexForFinalPropagationRef.current);
        }
    }, [stopModification, onMoveItem]);

    const itemsToRender = activeModifications > 0 ? temporaryModificationList : items;

    const getIndexByItemId = (id) => {
        return itemsToRender.findIndex((item) => getIdFromItem(item) === id);
    };

    return {
        items: itemsToRender,
        getIndexByItemId,
        onDragStart,
        onDragEnd,
        onDragMove
    };
}
