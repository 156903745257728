import { FieldState, FieldValidator, FormApi } from 'final-form';
import { Errors } from '../../modules/types';
import { Job, JobExperience } from '../../modules/jobs/types';
import { clone } from '../../utils/object';
import {
    JOB_FORM_PAGE_FIELD_NAMES_WITH_NESTED,
    JobFormPageJobPartial,
    JobFormPageValues,
    JobFormPageFieldNameWithNested,
    JobFormPageValidationLevel,
    JOB_FORM_PAGE_VALIDATION_LEVEL_FULL,
    JOB_FORM_PAGE_VALIDATION_LEVEL_MINIMAL,
    JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE,
    JOB_FORM_PAGE_NESTED_FIELD_NAME_TO_PARENT_FIELD_NAME_RESOLVE_MAP,
    JobFormPageFieldName
} from './JobFormPage.constants';
import { JobFormLocationFormFieldValue } from './JobFormLocationFormField';
import {
    initialJobFormAvailabilityFormFieldsValue,
    JobFormAvailabilityFormFieldsValue
} from './JobFormWorkConditionsFormFieldset/JobFormAvailabilityFormFields';
import {
    initialJobFormHiringBudgetFormFieldsValue,
    JobFormHiringBudgetFormFieldsValue
} from './JobFormWorkConditionsFormFieldset/JobFormHiringBudgetFormFields';
import { JobFormFunctionsFormFieldsValue } from './JobFormAdDetailsFormFieldset/JobFormFunctionsFormFields';
import { jobFormPageActionsFixedHeight } from './JobFormPageActions';
import { makeJobFormFieldAnchorId } from './JobFormFieldAnchor';
import { isJobExperience } from '../../modules/jobs/utils';

export function makeValuesFromJobPartial(
    job: JobFormPageJobPartial,
    initialValidation: boolean = false
): JobFormPageValues {
    const functionSlug: JobFormFunctionsFormFieldsValue = job.functions[0] ?? null;

    const location: JobFormLocationFormFieldValue =
        job.location.latitude && job.location.longitude ? job.location : null;

    const startDate: JobFormAvailabilityFormFieldsValue = job.start_date
        ? job.start_date
        : initialJobFormAvailabilityFormFieldsValue;

    const hiringBudget: JobFormHiringBudgetFormFieldsValue = job.hiring_budget
        ? job.hiring_budget
        : initialJobFormHiringBudgetFormFieldsValue;

    return clone<JobFormPageValues>({
        company: job.company,
        sectors: job.sectors,
        title: job.title,
        is_startup: job.is_startup,
        function: functionSlug,
        location: location,
        remote_possible: job.remote_possible,
        experiences: job.experiences,
        worktypes: job.worktypes,
        career_statutes: job.career_statutes,
        start_date: startDate,
        hiring_budget: hiringBudget,
        tasks: job.tasks,
        skills: job.skills,
        talents: job.talents,
        languages: job.languages,
        benefits: job.benefits,

        '_validation-level': initialValidation
            ? JOB_FORM_PAGE_VALIDATION_LEVEL_FULL
            : JOB_FORM_PAGE_VALIDATION_LEVEL_MINIMAL,
        '_submission-type': JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE
    });
}

export function makeJobPartialFromValues(values: JobFormPageValues): JobFormPageJobPartial {
    const functions = typeof values.function === 'string' ? [values.function] : [];

    const location: Job['location'] = values.location
        ? values.location
        : {
              latitude: null,
              longitude: null,
              raw_source: '',
              country: '',
              region: '',
              locality: '',
              postal_code: '',
              street_address: ''
          };

    const experiences = values.experiences.filter((value): value is JobExperience => {
        return isJobExperience(value);
    });

    const hiringBudget: Job['hiring_budget'] =
        values.hiring_budget.budget_lower !== null
            ? {
                  budget_currency: 'EUR',
                  budget_lower: values.hiring_budget.budget_lower,
                  budget_higher: values.hiring_budget.budget_higher ?? '',
                  scale: values.hiring_budget.scale
              }
            : null;

    return clone<JobFormPageJobPartial>({
        company: values.company,
        sectors: values.sectors,
        title: values.title,
        is_startup: values.is_startup,
        functions: functions,
        location: location,
        remote_possible: values.remote_possible,
        experiences: experiences,
        worktypes: values.worktypes,
        career_statutes: values.career_statutes,
        start_date: values.start_date,
        hiring_budget: hiringBudget,
        tasks: values.tasks,
        skills: values.skills,
        talents: values.talents,
        languages: values.languages,
        benefits: values.benefits
    });
}

export function onJobFormPageValidationLevel<FieldValue>(
    level: JobFormPageValidationLevel,
    validator: FieldValidator<FieldValue>
): FieldValidator<FieldValue> {
    const wrapperValidator: FieldValidator<FieldValue> = (
        value: FieldValue,
        allValues: object,
        meta?: FieldState<FieldValue>
    ) => {
        if (
            level === JOB_FORM_PAGE_VALIDATION_LEVEL_MINIMAL ||
            (level === JOB_FORM_PAGE_VALIDATION_LEVEL_FULL &&
                allValues['_validation-level'] === JOB_FORM_PAGE_VALIDATION_LEVEL_FULL)
        ) {
            return validator(value, allValues, meta);
        }

        return undefined;
    };

    return wrapperValidator;
}

export class JobFormSubmissionError extends Error {
    errors: Errors | null;

    constructor(errors: Errors | null) {
        super('Job form submission failed');

        this.errors = errors;
    }
}

export function scrollToFirstInvalidField(form: FormApi<JobFormPageValues>) {
    const firstInvalidFieldName = JOB_FORM_PAGE_FIELD_NAMES_WITH_NESTED.find(
        (fieldName: JobFormPageFieldNameWithNested): boolean => {
            // This type cast is necessary because final-form doesn't map the field names of the nested values correctly
            const anyFieldName = fieldName as any;

            const fieldState = form.getFieldState(anyFieldName);

            if (!fieldState) {
                return false;
            }

            return fieldState.invalid ?? false;
        }
    );

    if (!firstInvalidFieldName) {
        return;
    }

    const resolvedFieldName: JobFormPageFieldName =
        JOB_FORM_PAGE_NESTED_FIELD_NAME_TO_PARENT_FIELD_NAME_RESOLVE_MAP[firstInvalidFieldName] ??
        firstInvalidFieldName;

    scrollToField(resolvedFieldName, jobFormPageActionsFixedHeight * -1);
}

export function scrollToField(fieldName: JobFormPageFieldName, offset: number = 0) {
    const id = makeJobFormFieldAnchorId(fieldName);
    const element = document.getElementById(id);

    if (!element) {
        throw new Error(`Anchor element for field '${fieldName}' not found`);
    }

    window.scrollTo(0, element.offsetTop + offset);
}
