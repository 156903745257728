import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import FlipMove from 'react-flip-move';
import { Link } from 'react-router-dom';

import './NextNewCandidateBanner.style.scss';

import { getCandidateRoute } from '../../routes';

import { getEntries, getFetchStatus } from '../../modules/candidates/selectors';

import Badge from '../../components/Badge';
import BadgeIcon from '../../components/BadgeIcon';

import { FetchStatus } from '../../modules/types';

type Props = {
    className?: string;
    jobId: number;

    nextCandidateIds?: number[];
    nextCandidateFetchStatus?: FetchStatus;
};

const NextNewCandidateBanner = ({ className, jobId, nextCandidateIds, nextCandidateFetchStatus }: Props) => {
    if (!nextCandidateIds) {
        return null;
    }

    const nextCandidateLoaded = nextCandidateIds.length > 0 && nextCandidateFetchStatus === 'loaded';

    if (!nextCandidateLoaded) {
        return null;
    }

    return (
        <Link className={className} to={getCandidateRoute(jobId, nextCandidateIds[0])}>
            <FlipMove
                duration={200}
                appearAnimation={{
                    from: {
                        transform: 'translateY(-50px)'
                    },

                    to: {
                        transform: 'translateY(0px)'
                    }
                }}
                leaveAnimation={{
                    from: {
                        transform: 'translateY(0px)'
                    },

                    to: {
                        transform: 'translateY(-50px)'
                    }
                }}
            >
                <div className="NextNewCandidateBanner">
                    <span className="NextNewCandidateBanner__badge">
                        <Badge danger>1</Badge>
                    </span>
                    <span className="NextNewCandidateBanner__badge-icon">
                        <BadgeIcon icon="avatar" primaryBadge="1" />
                    </span>
                    <span className="NextNewCandidateBanner__text-link">
                        <FormattedMessage id="NEXT_NEW_CANDIDATE_BANNER.LINK" />
                    </span>
                </div>
            </FlipMove>
        </Link>
    );
};

const mapStateToProps = (state, props: Props) => {
    return {
        nextCandidateIds: getEntries(state, 'nextNewForCurrentJob', props.jobId),
        nextCandidateFetchStatus: getFetchStatus(state, 'nextNewForCurrentJob', props.jobId)
    };
};

export default connect(mapStateToProps)(NextNewCandidateBanner);
