import { createSelector } from 'reselect';

import { MOUNT } from './reducer';

import { ApplicationState } from '../../store/reducer';

import {
    AuthenticationState,
    SignupState,
    EmailAndPasswordAuthenticationState,
    RequestTokenAuthenticationState,
    TempTokenAuthenticationState,
    LastAuthenticationCheckState,
    IsAuthenticatedState,
    IsAuthenticatingState,
    ResetPasswordState,
    RequestTokenRequestState,
    VerificationEmailResendState
} from './reducer';
import { RequestStatus, ResetStatus } from '../types';

function getState(state: ApplicationState): AuthenticationState {
    return state[MOUNT];
}

export const getLastAuthenticationCheck = createSelector(
    getState,
    (state): LastAuthenticationCheckState => state.lastAuthenticationCheck
);

export const isAuthenticated = createSelector(getState, (state): IsAuthenticatedState => state.isAuthenticated);

export const isAuthenticating = createSelector(getState, (state): IsAuthenticatingState => state.isAuthenticating);

export const getSignup = createSelector(getState, (state): SignupState => state.signup);

export const getSignupStatus = createSelector(getSignup, (state) => state.status);

export const getSignupResult = createSelector(getSignup, (state) => state.result);

export const getSignupErrors = createSelector(getSignup, (state) => state.errors);

export const getEmailAndPasswordAuthentication = createSelector(
    getState,
    (state): EmailAndPasswordAuthenticationState => state.emailAndPasswordAuthentication
);

export const getEmailAndPasswordAuthenticationErrors = createSelector(
    getEmailAndPasswordAuthentication,
    (state) => state.errors
);

export const getRequestTokenAuthentication = createSelector(
    getState,
    (state): RequestTokenAuthenticationState => state.requestTokenAuthentication
);

export const getRequestTokenAuthenticationErrors = createSelector(
    getRequestTokenAuthentication,
    (state) => state.errors
);

export const getTempTokenAuthentication = createSelector(
    getState,
    (state): TempTokenAuthenticationState => state.tempTokenAuthentication
);

export const getTempTokenAuthenticationErrors = createSelector(getTempTokenAuthentication, (state) => state.errors);

export const getResetPassword = createSelector(
    getState,
    (authenticationState): ResetPasswordState => authenticationState.resetPassword
);

export const getResetPasswordRequestStatus = createSelector(
    getResetPassword,
    (resetPasswordState): RequestStatus => resetPasswordState.requestStatus
);

export const getResetPasswordResetStatus = createSelector(
    getResetPassword,
    (resetPasswordState): ResetStatus => resetPasswordState.resetStatus
);

export const getRequestTokenRequest = createSelector(
    getState,
    (state): RequestTokenRequestState => state.requestTokenRequest
);

export const getRequestTokenRequestStatus = createSelector(getRequestTokenRequest, (state) => state.status);

export const getRequestTokenRequestEmail = createSelector(getRequestTokenRequest, (state) => state.email);

export const getVerificationEmailResend = createSelector(
    getState,
    (state): VerificationEmailResendState => state.verificationEmailResend
);

export const getVerificationEmailResendStatus = createSelector(getVerificationEmailResend, (state) => state.status);

export const getChangePassword = createSelector(getState, (state) => {
    return state.changePassword;
});

export const getChangePasswordRequestStatus = createSelector(getChangePassword, (state) => {
    return state.requestStatus;
});

export const getChangePasswordErrors = createSelector(getChangePassword, (state) => {
    return state.errors;
});
