import { combineReducers } from 'redux';

import signupFormReducer from '../../containers/SignupForm/reducer';
import tutorialReducer from '../../containers/Tutorial/redux/reducer';

import { SHOW_NAVIGATION, HIDE_NAVIGATION } from './actions';

export const MOUNT = 'ui';

// Types
import { Action } from '../types';

import { State as SignupFormState } from '../../containers/SignupForm/reducer';
import { TutorialState } from '../../containers/Tutorial/redux/reducer';

type NavigationVisibleState = boolean;
const initialNavigationVisibleState: NavigationVisibleState = true;

function navigationVisibleReducer(
    state: NavigationVisibleState = initialNavigationVisibleState,
    action: Action
): NavigationVisibleState {
    switch (action.type) {
        case SHOW_NAVIGATION:
            return true;
        case HIDE_NAVIGATION:
            return false;

        default:
            return state;
    }
}

export type UIState = {
    signupForm: SignupFormState;
    tutorial: TutorialState;

    navigationVisible: NavigationVisibleState;
};

export default combineReducers<UIState>({
    signupForm: signupFormReducer,
    tutorial: tutorialReducer,

    navigationVisible: navigationVisibleReducer
});
