import { all, fork, spawn, take, cancel, put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { isResponseError } from '../../services/api/utils';
import { FETCH_PACKAGES, FETCH_PACKAGE, packagesFetch, packageFetch, packageAdvertisementCreate } from './actions';
import * as packagesApi from './api';

import { ResponseError } from '../../services/api/types';
import { ApiAdvertisement } from './api/types';

export function* fetchPackages(params: Object = {}): SagaIterator {
    try {
        yield put(packagesFetch.request());

        const response = yield call(packagesApi.fetchPackages, params);

        yield put(packagesFetch.success(response.data));
    } catch (error) {
        let errors: ResponseError[] = [];

        if (isResponseError(error)) {
            errors = error.response.data.errors || [];
        }

        // TODO If errors are unknown add status notification

        yield put(packagesFetch.failure(errors));
    }
}

export function* fetchPackage(packageId: number): SagaIterator {
    try {
        yield put(packageFetch.request(packageId));

        const response = yield call(packagesApi.fetchPackage, packageId);

        yield put(packageFetch.success(packageId, response.data));
    } catch (error) {
        let errors: ResponseError[] = [];

        if (isResponseError(error)) {
            errors = error.response.data.errors || [];
        }

        // TODO If errors are unknown add status notification

        yield put(packageFetch.failure(packageId, errors));
    }
}

export function* createPackageAdvertisementSaga(advertisement: ApiAdvertisement): SagaIterator {
    try {
        yield put(packageAdvertisementCreate.request(advertisement.package));

        yield call(packagesApi.createAdvertisement, advertisement);

        yield put(packageAdvertisementCreate.success(advertisement.package));
    } catch (error) {
        if (isResponseError(error)) {
            yield put(packageAdvertisementCreate.failure(error.response.data.errors));
        } else {
            yield put(packageAdvertisementCreate.failure());
        }

        throw error;
    }
}

function* watchFetchPackagesSaga(): SagaIterator {
    let task;

    while (true) {
        const action = yield take(FETCH_PACKAGES);

        if (!!task) {
            yield cancel(task);
        }

        const { params } = action.payload;
        task = yield fork(fetchPackages, params);
    }
}

function* watchFetchPackageSaga(): SagaIterator {
    let task;

    while (true) {
        const action = yield take(FETCH_PACKAGE);
        const payload = action.payload;
        const packageId = payload.packageId;

        if (!!task) {
            yield cancel(task);
        }

        task = yield fork(fetchPackage, packageId);
    }
}

function* rootSaga(): SagaIterator {
    yield all([spawn(watchFetchPackagesSaga), spawn(watchFetchPackageSaga)]);
}

export default rootSaga;
