import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonAsLink from '../ButtonAsLink';
import LoaderButton from '../LoaderButtonV2';
import SignupFormStepHeadline from '../SignupFormStepHeadline';

import './SignupFormEmailInUse.style.scss';

type Props = {
    email: string;
    loading: boolean;
    onClickRequestToken: () => void;
    onClickChangeEmail: () => void;
};

const SignupFormEmailInUse = (props: Props): React.ReactElement => {
    const { email, loading, onClickRequestToken, onClickChangeEmail } = props;

    return (
        <div className="SignupFormEmailInUse">
            <SignupFormStepHeadline>
                <FormattedMessage id="SIGNUP_FORM_EMAIL_IN_USE.TITLE" />
            </SignupFormStepHeadline>

            <p className="SignupFormEmailInUse__paragraph">
                <FormattedMessage
                    id="SIGNUP_FORM_EMAIL_IN_USE.PARAGRAPH.ALREADY_IN_USE"
                    values={{
                        email: <strong>{email}</strong>
                    }}
                />
            </p>

            <p className="SignupFormEmailInUse__paragraph">
                <FormattedMessage id="SIGNUP_FORM_EMAIL_IN_USE.PARAGRAPH.REQUEST_LINK" />
            </p>

            <div className="SignupFormEmailInUse__action-container">
                <LoaderButton
                    type="submit"
                    onClick={onClickRequestToken}
                    loading={loading}
                    typeStyle="raised"
                    variationStyle="brand"
                >
                    <FormattedMessage id="SIGNUP_FORM_EMAIL_IN_USE.BUTTON.REQUEST_TOKEN" />
                </LoaderButton>
            </div>

            <p className="SignupFormEmailInUse__paragraph">
                <FormattedMessage
                    id="SIGNUP_FORM_EMAIL_IN_USE.PARAGRAPH.CHANGE_EMAIL"
                    values={{
                        changeEmailLink: (
                            <ButtonAsLink type="button" onClick={onClickChangeEmail}>
                                <FormattedMessage id="SIGNUP_FORM_EMAIL_IN_USE.LINK.CHANGE_EMAIL" />
                            </ButtonAsLink>
                        )
                    }}
                />
            </p>
        </div>
    );
};

export default SignupFormEmailInUse;
