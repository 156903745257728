import { useState, useRef, useEffect, FocusEvent, ChangeEvent } from 'react';
import { useCombobox, UseComboboxState, UseComboboxPropGetters, UseComboboxGetItemPropsOptions } from 'downshift';

import { SearchResult } from '../../modules/search/types';
import { ITEM_ACTION_LOAD_MORE } from './SearchBar.constants';

type Values = {
    query: string;
    type: string;
};

export type GetComboboxPropsGetter = UseComboboxPropGetters<any>['getComboboxProps'];
export type GetQueryLabelPropsGetter = UseComboboxPropGetters<any>['getLabelProps'];
export type GetQueryInputPropsGetter = UseComboboxPropGetters<any>['getInputProps'];
export type GetTypeLabelPropsGetter = UseComboboxPropGetters<any>['getLabelProps'];
export type GetTypeInputPropsGetter = UseComboboxPropGetters<any>['getInputProps'];
export type GetLoadMoreActionPropsGetter = (options?: UseComboboxGetItemPropsOptions<any>) => any;

export function useSearchBar({
    initialValues,

    items,
    count,

    onLoadItems,
    onLoadMoreItems,
    onSelectItem,
    onReset
}: {
    initialValues: Values;

    items: SearchResult[];
    count: number;

    onLoadItems: (arg1: { query: string; type: string }) => void;

    onLoadMoreItems: (arg1: { query: string; type: string }) => void;

    onSelectItem: (item: SearchResult) => void;
    onReset: () => void;
}) {
    const [values, setValues] = useState<Values>(initialValues);
    const queryInputRef = useRef<HTMLInputElement | null>(null);
    const typeInputRef = useRef<HTMLSelectElement | null>(null);
    const comboboxRef = useRef<HTMLDivElement | null>(null);
    const mouseTrackers = useRef<{
        isMouseDown: boolean;
    }>({
        isMouseDown: false
    });

    const typeInputTracker = useRef<{
        wasChanged: boolean;
    }>({
        wasChanged: false
    });

    const hasMoreItems = count > items.length;

    // Workaround for https://github.com/downshift-js/downshift/issues/874
    const [scheduledValues, scheduleValues] = useState<Values | null>(null);
    const scheduledOnReset = useRef<(() => void) | null>(null);
    useEffect(() => {
        if (!scheduledValues) {
            return;
        }

        setValues(scheduledValues);

        if (typeof scheduledOnReset.current === 'function') {
            scheduledOnReset.current();
        }

        scheduleValues(null);
        scheduledOnReset.current = null;
    }, [scheduledValues]);
    const reset = () => {
        scheduleValues(initialValues);

        scheduledOnReset.current = onReset;
    };

    const onIsOpenChange = (changes) => {
        if (!changes.isOpen) {
            reset();
        }
    };

    const onInputValueChange = (changes) => {
        if (!changes.isOpen && changes.inputValue === '') {
            // We assume that this the state when the menu was closed
            reset();
        }
    };

    const onSelect = (changes: Partial<UseComboboxState<SearchResult | typeof ITEM_ACTION_LOAD_MORE>>) => {
        const selectedItem = changes.selectedItem;

        if (!selectedItem) {
            return;
        }

        if (selectedItem === ITEM_ACTION_LOAD_MORE) {
            onLoadMoreItems({
                type: values.type,
                query: values.query
            });

            return;
        }

        // setValues(initialValues);

        onSelectItem(selectedItem);

        !!queryInputRef.current && queryInputRef.current.blur();
        !!typeInputRef.current && typeInputRef.current.blur();
    };

    const allItems: Array<SearchResult | typeof ITEM_ACTION_LOAD_MORE> = [
        ...items,
        ...(hasMoreItems ? [ITEM_ACTION_LOAD_MORE] : [])
    ];

    const {
        isOpen,
        openMenu,
        closeMenu,

        highlightedIndex,

        getComboboxProps,
        getLabelProps,
        getInputProps,
        getMenuProps,
        getItemProps,

        setHighlightedIndex
    } = useCombobox({
        items: allItems,

        id: 'search-bar',

        initialIsOpen: false,
        // isOpen: true,

        inputValue: values.query,
        selectedItem: undefined,

        stateReducer: searchBarStateReducer,

        onIsOpenChange,
        onInputValueChange,
        onSelectedItemChange: onSelect
    });

    const handleChangeValue = (incomingValues) => {
        const previousValues = values;

        const nextValues = {
            ...previousValues,
            ...incomingValues
        };

        if (previousValues.query !== nextValues.query || previousValues.type !== nextValues.type) {
            setValues(nextValues);

            onLoadItems({
                type: nextValues.type,
                query: nextValues.query
            });

            setHighlightedIndex(-1);
        }
    };

    const getComboboxPropsWrapper: GetComboboxPropsGetter = (props = {}) => {
        const onMouseDown = () => {
            mouseTrackers.current.isMouseDown = true;
        };
        const onMouseUp = () => {
            mouseTrackers.current.isMouseDown = false;
        };

        const onBlur = (event: FocusEvent) => {
            const relatedTarget = event.relatedTarget as Node;

            // If it's losing focus because of a mouse down we want to prevent that the menu will be closed
            if (mouseTrackers.current.isMouseDown) {
                return;
            }

            const isTargetWithin =
                !!comboboxRef.current &&
                (comboboxRef.current === relatedTarget ||
                    comboboxRef.current.contains(relatedTarget) ||
                    // Is is relevant if the window becomes inactive
                    comboboxRef.current.contains(document.activeElement));

            if (isTargetWithin) {
                return;
            }

            closeMenu();
        };

        return getComboboxProps({
            ref: comboboxRef,

            onMouseDown,
            onMouseUp,
            onBlur,
            ...props
        });
    };

    const getQueryLabelProps: GetQueryLabelPropsGetter = (props = {}) => {
        return getLabelProps(props);
    };
    const getQueryInputProps: GetQueryInputPropsGetter = (props = {}) => {
        const onChange = (event: ChangeEvent<HTMLInputElement>) => {
            handleChangeValue({
                query: event.target.value
            });
        };

        const onBlur = (event: FocusEvent<HTMLInputElement> & { preventDownshiftDefault?: boolean }) => {
            // We prevent the default behaviour of downshift because we handle blur via the combobox's blur
            event.preventDownshiftDefault = true;
        };

        return getInputProps({
            ...props,

            onChange,
            onBlur,

            ref: queryInputRef
        });
    };

    const getTypeLabelProps: GetTypeLabelPropsGetter = (props = {}) => {
        return { ...props };
    };
    const getTypeInputProps: GetTypeInputPropsGetter = (props = {}) => {
        const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
            handleChangeValue({
                type: event.target.value
            });

            typeInputTracker.current.wasChanged = true;

            // We have to delay the focus of the element so that it wont be overriden by the blur event on Safari
            setTimeout(() => {
                // We reset the tracker so that we can blur normally when it's not because of a change
                typeInputTracker.current.wasChanged = false;

                // TODO: Should we automatically focus the element?
                !!queryInputRef.current && queryInputRef.current.focus();
            }, 50);
        };

        const onBlur = (event: FocusEvent<HTMLInputElement> & { preventDownshiftDefault?: boolean }) => {
            if (typeInputTracker.current.wasChanged) {
                // We reset the tracker so that we can blur normally when it's not because of a change
                typeInputTracker.current.wasChanged = false;

                // We prevent that the combobox's blur handler acts.
                // This way we only have to use `wasChanged` in the context of the type input field.
                event.preventDefault();
                event.stopPropagation();
            }
        };

        return {
            ...props,

            value: values.type,
            onChange,
            onBlur,

            ref: typeInputRef
        };
    };

    const getLoadMoreActionProps: GetLoadMoreActionPropsGetter = (props) => {
        if (!hasMoreItems) {
            return { ...props };
        }

        return getItemProps({
            item: ITEM_ACTION_LOAD_MORE,
            index: items.length
        });
    };

    const getBackdropProps: <T>(props: T) => T = (props) => {
        const onClick = () => {
            closeMenu();
            setValues(initialValues);
        };

        return {
            ...props,

            onClick
        };
    };

    return {
        // State
        values,
        isOpen,
        highlightedIndex,

        // Getter
        getComboboxProps: getComboboxPropsWrapper,
        getQueryLabelProps,
        getQueryInputProps,
        getTypeLabelProps,
        getTypeInputProps,
        getMenuProps,
        getItemProps,
        getLoadMoreActionProps,
        getBackdropProps,

        // Actions
        closeMenu,
        openMenu
    };
}

function searchBarStateReducer(state, { type, changes }) {
    switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
            // We've to prevent that the menu closes if the users want to load more items.
            return {
                ...changes,
                isOpen: changes.selectedItem === ITEM_ACTION_LOAD_MORE,
                inputValue: changes.selectedItem === ITEM_ACTION_LOAD_MORE ? changes.inputValue : ''
            };

        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.FunctionSelectItem:
        case useCombobox.stateChangeTypes.ItemClick:
            if (changes.selectedItem === ITEM_ACTION_LOAD_MORE) {
                return {
                    ...changes,
                    isOpen: state.isOpen,
                    highlightedIndex: state.highlightedIndex,
                    inputValue: state.inputValue
                };
            }

            return changes;

        default:
            return changes;
    }
}
