import React from 'react';
import classNames from 'classnames';

import './ListItem.scss';

export type ListItemProps = {
    children?: React.ReactNode;
    className?: string;
    component?: React.ElementType;
    disabled?: boolean;
    highlighted?: boolean;

    [prop: string]: any;
};

const ListItem = React.forwardRef<HTMLElement, ListItemProps>(function ListItem(
    { children, className, component: Component = 'li', highlighted, ...rest },
    ref
) {
    return (
        <Component
            ref={ref}
            className={classNames(
                'ListItem',
                {
                    'ListItem--highlight': highlighted
                },
                className
            )}
            {...rest}
        >
            {children}
        </Component>
    );
});

export default ListItem;
