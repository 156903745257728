import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { NavDropdown, MenuItem } from 'react-bootstrap';

import { Locale, appLocales } from '../../../i18n';

import LanguageDropdownToggle from './LanguageDropdownToggle';

export type LanguageDropdownProps = {
    open: boolean;
    onToggle: (isOpen: boolean) => void;
    onChangeLanguage: (lang: Locale) => void;
};

function LanguageDropdown({ open, onToggle, onChangeLanguage }: LanguageDropdownProps) {
    return (
        <NavDropdown
            open={open}
            onToggle={onToggle}
            title={<LanguageDropdownToggle />}
            id="NavigationActions__language-dropdown"
            className="NavigationActions__dropdown"
        >
            {appLocales.map((locale) => {
                return (
                    <MenuItem key={locale} onClick={onChangeLanguage.bind(null, locale)}>
                        <FormattedMessage id={`NAVIGATION.ACTIONS.LANGUAGE_MENU.LANGUAGE.${locale}`} />
                    </MenuItem>
                );
            })}
        </NavDropdown>
    );
}

export default LanguageDropdown;
