import { IconCalendar3Bold } from '@truffls/icons-react';
import React from 'react';
import { Job } from '../../../modules/jobs/types';
import { hasValidActiveUntilDate, isExpired } from '../../../modules/jobs/utils';
import FormattedLongNumericDate from '../../FormattedLongNumericDate';
import JobDetailsMetaItem from '../JobDetailsMetaItem';
import JobDetailsMetaItemLabeledValue from '../JobDetailsMetaItemLabeledValue';

export type JobDetailsMetaActiveUntilProps = {
    job: Job;
};

function JobDetailsMetaActiveUntil({ job }: JobDetailsMetaActiveUntilProps) {
    if (!hasValidActiveUntilDate(job)) {
        return null;
    }

    const isExpiredJob = isExpired(job, Date.now());
    const labelId = isExpiredJob
        ? 'JOB_DETAILS_META.ACTIVE_UNTIL_LABEL.PAST'
        : 'JOB_DETAILS_META.ACTIVE_UNTIL_LABEL.FUTURE';

    return (
        <JobDetailsMetaItem icon={<IconCalendar3Bold />}>
            <JobDetailsMetaItemLabeledValue labelId={labelId}>
                <FormattedLongNumericDate value={job.active_until} />
            </JobDetailsMetaItemLabeledValue>
        </JobDetailsMetaItem>
    );
}

export default JobDetailsMetaActiveUntil;
