import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { links } from '../../../constants';

import './SignupPageFooter.style.scss';

type Props = {
    intl: IntlShape;
};

function SignupPageFooter(props: Props) {
    const locale = props.intl.locale;

    return (
        <div className="SignupPageFooter">
            <ul className="SignupPageFooter__menu">
                <li className="SignupPageFooter__menu-item">
                    <a href={links.privacy[locale]} className="SignupPageFooter__link">
                        <FormattedMessage id="SIGNUP_PAGE.FOOTER.LINK_PRIVACY" />
                    </a>
                </li>
                <li className="SignupPageFooter__menu-item">
                    <a href={links.imprint[locale]} className="SignupPageFooter__link">
                        <FormattedMessage id="SIGNUP_PAGE.FOOTER.LINK_IMPRINT" />
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default injectIntl(SignupPageFooter);
