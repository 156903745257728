import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { SEARCH_RESULT_TYPE_CANDIDATE, SEARCH_RESULT_TYPE_JOB } from '../../../modules/search/constants';
import { SearchResult } from '../../../modules/search/types';
import { Candidate } from '../../../modules/candidates/types';
import { Job } from '../../../modules/jobs/types';

import CandidateAvatar from '../../../components/CandidateAvatar';
import JobState from '../../../components/JobState';

import { highlight } from './SearchBarResultsListItem.utils';

import './SearchBarResultsListItem.style.scss';

type Props = {
    item: SearchResult;
    highlighted?: boolean;
    search: string;
};

type ItemProps = {
    title: React.ReactNode;
    subtitle: React.ReactNode;
    image?: React.ReactNode;
};

function SearchBarResultsListItem({ item, highlighted, search }: Props) {
    const itemProps = mapItemToProps(item, search);

    if (!itemProps) {
        return null;
    }

    const { title, subtitle, image } = itemProps;

    return (
        <div
            className={classNames('SearchBarResultsListItem', {
                'SearchBarResultsListItem--highlighted': highlighted
            })}
        >
            {image}

            <div className="SearchBarResultsListItem__content">
                <div className="SearchBarResultsListItem__title">{title}</div>
                <div className="SearchBarResultsListItem__subtitle">{subtitle}</div>
            </div>
        </div>
    );
}

function mapItemToProps(item: SearchResult, search: string): ItemProps | null {
    if (item.type === SEARCH_RESULT_TYPE_CANDIDATE) {
        const candidate: Candidate = item.data;

        const title = highlight(candidate.applicant.full_name, search);
        const subtitle = highlight(candidate.job.title, search);

        const image = (
            <div className="SearchBarResultsListItem__avatar-container">
                <CandidateAvatar candidate={candidate} />
            </div>
        );

        return {
            title,
            subtitle,
            image
        };
    } else if (item.type === SEARCH_RESULT_TYPE_JOB) {
        const job: Job = item.data;

        const candidateCount = job.statistics.candidates;
        const candidateCountText = (
            <FormattedMessage
                id="SEARCH_BAR_RESULTS_LIST_ITEM.JOB_SUBTITLE_CANDIDATES_COUNT"
                values={{
                    candidateCount
                }}
            />
        );

        const title = highlight(job.title, search);
        const subtitle = (
            <React.Fragment>
                <JobState job={job} />
                {' · '}

                {candidateCountText}
            </React.Fragment>
        );

        return {
            title,
            subtitle
        };
    }

    return null;
}

export default SearchBarResultsListItem;
