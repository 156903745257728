import { Value } from './constants';
import { values } from './constants';

export const getNextValue = (value: Value): Value => {
    let nextIndex = values.indexOf(value) + 1;
    if (nextIndex >= values.length) {
        nextIndex = 0;
    }
    return values[nextIndex];
};
