import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { getAdvertisementFromCheckoutProcessState } from '../../../modules/checkout/utils';
import { CheckoutProcessState } from '../../../modules/checkout/types';

import classes from './CheckoutProductPageExplanation.module.scss';

type Props = {
    checkoutProcessState: CheckoutProcessState;
};

function CheckoutProductPageExplanation(props: Props) {
    const { checkoutProcessState } = props;

    const advertisement = getAdvertisementFromCheckoutProcessState(checkoutProcessState);
    const advertisementAction = !!advertisement ? advertisement.action : null;

    if (advertisementAction !== 'prolong') {
        return null;
    }

    return (
        <div className="container">
            <p className={classes.text}>
                <FormattedMessage id="CHECKOUT_PRODUCT_PAGE.EXPLANATION.PROLONG" />
            </p>
        </div>
    );
}

export default CheckoutProductPageExplanation;
