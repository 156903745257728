import * as React from 'react';
import classNames from 'classnames';
type Props = { children: React.ReactNode; danger?: boolean; className?: string };

const Badge = (props: Props) => {
    const className: string = classNames('tf-badge', props.className, {
        'tf-badge--danger': props.danger
    });

    return <span className={className}>{props.children}</span>;
};

export default Badge;
