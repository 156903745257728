import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import MobilePageContainer from '../../../components/MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../../../components/NavigationBar';
import { getJobsRoute } from '../../../routes';
import { goBackOrFallback } from '../../../utils/history';
import './CandidateDocumentsPageMobile.style.scss';

type Props = {
    children: React.ReactNode;
};

const CandidateDocumentsPageMobile = ({ children }: Props) => {
    const history = useHistory();

    const onClickClose = () => {
        goBackOrFallback(history.push, getJobsRoute());
    };

    return (
        <MobilePageContainer show>
            <NavigationBar
                leftElement={
                    <NavigationBarText>
                        <FormattedMessage id="CANDIDATE_DOCUMENTS_PAGE.MOBILE_TITLE" />
                    </NavigationBarText>
                }
                rightElement={<NavigationBarCloseAction onClick={onClickClose} />}
            />
            <div className="CandidateDocumentsPageMobile__content-container">{children}</div>
        </MobilePageContainer>
    );
};

export default CandidateDocumentsPageMobile;
