import { differenceInDays, parseISO, startOfDay } from 'date-fns';

import { CANDIDATE_EXPIRATION_THRESHOLD_IN_DAYS } from './constants';
import { Candidate, CandidateRating } from './types';

/*
 * State
 */

export function isNew(candidate: Candidate): boolean {
    return candidate.state === 'new';
}

export function isLocked(candidate: Candidate): boolean {
    return candidate.state === 'locked';
}

export function isNotInteresting(candidate: Candidate): boolean {
    return candidate.state === 'not-interesting';
}

export function isInteresting(candidate: Candidate): boolean {
    return candidate.state === 'interesting';
}

export function isPhoneInterview(candidate: Candidate): boolean {
    return candidate.state === 'phone-interview';
}

export function isInterview(candidate: Candidate): boolean {
    return candidate.state === 'interview';
}

export function isHired(candidate: Candidate): boolean {
    return candidate.state === 'hired';
}

/*
 * Aggregated state helpers
 */
export function isUnprocessed(candidate: Candidate): boolean {
    return isNew(candidate) || isLocked(candidate);
}

export function isUnprocessedOrNotInteresting(candidate: Candidate): boolean {
    return isUnprocessed(candidate) || isNotInteresting(candidate);
}

export function isNotLockedAndAtLeastInteresting(candidate: Candidate): boolean {
    return !isUnprocessedOrNotInteresting(candidate);
}

export function isBetweenInterestingAndHired(candidate: Candidate): boolean {
    return isPhoneInterview(candidate) || isInterview(candidate);
}

/**
 * Action types
 */
export function canDelete(candidate: Candidate): boolean {
    return isNotLockedAndAtLeastInteresting(candidate);
}

export function canMarkAsNotInteresting(candidate: Candidate): boolean {
    return isUnprocessed(candidate);
}

export function canKeptFromExpiring(candidate: Candidate): boolean {
    return isNotLockedAndAtLeastInteresting(candidate) && isExpiring(candidate);
}

/*
 * Helpers
 */

export function hasProfilePicture(candidate: Candidate): boolean {
    return !!candidate.applicant.portrait_file.original;
}

/*
 * Computed
 */

export function getFullName(candidate: Candidate): string {
    return candidate.applicant.full_name;
}

export function getInitialsWithDots(candidate: Candidate): string {
    const { first_name: firstName, last_name: lastName } = candidate.applicant;

    const firstNameLetter = firstName.charAt(0).toUpperCase();
    const lastNameLetter = lastName.charAt(0).toUpperCase();

    return `${firstNameLetter}.${lastNameLetter}.`;
}

export function getInitials(candidate: Candidate): string {
    const dottedInitials = getInitialsWithDots(candidate);

    return dottedInitials.replace(/\./g, '');
}

export function getRecruiterRating(candidate: Candidate): string {
    const rating: CandidateRating = candidate.rating_by_recruiter;

    switch (rating) {
        case 1:
            return 'C';
        case 2:
            return 'B';
        case 3:
            return 'A';
        default:
            return '-';
    }
}

export function getExpirationDaysLeft(candidate: Candidate, now: Date = new Date()): number {
    const today = startOfDay(now);
    const expirationDate = startOfDay(parseISO(candidate.expiration_date));

    return differenceInDays(expirationDate, today);
}

export function isExpiring(candidate: Candidate, now: Date = new Date()): boolean {
    const daysLeft: number = getExpirationDaysLeft(candidate, now);

    return !!candidate.expiration_date && daysLeft <= CANDIDATE_EXPIRATION_THRESHOLD_IN_DAYS;
}

export function getCandidateConversationId(candidate: Candidate) {
    return candidate.conversation_id;
}
