import { automaticRequestTokenRequestPageReasons } from './AutomaticRequestTokenRequestPage.constants';
import { RequestTokenTokenType } from '../../modules/authentication/type';
import {
    AutomaticRequestTokenRequestPageReason,
    reasonToRequestTokenTokenTypeMap
} from './AutomaticRequestTokenRequestPage.constants';

export function mapReasonToRequestTokenTokenType(
    reason: AutomaticRequestTokenRequestPageReason | undefined
): RequestTokenTokenType | undefined {
    return !!reason ? reasonToRequestTokenTokenTypeMap[reason] ?? undefined : undefined;
}

export function isAutomaticRequestTokenRequestPageReason(value: any): value is AutomaticRequestTokenRequestPageReason {
    return automaticRequestTokenRequestPageReasons.includes(value);
}
