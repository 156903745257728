import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import ExpiringCandidateMessagingView from '../../components/ExpiringCandidateMessagingView';
import { loadCandidate } from '../../modules/candidates/actions';
import { Candidate } from '../../modules/candidates/types';
import { canKeptFromExpiring } from '../../modules/candidates/utils';
import { sendConversationMessage } from '../../modules/conversations/actions';
import { getConversationMessages } from '../../modules/conversations/selectors';
import { Message } from '../../modules/conversations/types';
import { isMessageSending } from '../../modules/conversations/utils';
import { getCandidate, getJob } from '../../modules/entities/selectors';
import { Job } from '../../modules/jobs/types';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { Recruiter } from '../../modules/recruiters/types';
import { getUser } from '../../modules/users/selectors';
import { User } from '../../modules/users/types';
import { State as ApplicationState } from '../../store/reducer';

type ConnectorProps = {
    jobId: number;
    candidateId: number;
    className?: string;
};

type ConnectedStateProps = {
    currentUser: User | null;
    recruiter: Recruiter | null;
    job: Job | null;
    candidate: Candidate | null;
    conversationMessages: Message[];
};

type ConnectedDispatchProps = {
    sendConversationMessage: typeof sendConversationMessage;
    loadCandidate: typeof loadCandidate;
};

export type Props = ConnectedStateProps & ConnectedDispatchProps & ConnectorProps;

type State = {
    showExpirationMessagingView: boolean;
    sendingExpirationMessage: boolean;
    expirationMessageLocalId: Message['id'] | null;
};

const getInitialState = (): State => {
    return {
        showExpirationMessagingView: false,
        sendingExpirationMessage: false,
        expirationMessageLocalId: null
    };
};

export class CandidateActionKeepExpiring extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = getInitialState();

        this.openExpirationMessagingView = this.openExpirationMessagingView.bind(this);
        this.closeExpirationMessagingView = this.closeExpirationMessagingView.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        const { sendingExpirationMessage, expirationMessageLocalId } = this.state;

        if (sendingExpirationMessage) {
            const prevConversationMessages = prevProps.conversationMessages;
            const currentConversationMessages = this.props.conversationMessages;

            const conversationMessagesJustUpdated =
                prevConversationMessages.length !== currentConversationMessages.length;

            const lastConversationMessage = currentConversationMessages[0]; // most recent messages are first

            const shouldGrabMessageLocalId =
                expirationMessageLocalId === null &&
                conversationMessagesJustUpdated &&
                !!lastConversationMessage &&
                isMessageSending(lastConversationMessage);

            if (shouldGrabMessageLocalId) {
                this.setState({
                    expirationMessageLocalId: lastConversationMessage.id
                });
            } else if (expirationMessageLocalId !== null) {
                const isExpirationLocalMessage = (message) => message.id === expirationMessageLocalId;

                const justFinishedSendingExpirationMessage =
                    prevConversationMessages.find(isExpirationLocalMessage) &&
                    !currentConversationMessages.find(isExpirationLocalMessage);

                if (justFinishedSendingExpirationMessage) {
                    this.setState(getInitialState());

                    this.props.loadCandidate(this.props.candidateId);
                }
            }
        }
    }

    openExpirationMessagingView() {
        this.setState({
            showExpirationMessagingView: true
        });
    }

    closeExpirationMessagingView() {
        this.setState(getInitialState());
    }

    handleSubmit(message: string) {
        const { candidate, currentUser, sendConversationMessage } = this.props;

        if (!candidate || !currentUser) {
            return;
        }

        sendConversationMessage(candidate.conversation_id, currentUser.id, message);

        this.setState({ sendingExpirationMessage: true });
    }

    render() {
        const { showExpirationMessagingView, sendingExpirationMessage } = this.state;
        const { recruiter, job, candidate, className } = this.props;

        if (!candidate || !canKeptFromExpiring(candidate)) {
            return null;
        }

        return (
            <React.Fragment>
                <Button
                    type="button"
                    onClick={this.openExpirationMessagingView}
                    typeStyle="outlined"
                    variationStyle="normal"
                    className={className}
                >
                    <FormattedMessage id="CANDIDATE_ACTION_KEEP.LABEL" />
                </Button>

                <ExpiringCandidateMessagingView
                    open={showExpirationMessagingView}
                    loading={sendingExpirationMessage}
                    candidate={candidate}
                    job={job}
                    recruiter={recruiter}
                    onClose={this.closeExpirationMessagingView}
                    onSubmit={this.handleSubmit}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => {
    const candidate = getCandidate(state, props.candidateId);

    let conversationMessages = [];
    if (!!candidate) {
        const conversationId = candidate.conversation_id;

        conversationMessages = getConversationMessages(state, conversationId);
    }

    return {
        currentUser: getUser(state),
        recruiter: getRecruiter(state),
        job: getJob(state, props.jobId),
        candidate,
        conversationMessages
    };
};

const mapDispatchToProps = {
    sendConversationMessage,
    loadCandidate
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateActionKeepExpiring);
