import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import FlipMove from 'react-flip-move';

import CopyJobListItem from '../CopyJobListItem';
import ListEmpty from '../../components/ListEmpty';
import ListLoadingIndicator from '../ListLoadingIndicator';

import './style.scss';

import { Job } from '../../modules/jobs/types';

type Props = {
    jobs: Job[];

    skeleton: boolean;
    isLoading: boolean;

    onClickCopy: () => void;
};

const CopyJobList = ({ jobs, onClickCopy, skeleton, isLoading }: Props) => {
    let items;

    if (!!skeleton) {
        items = jobs.map((_, index) => {
            return (
                <li key={index} className="CopyJobList__item">
                    <CopyJobListItem skeleton />
                </li>
            );
        });
    } else if (jobs.length === 0) {
        items = (
            <ListEmpty>
                <FormattedMessage id="COPY_JOB_LIST.EMPTY_TEXT" />
            </ListEmpty>
        );
    } else {
        items = jobs.map((job, index) => {
            return (
                <li key={index} className="CopyJobList__item">
                    <CopyJobListItem job={job} onClickCopy={onClickCopy} />
                </li>
            );
        });
    }

    return (
        <div className="CopyJobList">
            <FlipMove
                typeName="ul"
                duration={500}
                easing="ease-out"
                appearAnimation="fade"
                enterAnimation="fade"
                leaveAnimation="fade"
            >
                {items}
            </FlipMove>

            {isLoading && <ListLoadingIndicator />}
        </div>
    );
};

export default CopyJobList;
