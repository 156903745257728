import * as React from 'react';
import { animated } from 'react-spring/renderprops';

import './TutorialMobileBackground.style.scss';

import { ANIMATION_STEP_NEXT_STEP, AnimationStep } from '../../constants';

import { AnimationKeyframes } from './animations';

type Props = {
    open: boolean;
    step: number;
    animationState: AnimationStep;
};

const TutorialMobileBackground = ({ open, step, animationState }: Props) => (
    <AnimationKeyframes native state={!open && step > 0 ? ANIMATION_STEP_NEXT_STEP : animationState}>
        {({ wrapperTranslate, childTranslate, ...styleProps }) => (
            <animated.div
                className="TutorialMobileBackgroundAnimationWrapper"
                style={{
                    ...styleProps,
                    transform: wrapperTranslate.interpolate(
                        (x, y, selfX, selfY) =>
                            `translate(
                                      calc(${x}% + ${selfX}%),
                                      calc(${y}% + ${selfY}%)
                                  )`
                    )
                }}
            >
                <animated.div
                    className="TutorialMobileBackground"
                    style={{
                        transform: childTranslate.interpolate(
                            (x, y, selfX, selfY) =>
                                `translate(
                                          calc(${x}px - ${selfX}%),
                                          calc(${y}px - ${selfY}%)
                                      )`
                        )
                    }}
                />
            </animated.div>
        )}
    </AnimationKeyframes>
);

export default TutorialMobileBackground;
