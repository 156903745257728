import { DependencyList, useMemo, useRef } from 'react';

/**
 * Memorizes the value returned by the callback. The callback get's the previous memorized value as the first argument.
 */
export default function useMemoWithPrevious<ReturnType = unknown>(
    callback: (previous?: ReturnType) => ReturnType,
    deps: DependencyList
): ReturnType {
    const previousRef = useRef<ReturnType>();

    const value = useMemo(() => {
        return callback(previousRef.current);
    }, [callback, ...deps]);

    previousRef.current = value;

    return value;
}
