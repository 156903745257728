import { createAction } from 'redux-actions';
import { createActionTypeCreator } from '../../utils/redux';

// Types
import { FormValues, EmailTakenDecision } from './constants';

const createActionType = createActionTypeCreator('UI/SIGNUP_FORM');

/*
 * Actions
 */
export const RESET = createActionType('RESET');
export const START = createActionType('START');
export const SET_STEP = createActionType('SET_STEP');
export const CANCEL = createActionType('CANCEL');
export const OPEN_LOGIN = createActionType('OPEN_LOGIN');
export const SUBMIT = createActionType('SUBMIT');

export const SET_EMAIL_TAKEN_DECISION = createActionType('SET_EMAIL_TAKEN_DECISION');
export const RESET_EMAIL_TAKEN_DECISION = createActionType('RESET_EMAIL_TAKEN_DECISION');

/*
 * Action creators
 */
export const reset = createAction(RESET);

export const start = createAction(START);

export const setStep = createAction(SET_STEP, (step: string) => {
    return {
        step
    };
});

export const cancel = createAction(CANCEL);

export const openLogin = createAction(OPEN_LOGIN);

export const submit = createAction(SUBMIT, (values: FormValues) => {
    return {
        values
    };
});

export const setEmailTakenDecision = createAction(SET_EMAIL_TAKEN_DECISION, (decision: EmailTakenDecision) => {
    return {
        decision
    };
});

export const resetEmailTakenDecision = createAction(RESET_EMAIL_TAKEN_DECISION);
