import * as React from 'react';
import { Transition, TransitionProps } from 'react-spring/renderprops';

type Props = Omit<TransitionProps<boolean>, 'items' | 'children'> & {
    open: boolean;
    children: Exclude<ReturnType<Exclude<TransitionProps<boolean>['children'], undefined>>, boolean | null>;
};

const ToggleAnimation = ({ open, children, ...rest }: Props) => (
    <Transition items={open} {...rest}>
        {(item) => (item ? children : null)}
    </Transition>
);

export default ToggleAnimation;
