import * as React from 'react';
import classNames from 'classnames';

import './CandidateResumeSection.style.scss';

type Props = {
    className?: string;
    children: React.ReactNode;
};

const CandidateResumeSection = ({ className, children }: Props) => (
    <section className={classNames('CandidateResumeSection', className)}>{children}</section>
);

export default CandidateResumeSection;
