import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import equal from 'deep-equal';

import PageContainer from '../../components/PageContainer';
import JobListPageSection from '../../components/JobListPageSection';
import JobListPageTitle from '../../components/JobListPageTitle';

import { loadJobs } from '../../modules/jobs/actions';
import { getFetchStatusByList, getTotalCountByList, getPageByList, getJobsByList } from '../../modules/jobs/selectors';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { startAdvertiseCheckout } from '../../modules/checkout/actions';

import { parseSearch, stringifySearch } from '../../utils/url';
import { diffParams } from '../../utils/params';

import { LIST_NAMES, FILTER, DEFAULT_PARAMS } from './constants';
import {
    normalizeQueryParams,
    convertQueryParamsToParams,
    convertParamsToQueryParams,
    convertParamsToActionParams
} from './utils';

import { Recruiter } from '../../modules/recruiters/types';
import { Job } from '../../modules/jobs/types';
import { FilterOptions, FilterValues, Ordering } from '../../components/JobListHeader/constants';

import { ListName, Params, ListParams, QueryParams } from './constants';

type ListProps = {
    fetchStatus: 'none' | 'loading' | 'loaded' | 'failed';
    jobs: Job[];
    totalCount: number;
    page: number;
};

type ConnectorProps = RouteComponentProps & {};

type ConnectedStateProps = {
    recruiter: Recruiter | null;

    news: ListProps;
    management: ListProps;
};

type ConnectedDispatchProps = {
    loadJobs: typeof loadJobs;
    startAdvertiseCheckout: typeof startAdvertiseCheckout;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps & {};

type ListHandlers = {
    handleChangeFilterAndOrdering: (filterValues: any[], ordering: Ordering) => void;
    handleChangeFilter: (filterValues: any[]) => void;
    handleChangeOrdering: (ordering: Ordering) => void;

    getPaginationLinkProps: JobListPageSection['props']['getPaginationLinkProps'];
};

type Handlers = {
    news: ListHandlers;
    management: ListHandlers;
};

class JobListPage extends React.Component<Props> {
    handlers: Handlers;

    constructor(props: Props) {
        super(props);

        // Create the event handlers for every list
        this.handlers = LIST_NAMES.reduce((handlers, listName) => {
            handlers[listName] = {
                handleChangeFilterAndOrdering: this.handleChangeFilterAndOrdering.bind(this, listName),
                handleChangeFilter: this.handleChangeFilter.bind(this, listName),
                handleChangeOrdering: this.handleChangeOrdering.bind(this, listName),

                getPaginationLinkProps: this.getPaginationLinkProps.bind(this, listName)
            };

            return handlers;
        }, {} as Handlers);

        this.handleClickCheckoutStart = this.handleClickCheckoutStart.bind(this);
    }

    componentDidMount() {
        const params = {
            news: this.resolveListParamsByList('news', this.props),
            management: this.resolveListParamsByList('management', this.props)
        };

        this.updateLocationQueryParams(params);

        this.loadJobs('news', params.news);
        this.loadJobs('management', params.management);
    }

    componentDidUpdate(prevProps: Props) {
        const prevSearch = prevProps.location.search;
        const nextSearch = this.props.location.search;

        this.updatePageInLocationIfNeeded('news', prevProps, this.props);
        this.updatePageInLocationIfNeeded('management', prevProps, this.props);

        // If the search is different we want to check which list has to be loaded
        if (prevSearch !== nextSearch) {
            this.loadJobsIfNedded('news', prevProps, this.props);
            this.loadJobsIfNedded('management', prevProps, this.props);
        }
    }

    /**
     * If the page in the app state changed we want to update the search params in the location. This makes it possible
     * to control the page via the application state.
     * @param {ListName} listName
     * @param {Props} prevProps
     * @param {Props} this.props
     */

    updatePageInLocationIfNeeded(listName: ListName, prevProps: Props, nextProps: Props) {
        const prevListState = prevProps[listName];
        const nextListState = nextProps[listName];

        /*
         */
        if (prevListState.page !== nextListState.page) {
            const listParams = this.resolveListParamsByList(listName, prevProps);

            this.updateLocationQueryParams({
                [listName]: {
                    ...listParams,
                    page: nextListState.page
                }
            });
        }
    }

    /**
     * Load jobs if the conditions like the change of params or the page match.
     *
     * @param {ListName} listName
     * @param {Props} prevProps
     * @param {Props} this.props
     */
    loadJobsIfNedded(listName: ListName, prevProps: Props, nextProps: Props) {
        const prevListParams = this.resolveListParamsByList(listName, prevProps);
        const nextListParams = this.resolveListParamsByList(listName, nextProps);

        if (!equal(prevListParams, nextListParams)) {
            // This is the state which is stored in the application state
            const nextListState = nextProps[listName];

            //
            const paramsDiff = diffParams(prevListParams, nextListParams);
            const paramsDiffNames = Object.keys(paramsDiff);

            // Calculate if only the page number changed
            const onlyPageNumberChanged = paramsDiffNames.length === 1 && !!~paramsDiffNames.indexOf('page');

            const pageNumberFromParams = nextListParams.page;
            const pageNumberFromProps = nextListState.page;

            /* If the page number changed and the new page number is the same like the page number which is passed
             * via props (from application state) we don't want to load the jobs because this page was loaded
             * before.
             *
             * This could happen because we control the page number from our app state too.
             *
             *
             * Short: We only want to load the jobs if something different than the page changed in the props
             * or the new page number in the props is different to the page number which is stored in the
             * application state.
             */
            if (!onlyPageNumberChanged || pageNumberFromParams !== pageNumberFromProps) {
                this.loadJobs(listName, nextListParams);
            }
        }
    }

    loadJobs(listName: ListName, params: ListParams) {
        this.props.loadJobs(listName, convertParamsToActionParams(params));
    }

    resolveParams(props: Props): Params {
        const queryParams: QueryParams = normalizeQueryParams(parseSearch(props.location.search));

        return convertQueryParamsToParams(DEFAULT_PARAMS, queryParams);
    }

    resolveListParamsByList(listName: ListName, props: Props): ListParams {
        return this.resolveParams(props)[listName] as ListParams;
    }

    updateLocationQueryParams(params: Params) {
        const { location, history } = this.props;

        const queryParams: QueryParams = parseSearch(location.search);

        const newQueryParams = convertParamsToQueryParams(DEFAULT_PARAMS, params);

        if (!equal(queryParams, newQueryParams)) {
            const newSearch = stringifySearch(newQueryParams);

            history.replace({
                search: newSearch
            });
        }
    }

    handleChangeFilterAndOrdering(listName: ListName, filterValues: any[], ordering: Ordering) {
        const filterName = FILTER[listName].name;
        const listParams = this.resolveListParamsByList(listName, this.props);

        this.updateLocationQueryParams({
            [listName]: {
                ...listParams,
                [filterName]: filterValues,
                ordering
            }
        });
    }

    handleChangeFilter(listName: ListName, filterValues: any[]) {
        const filterName = FILTER[listName].name;
        const listParams = this.resolveListParamsByList(listName, this.props);

        this.updateLocationQueryParams({
            [listName]: {
                ...listParams,
                [filterName]: filterValues
            }
        });
    }

    handleChangeOrdering(listName: ListName, ordering: Ordering) {
        const listParams = this.resolveListParamsByList(listName, this.props);

        this.updateLocationQueryParams({
            [listName]: {
                ...listParams,
                ordering
            }
        });
    }

    handleClickCheckoutStart(job) {
        this.props.startAdvertiseCheckout(job);
    }

    getPaginationLinkProps(listName: ListName, page: number) {
        const location = this.props.location;

        const currentParams = this.resolveParams(this.props);
        const currentListParams = currentParams[listName] as ListParams;

        const linkListParams: ListParams = {
            ...currentListParams,
            page
        };

        const linkParams: Params = {
            ...currentParams,

            [listName]: linkListParams
        };

        const linkQueryParam = convertParamsToQueryParams(DEFAULT_PARAMS, linkParams);
        const linkSearch = stringifySearch(linkQueryParam);

        const to = {
            pathname: location.pathname,
            search: linkSearch,
            hash: location.hash
        };

        return {
            to
        };
    }

    resolveFilterOptionsByListName(listName: ListName): FilterOptions {
        const filterOptions: FilterOptions = FILTER[listName].options.map((option: string) => ({
            value: option,
            label: <FormattedMessage id={`JOB_LIST_PAGE.FILTER_OPTION.${option}`} />
        }));

        return filterOptions;
    }

    resolveFilterValuesByListName(listName: ListName): FilterValues {
        const listParams = this.resolveListParamsByList(listName, this.props);

        const name = FILTER[listName].name;

        return listParams[name];
    }

    resolveOrderingByListName(listName: ListName) {
        const listParams = this.resolveListParamsByList(listName, this.props);

        return listParams.ordering;
    }

    render() {
        const { recruiter } = this.props;

        if (!recruiter) {
            return null;
        }

        return (
            <PageContainer className="JobListPage">
                <JobListPageTitle recruiter={recruiter} />

                {this.renderSection('news')}
                {this.renderSection('management')}
            </PageContainer>
        );
    }

    renderSection(listName: ListName) {
        const handlers = this.handlers[listName];
        const { fetchStatus, totalCount, jobs } = this.props[listName];

        const listParams = this.resolveListParamsByList(listName, this.props);

        const countOfPages = Math.ceil(totalCount / listParams.pageSize);

        const filterOptions = this.resolveFilterOptionsByListName(listName);
        const filterValues = this.resolveFilterValuesByListName(listName);
        const ordering = this.resolveOrderingByListName(listName);

        return (
            <JobListPageSection
                name={listName}
                fetchStatus={fetchStatus}
                jobs={jobs}
                activePage={listParams.page}
                countOfPages={countOfPages}
                onChangeFilterAndOrdering={handlers.handleChangeFilterAndOrdering}
                filterOptions={filterOptions}
                filterValues={filterValues}
                onChangeFilters={handlers.handleChangeFilter}
                ordering={ordering}
                onChangeOrdering={handlers.handleChangeOrdering}
                onClickCheckoutStart={this.handleClickCheckoutStart}
                getPaginationLinkProps={handlers.getPaginationLinkProps}
            />
        );
    }
}

const mapStateToProps = (state): ConnectedStateProps => {
    const newsFetchStatus = getFetchStatusByList(state, 'news');
    const managementFetchStatus = getFetchStatusByList(state, 'management');

    return {
        recruiter: getRecruiter(state),

        news: {
            fetchStatus: newsFetchStatus,
            totalCount: getTotalCountByList(state, 'news'),
            page: getPageByList(state, 'news'),
            jobs: getJobsByList(state, 'news')
        },

        management: {
            fetchStatus: managementFetchStatus,
            totalCount: getTotalCountByList(state, 'management'),
            page: getPageByList(state, 'management'),
            jobs: getJobsByList(state, 'management')
        }
    };
};

const mapDisptachToProps: ConnectedDispatchProps = {
    loadJobs,
    startAdvertiseCheckout
};

const withStore = connect<ConnectedStateProps, ConnectedDispatchProps, ConnectorProps>(
    mapStateToProps,
    mapDisptachToProps
);

export default withStore(JobListPage);
