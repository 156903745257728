import * as React from 'react';
import classNames from 'classnames';
import { Button } from '@truffls/design-system-react';

import { Type, Variation } from '../Button/constants';

import './LoaderButtonV2.scss';

export type LoaderButtonV2Props = {
    typeStyle: Type;
    variationStyle: Variation;

    children: React.ReactNode;

    component?: React.ElementType;
    className?: string;

    loading?: boolean;

    [propName: string]: any;
};

const LoaderButtonV2 = (props: LoaderButtonV2Props) => {
    const {
        loading,

        className,
        children,
        ...buttonProps
    } = props;

    const modifiers = {
        'LoaderButtonV2--loading': loading
    };

    const combinedClassName = classNames('LoaderButtonV2', modifiers, className);

    return (
        <Button className={combinedClassName} {...buttonProps}>
            <span className="LoaderButtonV2__content">{children}</span>
            <span className="LoaderButtonV2__spinner"></span>
        </Button>
    );
};

export default LoaderButtonV2;
