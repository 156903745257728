import * as React from 'react';
import classNames from 'classnames';

type Props = {
    children?: React.ReactNode;
    className?: string;
    skeleton?: boolean;
};

const DetailsSecondary = (props: Props) => {
    const classname = classNames('Details__secondary-content', props.className, {
        'Details__secondary-content--skeleton': props.skeleton
    });

    return <span className={classname}>{props.children}</span>;
};

export default DetailsSecondary;
