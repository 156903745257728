import React from 'react';
import FormattedCurrencyNumber from '../FormattedCurrencyNumber';

export type ProductPriceProps = {
    value: number;
};

function ProductPrice({ value }: ProductPriceProps) {
    return <FormattedCurrencyNumber value={value} minimumFractionDigits={0} maximumFractionDigits={0} />;
}

export default ProductPrice;
