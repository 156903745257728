import * as React from 'react';
import classNames from 'classnames';

import './CandidateResumeItem.style.scss';

type Props = {
    label?: React.ReactNode;
    className?: string;
    children: React.ReactNode;
};

const CandidateResumeItem = ({ label, className, children }: Props) => (
    <li className={classNames('CandidateResumeItem', className)}>
        {label && <div className="CandidateResumeItem__label">{label}</div>}
        <div>{children}</div>
    </li>
);

export default CandidateResumeItem;
