import { SagaIterator } from 'redux-saga';
import { all, call, cancel, fork, put, select, spawn, take } from 'redux-saga/effects';

import { getHistory } from '../../history';
import { getCandidateConversationRoute } from '../../routes';
import { isApiResponseError } from '../../services/api/utils';

import { fetchFilesByCandidateId, filesRequest, LOAD_FILES_BY_CANDIDATE_ID, REQUEST_FILES } from './actions';
import * as filesApi from './api';
import { getCandidateIdListPaginationNextPage } from './selectors';

import { Candidate } from '../candidates/types';

type CandidateId = Candidate['id'];

function* loadFilesByCandidateIdSaga(candidateId: CandidateId): SagaIterator<void> {
    try {
        yield put(fetchFilesByCandidateId.request(candidateId));

        const queryParams: filesApi.FetchFilesByCandidateIdQueryParams = {};
        const nextPage = yield select(getCandidateIdListPaginationNextPage, candidateId);
        if (nextPage !== null) {
            queryParams.page = nextPage;
        }

        const response = yield call(filesApi.fetchFilesByCandidateId, candidateId, queryParams);

        yield put(fetchFilesByCandidateId.success(candidateId, response));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(fetchFilesByCandidateId.failure(candidateId, error.response.data.errors));
        } else {
            throw error;
        }
    }
}

function* requestFilesSaga(jobId, candidateId: CandidateId, requestedFiles): SagaIterator<void> {
    try {
        yield put(filesRequest.request(candidateId));

        const response = yield call(filesApi.requestFilesFromCandidate, candidateId, requestedFiles);

        yield put(filesRequest.success(candidateId, response));

        const history = getHistory();
        yield call(history.replace, getCandidateConversationRoute(jobId, candidateId));
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(filesRequest.failure(candidateId, error.response.data.errors));
        } else {
            throw error;
        }
    }
}

// Watchers
function* watchLoadFileByCandidateIdSaga(): SagaIterator<void> {
    const tasks = {};

    while (true) {
        const action = yield take(LOAD_FILES_BY_CANDIDATE_ID);
        const { candidateId } = action.payload;

        const taskId = `${candidateId}`;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        tasks[taskId] = yield fork(loadFilesByCandidateIdSaga, candidateId);
    }
}

function* watchRequestFilesSaga(): SagaIterator<void> {
    const tasks = {};

    while (true) {
        const action = yield take(REQUEST_FILES);
        const { jobId, candidateId, requestedFiles } = action.payload;

        const taskId = `${candidateId}`;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        tasks[taskId] = yield fork(requestFilesSaga, jobId, candidateId, requestedFiles);
    }
}

// Root
function* rootSaga(): SagaIterator<void> {
    yield all([spawn(watchLoadFileByCandidateIdSaga), spawn(watchRequestFilesSaga)]);
}

export default rootSaga;
