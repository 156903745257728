import * as React from 'react';
import classNames from 'classnames';

import './DecorationLine.style.scss';

type Props = {
    width: number;
    height: number;
    className: string;
};

const DecorationLine = ({ width, height, className }: Props) => (
    <span className={classNames('DecorationLine', className)} style={{ width, height }} />
);

export default DecorationLine;
