import { FormField, Input } from '@truffls/design-system-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import './DeleteCandidateViewContent.scss';

export type DeleteCandidateViewContentProps = {
    message: string;
    onChangeMessage?: (message: string) => void;
    onSubmitMessage?: (event: React.FormEvent<HTMLElement>) => void;
};

export default function DeleteCandidateViewContent({
    message,
    onChangeMessage,
    onSubmitMessage
}: DeleteCandidateViewContentProps) {
    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        onChangeMessage?.(event.target.value);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.ctrlKey && event.code === 'Enter') {
            onSubmitMessage?.(event);
        }
    };

    return (
        <div>
            <span className="DeleteCandidateViewContent__heading">
                <FormattedMessage id="DELETE_CANDIDATE.LIST.HEADING" />
            </span>
            <ol className="DeleteCandidateViewContent__list">
                <li>
                    <FormattedMessage id="DELETE_CANDIDATE.LIST.ITEM.FIRST" />
                </li>
                <li>
                    <FormattedMessage id="DELETE_CANDIDATE.LIST.ITEM.SECOND" />
                </li>
                <li>
                    <FormattedMessage id="DELETE_CANDIDATE.LIST.ITEM.THIRD" />
                </li>
                <li>
                    <FormattedMessage id="DELETE_CANDIDATE.LIST.ITEM.FOURTH" />
                </li>
            </ol>
            <FormField
                id="message"
                labelText={<FormattedMessage id="DELETE_CANDIDATE.MESSAGE.LABEL" />}
                helpText={<FormattedMessage id="DELETE_CANDIDATE.MESSAGE.HELP_TEXT" />}
            >
                <Input
                    value={message}
                    multiline
                    minRows={2}
                    maxRows={2}
                    className="DeleteCandidateViewContent__textarea"
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                />
            </FormField>
        </div>
    );
}
