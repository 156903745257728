import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import scrolled, { ScrolledProps } from '../Scrolled';

import MobilePageContainer from '../MobilePageContainer';
import NavigationBar, { NavigationBarText, NavigationBarCloseAction } from '../NavigationBar';
import BottomActionBar from '../BottomActionBar';
import Button from '../Button';

import Modal from 'react-bootstrap/lib/Modal';

import './UnlockCandidateView.style.scss';

type Props = ScrolledProps & {
    doNotShowAgain: boolean;

    open: boolean;
    onClose: () => void;
    onUnlock: (doNotShowAgain: boolean) => void;
};

type State = {
    isViewportMobile: boolean;
    doNotShowAgain: boolean;
};

export const UNLOCK_CANDIDATE_VIEW_DO_NOT_SHOW_AGAIN_STORAGE_KEY = 'NotShowUnlockModalAgain';

class UnlockCandidateView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isViewportMobile: false,
            doNotShowAgain: false
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleUnlock = this.handleUnlock.bind(this);
    }

    handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ doNotShowAgain: event.target.checked });
    }

    handleUnlock() {
        this.props.onUnlock(this.state.doNotShowAgain);
    }

    componentDidMount() {
        if (matchMedia('(max-width: 769px)').matches) {
            this.setState({ isViewportMobile: true });
        } else {
            this.setState({ isViewportMobile: false });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.open && !!this.props.open) {
            if (matchMedia('(max-width: 769px)').matches) {
                this.setState({ isViewportMobile: true });
            } else {
                this.setState({ isViewportMobile: false });
            }
        }
    }

    render() {
        const title = <FormattedMessage id="UNLOCK_CANDIDATE.TITLE" />;
        const notAgain = <FormattedMessage id="UNLOCK_CANDIDATE.ACTION.NOT_AGAIN" />;
        const unlock = <FormattedMessage id="UNLOCK_CANDIDATE.ACTION.UNLOCK" />;

        const content = this.renderContent();

        if (this.state.isViewportMobile) {
            return this.renderMobileFrame(content, title, unlock, notAgain);
        } else {
            return this.renderDesktopFrame(content, title, unlock, notAgain);
        }
    }

    renderContent() {
        return (
            <ul className="UnlockCandidateView__description">
                <li className="UnlockCandidateView__description-line">
                    <FormattedMessage id="UNLOCK_CANDIDATE.DESCRIPTION_LINE_1" />
                </li>
                <li className="UnlockCandidateView__description-line">
                    <FormattedMessage id="UNLOCK_CANDIDATE.DESCRIPTION_LINE_2" />
                </li>
                <li className="UnlockCandidateView__description-line">
                    <FormattedMessage id="UNLOCK_CANDIDATE.DESCRIPTION_LINE_3" />
                </li>
            </ul>
        );
    }

    renderMobileFrame(content, title, unlock, notAgain) {
        const { scrolled, onScroll, open, onClose, doNotShowAgain } = this.props;

        return (
            <MobilePageContainer show={open}>
                <NavigationBar
                    noShadow={!scrolled}
                    centerElement={<NavigationBarText>{title}</NavigationBarText>}
                    rightElement={<NavigationBarCloseAction onClick={onClose} />}
                />

                <div className="UnlockCandidateView UnlockCandidateView--mobile">
                    <div className="UnlockCandidateView__content" onScroll={onScroll}>
                        {content}
                    </div>
                    <span className="tf-checkbox UnlockCandidateView__checkbox">
                        <input
                            type="checkbox"
                            id="unlock-candidate_not-show-again"
                            onChange={this.handleCheckboxChange}
                            checked={doNotShowAgain}
                        />

                        <label
                            className="tf-checkbox__label UnlockCandidateView__checkbox-label"
                            htmlFor="unlock-candidate_not-show-again"
                        >
                            <span className="tf-checkbox__faux" />
                            <span className="tf-checkbox__label-text">{notAgain}</span>
                        </label>
                    </span>
                </div>

                <BottomActionBar>
                    <Button type="button" onClick={this.handleUnlock} typeStyle="raised" variationStyle="brand">
                        {unlock}
                    </Button>
                </BottomActionBar>
            </MobilePageContainer>
        );
    }

    renderDesktopFrame(content, title, unlock, notAgain) {
        const { open, onClose } = this.props;
        const { doNotShowAgain } = this.state;

        return (
            <Modal show={open} onHide={onClose} className="UnlockCandidateView UnlockCandidateView--desktop">
                <Modal.Header className="UnlockCandidateView__header">
                    <Modal.Title>
                        <FormattedMessage id="UNLOCK_CANDIDATE.TITLE" />
                    </Modal.Title>
                </Modal.Header>

                <div className="UnlockCandidateView__content">{content}</div>

                <Modal.Footer className="UnlockCandidateView__footer">
                    <span className="tf-checkbox pull-left UnlockCandidateView__checkbox">
                        <input
                            type="checkbox"
                            id="unlock-candidate_not-show-again"
                            onChange={this.handleCheckboxChange}
                            checked={doNotShowAgain}
                        />

                        <label
                            className="tf-checkbox__label UnlockCandidateView__checkbox-label"
                            htmlFor="unlock-candidate_not-show-again"
                        >
                            <span className="tf-checkbox__faux" />
                            <span className="tf-checkbox__label-text">{notAgain}</span>
                        </label>
                    </span>

                    <Button type="button" onClick={onClose} typeStyle="flat" variationStyle="brand">
                        <FormattedMessage id="UNLOCK_CANDIDATE.ACTION.CANCEL" />
                    </Button>
                    <Button type="button" onClick={this.handleUnlock} typeStyle="flat" variationStyle="brand">
                        {unlock}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default scrolled(UnlockCandidateView);
