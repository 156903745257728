import React from 'react';

import { Candidate } from '../../modules/candidates/types';
import { getInitials } from '../../modules/candidates/utils';

import './CandidateAvatarFallback.style.scss';
type Props = { candidate: Candidate };

function CandidateAvatarFallback({ candidate }: Props) {
    // return (
    //     <div className="CandidateAvatarFallback">
    //         {getInitials(candidate)}
    //     </div>
    // );

    return (
        <svg className="CandidateAvatarFallback" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
            <text
                x="32"
                y="34" // We have to correct the position a little bit because of the baseline
                fontFamily="inherit"
                fontSize="24"
                fontWeight="bold"
                textAnchor="middle"
                alignmentBaseline="middle"
                color="inherit"
            >
                {getInitials(candidate)}
            </text>
        </svg>
    );
}

export default CandidateAvatarFallback;
