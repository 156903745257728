import { combineReducers } from 'redux';

import resetOnLogout from '../../../modules/authentication/reducer/resetOnLogout';

import { OPEN_TUTORIAL, HIDE_TUTORIAL, TUTORIAL_COMPLETE } from './actionsTypes';

import { Action } from '../../../modules/types';
import { CompleteTutorialUpdateStatus } from './types';

export type TutorialState = {
    open: boolean;
    updateStatus: CompleteTutorialUpdateStatus;
    completeUpdateStatus: CompleteTutorialUpdateStatus;
};

function isOpenReducer(state: boolean = false, action: Action): boolean {
    switch (action.type) {
        case OPEN_TUTORIAL:
            return true;

        case HIDE_TUTORIAL:
            return false;

        default:
            return state;
    }
}

function completeTutorialUpdateStatusReducer(
    state: CompleteTutorialUpdateStatus = 'none',
    action: Action
): CompleteTutorialUpdateStatus {
    switch (action.type) {
        case TUTORIAL_COMPLETE.REQUEST:
            return 'updating';

        case TUTORIAL_COMPLETE.SUCCESS:
            return 'updated';

        case TUTORIAL_COMPLETE.FAILURE:
            return 'failed';

        default:
            return state;
    }
}

const tutorialReducer = combineReducers<TutorialState>({
    open: isOpenReducer,
    updateStatus: completeTutorialUpdateStatusReducer,
    completeUpdateStatus: completeTutorialUpdateStatusReducer
});

export default resetOnLogout(tutorialReducer);
