import { combineReducers } from 'redux';
import { createMapReducer } from '../../../utils/redux';

import { getUrlSearch, parseSearch } from '../../../utils/url';

import { INITIAL_PAGINATION } from '../../constants';

import { FETCH_FILES_BY_CANDIDATE_ID } from '../actions';

import { FetchStatus, Action, Pagination } from '../../types';
import { File } from '../types';

export type FetchStatusState = FetchStatus;
export type EntriesState = Array<File['id']>;
export type ErrorState = null | any;
export type PaginationState = Pagination;

export type CandidateIdListState = {
    entries: EntriesState;
    error: ErrorState;
    fetchStatus: FetchStatusState;
    pagination: Pagination;
};

export type CandidateIdListsState = {
    [id: number]: CandidateIdListState;
};

export const initialCandidateIdListState: CandidateIdListState = {
    entries: [],
    error: null,
    fetchStatus: 'none',
    pagination: INITIAL_PAGINATION
};

export const entriesReducer = (
    state: EntriesState = initialCandidateIdListState.entries,
    action: Action
): EntriesState => {
    switch (action.type) {
        case FETCH_FILES_BY_CANDIDATE_ID.SUCCESS:
            const newFiles = action.payload.data.results.map((file: File) => file.id);
            const nextState = [...state, ...newFiles];
            const nextStateUnique = [...Array.from(new Set(nextState))];

            return nextStateUnique;

        default:
            return state;
    }
};

export const errorReducer = (state: ErrorState = initialCandidateIdListState.error, action: Action): ErrorState => {
    switch (action.type) {
        case FETCH_FILES_BY_CANDIDATE_ID.SUCCESS:
            return null;

        case FETCH_FILES_BY_CANDIDATE_ID.FAILURE:
            return action.payload.errors;

        default:
            return state;
    }
};

export const fetchStatusReducer = (
    state: FetchStatusState = initialCandidateIdListState.fetchStatus,
    action: Action
): FetchStatusState => {
    switch (action.type) {
        case FETCH_FILES_BY_CANDIDATE_ID.REQUEST:
            return 'loading';

        case FETCH_FILES_BY_CANDIDATE_ID.SUCCESS:
            return 'loaded';

        case FETCH_FILES_BY_CANDIDATE_ID.FAILURE:
            return 'failed';

        default:
            return state;
    }
};

const paginationReducer = (
    state: PaginationState = initialCandidateIdListState.pagination,
    action: Action
): PaginationState => {
    switch (action.type) {
        case FETCH_FILES_BY_CANDIDATE_ID.SUCCESS:
            const nextState: PaginationState = {
                ...INITIAL_PAGINATION,
                count: action.payload.data.count
            };

            // Next page
            const nextPageUrl = action.payload.data.next;
            const thereIsNextPage = nextPageUrl !== null;
            if (thereIsNextPage) {
                const search = getUrlSearch(nextPageUrl);
                const { page: nextPage } = parseSearch<{ page: string }>(search);

                nextState.nextPage = parseInt(nextPage, 10);
            }

            // Previous page
            const previousPageUrl = action.payload.data.previous;
            const thereIsPreviousPage = previousPageUrl !== null;
            if (thereIsPreviousPage) {
                const search = getUrlSearch(previousPageUrl);
                const { page: previousPage } = parseSearch<{ page: string }>(search);

                nextState.previousPage = parseInt(previousPage, 10);
            }

            return nextState;

        default:
            return state;
    }
};

export const filesReducer = combineReducers({
    entries: entriesReducer,
    error: errorReducer,
    fetchStatus: fetchStatusReducer,
    pagination: paginationReducer
});

function getMapKey(action) {
    if (typeof action.payload !== 'object' || typeof action.payload.candidateId !== 'number') {
        return undefined;
    }

    return action.payload.candidateId;
}

export default createMapReducer(filesReducer, getMapKey);
