import * as React from 'react';
import classNames from 'classnames';

type Props = {
    children: React.ReactNode;
    className?: string;
};

function DialogActions(props: Props) {
    const className = classNames('tf-dialog__actions', props.className);

    return <div className={className}>{props.children}</div>;
}

export default DialogActions;
