import * as Sentry from '@sentry/browser';
import { AxiosResponse } from 'axios';
import { isApiResponseError } from '../api/utils';
import { convertToResponseError } from './utils';

type Options = {
    extra?: {
        [index: string]: any;
    };
};

type ErrorExtraData = {
    extra?: {
        [index: string]: any;
    };
};

type ErrorResponseData = {
    response?: AxiosResponse;
};

export type ErrorWithExtra = Error & ErrorExtraData;

export type ErrorWithResponse = Error & ErrorResponseData;

export type ErrorWithResponseAndExtra = Error & ErrorResponseData & ErrorExtraData;

export function handleError(
    error: ErrorWithExtra | ErrorWithResponse | ErrorWithResponseAndExtra | unknown,
    options: Options = {}
): void {
    let errorToHandle: ErrorWithResponseAndExtra;

    if (error instanceof Error) {
        errorToHandle = error;
    } else {
        errorToHandle = new Error('Unknown error');
        errorToHandle.extra = {};
        errorToHandle.extra.reason = error;
    }

    if (isApiResponseError(errorToHandle)) {
        errorToHandle = convertToResponseError(errorToHandle);
    }

    options = {
        ...options,
        extra: {}
    };

    if (errorToHandle !== error && !!errorToHandle.extra) {
        options.extra = {
            ...options.extra,
            ...errorToHandle.extra
        };
    }

    if (!!errorToHandle.extra) {
        options.extra = {
            ...options.extra,
            ...errorToHandle.extra
        };
    }

    Sentry.withScope((scope) => {
        const extra = typeof options.extra === 'object' ? options.extra : {};
        Object.keys(extra).forEach((key) => {
            scope.setExtra(key, extra[key]);
        });

        Sentry.captureException(errorToHandle);
    });
}
