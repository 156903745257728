import * as React from 'react';

import iphoneFrameImage from '../../assets/images/MobileApp/iphone-frame-silver.svg';

import styleVariables from './style.scss';

const baseWidth = parseInt(styleVariables.baseWidth, 10) || 0;
const baseHeight = parseInt(styleVariables.baseHeight, 10) || 0;

export type MobileAppProps = {
    children: React.ReactNode;
    scale?: number;
    width?: number;
};

function MobileApp({ width, children, scale = 1 }: MobileAppProps) {
    const applicableScale = typeof width === 'number' ? width / baseWidth : scale;

    const style = {
        fontSize: applicableScale * 100,
        width: applicableScale * baseWidth,
        height: applicableScale * baseHeight
    };

    return (
        <div className="MobileApp" style={style}>
            <img className="MobileApp__frame" src={iphoneFrameImage} role="presentation" />
            <div className="MobileApp__content">{children}</div>
        </div>
    );
}

export default MobileApp;
