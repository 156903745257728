import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RequestTokenRequestPageLayout } from '../RequestTokenRequestPageLayout';
import {
    RequestTokenRequestForm,
    RequestTokenRequestFormProps,
    RequestTokenRequestFormValues
} from './RequestTokenRequestForm';

export type { RequestTokenRequestFormValues };
export type RequestTokenRequestFormPageProps = {
    email?: string;
} & Omit<RequestTokenRequestFormProps, 'initialValues'>;

export function RequestTokenRequestFormPage({ email, ...formProps }: RequestTokenRequestFormPageProps) {
    const initialValues = React.useMemo(() => {
        return {
            email: email ?? ''
        };
    }, [email]);

    return (
        <RequestTokenRequestPageLayout title={<FormattedMessage id="REQUEST_TOKEN_REQUEST_FORM_PAGE.TITLE" />}>
            <RequestTokenRequestForm initialValues={initialValues} {...formProps} />
        </RequestTokenRequestPageLayout>
    );
}
