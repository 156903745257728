import { createAction } from 'redux-actions';
import { createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';

// Factory to create all actions of the job module
const createType = createActionTypeCreator('CANDIDATE_CONVERSATION_FILES');

export const LOAD_FILES_BY_CANDIDATE_ID = createType('LOAD_FILES_BY_CANDIDATE_ID');
export const loadFilesByCandidateId = createAction(LOAD_FILES_BY_CANDIDATE_ID, (candidateId) => ({
    candidateId
}));
export const FETCH_FILES_BY_CANDIDATE_ID = createRequestActionTypes(createType('FETCH_FILES_BY_CANDIDATE_ID'));
export const fetchFilesByCandidateId = {
    request: createAction(FETCH_FILES_BY_CANDIDATE_ID.REQUEST, (candidateId) => ({ candidateId })),
    success: createAction(FETCH_FILES_BY_CANDIDATE_ID.SUCCESS, (candidateId, response) => ({
        candidateId,
        data: response.data
    })),
    failure: createAction(FETCH_FILES_BY_CANDIDATE_ID.FAILURE, (candidateId, errors) => ({ candidateId, errors }))
};

export const REQUEST_FILES = createType('REQUEST_FILES');
export const requestFiles = createAction(REQUEST_FILES, (jobId, candidateId, requestedFiles) => ({
    jobId,
    candidateId,
    requestedFiles
}));
export const FILES_REQUEST = createRequestActionTypes(createType('FILES_REQUEST'));
export const filesRequest = {
    request: createAction(FILES_REQUEST.REQUEST, (candidateId) => ({ candidateId })),
    success: createAction(FILES_REQUEST.SUCCESS, (candidateId, response) => ({ candidateId, data: response.data })),
    failure: createAction(FILES_REQUEST.FAILURE, (candidateId, errors) => ({ candidateId, errors }))
};

export const RESET_FILES_REQUEST = createType('RESET_FILES_REQUEST');
export const resetFilesRequest = createAction(RESET_FILES_REQUEST, (candidateId) => ({ candidateId }));
