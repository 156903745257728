import { combineReducers } from 'redux';

import { FETCH_CONVERSATION_MESSAGES, CONVERSATION_MESSAGE_SEND, CONVERSATION_MESSAGES_POLLING } from '../actions';

import { LOGOUT_SUCCESS } from '../../authentication/actions';

export const entriesReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_CONVERSATION_MESSAGES.SUCCESS:
        case CONVERSATION_MESSAGES_POLLING.SUCCESS:
            const newEntries = action.payload.response.data.results.map((message) => message.id);
            const entries = [...newEntries, ...state];
            const uniqueEntries = [...new Set(entries)];

            return uniqueEntries;

        case CONVERSATION_MESSAGE_SEND.REQUEST:
            return [action.payload.message.id, ...state];

        case CONVERSATION_MESSAGE_SEND.SUCCESS:
            const localMessageId = action.payload.localMessageId;

            const localMessageIndex = state.indexOf(localMessageId);

            if (localMessageIndex < 0) {
                return state;
            }

            const newMessageId = action.payload.response.data.id;

            // Replace placeholder message with true one
            return [...state.slice(0, localMessageIndex), newMessageId, ...state.slice(localMessageIndex + 1)];

        case LOGOUT_SUCCESS:
            return [];

        default:
            return state;
    }
};

const fetchStatusReducer = (state = 'none', action) => {
    switch (action.type) {
        case FETCH_CONVERSATION_MESSAGES.REQUEST:
            return 'loading';

        case FETCH_CONVERSATION_MESSAGES.SUCCESS:
            return 'loaded';

        case FETCH_CONVERSATION_MESSAGES.FAILURE:
            return 'failed';

        case LOGOUT_SUCCESS:
            return 'none';

        default:
            return state;
    }
};

const pollingStatusReducer = (state = 'none', action) => {
    switch (action.type) {
        case CONVERSATION_MESSAGES_POLLING.REQUEST:
            return 'loading';

        case CONVERSATION_MESSAGES_POLLING.SUCCESS:
            return 'loaded';

        case CONVERSATION_MESSAGES_POLLING.FAILURE:
            return 'failed';

        case LOGOUT_SUCCESS:
            return 'none';

        default:
            return state;
    }
};

const errorReducer = (state = null, action) => {
    switch (action.type) {
        case FETCH_CONVERSATION_MESSAGES.FAILURE:
            return action.payload.errors;

        case LOGOUT_SUCCESS:
            return null;

        default:
            return state;
    }
};

const messagesReducer = combineReducers({
    entries: entriesReducer,
    fetchStatus: fetchStatusReducer,
    pollingStatus: pollingStatusReducer,
    error: errorReducer
});

export default messagesReducer;
