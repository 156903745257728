import { AxiosRequestConfig } from 'axios';

export default function makeAuthorizationTokenRequestInterceptor(getToken: () => string | null) {
    // Create the real interceptor
    return (config: AxiosRequestConfig) => {
        const token = getToken();

        // If we have a token we want to set those
        if (!!token) {
            // Set headers if undefined
            if (!config.headers) {
                config.headers = {};
            }

            // We only want to set the authorization header if no header was set before - this
            // didn't cover all cases because axios stores header also in other dicts.
            if (!config.headers.Authorization) {
                config.headers.Authorization = `Token ${token}`;
            }
        }

        return config;
    };
}
