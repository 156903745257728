import React from 'react';

import { Errors } from '../../modules/types';
import { Recruiter } from '../../modules/recruiters/types';
import { Job, JobId } from '../../modules/jobs/types';

import { ERROR_GENERIC_NOT_FOUND } from '../../services/api/constants';
import { hasErrorWithCode } from '../../services/api/utils';

import JobErrorPage from '../../components/JobErrorPage';
import JobFormPage, { JobFormPageJobPartial } from '../JobFormPage';
import { JobFormPageSubmissionType } from '../JobFormPage/JobFormPage.constants';

export type JobEditFormPageProps = {
    recruiter: Recruiter;
    jobId: JobId | null;
    job: Job | null;
    loading: boolean;
    failed: boolean;
    errors: null | Errors;

    isParsed: boolean;
    isUpgraded: boolean;
    initialValidation: boolean;
    onSubmit: (jobPartial: JobFormPageJobPartial, submissionType: JobFormPageSubmissionType | null) => Promise<void>;
    onCancel: () => void;
    onOpenSettings: () => void;
};

function JobEditFormPage({
    recruiter,
    jobId,
    job,
    loading,
    failed,
    errors,
    isParsed,
    isUpgraded,
    initialValidation,
    onSubmit,
    onCancel,
    onOpenSettings
}: JobEditFormPageProps) {
    if (!jobId) {
        return <JobErrorPage key="not-found" errorType="not-found" />;
    }

    if (loading) {
        // TODO: Use a loading state component here
        return null;
    } else if (failed) {
        const hasNotFoundError = errors && hasErrorWithCode(errors, ERROR_GENERIC_NOT_FOUND);

        if (hasNotFoundError) {
            return <JobErrorPage key="not-found" errorType="not-found" />;
        }

        return <JobErrorPage key={jobId} errorType="unknown" />;
    }

    return (
        <JobFormPage
            recruiter={recruiter}
            job={job as Job}
            initialValidation={initialValidation}
            isParsed={isParsed}
            isUpgraded={isUpgraded}
            onSubmit={onSubmit}
            onCancel={onCancel}
            onOpenSettings={onOpenSettings}
        />
    );
}

export default JobEditFormPage;
