import * as React from 'react';
import classNames from 'classnames';

import './style.scss';

type Props = {
    leftElement?: React.ReactNode;
    centerElement?: React.ReactNode;
    rightElement?: React.ReactNode;
    borderBottom?: boolean;
};

const MobileAppNavBar = (props: Props): React.ReactElement => {
    const className = classNames('MobileAppNavBar', { 'MobileAppNavBar--border-bottom': props.borderBottom });

    return (
        <div className={className}>
            <div className="MobileAppNavBar__left-section">{props.leftElement}</div>
            <div className="MobileAppNavBar__center-section">{props.centerElement}</div>
            <div className="MobileAppNavBar__right-section">{props.rightElement}</div>
        </div>
    );
};

export default MobileAppNavBar;
