import { useEffect, DependencyList } from 'react';

export function useOnResize(callback: () => void, deps: DependencyList) {
    useEffect(() => {
        const onResize = () => {
            callback();
        };

        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, deps);
}
