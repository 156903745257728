import { Locale as DateFnsLocale } from 'date-fns';

import dateFnsLocaleDe from 'date-fns/locale/de';
import dateFnsLocaleEn from 'date-fns/locale/en-US';

import { Locale } from '.';

export const dateFnsLocales: Record<Locale, DateFnsLocale> = {
    de: dateFnsLocaleDe,
    en: dateFnsLocaleEn
};
