import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_STATUS } from '../modules/constants';
import { Errors } from '../modules/types';
import { getJob, getJobFetchErrors, getJobFetchStatus } from '../modules/entities/selectors';
import { loadJob } from '../modules/jobs/actions';
import { Job, JobId } from '../modules/jobs/types';
import { ApplicationState } from '../store/types';

export type UseLoadJobProps = { jobId: JobId | null };

export type UseLoadJobState = {
    job: Job | null;
    loading: boolean;
    failed: boolean;
    errors: null | Errors;
};

export function useLoadJob({ jobId }: UseLoadJobProps): UseLoadJobState & { revalidate: () => void } {
    const dispatch = useDispatch();

    const revalidate = useCallback(() => {
        if (jobId) {
            dispatch(loadJob(jobId));
        }
    }, [dispatch, jobId]);

    const selector = useCallback(
        (state: ApplicationState): UseLoadJobState => {
            if (jobId === null) {
                return {
                    job: null,
                    loading: false,
                    failed: false,
                    errors: null
                };
            }

            const fetchStatus = getJobFetchStatus(state, jobId);
            const errors = getJobFetchErrors(state, jobId);
            const loading = fetchStatus === FETCH_STATUS.NONE || fetchStatus === FETCH_STATUS.LOADING;
            const failed = fetchStatus === FETCH_STATUS.FAILED;

            return {
                job: getJob(state, jobId),
                loading,
                failed,
                errors
            };
        },
        [jobId]
    );

    useEffect(() => {
        revalidate();
    }, [revalidate]);

    const state = useSelector<ApplicationState, UseLoadJobState>(selector);

    return {
        ...state,
        revalidate
    };
}
