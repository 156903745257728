import * as React from 'react';
import { FormField, Input } from '@truffls/design-system-react';

import fieldify, { FieldifyProps } from '../../utils/form/field';
import { hasError } from '../../utils/form/state';
import { useFieldMeta } from '../../utils/form/hooks';

type Props = FieldifyProps &
    Omit<React.SelectHTMLAttributes<HTMLTextAreaElement>, 'name' | 'id'> & {
        id: string;
        rows?: number;
        minRows?: number;
        maxRows?: number;
        label?: React.ReactNode;
        labelText?: React.ReactNode;
        helpText?: React.ReactNode;
        errors?: {
            [key: string]: React.ReactNode;
        };
    };

const FormFieldTextarea = (props: Props) => {
    const {
        input,
        meta,

        id,
        label,
        labelText,
        helpText,
        errors = {},

        ...rest
    } = props;

    const fieldMeta = useFieldMeta(meta);

    const fieldHasError = hasError(input.value, fieldMeta);

    const assistiveText = fieldHasError && errors[meta.error] ? errors[meta.error] : helpText;

    return (
        <FormField id={id} labelText={labelText || label} helpText={assistiveText} hasError={fieldHasError}>
            <Input {...rest} {...input} multiline value={!!input.value ? input.value : ''} />
        </FormField>
    );
};

export default fieldify()(FormFieldTextarea);
