import { format } from 'url';

import apiClientV3 from '../../services/api/v3';

import { FileId } from '../files/types';
import { Conversation, ConversationId, Message } from './types';
import { ApiEntityResponse, ApiListResponse } from '../../services/api/types';
import { parseISOWithCeiledMilliseconds } from '../../utils/date';

export type MessagesQueryParams = {
    newerThan?: string;
    ordering?: string;
};

type MessagesParams = {
    created__gt?: string;
    ordering?: string;
};

export function fetchConversation(conversationId: ConversationId): Promise<ApiEntityResponse<Conversation>> {
    return apiClientV3.get(`/conversations/${conversationId}/`);
}

export function fetchConversationMessages(
    conversationId: ConversationId,
    queryParams: MessagesQueryParams = {}
): Promise<ApiListResponse<Conversation>> {
    const params: MessagesParams = {};

    const { newerThan, ordering } = queryParams;

    if (newerThan) {
        params.created__gt = newerThan;
    }
    if (ordering) {
        params.ordering = ordering;
    }

    return apiClientV3.get(
        format({
            pathname: `/conversations/${conversationId}/messages/`,
            query: params
        })
    );
}

export function updateConversation(
    conversationId: ConversationId,
    newConversation: Partial<Conversation>
): Promise<ApiEntityResponse<Conversation>> {
    const data = { ...newConversation };

    if (!!newConversation.last_read) {
        data.last_read = parseISOWithCeiledMilliseconds(newConversation.last_read).toISOString();
    }

    return apiClientV3.patch(`/conversations/${conversationId}/`, data);
}

export function sendConversationMessage(
    conversationId: ConversationId,
    content: { text?: string; attachment?: FileId }
): Promise<ApiEntityResponse<Message>> {
    const newConversationMessage = {
        ...content,
        conversation: conversationId
    };

    return apiClientV3.post(`/conversations/${conversationId}/messages/`, newConversationMessage);
}
