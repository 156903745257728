import { SagaIterator } from 'redux-saga';
import { all, spawn } from 'redux-saga/effects';

// Modules
import agreementSaga from '../modules/agreements/saga';
import authenticationSaga from '../modules/authentication/saga';
import candidateSaga from '../modules/candidates/saga';
import checkoutSaga from '../modules/checkout/saga';
import clientInfoSaga from '../modules/clientInfo/saga';
import conversationSaga from '../modules/conversations/saga';
import filesSaga from '../modules/files/saga';
import flagsSaga from '../modules/flags/saga';
import { i18nSaga } from '../modules/i18n/saga';
import jobParsingSaga from '../modules/jobParsing/saga';
import jobRegionsSaga from '../modules/jobRegions/saga';
import jobSaga from '../modules/jobs/saga';
import notificationsSaga from '../modules/notifications/saga';
import packageSaga from '../modules/packages/saga';
import recruiterSaga from '../modules/recruiters/saga';
import searchSaga from '../modules/search/saga';
import templateSaga from '../modules/templates/saga';
import versionSaga from '../modules/version/saga';

// UI
import checkoutAddressPageSaga from '../containers/CheckoutAddressPage/saga';
import checkoutProductPageSaga from '../containers/CheckoutProductPage/saga';
import emailAndPasswordLoginFormSaga from '../containers/EmailAndPasswordLoginForm/saga';
import signupFormSaga from '../containers/SignupForm/saga';
import tutorialSaga from '../containers/Tutorial/redux/saga';

export function* appSaga(): SagaIterator<void> {
    yield all([
        // Modules
        spawn(agreementSaga),
        spawn(authenticationSaga),
        spawn(candidateSaga),
        spawn(checkoutSaga),
        spawn(clientInfoSaga),
        spawn(conversationSaga),
        spawn(filesSaga),
        spawn(flagsSaga),
        spawn(i18nSaga),
        spawn(jobParsingSaga),
        spawn(jobRegionsSaga),
        spawn(jobSaga),
        spawn(notificationsSaga),
        spawn(packageSaga),
        spawn(recruiterSaga),
        spawn(searchSaga),
        spawn(templateSaga),
        spawn(versionSaga),

        // UI
        spawn(checkoutAddressPageSaga),
        spawn(checkoutProductPageSaga),
        spawn(emailAndPasswordLoginFormSaga),
        spawn(signupFormSaga),
        spawn(tutorialSaga)
    ]);
}

export default appSaga;
