import { createAction } from 'redux-actions';

import { createActionTypeCreator } from '../../utils/redux';

const createActionType = createActionTypeCreator('UI/CHECKOUT_PRODUCT_PAGE');

export const CONTINUE_TO_NEXT_STEP = createActionType('CONTINUE_TO_NEXT_STEP');

export const continueToNextStep = createAction(CONTINUE_TO_NEXT_STEP, (checkoutProcessState) => {
    return {
        checkoutProcessState
    };
});
