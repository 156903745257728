import { combineReducers } from 'redux';

import { createPagination, PaginationState, resetReducer } from '../../utils/redux';

import resetOnLogout from '../authentication/reducer/resetOnLogout';

import { FETCH_STATUS } from '../constants';
import { Action, FetchStatus, ListResponseData, Errors as ErrorsState } from '../types';

import { SearchResultId, SearchResult } from './types';
import { SEARCH_RESULTS_FETCH, RESET_SEARCH } from './actions';

export const MOUNT: 'search' = 'search';

type EntriesState = SearchResultId[];
type FetchStatusState = FetchStatus;
export type SearchState = {
    entries: EntriesState;
    fetchStatus: FetchStatusState;
    pagination: PaginationState;
    errors: ErrorsState;
};

export function entriesReducer(state: EntriesState = [], action: Action): EntriesState {
    switch (action.type) {
        case SEARCH_RESULTS_FETCH.SUCCESS:
            const append = action.payload.append;
            const data: ListResponseData<SearchResult> = action.payload.data;

            const incoming = data.results.map((searchResult: SearchResult) => {
                return searchResult.id;
            });

            if (append) {
                return [...state, ...incoming];
            }

            return incoming;

        case SEARCH_RESULTS_FETCH.FAILURE:
            return [];

        default:
            return state;
    }
}

export function fetchStatusReducer(state: FetchStatusState = FETCH_STATUS.NONE, action: Action): FetchStatusState {
    switch (action.type) {
        case SEARCH_RESULTS_FETCH.REQUEST:
            return FETCH_STATUS.LOADING;

        case SEARCH_RESULTS_FETCH.SUCCESS:
            return FETCH_STATUS.LOADED;

        case SEARCH_RESULTS_FETCH.FAILURE:
            return FETCH_STATUS.FAILED;

        default:
            return state;
    }
}

export function paginationReducer(state: PaginationState = createPagination(), action: Action): PaginationState {
    switch (action.type) {
        case SEARCH_RESULTS_FETCH.SUCCESS:
            const { count, previous, next }: ListResponseData<SearchResult> = action.payload.data;

            return createPagination({
                count,
                previous,
                next
            });

        default:
            return state;
    }
}

export function errorsReducer(state: ErrorsState = [], action: Action): ErrorsState {
    switch (action.type) {
        case SEARCH_RESULTS_FETCH.REQUEST:
            return [];

        case SEARCH_RESULTS_FETCH.FAILURE:
            return action.payload.errors;

        default:
            return state;
    }
}

const searchReducer = combineReducers({
    entries: entriesReducer,
    fetchStatus: fetchStatusReducer,
    pagination: paginationReducer,
    errors: errorsReducer
});

const resetOnReset = resetReducer((state, action) => {
    return action.type === RESET_SEARCH;
});

export default resetOnLogout(resetOnReset(searchReducer));
