import { isNumber } from '../utils/number';
import { isValidUrl } from '../utils/url';

export function setupEnvironment() {
    populateEnvironmentVariables();
    validateEnvironmentVariables();
}

let didPopulation = false;
export function populateEnvironmentVariables() {
    if (didPopulation) {
        return;
    }

    didPopulation = true;

    if (process.env.VERCEL === '1') {
        process.env = {
            ...process.env,
            APP_URL: `https://${process.env.VERCEL_URL}`,
            APP_ENVIRONMENT:
                process.env.APP_ENVIRONMENT ||
                (process.env.VERCEL_ENV === 'production'
                    ? 'live'
                    : process.env.VERCEL_ENV === 'development'
                    ? 'stage'
                    : 'dev')
        };
    }

    if (typeof process.env.SENTRY_DSN === 'undefined' && typeof process.env.NEXT_PUBLIC_SENTRY_DSN === 'string') {
        process.env.SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
    }
}

export function validateEnvironmentVariables() {
    if (typeof process.env.APP_VERSION !== 'string' || process.env.APP_VERSION.length <= 0) {
        throw new Error("Environment variable 'APP_VERSION' is not defined");
    }

    if (typeof process.env.APP_ENVIRONMENT !== 'string' || process.env.APP_ENVIRONMENT.length <= 0) {
        throw new Error("Environment variable 'APP_ENVIRONMENT' is not defined");
    }

    // if (typeof process.env.APP_URL !== 'string') {
    //     throw new Error("Environment variable 'APP_URL' is not defined");
    // } else if (!isValidUrl(process.env.APP_URL)) {
    //     throw new Error("Environment variable 'APP_URL' is not a valid URL");
    // }

    if (typeof process.env.DASHBOARD_URL !== 'string') {
        throw new Error("Environment variable 'DASHBOARD_URL' is not defined");
    } else if (!isValidUrl(process.env.DASHBOARD_URL)) {
        throw new Error("Environment variable 'DASHBOARD_URL' is not a valid URL");
    }

    if (typeof process.env.WEBLICANT_URL !== 'string') {
        throw new Error("Environment variable 'WEBLICANT_URL' is not defined");
    } else if (!isValidUrl(process.env.WEBLICANT_URL)) {
        throw new Error("Environment variable 'WEBLICANT_URL' is not a valid URL");
    }

    if (typeof process.env.UNCHAINED_URL !== 'string') {
        throw new Error("Environment variable 'UNCHAINED_URL' is not defined");
    } else if (!isValidUrl(process.env.UNCHAINED_URL)) {
        throw new Error("Environment variable 'UNCHAINED_URL' is not a valid URL");
    }

    // if (typeof process.env.APP_DEEPLINK_SCHEME !== 'string' || process.env.APP_DEEPLINK_SCHEME.length <= 0) {
    //     throw new Error("Environment variable 'APP_DEEPLINK_SCHEME' is not defined");
    // }

    // TODO: Replace with `APP_DEEPLINK_SCHEME` when it's available
    if (typeof process.env.APP_DEEPLINK_JOB_PATTERN !== 'string' || process.env.APP_DEEPLINK_JOB_PATTERN.length <= 0) {
        throw new Error("Environment variable 'APP_DEEPLINK_JOB_PATTERN' is not defined");
    }

    if (typeof process.env.GOOGLE_MAPS_API_KEY !== 'string' || process.env.GOOGLE_MAPS_API_KEY.length <= 0) {
        throw new Error("Environment variable 'GOOGLE_MAPS_API_KEY' is not defined");
    }

    if (typeof process.env.MAPBOX_ACCESS_TOKEN !== 'string' || process.env.MAPBOX_ACCESS_TOKEN.length <= 0) {
        throw new Error("Environment variable 'MAPBOX_ACCESS_TOKEN' is not defined");
    }

    if (typeof process.env.SENTRY_DSN === 'string' && process.env.SENTRY_DSN.length > 0) {
        if (typeof process.env.SENTRY_RELEASE !== 'string' || process.env.SENTRY_RELEASE.length <= 0) {
            throw new Error("Environment variable 'SENTRY_RELEASE' is not defined");
        }
    }

    if (
        typeof process.env.SENTRY_TRACES_SAMPLE_RATE === 'string' &&
        !isNumber(parseFloat(process.env.SENTRY_TRACES_SAMPLE_RATE))
    ) {
        throw new Error("Environment variable 'SENTRY_TRACES_SAMPLE_RATE' is not a valid number");
    }
}
