import { ApiEntityResponse, ApiListResponse } from '../../../services/api/types';
import { toBooleanString } from '../../../services/api/utils';
import apiClientV5 from '../../../services/api/v5';
import { Package, PackageId } from '../types';
import { ApiAdvertisement, ApiAdvertisementCreateData } from './types';

type QueryParams = {
    hasAvailableAdvertisements?: boolean;
};

type Params = {
    only_valid?: string;
};

export function fetchPackages(queryParams: QueryParams = {}): Promise<ApiListResponse<Package>> {
    const params: Params = {};

    if (typeof queryParams.hasAvailableAdvertisements === 'boolean') {
        params.only_valid = toBooleanString(queryParams.hasAvailableAdvertisements);
    }

    return apiClientV5.get('/packages/', {
        params
    });
}

export function fetchPackage(packageId: PackageId): Promise<ApiEntityResponse<Package>> {
    return apiClientV5.get(`/packages/${packageId}/`);
}

export function createAdvertisement(
    advertisement: ApiAdvertisementCreateData
): Promise<ApiEntityResponse<ApiAdvertisement>> {
    return apiClientV5.post('/bulletins/', advertisement);
}
