import { getCandidate } from '../entities/selectors';
import { MOUNT } from './reducer';

// Types
import { CandidatesState, ForwardsState, ForwardStatus } from './reducer';
import { State } from '../../store/reducer';
import { ApplicantId, Candidate, CandidateId } from './types';

import { byApplicantInitialState } from './reducer/byApplicantsReducer';

function getState(state: State): CandidatesState {
    return state[MOUNT];
}

function getList(state: State, listName: string) {
    return getState(state)[listName];
}

function getForwardsState(state: State): ForwardsState {
    return getState(state).forwards;
}

function getJobState(state: State, listName: string, jobId: number) {
    // We should generate an initial state here
    return getList(state, listName)[jobId] || {};
}

export function getEntries(state: State, listName: string, jobId: number) {
    return getJobState(state, listName, jobId).entries || [];
}

export function getCandidates(state: State, listName: string, jobId: number): Candidate[] {
    return getEntries(state, listName, jobId).map((id: number) => {
        const candidate = getCandidate(state, id);

        if (!candidate) {
            throw new Error(`Cannot find a candidate entitiy for id ${id}`);
        }

        return candidate;
    });
}

export function getTotalCount(state: State, listName: string, jobId: number) {
    return getJobState(state, listName, jobId).totalCount;
}

export function getPage(state: State, listName: string, jobId: number) {
    return getJobState(state, listName, jobId).page;
}

export function getError(state: State, listName: string, jobId: number) {
    return getJobState(state, listName, jobId).error || null;
}

export function getFetchStatus(state: State, listName: string, jobId: number) {
    return getJobState(state, listName, jobId).fetchStatus || 'none';
}

export function getByApplicants(state: State) {
    return getState(state).byApplicants;
}

export function getByApplicant(state: State, applicantId: ApplicantId) {
    return getByApplicants(state)[applicantId] || byApplicantInitialState;
}

export function getByApplicantFetchStatus(state: State, applicantId: ApplicantId) {
    return getByApplicant(state, applicantId).fetchStatus;
}

export function getByApplicantEntries(state: State, applicantId: ApplicantId) {
    return getByApplicant(state, applicantId).entries;
}

export function getByApplicantErrors(state: State, applicantId: ApplicantId) {
    return getByApplicant(state, applicantId).errors;
}

export function getByApplicantEntities(state: State, applicantId: ApplicantId) {
    const candidateIds = getByApplicantEntries(state, applicantId);
    return candidateIds.map((candidateId: CandidateId) => {
        const candidate = getCandidate(state, candidateId);

        if (!candidate) {
            throw new Error(`Cannot find a candidate entitiy for id ${candidateId}`);
        }

        return candidate;
    });
}

export function getOtherCandidatesThatMatchApplicant(
    state: State,
    applicantId: ApplicantId,
    excludeCandidateIds: CandidateId[] = []
) {
    const candidates = getByApplicantEntities(state, applicantId);
    return candidates.filter((candidate) => !excludeCandidateIds.includes(candidate.id));
}

export const getOtherCandidatesThatMatchApplicantFetchStatus = getByApplicantFetchStatus;

export function getForwardState(state: State, candidateId: number): ForwardStatus {
    return getForwardsState(state)[String(candidateId)] || 'none';
}
