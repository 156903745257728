import { createAction } from 'redux-actions';
import { createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';

import { Response, Errors } from '../types';
import { Flag, FlagSlug, FlagValue } from './types';

const createActionType = createActionTypeCreator('FLAGS');

export const FETCH_FLAG = createActionType('FETCH_FLAG');
export const fetchFlag = createAction(FETCH_FLAG, (slug: FlagSlug) => {
    return { slug };
});

export const FLAG_FETCH = createRequestActionTypes(createActionType('FLAG_FETCH'));
export const flagFetch = {
    request: createAction(FLAG_FETCH.REQUEST, (slug: FlagSlug) => {
        return { slug };
    }),
    success: createAction(FLAG_FETCH.SUCCESS, (slug: FlagSlug, response: Response<Flag>) => {
        return {
            slug,
            data: response.data
        };
    }),
    failure: createAction(FLAG_FETCH.FAILURE, (slug: FlagSlug, errors: Errors) => {
        return {
            slug,
            errors
        };
    })
};

export const UPDATE_FLAG = createActionType('UPDATE_FLAG');
export const updateFlag = createAction(UPDATE_FLAG, (slug: FlagSlug, value: FlagValue) => {
    return {
        slug,
        value
    };
});

export const FLAG_UPDATE = createRequestActionTypes(createActionType('FLAG_UPDATE'));
export const flagUpdate = {
    request: createAction(FLAG_UPDATE.REQUEST, (slug: FlagSlug) => {
        return { slug };
    }),
    success: createAction(FLAG_UPDATE.SUCCESS, (slug: FlagSlug, response: Response<Flag>) => {
        return {
            slug,
            data: response.data
        };
    }),
    failure: createAction(FLAG_UPDATE.FAILURE, (slug: FlagSlug, errors: Errors) => {
        return {
            slug,
            errors
        };
    })
};
