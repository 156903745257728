import React from 'react';
import { Field, Normalizer, Formatter, Parser, Validator, BaseFieldProps, WrappedFieldProps } from 'redux-form';
import { isRequired } from './validators';
import { noop, compose } from '../function';
import { toArray } from '../array';
import { getComponentName } from '../component';

export type FieldifyProps = WrappedFieldProps & {};

type ComponentOptions = {
    type?: string;
    required?: boolean;
    validate?: Validator | Validator[];
    parse?: Parser;
    format?: Formatter;
    normalize?: Normalizer;
};

type InstanceOptions = {
    required?: boolean;
};

type IncomeProps = Omit<BaseFieldProps, 'components'> & {
    value?: any;
    required?: boolean;
};

type State = {
    validate: Validator;
};

const defaultComponentOptions: ComponentOptions = {
    required: false
};

const fieldify = (componentOptions: ComponentOptions = defaultComponentOptions) => {
    return function generateComponent<Props>(
        WrappedComponent: React.ComponentType<Props & FieldifyProps>
    ): React.ComponentType<Props & IncomeProps> {
        const wrappedComponentName = getComponentName(WrappedComponent);

        const generateValidate = (
            propValidators: Validator[] | Validator = [],
            instanceOptions: InstanceOptions
        ): Validator => {
            // Note: validation is not regenerated on value change
            return (...args) => {
                const [value] = args;

                // Make sure that validators is an array of validator function
                let validators: Validator[] = toArray(propValidators);

                // Combine options with argument
                let required = instanceOptions.required || componentOptions.required;
                if (typeof componentOptions.validate === 'function') {
                    // Combine component specific validators with instance specific validators
                    validators = [componentOptions.validate, ...validators];
                }

                // If the field is required add the proper validator
                if (required) {
                    validators.unshift(isRequired);
                }

                // If the field is not required turn of any validator - be careful this doesn't cover checkboxes and
                // radio buttons
                if (!required && !value) {
                    validators = [];
                }

                // Iterate over the validators and check if one returns an error
                let result;
                for (let i = 0, l = validators.length; i < l && typeof result === 'undefined'; i++) {
                    result = validators[i](...args);
                }

                return result;
            };
        };

        return class extends React.Component<IncomeProps & Props, State> {
            static WrappedComponent = WrappedComponent;
            static displayName = `Fieldify(${wrappedComponentName})`;

            constructor(props) {
                super(props);

                this.state = {
                    validate: generateValidate(props.validate, { required: props.required })
                };
            }

            componentDidUpdate(prevProps) {
                if (prevProps.required !== this.props.required || prevProps.validate !== this.props.validate) {
                    this.setState({
                        validate: generateValidate(this.props.validate, { required: this.props.required })
                    });
                }
            }

            render() {
                let {
                    value,

                    parse = noop,
                    format = noop,
                    normalize = noop,

                    required,

                    ...rest
                } = this.props;

                // TODO: Do we realy need to convert null and undefined to an empty string?
                const normalizedValue = !!value ? value : '';

                // Combine component specific parser and formatters with instance specific ones
                parse = compose(componentOptions.parse || noop, parse);
                format = compose(componentOptions.format || noop, format);
                normalize = compose(componentOptions.normalize || noop, normalize);

                const propsFromComponentOptions: { type?: string } = {};
                if (componentOptions.type) {
                    propsFromComponentOptions.type = componentOptions.type;
                }

                const conditionalProps: { ['aria-required']?: boolean } = {};
                if (required) {
                    conditionalProps['aria-required'] = required;
                }

                return (
                    <Field
                        {...propsFromComponentOptions}
                        {...rest}
                        {...conditionalProps}
                        component={WrappedComponent}
                        value={normalizedValue}
                        validate={this.state.validate}
                        parse={parse}
                        format={format}
                        normalize={normalize}
                    />
                );
            }
        };
    };
};

export default fieldify;
