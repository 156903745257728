import React from 'react';
import { FormattedMessage } from 'react-intl';

export type ProductPromoteTextProps = {};

function ProductPromoteText({}: ProductPromoteTextProps) {
    return (
        <span>
            ⭐️ <FormattedMessage id="PRODUCT.PROMOTE_TEXT" />
        </span>
    );
}

export default ProductPromoteText;
