import { AUTHENTICATION, LOGOUT_SUCCESS } from '../actions';

// Types
import { Action } from '../../types';

export type State = boolean;

// Reducer
function isAuthenticated(state: State = false, action: Action): State {
    switch (action.type) {
        case AUTHENTICATION.SUCCESS:
            return true;

        case AUTHENTICATION.FAILURE:
        case LOGOUT_SUCCESS:
            return false;

        default:
            return state;
    }
}

export default isAuthenticated;
