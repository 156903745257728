import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { getRecruiter } from '../../modules/recruiters/selectors';
import { ApplicationState } from '../../store/reducer';

import { getDaysLeft } from '../../utils/date';

import { links } from '../../constants';

import { routesToMatch } from './TrialStatusBanner.constants';

import './TrialStatusBanner.style.scss';

export type TrialStatusBannerProps = {};

type ConnectedTrialStatusBannerProps = {
    trialEndDate: string | null;
};

type FullTrialStatusBannerProps = TrialStatusBannerProps & ConnectedTrialStatusBannerProps;

export function TrialStatusBanner({ trialEndDate }: FullTrialStatusBannerProps) {
    const intl = useIntl();
    const routeMatch = useRouteMatch({
        path: routesToMatch,
        exact: true
    });

    if (!trialEndDate || !routeMatch) {
        return null;
    }

    const daysLeft = getDaysLeft(trialEndDate, Date.now());

    if (daysLeft === 0) {
        return null;
    }

    return (
        <div className="TrialStatusBanner" data-testid="trial-status-banner">
            <svg className="TrialStatusBanner__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    fillRule="evenodd"
                    d="M12,2 C6.4775,2 2,6.4775 2,12 C2,17.5225 6.4775,22 12,22 C17.5225,22 22,17.5225 22,12 C21.9933333,6.48 17.52,2.00666667 12,2 Z M12,20.3333333 C7.3975,20.3333333 3.66666667,16.6025 3.66666667,12 C3.66666667,7.3975 7.3975,3.66666667 12,3.66666667 C16.6025,3.66666667 20.3333333,7.3975 20.3333333,12 C20.3283333,16.6 16.6,20.3283333 12,20.3333333 Z M16.2783333,15.175 L12.4166667,11.6341667 L12.4166667,7.41666667 C12.4166667,6.95666667 12.0433333,6.58333333 11.5833333,6.58333333 C11.1233333,6.58333333 10.75,6.95666667 10.75,7.41666667 L10.75,12 C10.75,12.2341667 10.8475,12.4566667 11.02,12.615 L15.1525,16.4025 C15.4933333,16.7091667 16.0166667,16.6866667 16.33,16.3516667 C16.64,16.0125 16.6175,15.4858333 16.2783333,15.175 Z"
                />
            </svg>

            <span className="TrialStatusBanner__text">
                <FormattedMessage
                    id="TRIALS_STATUS_BANNER.TEXT"
                    values={{
                        days: daysLeft
                    }}
                />{' '}
                <a
                    className="TrialStatusBanner__link"
                    href={links.calendly[intl.locale]}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FormattedMessage id="TRIALS_STATUS_BANNER.SET_CONSULTATION_LINK" />
                </a>
            </span>
        </div>
    );
}

function mapStateToProps(state: ApplicationState): ConnectedTrialStatusBannerProps {
    const recruiter = getRecruiter(state);

    let trialEndDate = (!!recruiter && recruiter.trial_end) || null;

    return {
        trialEndDate: trialEndDate
    };
}

const ConnectedTrialStatusBanner = connect(mapStateToProps)(TrialStatusBanner);

export default ConnectedTrialStatusBanner;
