import * as React from 'react';
import classnames from 'classnames';
import JobState from '../JobState';
import Details from '../Details';
import DetailsPrimary from '../Details/DetailsPrimary';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';

import './style.scss';

import { Job } from '../../modules/jobs/types';

type Props = {
    job?: Job | null;
    skeleton?: boolean;
    onClickCopy?: (jobId: number) => void;
};

class CopyJobListItem extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleClickCopy = this.handleClickCopy.bind(this);
    }

    handleClickCopy() {
        const { job, onClickCopy } = this.props;

        if (!!job && !!job.id && onClickCopy) {
            onClickCopy(job.id);
        }
    }

    render() {
        const { job, skeleton } = this.props;
        const classname = classnames('CopyJobListItem', {
            'CopyJobListItem--skeleton': !!this.props.skeleton
        });

        return (
            <div className={classname}>
                <div className="CopyJobListItem__informations">
                    <div className="CopyJobListItem__state">{!skeleton && !!job && <JobState job={job} />}</div>
                    <Details className="CopyJobListItem__title">
                        <DetailsPrimary truncate className="CopyJobListItem__details-primary">
                            {!skeleton && !!job ? job.title : null}
                        </DetailsPrimary>
                    </Details>
                </div>
                <div className="CopyJobListItem__action">
                    <span className="CopyJobListItem__button-wrapper">
                        <Button
                            typeStyle="raised"
                            variationStyle="brand"
                            className="CopyJobListItem__button"
                            onClick={this.handleClickCopy}
                        >
                            <FormattedMessage id="COPY_JOB_LIST_ITEM.BUTTON" />
                        </Button>
                    </span>
                </div>
            </div>
        );
    }
}

export default CopyJobListItem;
