import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InjectedFormProps, reduxForm } from 'redux-form';

import { links } from '../../../constants';

import FormFieldCheckbox from '../../../components/FormFieldCheckbox';
import FormFieldEmail from '../../../components/FormFieldEmail';
import FormFieldPassword from '../../../components/FormFieldPassword';
import SignupFormStepHeadline from '../../../components/SignupFormStepHeadline';
import SignupFormStepActions from '../SignupFormStepActions';

import { FORM_NAME, FormValues } from '../constants';

import './SignupFormStepLogin.style.scss';

type ConnectorProps = {
    forTrial: boolean;
};

type Props = ConnectorProps & InjectedFormProps<FormValues, ConnectorProps>;

function SignupFormStepLogin({ forTrial, handleSubmit, submitting, valid }: Props) {
    const intl = useIntl();

    let titleMessageId = 'SIGNUP_FORM_STEP_LOGIN.TITLE';
    let descriptionMessageId = 'SIGNUP_FORM_STEP_LOGIN.DESCRIPTION';
    let passwordLabelMessageId = 'SIGNUP_FORM_STEP_LOGIN.FIELD_PASSWORD.LABEL';

    if (forTrial) {
        titleMessageId = 'SIGNUP_FORM_STEP_LOGIN.TITLE_FOR_TRIAL';
        descriptionMessageId = 'SIGNUP_FORM_STEP_LOGIN.DESCRIPTION_FOR_TRIAL';
        passwordLabelMessageId = 'SIGNUP_FORM_STEP_LOGIN.FIELD_PASSWORD.LABEL_FOR_TRIAL';
    }

    return (
        <form onSubmit={handleSubmit}>
            <SignupFormStepHeadline>
                <FormattedMessage id={titleMessageId} />
            </SignupFormStepHeadline>
            <p className="SignupFormStepLogin__paragraph">
                <FormattedMessage id={descriptionMessageId} />
            </p>
            <div className="SignupFormStepLogin__content">
                <FormFieldEmail
                    name="email"
                    id="email"
                    label={<FormattedMessage id="SIGNUP_FORM_STEP_LOGIN.FIELD_EMAIL.LABEL" />}
                    required
                    autoFocus
                    autoComplete="email"
                />
                <FormFieldPassword
                    name="password"
                    id="password"
                    label={<FormattedMessage id={passwordLabelMessageId} />}
                    helpText={<FormattedMessage id="SIGNUP_FORM_STEP_LOGIN.FIELD_PASSWORD.HELPTEXT" />}
                    required
                    autoComplete="new-password"
                />
                <div className="tf-form-row">
                    <FormFieldCheckbox
                        name="acceptLatestGTC"
                        id="accept-latest-gtc"
                        label={
                            <FormattedMessage
                                id="SIGNUP_FORM_STEP_LOGIN.FIELD_GTC.LABEL"
                                values={{
                                    gtcLink: (
                                        <a href={links.gtc[intl.locale]} rel="noopener noreferrer" target="_blank">
                                            <FormattedMessage id="SIGNUP_FORM_STEP_LOGIN.FIELD_GTC.LINK" />
                                        </a>
                                    )
                                }}
                            />
                        }
                        required
                        errors={{
                            required: <FormattedMessage id="SIGNUP_FORM_STEP_LOGIN.FIELD_GTC.HELPTEXT" />
                        }}
                    />
                </div>
                <SignupFormStepActions
                    hideBack
                    submitting={submitting}
                    valid={valid}
                    onNext={handleSubmit}
                    forTrial={forTrial}
                />
            </div>
        </form>
    );
}

const withForm = reduxForm<FormValues, ConnectorProps>({
    form: FORM_NAME,
    destroyOnUnmount: false
});

export default withForm(SignupFormStepLogin);
