export const PROCESS_STATUS_NONE: 'none' = 'none';
export const PROCESS_STATUS_PROCESSING: 'processing' = 'processing';
export const PROCESS_STATUS_PROCESSED: 'processed' = 'processed';
export const PROCESS_STATUS_FAILED: 'failed' = 'failed';

export const PROCESS_STATUS = {
    NONE: PROCESS_STATUS_NONE,
    PROCESSING: PROCESS_STATUS_PROCESSING,
    PROCESSED: PROCESS_STATUS_PROCESSED,
    FAILED: PROCESS_STATUS_FAILED
};
