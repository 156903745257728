import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './SearchBarLoadMoreOption.style.scss';

type Props = {
    highlight: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const SearchBarLoadMoreOption = ({ highlight, ...rest }: Props, ref: any) => {
    return (
        <div
            {...rest}
            className={classNames('SearchBarLoadMoreOption', {
                'SearchBarLoadMoreOption--highlight': highlight
            })}
            ref={ref}
        >
            <FormattedMessage id="SEARCH_BAR_LOAD_MORE_OPTION.LABEL" />
        </div>
    );
};

export default forwardRef(SearchBarLoadMoreOption);
