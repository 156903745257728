import { v4 as uuidv4 } from 'uuid';

import { toEntries } from '../../utils/object';
import { containsChars } from '../../utils/string';
import { FILE_CATEGORIES } from '../../modules/files/constants';

import { FORM_CONTROL_NAME_PATTERN } from './constants';

export function convertSelectedTilesToRequestedDocuments(tiles) {
    return toEntries(tiles)
        .filter((entry) => entry.value)
        .map((entry) => ({ category: entry.key }));
}

export function convertSelectedTilesToRequestedDocumentsWithoutOther(tiles) {
    return convertSelectedTilesToRequestedDocuments(tiles).filter((tile) => tile.category !== FILE_CATEGORIES.OTHER);
}

export function noTileSelected(tiles) {
    const selectedTiles = convertSelectedTilesToRequestedDocumentsWithoutOther(tiles);

    return selectedTiles.length === 0;
}

export function getIndexFromName(name) {
    const [, indexStr] = name.match(FORM_CONTROL_NAME_PATTERN);

    return parseInt(indexStr);
}

export function getNonEmptyOtherFields(fields) {
    return fields.filter((input) => {
        return containsChars(input.value);
    });
}

export function getEmptyOtherFields(fields) {
    return fields.filter((input) => {
        return !containsChars(input.value);
    });
}

export function getNewOtherInput(propsToMerge) {
    return {
        id: uuidv4(),
        value: '',
        ...propsToMerge
    };
}

export function canSubmitRequest(selectedTiles, otherFieldValues) {
    if (selectedTiles.other) {
        return otherFieldValues.length > 0 && getEmptyOtherFields(otherFieldValues).length === 0;
    } else {
        return !noTileSelected(selectedTiles);
    }
}
