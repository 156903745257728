import * as React from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';

import Button from '../../../components/Button';
import SignupPageContainer from '../SignupPageContainer';

import { links } from '../../../constants';

import logoGreen from '../../../assets/images/logo-green.svg';
import logoWhite from '../../../assets/images/logo-white.svg';

import SignupPageNavigationOverlayMenu from './SignupPageNavigationOverlayMenu';

import './SignupPageNavigation.style.scss';

type Props = {
    hideLinks?: Boolean;
    onOpenLogin: () => void;
};

function SignupPageNavigation({ hideLinks, onOpenLogin }: Props) {
    const { locale }: IntlShape = useIntl();
    const [menuOpen, setMenuOpen] = React.useState(false);

    const handleClickMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const handleCloseMenu = () => {
        setMenuOpen(false);
    };

    return (
        <div className="SignupPageNavigation">
            <SignupPageContainer
                className="SignupPageNavigation__container"
                spacerClassName="SignupPageNavigation__spacer"
            >
                <div className="SignupPageNavigation__content">
                    <div className="SignupPageNavigation__logo-container">
                        <img src={logoWhite} className="SignupPageNavigation__logo SignupPageNavigation__logo--white" />
                        <img src={logoGreen} className="SignupPageNavigation__logo SignupPageNavigation__logo--green" />
                    </div>

                    {!hideLinks && (
                        <React.Fragment>
                            <div className="SignupPageNavigation__menu-toggle-container">
                                <button
                                    type="button"
                                    className="tf-button tf-button--flat SignupPageNavigation__menu-toggle"
                                    onClick={handleClickMenuToggle}
                                >
                                    <svg
                                        className="SignupPageNavigation__menu-toggle-icon"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M17.4797799,9.85355339 L12.0202201,15.3131133 C11.8249579,15.5083754 11.5083754,15.5083754 11.3131133,15.3131133 L5.85355339,9.85355339 C5.65829124,9.65829124 5.65829124,9.34170876 5.85355339,9.14644661 C5.94732158,9.05267842 6.07449854,9 6.20710678,9 L17.1262266,9 C17.4023689,9 17.6262266,9.22385763 17.6262266,9.5 C17.6262266,9.63260824 17.5735481,9.7597852 17.4797799,9.85355339 Z"
                                        />
                                    </svg>
                                </button>
                            </div>
                            <SignupPageNavigationOverlayMenu
                                open={menuOpen}
                                onClose={handleCloseMenu}
                                onOpenLogin={onOpenLogin}
                            />

                            <ul className="SignupPageNavigation__menu">
                                <li className="SignupPageNavigation__menu-item">
                                    <Button
                                        typeStyle="flat"
                                        variationStyle="normal"
                                        component="a"
                                        href={links.websiteB2B[locale]}
                                    >
                                        <FormattedMessage id="SIGNUP_PAGE.NAVIGATION.LINK_ABOUT" />
                                    </Button>
                                </li>

                                <li className="SignupPageNavigation__menu-item">
                                    <Button
                                        typeStyle="outlined"
                                        variationStyle="normal"
                                        type="button"
                                        onClick={onOpenLogin}
                                    >
                                        <FormattedMessage id="SIGNUP_PAGE.NAVIGATION.LINK_LOGIN" />
                                    </Button>
                                </li>
                            </ul>
                        </React.Fragment>
                    )}
                </div>
            </SignupPageContainer>
        </div>
    );
}

export default SignupPageNavigation;
