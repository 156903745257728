import { isDeletable, isDraft } from '../../modules/jobs/utils';

import config from '../../config';

import { Job } from '../../modules/jobs/types';

export function shouldShow(job: Job) {
    if (!config.showDeleteJob) {
        return false;
    }

    return isDeletable(job) && !isDraft(job);
}
