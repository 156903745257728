import { Locale } from '../../i18n/locale';
import { Deferred } from '../../utils/promise';
import { createAction, createActionTypeCreator } from '../../utils/redux';

const createActionType = createActionTypeCreator('I18N');

export const SET_LOCALE = createActionType('SET_LOCALE');
export const setLocale = createAction(SET_LOCALE, (locale: Locale, { promise }: { promise?: Deferred<void> } = {}) => {
    return {
        payload: {
            locale
        },
        meta: {
            promise
        }
    };
});
export type SetLocaleAction = ReturnType<typeof setLocale>;
