import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { Recruiter } from '../../../modules/recruiters/types';
import { isSignedUp } from '../../../modules/recruiters/utils';

import config from '../../../config';
import { links } from '../../../constants';
import { getJobCreateRoute, getPricesRoute, getSignupRoute } from '../../../routes';

import Button from '../../../components/Button';
import OverlayUserMenu from '../../../components/OverlayUserMenu';

import SearchBar from '../../SearchBar';

import UserMenuDropdown from './UserMenuDropdown';

type Props = {
    recruiter: Recruiter;

    onClickLogout: () => void;
    onClickTutorial: () => void;
};

function LoggedInActions({
    recruiter,

    onClickLogout,
    onClickTutorial
}: Props) {
    const intl = useIntl();

    const [menuOpen, setMenuOpen] = React.useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const toggleMenu = () => {
        if (matchMedia('(max-width: 769px)').matches) {
            setMenuOpen(!menuOpen);
        } else {
            setDropdownOpen(!dropdownOpen);
        }
    };

    const closeMenu = () => {
        setMenuOpen(false);
        setDropdownOpen(false);
    };

    const primaryActionTargetPath = isSignedUp(recruiter)
        ? getJobCreateRoute()
        : getSignupRoute({ destinationUrl: '/job-create' });

    return (
        <div className="NavigationActions">
            {!!config.showCalendlyLink && (!isSignedUp(recruiter) || true) && (
                <a
                    href={links.calendly[intl.locale]}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="NavigationActions__action NavigationActions__link NavigationActions__action--lg"
                >
                    <FormattedMessage id="NAVIGATION.ACTIONS.SECONDARY.SCHEDULE_CALL" />
                </a>
            )}

            <Link
                to={getPricesRoute()}
                className="NavigationActions__action NavigationActions__link NavigationActions__action--md"
            >
                <FormattedMessage id="NAVIGATION.ACTIONS.SECONDARY.PRICES" />
            </Link>
            <Button
                component={Link}
                to={primaryActionTargetPath}
                typeStyle="raised"
                variationStyle="brand"
                className="NavigationActions__action NavigationActions__action--sm"
            >
                <FormattedMessage id="NAVIGATION.ACTIONS.PRIMARY.CREATE_JOB_AD" />
            </Button>

            <div className="NavigationActions__search">
                <SearchBar />
            </div>

            <UserMenuDropdown
                open={dropdownOpen}
                recruiter={recruiter}
                onToggle={toggleMenu}
                onClickLogout={onClickLogout}
                onClickTutorial={onClickTutorial}
            />

            <OverlayUserMenu
                open={menuOpen}
                onClose={closeMenu}
                onClickLogout={onClickLogout}
                recruiter={recruiter}
                onClickTutorial={onClickTutorial}
            />
        </div>
    );
}

export default LoggedInActions;
