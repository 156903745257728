import * as Sentry from '@sentry/browser';
import type { PostHog, Properties } from 'posthog-js';
import { extractPath } from '../../utils/url';
import { findTrackableRouteWithExcludes } from '../../routes/routesAnalytics';
import { routesRegistry } from '../../routes/registry';

export function initialize() {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    if (typeof window === 'undefined') {
        return;
    }

    if (typeof window.posthog !== 'object') {
        if (process.env.NODE_ENV !== 'production') {
            console.log('PostHog is not initialized');
        }

        Sentry.captureMessage('PostHog is not initialized', {
            level: 'warning'
        });
        return;
    }

    const posthog = window.posthog;

    posthog.set_config({
        sanitize_properties: sanitizeProperties
    });
}

export function trackEvent(eventName: string, props: { [key: string]: any } = {}) {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    if (process.env.NODE_ENV !== 'production') {
        console.groupCollapsed(`Track PostHog event: ${eventName}`);
        console.log(`Name: ${eventName}`);

        for (const propName in props) {
            if (Object.prototype.hasOwnProperty.call(props, propName)) {
                console.log(`Prop '${propName}':`, props[propName]);
            }
        }

        console.groupEnd();
    }

    execute((posthog) => {
        posthog.capture(eventName, props);
    });
}

export function trackPageview(path: string, props: Record<string, any> = {}) {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    const url = new URL(path, window.location.href);

    if (process.env.NODE_ENV !== 'production') {
        console.groupCollapsed(`Track PostHog pageview: ${path}`);
        console.log(`URL: ${url.toString()}`);

        for (const propName in props) {
            if (Object.prototype.hasOwnProperty.call(props, propName)) {
                console.log(`Prop '${propName}':`, props[propName]);
            }
        }

        console.groupEnd();
    }

    execute((posthog) => {
        posthog.capture('$pageview', {
            $current_url: url.toString(),
            $pathname: extractPath(url)
        });
    });
}

export function identify(userId: number) {
    execute((posthog) => {
        posthog.identify(String(userId));
    });
}

export function unidentify() {
    execute((posthog) => {
        posthog.reset();
    });
}

export function setUserPropertiesOnce(properties: Record<string, any>) {
    execute((posthog) => {
        // NOTE: This function is deprecated, but we use it as long as `setPersonProperties` is not available in the init snippet.
        posthog.people.set_once(properties);
    });
}

export function sanitizeProperties(properties: Properties) {
    let nextProperties = properties;

    if (!!properties.$pathname || !!properties.$current_url) {
        let pathname = properties.$pathname ?? extractPath(properties.$current_url);
        const trackableRoute = findTrackableRouteWithExcludes(routesRegistry, pathname);

        const nextPathname = trackableRoute.path;
        const nextCurrentUrl = new URL(nextPathname, properties.$current_url).href;

        nextProperties = {
            ...nextProperties,
            $pathname: nextPathname,
            $current_url: nextCurrentUrl,
            route: trackableRoute.props
        };
    }

    return nextProperties;
}

function execute(callback: (posthog: PostHog) => void) {
    if (typeof window.posthog === 'object') {
        callback(window.posthog);
    }
}
