import * as React from 'react';
import { injectIntl } from 'react-intl';

import { LOCALE_DE, LOCALE_EN } from '../../i18n';

import englishFlag from '../../assets/images/flags/flag-english.png';
import germanFlag from '../../assets/images/flags/flag-german.png';

import { Locale } from '../../i18n';
import { Intl } from '../../types/intl';

type Props = {
    language: Locale;
    className?: string;
    intl: Intl;
};

const LanguageFlag = ({ language, className, intl }: Props) => {
    let src: string | null = null;
    let altId: string | null = null;

    switch (language) {
        case LOCALE_DE: {
            src = germanFlag;
            altId = 'LANGUAGE_FLAGS.GERMAN';
            break;
        }
        case LOCALE_EN: {
            src = englishFlag;
            altId = 'LANGUAGE_FLAGS.ENGLISH';
            break;
        }
    }

    if (src === null || altId === null) {
        return null;
    }

    return (
        <img
            src={src}
            className={className}
            alt={intl.formatMessage({
                id: altId
            })}
        />
    );
};

export default injectIntl(LanguageFlag);
