import { createSelector } from 'reselect';
import { ApplicationState } from '../../store/types';
import { JOB_PARSING_MOUNT, JobParsingState } from './reducer';

function getJobParsing(state: ApplicationState): JobParsingState {
    return state[JOB_PARSING_MOUNT];
}

export const getJobParsingStatus = createSelector(getJobParsing, (jobParsing) => {
    return jobParsing.status;
});

export const getJobParsingError = createSelector(getJobParsing, (jobParsing) => {
    return jobParsing.error;
});

export const getJobParsingServerErrors = createSelector(getJobParsing, (jobParsing) => {
    return jobParsing.serverErrors;
});
