import { all, spawn, take, put, call, race } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { startSubmit, stopSubmit, ErrorOther } from 'redux-form';

import { hasErrorWithCode } from '../../services/api/utils';
import { ERROR_CODE_EMAIL_NOT_CONFIRMED, ERROR_INVALID_CREDENTIALS } from '../../services/api/constants';

import {
    AUTHENTICATION,
    EMAIL_AND_PASSWORD_AUTHENTICATION,
    loginWithEmailAndPassword
} from '../../modules/authentication/actions';

import { FORM_ERROR_EMAIL_NOT_CONFIRMED, FORM_ERROR_INVALID_CREDENTIALS, FORM_NAME, FormValues } from './constants';
import { SUBMIT_FORM } from './actions';

export function* submitForm(values: FormValues, destinationUrl?: string): SagaIterator {
    yield put(startSubmit(FORM_NAME));

    const { email, password } = values;
    yield put(loginWithEmailAndPassword(email, password, destinationUrl));

    const { /* success,*/ failure } = yield race({
        success: take(AUTHENTICATION.SUCCESS),
        failure: take(EMAIL_AND_PASSWORD_AUTHENTICATION.FAILURE)
    });

    if (!failure) {
        yield put(stopSubmit(FORM_NAME));
    } else {
        const errorsFromServer = failure.payload.errors;
        const errors: ErrorOther<string> = {};

        if (hasErrorWithCode(errorsFromServer, ERROR_INVALID_CREDENTIALS)) {
            errors._error = FORM_ERROR_INVALID_CREDENTIALS;
        } else if (hasErrorWithCode(errorsFromServer, ERROR_CODE_EMAIL_NOT_CONFIRMED)) {
            errors._error = FORM_ERROR_EMAIL_NOT_CONFIRMED;
        }

        yield put(stopSubmit(FORM_NAME, errors));
    }
}

export function* watchSubmitForm(): SagaIterator {
    while (true) {
        const action = yield take(SUBMIT_FORM);

        const values = action.payload.values;
        const destinationUrl = action.payload.destinationUrl;

        yield call(submitForm, values, destinationUrl);
    }
}

export default function* rootSage(): SagaIterator {
    yield all([spawn(watchSubmitForm)]);
}
