import { omit } from '../../utils/object';

import * as localStorage from '../localStorage';

const STORAGE_KEY_MESSAGE_DRAFTS = 'messageDrafts';

const getCurrentDraftsObject = () => localStorage.get(STORAGE_KEY_MESSAGE_DRAFTS) || {};

export const setDraft = (candidateId, message) => {
    const currentDrafts = getCurrentDraftsObject();

    localStorage.set(STORAGE_KEY_MESSAGE_DRAFTS, {
        ...currentDrafts,
        [candidateId]: message
    });
};

export const getDraft = (candidateId) => {
    const currentDrafts = getCurrentDraftsObject();

    return currentDrafts[String(candidateId)] || '';
};

export const removeDraft = (candidateId) => {
    const currentDrafts = getCurrentDraftsObject();

    localStorage.set(STORAGE_KEY_MESSAGE_DRAFTS, omit(currentDrafts, String(candidateId)));
};

export const clearDrafts = () => {
    localStorage.remove(STORAGE_KEY_MESSAGE_DRAFTS);
};
