import { move } from '../../../utils/array';

export type DragMoveOnDragEndState<Type> = {
    activeModifications: number;
    temporaryModificationList: Type[];
};

const ACTION_START_MODIFICATION: 'start-modification' = 'start-modification';

type ActionStartModification<Type> = {
    type: typeof ACTION_START_MODIFICATION;
    items: Type[];
};

export function startModification<Type>(items: Type[]): ActionStartModification<Type> {
    return {
        type: ACTION_START_MODIFICATION,
        items
    };
}

const ACTION_STOP_MODIFICATION: 'stop-modification' = 'stop-modification';

type ActionStopModifcation = {
    type: typeof ACTION_STOP_MODIFICATION;
};

export function stopModifcation(): ActionStopModifcation {
    return {
        type: ACTION_STOP_MODIFICATION
    };
}

const ACTION_UPDATE_TEMPORARY_MODIFICATION_LIST: 'update-temporary-modification-list' =
    'update-temporary-modification-list';

type ActionUpdateTemporaryModificationList = {
    type: typeof ACTION_UPDATE_TEMPORARY_MODIFICATION_LIST;
    fromIndex: number;
    toIndex: number;
};

export function updateTemporaryModificationList(
    fromIndex: number,
    toIndex: number
): ActionUpdateTemporaryModificationList {
    return {
        type: ACTION_UPDATE_TEMPORARY_MODIFICATION_LIST,
        fromIndex,
        toIndex
    };
}

type DragMoveOnDragEndStateReducerAction<Type> =
    | ActionStartModification<Type>
    | ActionStopModifcation
    | ActionUpdateTemporaryModificationList;

type DragMoveOnDragEndStateReducer<Type> = (
    state: DragMoveOnDragEndState<Type> | undefined,
    action: DragMoveOnDragEndStateReducerAction<Type> | undefined
) => DragMoveOnDragEndState<Type>;

export function createDragMoveOnDragEndStateReducer<Type>(): DragMoveOnDragEndStateReducer<Type> {
    const initialState: DragMoveOnDragEndState<any> = {
        activeModifications: 0,
        temporaryModificationList: []
    };

    return (
        state: DragMoveOnDragEndState<Type> = initialState,
        action: DragMoveOnDragEndStateReducerAction<Type> | undefined = undefined
    ): DragMoveOnDragEndState<Type> => {
        switch (action?.type) {
            case ACTION_START_MODIFICATION: {
                const nextActiveModifications = state.activeModifications + 1;
                const nextTemporaryModificationList =
                    nextActiveModifications === 1 ? action.items : state.temporaryModificationList;

                return {
                    ...state,
                    activeModifications: nextActiveModifications,
                    temporaryModificationList: nextTemporaryModificationList
                };
            }

            case ACTION_STOP_MODIFICATION: {
                const nextActiveModifications = Math.max(0, state.activeModifications - 1);
                // We reset the temporary modification list if it was the last active modification
                const nextTemporaryModificationList =
                    nextActiveModifications === 0 ? [] : state.temporaryModificationList;

                return {
                    ...state,
                    activeModifications: nextActiveModifications,
                    temporaryModificationList: nextTemporaryModificationList
                };
            }

            case ACTION_UPDATE_TEMPORARY_MODIFICATION_LIST: {
                const nextTemporaryModificationList = move(
                    state.temporaryModificationList,
                    action.fromIndex,
                    action.toIndex
                );

                return {
                    ...state,
                    temporaryModificationList: nextTemporaryModificationList
                };
            }

            default: {
                return state;
            }
        }
    };
}
