export function includes<T extends U, U>(coll: ReadonlyArray<T>, el: U): el is T {
    return coll.includes(el as T);
}

export function move<T>(array: Array<T>, fromIndex: number, toIndex: number): Array<T> {
    const temp1 = [...array];

    temp1.splice(toIndex, 0, temp1.splice(fromIndex, 1)[0]);

    return temp1;
}

export function range(count: number, offset: number = 0): Array<number> {
    const arr: number[] = [];

    for (let i = offset; i < count + offset; i++) {
        arr.push(i);
    }

    return arr;
}

export function sort<Type>(array: Array<Type>, compareFn?: (a: Type, b: Type) => number): Array<Type> {
    return [...array].sort(compareFn);
}

export function toArray<T>(value: T | T[]): T[] {
    if (value instanceof Array) {
        return value as T[];
    }

    return [value];
}

export function removeElementAtIndex<T>(array: Array<T>, targetIndex: number): Array<T> {
    return array.filter((_, index) => index !== targetIndex);
}
