import React from 'react';
import AuthenticationState, { AuthenticationStateProps } from './AuthenticationState';
import { useAuthenticationState } from './useAuthenticationState';

export type AuthenticationStateContainerProps = Omit<AuthenticationStateProps, 'state'>;

function AuthenticationStateContainer(props: AuthenticationStateContainerProps) {
    const state = useAuthenticationState();

    return <AuthenticationState {...props} state={state} />;
}

export default AuthenticationStateContainer;
