import * as React from 'react';

type Props = {
    children: React.ReactNode;
};

const HeadlineTitle = (props: Props) => {
    return <h1 className="Headline__title">{props.children}</h1>;
};

export default HeadlineTitle;
