import { OrderingField, Ordering, OrderValue } from './constants';

export function determinateOrder(fieldName: string, currentOrdering: Ordering): OrderValue {
    if (!!currentOrdering) {
        if (fieldName === currentOrdering) {
            return 'asc';
        } else if (currentOrdering.substr(0, 1) === '-' && fieldName === currentOrdering.substr(1)) {
            return 'desc';
        }
    }

    return null;
}

export function determinateFieldNameWithOrder(currentOrdering: Ordering): string | null {
    if (currentOrdering === null) {
        return null;
    }

    let fieldName = currentOrdering;
    let value = 'asc';

    if (fieldName.substr(0, 1) === '-') {
        fieldName = fieldName.substr(1) as Exclude<Ordering, null>;
        value = 'desc';
    }

    return fieldName + '-' + value;
}

export function createOrdering(fieldName: OrderingField, value: OrderValue): Ordering {
    if (value === 'asc') {
        return fieldName;
    } else if (value === 'desc') {
        return `-${fieldName}` as Exclude<Ordering, null>;
    }

    return null;
}

export function createOrderingByFieldNameWithOrder(fieldNameWithOrder: string): Ordering {
    if (!fieldNameWithOrder) {
        return null;
    }

    const [, /* ignore */ fieldName, value] = fieldNameWithOrder.match(/(.+)-(asc|desc)$/) as any;

    return createOrdering(fieldName, value);
}
