import * as React from 'react';

import iphoneBackwardsIcon from '../../../../assets/images/MobileApp/ic_iphone-backwards.svg';
import iphoneShareIcon from '../../../../assets/images/MobileApp/ic_iphone-share.svg';

import './style.scss';

const iconFiles = {
    back: iphoneBackwardsIcon,
    share: iphoneShareIcon
};

type Props = {
    type: 'back' | 'share';
};

const MobileAppNavBarIcon = (props: Props): React.ReactElement => {
    return <img className="MobileAppNavBarIcon" src={iconFiles[props.type]} role="presentation" />;
};

export default MobileAppNavBarIcon;
