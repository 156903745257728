import React from 'react';
import { connect } from 'react-redux';

import { State as ApplicationState } from '../../store/reducer';
import { FETCH_STATUS } from '../../modules/constants';
import { ApplicantId, Candidate, CandidateId } from '../../modules/candidates/types';
import { getByApplicantFetchStatus, getOtherCandidatesThatMatchApplicant } from '../../modules/candidates/selectors';
import { fetchCandidatesByApplicantId } from '../../modules/candidates/actions';

import './CandidateAlsoAppliedFor.scss';
import CandidateAlsoAppliedForView from '../CandidateAlsoAppliedForView';
import CandidatesJobList from '../../components/CandidatesJobList';
import { FormattedMessage } from 'react-intl';

type ConnectorProps = {
    candidateId: CandidateId;
    applicantId: ApplicantId;
};

type ConnectedStateProps = {
    isLoading: boolean;
    hasFailed: boolean;
    candidates: Candidate[];
};

type ConnectedDispatchProps = {
    fetchCandidatesByApplicantId: typeof fetchCandidatesByApplicantId;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps & {};

function CandidateAlsoAppliedFor({
    candidateId,
    applicantId,
    fetchCandidatesByApplicantId,
    isLoading,
    hasFailed,
    candidates
}: Props) {
    React.useEffect(() => {
        fetchCandidatesByApplicantId({ applicantId });
    }, [applicantId]);

    const [isMoreViewOpen, setIsMoreViewOpen] = React.useState(false);

    const openMoreView = () => setIsMoreViewOpen(true);
    const closeMoreView = () => setIsMoreViewOpen(false);

    const onClickMore = () => {
        openMoreView();
    };

    const onCloseMoreView = () => {
        closeMoreView();
    };

    return (
        <div className="CandidateAlsoAppliedFor">
            {isLoading && <div>Loading ...</div>}
            {hasFailed && <div>Failed to load</div>}
            {!isLoading && !hasFailed && (
                <React.Fragment>
                    <div className="CandidateAlsoAppliedFor__list">
                        <CandidatesJobList candidates={candidates.slice(0, 2)} />
                    </div>

                    {candidates.length > 2 && (
                        <React.Fragment>
                            <div className="CandidateAlsoAppliedFor__more-container">
                                <button
                                    type="button"
                                    className="CandidateAlsoAppliedFor__more-link"
                                    onClick={onClickMore}
                                >
                                    <FormattedMessage
                                        id="CANDIDATE_ALSO_APPLIED_FOR.MORE_LINK"
                                        values={{ candidatesCount: candidates.length - 2 }}
                                    />
                                </button>
                            </div>
                            <CandidateAlsoAppliedForView
                                open={isMoreViewOpen}
                                onClose={onCloseMoreView}
                                applicantId={applicantId}
                                candidateId={candidateId}
                            />
                        </React.Fragment>
                    )}
                </React.Fragment>
            )}
        </div>
    );
}

function mapStateToProps(state: ApplicationState, props: ConnectorProps): ConnectedStateProps {
    const { applicantId, candidateId } = props;

    const fetchStatus = getByApplicantFetchStatus(state, applicantId);

    const isLoading = fetchStatus === FETCH_STATUS.NONE || fetchStatus === FETCH_STATUS.LOADING;
    const hasFailed = fetchStatus === FETCH_STATUS.FAILED;

    const candidates = getOtherCandidatesThatMatchApplicant(state, applicantId, [candidateId]);

    return {
        isLoading,
        hasFailed,
        candidates
    };
}

const mapDispatchToProps: ConnectedDispatchProps = {
    fetchCandidatesByApplicantId
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateAlsoAppliedFor);
