import { Job } from '../../modules/jobs/types';
import {
    JobFormFunctionsFormFieldsName,
    JobFormFunctionsFormFieldsValue,
    JOB_EDIT_FUNCTIONS_FORM_FIELDS_NAME
} from './JobFormAdDetailsFormFieldset/JobFormFunctionsFormFields';
import {
    JobFormIsStartupFormFieldName,
    JobFormIsStartupFormFieldValue,
    JOB_EDIT_IS_STARTUP_FORM_FIELD_NAME
} from './JobFormAdDetailsFormFieldset/JobFormIsStartupFormField';
import {
    JobFormTitleFormFieldName,
    JobFormTitleFormFieldValue,
    JOB_EDIT_TITLE_FORM_FIELD_NAME
} from './JobFormAdDetailsFormFieldset/JobFormTitleFormField';
import {
    JobFormBenefitsFormFieldName,
    JobFormBenefitsFormFieldValue,
    JOB_EDIT_BENEFITS_FORM_FIELD_NAME
} from './JobFormBenefitsFormFieldset/JobFormBenefitsFormField';
import {
    JobFormCompanyFormFieldName,
    JobFormCompanyFormFieldValue,
    JOB_EDIT_COMPANY_FORM_FIELD_NAME
} from './JobFormCompanyDetailsFormFieldset/JobFormCompanyFormField';
import {
    JobFormSectorsFormFieldsName,
    JobFormSectorsFormFieldsValue,
    JOB_EDIT_SECTORS_FORM_FIELDS_NAME
} from './JobFormCompanyDetailsFormFieldset/JobFormSectorsFormFields';
import {
    JobFormLocationFormFieldName,
    JobFormLocationFormFieldValue,
    JOB_EDIT_LOCATION_FORM_FIELD_NAME
} from './JobFormLocationFormField';
import {
    JobFormRemotePossibleFormFieldName,
    JobFormRemotePossibleFormFieldValue,
    JOB_EDIT_REMOTE_POSSIBLE_FORM_FIELD_NAME
} from './JobFormRemotePossibleFormField';
import {
    JobFormLanguagesFormFieldsName,
    JobFormLanguagesFormFieldsValue,
    JOB_EDIT_LANGUAGES_FORM_FIELDS_NAME
} from './JobFormRequirementsFormFieldset/JobFormLanguagesFormFields';
import {
    JobFormSkillsFormFieldsName,
    JobFormSkillsFormFieldsValue,
    JOB_EDIT_SKILLS_FORM_FIELDS_NAME
} from './JobFormRequirementsFormFieldset/JobFormSkillsFormFields';
import {
    JobFormTalentsFormFieldsName,
    JobFormTalentsFormFieldsValue,
    JOB_EDIT_TALENTS_FORM_FIELDS_NAME
} from './JobFormRequirementsFormFieldset/JobFormTalentsFormFields';
import {
    JobFormTasksFormFieldsName,
    JobFormTasksFormFieldsValue,
    JOB_EDIT_TASKS_FORM_FIELDS_NAME
} from './JobFormTasksFormFieldset/JobFormTasksFormFields';
import {
    JobFormAvailabilityFormFieldsValue,
    JobFormAvailabilityStartFormFieldName,
    JobFormAvailabilityDateFormFieldName,
    JOB_EDIT_AVAILABILITY_START_FORM_FIELD_NAME,
    JOB_EDIT_AVAILABILITY_DATE_FORM_FIELD_NAME,
    JOB_EDIT_AVAILABILITY_FORM_FIELDS_NAME,
    JobFormAvailabilityFormFieldsName
} from './JobFormWorkConditionsFormFieldset/JobFormAvailabilityFormFields';
import {
    JobFormCareerStatutesFormFieldsName,
    JobFormCareerStatutesFormFieldsValue,
    JOB_EDIT_CAREER_STATUTES_FORM_FIELDS_NAME
} from './JobFormWorkConditionsFormFieldset/JobFormCareerStatutesFormFields';
import {
    JobFormExperiencesFormFieldsName,
    JobFormExperiencesFormFieldsValue,
    JOB_EDIT_EXPERIENCES_FORM_FIELDS_NAME
} from './JobFormWorkConditionsFormFieldset/JobFormExperiencesFormFields';
import {
    JobFormHiringBudgetBudgetLowerFormFieldName,
    JobFormHiringBudgetBudgetHigherFormFieldName,
    JobFormHiringBudgetScaleFormFieldName,
    JobFormHiringBudgetFormFieldsValue,
    JOB_EDIT_HIRING_BUDGET_FORM_FIELDS_NAME,
    JOB_EDIT_HIRING_BUDGET_BUDGET_HIGHER_FORM_FIELD_NAME,
    JOB_EDIT_HIRING_BUDGET_BUDGET_LOWER_FORM_FIELD_NAME,
    JOB_EDIT_HIRING_BUDGET_SCALE_FORM_FIELD_NAME,
    JobFormHiringBudgetFormFieldsName
} from './JobFormWorkConditionsFormFieldset/JobFormHiringBudgetFormFields';
import {
    JobFormWorktypesFormFieldsName,
    JobFormWorktypesFormFieldsValue,
    JOB_EDIT_WORKTYPES_FORM_FIELDS_NAME
} from './JobFormWorkConditionsFormFieldset/JobFormWorktypesFormFields';

export type JobFormPageSubmissionType = 'close' | 'checkout' | 'view-in-app';
export const JOB_FORM_PAGE_SUBMISSION_TYPE_CLOSE: 'close' = 'close';
export const JOB_FORM_PAGE_SUBMISSION_TYPE_CHECKOUT: 'checkout' = 'checkout';
export const JOB_FORM_PAGE_SUBMISSION_TYPE_VIEW_IN_APP = 'view-in-app';

export type JobFormPageValidationLevel = 'minimal' | 'full';
export const JOB_FORM_PAGE_VALIDATION_LEVEL_MINIMAL = 'minimal';
export const JOB_FORM_PAGE_VALIDATION_LEVEL_FULL = 'full';

export type JobFormPageDataValues = {
    company: JobFormCompanyFormFieldValue;
    sectors: JobFormSectorsFormFieldsValue;
    title: JobFormTitleFormFieldValue;
    is_startup: JobFormIsStartupFormFieldValue;
    function: JobFormFunctionsFormFieldsValue;
    location: JobFormLocationFormFieldValue;
    remote_possible: JobFormRemotePossibleFormFieldValue;
    experiences: JobFormExperiencesFormFieldsValue;
    worktypes: JobFormWorktypesFormFieldsValue;
    career_statutes: JobFormCareerStatutesFormFieldsValue;
    start_date: JobFormAvailabilityFormFieldsValue;
    hiring_budget: JobFormHiringBudgetFormFieldsValue;
    tasks: JobFormTasksFormFieldsValue;
    skills: JobFormSkillsFormFieldsValue;
    talents: JobFormTalentsFormFieldsValue;
    languages: JobFormLanguagesFormFieldsValue;
    benefits: JobFormBenefitsFormFieldValue;
};

export type JobFormPageMetaValues = {
    '_validation-level': JobFormPageValidationLevel;
    '_submission-type': JobFormPageSubmissionType | null;
};

export type JobFormPageValues = JobFormPageDataValues & JobFormPageMetaValues;

export type JobFormPageDataFieldNames = keyof JobFormPageDataValues;

export type JobFormPageFieldName =
    | JobFormCompanyFormFieldName
    | JobFormSectorsFormFieldsName
    | JobFormTitleFormFieldName
    | JobFormIsStartupFormFieldName
    | JobFormFunctionsFormFieldsName
    | JobFormLocationFormFieldName
    | JobFormRemotePossibleFormFieldName
    | JobFormExperiencesFormFieldsName
    | JobFormWorktypesFormFieldsName
    | JobFormCareerStatutesFormFieldsName
    | JobFormAvailabilityFormFieldsName
    | JobFormHiringBudgetFormFieldsName
    | JobFormTasksFormFieldsName
    | JobFormSkillsFormFieldsName
    | JobFormTalentsFormFieldsName
    | JobFormLanguagesFormFieldsName
    | JobFormBenefitsFormFieldName;

/**
 * List of all field names – only the nested field names if nested.
 */
export type JobFormPageFieldNameWithNested =
    | JobFormCompanyFormFieldName
    | JobFormSectorsFormFieldsName
    | JobFormTitleFormFieldName
    | JobFormIsStartupFormFieldName
    | JobFormFunctionsFormFieldsName
    | JobFormLocationFormFieldName
    | JobFormRemotePossibleFormFieldName
    | JobFormExperiencesFormFieldsName
    | JobFormWorktypesFormFieldsName
    | JobFormCareerStatutesFormFieldsName
    | JobFormAvailabilityStartFormFieldName
    | JobFormAvailabilityDateFormFieldName
    | JobFormHiringBudgetBudgetLowerFormFieldName
    | JobFormHiringBudgetBudgetHigherFormFieldName
    | JobFormHiringBudgetScaleFormFieldName
    | JobFormTasksFormFieldsName
    | JobFormSkillsFormFieldsName
    | JobFormTalentsFormFieldsName
    | JobFormLanguagesFormFieldsName
    | JobFormBenefitsFormFieldName;

/**
 * List of all field names – only the nested field names if nested.
 */
export const JOB_FORM_PAGE_FIELD_NAMES_WITH_NESTED: JobFormPageFieldNameWithNested[] = [
    JOB_EDIT_COMPANY_FORM_FIELD_NAME,
    JOB_EDIT_SECTORS_FORM_FIELDS_NAME,
    JOB_EDIT_TITLE_FORM_FIELD_NAME,
    JOB_EDIT_IS_STARTUP_FORM_FIELD_NAME,
    JOB_EDIT_FUNCTIONS_FORM_FIELDS_NAME,
    JOB_EDIT_LOCATION_FORM_FIELD_NAME,
    JOB_EDIT_REMOTE_POSSIBLE_FORM_FIELD_NAME,
    JOB_EDIT_EXPERIENCES_FORM_FIELDS_NAME,
    JOB_EDIT_WORKTYPES_FORM_FIELDS_NAME,
    JOB_EDIT_CAREER_STATUTES_FORM_FIELDS_NAME,
    JOB_EDIT_AVAILABILITY_START_FORM_FIELD_NAME,
    JOB_EDIT_AVAILABILITY_DATE_FORM_FIELD_NAME,
    JOB_EDIT_HIRING_BUDGET_BUDGET_LOWER_FORM_FIELD_NAME,
    JOB_EDIT_HIRING_BUDGET_BUDGET_HIGHER_FORM_FIELD_NAME,
    JOB_EDIT_HIRING_BUDGET_SCALE_FORM_FIELD_NAME,
    JOB_EDIT_TASKS_FORM_FIELDS_NAME,
    JOB_EDIT_SKILLS_FORM_FIELDS_NAME,
    JOB_EDIT_TALENTS_FORM_FIELDS_NAME,
    JOB_EDIT_LANGUAGES_FORM_FIELDS_NAME,
    JOB_EDIT_BENEFITS_FORM_FIELD_NAME
];

type JobFormPageNestedFieldNameToParentFieldNameResolveMap = {
    [Key in JobFormAvailabilityStartFormFieldName]: JobFormAvailabilityFormFieldsName;
} &
    {
        [Key in JobFormAvailabilityDateFormFieldName]: JobFormAvailabilityFormFieldsName;
    } &
    {
        [Key in JobFormHiringBudgetBudgetLowerFormFieldName]: JobFormHiringBudgetFormFieldsName;
    } &
    {
        [Key in JobFormHiringBudgetBudgetHigherFormFieldName]: JobFormHiringBudgetFormFieldsName;
    } &
    {
        [Key in JobFormHiringBudgetScaleFormFieldName]: JobFormHiringBudgetFormFieldsName;
    };

/**
 * Mapping of the nested field name to their parent field name.
 */
export const JOB_FORM_PAGE_NESTED_FIELD_NAME_TO_PARENT_FIELD_NAME_RESOLVE_MAP: JobFormPageNestedFieldNameToParentFieldNameResolveMap = {
    [JOB_EDIT_AVAILABILITY_START_FORM_FIELD_NAME]: JOB_EDIT_AVAILABILITY_FORM_FIELDS_NAME,
    [JOB_EDIT_AVAILABILITY_DATE_FORM_FIELD_NAME]: JOB_EDIT_AVAILABILITY_FORM_FIELDS_NAME,
    [JOB_EDIT_HIRING_BUDGET_BUDGET_LOWER_FORM_FIELD_NAME]: JOB_EDIT_HIRING_BUDGET_FORM_FIELDS_NAME,
    [JOB_EDIT_HIRING_BUDGET_BUDGET_HIGHER_FORM_FIELD_NAME]: JOB_EDIT_HIRING_BUDGET_FORM_FIELDS_NAME,
    [JOB_EDIT_HIRING_BUDGET_SCALE_FORM_FIELD_NAME]: JOB_EDIT_HIRING_BUDGET_FORM_FIELDS_NAME
};

/**
 * This type contains the fields of the job.
 */
type JobFormPageJobPartialFieldName =
    | 'company'
    | 'sectors'
    | 'title'
    | 'is_startup'
    | 'functions'
    | 'location'
    | 'remote_possible'
    | 'experiences'
    | 'worktypes'
    | 'career_statutes'
    | 'start_date'
    | 'hiring_budget'
    | 'tasks'
    | 'skills'
    | 'talents'
    | 'languages'
    | 'benefits';

export type JobFormPageJobPartial = Pick<Job, JobFormPageJobPartialFieldName>;

export const JOB_FORM_ERROR_FIELDS_NOT_EDITABLE = 'fields-not-editable';
