import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { SKELETON_CANDIDATES } from '../constants';

import CandidateList from '../../../components/CandidateList';
import ListEmpty from '../../../components/ListEmpty';

import { Candidate } from '../../../modules/candidates/types';
import { Job } from '../../../modules/jobs/types';

type Props = {
    showPlaceholderList: boolean;
    showProgress: boolean;
    isFetchingCandidates: boolean;
    candidates: Candidate[];
    listName: string;
    jobId: Job['id'];
    onClickExpirationLink: (candidateId: number) => void;
};

const CandidateListPageList = ({
    showPlaceholderList,
    showProgress,
    isFetchingCandidates,
    candidates,
    listName,
    jobId,
    onClickExpirationLink
}: Props) => {
    if (showPlaceholderList) {
        return <CandidateList skeleton={true} items={SKELETON_CANDIDATES} showProgress={showProgress} />;
    }

    if (candidates.length === 0) {
        const emptyMessageId =
            listName === 'withNewMessages'
                ? 'CANDIDATE_LIST_PAGE.EMPTY_TEXT.WITH_NEW_MESSAGES'
                : 'CANDIDATE_LIST_PAGE.EMPTY_TEXT';

        return (
            <ListEmpty>
                <FormattedMessage id={emptyMessageId} />
            </ListEmpty>
        );
    }

    return (
        <CandidateList
            jobId={jobId}
            items={candidates}
            showProgress={showProgress}
            isLoading={isFetchingCandidates}
            onClickExpirationLink={onClickExpirationLink}
        />
    );
};

export default CandidateListPageList;
