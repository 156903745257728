import tutorialVideoStep1WebmEn from '../../assets/videos/tutorial/en/Step.01-EN.webm';
import tutorialVideoStep2WebmEn from '../../assets/videos/tutorial/en/Step.02-EN.webm';
import tutorialVideoStep3WebmEn from '../../assets/videos/tutorial/en/Step.03-EN.webm';
import tutorialVideoStep4WebmEn from '../../assets/videos/tutorial/en/Step.04-EN.webm';
import tutorialVideoStep5WebmEn from '../../assets/videos/tutorial/en/Step.05-EN.webm';
import tutorialVideoStep1WebmDe from '../../assets/videos/tutorial/de/Step.01-DE.webm';
import tutorialVideoStep2WebmDe from '../../assets/videos/tutorial/de/Step.02-DE.webm';
import tutorialVideoStep3WebmDe from '../../assets/videos/tutorial/de/Step.03-DE.webm';
import tutorialVideoStep4WebmDe from '../../assets/videos/tutorial/de/Step.04-DE.webm';
import tutorialVideoStep5WebmDe from '../../assets/videos/tutorial/de/Step.05-DE.webm';

import tutorialVideoStep1Mp4En from '../../assets/videos/tutorial/en/Step.01-EN.mp4';
import tutorialVideoStep2Mp4En from '../../assets/videos/tutorial/en/Step.02-EN.mp4';
import tutorialVideoStep3Mp4En from '../../assets/videos/tutorial/en/Step.03-EN.mp4';
import tutorialVideoStep4Mp4En from '../../assets/videos/tutorial/en/Step.04-EN.mp4';
import tutorialVideoStep5Mp4En from '../../assets/videos/tutorial/en/Step.05-EN.mp4';
import tutorialVideoStep1Mp4De from '../../assets/videos/tutorial/de/Step.01-DE.mp4';
import tutorialVideoStep2Mp4De from '../../assets/videos/tutorial/de/Step.02-DE.mp4';
import tutorialVideoStep3Mp4De from '../../assets/videos/tutorial/de/Step.03-DE.mp4';
import tutorialVideoStep4Mp4De from '../../assets/videos/tutorial/de/Step.04-DE.mp4';
import tutorialVideoStep5Mp4De from '../../assets/videos/tutorial/de/Step.05-DE.mp4';

export const videos = {
    webm: [
        {
            en: tutorialVideoStep1WebmEn,
            de: tutorialVideoStep1WebmDe
        },
        {
            en: tutorialVideoStep2WebmEn,
            de: tutorialVideoStep2WebmDe
        },
        {
            en: tutorialVideoStep3WebmEn,
            de: tutorialVideoStep3WebmDe
        },
        {
            en: tutorialVideoStep4WebmEn,
            de: tutorialVideoStep4WebmDe
        },
        {
            en: tutorialVideoStep5WebmEn,
            de: tutorialVideoStep5WebmDe
        }
    ],
    mp4: [
        {
            en: tutorialVideoStep1Mp4En,
            de: tutorialVideoStep1Mp4De
        },
        {
            en: tutorialVideoStep2Mp4En,
            de: tutorialVideoStep2Mp4De
        },
        {
            en: tutorialVideoStep3Mp4En,
            de: tutorialVideoStep3Mp4De
        },
        {
            en: tutorialVideoStep4Mp4En,
            de: tutorialVideoStep4Mp4De
        },
        {
            en: tutorialVideoStep5Mp4En,
            de: tutorialVideoStep5Mp4De
        }
    ]
};

export function getVideo(step, locale, format) {
    return videos[format][step][locale];
}
