import * as React from 'react';

import LoaderButton from '../../LoaderButtonV2';

export type CheckoutActionBarButtonProps = {
    type?: 'button' | 'submit';
    primary?: boolean;
    className?: string;
    children: React.ReactNode;
    loading?: boolean;

    [index: string]: any;
};

function CheckoutActionBarButton({
    type = 'button',
    primary = false,
    children,
    ...restProps
}: CheckoutActionBarButtonProps) {
    return (
        <LoaderButton
            type={type}
            typeStyle={primary ? 'raised' : 'flat'}
            variationStyle={primary ? 'brand' : 'normal'}
            {...restProps}
        >
            {children}
        </LoaderButton>
    );
}

export default CheckoutActionBarButton;
