import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCheckoutProcessState } from '../modules/entities/selectors';
import { CheckoutProcessState, CheckoutProcessStateId } from '../modules/checkout/types';
import { State } from '../store/reducer';

export function useCheckoutProcessState(checkoutProcessId: CheckoutProcessStateId | null): CheckoutProcessState | null {
    const selector = useCallback(
        (state: State) => {
            if (!checkoutProcessId) {
                return null;
            }

            return getCheckoutProcessState(state, checkoutProcessId);
        },
        [checkoutProcessId]
    );

    return useSelector(selector);
}
