import React from 'react';
import PropTypes from 'prop-types';

import './TagGroup.styles.scss';

const TagGroup = ({ children }) => <div className="TagGroup">{children}</div>;

TagGroup.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};

export default TagGroup;
