import { SagaIterator } from 'redux-saga';
import { all, call, cancel, fork, put, select, spawn, take, takeLatest } from 'redux-saga/effects';

import { getHistory } from '../../history';
import { getJobEditRoute, getJobsRoute } from '../../routes';
import { ERROR_GENERIC_NOT_FOUND } from '../../services/api/constants';
import { hasErrorWithCode, isApiResponseError, isResponseError } from '../../services/api/utils';
import { handleError } from '../../services/errorHandler';
import { getJob } from '../entities/selectors';
import { Action } from '../types';
import {
    ARCHIVE_JOB,
    COPY_JOB,
    CREATE_JOB,
    DELETE_JOB,
    LOAD_JOB,
    LOAD_JOBS,
    UPDATE_JOB,
    fetchJob,
    fetchJobs,
    jobArchive,
    jobCreate,
    jobDelete,
    jobUpdate
} from './actions';
import * as jobsApi from './api';
import { Params, getParamsByListName } from './params';
import { getCleanedCopyableJob } from './utils';

import { ListName } from './reducer';
import { Job } from './types';

export function* createJobSaga(job: jobsApi.CreateJobInput, localCreateId: string | null = null): SagaIterator<Job> {
    try {
        yield put(jobCreate.request(localCreateId));

        const response: Awaited<ReturnType<typeof jobsApi.createJob>> = yield call(jobsApi.createJob, job);

        yield put(jobCreate.success(localCreateId, response));

        return response.data;
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(jobCreate.failure(localCreateId, error.response.data.errors));
        } else {
            yield put(jobCreate.failure(localCreateId));
        }

        throw error;
    }
}

export function* loadJobSaga(id: number): SagaIterator<Job | void> {
    try {
        yield put(fetchJob.request(id));

        const response = yield call(jobsApi.getJobById, id);

        yield put(fetchJob.success(id, response));

        return response.data;
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(fetchJob.failure(id, error.response.data.errors));
        } else {
            throw error;
        }
    }
}

function* copyJobSaga({ payload }): SagaIterator<void> {
    try {
        const job = yield select(getJob, payload.id);
        const cleanedJob = yield call(getCleanedCopyableJob, job);
        const copiedJob = yield call(createJobSaga, cleanedJob);

        const history = getHistory();
        yield call(history.push, getJobEditRoute(copiedJob.id));
    } catch (error) {
        handleError(error);
    }
}

type EnhancedParams = Omit<Params, 'page'> & {
    page: number | 'last';
};

function* loadJobsByListWithRetrySaga(
    listName: ListName,
    params: EnhancedParams,
    countOfRun: number = 0
): SagaIterator<void> {
    try {
        // Fetch jobs with given query params
        const response = yield call(jobsApi.fetchJobs, params);

        let currentPage: number;
        // If the param `page` is last we have the calculate the page
        if (params.page === 'last') {
            const pageSize = params.pageSize;
            const totalCount = response.data.count;

            currentPage = Math.max(1, Math.ceil(totalCount / pageSize));
        } else {
            currentPage = params.page;
        }

        yield put(fetchJobs.success(listName, currentPage, response));
    } catch (error) {
        // If it's not a response error stop here
        if (!isResponseError(error)) {
            yield put(fetchJobs.failure(listName));

            throw error;
        }

        const errors = error.response.data.errors || [];

        // If ran this before stop here and dispatch fail action
        if (countOfRun >= 1) {
            yield put(fetchJobs.failure(listName, errors));

            throw error;
        }

        /* If the the errors array contains an error about an invalid page and the page is not last we want to fetch
         * the last page.
         */
        if (hasErrorWithCode(errors, ERROR_GENERIC_NOT_FOUND) && params.page !== 'last') {
            const paramsForLastPage: EnhancedParams = {
                ...params,
                page: 'last'
            };

            yield call(loadJobsByListWithRetrySaga, listName, paramsForLastPage, countOfRun + 1);
            return;
        }

        yield put(fetchJobs.failure(listName, errors));

        throw error;
    }
}

function* loadJobsByListSaga(listName: ListName, params: Object): SagaIterator<void> {
    try {
        const defaultParams = yield call(getParamsByListName, listName);
        const combinedParams = { ...defaultParams, ...params };

        yield put(fetchJobs.request(listName, combinedParams.page));

        yield call(loadJobsByListWithRetrySaga, listName, combinedParams);
    } catch (error) {
        handleError(error);

        // TODO Show notification so that the user knows that an error occurred
    }
}

function* updateJobSaga(jobId: number, newJob: Partial<Job>): SagaIterator<void> {
    try {
        yield put(jobUpdate.request(jobId));

        const response = yield call(jobsApi.updateJob, jobId, newJob);

        yield put(jobUpdate.success(jobId, response));
    } catch (error) {
        if (isResponseError(error)) {
            yield put(jobUpdate.failure(jobId, error.response.data.errors));
        } else {
            yield put(jobUpdate.failure(jobId));
        }

        throw error;
    }
}

function* deleteJobSaga({ payload }: Action): SagaIterator<void> {
    const { jobId } = payload;

    try {
        yield put(jobDelete.request(jobId));

        yield call(jobsApi.deleteJob, jobId);

        yield put(jobDelete.success(jobId));

        const history = getHistory();
        yield call(history.replace, getJobsRoute());
    } catch (error) {
        if (isResponseError(error)) {
            yield put(jobDelete.failure(jobId, error.response.data.errors));
        } else {
            yield put(jobDelete.failure(jobId));
        }

        throw error;
    }
}

function* archiveJobSaga({ payload }: Action): SagaIterator<void> {
    const { jobId } = payload;
    try {
        yield put(jobArchive.request(jobId));

        const response = yield call(jobsApi.archiveJob, jobId);

        yield put(jobArchive.success(jobId, response));

        yield call(loadJobSaga, jobId);
    } catch (error) {
        if (isResponseError(error)) {
            yield put(jobArchive.failure(jobId, error.response.data.errors));
        } else {
            yield put(jobArchive.failure(jobId));
        }

        throw error;
    }
}

function* watchLoadJobs(): SagaIterator<void> {
    const tasksPerList = {};

    while (true) {
        const action: Action<typeof LOAD_JOBS, { listName: ListName; params: Object }> = yield take(LOAD_JOBS);
        const listName = action.payload.listName;

        if (!!tasksPerList[listName]) {
            yield cancel(tasksPerList[listName]);
        }

        const params = action.payload.params;

        tasksPerList[listName] = yield fork(loadJobsByListSaga, listName, params);
    }
}

function* watchLoadJob(): SagaIterator<void> {
    const tasksPerList = {};

    while (true) {
        const action = yield take(LOAD_JOB);
        const payload = action.payload;
        const taskId = payload.jobId;

        if (!!tasksPerList[taskId]) {
            yield cancel(tasksPerList[taskId]);
        }

        tasksPerList[taskId] = yield fork(loadJobSaga, payload.jobId);
    }
}

function* watchCreateJob(): SagaIterator<void> {
    const tasks = {};

    while (true) {
        const action = yield take(CREATE_JOB);
        const { data, localCreateId } = action.payload;

        if (!!localCreateId && tasks[localCreateId]) {
            yield cancel(tasks[localCreateId]);
        }

        const task = yield fork(createJobSaga, data, localCreateId);

        if (!!localCreateId) {
            tasks[localCreateId] = task;
        }
    }
}

function* watchCopyJob(): SagaIterator<void> {
    while (true) {
        const action = yield take(COPY_JOB);
        yield call(copyJobSaga, action);
    }
}

function* watchUpdateJob(): SagaIterator<void> {
    const tasks = {};

    while (true) {
        const action = yield take(UPDATE_JOB);
        const payload = action.payload;

        const taskId = payload.jobId;

        if (!!tasks[taskId]) {
            yield cancel(tasks[taskId]);
        }

        tasks[taskId] = yield fork(updateJobSaga, payload.jobId, payload.newJob);
    }
}

function* watchDeleteJob(): SagaIterator<void> {
    yield takeLatest(DELETE_JOB, deleteJobSaga);
}

function* watchArchiveJob(): SagaIterator<void> {
    yield takeLatest(ARCHIVE_JOB, archiveJobSaga);
}

function* jobs(): SagaIterator<void> {
    yield all([
        spawn(watchLoadJobs),
        spawn(watchLoadJob),
        spawn(watchCreateJob),
        spawn(watchCopyJob),
        spawn(watchUpdateJob),
        spawn(watchDeleteJob),
        spawn(watchArchiveJob)
    ]);
}

export default jobs;
