import Big from 'big.js';

import { getProductById } from '../repositories/products';

export function calculateUnitPrice(productId: string, quantity: number): Big {
    const product = getProductById(productId);

    if (!product.price) {
        throw new Error(`Product with productId '${productId}' doesn't have prices`);
    }

    if (quantity !== 1) {
        throw new RangeError(`Price for quantity '${quantity}' of product with id '${productId}' doesn't exist`);
    }

    return new Big(product.price);
}

export function calculateSave(productId: string, quantity: number): Big {
    const baseUnitPrice = calculateUnitPrice(productId, 1);
    const quantityPriceOfBasePrice = baseUnitPrice.times(quantity);

    return quantityPriceOfBasePrice.minus(calculateNetto(productId, quantity));
}

export function calculateNetto(productId: string, quantity: number): Big {
    return calculateUnitPrice(productId, quantity).times(quantity);
}

export function calculateVat(productId: string, quantity: number, vatRate: number): Big {
    return calculateNetto(productId, quantity).times(new Big(vatRate));
}

export function calculateSum(productId: string, quantity: number, vatRate: number): Big {
    return calculateNetto(productId, quantity).plus(calculateVat(productId, quantity, vatRate));
}
