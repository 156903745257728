import { AUTHENTICATION } from '../actions';

// Types
import { Action } from '../../types';

export type State = boolean;

// Reducer
function isAuthenticating(state: State = false, action: Action): State {
    switch (action.type) {
        case AUTHENTICATION.START:
            return true;

        case AUTHENTICATION.SUCCESS:
        case AUTHENTICATION.FAILURE:
            return false;

        default:
            return state;
    }
}

export default isAuthenticating;
