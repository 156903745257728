import * as React from 'react';

import classNames from 'classnames';

// Import the most likely used components before the own style because we want to override the style
import '../DialogActionBar';
import '../DialogBody';
import '../DialogIcon';
import '../DialogText';
import '../DialogTitle';

import './style.scss';

type Props = {
    children: React.ReactNode;

    shrinkContent?: boolean;
};

const Dialog = ({ children, shrinkContent }: Props) => {
    return (
        <div
            className={classNames('Dialog', {
                'Dialog--shrink-content': shrinkContent || false
            })}
        >
            {children}
        </div>
    );
};

export default Dialog;
