import * as React from 'react';

import { Recruiter } from '../../../modules/recruiters/types';

type Props = {
    recruiter: Recruiter;
};

function UserMenuDropdownToggle({ recruiter }: Props) {
    const shouldRenderName = !!recruiter.first_name && !!recruiter.last_name;

    const email = recruiter.email;
    const fullName = recruiter.first_name + ' ' + recruiter.last_name;

    const title = `${fullName}\n${email}`.trim();

    return (
        <span className="NavigationActions__dropdown-toggle" title={title}>
            {shouldRenderName && (
                <React.Fragment>
                    <span className="NavigationActions__dropdown-toggle-title">{fullName}</span>
                    <span className="NavigationActions__dropdown-toggle-subtitle">{email}</span>
                </React.Fragment>
            )}

            {!shouldRenderName && <span className="NavigationActions__dropdown-toggle-title">{email}</span>}
        </span>
    );
}

export default UserMenuDropdownToggle;
