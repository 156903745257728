import * as React from 'react';

import './MessageItemGroupSeparator.style.scss';

type Props = {
    size: number;
};

const MessageItemGroupSeparator = ({ size }: Props) => {
    return <div className="MessageItemGroupSeparator" style={{ height: size }} />;
};

export default MessageItemGroupSeparator;
