function serialize(value: any) {
    return JSON.stringify(value);
}

function deserialize(value: string | null | void): any {
    if (!value) {
        return value;
    }

    try {
        return JSON.parse(value);
    } catch (err) {
        return value;
    }
}

function createStorageSupportTest(getStorage: () => Storage): () => boolean {
    let supported: boolean | null = null;

    return function isStorageSupported(): boolean {
        if (typeof supported !== 'boolean') {
            const key = '_' + Math.round(Date.now() * Math.random());

            try {
                getStorage().setItem(key, key);
                getStorage().removeItem(key);

                supported = true;
            } catch (error) {
                supported = false;
            }
        }

        return supported;
    };
}

export const isLocalStorageSupported = createStorageSupportTest(() => window.localStorage);
export const isSessionStorageSupported = createStorageSupportTest(() => window.sessionStorage);

type StorageData = {
    [key: string]: string;
};

export function createMemoryStorage(initialData: StorageData = {}) {
    const data: StorageData = initialData;

    const length = () => {
        return Object.keys(data).length;
    };

    const key = (index: number) => {
        return Object.keys(data)[index] || null;
    };

    const getItem = (key: string) => {
        return data[key] || null;
    };

    const setItem = (key: string, value: unknown) => {
        data[key] = String(value);
    };

    const removeItem = (key: string) => {
        delete data[key];
    };

    const inst = {
        length: 0,
        key,

        getItem,
        setItem,
        removeItem
    };

    Object.defineProperty(inst, 'length', { get: length } as Object);

    return inst;
}

type CreateStorageOptions = {
    isStorageSupported: () => boolean;
    getStorage: () => Storage;
    storageKeyPrefix: string;
};

export function createStorage(options: CreateStorageOptions) {
    const { isStorageSupported = () => false, getStorage, storageKeyPrefix } = options;

    const storage = isStorageSupported() ? getStorage() : createMemoryStorage();

    const get = (key: string) => {
        return deserialize(storage.getItem(`${storageKeyPrefix}${key}`));
    };

    const set = (key: string, value: any) => {
        storage.setItem(`${storageKeyPrefix}${key}`, serialize(value));
    };

    const remove = (key: string) => {
        storage.removeItem(`${storageKeyPrefix}${key}`);
    };

    return {
        get,
        set,
        remove
    };
}
