import React from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormField, Input, Select } from '@truffls/design-system-react';

import useField from '../../../../utils/form/react-final-form/useField';

import { JobHiringBudgetScale } from '../../../../modules/jobs/types';
import { hasError } from '../../../../utils/form/state';
import {
    JOB_EDIT_HIRING_BUDGET_FORM_FIELDS_NAME,
    JOB_EDIT_HIRING_BUDGET_BUDGET_LOWER_FORM_FIELD_NAME,
    JOB_EDIT_HIRING_BUDGET_BUDGET_HIGHER_FORM_FIELD_NAME,
    JOB_EDIT_HIRING_BUDGET_SCALE_FORM_FIELD_NAME,
    JobFormHiringBudgetFormFieldsValue
} from './JobFormHiringBudgetFormFields.contstants';
import {
    parseBudget,
    validateBudgetHigherLowerThanBudgetLower,
    validateBudgetLowerSetIfBudgetHigher
} from './JobFormHiringBudgetFormFields.utils';

import './JobFormHiringBudgetFormFields.scss';
import JobFormFieldAnchor from '../../JobFormFieldAnchor';

export type JobFormHiringBudgetFormFieldsProps = {};

function JobFormHiringBudgetFormFields({}: JobFormHiringBudgetFormFieldsProps) {
    const intl = useIntl();

    const budgetLowerField = useField<JobFormHiringBudgetFormFieldsValue['budget_lower'], HTMLInputElement>(
        JOB_EDIT_HIRING_BUDGET_BUDGET_LOWER_FORM_FIELD_NAME,
        {
            initialValue: null,
            parse: parseBudget,
            validate: validateBudgetLowerSetIfBudgetHigher
        }
    );
    const selectedBudgetLowerValue = parseBudget(budgetLowerField.input.value || '');

    const budgetHigherField = useField<JobFormHiringBudgetFormFieldsValue['budget_higher'], HTMLInputElement>(
        JOB_EDIT_HIRING_BUDGET_BUDGET_HIGHER_FORM_FIELD_NAME,
        {
            initialValue: null,
            parse: parseBudget,
            validate: validateBudgetHigherLowerThanBudgetLower
        }
    );
    const selectedBudgetHigherValue = parseBudget(budgetHigherField.input.value || '');

    const scaleField = useField<JobFormHiringBudgetFormFieldsValue['scale'], HTMLSelectElement>(
        JOB_EDIT_HIRING_BUDGET_SCALE_FORM_FIELD_NAME,
        {
            initialValue: JobHiringBudgetScale.Year
        }
    );

    const hasBudgetLowerError = hasError(selectedBudgetLowerValue, budgetLowerField.meta);
    const hasBudgetHigherError = hasError(selectedBudgetHigherValue, budgetHigherField.meta);

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_HIRING_BUDGET_FORM_FIELDS_NAME} />
            <div
                className={classNames('JobFormHiringBudgetFormFields form-group', {
                    'has-error': hasBudgetLowerError || hasBudgetHigherError
                })}
            >
                <label className="control-label">
                    <FormattedMessage id="JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.LABEL_TEXT" />
                </label>
                <div className="help-block">
                    <FormattedMessage id="JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.HELP_TEXT" />
                </div>
                <div className="JobFormHiringBudgetFormFields__fields">
                    <div className="JobFormHiringBudgetFormFields__fields-budget">
                        <div className="JobFormHiringBudgetFormFields__field-budget-lower">
                            <FormField
                                id="job-form-field-input-hiring_budget-budget_lower"
                                labelText={
                                    <FormattedMessage id="JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_BUDGET_LOWER.LABEL_TEXT" />
                                }
                                iconLeft={<span className="JobFormHiringBudgetFormFields__currency-faux-icon">€</span>}
                                hasError={hasBudgetLowerError}
                                helpText={
                                    hasBudgetLowerError &&
                                    budgetLowerField.meta.error === 'required' && (
                                        <FormattedMessage id="JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_BUDGET_LOWER.ERROR_REQUIRED" />
                                    )
                                }
                            >
                                <Input
                                    {...budgetLowerField.input}
                                    value={budgetLowerField.input.value ?? ''}
                                    inputMode="numeric"
                                    placeholder={intl.formatMessage({
                                        id: 'JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_BUDGET_LOWER.PLACEHOLDER_TEXT'
                                    })}
                                />
                            </FormField>
                        </div>

                        <div className="JobFormHiringBudgetFormFields__fields-budget-seperator">{'–'}</div>

                        <div className="JobFormHiringBudgetFormFields__field-budget-higher">
                            <FormField
                                id="job-form-field-input-hiring_budget-budget_higher"
                                labelText={
                                    <FormattedMessage id="JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_BUDGET_HIGHER.LABEL_TEXT" />
                                }
                                hasError={hasBudgetHigherError}
                                helpText={
                                    hasBudgetHigherError &&
                                    budgetHigherField.meta.error === 'greater-than' && (
                                        <FormattedMessage
                                            id="JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_BUDGET_HIGHER.ERROR_GREATER_THAN"
                                            values={{
                                                budgetLowerLabelText: (
                                                    <FormattedMessage id="JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_BUDGET_LOWER.LABEL_TEXT" />
                                                ),
                                                budgetHigherLabelText: (
                                                    <FormattedMessage id="JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_BUDGET_HIGHER.LABEL_TEXT" />
                                                )
                                            }}
                                        />
                                    )
                                }
                            >
                                <Input
                                    {...budgetHigherField.input}
                                    value={budgetHigherField.input.value ?? ''}
                                    inputMode="numeric"
                                    placeholder={intl.formatMessage({
                                        id: 'JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_BUDGET_HIGHER.PLACEHOLDER_TEXT'
                                    })}
                                />
                            </FormField>
                        </div>
                    </div>

                    <div className="JobFormHiringBudgetFormFields__field-scale">
                        <FormField
                            id="job-form-field-input-hiring_budget-scale"
                            labelText={
                                <FormattedMessage id="JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_SCALE.LABEL_TEXT" />
                            }
                        >
                            <Select {...scaleField.input}>
                                <option value={JobHiringBudgetScale.Year}>
                                    {intl.formatMessage({
                                        id: 'JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_SCALE.OPTION_YEAR.LABEL_TEXT'
                                    })}
                                </option>
                                <option value={JobHiringBudgetScale.Month}>
                                    {intl.formatMessage({
                                        id: 'JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_SCALE.OPTION_MONTH.LABEL_TEXT'
                                    })}
                                </option>
                                <option value={JobHiringBudgetScale.Hour}>
                                    {intl.formatMessage({
                                        id: 'JOB_EDIT.HIRING_BUDGET_FORM_FIELDS.FIELD_SCALE.OPTION_HOUR.LABEL_TEXT'
                                    })}
                                </option>
                            </Select>
                        </FormField>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default JobFormHiringBudgetFormFields;
