import * as React from 'react';
import classNames from 'classnames';
import { Modal } from 'react-overlays';

import { animated } from 'react-spring/renderprops';

import './TutorialMobile.style.scss';

import { STEPS_COUNT } from '../constants';
import { getAnimationState } from '../utils';

import { clamp } from '../../../utils/math';

import StepsDots from '../../../components/StepsDots';

import TutorialMobileVideo from './TutorialMobileVideo/TutorialMobileVideo';
import TutorialMobileBackground from './TutorialMobileBackground/TutorialMobileBackground';
import TutorialMobileContent from './TutorialMobileContent/TutorialMobileContent';

type State = {
    step: number;
    acceptedConditions: boolean;
    touchX: number;
    goingRight: boolean;
};

type Props = {
    open: boolean;
    showGtc: boolean;
    animationProps: any;
    acceptGtc: () => void;
    skipTutorial: () => void;
    completeTutorial: () => void;
};

class TutorialMobile extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            step: 0,
            acceptedConditions: !props.showGtc || false,
            touchX: -1,
            goingRight: true
        };

        this.goToStep = this.goToStep.bind(this);
        this.handleConditionsToggle = this.handleConditionsToggle.bind(this);
        this.recordTouchStart = this.recordTouchStart.bind(this);
        this.recordTouchEnd = this.recordTouchEnd.bind(this);
    }

    goToStep(targetStep: number) {
        if (targetStep > 5) {
            this.props.completeTutorial();
        }

        const step = clamp(targetStep, 1, STEPS_COUNT);

        this.setState((state) => ({ step, goingRight: state.step < step }));
    }

    handleConditionsToggle() {
        this.setState((state) => ({
            acceptedConditions: !state.acceptedConditions
        }));
    }

    recordTouchStart(event: React.TouchEvent<HTMLDivElement>) {
        const touch = event.changedTouches[0];

        this.setState({
            touchX: touch.clientX
        });
    }

    recordTouchEnd(event: React.TouchEvent<HTMLDivElement>) {
        const { step, touchX } = this.state;

        // Swipe not enabled on GTC step
        if (step === 0) {
            return;
        }

        const touch = event.changedTouches[0];

        const widthDifference = touchX - touch.clientX;

        const minWidthValidSwipe = 50;
        if (Math.abs(widthDifference) < minWidthValidSwipe) {
            return;
        }

        if (widthDifference > 0) {
            const targetStep = Math.min(step + 1, 5); // we don't want to swipe to finish tutorial
            this.goToStep(targetStep);
        } else {
            const targetStep = step - 1;
            this.goToStep(targetStep);
        }
    }

    render() {
        const { step, acceptedConditions, goingRight } = this.state;
        const { showGtc, acceptGtc, skipTutorial, open, animationProps } = this.props;

        const animationState = getAnimationState(open, step);

        const { scale, ...animationStyles } = animationProps;
        const interpolatedScale = scale.interpolate((scale) => `scale(${scale})`);

        return (
            <Modal
                show
                className={classNames('TutorialMobile', {
                    'TutorialMobile--step': step > 0
                })}
            >
                <animated.div
                    data-testid="swipe-zone-container"
                    className="TutorialMobile__container"
                    style={{ transform: interpolatedScale, ...animationStyles }}
                    onTouchStart={this.recordTouchStart}
                    onTouchEnd={this.recordTouchEnd}
                >
                    <TutorialMobileContent
                        open={open}
                        step={step}
                        animationState={animationState}
                        goingRight={goingRight}
                        acceptedConditions={acceptedConditions}
                        showGtc={showGtc}
                        acceptGtc={acceptGtc}
                        skipTutorial={skipTutorial}
                        toggleConditions={this.handleConditionsToggle}
                        goToStep={this.goToStep}
                    />

                    {step > 0 && (
                        <StepsDots
                            className="TutorialMobile__steps-dots"
                            stepsCount={STEPS_COUNT}
                            activeStep={step}
                            onStepClick={this.goToStep}
                        />
                    )}

                    <TutorialMobileVideo open={open} step={step} animationState={animationState} />
                    <TutorialMobileBackground open={open} step={step} animationState={animationState} />
                </animated.div>
            </Modal>
        );
    }
}

export default TutorialMobile;
