import { createBrowserCookieStorageFactory } from '../../lib/cookieStorage/browser';
import { deserialize, serialize } from './serializer';
import {
    preferredLanguageStorageCookieName,
    PreferredLanguageStorageData,
    preferredLanguageStorageMaxAge
} from './specification';

export const createBrowserPreferredLanguageStorage = createBrowserCookieStorageFactory<
    PreferredLanguageStorageData,
    PreferredLanguageStorageData
>({
    name: preferredLanguageStorageCookieName,
    maxAge: preferredLanguageStorageMaxAge,
    secure: true,
    deserialize,
    serialize
});
