import { isSignedUp } from '../../modules/recruiters/utils';
import { isUnprocessedOrNotInteresting } from '../../modules/candidates/utils';

import { Conversation } from '../../modules/conversations/types';
import { Recruiter } from '../../modules/recruiters/types';
import { Candidate } from '../../modules/candidates/types';

export function shouldFetchConversation(
    conversationId: Conversation['id'] | null,
    conversationFetchStatus: string,
    recruiter: Recruiter | null,
    candidate: Candidate | null
): boolean {
    if (!recruiter || !candidate || !conversationId) {
        return false;
    }

    return conversationFetchStatus === 'none' && isSignedUp(recruiter) && !isUnprocessedOrNotInteresting(candidate);
}
