import { matchRoute } from '../utils';

export interface RouteRegistrySpecification<
    Params extends {} = {}, // eslint-disable-line @typescript-eslint/no-unused-vars
    Query extends {} = {}, // eslint-disable-line @typescript-eslint/no-unused-vars
    RawQuery extends {} = {} // eslint-disable-line @typescript-eslint/no-unused-vars
> {
    pattern: string;
}

export interface CreateRouteRegistrySpecificationProps<
    Params extends {} = {}, // eslint-disable-line @typescript-eslint/no-unused-vars
    Query extends {} = {}, // eslint-disable-line @typescript-eslint/no-unused-vars
    RawQuery extends {} = {} // eslint-disable-line @typescript-eslint/no-unused-vars
> {
    pattern: string;
}

export function createRouteRegistrySpecification<Params extends {}, Query extends {}, RawQuery extends {}>({
    pattern,
    ...props
}: CreateRouteRegistrySpecificationProps<Params, Query, RawQuery>): RouteRegistrySpecification<
    Params,
    Query,
    RawQuery
> {
    return {
        pattern,
        ...props
    };
}

export type RoutesRegistry = {
    findSpecification: (path: string, skipRoutePatterns?: string[]) => RouteRegistrySpecification | null;
};

export function createRoutesRegistry(specifications: readonly RouteRegistrySpecification[]): RoutesRegistry {
    const findSpecification = (path: string, skipRoutePatterns: string[] = []) => {
        const { pathname } = new URL(path, 'http://localhost');

        const specification = specifications.find(({ pattern }) => {
            if (skipRoutePatterns.includes(pattern)) {
                return false;
            }

            return matchRoute(pattern, pathname, true);
        });

        return specification ?? null;
    };

    return {
        findSpecification
    };
}
