import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape, FormattedMessage } from 'react-intl';

import './CandidateDocuments.style.scss';

import DocumentList from '../../components/DocumentList';
import LoaderButtonV2 from '../../components/LoaderButtonV2';

import { getCandidate, getCandidateFetchStatus } from '../../modules/entities/selectors';
import { loadCandidate } from '../../modules/candidates/actions';
import {
    getCandidateIdListFiles,
    getCandidateIdListFetchStatus,
    hasCandidateMoreFiles
} from '../../modules/files/selectors';
import { getFullName } from '../../modules/candidates/utils';
import { loadFilesByCandidateId } from '../../modules/files/actions';
import { FILE_CATEGORIES } from '../../modules/files/constants';

import { createWindow } from '../../common/window/';
import { requestDownload } from '../../modules/files/api';

import { State as ApplicationState } from '../../store/reducer';
import { Job } from '../../modules/jobs/types';
import { File } from '../../modules/files/types';
import { Candidate } from '../../modules/candidates/types';
import { FetchStatus } from '../../modules/types';

type CandidateDocumentsProps = {
    jobId: Job['id'];
    candidateId: Candidate['id'];
};

type ConnectorProps = CandidateDocumentsProps;

type ConnectedStateProps = {
    candidate: Candidate | null;
    candidateFetchStatus: FetchStatus;
    candidateHasMoreFiles: boolean;

    files: File[];
    filesFetchStatus: FetchStatus;
};

type ConnectedDispatchProps = {
    loadCandidate: typeof loadCandidate;
    loadFilesByCandidateId: typeof loadFilesByCandidateId;
};

type CandidateDocumentsWithConnectProps = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type CandidateDocumentsWithIntlProps = CandidateDocumentsWithConnectProps & {
    intl: IntlShape;
};

type CandidateDocumentsAllProps = CandidateDocumentsWithIntlProps;

class CandidateDocuments extends React.Component<CandidateDocumentsAllProps> {
    constructor(props: CandidateDocumentsAllProps) {
        super(props);

        this.loadFiles = this.loadFiles.bind(this);
        this.handleClickDownload = this.handleClickDownload.bind(this);
    }
    componentDidMount() {
        const { candidateId, candidateFetchStatus, loadCandidate, filesFetchStatus } = this.props;

        // TODO: use FetchStatus constants
        if (candidateFetchStatus !== 'loading') {
            loadCandidate(candidateId);
        }

        // TODO: use FetchStatus constants
        if (filesFetchStatus !== 'loading') {
            this.loadFiles();
        }
    }

    loadFiles() {
        const { candidateId, loadFilesByCandidateId } = this.props;
        loadFilesByCandidateId(candidateId);
    }

    handleClickDownload(file) {
        if (!file) {
            return;
        }

        // XXX: This should be an action which requests the url and store in the state
        if (!!file.id && file.category !== FILE_CATEGORIES.GENERATED_RESUME) {
            requestDownload(file.id).then((response) => {
                window.location = response.data.url;
            });
        } else if (!!file.content_url) {
            // The content url is always a preview of a file and we need a new tab for it.
            const previewWindow = createWindow();
            previewWindow.location = file.content_url;
            previewWindow.focus();
        }
    }

    render() {
        const { candidate, candidateHasMoreFiles, files, filesFetchStatus, intl } = this.props;

        // TODO: Render skeleton if the candidate ot the messages are missing
        if (!candidate) {
            return null;
        }

        const generatedResumeFileTemplate: File = {
            id: -1, // no negative index will be assigned in the database
            category: FILE_CATEGORIES.GENERATED_RESUME,
            name: intl.formatMessage(
                {
                    id: 'CANDIDATE_DOCUMENTS.FILE_GENERATED_RESUME_NAME'
                },

                {
                    candidateName: getFullName(candidate)
                }
            ),

            content_url: candidate.resume_pdf_url,
            highlight: true,
            extension: '.pdf',
            mime_type: 'application/pdf',
            size: 0,
            created: null,
            last_shared: null
        };

        const allFiles = [generatedResumeFileTemplate, ...files];

        return (
            <div>
                <DocumentList files={allFiles} onClickDownload={this.handleClickDownload} />
                {candidateHasMoreFiles && (
                    <LoaderButtonV2
                        className="CandidateDocuments__load-more-button"
                        typeStyle="outlined"
                        variationStyle="normal"
                        onClick={this.loadFiles}
                        loading={filesFetchStatus === 'loading'}
                    >
                        <FormattedMessage id="CANDIDATE_DOCUMENTS.LOAD_MORE_BUTTON.LABEL" />
                    </LoaderButtonV2>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => {
    return {
        candidate: getCandidate(state, props.candidateId),
        candidateFetchStatus: getCandidateFetchStatus(state, props.candidateId),
        candidateHasMoreFiles: hasCandidateMoreFiles(state, props.candidateId),

        files: getCandidateIdListFiles(state, props.candidateId),
        filesFetchStatus: getCandidateIdListFetchStatus(state, props.candidateId)
    };
};

const mapDispatchToProps = {
    loadCandidate,
    loadFilesByCandidateId
};

const CandidateDocumentsWithIntl = injectIntl(CandidateDocuments);

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDocumentsWithIntl);
