import * as React from 'react';
import classNames from 'classnames';
import { RootCloseWrapper } from 'react-overlays';
import { FormattedMessage } from 'react-intl';

import Button from '../../Button';

import { Options, Values } from '../constants';

import './style.scss';

type Props = {
    title: React.ReactNode;

    id: string;
    className?: string;

    open?: boolean;
    onClose?: (event: Event, source: { source: 'rootClose' }) => void;

    options: Options;
    values: Values;
    onChange: (values: Values) => void;

    bsRole?: string;
};

type State = {
    formChanged: boolean;
    formValues: Values;
};

// XXX: Make FilterControlMenu accessible

class FilterControlMenu extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            formChanged: false,
            formValues: []
        };

        this.handleRootClose = this.handleRootClose.bind(this);
        this.handleChangeFilterOption = this.handleChangeFilterOption.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        const prevOpen = prevProps.open;
        const nextOpen = this.props.open;

        // We initialize the form values on open
        if (!prevOpen && nextOpen) {
            this.initializeForm();
        }
    }

    initializeForm() {
        this.setState(() => {
            return {
                formChanged: false,
                formValues: this.props.values
            };
        });
    }

    handleRootClose(event: React.SyntheticEvent) {
        if (!this.props.onClose) {
            return;
        }

        this.props.onClose(event.nativeEvent, { source: 'rootClose' });
    }

    handleChangeFilterOption(event: React.ChangeEvent<HTMLInputElement>) {
        const { value: filter } = event.currentTarget;

        if (filter === 'reset') {
            this.setState(() => {
                return {
                    formChanged: true,
                    formValues: []
                };
            });

            return;
        }

        this.setState((state: State) => {
            const { formValues } = state;
            const index = formValues.indexOf(filter);

            let newFormValues;
            if (!!~index) {
                newFormValues = [...formValues.slice(0, index), ...formValues.slice(index + 1)];
            } else {
                newFormValues = [...formValues, filter];
            }
            return {
                formChanged: true,
                formValues: newFormValues
            };
        });
    }

    handleSubmit(event: React.FormEvent) {
        event.preventDefault();

        this.setState(() => {
            return {
                formChanged: false
            };
        });
        this.props.onChange(this.state.formValues);
    }

    render() {
        const {
            id,

            open,
            title,
            values: currentValues
        } = this.props;
        const { formChanged, formValues } = this.state;

        const values = formChanged ? formValues : currentValues;

        const className = classNames('FilterControlMenu', this.props.className, {
            'FilterControlMenu--open': open
        });

        return (
            <RootCloseWrapper disabled={!open} onRootClose={this.handleRootClose} event="click">
                <div className={className} role="listbox">
                    <div className="FilterControlMenu__triangle" />

                    <form className="FilterControlMenu__menu" onSubmit={this.handleSubmit}>
                        <div className="FilterControlMenu__menu-header" role="heading">
                            {title}
                        </div>

                        <div className="FilterControlMenu__menu-content">
                            <span className="tf-radio">
                                <input
                                    type="radio"
                                    id={`${id}-reset`}
                                    value="reset"
                                    checked={!values.length}
                                    onChange={this.handleChangeFilterOption}
                                />

                                <label
                                    className="tf-radio__label FilterControlMenu__menu-option"
                                    htmlFor={`${id}-reset`}
                                    role="option"
                                >
                                    <span className="tf-radio__faux FilterControlMenu__menu-option-control" />
                                    <span className="tf-radio__label-text FilterControlMenu__menu-option-label">
                                        <FormattedMessage id="FILTER_CONTROL_MENU.RESET" />
                                    </span>
                                </label>
                            </span>

                            {this.renderOptions()}
                        </div>

                        <div className="FilterControlMenu__menu-footer">
                            <Button type="submit" typeStyle="flat" variationStyle="brand">
                                <FormattedMessage id="FILTER_CONTROL_MENU.SUBMIT" />
                            </Button>
                        </div>
                    </form>
                </div>
            </RootCloseWrapper>
        );
    }

    renderOptions() {
        const { id, options, values: currentValues } = this.props;
        const { formChanged, formValues } = this.state;

        const values = formChanged ? formValues : currentValues;

        return options.map((option) => {
            const { value, label } = option;
            const checked = values.includes(value);

            return (
                <span key={value} className="tf-checkbox">
                    <input
                        type="checkbox"
                        id={`${id}-option-${value}`}
                        value={value}
                        checked={checked}
                        onChange={this.handleChangeFilterOption}
                    />

                    <label
                        className="tf-checkbox__label FilterControlMenu__menu-option"
                        htmlFor={`${id}-option-${value}`}
                        role="option"
                    >
                        <span className="tf-checkbox__faux FilterControlMenu__menu-option-control" />
                        <span className="tf-checkbox__label-text FilterControlMenu__menu-option-label">{label}</span>
                    </label>
                </span>
            );
        });
    }
}

export default FilterControlMenu;
