import { combineReducers } from 'redux';
import { CHANGE_PASSWORD_REQUEST, RESET_CHANGE_PASSWORD_REQUEST } from '../actions';
import { STATUS_NONE, STATUS_REQUESTING, STATUS_REQUESTED, STATUS_FAILED } from '../../constants';

// Types
import { Action, RequestStatus } from '../../types';

type RequestStatusState = RequestStatus;
type ErrorsState = unknown[]; // TODO: Specify errors

export type State = {
    requestStatus: RequestStatusState;
    errors: ErrorsState;
};

// Reducers

export const initialRequestStatusState = STATUS_NONE;
export const requestStatusReducer = (
    state: RequestStatusState = initialRequestStatusState,
    action: Action
): RequestStatusState => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST.REQUEST:
            return STATUS_REQUESTING;

        case CHANGE_PASSWORD_REQUEST.SUCCESS:
            return STATUS_REQUESTED;

        case CHANGE_PASSWORD_REQUEST.FAILURE:
            return STATUS_FAILED;

        case RESET_CHANGE_PASSWORD_REQUEST:
            return STATUS_NONE;

        default:
            return state;
    }
};

const initialErrorsState = [];
export function errorsReducer(state: ErrorsState = initialErrorsState, action: Action): ErrorsState {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST.REQUEST:
            return initialErrorsState;

        case CHANGE_PASSWORD_REQUEST.FAILURE:
            return action.payload.errors;

        default:
            return state;
    }
}

const rootReducer = combineReducers<State>({
    requestStatus: requestStatusReducer,
    errors: errorsReducer
});

export default rootReducer;
