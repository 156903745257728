import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormField, Select } from '@truffls/design-system-react';
import debounce from 'just-debounce-it';

import { State as ApplicationState } from '../../store/reducer';

import { Candidate, CandidateAvailabilitySlug } from '../../modules/candidates/types';
import { getCandidate } from '../../modules/entities/selectors';
import { updateCandidate } from '../../modules/candidates/actions';

import DateInput from '../../components/DateInput';

import './CandidateAvailability.scss';

type ConnectorProps = {
    candidateId: number;
};

type ConnectedStateProps = {
    candidate: Candidate | null;
};

type ConnectedDispatchProps = {
    updateCandidate: typeof updateCandidate;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type FormValues = {
    slug: CandidateAvailabilitySlug | '';
    date: string | null;
};

function CandidateAvailability({ candidate, updateCandidate }: Props) {
    const intl = useIntl();

    const { formValues, setFormValues } = useFormValues(candidate);

    const saveCandidate = React.useCallback(
        (formValues: FormValues) => {
            if (!candidate) {
                return;
            }

            // We don't update if the user doesn't set a date
            if (formValues.slug === 'date' && !formValues.date) {
                return null;
            }

            updateCandidate(candidate.id, {
                availability: {
                    slug: formValues.slug,
                    date: formValues.date
                }
            });
        },
        [candidate, updateCandidate]
    );
    const saveCandidateDebounce = React.useMemo(() => {
        return debounce(saveCandidate, 400);
    }, [saveCandidate]);

    const onChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            const { name, value } = event.target;
            const newFormValues = {
                ...formValues,
                [name]: value
            };

            if (name === 'slug') {
                // If the chosen slug isn't date we clear the date field
                if (value !== 'date') {
                    newFormValues.date = null;
                }
            }

            setFormValues(newFormValues);

            saveCandidateDebounce(newFormValues);
        },
        [formValues, setFormValues, saveCandidateDebounce]
    );

    const onSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            saveCandidate(formValues);
        },
        [formValues, saveCandidate]
    );

    return (
        <form onSubmit={onSubmit}>
            <div className="CandidateAvailability__fields">
                <FormField
                    id="candidate-availability-slug"
                    labelText={<FormattedMessage id="CANDIDATE_AVAILABILITY.FIELD_SLUG.LABEL_TEXT" />}
                    className="CandidateAvailability__field-slug"
                >
                    <Select name="slug" value={formValues.slug} onChange={onChange}>
                        <option value=""></option>
                        <option value={CandidateAvailabilitySlug.Date}>
                            {intl.formatMessage({
                                id: 'CANDIDATE_AVAILABILITY.FIELD_SLUG.OPTION_DATE.LABEL_TEXT'
                            })}
                        </option>
                        <option value={CandidateAvailabilitySlug.Now}>
                            {intl.formatMessage({
                                id: 'CANDIDATE_AVAILABILITY.FIELD_SLUG.OPTION_NOW.LABEL_TEXT'
                            })}
                        </option>
                        <option value={CandidateAvailabilitySlug.TwoWeeks}>
                            {intl.formatMessage({
                                id: 'CANDIDATE_AVAILABILITY.FIELD_SLUG.OPTION_TWO_WEEKS.LABEL_TEXT'
                            })}
                        </option>
                        <option value={CandidateAvailabilitySlug.OneMonths}>
                            {intl.formatMessage({
                                id: 'CANDIDATE_AVAILABILITY.FIELD_SLUG.OPTION_ONE_MONTHS.LABEL_TEXT'
                            })}
                        </option>
                        <option value={CandidateAvailabilitySlug.TwoMonths}>
                            {intl.formatMessage({
                                id: 'CANDIDATE_AVAILABILITY.FIELD_SLUG.OPTION_TWO_MONTHS.LABEL_TEXT'
                            })}
                        </option>
                        <option value={CandidateAvailabilitySlug.ThreeMonths}>
                            {intl.formatMessage({
                                id: 'CANDIDATE_AVAILABILITY.FIELD_SLUG.OPTION_THREE_MONTHS.LABEL_TEXT'
                            })}
                        </option>
                        <option value={CandidateAvailabilitySlug.SixMonths}>
                            {intl.formatMessage({
                                id: 'CANDIDATE_AVAILABILITY.FIELD_SLUG.OPTION_SIX_MONTHS.LABEL_TEXT'
                            })}
                        </option>
                    </Select>
                </FormField>
                {formValues.slug === 'date' && (
                    <FormField
                        id="candidate-availability-date"
                        labelText={<FormattedMessage id="CANDIDATE_AVAILABILITY.FIELD_DATE.LABEL_TEXT" />}
                        className="CandidateAvailability__field-date"
                    >
                        <DateInput name="date" value={formValues.date || null} onChange={onChange} />
                    </FormField>
                )}
            </div>
        </form>
    );
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => ({
    candidate: getCandidate(state, props.candidateId)
});

const mapDispatchToProps = {
    updateCandidate
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateAvailability);

const initialFormValues: FormValues = {
    slug: '',
    date: null
};

function useFormValues(candidate: Candidate | null) {
    const [formValues, setFormValues] = React.useState<FormValues | null>(null);

    React.useEffect(() => {
        if (!!formValues) {
            return;
        }

        if (!candidate) {
            return;
        }

        const { availability } = candidate;

        const slug: FormValues['slug'] = !!availability.slug ? availability.slug : initialFormValues.slug;
        const date: FormValues['date'] = slug === 'date' ? availability.date : initialFormValues.date;

        setFormValues({
            slug,
            date
        });
    }, [candidate, setFormValues]);

    return {
        formValues: formValues || initialFormValues,
        setFormValues
    };
}
