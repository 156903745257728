import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { LANGUAGES } from '../../../../modules/languages';
import { sort } from '../../../../utils/array';

import JobFormRequirementsFormFields, { JobFormRequirementsFormFieldsValue } from '../../JobFormRequirementsFormFields';

export type JobFormLanguagesFormFieldsValue = JobFormRequirementsFormFieldsValue;
export type JobFormLanguagesFormFieldsName = 'languages';
export const JOB_EDIT_LANGUAGES_FORM_FIELDS_NAME = 'languages';

export type JobFormLanguagesFormFieldsProps = {};

function JobFormLanguagesFormFields({}: JobFormLanguagesFormFieldsProps) {
    const intl = useIntl();

    const availableLanguages = React.useMemo(() => {
        const availableLanguages = LANGUAGES.map((languageCode) => {
            return intl.formatMessage({
                id: `CONSTANT.LANGUAGE.${languageCode}`
            });
        });

        return sort(availableLanguages);
    }, [intl]);

    return (
        <JobFormRequirementsFormFields
            name={JOB_EDIT_LANGUAGES_FORM_FIELDS_NAME}
            selectableItems={availableLanguages}
            labelText={<FormattedMessage id="JOB_EDIT.LANGUAGES_FORM_FIELDS.LABEL_TEXT" />}
            helpText={<FormattedMessage id="JOB_EDIT.LANGUAGES_FORM_FIELDS.HELP_TEXT" />}
            inputPlaceholderText={intl.formatMessage({ id: 'JOB_EDIT.LANGUAGES_FORM_FIELDS.INPUT_PLACEHOLDER_TEXT' })}
            errorMessages={{
                duplicate: <FormattedMessage id="JOB_EDIT.LANGUAGES_FORM_FIELDS.ERROR_DUPLICATE" />
            }}
        />
    );
}

export default JobFormLanguagesFormFields;
