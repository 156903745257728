import * as React from 'react';
import { NavigationShell } from '../Navigation/NavigationShell';
import Spinner from '../../components/Spinner';
import LoggedOutPageFooter from '../LoggedOutPageFooter';

export type ApplicationShellProps = {
    navigation?: React.ComponentType;
    children: React.ReactNode;
};

export function ApplicationShell({ navigation, children }: ApplicationShellProps) {
    const Navigation = navigation || NavigationShell;

    return (
        <React.Fragment>
            <Navigation />

            <div className="app__content">{children}</div>

            <LoggedOutPageFooter className="app_footer" />

            <div id="initial-loading-overlay" style={{ display: 'none' }}>
                <Spinner fullScreen={true} />
            </div>
        </React.Fragment>
    );
}
