import * as React from 'react';
import classNames from 'classnames';

import successInfoIcon from '../../assets/images/ic_info_success.svg';
import dangerInfoIcon from '../../assets/images/ic_info_danger.svg';

import './style.scss';

type Props = {
    type: 'success' | 'danger';
    title?: React.ReactNode;
    children: React.ReactNode;
};

const InfoBox = (props: Props) => {
    let icon;

    switch (props.type) {
        case 'success':
            icon = successInfoIcon;
            break;
        case 'danger':
            icon = dangerInfoIcon;
            break;
    }

    return (
        <div className="InfoBox">
            <img className="InfoBox__icon" src={icon} role="presentation" />

            {!!props.title && (
                <div className={classNames('InfoBox__title', `InfoBox__title--${props.type}`)}>{props.title}</div>
            )}

            <div className="InfoBox__content">{props.children}</div>
        </div>
    );
};

export default InfoBox;
