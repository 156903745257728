import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { links } from '../../constants';
import { AgreementContract } from '../../modules/agreements/types';

import DialogV2 from '../DialogV2';
import DialogV2Actions from '../DialogV2/DialogActions';
import DialogV2Content from '../DialogV2/DialogContent';
import DialogV2Title from '../DialogV2/DialogTitle';
import LoaderButtonV2 from '../LoaderButtonV2';

import shieldImage from '../../assets/images/shield.svg';

import './style.scss';

// Types

type Props = {
    intl: IntlShape;

    open: boolean;
    accepting: boolean;
    initialValues: {
        gtc: boolean;
    };

    onAcceptContracts: (contracts: AgreementContract[]) => void;
};

type State = {
    values: {
        [key in AgreementContract]: boolean;
    };
};

class LegalAgreementDialog extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            values: props.initialValues
        };

        this.handleChangeField = this.handleChangeField.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.open && !!this.props.open) {
            this.setState({
                values: this.props.initialValues
            });
        }
    }

    handleChangeField(event: React.ChangeEvent<HTMLInputElement>) {
        const field = event.target;
        const name = field.name;
        const value = field.checked;

        const nextValues = {
            ...this.state.values,
            [name]: value
        };

        this.setState({
            values: nextValues
        });
    }

    handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const { onAcceptContracts } = this.props;
        const { values } = this.state;

        const contracts = Object.keys(values).filter((contract) => values[contract]);

        onAcceptContracts(contracts as AgreementContract[]);
    }

    isDisabled() {
        const { values } = this.state;

        return !values.gtc;
    }

    render() {
        const title = <FormattedMessage id="LEGAL_AGREEMENT_DIALOG.TITLE" />;
        const acceptLabel = <FormattedMessage id="LEGAL_AGREEMENT_DIALOG.FORM.ACTION_ACCEPT" />;

        const content = this.renderContent();

        return this.renderDesktopFrame(content, title, acceptLabel);
    }

    renderContent() {
        const { intl } = this.props;
        const { values } = this.state;

        const gtcLink = (
            <a href={links.gtc[intl.locale]} rel="noopener noreferrer" target="_blank">
                <FormattedMessage id="LEGAL_AGREEMENT_DIALOG.LINK_GTC" />
            </a>
        );

        const privacyLink = (
            <a href={links.privacy[intl.locale]} rel="noopener noreferrer" target="_blank">
                <FormattedMessage id="LEGAL_AGREEMENT_DIALOG.LINK_PP" />
            </a>
        );

        const gdprLink = (
            <a href={links.gdpr[intl.locale]} rel="noopener noreferrer" target="_blank">
                <FormattedMessage id="LEGAL_AGREEMENT_DIALOG.LINK_GDPR" />
            </a>
        );

        const labelGtcLink = (
            <a href={links.gtc[intl.locale]} rel="noopener noreferrer" target="_blank">
                <FormattedMessage id="LEGAL_AGREEMENT_DIALOG.FORM.LABEL_LINK_GTC" />
            </a>
        );

        return (
            <div>
                <p className="LegalAgreementDialog__description">
                    <FormattedMessage
                        id="LEGAL_AGREEMENT_DIALOG.DESCRIPTION"
                        values={{
                            gtcLink,
                            privacyLink,
                            gdprLink
                        }}
                    />
                </p>

                <div className="tf-form-row">
                    <div className="tf-form-element">
                        <div className="tf-form-element__control">
                            <span className="tf-checkbox">
                                <input
                                    type="checkbox"
                                    name="gtc"
                                    required
                                    checked={values.gtc}
                                    onChange={this.handleChangeField}
                                    id="field-gtc"
                                />

                                <label className="tf-checkbox__label" htmlFor="field-gtc">
                                    <span className="tf-checkbox__faux"></span>
                                    <span className="tf-checkbox__label-text">
                                        <FormattedMessage
                                            id="LEGAL_AGREEMENT_DIALOG.FORM.LABEL_GTC"
                                            values={{
                                                gtcLink: labelGtcLink
                                            }}
                                        />
                                    </span>
                                </label>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDesktopFrame(content, title, acceptLabel) {
        const { open, accepting } = this.props;

        return (
            <DialogV2 open={open}>
                <DialogV2Title>{title}</DialogV2Title>
                <form onSubmit={this.handleSubmit} className="LegalAgreementDialog__form">
                    <div className="LegalAgreementDialog__body">
                        <DialogV2Content className="LegalAgreementDialog__content">{content}</DialogV2Content>
                        <DialogV2Content className="LegalAgreementDialog__image-frame">
                            <img src={shieldImage} role="presentation" className="LegalAgreementDialog__image" />
                        </DialogV2Content>
                    </div>
                    <DialogV2Actions>
                        <LoaderButtonV2
                            type="submit"
                            typeStyle="raised"
                            variationStyle="brand"
                            loading={accepting}
                            disabled={this.isDisabled()}
                        >
                            {acceptLabel}
                        </LoaderButtonV2>
                    </DialogV2Actions>
                </form>
            </DialogV2>
        );
    }
}

export default injectIntl(LegalAgreementDialog);
