import * as React from 'react';
import classNames from 'classnames';

type Props = {
    id: string | number;
    className?: string;
    required?: boolean;
    checked: boolean;
    onSpace?: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    children: React.ReactNode;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Checkbox = ({ id, className, required, checked, onChange, onSpace, children: label, ...rest }: Props) => (
    <span className={classNames('tf-checkbox', className)}>
        <input
            {...rest}
            type="checkbox"
            id={String(id)}
            required={required}
            checked={checked}
            onChange={onChange}
            onKeyUp={(event) => {
                if (event.key === ' ' && !!onSpace) {
                    onSpace();
                }
            }}
        />

        <label htmlFor={String(id)} className="tf-checkbox__label">
            <span className="tf-checkbox__faux" />
            <span className="tf-checkbox__label-text">{label}</span>
        </label>
    </span>
);

Checkbox.defaultProps = {
    required: false,
    onChange: () => undefined
};

export default Checkbox;
