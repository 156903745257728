import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Link } from 'react-router-dom';

import './OverlayUserMenu.style.scss';

import scrolled, { ScrolledProps } from '../Scrolled';

import BottomActionBar from '../BottomActionBar';
import Button from '../Button';
import MobilePageContainer from '../MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../NavigationBar';
import OverlayUserMenuLogout from './OverlayUserMenuLogout';

import { Recruiter } from '../../modules/recruiters/types';
import { isSignedUp } from '../../modules/recruiters/utils';

import { legalLinks, links } from '../../constants';

type Props = ScrolledProps & {
    open: boolean;
    onClose: () => void;

    recruiter: Recruiter | null;

    onClickLogout: () => void;
    onClickTutorial: () => void;

    intl: IntlShape;
};

class OverlayUserMenu extends React.Component<Props> {
    renderLegalLinks() {
        const { onClose, intl } = this.props;

        return Object.keys(legalLinks).map((keyLink, index) => {
            return (
                <a
                    key={index}
                    href={links[keyLink][intl.locale]}
                    className="OverlayUserMenu__nav-button"
                    onClick={onClose}
                    target="_blank"
                    rel="noopener"
                >
                    <FormattedMessage id={`NAVIGATION.ACTIONS.USER_MENU.LEGAL.${keyLink}`} />
                </a>
            );
        });
    }

    render() {
        const {
            scrolled,
            onScroll,

            open,
            onClose,

            recruiter,
            onClickLogout,
            onClickTutorial
        } = this.props;

        return (
            <MobilePageContainer show={open} transitionName="scale">
                <NavigationBar
                    noShadow={!scrolled}
                    centerElement={
                        <NavigationBarText>
                            <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.TITLE" />
                        </NavigationBarText>
                    }
                    rightElement={<NavigationBarCloseAction onClick={onClose} />}
                />

                <div className="OverlayUserMenu__nav" onScroll={onScroll}>
                    {!!recruiter && isSignedUp(recruiter) && (
                        <Link to="/settings/profile" className="OverlayUserMenu__nav-button" onClick={onClose}>
                            <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.SETTINGS" />
                        </Link>
                    )}

                    <button
                        className="OverlayUserMenu__nav-button"
                        onClick={() => {
                            onClickTutorial();
                            onClose();
                        }}
                    >
                        <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.TUTORIAL" />
                    </button>
                    <div className="OverlayUserMenu__nav-seperator" />
                    <Link to="/contact" className="OverlayUserMenu__nav-button" onClick={onClose}>
                        <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.CONTACT" />
                    </Link>
                    {!!recruiter && isSignedUp(recruiter) && (
                        <Link to="/prices" className="OverlayUserMenu__nav-button" onClick={onClose}>
                            <FormattedMessage id="NAVIGATION.ACTIONS.USER_MENU.PRICES" />
                        </Link>
                    )}

                    {this.renderLegalLinks()}
                    <div className="OverlayUserMenu__nav-seperator" />
                    <OverlayUserMenuLogout recruiter={recruiter} onClick={onClickLogout} />
                </div>

                <BottomActionBar>
                    <Button
                        typeStyle="raised"
                        variationStyle="brand"
                        component={Link}
                        to="/job-create"
                        onClick={onClose}
                    >
                        <FormattedMessage id="NAVIGATION.ACTIONS.PRIMARY.CREATE_JOB_AD" />
                    </Button>
                </BottomActionBar>
            </MobilePageContainer>
        );
    }
}

export default injectIntl(scrolled(OverlayUserMenu));
