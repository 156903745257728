import { Badge } from '@truffls/design-system-react';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { BREAKPOINT_MD } from '../../utils/viewport';
import { getCandidateRoute, getCandidateConversationRoute, getCandidateDocumentsRoute } from '../../routes';

import './CandidateDetailsPageTabs.scss';
import useBreakpointMatch from '../../hooks/useBreakpointMatch';

export type CandidateDetailsPageTabsProps = {
    jobId: number;
    candidateId: number;
    unreadMessageCount?: number;
};

const CandidateDetailsPageTabs = ({ jobId, candidateId, unreadMessageCount = 0 }: CandidateDetailsPageTabsProps) => {
    const desktopMatch = useBreakpointMatch(BREAKPOINT_MD);
    const isDesktop = !!desktopMatch;

    return (
        <div className="CandidateDetailsPageTabs">
            <NavLink
                replace={isDesktop}
                exact
                to={getCandidateRoute(jobId, candidateId)}
                className="CandidateDetailsPageTabs__tab CandidateDetailsPageTabs__tab--profile"
                activeClassName="CandidateDetailsPageTabs__tab--active"
            >
                <FormattedMessage id="CANDIDATE_DETAILS_PAGE_TABS.PROFILE" />
            </NavLink>
            <NavLink
                replace={isDesktop}
                exact
                to={getCandidateConversationRoute(jobId, candidateId)}
                className="CandidateDetailsPageTabs__tab"
                activeClassName="CandidateDetailsPageTabs__tab--active"
            >
                <FormattedMessage id="CANDIDATE_DETAILS_PAGE_TABS.CONVERSATION" />
                {unreadMessageCount > 0 ? (
                    <React.Fragment>
                        {' '}
                        <Badge danger>{unreadMessageCount}</Badge>
                    </React.Fragment>
                ) : null}
            </NavLink>
            <NavLink
                replace={isDesktop}
                exact
                to={getCandidateDocumentsRoute(jobId, candidateId)}
                className="CandidateDetailsPageTabs__tab"
                activeClassName="CandidateDetailsPageTabs__tab--active"
            >
                <FormattedMessage id="CANDIDATE_DETAILS_PAGE_TABS.DOCUMENTS" />
            </NavLink>
        </div>
    );
};

export default CandidateDetailsPageTabs;
