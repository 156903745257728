import { createMapReducer } from '../../../utils/redux';
import { Action } from '../../types';

import { CANDIDATE_FORWARD } from '../actions';

export type ForwardStatus = 'none' | 'sending' | 'sent' | 'failed';

type ForwardStatusState = ForwardStatus;

const initialForwardStatusState: ForwardStatus = 'none';

function forwardStatusReducer(
    state: ForwardStatusState = initialForwardStatusState,
    action: Action
): ForwardStatusState {
    switch (action.type) {
        case CANDIDATE_FORWARD.REQUEST:
            return 'sending';

        case CANDIDATE_FORWARD.SUCCESS:
            return 'sent';

        case CANDIDATE_FORWARD.FAILURE:
            return 'failed';

        default:
            return state;
    }
}

export type State = {
    [id: string]: ForwardStatusState;
};

function getCandidateIdFromAction(action: Action) {
    return (!!action.payload && action.payload.candidateId) || null;
}

const forwardsReducer = createMapReducer(forwardStatusReducer, getCandidateIdFromAction);

export default forwardsReducer;
