import { IconLocationPinBold } from '@truffls/icons-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Job } from '../../../modules/jobs/types';
import JobDetailsMetaItem from '../JobDetailsMetaItem';
import JobDetailsMetaItemLabeledValue from '../JobDetailsMetaItemLabeledValue';

export type JobDetailsMetaLocationProps = {
    job: Job;
};

function JobDetailsMetaLocation({ job }: JobDetailsMetaLocationProps) {
    return (
        <JobDetailsMetaItem icon={<IconLocationPinBold />}>
            <JobDetailsMetaItemLabeledValue labelId="JOB_DETAILS_META.LOCATION_LABEL">
                {job.location.locality || <FormattedMessage id="JOB_DETAILS_META.LOCATION_FALLBACK_VALUE" />}
                {job.remote_possible && (
                    <React.Fragment>
                        {' / '}
                        <FormattedMessage id="JOB_DETAILS_META.LOCATION_REMOTE_LABEL_TEXT" />
                    </React.Fragment>
                )}
            </JobDetailsMetaItemLabeledValue>
        </JobDetailsMetaItem>
    );
}

export default JobDetailsMetaLocation;
