import * as React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import classNames from 'classnames';

import { DragStrategy } from './strategies';
import { DragObject } from './types';

import './Draggable.style.scss';

type Props = {
    id: string;
    type: string;
    dragStrategy: DragStrategy;
    placeholder: React.ReactNode;
    children: React.ReactElement;
    getIndexByItemId: (id: DragObject['id']) => number;
    onDragMove: (fromIndex: number, toIndex: number) => void;
    onDragStart?: () => void;
    onDragEnd?: () => void;
    disable?: boolean;
    className?: string;
};

function Draggable({
    id,
    type,
    dragStrategy,
    placeholder,
    children,
    getIndexByItemId,
    onDragMove,
    onDragStart = () => {},
    onDragEnd = () => {},
    disable = false,
    className
}: Props) {
    const ref = React.useRef(null);

    const [, drop] = useDrop({
        accept: type,
        hover: dragStrategy(ref, id, getIndexByItemId, onDragMove)
    });

    const child = React.Children.only(children);

    const [{ isDragging }, drag] = useDrag(() => {
        return {
            type,
            item: (): DragObject => {
                onDragStart();

                return {
                    id,
                    ref,
                    content: child
                };
            },

            end: () => {
                onDragEnd();
            },

            canDrag: !disable,
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            })
        };
    }, [onDragStart, onDragEnd]);

    drag(drop(ref));

    const computedClassName = classNames('Draggable', {
        'Draggable--dragging': isDragging
    });

    const computedContentClassName = classNames('Draggable__content', className);
    const computedOverlayClassName = classNames('Draggable__overlay', className);

    // const childWithRef = React.cloneElement(child, { ref });

    return (
        <div className={computedClassName}>
            <div className={computedContentClassName} ref={ref}>
                {child}
            </div>
            <div className={computedOverlayClassName} aria-hidden="true">
                {placeholder}
            </div>
        </div>
    );
}

export default Draggable;
