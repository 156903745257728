import * as React from 'react';

import { findOccurrences } from '../../../utils/string';

export function highlight(text: string, search: string): React.ReactNode[] {
    const lowerCaseText = text.toLowerCase();
    const lowerCaseSearch = search.toLowerCase();

    const occurrences = !lowerCaseSearch ? [] : findOccurrences(lowerCaseText, lowerCaseSearch);

    const relativeOccurrences = occurrences.map(({ startIndex, endIndex }, index, occurrences) => {
        // We get the previous occurrence to calculate the offset for the relative indexes
        const { endIndex: previousEndIndex } = occurrences[index - 1] || {
            startIndex: 0,
            endIndex: 0
        };

        const relativeStartIndex = startIndex - previousEndIndex;
        const relativeEndIndex = endIndex - previousEndIndex;

        return {
            relativeStartIndex,
            relativeEndIndex
        };
    });

    const annotate = (text: string, match: boolean) => ({ text, match });

    // In `textParts` the last item of the array is always the unprocessed text which is used in the current iteration
    const annotatedTextParts = relativeOccurrences.reduce(
        (textParts, { relativeStartIndex, relativeEndIndex }) => {
            const lastTextPartIndex = textParts.length - 1;

            const completeTextAnnotations = textParts.slice(0, -1);
            const { text: unprocessedText } = textParts[lastTextPartIndex];

            const newProcessedText = unprocessedText.substring(0, relativeStartIndex);
            const matchedText = unprocessedText.substring(relativeStartIndex, relativeEndIndex);
            const remainingUnprocessedText = unprocessedText.substring(relativeEndIndex);

            return [
                ...completeTextAnnotations,
                annotate(newProcessedText, false),
                annotate(matchedText, true),
                annotate(remainingUnprocessedText, false)
            ];
        },
        [annotate(text, false)]
    );

    const cleanAnnotatedTextParts = annotatedTextParts.filter(({ text }) => {
        return !!text;
    });

    return cleanAnnotatedTextParts.map(({ match, text }, index) => {
        const key = `${text}-${index}`;

        if (!!match) {
            return <strong key={key}>{text}</strong>;
        }

        return <React.Fragment key={key}>{text}</React.Fragment>;
    });
}
