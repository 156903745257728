import React from 'react';
import CSSTransition, { CSSTransitionProps, CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import './style.scss';

const transitionClassNames: CSSTransitionClassNames = {
    appear: 'FadeInTransition--enter',
    appearActive: 'FadeInTransition--enter-active',
    exit: 'FadeInTransition--exit',
    exitActive: 'FadeInTransition--exit-active'
};

export type FadeInTransitionProps = Omit<CSSTransitionProps, 'classNames' | 'timeout'>;

function FadeInTransition(props: FadeInTransitionProps) {
    return <CSSTransition {...props} timeout={300} classNames={transitionClassNames} />;
}

export default FadeInTransition;
