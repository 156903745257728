import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormField, Input, FormActions } from '@truffls/design-system-react';

import LoaderButtonV2 from '../../../../components/LoaderButtonV2';

export type RequestTokenRequestFormValues = {
    email: string;
};

export type RequestTokenRequestFormProps = {
    initialValues?: Partial<RequestTokenRequestFormValues>;
    onSubmit: (values: RequestTokenRequestFormValues) => void;
    submitting: boolean;
    submitFailed: boolean;
};

export function RequestTokenRequestForm({ initialValues, onSubmit, submitting }: RequestTokenRequestFormProps) {
    const [formValues, setFormValues] = React.useState<RequestTokenRequestFormValues>({
        email: '',
        ...initialValues
    });

    const handleChange = (event) => {
        const { name, value } = event.target;

        setFormValues((state) => ({
            ...state,
            [name]: value
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // TODO: Validate

        onSubmit(formValues);
    };

    return (
        <form onSubmit={handleSubmit} data-testid="request-token-request-form">
            <div>
                <p className="text-center">
                    <FormattedMessage id="REQUEST_TOKEN_REQUEST_FORM.DESCRIPTION_1" />
                </p>
                <p className="text-center">
                    <FormattedMessage id="REQUEST_TOKEN_REQUEST_FORM.DESCRIPTION_2" />
                </p>
                <hr />
            </div>

            <FormField
                id="request-token-request-form-email"
                labelText={<FormattedMessage id="REQUEST_TOKEN_REQUEST_FORM.FIELD_EMAIL.LABEL_TEXT" />}
            >
                <Input
                    type="email"
                    name="email"
                    required
                    autoFocus
                    value={formValues.email}
                    onChange={handleChange}
                    data-testid="request-token-request-form-email"
                />
            </FormField>
            <FormActions className="text-center">
                <LoaderButtonV2
                    loading={submitting}
                    type="submit"
                    typeStyle="raised"
                    variationStyle="brand"
                    data-testid="request-token-request-form-submit-button"
                >
                    <FormattedMessage id="REQUEST_TOKEN_REQUEST_FORM.BUTTON_SUBMIT.LABEL_TEXT" />
                </LoaderButtonV2>
            </FormActions>
        </form>
    );
}
