import classNames from 'classnames';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckoutProcessStep, CHECKOUT_PROCESS_STEPS } from '../../routes';
import './style.scss';

export type Props = {
    currentStep: CheckoutProcessStep;
};

function CheckoutProgressBar(props: Props) {
    const currentStepIndex = CHECKOUT_PROCESS_STEPS.indexOf(props.currentStep);

    const steps = CHECKOUT_PROCESS_STEPS.map((stepName) => {
        const active = currentStepIndex === CHECKOUT_PROCESS_STEPS.indexOf(stepName);
        const complete = currentStepIndex > CHECKOUT_PROCESS_STEPS.indexOf(stepName);

        const className = classNames('col-xs-4 CheckoutProgressBar__step', {
            'CheckoutProgressBar__step--complete': complete,
            'CheckoutProgressBar__step--active': active
        });

        return (
            <div key={stepName} className={className}>
                <div className="CheckoutProgressBar__step-label text-center">
                    <FormattedMessage id={`CHECKOUT_PROGRESS_BAR.STEP.${stepName}.LABEL`} />
                </div>
                <div className="CheckoutProgressBar__step-progress progress">
                    <div className="CheckoutProgressBar__step-progress-bar progress-bar"></div>
                </div>
                <div className="CheckoutProgressBar__step-dot"></div>
            </div>
        );
    });

    return <div className="CheckoutProgressBar row">{steps}</div>;
}

export default CheckoutProgressBar;
