import { all, spawn, take, cancel, fork, put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { saveCheckoutProcessState } from '../../modules/checkout/actions';
import { redirectToCheckout } from '../../modules/checkout/saga';
import { submitFormSaga } from '../CheckoutAddressForm/saga';

import { CONTINUE_TO_NEXT_STEP } from './actions';

import { FormValues } from '../CheckoutAddressForm/constants';
import { CheckoutProcessState } from '../../modules/checkout/types';
import { handleError } from '../../services/errorHandler';

export function* continueToNextStepSaga(
    checkoutProcessState: CheckoutProcessState,
    formValues: FormValues
): SagaIterator {
    try {
        yield put(saveCheckoutProcessState(checkoutProcessState));

        const successful = yield call(submitFormSaga, formValues, checkoutProcessState);

        if (!successful) {
            return;
        }

        yield call(redirectToCheckout, checkoutProcessState.id, false, 'finish');
    } catch (error) {
        handleError(error);
    }
}

export function* watchContinueToNextStepSaga(): SagaIterator {
    let task = null;

    while (true) {
        const action = yield take(CONTINUE_TO_NEXT_STEP);

        const checkoutProcessState: CheckoutProcessState = action.payload.checkoutProcessState;
        const formValues: FormValues = action.payload.formValues;

        if (!!task) {
            yield cancel(task);
        }

        task = yield fork(continueToNextStepSaga, checkoutProcessState, formValues);
    }
}

export default function* rootSage(): SagaIterator {
    yield all([spawn(watchContinueToNextStepSaga)]);
}
