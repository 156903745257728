import * as React from 'react';

import './style.scss';

type Props = {
    children: React.ReactNode;
};

const DialogPageContainer = ({ children }: Props) => {
    return (
        <div className="DialogPageContainer">
            <div className="DialogPageContainer__dialog">{children}</div>
        </div>
    );
};

export default DialogPageContainer;
