import { IconTagsBold } from '@truffls/icons-react';
import React from 'react';
import { Job } from '../../../modules/jobs/types';
import { isActive, isArchived } from '../../../modules/jobs/utils';
import JobType from '../../JobType';
import JobDetailsMetaItem from '../JobDetailsMetaItem';
import JobDetailsMetaItemLabeledValue from '../JobDetailsMetaItemLabeledValue';

export type JobDetailsMetaAdTypeProps = {
    job: Job;
};

function JobDetailsMetaAdType({ job }: JobDetailsMetaAdTypeProps) {
    // If the job has no type or is not active we want to render nothing
    if (!job.product || (!isActive(job) && !isArchived(job))) {
        return null;
    }

    return (
        <JobDetailsMetaItem icon={<IconTagsBold />}>
            <JobDetailsMetaItemLabeledValue labelId="JOB_DETAILS_META.TYPE_LABEL">
                <JobType job={job} />
            </JobDetailsMetaItemLabeledValue>
        </JobDetailsMetaItem>
    );
}

export default JobDetailsMetaAdType;
