import * as React from 'react';
import FlipMove from 'react-flip-move';

import ListLoadingIndicator from '../ListLoadingIndicator';
import CandidateListItem from '../CandidateListItem';

import './style.scss';

// Types
import { Candidate } from '../../modules/candidates/types';
type Props =
    | {
          skeleton: true;
          showProgress?: boolean;
          isLoading?: boolean;
          jobId?: number;
          items: any[];
          onClickExpirationLink?: (candidateId: number) => void;
      }
    | {
          skeleton?: false | undefined;
          showProgress?: boolean;
          isLoading?: boolean;
          jobId: number;
          items: Candidate[];
          onClickExpirationLink?: (candidateId: number) => void;
      };

class CandidateList extends React.Component<Props> {
    render() {
        const { isLoading } = this.props;

        let loadingIndicator: React.ReactNode = null;
        if (isLoading) {
            loadingIndicator = <ListLoadingIndicator />;
        }

        return (
            <div className="CandidateList">
                <FlipMove
                    typeName="ul"
                    className="CandidateList__list"
                    duration={500}
                    easing="ease-out"
                    appearAnimation="fade"
                    enterAnimation="fade"
                    leaveAnimation="fade"
                >
                    {this.renderItems()}
                </FlipMove>

                {loadingIndicator}
            </div>
        );
    }

    renderItems() {
        const { items, skeleton, jobId, showProgress, onClickExpirationLink } = this.props;

        return (items as Candidate[]).map(
            (candidate: Candidate, index): React.ReactNode => {
                const keyIdentifier = skeleton ? `skeleton-${index}` : candidate.id;
                const key = `candidate-${keyIdentifier}`;

                return (
                    <li key={key} className="CandidateList__item">
                        <CandidateListItem
                            skeleton={skeleton}
                            jobId={jobId}
                            candidate={candidate}
                            showProgress={showProgress}
                            onClickExpirationLink={onClickExpirationLink}
                        />
                    </li>
                );
            }
        );
    }
}

export default CandidateList;
