import { SagaIterator } from 'redux-saga';
import { all, take, call, cancel, put } from 'redux-saga/effects';

import { Action, ListResponse, ListResponseData } from '../types';

import { fetchJobs } from '../jobs/api';
import { Job } from '../jobs/types';

import { fetchCandidates } from '../candidates/api';
import { Candidate } from '../candidates/types';

import { SearchResult } from './types';
import { SEARCH_RESULT_TYPE_JOB, SEARCH_RESULT_TYPE_CANDIDATE } from './constants';
import { FETCH_SEARCH_RESULTS, searchResultsFetch } from './actions';
import { isResponseError } from '../../services/api/utils';

function* watchFetchSearchResultsSaga(): SagaIterator<void> {
    const tasks = {};

    while (true) {
        const { payload }: Action = yield take(FETCH_SEARCH_RESULTS);

        const taskId = `${payload.type}_${payload.query}`;

        const previousTask = tasks[taskId];
        if (!!previousTask) {
            yield cancel(previousTask);
        }

        yield call(fetchSearchResultsSaga, payload.type, payload.query, payload.page, payload.append);
    }
}

function* fetchSearchResultsSaga(
    type: 'job' | 'candidate',
    query: string,
    page: number = 1,
    append: boolean
): SagaIterator<void> {
    try {
        yield put(searchResultsFetch.request(type, query, page));

        let response: ListResponse<any> | null;
        if (type === SEARCH_RESULT_TYPE_JOB) {
            response = yield call(fetchJobs, {
                search: query,
                page,
                pageSize: 5
            });
        } else if (type === SEARCH_RESULT_TYPE_CANDIDATE) {
            response = yield call(fetchCandidates, {
                search: query,
                page,
                pageSize: 5
            });
        } else {
            throw new Error(`Unkown type '${type}' for search request`);
        }

        if (!response) {
            throw new Error();
        }

        let normalizedResponseResults: SearchResult[] = [];
        if (type === 'candidate') {
            const candidateListResponse = response as ListResponse<Candidate>;

            normalizedResponseResults = candidateListResponse.data.results.map(
                (candidate: Candidate): SearchResult => {
                    return {
                        id: `candidate_${candidate.id}`,
                        type: 'candidate',
                        data: candidate
                    };
                }
            );
        } else if (type === 'job') {
            const jobListResponse = response as ListResponse<Job>;

            normalizedResponseResults = jobListResponse.data.results.map(
                (job: Job): SearchResult => {
                    return {
                        id: `job_${job.id}`,
                        type: 'job',
                        data: job
                    };
                }
            );
        }

        const listResponseData: ListResponseData<SearchResult> = {
            count: response.data.count,
            previous: response.data.previous,
            next: response.data.next,
            results: normalizedResponseResults
        };

        yield put(searchResultsFetch.success(type, query, page, listResponseData, append));
    } catch (error) {
        console.log(error);

        if (isResponseError(error)) {
            yield put(searchResultsFetch.failure(type, query, page, error.response.data.errors || [], append));
        } else {
            yield put(searchResultsFetch.failure(type, query, page, [], append));
        }
    }
}

function* searchSaga(): SagaIterator<void> {
    yield all([call(watchFetchSearchResultsSaga)]);
}

export default searchSaga;
