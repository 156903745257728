import pathToRegexp, { compile } from 'path-to-regexp';
import { ParsedUrlQuery } from 'querystring';

export function createRouteString(pathPattern: string, pathParams: {} = {}, rawQuery: { [key: string]: string } = {}) {
    const pathname = compile(pathPattern)(pathParams);
    const searchParams = new URLSearchParams(rawQuery).toString();

    return `${pathname}${searchParams ? `?${searchParams}` : ''}`;
}

export function createRouteStringUnsafe(
    pathPattern: string,
    pathParams: {} = {},
    rawQuery: { [key: string]: string } = {}
) {
    const pathname = compile(pathPattern)(pathParams, {
        encode: (value: string) => value
    });
    const searchParams = new URLSearchParams(rawQuery).toString();

    return `${pathname}${searchParams ? `?${searchParams}` : ''}`;
}

export function matchRoute(routePattern: string, path: string, exact: boolean = true): boolean {
    const { pathname } = new URL(path, 'https://example.com');

    const routerPatternRegex = pathToRegexp(routePattern);

    if (exact) {
        return routerPatternRegex.test(pathname);
    }

    const pathnameParts = pathname.replace(/(^\/|\/$)/g, '').split('/');

    for (let index = pathnameParts.length - 1; index >= 0; index--) {
        const pathnamePartsToCheck = pathnameParts.slice(0, index + 1);
        const pathnameToCheck = `/${pathnamePartsToCheck.join('/')}/`;

        const match = routerPatternRegex.test(pathnameToCheck);

        if (match) {
            return match;
        }
    }

    return false;
}

export type ExtractParamsOptions = {
    exact?: boolean;
};

export function extractParams(
    routePattern: string,
    path: string,
    { exact = true }: ExtractParamsOptions = {}
): ParsedUrlQuery {
    const { pathname } = new URL(path, 'https://example.com');

    const keys: pathToRegexp.Key[] = [];
    const pattern = pathToRegexp(routePattern, keys, {
        end: exact
    });
    const result = pattern.exec(pathname);

    if (!result) {
        return {};
    }

    const nextRawParams = {} as ParsedUrlQuery;

    keys.forEach((key, index) => {
        nextRawParams[key.name] = String(result[index + 1]);
    });

    return nextRawParams;
}

/**
 * Create a path pattern resolver that always resolves with the last found match in the list. Returning the last found
 * match allows us to order the input patterns by hierarchy.
 * @param inputPatterns The patterns that should be used to resolve pathnames
 */
export function createPathnamePatternResolver(inputPatterns: string[]) {
    return (pathname: string, { skip = [] }: { skip?: string[] } = {}): string | null => {
        let patterns = inputPatterns;
        if (skip.length) {
            patterns = patterns.filter((pathPattern) => {
                return !skip.includes(pathPattern);
            });
        }

        let matchedPattern: null | string = null;

        patterns.forEach((pattern: string) => {
            const match = pathToRegexp(pattern).exec(pathname);

            if (!!match) {
                matchedPattern = pattern;
            }
        });

        return matchedPattern;
    };
}
