import { ParsedUrlQuery } from 'querystring';
import { convertSearchParamsToObject } from '../../utils/url';
import { RoutesRegistry, RouteRegistrySpecification } from './registry';
import { createRouteStringUnsafe } from '../utils';

type SpecificationValueGetter<Input, Output = Input> = (value: Input, routesRegistry: RoutesRegistry) => Output;

export type RouteRegistryParamsInUrlSpecification<Params extends {}> = {
    [K in keyof Params]?:
        | {
              value?: Params[K];
          }
        | SpecificationValueGetter<Params[K]>;
};

export type RouteRegistryParamsInPropsSpecification<Params extends {}> = {
    [K in keyof Params]?:
        | {
              value?: Params[K];
          }
        | SpecificationValueGetter<Params[K], any>;
};

export type RouteRegistryQueryInPropsSpecification<Query extends {}> = {
    [K in keyof Query]?:
        | {
              value?: Query[K];
          }
        | SpecificationValueGetter<Query[K], any>;
};

export type RouteRegistryRawQueryInUrlSpecification<RawQuery extends {}> = {
    [K in keyof RawQuery]?:
        | {
              value?: RawQuery[K];
          }
        | SpecificationValueGetter<string, RawQuery[K]>;
};

interface RouteRegistryAnalyticsSpecification<Params extends {} = {}, Query extends {} = {}, RawQuery extends {} = {}> {
    parseParams?: (input: string | ParsedUrlQuery) => Params;
    parseQuery?: (rawQuery: RawQuery | ParsedUrlQuery) => Query;
    analytics?: {
        paramsInUrl?: RouteRegistryParamsInUrlSpecification<Params>;
        paramsInProps?: RouteRegistryParamsInPropsSpecification<Params>;
        queryInProps?: RouteRegistryQueryInPropsSpecification<Query>;
        rawQueryInUrl?: RouteRegistryRawQueryInUrlSpecification<RawQuery>;
    };
}

interface CreateRouteRegistryAnalyticsSpecificationProps<
    Params extends {} = {},
    Query extends {} = {},
    RawQuery extends {} = {}
> {
    parseParams?: (input: string | ParsedUrlQuery) => Params;
    parseQuery?: (rawQuery: RawQuery | ParsedUrlQuery) => Query;
    analytics?: {
        paramsInUrl?: RouteRegistryParamsInUrlSpecification<Params>;
        paramsInProps?: RouteRegistryParamsInPropsSpecification<Params>;
        queryInProps?: RouteRegistryQueryInPropsSpecification<Query>;
        rawQueryInUrl?: RouteRegistryRawQueryInUrlSpecification<RawQuery>;
    };
}

declare module './registry' {
    interface RouteRegistrySpecification<Params extends {} = {}, Query extends {} = {}, RawQuery extends {} = {}>
        extends RouteRegistryAnalyticsSpecification<Params, Query, RawQuery> {}

    interface CreateRouteRegistrySpecificationProps<
        Params extends {} = {},
        Query extends {} = {},
        RawQuery extends {} = {}
    > extends CreateRouteRegistryAnalyticsSpecificationProps<Params, Query, RawQuery> {}
}

export type TrackableRoute<Params extends {} = {}, Query extends {} = {}> = {
    path: string;
    props: {
        params: Partial<Params>;
        query: Partial<Query>;
    };
};

export function findTrackableRoute(
    routesRegistry: RoutesRegistry,
    path: string,
    skipRoutePatterns: string[] = []
): TrackableRoute {
    const specification = routesRegistry.findSpecification(path, skipRoutePatterns);

    if (!specification) {
        return {
            path,
            props: {
                params: {},
                query: {}
            }
        };
    }

    return convertToTrackableRoute(routesRegistry, specification, path);
}

export function convertToTrackableRoute<
    TRouteRegistry extends RoutesRegistry,
    Params extends {},
    Query extends {},
    RawQuery extends Record<string, string>
>(
    routesRegistry: TRouteRegistry,
    specification: RouteRegistrySpecification<Params, Query, RawQuery>,
    path: string
): TrackableRoute<Params, Query> {
    const analyticsSpecification = specification.analytics ?? {};

    const { pathname, searchParams } = new URL(path, 'http://localhost');
    const searchParamsAsObject = convertSearchParamsToObject(searchParams);

    const params = specification.parseParams?.(pathname);
    const query = specification.parseQuery?.(searchParamsAsObject);
    const rawQuery = searchParamsAsObject;

    let paramsInUrl: Partial<Params> = {};
    let paramsInProps: Partial<Params> = {};
    let queryInProps: Partial<Query> = {};
    let rawQueryInUrl: Record<string, string> = {};

    if (!!params && (!!analyticsSpecification.paramsInUrl || !!analyticsSpecification.paramsInProps)) {
        for (const key in params) {
            const value = params[key]!;

            if (!!analyticsSpecification.paramsInUrl && key in analyticsSpecification.paramsInUrl) {
                const paramsInUrlKeySpecification = analyticsSpecification.paramsInUrl[key];

                if (typeof paramsInUrlKeySpecification === 'object' && paramsInUrlKeySpecification !== null) {
                    if (
                        'value' in paramsInUrlKeySpecification &&
                        typeof paramsInUrlKeySpecification.value !== 'undefined'
                    ) {
                        paramsInUrl[key] = paramsInUrlKeySpecification.value;
                    } else {
                        paramsInUrl[key] = value;
                    }
                } else if (typeof paramsInUrlKeySpecification === 'function') {
                    paramsInUrl[key] = paramsInUrlKeySpecification(value, routesRegistry);
                }
            }

            if (!!analyticsSpecification.paramsInProps && key in analyticsSpecification.paramsInProps) {
                const paramsInPropsKeySpecification = analyticsSpecification.paramsInProps[key];

                if (typeof paramsInPropsKeySpecification === 'object' && paramsInPropsKeySpecification !== null) {
                    if (
                        'value' in paramsInPropsKeySpecification &&
                        typeof paramsInPropsKeySpecification.value !== 'undefined'
                    ) {
                        paramsInProps[key] = paramsInPropsKeySpecification.value;
                    } else {
                        paramsInProps[key] = value;
                    }
                } else if (typeof paramsInPropsKeySpecification === 'function') {
                    paramsInProps[key] = paramsInPropsKeySpecification(value, routesRegistry);
                }
            }
        }
    }

    if (!!query && !!analyticsSpecification.queryInProps) {
        for (const key in query) {
            const value = query[key];

            if (key in analyticsSpecification.queryInProps) {
                const queryKeySpecification = analyticsSpecification.queryInProps[key];

                if (typeof queryKeySpecification === 'object' && queryKeySpecification !== null) {
                    if ('value' in queryKeySpecification && typeof queryKeySpecification.value !== 'undefined') {
                        queryInProps[key] = queryKeySpecification.value;
                    } else {
                        queryInProps[key] = value;
                    }
                } else if (typeof queryKeySpecification === 'function') {
                    queryInProps[key] = queryKeySpecification(value, routesRegistry);
                }
            }
        }
    }

    if (!!analyticsSpecification.rawQueryInUrl) {
        for (const key in rawQuery) {
            const value = rawQuery[key];

            if (key in analyticsSpecification.rawQueryInUrl) {
                const rawQueryKeySpecification = analyticsSpecification.rawQueryInUrl[key];

                if (typeof rawQueryKeySpecification === 'object' && rawQueryKeySpecification !== null) {
                    if ('value' in rawQueryKeySpecification && typeof rawQueryKeySpecification.value !== 'undefined') {
                        rawQueryInUrl[key] = rawQueryKeySpecification.value;
                    } else {
                        rawQueryInUrl[key] = value;
                    }
                } else if (typeof rawQueryKeySpecification === 'function') {
                    rawQueryInUrl[key] = rawQueryKeySpecification(value, routesRegistry);
                }
            }
        }
    }

    const paramsProxy = new Proxy(paramsInUrl, {
        get(target, key, receiver) {
            if (!(key in target) && typeof key !== 'symbol') {
                return `:${key}`;
            }

            return Reflect.get(target, key, receiver);
        }
    });

    const trackablePath = createRouteStringUnsafe(specification.pattern, paramsProxy, rawQueryInUrl);

    return {
        path: trackablePath,
        props: {
            params: paramsInProps,
            query: queryInProps
        }
    };
}
