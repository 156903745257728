import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import FlipMove from 'react-flip-move';
import { FormField, Input } from '@truffls/design-system-react';

import './RequestDocumentsViewOtherInputs.style.scss';

type Props = {
    show: boolean;
    values: {
        id: string;
        value: string;
    }[];

    changeValueField: (event: React.ChangeEvent) => void;
    addField: () => void;
    removeField: (event: React.MouseEvent) => void;
};

const RequestDocumentsViewOtherInputs = ({ show, values, changeValueField, addField, removeField }: Props) => {
    const inputs = values.map((input, index) => {
        const inputId = `other-input-${index}`;

        return (
            <div
                className="RequestDocumentsViewOtherInputs__input"
                key={`RequestDocumentsView__other-input__${input.id}`}
            >
                <div className="tf-form-element RequestDocumentsViewOtherInputs__input__field">
                    <FormField
                        id={inputId}
                        labelText={<FormattedMessage id="REQUEST_DOCUMENTS_VIEW.OTHER_INPUTS.LABEL" />}
                    >
                        <Input
                            type="text"
                            name={`other-document-input[${index}]`}
                            autoComplete="off"
                            value={input.value}
                            onChange={changeValueField}
                            data-lpignore="true"
                        />
                    </FormField>
                </div>
                <div className="RequestDocumentsViewOtherInputs__remove-button-container">
                    <button
                        type="button"
                        className="RequestDocumentsViewOtherInputs__remove-button"
                        name={`other-document-remove[${index}]`}
                        onClick={removeField}
                    />
                </div>
            </div>
        );
    });

    return (
        <div
            className={classNames('RequestDocumentsViewOtherInputs', {
                'RequestDocumentsViewOtherInputs--showing': show
            })}
        >
            <span className="RequestDocumentsViewOtherInputs__title">
                <FormattedMessage id="REQUEST_DOCUMENTS_VIEW.OTHER_INPUTS.TITLE" />
            </span>
            <FlipMove
                duration={200}
                appearAnimation="accordionVertical"
                enterAnimation="fade"
                leaveAnimation="fade"
                maintainContainerHeight
            >
                {inputs}
                {values.length < 3 && (
                    <button
                        type="button"
                        key="request-documents-view_other-inputs-add-button"
                        className="RequestDocumentsViewOtherInputs__add-button"
                        onClick={addField}
                    >
                        <FormattedMessage id="REQUEST_DOCUMENTS_VIEW.OTHER_INPUTS.ADD_BUTTON" />
                    </button>
                )}
            </FlipMove>
        </div>
    );
};

export default RequestDocumentsViewOtherInputs;
