import { createAction } from 'redux-actions';

import { OPEN_TUTORIAL, HIDE_TUTORIAL, COMPLETE_TUTORIAL, TUTORIAL_COMPLETE } from './actionsTypes';

export const openTutorial = createAction(OPEN_TUTORIAL);

export const hideTutorial = createAction(HIDE_TUTORIAL);

export const completeTutorial = createAction(COMPLETE_TUTORIAL);

export const tutorialComplete = {
    request: createAction(TUTORIAL_COMPLETE.REQUEST),
    success: createAction(TUTORIAL_COMPLETE.SUCCESS),
    failure: createAction(TUTORIAL_COMPLETE.FAILURE, (errors) => {
        return {
            errors
        };
    })
};
