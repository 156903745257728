import { Coordinates } from './types';

export function areCoordinates(coordinates: any): boolean {
    return (
        typeof coordinates === 'object' &&
        typeof coordinates.latitude === 'number' &&
        typeof coordinates.longitude === 'number'
    );
}

export function convertCoordinatesToCoordinatesString(coordinates: Coordinates): string {
    return `${coordinates.latitude},${coordinates.longitude}`;
}

export function convertCoordinatesStringToCoordinates(coordinatesString: string): Coordinates {
    const [latitudeString, longitudeString] = coordinatesString.split(',');

    return {
        latitude: parseFloat(latitudeString),
        longitude: parseFloat(longitudeString)
    };
}

export function convertCoordinatesToGeoJSON(coordinates: Coordinates) {
    return JSON.stringify({
        type: 'Point',
        coordinates: [coordinates.longitude, coordinates.latitude]
    });
}
