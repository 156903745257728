import { all, spawn, take, cancel, fork, put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import * as Sentry from '@sentry/browser';

import { Deferred } from '../../utils/promise';

import { ResponseError } from '../../services/api/types';
import { hasErrorWithCode, isResponseError } from '../../services/api/utils';
import {
    ERROR_CODE_BAD_CONTENT_TYPE_ERROR,
    ERROR_CODE_VALIDATION_ERROR,
    ERROR_CODE_MAX_UPLOAD_SIZE_ERROR,
    ERROR_CODE_MIN_RESOLUTION_ERROR,
    ERROR_CODE_MAX_RESOLUTION_ERROR
} from '../../services/api/constants';

import * as recruiterActions from './actions';
import * as recruitersApi from './api';

import { GalleryImage, GalleryImageId, Recruiter } from './types';
import { ResponseErrorsCollectionError } from '../../services/api/errors';

export function* fetchRecruiter(): SagaIterator<Recruiter> {
    try {
        yield put(recruiterActions.recruiterFetch.request());

        const response = yield call(recruitersApi.fetchRecruiter);
        const recruiter = response.data;

        yield put(recruiterActions.recruiterFetch.success(recruiter));

        return recruiter;
    } catch (error) {
        if (isResponseError(error)) {
            yield put(recruiterActions.recruiterFetch.failure(error.response.data.errors));
        } else {
            yield put(recruiterActions.recruiterFetch.failure());
        }

        throw error;
    }
}

export function* updateRecruiterSaga(
    recruiter: Partial<Recruiter>,
    { promise }: { promise?: Deferred<Recruiter, ResponseError[]> } = {}
): SagaIterator<Recruiter> {
    try {
        yield put(recruiterActions.reruiterUpdate.request());

        const response = yield call(recruitersApi.updateRecruiter, recruiter);
        const newRecruiter: Recruiter = response.data;

        yield put(recruiterActions.reruiterUpdate.success(newRecruiter));

        if (promise) {
            yield call(promise.resolve, newRecruiter);
        }

        return newRecruiter;
    } catch (error) {
        if (isResponseError(error)) {
            const errors = error.response.data.errors || [];

            yield put(recruiterActions.reruiterUpdate.failure(errors));

            const isErrorCausedByUser = false;

            if (isErrorCausedByUser) {
                if (promise) {
                    yield call(promise.reject);
                }
            } else {
                if (promise) {
                    yield call(promise.reject);
                }

                Sentry.captureException(error);
            }
        } else {
            yield put(recruiterActions.reruiterUpdate.failure());

            if (promise) {
                yield call(promise.reject);
            }

            Sentry.captureException(error);
        }

        throw error;
    }
}

export function* updateRecruiterPortraitSaga(
    file: File,
    { promise }: { promise?: Deferred<Recruiter, ResponseErrorsCollectionError> } = {}
): SagaIterator {
    try {
        yield put(recruiterActions.recruiterPortraitUpdate.request());

        const response = yield call(recruitersApi.updateRecruiterPortrait, file);
        const newRecruiter: Recruiter = response.data;

        yield put(recruiterActions.recruiterPortraitUpdate.success(newRecruiter));

        if (promise) {
            yield call(promise.resolve, newRecruiter);
        }
    } catch (error) {
        if (isResponseError(error)) {
            const errors = error.response.data.errors || [];

            yield put(recruiterActions.recruiterPortraitUpdate.failure(errors));

            if (promise) {
                yield call(promise.reject, new ResponseErrorsCollectionError(errors));
            }

            const isErrorCausedByUser =
                hasErrorWithCode(errors, ERROR_CODE_BAD_CONTENT_TYPE_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MIN_RESOLUTION_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MAX_RESOLUTION_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MAX_UPLOAD_SIZE_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_VALIDATION_ERROR);
            const isErrorNotCausedByUser = isErrorCausedByUser === false;

            if (isErrorNotCausedByUser) {
                Sentry.captureException(error);
            }
        } else {
            yield put(recruiterActions.recruiterPortraitUpdate.failure());

            if (promise) {
                yield call(promise.reject);
            }

            Sentry.captureException(error);
        }
    }
}

export function* updateRecruiterCompanyLogoSaga(
    file: File,
    { promise }: { promise?: Deferred<Recruiter, ResponseErrorsCollectionError> } = {}
): SagaIterator {
    try {
        yield put(recruiterActions.recruiterCompanyLogoUpdate.request());

        const response = yield call(recruitersApi.updateRecruiterCompanyLogo, file);
        const newRecruiter: Recruiter = response.data;

        yield put(recruiterActions.recruiterCompanyLogoUpdate.success(newRecruiter));

        if (promise) {
            yield call(promise.resolve, newRecruiter);
        }
    } catch (error) {
        if (isResponseError(error)) {
            const errors = error.response.data.errors || [];

            yield put(recruiterActions.recruiterCompanyLogoUpdate.failure(errors));

            if (promise) {
                yield call(promise.reject, new ResponseErrorsCollectionError(errors));
            }

            const isErrorCausedByUser =
                hasErrorWithCode(errors, ERROR_CODE_BAD_CONTENT_TYPE_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MIN_RESOLUTION_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MAX_RESOLUTION_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MAX_UPLOAD_SIZE_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_VALIDATION_ERROR);
            const isErrorNotCausedByUser = isErrorCausedByUser === false;

            if (isErrorNotCausedByUser) {
                Sentry.captureException(error);
            }
        } else {
            yield put(recruiterActions.recruiterCompanyLogoUpdate.failure());

            if (promise) {
                yield call(promise.reject);
            }

            Sentry.captureException(error);
        }
    }
}

export function* updateRecruiterCompanyBackgroundSaga(
    file: File,
    { promise }: { promise?: Deferred<Recruiter, ResponseErrorsCollectionError> } = {}
): SagaIterator {
    try {
        yield put(recruiterActions.recruiterCompanyBackgroundUpdate.request());

        const response = yield call(recruitersApi.updateRecruiterCompanyBackground, file);
        const newRecruiter: Recruiter = response.data;

        yield put(recruiterActions.recruiterCompanyBackgroundUpdate.success(newRecruiter));

        if (promise) {
            yield call(promise.resolve, newRecruiter);
        }
    } catch (error) {
        if (isResponseError(error)) {
            const errors = error.response.data.errors || [];

            yield put(recruiterActions.recruiterCompanyBackgroundUpdate.failure(errors));

            if (promise) {
                yield call(promise.reject, new ResponseErrorsCollectionError(errors));
            }

            const isErrorCausedByUser =
                hasErrorWithCode(errors, ERROR_CODE_BAD_CONTENT_TYPE_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MIN_RESOLUTION_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MAX_RESOLUTION_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MAX_UPLOAD_SIZE_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_VALIDATION_ERROR);
            const isErrorNotCausedByUser = isErrorCausedByUser === false;

            if (isErrorNotCausedByUser) {
                Sentry.captureException(error);
            }
        } else {
            yield put(recruiterActions.recruiterCompanyBackgroundUpdate.failure());

            if (promise) {
                yield call(promise.reject);
            }

            Sentry.captureException(error);
        }
    }
}

export function* createRecruiterCompanyGalleryImageSaga(
    file: Blob,
    order: number,
    localCreateId?: string,
    { promise }: { promise?: Deferred<Recruiter, ResponseErrorsCollectionError> } = {}
): SagaIterator<void> {
    try {
        yield put(recruiterActions.recruiterCompanyGalleryImageCreate.request(localCreateId));

        const galleryImageResponse = yield call(recruitersApi.createRecruiterCompanyGalleryImage, file, order);
        const newGalleryImage: GalleryImage = galleryImageResponse.data;

        const recruiterResponse = yield call(recruitersApi.fetchRecruiter);
        const newRecruiter: Recruiter = recruiterResponse.data;

        yield put(
            recruiterActions.recruiterCompanyGalleryImageCreate.success(localCreateId, newGalleryImage, newRecruiter)
        );

        if (promise) {
            yield call(promise.resolve, newRecruiter);
        }
    } catch (error) {
        if (isResponseError(error)) {
            const errors = error.response.data.errors || [];

            yield put(recruiterActions.recruiterCompanyGalleryImageCreate.failure(localCreateId, errors));

            if (promise) {
                yield call(promise.reject, new ResponseErrorsCollectionError(errors));
            }

            const isErrorCausedByUser =
                hasErrorWithCode(errors, ERROR_CODE_BAD_CONTENT_TYPE_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MIN_RESOLUTION_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MAX_RESOLUTION_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_MAX_UPLOAD_SIZE_ERROR) ||
                hasErrorWithCode(errors, ERROR_CODE_VALIDATION_ERROR);
            const isErrorNotCausedByUser = isErrorCausedByUser === false;

            if (isErrorNotCausedByUser) {
                Sentry.captureException(error);
            }
        } else {
            yield put(recruiterActions.recruiterCompanyGalleryImageCreate.failure(localCreateId));

            if (promise) {
                yield call(promise.reject);
            }

            Sentry.captureException(error);
        }
    }
}

export function* deleteRecruiterCompanyGalleryImageSaga(
    id: GalleryImageId,
    { promise }: { promise?: Deferred<Recruiter, ResponseErrorsCollectionError> } = {}
): SagaIterator<void> {
    try {
        yield put(recruiterActions.recruiterCompanyGalleryImageDelete.request(id));

        yield call(recruitersApi.deleteRecruiterCompanyGalleryImage, id);

        const recruiterResponse = yield call(recruitersApi.fetchRecruiter);
        const newRecruiter: Recruiter = recruiterResponse.data;

        yield put(recruiterActions.recruiterCompanyGalleryImageDelete.success(id, newRecruiter));

        if (promise) {
            yield call(promise.resolve, newRecruiter);
        }
    } catch (error) {
        if (isResponseError(error)) {
            const errors = error.response.data.errors || [];

            yield put(recruiterActions.recruiterCompanyGalleryImageDelete.failure(id, errors));

            if (promise) {
                yield call(promise.reject, new ResponseErrorsCollectionError(errors));
            }

            Sentry.captureException(error);
        } else {
            yield put(recruiterActions.recruiterCompanyGalleryImageDelete.failure(id));

            if (promise) {
                yield call(promise.reject);
            }

            Sentry.captureException(error);
        }
    }
}

export function* watchUpdateRecruiterSaga(): SagaIterator<void> {
    let task = null;

    while (true) {
        const action: recruiterActions.UpdateRecruiterAction = yield take(recruiterActions.UPDATE_RECRUITER);
        const { recruiter } = action.payload;
        const { promise } = action.meta;

        if (!!task) {
            yield cancel(task);
        }

        task = yield fork(updateRecruiterSaga, recruiter, { promise });
    }
}

export function* watchUpdateRecruiterPortraitSaga(): SagaIterator<void> {
    let task = null;

    while (true) {
        const action: recruiterActions.UpdateRecuiterPortraitAction = yield take(
            recruiterActions.UPDATE_RECRUITER_PORTRAIT
        );
        const { file } = action.payload;
        const { promise } = action.meta;

        if (!!task) {
            yield cancel(task);
        }

        task = yield fork(updateRecruiterPortraitSaga, file, { promise });
    }
}

export function* watchUpdateRecruiterCompanyLogoSaga(): SagaIterator<void> {
    let task = null;

    while (true) {
        const action: recruiterActions.UpdateRecruiterCompanyLogoAction = yield take(
            recruiterActions.UPDATE_RECRUITER_COMPANY_LOGO
        );
        const { file } = action.payload;
        const { promise } = action.meta;

        if (!!task) {
            yield cancel(task);
        }

        task = yield fork(updateRecruiterCompanyLogoSaga, file, { promise });
    }
}

export function* watchUpdateRecruiterCompanyBackgroundSaga(): SagaIterator<void> {
    let task = null;

    while (true) {
        const action: recruiterActions.UpdateRecruiterCompanyBackgroundAction = yield take(
            recruiterActions.UPDATE_RECRUITER_COMPANY_BACKGROUND
        );
        const { file } = action.payload;
        const { promise } = action.meta;

        if (!!task) {
            yield cancel(task);
        }

        task = yield fork(updateRecruiterCompanyBackgroundSaga, file, { promise });
    }
}

export function* watchCreateRecruiterCompanyGalleryImageSaga(): SagaIterator<void> {
    let tasks = {};

    while (true) {
        const action: recruiterActions.CreateRecruiterCompanyGalleryImageAction = yield take(
            recruiterActions.CREATE_RECRUITER_COMPANY_GALLERY_IMAGE
        );
        const { file, order, localCreateId } = action.payload;
        const { promise } = action.meta;

        if (!!localCreateId && tasks[localCreateId]) {
            yield cancel(tasks[localCreateId]);
        }

        const task = yield fork(createRecruiterCompanyGalleryImageSaga, file, order, localCreateId, { promise });

        if (!!localCreateId) {
            tasks[localCreateId] = task;
        }
    }
}

export function* watchDeleteRecruiterCompanyGalleryImageSaga(): SagaIterator<void> {
    let tasks = {};

    while (true) {
        const action: recruiterActions.DeleteRecruiterCompanyGalleryImageAction = yield take(
            recruiterActions.DELETE_RECRUITER_COMPANY_GALLERY_IMAGE
        );
        const { id } = action.payload;
        const { promise } = action.meta;

        if (tasks[id]) {
            yield cancel(tasks[id]);
        }

        tasks[id] = yield fork(deleteRecruiterCompanyGalleryImageSaga, id, { promise });
    }
}

export default function* rootSaga(): SagaIterator<void> {
    yield all([
        spawn(watchUpdateRecruiterSaga),
        spawn(watchUpdateRecruiterPortraitSaga),
        spawn(watchUpdateRecruiterCompanyLogoSaga),
        spawn(watchUpdateRecruiterCompanyBackgroundSaga),
        spawn(watchCreateRecruiterCompanyGalleryImageSaga),
        spawn(watchDeleteRecruiterCompanyGalleryImageSaga)
    ]);
}
