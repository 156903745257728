import { take, put, call, spawn } from 'redux-saga/effects';

import { isResponseError } from '../../../services/api/utils';
import { handleError } from '../../../services/errorHandler';

import { candidatesByApplicantIdFetch, FETCH_CANDIDATES_BY_APPLICANT_ID } from '../actions';
import { ApplicantId, Candidate } from '../types';
import * as candidatesApi from '../api';
import { ApiError, ApiListResponse } from '../../../services/api/types';

function* fetchCandidatesByApplicantIdSaga(applicantId: ApplicantId) {
    try {
        yield put(candidatesByApplicantIdFetch.request({ applicantId }));

        const { data }: ApiListResponse<Candidate> = yield call(candidatesApi.fetchCandidates, {
            applicant: applicantId
        });

        yield put(candidatesByApplicantIdFetch.success({ applicantId, data }));
    } catch (error) {
        let errors: ApiError[] = [];

        if (isResponseError(error)) {
            errors = error.response.data.errors || [];
        } else {
            handleError(error);
        }

        yield put(candidatesByApplicantIdFetch.failure({ applicantId, errors }));
    }
}

export default function* watchFetchCandidatesByApplicantIdSaga() {
    const tasks = {};

    while (true) {
        const { payload } = yield take(FETCH_CANDIDATES_BY_APPLICANT_ID);
        const { applicantId } = payload;

        // If there is a task and it's still running skip fetching again
        if (!!tasks[applicantId] && tasks[applicantId].isRunning()) {
            continue;
        }

        tasks[applicantId] = yield spawn(fetchCandidatesByApplicantIdSaga, applicantId);
    }
}
