import * as React from 'react';
import Leaflet from 'leaflet';
import { Marker, Popup } from 'react-leaflet';

import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// This URL generation is needed because leaflet doesn't work correctly with webpack – see https://github.com/Leaflet/Leaflet/issues/7424
const assetUrl = markerIcon.replace(/\/[^/]*\.png.*$/, '/');
const icon = new Leaflet.Icon.Default({
    iconUrl: markerIcon.replace(assetUrl, ''),
    iconRetinaUrl: markerIcon2x.replace(assetUrl, ''),
    shadowUrl: markerShadow.replace(assetUrl, ''),
    shadowSize: [0, 0]
});
Leaflet.Icon.Default.imagePath = assetUrl;

export type MapMarkerProps = {
    lat: number;
    long: number;
    children?: React.ReactNode;
};

class MapMarker extends React.Component<MapMarkerProps> {
    render() {
        const { lat, long, children } = this.props;

        const childCount = React.Children.count(children);

        const position: [number, number] = [lat, long];

        return (
            <Marker position={position} icon={icon}>
                {childCount && <Popup>{children}</Popup>}
            </Marker>
        );
    }
}

export default MapMarker;
