import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import DeleteCandidateView from '../../components/DeleteCandidateView';
import { deleteCandidate } from '../../modules/candidates/actions';
import { Candidate } from '../../modules/candidates/types';
import { canDelete } from '../../modules/candidates/utils';
import { getCandidate, getJob } from '../../modules/entities/selectors';
import { Job } from '../../modules/jobs/types';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { Recruiter } from '../../modules/recruiters/types';
import { getUser } from '../../modules/users/selectors';
import { User } from '../../modules/users/types';
import { State as ApplicationState } from '../../store/reducer';

type ConnectorProps = {
    jobId: number;
    candidateId: number;
    className?: string;
};

type ConnectedStateProps = {
    user: User | null;
    recruiter: Recruiter | null;
    job: Job | null;
    candidate: Candidate | null;
};

type ConnectedDispatchProps = {
    deleteCandidate: typeof deleteCandidate;
};

type Props = ConnectedStateProps & ConnectedDispatchProps & ConnectorProps;

type State = {
    showDeleteCandidateView: boolean;
};

export class CandidateActionDelete extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            showDeleteCandidateView: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClick() {
        this.setState({
            showDeleteCandidateView: true
        });
    }

    handleClose() {
        this.setState({
            showDeleteCandidateView: false
        });
    }

    handleSubmit(messageText: string) {
        const { candidateId, candidate, user, deleteCandidate } = this.props;

        if (!candidate || !user) {
            return;
        }

        const messageOwnerId = user.id;

        this.handleClose();
        deleteCandidate(candidateId, candidate.conversation_id, messageOwnerId, messageText);
    }

    render() {
        const { recruiter, job, candidate, className } = this.props;
        const { showDeleteCandidateView } = this.state;

        if (!candidate || !canDelete(candidate)) {
            return null;
        }

        return (
            <React.Fragment>
                <Button
                    type="button"
                    onClick={this.handleClick}
                    typeStyle="outlined"
                    variationStyle="destructive"
                    className={className}
                >
                    <FormattedMessage id="CANDIDATE_ACTION_DELETE.LABEL" />
                </Button>

                <DeleteCandidateView
                    open={showDeleteCandidateView}
                    recruiter={recruiter}
                    job={job}
                    candidate={candidate}
                    onClose={this.handleClose}
                    onDelete={this.handleSubmit}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => ({
    user: getUser(state),
    recruiter: getRecruiter(state),
    job: getJob(state, props.jobId),
    candidate: getCandidate(state, props.candidateId)
});

const mapDispatchToProps = {
    deleteCandidate
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateActionDelete);
