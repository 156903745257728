import * as Sentry from '@sentry/browser';
import { startSubmit, stopSubmit } from 'redux-form';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { clone, omit } from '../../utils/object';

import { saveCheckoutProcessState } from '../../modules/checkout/actions';
import { CheckoutProcessState } from '../../modules/checkout/types';
import { updateRecruiterSaga } from '../../modules/recruiters/saga';
import { Recruiter } from '../../modules/recruiters/types';

import { FormValues, FORM_NAME } from './constants';

export function* submitFormSaga(
    formValues: FormValues,
    checkoutProcessState: CheckoutProcessState
): SagaIterator<boolean> {
    try {
        const recruiter = omit(formValues, 'custom_invoice_text') as Recruiter;
        const newCheckoutProcessState: CheckoutProcessState = clone(checkoutProcessState);
        newCheckoutProcessState.data.customInvoiceText = formValues.custom_invoice_text || undefined;

        yield put(saveCheckoutProcessState(newCheckoutProcessState));

        yield put(startSubmit(FORM_NAME));

        yield call(updateRecruiterSaga, recruiter);

        yield put(stopSubmit(FORM_NAME));

        return true;
    } catch (error) {
        yield put(stopSubmit(FORM_NAME, { _error: 'unknown-error' }));

        Sentry.captureException(error);

        return false;
    }
}
