import { convertMarkdownToHtmlSync } from '../../../../utils/markdown';

import { ProductV2, ProductV2Content } from '../../types';

const records = [
    require('../../../../data/products/premium.yaml'),
    require('../../../../data/products/premium-1-year.yaml'),
    require('../../../../data/products/individual.yaml')
];

export function parseProducts(): ProductV2[] {
    return records.sort(byProperty('position')).map(
        (record): ProductV2 => {
            return parseProduct(record);
        }
    );
}

function parseProduct(record): ProductV2 {
    if (typeof record.id !== 'string' || !record.id) {
        throw new TypeError('Product id is not a string');
    } else if (typeof record.position !== 'number' || isNaN(record.position)) {
        throw new TypeError('Product position is not a number');
    } else if (typeof record.content !== 'object' || record.content === null) {
        throw new TypeError('Product content is not an object');
    } else if (typeof record.price !== 'string' && record.price !== null) {
        throw new TypeError('Product price is not a valid');
    } else if (typeof record.bookable !== 'boolean') {
        throw new TypeError('Product bookable is not a boolean');
    } else if (typeof record.promote !== 'boolean') {
        throw new TypeError('Product promote is not a boolean');
    } else if (
        typeof record.product_slug !== 'undefined' &&
        typeof record.product_slug !== 'string' &&
        record.product_slug !== null
    ) {
        throw new TypeError('Product slug is not a string');
    } else if (
        typeof record.duration !== 'undefined' &&
        typeof record.duration !== 'string' &&
        parseDuration(record.duration) === null
    ) {
        throw new TypeError('Product duration is not a valid');
    }

    const productSlug = record.product_slug || null;
    const duration = record.duration ? parseDuration(record.duration) : null;

    return {
        id: record.id,
        position: record.position,

        price: record.price,

        bookable: record.bookable,
        promote: record.promote,

        product_slug: productSlug,
        duration,

        content: (locale: string) => {
            const recordContent = record.content[locale];
            return parseProductContent(recordContent);
        }
    };
}

function parseProductContent(recordContent): ProductV2Content {
    if (typeof recordContent !== 'object' || recordContent === null) {
        throw new TypeError('Product content is not an object');
    } else if (typeof recordContent.title !== 'string' || !recordContent.title) {
        throw new TypeError('Product content title is not a string');
    } else if (!Array.isArray(recordContent.sections)) {
        throw new TypeError('Product content sections is not an array');
    }

    const sections = recordContent.sections.map((recordContentSection) => {
        return parseProductContentSection(recordContentSection);
    });

    return {
        title: recordContent.title,
        subtitle: recordContent.subtitle || null,
        sections
    };
}

function parseProductContentSection(recordContentSection) {
    // validate
    if (typeof recordContentSection !== 'object' || recordContentSection === null) {
        throw new TypeError('Product content section is not an object');
    } else if (typeof recordContentSection.content !== 'string' || !recordContentSection.content) {
        throw new TypeError('Product content section content is not a string');
    }

    const content = convertMarkdownToHtmlSync(recordContentSection.content, {
        emojiListItems: true
    });

    return {
        content
    };
}

function byProperty(propertyName) {
    return (a, b) => {
        return byValue()(a[propertyName], b[propertyName]);
    };
}

function byValue() {
    return (a, b) => {
        if (a > b) {
            return 1;
        } else if (a < b) {
            return -1;
        } else {
            return 0;
        }
    };
}

function parseDuration(duration: string): [number, 'years' | 'days'] | null {
    const match = duration.match(/^(\d+)-(years|days)$/);

    if (!match) {
        return null;
    }

    const [amountStr, unitStr] = match.slice(1);

    const amount = parseInt(amountStr, 10);
    const unit = unitStr as 'years' | 'days';

    return [amount, unit];
}
