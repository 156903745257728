import { Ordering } from '../../components/JobListHeader/constants';

export type ListName = 'news' | 'management';

export type ListFilter = {
    name: string;
    options: string[];
};

export type Filter = {
    [listName in ListName]: ListFilter;
};

export type ListParams = {
    pageSize: number;
    page: number;
    ordering: Ordering;
    [filterName: string]: any;
};

export type Params = {
    [listName in ListName]?: ListParams;
};

export type ListQueryParams = {
    page?: number;
    ordering?: Ordering;
    [filterName: string]: any;
};

export type QueryParams = {
    [listName in ListName]?: ListQueryParams;
};

export const LIST_NAMES: ListName[] = ['news', 'management'];

export const FILTER: Filter = {
    news: {
        name: 'state',
        options: ['draft', 'active', 'archived']
    },

    management: {
        name: 'state',
        options: ['active', 'archived', 'draft']
    }
};

export const DEFAULT_PARAMS: Params = {
    news: {
        pageSize: 10,
        page: 1,
        state: [],
        ordering: null
    },

    management: {
        pageSize: 10,
        page: 1,
        state: [],
        ordering: null
    }
};
