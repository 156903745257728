import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Button from '../../components/Button';
import UnlockCandidateView, {
    UNLOCK_CANDIDATE_VIEW_DO_NOT_SHOW_AGAIN_STORAGE_KEY
} from '../../components/UnlockCandidateView';
import { updateCandidateState } from '../../modules/candidates/actions';
import { Candidate } from '../../modules/candidates/types';
import { isNotInteresting, isUnprocessed } from '../../modules/candidates/utils';
import { getCandidate } from '../../modules/entities/selectors';
import * as localStorage from '../../services/localStorage';
import { State as ApplicationState } from '../../store/reducer';

type ConnectorProps = {
    candidateId: number;
    className?: string;
};

type ConnectedStateProps = {
    candidate: Candidate | null;
};

type ConnectedDispatchProps = {
    updateCandidateState: typeof updateCandidateState;
};

type Props = ConnectedStateProps & ConnectedDispatchProps & ConnectorProps;

type State = {
    shouldShowUnlockCandidateView: boolean;
    showUnlockCandidateView: boolean;
};

export class CandidateActionUnlock extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            shouldShowUnlockCandidateView: !localStorage.get(UNLOCK_CANDIDATE_VIEW_DO_NOT_SHOW_AGAIN_STORAGE_KEY),
            showUnlockCandidateView: false
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClick() {
        const { shouldShowUnlockCandidateView } = this.state;

        // If should not show the view we directly trigger the submit handler
        if (shouldShowUnlockCandidateView) {
            this.setState(() => ({
                showUnlockCandidateView: true
            }));
        } else {
            this.handleSubmit(true);
        }
    }

    handleClose() {
        this.setState(() => ({
            showUnlockCandidateView: false
        }));
    }

    handleSubmit(doNotShowAgain: boolean) {
        const { candidateId, updateCandidateState } = this.props;

        if (doNotShowAgain) {
            localStorage.set(UNLOCK_CANDIDATE_VIEW_DO_NOT_SHOW_AGAIN_STORAGE_KEY, true);
            this.setState(() => ({
                shouldShowUnlockCandidateView: false
            }));
        }

        this.handleClose();
        updateCandidateState(candidateId, 'interesting');
    }

    render() {
        const { candidate, className } = this.props;
        const { shouldShowUnlockCandidateView, showUnlockCandidateView } = this.state;

        if (!candidate || (!isUnprocessed(candidate) && !isNotInteresting(candidate))) {
            return null;
        }

        return (
            <React.Fragment>
                <Button
                    type="button"
                    onClick={this.handleClick}
                    typeStyle="raised"
                    variationStyle="brand"
                    className={className}
                >
                    <FormattedMessage id="CANDIDATE_ACTION_UNLOCK.LABEL" />
                </Button>

                <UnlockCandidateView
                    doNotShowAgain={shouldShowUnlockCandidateView}
                    open={showUnlockCandidateView}
                    onClose={this.handleClose}
                    onUnlock={this.handleSubmit}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => ({
    candidate: getCandidate(state, props.candidateId)
});

const mapDispatchToProps = {
    updateCandidateState
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateActionUnlock);
