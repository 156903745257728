import { createAction } from 'redux-actions';
import { createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';

import { ListResponse, Response, Errors } from '../types';
import { Template } from './types';

type TemplateId = Template['id'];
type NewTemplate = Partial<Template>;

// Factory to create all actions of the templates module
const createType = createActionTypeCreator('TEMPLATES');

// FETCH_TEMPLATES
export const FETCH_TEMPLATES = createType('FETCH_TEMPLATES');
export const fetchTemplates = createAction(
    FETCH_TEMPLATES,
    (page?: number, pageSize?: number, ordering?: Array<string>) => ({
        page,
        pageSize,
        ordering
    })
);

export const TEMPLATES_FETCH = createRequestActionTypes(createType('TEMPLATES_FETCH'));
export const templatesFetch = {
    request: createAction(TEMPLATES_FETCH.REQUEST),
    success: createAction(TEMPLATES_FETCH.SUCCESS, (response: ListResponse<Template>) => ({
        data: response.data
    })),

    failure: createAction(TEMPLATES_FETCH.FAILURE, (errors: Errors) => ({
        errors
    }))
};

// FETCH_TEMPLATE
export const FETCH_TEMPLATE = createType('FETCH_TEMPLATE');
export const fetchTemplate = createAction(FETCH_TEMPLATE, (templateId: TemplateId) => ({
    templateId
}));

export const TEMPLATE_FETCH = createRequestActionTypes(createType('TEMPLATE_FETCH'));
export const templateFetch = {
    request: createAction(TEMPLATE_FETCH.REQUEST, (templateId: TemplateId) => ({
        templateId
    })),

    success: createAction(TEMPLATE_FETCH.SUCCESS, (templateId: TemplateId, response: Response<Template>) => ({
        templateId,
        data: response.data
    })),

    failure: createAction(TEMPLATE_FETCH.FAILURE, (templateId: TemplateId, errors: Errors) => ({
        templateId,
        errors
    }))
};

// CREATE_TEMPLATE
export const CREATE_TEMPLATE = createType('CREATE_TEMPLATE');
export const createTemplate = createAction(CREATE_TEMPLATE, (newTemplate: NewTemplate) => ({
    newTemplate
}));

export const TEMPLATE_CREATE = createRequestActionTypes(createType('TEMPLATE_CREATE'));
export const templateCreate = {
    request: createAction(TEMPLATE_CREATE.REQUEST),
    success: createAction(TEMPLATE_CREATE.SUCCESS, (response: Response<Template>) => ({
        data: response.data
    })),

    failure: createAction(TEMPLATE_CREATE.FAILURE, (errors: Errors) => ({
        errors
    }))
};

// DUPLICATE_TEMPLATE
export const DUPLICATE_TEMPLATE = createType('DUPLICATE_TEMPLATE');
export const duplicateTemplate = createAction(DUPLICATE_TEMPLATE, (newTemplate: NewTemplate) => ({
    newTemplate
}));

// UPDATE_TEMPLATE
export const UPDATE_TEMPLATE = createType('UPDATE_TEMPLATE');
export const updateTemplate = createAction(UPDATE_TEMPLATE, (templateId: TemplateId, newTemplate: NewTemplate) => ({
    templateId,
    newTemplate
}));

export const TEMPLATE_UPDATE = createRequestActionTypes(createType('TEMPLATE_UPDATE'));
export const templateUpdate = {
    request: createAction(TEMPLATE_UPDATE.REQUEST, (templateId: TemplateId) => ({
        templateId
    })),

    success: createAction(TEMPLATE_UPDATE.SUCCESS, (templateId: TemplateId, response: Response<Template>) => ({
        templateId,
        data: response.data
    })),

    failure: createAction(TEMPLATE_UPDATE.FAILURE, (templateId: TemplateId, errors: Errors) => ({
        templateId,
        errors
    }))
};

// DELETE_TEMPLATE
export const DELETE_TEMPLATE = createType('DELETE_TEMPLATE');
export const deleteTemplate = createAction(DELETE_TEMPLATE, (templateId: TemplateId) => ({
    templateId
}));

export const TEMPLATE_DELETE = createRequestActionTypes(createType('TEMPLATE_DELETE'));
export const templateDelete = {
    request: createAction(TEMPLATE_DELETE.REQUEST, (templateId: TemplateId) => ({
        templateId
    })),

    success: createAction(TEMPLATE_DELETE.SUCCESS, (templateId: TemplateId) => ({
        templateId
    })),

    failure: createAction(TEMPLATE_DELETE.FAILURE, (templateId, errors: Errors) => ({
        templateId,
        errors
    }))
};
