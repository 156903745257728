import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import classes from './CheckoutContactButton.module.scss';

export type CheckoutContactButtonProps = {
    className?: string | null;
    component?: React.ComponentType<any> | string;
    wide?: boolean;
    [key: string]: any;
};

type AdditionPassDownProps = {
    type?: 'button';
};

function CheckoutContactButton({
    className,
    component: Component = 'button',
    wide = false,
    ...restProps
}: CheckoutContactButtonProps) {
    const additionalProps: AdditionPassDownProps = {};
    if (Component === 'button') {
        additionalProps.type = 'button';
    }

    return (
        <Component
            {...additionalProps}
            {...restProps}
            className={classNames(
                classes.root,
                {
                    [classes.wide]: wide
                },
                className
            )}
        >
            <FormattedMessage id="CHECKOUT_CONTACT_BUTTON.LABEL" />
        </Component>
    );
}

export default CheckoutContactButton;
