import { AxiosRequestConfig, Method, AxiosResponse, AxiosError } from 'axios';

import { omit } from '../../../utils/object';

declare module 'axios' {
    interface AxiosRequestConfig {
        methodOverridden?: boolean;
    }
}

export function overrideMethod(config: AxiosRequestConfig): AxiosRequestConfig {
    const originalMethod = !!config.method ? config.method.toUpperCase() : null;

    if (originalMethod === 'PUT' || originalMethod === 'PATCH' || originalMethod === 'DELETE') {
        if (!config.headers) {
            config.headers = {};
        }

        config.headers['X-HTTP-Method-Override'] = originalMethod;
        config.method = 'POST';

        config.methodOverridden = true;
    }

    return config;
}

export function rewindMethodOverride(config: AxiosRequestConfig): AxiosRequestConfig {
    const method = !!config.method ? config.method.toUpperCase() : null;
    const headers = config.headers || {};

    const originalMethod = headers['x-http-method-override'] || headers['X-HTTP-Method-Override'];

    if (method === 'POST' && typeof originalMethod === 'string') {
        config.method = originalMethod as Method;
        config.headers = omit(headers, ['x-http-method-override', 'X-HTTP-Method-Override']);
    }

    return config;
}

export function onRequestFulfilled(config: AxiosRequestConfig): Promise<AxiosRequestConfig> | AxiosRequestConfig {
    return Promise.resolve(overrideMethod(config));
}

export function onResponseFulfilled(response: AxiosResponse<any>): Promise<AxiosResponse<any>> {
    response.config = rewindMethodOverride(response.config);
    return Promise.resolve(response);
}

export function onResponseRejected(error: AxiosError): any {
    const response = error.response;

    if (!!response) {
        response.config = rewindMethodOverride(response.config);
        error.response = response;
    }

    if (!!error.config) {
        error.config = rewindMethodOverride(error.config);
    }

    return Promise.reject(error);
}
