import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';

import Headline from '../../components/Headline';
import HeadlineTitle from '../../components/Headline/HeadlineTitle';

import { parseSearch } from '../../utils/url';
import { stringToBoolean } from '../../utils/type';

import EmailAndPasswordLoginForm from '../EmailAndPasswordLoginForm';

type Props = RouteComponentProps & {};

class EmailAndPasswordLoginPage extends React.Component<Props> {
    render() {
        const { location } = this.props;
        const query = parseSearch<{ 'destination-url'?: string }>(location.search || '');

        const destinationUrl: null | string = query['destination-url'] || null;

        let verificationSuccessful: null | boolean = null;
        if (typeof query['verification-successful'] === 'string') {
            verificationSuccessful = stringToBoolean(query['verification-successful']);
        }

        return (
            <div className="container">
                <Headline center>
                    <HeadlineTitle>
                        <FormattedMessage id="EMAIL_AND_PASSWORD_LOGIN_PAGE.TITLE" />
                    </HeadlineTitle>
                </Headline>

                <EmailAndPasswordLoginForm
                    destinationUrl={destinationUrl}
                    verificationSuccessful={verificationSuccessful}
                />
            </div>
        );
    }
}

export default EmailAndPasswordLoginPage;
