import React from 'react';
import CSSTransition, { CSSTransitionProps, CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import './style.scss';

const transitionClassNames: CSSTransitionClassNames = {
    appear: 'SlideUpTransition--enter',
    appearActive: 'SlideUpTransition--enter-active',
    exit: 'SlideUpTransition--exit',
    exitActive: 'SlideUpTransition--exit-active'
};

export type SlideUpTransitionProps = Omit<CSSTransitionProps, 'classNames' | 'timeout'>;

function SlideUpTransition(props: SlideUpTransitionProps) {
    return <CSSTransition {...props} timeout={500} classNames={transitionClassNames} />;
}

export default SlideUpTransition;
