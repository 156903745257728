import React from 'react';

import JobFormTasksEditModeContext, { JobFormTasksEditModeContextValue } from './JobFormTasksEditModeContext';

export type JobFormTasksEditModeContextProviderProps = {
    children: React.ReactNode;
};

function JobFormTasksEditModeContextProvider({ children }: JobFormTasksEditModeContextProviderProps) {
    const [fieldsInEditMode, setFieldsInEditMode] = React.useState<string[]>([]);

    const enableEditMode = React.useCallback(
        (name: string) => {
            setFieldsInEditMode((fieldsInEditMode) => {
                return Array.from(new Set([...fieldsInEditMode, name]));
            });
        },
        [setFieldsInEditMode]
    );

    const disableEditMode = React.useCallback(
        (name: string) => {
            setFieldsInEditMode((fieldsInEditMode) => {
                return fieldsInEditMode.filter((fieldInEditMode) => {
                    return fieldInEditMode !== name;
                });
            });
        },
        [setFieldsInEditMode]
    );

    const value = React.useMemo((): JobFormTasksEditModeContextValue => {
        return {
            fieldsInEditMode,

            enableEditMode,
            disableEditMode
        };
    }, [fieldsInEditMode, enableEditMode, disableEditMode]);

    return <JobFormTasksEditModeContext.Provider value={value}>{children}</JobFormTasksEditModeContext.Provider>;
}

export default JobFormTasksEditModeContextProvider;
