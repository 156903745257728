import * as React from 'react';

import './style.scss';

export type MobileAppInfoMessageProps = {
    messageText: React.ReactNode;
};

const MobileAppInfoMessage = React.forwardRef(
    (props: MobileAppInfoMessageProps, ref: React.ForwardedRef<HTMLDivElement>): React.ReactElement => {
        return (
            <div ref={ref} className="MobileAppInfoMessage">
                <div className="MobileAppInfoMessage__bubble">{props.messageText}</div>
            </div>
        );
    }
);

export default MobileAppInfoMessage;
