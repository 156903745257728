export function calculateExpiresFromMaxAge(maxAgeInMs: number | null | undefined, now?: Date): Date | undefined {
    if (!maxAgeInMs) {
        return undefined;
    }

    const expires = !!now ? new Date(now) : new Date();
    expires.setTime(expires.getTime() + maxAgeInMs);

    return expires;
}
