import { hasFileValidSize } from '../../modules/files/utils';

import { compileTemplate } from '../../modules/templates/utils';
import {
    TEMPLATE_VARIABLE_CANDIDATE_FIRST_NAME,
    TEMPLATE_VARIABLE_CANDIDATE_LAST_NAME,
    TEMPLATE_VARIABLE_JOB_TITLE
} from '../../modules/templates/constants';

import { Template } from '../../modules/templates/types';
import { Candidate } from '../../modules/candidates/types';
import { Job } from '../../modules/jobs/types';

export function allAttachmentsHaveCorrectSize(attachments: File[]): boolean {
    for (let attachment of attachments) {
        if (!hasFileValidSize(attachment)) {
            return false;
        }
    }
    return true;
}

export function getCompiledTemplate(
    templates: Template[],
    selectedTemplateId: Template['id'] | null,
    candidate: Candidate | null,
    job: Job | null
): string | void {
    if (templates.length > 0 && !!candidate && !!job) {
        // If no template is selected the user expects to preview the first template (selected by default)
        const parsedSelectedTemplateId = selectedTemplateId || templates[0].id;

        const selectedTemplate = templates.find((template) => template.id === parsedSelectedTemplateId);

        if (selectedTemplate) {
            const tokens = {
                [TEMPLATE_VARIABLE_CANDIDATE_FIRST_NAME]: candidate.applicant.first_name,
                [TEMPLATE_VARIABLE_CANDIDATE_LAST_NAME]: candidate.applicant.last_name,
                [TEMPLATE_VARIABLE_JOB_TITLE]: job.title
            };

            return compileTemplate(selectedTemplate, tokens);
        }
    }
}
