import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Locale } from '../../../i18n';
import { getSignupRoute } from '../../../routes';

import Button from '../../../components/Button';
import OverlayLanguageMenu from '../../../components/OverlayLanguageMenu';

import LanguageDropdown from './LanguageDropdown';

export type LoggedOutActionsProps = {
    onChangeLanguage: (lang: Locale) => void;
};

function LoggedOutActions({ onChangeLanguage }: LoggedOutActionsProps) {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [dropdownOpen, setDropdownOpen] = React.useState(false);

    const toggleLanguageMenu = () => {
        if (matchMedia('(max-width: 769px)').matches) {
            setMenuOpen(!menuOpen);
        } else {
            setDropdownOpen(!dropdownOpen);
        }
    };

    const closeMenu = () => {
        setMenuOpen(false);
        setDropdownOpen(false);
    };

    return (
        <div className="NavigationActions">
            <Button
                component={Link}
                to={getSignupRoute()}
                typeStyle="raised"
                variationStyle="brand"
                className="NavigationActions__action NavigationActions__action--sm NavigationActions__link"
            >
                <FormattedMessage id="NAVIGATION.ACTIONS.PRIMARY.SIGN_UP" />
            </Button>

            <LanguageDropdown open={dropdownOpen} onToggle={toggleLanguageMenu} onChangeLanguage={onChangeLanguage} />

            <OverlayLanguageMenu open={menuOpen} onClose={closeMenu} onChangeLanguage={onChangeLanguage} />
        </div>
    );
}

export default LoggedOutActions;
