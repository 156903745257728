import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import './style.scss';

const CandidateListHeaderColumnState = () => {
    return (
        <div className="CandidateListHeaderColumnState">
            <div className="CandidateListHeaderColumnState__column CandidateListHeaderColumnState__column--locked">
                <span className="CandidateListHeaderColumnState__column-label">
                    <FormattedMessage id="CONSTANT.CANDIDATE_STATE.locked" />
                </span>
            </div>
            <div className="CandidateListHeaderColumnState__column CandidateListHeaderColumnState__column--interesting">
                <span className="CandidateListHeaderColumnState__column-label">
                    <FormattedMessage id="CONSTANT.CANDIDATE_STATE.interesting" />
                </span>
            </div>
            <div className="CandidateListHeaderColumnState__column CandidateListHeaderColumnState__column--phone-interview">
                <span className="CandidateListHeaderColumnState__column-label">
                    <FormattedMessage id="CONSTANT.CANDIDATE_STATE.phone-interview" />
                </span>
            </div>
            <div className="CandidateListHeaderColumnState__column CandidateListHeaderColumnState__column--interview">
                <span className="CandidateListHeaderColumnState__column-label">
                    <FormattedMessage id="CONSTANT.CANDIDATE_STATE.interview" />
                </span>
            </div>
            <div className="CandidateListHeaderColumnState__column CandidateListHeaderColumnState__column--hired">
                <span className="CandidateListHeaderColumnState__column-label">
                    <FormattedMessage id="CONSTANT.CANDIDATE_STATE.hired" />
                </span>
            </div>
        </div>
    );
};

export default CandidateListHeaderColumnState;
