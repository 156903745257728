import * as React from 'react';
import { FormActions } from '@truffls/design-system-react';

import SignupFormStepActionBack from './SignupFormStepActionBack';
import SignupFormStepActionSubmit from './SignupFormStepActionSubmit';

type Props = {
    hideBack?: boolean;
    submitStep?: boolean;
    forTrial?: boolean;

    submitting?: boolean;
    valid?: boolean;

    onNext?: (...args: any) => void;
    onBack?: (...args: any) => void;
};

function SignupFormStepActions(props: Props): React.ReactElement {
    return (
        <FormActions>
            {!props.hideBack && <SignupFormStepActionBack onBack={props.onBack} />}
            <SignupFormStepActionSubmit
                submitStep={props.submitStep}
                submitting={props.submitting || false}
                valid={props.valid}
                onNext={props.onNext}
                forTrial={props.forTrial || false}
            />
        </FormActions>
    );
}

export default SignupFormStepActions;
