import * as React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { FormattedMessage } from 'react-intl';
import hiredIcon from '../../assets/images/ic_hired.svg';
import { Candidate } from '../../modules/candidates/types';
import BottomActionBar from '../BottomActionBar';
import Button from '../Button';
import CandidateDetails from '../CandidateDetails';
import MobilePageContainer from '../MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../NavigationBar';
import './HireConfirmationView.style.scss';

type Props = {
    scrolled?: boolean;
    onScroll?: () => void;

    open: boolean;
    candidate: Candidate | null;
    onConfirm: () => void;
    onClose: () => void;
};

type State = {
    isViewportMobile: boolean;
};

class HireConfirmationView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isViewportMobile: false
        };
    }

    componentDidMount() {
        if (matchMedia('(max-width: 769px)').matches) {
            this.setState({ isViewportMobile: true });
        } else {
            this.setState({ isViewportMobile: false });
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.open && !!this.props.open) {
            if (matchMedia('(max-width: 769px)').matches) {
                this.setState({ isViewportMobile: true });
            } else {
                this.setState({ isViewportMobile: false });
            }
        }
    }

    render() {
        const title = <FormattedMessage id="HIRE_CONFIRMATION.TITLE" />;
        const confirm = <FormattedMessage id="HIRE_CONFIRMATION.ACTION.CONFIRM" />;

        const content = (
            <div className="HireConfirmationView__content">
                <img className="HireConfirmationView__content-icon" src={hiredIcon} />
                <div className="HireConfirmationView__content-text">
                    <FormattedMessage id="HIRE_CONFIRMATION.CONTENT.TEXT" />
                </div>
                <div className="HireConfirmationView__content-candidate">
                    <CandidateDetails candidate={this.props.candidate} showNewBadge showUnreadMessageBadge />
                </div>
            </div>
        );

        let contentToRender;

        if (this.state.isViewportMobile) {
            contentToRender = this.renderMobileFrame(content, title, confirm);
        } else {
            contentToRender = this.renderDesktopFrame(content, title, confirm);
        }

        return contentToRender;
    }

    renderMobileFrame(content, title, confirm) {
        const { scrolled, onScroll, open, onClose } = this.props;

        return (
            <MobilePageContainer show={open}>
                <NavigationBar
                    noShadow={!scrolled}
                    centerElement={<NavigationBarText>{title}</NavigationBarText>}
                    rightElement={<NavigationBarCloseAction onClick={onClose} />}
                />

                <div className="HireConfirmationView__overlay-container" onScroll={onScroll}>
                    {content}
                </div>
                <BottomActionBar>
                    <Button type="button" onClick={this.props.onConfirm} typeStyle="flat" variationStyle="normal">
                        {confirm}
                    </Button>
                </BottomActionBar>
            </MobilePageContainer>
        );
    }

    renderDesktopFrame(content, title, confirm) {
        return (
            <Modal show={this.props.open} dialogClassName="HireConfirmationView__modal" onHide={this.props.onClose}>
                <Modal.Header className="HireConfirmationView__modal-header">
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{content}</Modal.Body>
                <Modal.Footer className="HireConfirmationView__modal-footer">
                    <Button type="button" onClick={this.props.onClose} typeStyle="flat" variationStyle="normal">
                        <FormattedMessage id="HIRE_CONFIRMATION.ACTION.CANCEL" />
                    </Button>
                    <Button type="button" onClick={this.props.onConfirm} typeStyle="flat" variationStyle="normal">
                        {confirm}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default HireConfirmationView;
