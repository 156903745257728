import { createSelector } from 'reselect';

// Type
import { ApplicationState } from '../../store/reducer';
import { UIState as State } from './reducer';

export function getUIState(state: ApplicationState): State {
    return state.ui;
}

export const isNavigationVisible = createSelector(getUIState, (ui: State) => ui.navigationVisible);
