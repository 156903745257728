import apiClientV4 from '../../services/api/v4';

import { Candidate } from '../../modules/candidates/types';
import { File } from './types';

type CandidateId = Candidate['id'];
type FileId = File['id'];

export type FetchFilesByCandidateIdQueryParams = {
    page?: number;
};

type Params = {
    page: number;
    candidate: CandidateId;
    ordering: string;
};

export function fetchFilesByCandidateId(
    candidateId: CandidateId,
    queryParams: FetchFilesByCandidateIdQueryParams = {}
) {
    const params: Params = {
        page: queryParams.page || 1,
        candidate: candidateId,
        ordering: '-last_shared'
    };

    return apiClientV4.get('/shared-files/', { params });
}

export function requestPreview(fileId: FileId) {
    return apiClientV4.post(`/files/${fileId}/request-preview/`);
}

export function requestDownload(fileId: FileId) {
    return apiClientV4.post(`/files/${fileId}/request-download/`);
}

export function requestFilesFromCandidate(candidateId: CandidateId, requestedDocuments: Object[]) {
    const postData = {
        candidate_id: candidateId,
        documents: requestedDocuments
    };

    return apiClientV4.post('/document-requests/', postData);
}

export function uploadFile(file: Blob) {
    const data = new FormData();
    data.append('content', file);

    return apiClientV4.post('/files/', data);
}
