import * as React from 'react';
import { Route, RouteComponentProps, Switch, useRouteMatch } from 'react-router-dom';
import { useCheckoutProcessState } from '../../hooks/useCheckoutProcessState';
import { useLoadJob } from '../../hooks/useLoadJob';
import { useRecruiter } from '../../hooks/useRecruiter';
import { CheckoutProcessState } from '../../modules/checkout/types';
import { getAdvertisementFromCheckoutProcessState } from '../../modules/checkout/utils';
import { CheckoutRouteParams } from '../../routes';
import {
    CHECKOUT_ADDRESS_PATH_PATTERN,
    CHECKOUT_FINISH_PATH_PATTERN,
    CHECKOUT_PRODUCT_PATH_PATTERN,
    CHECKOUT_SUCCESS_PATH_PATTERN,
    parseCheckoutRouteParams
} from '../../routes/checkout';
import CheckoutAddressPage from '../CheckoutAddressPage';
import CheckoutConfirmPage from '../CheckoutConfirmPage';
import CheckoutProductPage from '../CheckoutProductPage';
import CheckoutSuccessPage from '../CheckoutSuccessPage';
import './CheckoutPage.style.scss';

type ConnectorProps = RouteComponentProps<CheckoutRouteParams>;

type MappedProps = {
    checkoutProcessState: CheckoutProcessState | null;
};

export type Props = ConnectorProps & MappedProps;

function CheckoutPage({}: Props) {
    const match = useRouteMatch();
    const { checkoutProcessId } = parseCheckoutRouteParams(match.url, {
        exact: false
    });

    const recruiter = useRecruiter();

    const checkoutProcessState = useCheckoutProcessState(checkoutProcessId);

    const advertisement = !!checkoutProcessState
        ? getAdvertisementFromCheckoutProcessState(checkoutProcessState)
        : null;

    const { job, loading: jobIsLoading } = useLoadJob({
        jobId: !!advertisement ? advertisement.job : null
    });

    if (!recruiter || !checkoutProcessState || (!!advertisement && !job)) {
        return null;
    }

    return (
        <div className="CheckoutPage">
            <Switch>
                <Route path={CHECKOUT_PRODUCT_PATH_PATTERN}>
                    <CheckoutProductPage
                        recruiter={recruiter}
                        checkoutProcessState={checkoutProcessState}
                        job={job}
                        jobIsLoading={jobIsLoading}
                    />
                </Route>
                <Route path={CHECKOUT_ADDRESS_PATH_PATTERN}>
                    <CheckoutAddressPage recruiter={recruiter} checkoutProcessState={checkoutProcessState} job={job} />
                </Route>
                <Route path={CHECKOUT_FINISH_PATH_PATTERN}>
                    <CheckoutConfirmPage recruiter={recruiter} checkoutProcessState={checkoutProcessState} job={job} />
                </Route>
                <Route path={CHECKOUT_SUCCESS_PATH_PATTERN}>
                    <CheckoutSuccessPage
                        recruiter={recruiter}
                        checkoutProcessState={checkoutProcessState}
                        job={job}
                        jobIsLoading={jobIsLoading}
                    />
                </Route>
            </Switch>
        </div>
    );
}

export default CheckoutPage;
