import React from 'react';
import classNames from 'classnames';

import './List.scss';

export type ListProps = {
    children?: React.ReactNode;
    className?: string;
    component?: React.ElementType;
    disablePadding?: boolean;

    [prop: string]: any;
};

const List = React.forwardRef<HTMLElement, ListProps>(function List(
    { className, children, component: Component = 'ul', disablePadding = false, ...rest }: ListProps,
    ref
) {
    return (
        <Component
            ref={ref}
            className={classNames('List', className, {
                'List--padding': disablePadding === false
            })}
            {...rest}
        >
            {children}
        </Component>
    );
});

export default List;
