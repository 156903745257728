import { MAX_SIZE_FILE, FILE_TYPES, FILE_MIME_TYPES_MAPPING, FILE_CATEGORIES } from '../../modules/files/constants';

import { File, FileCategory, FileType } from './types';

export function hasFileValidSize(file: { size: number }): boolean {
    return file.size <= MAX_SIZE_FILE;
}

export function getFileTypeFromMimeType(mimeType: string): FileType {
    let type: FileType = FILE_TYPES.GENERIC;

    // Look for a match on each file type's mime types
    const fileTypeKeys = Object.keys(FILE_MIME_TYPES_MAPPING) as FileType[];
    fileTypeKeys.forEach((fileTypeKey) => {
        const mimeTypes = FILE_MIME_TYPES_MAPPING[fileTypeKey];
        if (mimeTypes.indexOf(mimeType) >= 0) {
            type = fileTypeKey;
        }
    });

    return type;
}

export function getFormattedFileName(file: File): string {
    return file.name + file.extension;
}

export function getFileCategoryTranslationId(category: FileCategory): string {
    switch (category) {
        case FILE_CATEGORIES.RESUME:
            return 'FILE_CATEGORY.RESUME';
        case FILE_CATEGORIES.GENERATED_RESUME:
            return 'FILE_CATEGORY.GENERATED_RESUME';
        case FILE_CATEGORIES.GRADUATION_CERTIFICATE:
            return 'FILE_CATEGORY.GRADUATION_CERTIFICATE';
        case FILE_CATEGORIES.CERTIFICATE:
            return 'FILE_CATEGORY.CERTIFICATE';
        case FILE_CATEGORIES.WORK_SAMPLE:
            return 'FILE_CATEGORY.WORK_SAMPLE';
        case FILE_CATEGORIES.EMPLOYMENT_REFERENCE:
            return 'FILE_CATEGORY.EMPLOYMENT_REFERENCE';
        case FILE_CATEGORIES.OTHER:
        default:
            return 'FILE_CATEGORY.OTHER';
    }
}
