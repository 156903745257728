import React from 'react';
import { connect } from 'react-redux';
import { FormFieldGroup, FormFieldGroupItem, Input, Select } from '@truffls/design-system-react';
import debounce from 'just-debounce-it';
import Big from 'big.js';
import { FormattedMessage, useIntl } from 'react-intl';

import { State as ApplicationState } from '../../store/reducer';

import { Candidate, CandidateSalaryExpectationScale } from '../../modules/candidates/types';
import { getCandidate } from '../../modules/entities/selectors';
import { updateCandidate } from '../../modules/candidates/actions';

import './CandidateSalaryExpecation.scss';

type ConnectorProps = {
    candidateId: number;
};

type ConnectedStateProps = {
    candidate: Candidate | null;
};

type ConnectedDispatchProps = {
    updateCandidate: typeof updateCandidate;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type FormValues = {
    expectation: string;
    scale: CandidateSalaryExpectationScale;
};

function CandidateSalaryExpecation({ candidate, updateCandidate }: Props) {
    const intl = useIntl();

    const { formValues, setFormValues } = useFormValues(candidate);

    const saveCandidate = React.useCallback(
        (formValues: FormValues) => {
            if (!candidate) {
                return;
            }

            updateCandidate(candidate.id, {
                salary_expectation: {
                    salary_scale: formValues.scale,
                    salary_expectation: new Big(formValues.expectation).toFixed()
                }
            });
        },
        [candidate, updateCandidate]
    );
    const saveCandidateDebounce = React.useMemo(() => {
        return debounce(saveCandidate, 400);
    }, [saveCandidate]);

    const onChange = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
            event.preventDefault();
            const { name, value } = event.target;

            // Validate expectation values
            if (name === 'expectation') {
                if (!value.match(/^\d*$/)) {
                    return;
                }

                if (value.length > 12) {
                    return;
                }
            }

            const newFormValues = {
                ...formValues,
                [name]: value
            };

            setFormValues(newFormValues);

            saveCandidateDebounce(newFormValues);
        },
        [formValues, setFormValues, saveCandidateDebounce]
    );

    const onSubmit = React.useCallback(
        (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            saveCandidate(formValues);
        },
        [formValues, saveCandidate]
    );

    const expectationLabelText = <FormattedMessage id="CANDIDATE_SALARY_EXPECATION.FIELD_EXPECTION.LABEL" />;

    return (
        <form onSubmit={onSubmit}>
            <FormFieldGroup labelText={expectationLabelText} className="CandidateSalaryExpecation__field-group">
                <FormFieldGroupItem primary id="candidate-salary-expectation" labelText={expectationLabelText}>
                    <Input name="expectation" value={formValues.expectation} onChange={onChange} />
                </FormFieldGroupItem>
                <FormFieldGroupItem id="candidate-salary-scale">
                    <Select
                        name="scale"
                        value={formValues.scale}
                        onChange={onChange}
                        className="CandidateSalaryExpectation__field-scale"
                    >
                        <option value={CandidateSalaryExpectationScale.Year}>
                            {intl.formatMessage({
                                id: 'CANDIDATE_SALARY_EXPECATION.FIELD_SCALE.OPTION_YEAR.LABEL_TEXT'
                            })}
                        </option>
                        <option value={CandidateSalaryExpectationScale.Month}>
                            {intl.formatMessage({
                                id: 'CANDIDATE_SALARY_EXPECATION.FIELD_SCALE.OPTION_MONTH.LABEL_TEXT'
                            })}
                        </option>
                        <option value={CandidateSalaryExpectationScale.Hour}>
                            {intl.formatMessage({
                                id: 'CANDIDATE_SALARY_EXPECATION.FIELD_SCALE.OPTION_HOUR.LABEL_TEXT'
                            })}
                        </option>
                    </Select>
                </FormFieldGroupItem>
            </FormFieldGroup>
        </form>
    );
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => ({
    candidate: getCandidate(state, props.candidateId)
});

const mapDispatchToProps = {
    updateCandidate
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateSalaryExpecation);

const initialFormValues: FormValues = {
    scale: CandidateSalaryExpectationScale.Year,
    expectation: ''
};

function useFormValues(candidate: Candidate | null) {
    const [formValues, setFormValues] = React.useState<FormValues | null>(null);

    React.useEffect(() => {
        if (!!formValues) {
            return;
        }

        if (!candidate) {
            return;
        }

        const { salary_expectation: salaryExpectation } = candidate;

        const scale: CandidateSalaryExpectationScale = !!salaryExpectation.salary_scale
            ? salaryExpectation.salary_scale
            : initialFormValues.scale;

        const expectation: string = !!salaryExpectation.salary_expectation
            ? new Big(salaryExpectation.salary_expectation).toFixed(0)
            : initialFormValues.expectation;

        setFormValues({
            scale,
            expectation
        });
    }, [candidate, setFormValues]);

    return {
        formValues: formValues || initialFormValues,
        setFormValues
    };
}
