import { Applicant } from './applicant';

export type CandidateId = number;

type CandidateJob = {
    id: number;
    title: string;
    locality: string;
};

export type CandidateState =
    | 'new'
    | 'locked'
    | 'interesting'
    | 'not-interesting'
    | 'phone-interview'
    | 'interview'
    | 'hired';

export type CandidateRating = null | 1 | 2 | 3;

export enum CandidateSalaryExpectationScale {
    Year = 'yr',
    Month = 'mo',
    Hour = 'hr'
}

export type CandidateSalaryExpectation = {
    salary_expectation: string;
    salary_scale: CandidateSalaryExpectationScale;
};

export enum CandidateAvailabilitySlug {
    Date = 'date',
    Now = 'now',
    TwoWeeks = '2-weeks',
    OneMonths = '1-months',
    TwoMonths = '2-months',
    ThreeMonths = '3-months',
    SixMonths = '6-months'
}

export type CandidateAvailability = {
    slug: CandidateAvailabilitySlug | '';
    date: string | null;
};

export type Candidate = {
    id: CandidateId;

    job: CandidateJob;

    state: CandidateState;

    rating_by_recruiter: CandidateRating;

    applicant: Applicant;
    application_message: string;

    notes: string;

    resume_pdf_url: string;

    created: string;

    /**
     * This is a date in the format `YYYY-MM-DD`.
     */
    expiration_date: string;

    conversation_id: number;

    backstage_applicant_url: null | string;

    unread_message_count: number;

    internal: boolean;

    salary_expectation: CandidateSalaryExpectation;
    availability: CandidateAvailability;
};
