import { ParsedQs } from 'qs';
import type { ParsedUrlQuery } from 'querystring';
import { isEmail } from 'validator';
import { createRouteString } from './utils';
import { AutomaticRequestTokenRequestPageReason } from '../containers/AutomaticRequestTokenRequestPage/AutomaticRequestTokenRequestPage.constants';
import { isAutomaticRequestTokenRequestPageReason } from '../containers/AutomaticRequestTokenRequestPage/AutomaticRequestTokenRequestPage.utils';

export const AUTOMATIC_REQUEST_TOKEN_REQUEST_PATH_PATTERN = '/auto-magic-link';

export type GetAutomaticRequestTokenRequestRouteProps = {
    email: string;
    destinationUrl?: string;
    reason?: AutomaticRequestTokenRequestPageReason;
};

export type AutomaticRequestTokenRequestRouteQuery = {
    email?: string;
    destinationUrl?: string;
    reason?: AutomaticRequestTokenRequestPageReason;
};

export type AutomaticRequestTokenRequestRouteRawQuery = {
    email: string;
    'destination-url'?: string;
    reason?: string;
};

export function getAutomaticRequestTokenRequestRoute({
    email,
    reason,
    destinationUrl
}: GetAutomaticRequestTokenRequestRouteProps): string {
    const rawQuery: AutomaticRequestTokenRequestRouteRawQuery = {
        email
    };

    if (typeof destinationUrl !== 'undefined') {
        rawQuery['destination-url'] = destinationUrl;
    }

    if (typeof reason !== 'undefined') {
        rawQuery.reason = reason;
    }

    return createRouteString(AUTOMATIC_REQUEST_TOKEN_REQUEST_PATH_PATTERN, undefined, rawQuery);
}

export function parseAutomaticRequestTokenRequestRouteQuery(
    rawQuery: AutomaticRequestTokenRequestRouteRawQuery | ParsedUrlQuery | ParsedQs
): AutomaticRequestTokenRequestRouteQuery {
    const query: AutomaticRequestTokenRequestRouteQuery = {};

    if (typeof rawQuery.email === 'string' && isEmail(rawQuery.email)) {
        query.email = rawQuery.email;
    }

    if (typeof rawQuery.reason === 'string' && isAutomaticRequestTokenRequestPageReason(rawQuery.reason)) {
        query.reason = rawQuery.reason;
    }

    if (typeof rawQuery['destination-url'] === 'string') {
        query.destinationUrl = rawQuery['destination-url'];
    }

    return query;
}
