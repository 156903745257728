import * as React from 'react';
import classNames from 'classnames';

import './style.scss';

type Props = {
    title: React.ReactNode;
    subtitle?: string;
    greenTitle?: boolean;
};

const MobileAppNavBarTitle = (props: Props): React.ReactElement => {
    return (
        <div className="MobileAppNavBarTitle">
            <div
                className={classNames('MobileAppNavBarTitle__title', {
                    'MobileAppNavBarTitle__title--green': props.greenTitle
                })}
            >
                {props.title}
            </div>
            <div className="MobileAppNavBarTitle__subtitle">{props.subtitle}</div>
        </div>
    );
};

export default MobileAppNavBarTitle;
