import { combineReducers } from 'redux';

import { createMapReducer } from '../../../utils/redux';

import { FILES_REQUEST, RESET_FILES_REQUEST } from '../actions';

import { CreateStatus, Action } from '../../types';

export type CreateStatusState = CreateStatus;
export type ErrorsState = null | any;

export type FileRequestsState = {
    errors: ErrorsState;
    createStatus: CreateStatusState;
};

export type FileRequestsByCandidateIdListState = {
    [id: number]: FileRequestsState;
};

export const initialFileRequestsListState: FileRequestsState = {
    errors: null,
    createStatus: 'none'
};

export const errorsReducer = (
    state: ErrorsState = initialFileRequestsListState.errors,
    action: Action
): ErrorsState => {
    switch (action.type) {
        case FILES_REQUEST.SUCCESS:
            return null;

        case FILES_REQUEST.FAILURE:
            return action.payload.errors;

        case RESET_FILES_REQUEST:
            return initialFileRequestsListState.errors;

        default:
            return state;
    }
};

export const createStatusReducer = (
    state: CreateStatusState = initialFileRequestsListState.createStatus,
    action: Action
): CreateStatusState => {
    switch (action.type) {
        case FILES_REQUEST.REQUEST:
            return 'creating';

        case FILES_REQUEST.SUCCESS:
            return 'created';

        case FILES_REQUEST.FAILURE:
            return 'failed';

        case RESET_FILES_REQUEST:
            return initialFileRequestsListState.createStatus;

        default:
            return state;
    }
};

export const fileRequestsReducer = combineReducers({
    errors: errorsReducer,
    createStatus: createStatusReducer
});

function getMapKey(action) {
    if (typeof action.payload !== 'object' || typeof action.payload.candidateId !== 'number') {
        return undefined;
    }

    return action.payload.candidateId;
}

export default createMapReducer(fileRequestsReducer, getMapKey);
