import React from 'react';

import './JobFormPageLayout.scss';

export type JobFormPageLayoutProps = {
    children: React.ReactNode;
};

function JobFormPageLayout({ children }: JobFormPageLayoutProps) {
    return <div className="JobFormPageLayout container">{children}</div>;
}

export default JobFormPageLayout;
