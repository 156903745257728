import * as React from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';

import './DragLayer.style.scss';
import { DragObject } from './types';

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none'
        };
    }

    const { x, y } = currentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform
    };
}

type Props = {
    type: string;
};

function DragLayer({ type }: Props): React.ReactElement | null {
    const { itemType, isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem() as DragObject,
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging()
    }));

    if (!isDragging) {
        return null;
    }

    let preview: React.ReactNode = null;
    if (itemType === type) {
        const currentTarget = item.ref.current as HTMLElement;

        const { width, height } = currentTarget.getBoundingClientRect();

        preview = <div style={{ width, height }}>{item.content}</div>;
    }

    const style = getItemStyles(initialOffset, currentOffset);

    return (
        <div className="LayerStyles">
            <div className="LayerStyles__visible" style={style}>
                {preview}
            </div>
        </div>
    );
}
export default DragLayer;
