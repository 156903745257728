import * as React from 'react';

import logoAcademicWork from '../../../assets/images/signup/reference-companies/logo-academic-work.png';
import logoAcademicWork2 from '../../../assets/images/signup/reference-companies/logo-academic-work@2x.png';
import logoAcademicWork3 from '../../../assets/images/signup/reference-companies/logo-academic-work@3x.png';
import logoDeliveryHero from '../../../assets/images/signup/reference-companies/logo-delivery-hero.png';
import logoDeliveryHero2 from '../../../assets/images/signup/reference-companies/logo-delivery-hero@2x.png';
import logoDeliveryHero3 from '../../../assets/images/signup/reference-companies/logo-delivery-hero@3x.png';
import logoVodafone from '../../../assets/images/signup/reference-companies/logo-vodafone.png';
import logoVodafone2 from '../../../assets/images/signup/reference-companies/logo-vodafone@2x.png';
import logoVodafone3 from '../../../assets/images/signup/reference-companies/logo-vodafone@3x.png';
import logoPaypal from '../../../assets/images/signup/reference-companies/logo-paypal.png';
import logoPaypal2 from '../../../assets/images/signup/reference-companies/logo-paypal@2x.png';
import logoPaypal3 from '../../../assets/images/signup/reference-companies/logo-paypal@3x.png';
import logoCaroobi from '../../../assets/images/signup/reference-companies/logo-caroobi.png';
import logoCaroobi2 from '../../../assets/images/signup/reference-companies/logo-caroobi@2x.png';
import logoCaroobi3 from '../../../assets/images/signup/reference-companies/logo-caroobi@3x.png';

import './SignupPageReferenceCompanies.style.scss';

function SignupPageReferenceCompanies() {
    return (
        <div className="SignupPageReferenceCompanies">
            <div className="SignupPageReferenceCompanies__title-container">
                <div className="SignupPageReferenceCompanies__title">Truffls References</div>
            </div>

            <div className="SignupPageReferenceCompanies__content">
                <div
                    className="SignupPageReferenceCompanies__item"
                    style={{ backgroundImage: `url(${logoAcademicWork})` }}
                >
                    <img
                        src={logoAcademicWork}
                        srcSet={`
                            ${logoAcademicWork2} 2x,
                            ${logoAcademicWork3} 3x
                        `}
                        role="presentation"
                        className="SignupPageReferenceCompanies__company-image"
                    />
                </div>
                <div
                    className="SignupPageReferenceCompanies__item"
                    style={{ backgroundImage: `url(${logoDeliveryHero})` }}
                >
                    <img
                        src={logoDeliveryHero}
                        srcSet={`
                            ${logoDeliveryHero2} 2x,
                            ${logoDeliveryHero3} 3x
                        `}
                        role="presentation"
                        className="SignupPageReferenceCompanies__company-image"
                    />
                </div>
                <div className="SignupPageReferenceCompanies__item" style={{ backgroundImage: `url(${logoVodafone})` }}>
                    <img
                        src={logoVodafone}
                        srcSet={`
                            ${logoVodafone2} 2x,
                            ${logoVodafone3} 3x
                        `}
                        role="presentation"
                        className="SignupPageReferenceCompanies__company-image"
                    />
                </div>
                <div className="SignupPageReferenceCompanies__item" style={{ backgroundImage: `url(${logoPaypal})` }}>
                    <img
                        src={logoPaypal}
                        srcSet={`
                            ${logoPaypal2} 2x,
                            ${logoPaypal3} 3x
                        `}
                        role="presentation"
                        className="SignupPageReferenceCompanies__company-image"
                    />
                </div>
                <div className="SignupPageReferenceCompanies__item" style={{ backgroundImage: `url(${logoCaroobi})` }}>
                    <img
                        src={logoCaroobi}
                        srcSet={`
                            ${logoCaroobi2} 2x,
                            ${logoCaroobi3} 3x
                        `}
                        role="presentation"
                        className="SignupPageReferenceCompanies__company-image"
                    />
                </div>
            </div>
        </div>
    );
}

export default SignupPageReferenceCompanies;
