import React from 'react';
import { AuthenticationState } from '../AuthenticationState/AuthenticationState.constants';
import LoadingPage from '../LoadingPage';
import NotFoundPage from '../NotFoundPage';
import { AutomaticRequestTokenRequestPageReason } from './AutomaticRequestTokenRequestPage.constants';
import { AutomaticRequestTokenRequestPageContext } from './AutomaticRequestTokenRequestPageContext';

export type AutomaticRequestTokenRequestPageProps = {
    state: AuthenticationState;
    email?: string;
    reason?: AutomaticRequestTokenRequestPageReason;
    destinationUrl?: string;
};

export function AutomaticRequestTokenRequestPage({
    state,
    email,
    reason,
    destinationUrl
}: AutomaticRequestTokenRequestPageProps) {
    const {
        AutomaticRequestTokenAnonymousRequestPageContainer,
        AutomaticRequestTokenAuthenticatedRequestPageContainer
    } = React.useContext(AutomaticRequestTokenRequestPageContext);

    if (state === null) {
        return <LoadingPage />;
    }

    if (!email) {
        return <NotFoundPage />;
    }

    if (state === 'authenticated') {
        return <AutomaticRequestTokenAuthenticatedRequestPageContainer destinationUrl={destinationUrl} />;
    }

    return <AutomaticRequestTokenAnonymousRequestPageContainer email={email} reason={reason} />;
}
