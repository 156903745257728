import { useCallback, useState } from 'react';

export type UseIsOpenResult = {
    isOpen: boolean;
    open: () => void;
    close: () => void;
    toggle: () => void;
};

export function useIsOpen(initialIsOpen: boolean = false): UseIsOpenResult {
    const [isOpen, setIsOpen] = useState<boolean>(initialIsOpen);

    const open = useCallback(() => {
        setIsOpen(true);
    }, []);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    const toggle = useCallback(() => {
        isOpen ? close() : open();
    }, [isOpen]);

    return {
        isOpen,
        open,
        close,
        toggle
    };
}
