import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import emptyStateIllustration from '../../assets/images/chat-empty-state-illustration.png';

import './MessageListEmptyState.style.scss';

const MessageListEmptyState = () => (
    <div className="MessageListEmptyState">
        <img
            className="MessageListEmptyState__illustration"
            src={emptyStateIllustration}
            alt={'Mobile phone with a chat bubble that says "Hi! :)"'}
        />

        <h3 className="MessageListEmptyState__title">
            <FormattedMessage id="CANDIDATE_CONVERSATION_PAGE.EMPTY_STATE.TITLE" />
        </h3>
        <p className="MessageListEmptyState__paragraph">
            <FormattedMessage id="CANDIDATE_CONVERSATION_PAGE.EMPTY_STATE.PARAGRAPH" />
        </p>
    </div>
);

export default MessageListEmptyState;
