import * as React from 'react';
import { FormattedMessage } from 'react-intl';

type Props = {
    onClick: (event: React.MouseEvent) => void;
};

function SearchBarFormOpenIcon({ onClick }: Props) {
    return (
        <button className="SearchBarForm__open-button" type="button" onClick={onClick}>
            <span className="sr-only">
                <FormattedMessage id="SEARCH_BAR_FORM.OPEN_ICON" />
            </span>
            <svg className="SearchBarForm__open-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path
                    fillRule="evenodd"
                    d="M21.5312,19.2728 L17.8152,15.5568 C20.1593884,12.0477317 19.4594182,7.33273405 16.1969119,4.65601367 C12.9344056,1.9792933 8.17351216,2.21393036 5.1900173,5.19848021 C2.20652243,8.18303006 1.97356857,12.9440062 4.65144222,16.2055659 C7.32931586,19.4671257 12.0445607,20.1654288 15.5528,17.82 L19.2688,21.5352 C19.8980378,22.1490009 20.9019622,22.1490009 21.5312,21.5352 C22.1558112,20.9104003 22.1558112,19.8975998 21.5312,19.2728 Z M11,5.20399997 C14.2032516,5.20399997 16.8,7.80074843 16.8,11.004 C16.8,14.2072516 14.2032516,16.804 11,16.804 C7.79674843,16.804 5.19999997,14.2072516 5.19999997,11.004 C5.20352714,7.80221057 7.79821057,5.20752714 11,5.20399997 Z"
                />
            </svg>
        </button>
    );
}

export default SearchBarFormOpenIcon;
