import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import { history } from '../../history';
import { IntlManagerProvider } from '../../i18n/IntlManagerProvider';
import { store } from '../../store';

export type ApplicationProvidersProps = {
    children?: React.ReactNode;
};

function ApplicationProviders({ children }: ApplicationProvidersProps) {
    return (
        <Provider store={store}>
            <IntlManagerProvider>
                <Router history={history}>{children}</Router>
            </IntlManagerProvider>
        </Provider>
    );
}

export default ApplicationProviders;
