import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getProductNameMessageId, isProductWithProductName } from '../../modules/checkout/products';

export type ProductTypeProps = {
    product: string | null;
};

function ProductType({ product }: ProductTypeProps) {
    if (!product || !isProductWithProductName(product)) {
        return null;
    }

    return <FormattedMessage id={getProductNameMessageId(product)} />;
}

export default ProductType;
