import * as React from 'react';

import './TutorialDesktopBigArrow.styles.scss';

import arrowIcon from '../../../../assets/images/ic-keyboard-arrow.svg';

type Props = {
    onClick: () => void;
    typeStyle: 'left' | 'right';
};

const TutorialDesktopBigArrow = ({ onClick, typeStyle }: Props) => (
    <button
        data-testid={`big-arrow-button-${typeStyle}`}
        className={`TutorialDesktopBigArrow TutorialDesktopBigArrow--${typeStyle}`}
        onClick={onClick}
    >
        <img src={arrowIcon} alt={`Direction arrow ${typeStyle}`} />
    </button>
);

export default TutorialDesktopBigArrow;
