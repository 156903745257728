import React from 'react';
import { FieldValidator } from 'final-form';
import { isRequired } from '../validators';
import { isValidator } from './utils';
import useComposeValidators from './useComposeValidators';

type UseValidateProps<FieldValue> = {
    validate?: FieldValidator<FieldValue>;
    required?: boolean;
};

export default function useValidate<FieldValue>({
    validate,
    required
}: UseValidateProps<FieldValue>): FieldValidator<FieldValue> {
    const validators = React.useMemo(() => {
        const validators: FieldValidator<FieldValue>[] = [
            required ? isRequired : null,
            validate
        ].filter((validator): validator is FieldValidator<FieldValue> => isValidator(validator));

        return validators;
    }, [required, validate]);

    return useComposeValidators(validators);
}
