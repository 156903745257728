import { put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { isResponseError } from '../../services/api/utils';

import * as userActions from './actions';
import * as usersApi from './api';

import { User } from './types';

export function* fetchUser(): SagaIterator<User> {
    try {
        yield put(userActions.userFetch.request());

        const response = yield call(usersApi.fetchUser);
        const user: User = response.data;

        yield put(userActions.userFetch.success(user));

        return user;
    } catch (error) {
        if (isResponseError(error)) {
            yield put(userActions.userFetch.failure(error.response.data.errors));
        } else {
            yield put(userActions.userFetch.failure());
        }

        throw error;
    }
}
