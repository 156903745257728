import { combineReducers } from 'redux';
// TODO Should we make some types globally available?
import { Conversation, Message } from '../../conversations/types';
import { CreateStatus, DeleteStatus, Errors, FetchStatus, UpdateStatus } from '../../types';
import candidatesReducer, { CandidateEntityTypeState } from './candidates';
import checkoutProcessStatesReducer, { CheckoutProcessStateEntityTypeState } from './checkoutProcessStates';
import conversationsReducer from './conversations';
import filesReducer, { FileEntityTypeState } from './files';
import jobsReducer, { JobEntityTypeState } from './jobs';
import messagesReducer from './messages';
import packagesReducer, { PackageEntityTypeState } from './packages';
import searchResultsReducer, { SearchResultEntityTypeState } from './searchResults';
import templatesReducer, { TemplateEntityTypeState } from './templates';

// We want to mount our reducer here
export const MOUNT: 'entities' = 'entities';

export type EntityTypeEntitesState<T> = {
    [id in number | string]: T;
};

export type EntityTypeFetchStatusState = {
    [id in number | string]: FetchStatus;
};

export type EntityTypeCreateStatusState = {
    [id in number | string]: CreateStatus;
};

export type EntityTypeUpdateStatusState = {
    [id in number | string]: UpdateStatus;
};

export type EntityTypeDeleteStatusState = {
    [id in number | string]: DeleteStatus;
};

export type EntityTypeErrorsState = {
    [id in number | string]: Errors | null;
};

export type EntityTypeStateEntitiesPartial<T> = {
    entities: EntityTypeEntitesState<T>;
};
export type EntityTypeStateFetchStatusPartial = {
    fetchStatus: EntityTypeFetchStatusState;
};
export type EntityTypeStateFetchErrorsPartial = {
    fetchErrors: EntityTypeErrorsState;
};
export type EntityTypeStateCreateStatusPartial = {
    createStatus: EntityTypeCreateStatusState;
};
export type EntityTypeStateCreateErrorsPartial = {
    createErrors: EntityTypeErrorsState;
};
export type EntityTypeStateUpdateStatusPartial = {
    updateStatus: EntityTypeUpdateStatusState;
};
export type EntityTypeStateUpdateErrorsPartial = {
    updateErrors: EntityTypeErrorsState;
};
export type EntityTypeStateDeleteStatusPartial = {
    deleteStatus: EntityTypeDeleteStatusState;
};
export type EntityTypeStateErrorsPartial = {
    errors: EntityTypeErrorsState;
};

/**
 * @deprecated This type is deprecated and it should be avoided to use it. Instead use the partials to create the
 *  individual state type defintions in the reducers. This allows to have some more flexibility.
 */
export type EntityTypeState<T> = EntityTypeStateEntitiesPartial<T> &
    EntityTypeStateFetchStatusPartial &
    EntityTypeStateCreateStatusPartial &
    EntityTypeStateUpdateStatusPartial &
    EntityTypeStateDeleteStatusPartial &
    EntityTypeStateErrorsPartial;

export type EntitiesState = {
    jobs: JobEntityTypeState;
    candidates: CandidateEntityTypeState;
    files: FileEntityTypeState;
    checkoutProcessStates: CheckoutProcessStateEntityTypeState;
    packages: PackageEntityTypeState;
    searchResults: SearchResultEntityTypeState;
    templates: TemplateEntityTypeState;

    conversations: EntityTypeEntitesState<Conversation>;
    messages: EntityTypeEntitesState<Message>;
};

export default combineReducers({
    jobs: jobsReducer,
    candidates: candidatesReducer,
    conversations: conversationsReducer,
    messages: messagesReducer,
    files: filesReducer,
    checkoutProcessStates: checkoutProcessStatesReducer,
    packages: packagesReducer,
    searchResults: searchResultsReducer,
    templates: templatesReducer
});
