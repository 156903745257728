import { AxiosResponse } from 'axios';
import { STATUS_REQUESTING, STATUS_REQUESTED, STATUS_FAILED } from '../constants';

export function extractApiTokenFromResponse(response: AxiosResponse<{ api_token?: string }>): string {
    const data = response.data;

    if (!data.api_token) {
        throw new Error("Response data doesn't contain an api token");
    }

    return data.api_token;
}

export const isRequestTokenRequestRequesting = (status: string) => status === STATUS_REQUESTING;
export const isRequestTokenRequestSucceed = (status: string) => status === STATUS_REQUESTED;
export const isRequestTokenRequestFailure = (status: string) => status === STATUS_FAILED;
