import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Locale } from '../../i18n';
import { logout } from '../../modules/authentication/actions';
import { setLocale } from '../../modules/i18n/actions';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { getBackPathname, getJobsRoute, getLoginRoute, hasBackRoute } from '../../routes';
import { goBackOrFallback } from '../../utils/history';
import { isNavigationVisible } from '../../modules/ui/selectors';

import { openTutorial } from '../Tutorial/redux/actions';

import NavigationActions from './NavigationActions';

import { NavigationShell } from './NavigationShell';

export type NavigationProps = {};

function Navigation({}: NavigationProps) {
    const history = useHistory();
    const location = useLocation();

    const dispatch = useDispatch();
    const navigationVisible = useSelector(isNavigationVisible);
    const recruiter = useSelector(getRecruiter);

    const handleClickBack = () => {
        const backPathname = getBackPathname(history.location.pathname);

        if (!!backPathname) {
            // Hierarchical hardcoded back behaviour
            history.push(backPathname);
        } else {
            // Browser's default back + fallback
            let fallbackPath = getLoginRoute();
            if (!!recruiter) {
                fallbackPath = getJobsRoute();
            }

            goBackOrFallback(history.push, fallbackPath);
        }
    };

    const handleClickLogout = () => {
        dispatch(logout());
    };

    const handleChangeLanguage = (language: Locale) => {
        dispatch(setLocale(language));
    };

    const handleClickTutorial = () => {
        dispatch(openTutorial());
    };

    const showBack = hasBackRoute(location.pathname);

    if (!navigationVisible) {
        return null;
    }

    return (
        <NavigationShell showBack={showBack} onClickBack={handleClickBack}>
            <NavigationActions
                recruiter={recruiter}
                onClickLogout={handleClickLogout}
                onChangeLanguage={handleChangeLanguage}
                onClickTutorial={handleClickTutorial}
            />
        </NavigationShell>
    );
}

export default Navigation;
