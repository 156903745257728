import axios, { AxiosRequestConfig } from 'axios';

import appConfig from '../../config';

import makeAuthorizationTokenRequestInterceptor from './interceptors/authorizationToken';
import * as methodOverride from './interceptors/methodOverride';
import makeApiTokenRefresherResponseInterceptor from './interceptors/apiTokenRefresher';

import * as apiTokenStorage from '../apiTokenStorage';

export function createClient(config: AxiosRequestConfig = {}) {
    const client = axios.create(config);

    client.defaults.headers.common = {
        ...(client.defaults.headers.common || {}),

        'x-truffls-application': 'dashboard',
        'x-truffls-version': appConfig.appVersion,
        'x-truffls-environment': appConfig.appEnvironment
    };

    /*
     * Register request interceptors
     */
    client.interceptors.request.use(makeAuthorizationTokenRequestInterceptor(() => apiTokenStorage.get()));
    client.interceptors.request.use(methodOverride.onRequestFulfilled);

    /*
     * Register response interceptors
     */
    client.interceptors.response.use(methodOverride.onResponseFulfilled, methodOverride.onResponseRejected);
    client.interceptors.response.use(
        makeApiTokenRefresherResponseInterceptor(() => apiTokenStorage.updateExpiration())
    );

    return client;
}
