import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import './ResumeLanguages.style.scss';

import CandidateResumeSection from '../CandidateResumeSection';

import { Applicant } from '../../../modules/candidates/types';

type Props = {
    applicant: Applicant;
};

const ResumeLanguages = ({ applicant }: Props) => {
    const { resume } = applicant;
    const languages = resume.languages;

    if (languages.length === 0) {
        return null;
    }

    return (
        <CandidateResumeSection>
            <h1>
                <FormattedMessage id="RESUME_LANGUAGES.TITLE" />
            </h1>

            <ul>
                {languages.map((language, index) => (
                    <ResumeLanguagesListItem key={index} language={language} />
                ))}
            </ul>
        </CandidateResumeSection>
    );
};

const ResumeLanguagesListItem = ({ language }) => {
    return (
        <li className="ResumeLanguages__list-item">
            <span className="ResumeLanguages__list-item__language">{language.language}</span>
            <strong className="ResumeLanguages__list-item__level">
                <FormattedMessage id={`CONSTANT.LANGUAGE_QUALITY.${language.quality}`} />
            </strong>
        </li>
    );
};

export default ResumeLanguages;
