import { all, fork, take, call, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { fetchUser } from '../users/saga';
import { ACCEPT_AGREEMENT_CONTRACTS, agreementContractAcceptance } from './actions';
import { isResponseError } from '../../services/api/utils';
import * as agreementsApi from './api';

import { AgreementContract } from './types';

export function* acceptAgreementContractSaga(agreementContract: AgreementContract): SagaIterator<void> {
    try {
        yield put(agreementContractAcceptance.request(agreementContract));

        yield call(agreementsApi.acceptAgreementContract, agreementContract);

        yield put(agreementContractAcceptance.success(agreementContract));
    } catch (error) {
        if (isResponseError(error)) {
            yield put(agreementContractAcceptance.failure(agreementContract, error.response.data.errors));
        } else {
            yield put(agreementContractAcceptance.failure(agreementContract));
        }
    }
}

export function* acceptAgreementContractsSaga(agreementContracts: AgreementContract[]): SagaIterator<void> {
    const tasks = agreementContracts.map((agreementContract) => {
        return call(acceptAgreementContractSaga, agreementContract);
    });

    yield all(tasks);
    yield call(fetchUser);
}

export function* watchAcceptAgreementContractsSaga(): SagaIterator<void> {
    while (true) {
        const action = yield take(ACCEPT_AGREEMENT_CONTRACTS);
        const payload = action.payload;

        yield call(acceptAgreementContractsSaga, payload.agreementContracts);
    }
}

export default function* rootSaga(): SagaIterator<void> {
    yield all([fork(watchAcceptAgreementContractsSaga)]);
}
