import React from 'react';
import { useLocation } from 'react-router';
import { parseSearch, ParsedSearch } from '../utils/url';

type UseQueryOptions<Type extends {}> = {
    // TODO: Define correct type definitions for the parse function so that the parse functions knows which type has to be returned for each key
    parse: (key: keyof Type, value: ParsedSearch['']) => Type[keyof Type];
};

function useQuery(): ParsedSearch;
function useQuery<Type extends {}>(options: UseQueryOptions<Type>): Type;
function useQuery<Type extends {} = ParsedSearch>(options: Partial<UseQueryOptions<Type>> = {}) {
    const location = useLocation();

    const rawParsedSearch = React.useMemo(() => {
        return parseSearch<{ [key in keyof Type]: ParsedSearch[''] }>(location.search);
    }, [location.search]);

    const parse = options.parse;
    if (typeof parse !== 'function') {
        return rawParsedSearch;
    }

    const keys = Object.keys(rawParsedSearch) as Array<keyof Type>;

    const parsesSearch: Partial<Type> = {};

    keys.forEach((key) => {
        parsesSearch[key] = parse(key, rawParsedSearch[key]);
    });

    return parsesSearch as Type;
}

export default useQuery;
