import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Checkbox } from '@truffls/design-system-react';
import { useFormState } from 'react-final-form';
import { getIn, FieldValidator } from 'final-form';
import { SECTORS } from '../../../../modules/jobs/constants';
import { Field, useComposeValidators } from '../../../../utils/form/react-final-form';
import useField from '../../../../utils/form/react-final-form/useField';
import { createArrayMinLength, createArrayMaxLength } from '../../../../utils/form/validators';
import { onJobFormPageValidationLevel } from '../../../JobFormPage/JobFormPage.utils';
import { useJobFormPageHasError } from '../../../JobFormPage/JobFormPage.hooks';
import JobFormFieldAnchor from '../../JobFormFieldAnchor';

export type JobFormSectorsFormFieldsValue = string[];
export type JobFormSectorsFormFieldsName = 'sectors';
export const JOB_EDIT_SECTORS_FORM_FIELDS_NAME: JobFormSectorsFormFieldsName = 'sectors';

const MIN_LENGTH = 1;
const MAX_LENGTH = 3;

const minLengthValidator = onJobFormPageValidationLevel<JobFormSectorsFormFieldsValue>(
    'full',
    createArrayMinLength(MIN_LENGTH) as FieldValidator<JobFormSectorsFormFieldsValue>
);
const maxLengthValidator = createArrayMaxLength(MAX_LENGTH) as FieldValidator<JobFormSectorsFormFieldsValue>;
const validators = [minLengthValidator, maxLengthValidator];

export type JobFormSectorsFormFieldsProps = {};

function JobFormSectorsFormFields({}: JobFormSectorsFormFieldsProps) {
    const validate = useComposeValidators(validators);

    const { meta } = useField<JobFormSectorsFormFieldsValue, HTMLInputElement>(JOB_EDIT_SECTORS_FORM_FIELDS_NAME, {
        type: 'checkbox',
        validate
    });
    const { values: allValues } = useFormState({
        subscription: {
            values: true
        }
    });

    const selectedValues = getIn(allValues, JOB_EDIT_SECTORS_FORM_FIELDS_NAME) || [];
    const shouldDisable = selectedValues.length >= MAX_LENGTH;

    const hasError = useJobFormPageHasError();

    return (
        <React.Fragment>
            <JobFormFieldAnchor name={JOB_EDIT_SECTORS_FORM_FIELDS_NAME} />
            <div
                className={classNames('tf-form-field', {
                    'tf-has-error': hasError(selectedValues, meta)
                })}
            >
                <label className="control-label">
                    <FormattedMessage id="JOB_EDIT.SECTORS_FORM_FIELDS.LABEL_TEXT" />
                </label>

                <span className="help-block">
                    <FormattedMessage id="JOB_EDIT.SECTORS_FORM_FIELDS.HELP_TEXT" />
                </span>

                <div className="row">
                    {SECTORS.map((sector) => {
                        const disabled = shouldDisable && !selectedValues.includes(sector);

                        return (
                            <Field
                                key={sector}
                                name={JOB_EDIT_SECTORS_FORM_FIELDS_NAME}
                                type="checkbox"
                                value={sector}
                                disabled={disabled}
                            >
                                {({ input, disabled }) => {
                                    return (
                                        <div className="col-sm-6 col-md-6 col-lg-4">
                                            <Checkbox
                                                id={`job-form-field-input-sectors-${sector}`}
                                                {...input}
                                                disabled={disabled}
                                            >
                                                <FormattedMessage id={`CONSTANT.SECTOR.${sector}`} />
                                            </Checkbox>
                                        </div>
                                    );
                                }}
                            </Field>
                        );
                    })}
                </div>
            </div>
        </React.Fragment>
    );
}

export default JobFormSectorsFormFields;
