import { createDeferred } from './promise';

type QueueItem<DataType = {}, ResultType = {}> = {
    data: DataType;
    deferred: {
        resolve: (result: ResultType) => void;
        reject: (error: unknown) => void;
    };
};

export function createQueue<DataType = {}, ResultType = {}>({
    processData
}: {
    processData: (data: DataType) => ResultType | Promise<ResultType>;
}) {
    let running = false;
    const queue: QueueItem<DataType, ResultType>[] = [];

    const runTask = async (item: QueueItem<DataType, ResultType>) => {
        running = true;

        await makeAsync();

        try {
            const result = await processData(item.data);
            item.deferred.resolve(result);
        } catch (error) {
            item.deferred.reject(error);
        }

        running = false;

        if (queue.length > 0) {
            runTask(queue.shift() as QueueItem<DataType, ResultType>);
        }
    };

    const enqueueTask = (item: QueueItem<DataType, ResultType>) => {
        queue.push(item);
    };

    const push = (data: DataType) => {
        const deferred = createDeferred<ResultType>();

        const item: QueueItem<DataType, ResultType> = {
            data,
            deferred
        };

        if (running) {
            enqueueTask(item);
        } else {
            runTask(item);
        }

        return deferred.promise;
    };

    return {
        push
    };
}

function makeAsync() {
    return new Promise((resolve) => {
        setTimeout(resolve);
    });
}
