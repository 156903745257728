import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillDisplayNames } from '@formatjs/intl-displaynames/should-polyfill';
import { shouldPolyfill as shouldPolyfillListFormat } from '@formatjs/intl-listformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillDateTimeFormat } from '@formatjs/intl-datetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from '@formatjs/intl-relativetimeformat/should-polyfill';
import { shouldPolyfill as shouldPolyfilDurationFormat } from '@formatjs/intl-durationformat/should-polyfill';

/**
 * Dynamically polyfill Intl API & its locale data
 */
export async function polyfill() {
    const dataPolyfills = [];

    if (shouldPolyfillGetCanonicalLocales()) {
        await import(/* webpackChunkName: "intl-getcanonicallocales" */ '@formatjs/intl-getcanonicallocales/polyfill');
    }

    if (shouldPolyfillLocale()) {
        await import(/* webpackChunkName: "intl-locale" */ '@formatjs/intl-locale/polyfill');
    }

    if (shouldPolyfillDisplayNames()) {
        await import(/* webpackChunkName: "intl-displaynames" */ '@formatjs/intl-displaynames/polyfill');
    }

    if (Intl.DisplayNames.polyfilled) {
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-displaynames" */ '@formatjs/intl-displaynames/locale-data/de')
        );
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-displaynames" */ '@formatjs/intl-displaynames/locale-data/en')
        );
    }

    if (shouldPolyfillListFormat()) {
        await import(/* webpackChunkName: "intl-listformat" */ '@formatjs/intl-listformat/polyfill');
    }

    if (Intl.ListFormat.polyfilled) {
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-listformat" */ '@formatjs/intl-listformat/locale-data/de')
        );
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-listformat" */ '@formatjs/intl-listformat/locale-data/en')
        );
    }

    if (shouldPolyfillPluralRules()) {
        await import(/* webpackChunkName: "intl-pluralrules" */ '@formatjs/intl-pluralrules/polyfill');
    }

    if (Intl.PluralRules.polyfilled) {
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-pluralrules" */ '@formatjs/intl-pluralrules/locale-data/de')
        );
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-pluralrules" */ '@formatjs/intl-pluralrules/locale-data/de')
        );
    }

    if (shouldPolyfillNumberFormat()) {
        await import(/* webpackChunkName: "intl-numberformat" */ '@formatjs/intl-numberformat/polyfill');
    }

    if (Intl.NumberFormat.polyfilled) {
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-numberformat" */ '@formatjs/intl-numberformat/locale-data/de')
        );
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-numberformat" */ '@formatjs/intl-numberformat/locale-data/en')
        );
    }

    if (shouldPolyfillDateTimeFormat()) {
        await import(/* webpackChunkName: "intl-datetimeformat" */ '@formatjs/intl-datetimeformat/polyfill');
    }

    if (Intl.DateTimeFormat.polyfilled) {
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-datetimeformat" */ '@formatjs/intl-datetimeformat/add-all-tz')
        );
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-datetimeformat" */ '@formatjs/intl-datetimeformat/locale-data/de')
        );
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-datetimeformat" */ '@formatjs/intl-datetimeformat/locale-data/en')
        );
    }

    if (shouldPolyfillRelativeTimeFormat()) {
        await import(/* webpackChunkName: "intl-relativetimeformat" */ '@formatjs/intl-relativetimeformat/polyfill');
    }

    if (Intl.RelativeTimeFormat.polyfilled) {
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-relativetimeformat" */ '@formatjs/intl-relativetimeformat/locale-data/de')
        );
        dataPolyfills.push(
            import(/* webpackChunkName: "intl-relativetimeformat" */ '@formatjs/intl-relativetimeformat/locale-data/en')
        );
    }

    if (shouldPolyfilDurationFormat()) {
        await import(/* webpackChunkName: "intl-durationformat" */ '@formatjs/intl-durationformat/polyfill');
    }

    await Promise.all(dataPolyfills);
}
