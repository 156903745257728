import * as React from 'react';

export type JobFormTasksListItemFormFieldProps = {
    onClick: (event: React.MouseEvent) => void;
};

function JobFormTasksListItemFormField({ onClick }: JobFormTasksListItemFormFieldProps) {
    return (
        <button
            type="button"
            onClick={onClick}
            className="JobFormTasksListItemFormField__action JobFormTasksListItemFormField__action--edit"
            data-testid="job-tasks-list-item-edit-button"
        >
            <svg
                className="JobFormTasksListItemFormField__action-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    fillRule="evenodd"
                    d="M14.5558333,5.32166663 L18.6741667,9.45166665 C18.8362712,9.61427905 18.8362712,9.87738759 18.6741667,10.04 L9.57666665,19.1375 C9.41395838,19.3001592 9.15020826,19.3001592 8.98749998,19.1375 L4.86416663,15.0141667 C4.70150749,14.8514584 4.70150749,14.5877083 4.86416663,14.425 L13.9658333,5.3208333 C14.043989,5.24243275 14.1501312,5.1983333 14.2608333,5.1983333 C14.3716555,5.19857737 14.4778146,5.2429603 14.5558333,5.32166663 Z M4.02499996,16 L7.99666665,19.98 C8.10342421,20.08665 8.14417476,20.2426869 8.10319859,20.3879189 C8.06222242,20.5331509 7.94592426,20.6448776 7.79916665,20.68 L2.57249995,21.93 C2.43172351,21.9627398 2.28397973,21.9212458 2.18083328,21.82 C2.07829297,21.7176011 2.03632111,21.5692795 2.06999995,21.4283334 L3.32499996,16.1975 C3.36041876,16.0509045 3.47228888,15.9348911 3.61749996,15.8941667 C3.76247753,15.8531769 3.91829022,15.8936436 4.02499996,16 Z M21.3333334,4.43666663 C22.1438561,5.24972861 22.1438561,6.56527133 21.3333334,7.37833331 L20.1533334,8.56083332 C19.9906251,8.72349246 19.726875,8.72349246 19.5641667,8.56083332 L15.4391667,4.43666663 C15.2765075,4.27395836 15.2765075,4.01020823 15.4391667,3.84749996 L16.6208334,2.66666662 C17.4340876,1.85503566 18.7509124,1.85503566 19.5641667,2.66666662 L21.3333334,4.43666663 Z"
                />
            </svg>
        </button>
    );
}

export default JobFormTasksListItemFormField;
