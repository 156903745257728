import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import Dialog from '../Dialog';
import DialogIcon from '../DialogIcon';
import DialogTitle from '../DialogTitle';
import DialogText from '../DialogText';
import DialogActionBar from '../DialogActionBar';
import LoaderButton from '../LoaderButtonV2';

import requestTokenExpiredIcon from './images/ic_request-token-expired.svg';

type Props = {
    isRequesting: boolean;
    onClickButtonResend: (event: React.MouseEvent) => void;
};

const RequestTokenExpiredDialog = ({ isRequesting, onClickButtonResend }: Props) => {
    return (
        <Dialog>
            <DialogIcon src={requestTokenExpiredIcon} />
            <DialogTitle>
                <FormattedMessage id="REQUEST_TOKEN_EXPIRED_DIALOG.TITLE" />
            </DialogTitle>
            <DialogText>
                <FormattedMessage id="REQUEST_TOKEN_EXPIRED_DIALOG.TEXT" />
            </DialogText>

            <DialogActionBar center>
                <LoaderButton
                    type="button"
                    loading={isRequesting}
                    onClick={onClickButtonResend}
                    typeStyle="raised"
                    variationStyle="brand"
                >
                    <FormattedMessage id="REQUEST_TOKEN_EXPIRED_DIALOG.ACTION" />
                </LoaderButton>
            </DialogActionBar>
        </Dialog>
    );
};

export default RequestTokenExpiredDialog;
