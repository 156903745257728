import * as React from 'react';
import classNames from 'classnames';

import './style.scss';

type Props = {
    children?: React.ReactNode;
    className?: string;
};

const Details = (props: Props) => {
    return <span className={classNames('Details', props.className)}>{props.children}</span>;
};

export default Details;
