import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { formatToMonthInYear } from '../../../utils/date';

import CandidateResumeSection from '../CandidateResumeSection';
import CandidateResumeItem from '../CandidateResumeItem';

import { Applicant } from '../../../modules/candidates/types';

type Props = {
    applicant: Applicant;
};

const ResumeAwards = ({ applicant }: Props) => {
    const { resume } = applicant;
    const awards = resume.awards;

    if (awards.length === 0) {
        return null;
    }

    return (
        <CandidateResumeSection>
            <h1>
                <FormattedMessage id="RESUME_AWARDS.TITLE" />
            </h1>

            <ul>
                {awards.map((award, index) => (
                    <ResumeAwardsListItem key={index} award={award} />
                ))}
            </ul>
        </CandidateResumeSection>
    );
};

const ResumeAwardsListItem = ({ award }) => {
    return (
        <CandidateResumeItem
            label={
                !!award.date && (
                    <time dateTime={award.date}>
                        <span>{formatToMonthInYear(award.date)}</span>
                    </time>
                )
            }
        >
            <div>
                <strong>{award.title}</strong>
            </div>
            {!!award.summary && (
                <div>
                    <span>{award.summary}</span>
                </div>
            )}
        </CandidateResumeItem>
    );
};

export default ResumeAwards;
