import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Product from '../Product';
import { ProductV2Id } from '../../modules/checkout/types';

import { ProductProps } from '../Product';
import ProductActionButton from '../Product/ProductActionButton';
import { links } from '../../constants';

export type ProductInteractiveProps = Omit<ProductProps, 'action'> & {
    id: string;
    onSelectProduct: (productId: ProductV2Id) => void;
    actionType?: 'select' | 'continue';
};

function ProductInteractive({
    disabled,

    id,
    onSelectProduct,
    actionType = 'select',
    ...props
}: ProductInteractiveProps) {
    const intl = useIntl();

    const handleClickAction = () => {
        onSelectProduct(id);
    };

    let action: React.ReactNode;
    if (!!props.price) {
        action = (
            <ProductActionButton onClick={handleClickAction} disabled={disabled}>
                {actionType === 'continue' ? (
                    <FormattedMessage id="PRODUCT_FOOTER_INPUT.BUTTON_CONTINUE" />
                ) : (
                    <FormattedMessage id="PRODUCT_FOOTER_INPUT.BUTTON_SELECT" />
                )}
            </ProductActionButton>
        );
    } else {
        action = (
            <ProductActionButton
                component="a"
                href={links.calendly[intl.locale]}
                target="_blank"
                rel="noopener noreferrer"
            >
                <FormattedMessage id="PRODUCT_FOOTER_INPUT.BUTTON_CONTACT" />
            </ProductActionButton>
        );
    }

    return <Product {...props} disabled={disabled} action={action} />;
}

export default ProductInteractive;
