import * as React from 'react';

import './style.scss';

type Props = {
    children: React.ReactNode;
};

const ListEmpty = (props: Props) => {
    return <div className="ListEmpty">{props.children}</div>;
};

export default ListEmpty;
