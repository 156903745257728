import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import './MessageItemReadStatus.style.scss';

import { ConversationParticipant } from '../../modules/conversations/types';

type Props = {
    sending: boolean;
    lastReadParticipants: ConversationParticipant[];
    lastUnreadParticipants: ConversationParticipant[];
};

const MessageItemReadStatus = ({ sending, lastReadParticipants, lastUnreadParticipants }: Props) => {
    let message: React.ReactNode = null;

    if (sending) {
        message = <FormattedMessage id="MESSAGE_ITEM.READ_STATUS.SENDING" />;
    } else if (lastUnreadParticipants.length > 0) {
        message = <FormattedMessage id="MESSAGE_ITEM.READ_STATUS.DELIVERED" />;
    } else if (lastReadParticipants.length > 0) {
        message = <FormattedMessage id="MESSAGE_ITEM.READ_STATUS.READ" />;
    }

    return (
        <div className="MessageItemReadStatus">
            <div className="MessageItemReadStatus__body">
                <span>{message}</span>
            </div>
        </div>
    );
};

export default MessageItemReadStatus;
