import { combineReducers } from 'redux';

import resetOnLogout from '../authentication/reducer/resetOnLogout';

import { PACKAGES_FETCH, PACKAGE_ADVERTISEMENT_CREATE } from './actions';

export const MOUNT = 'packages';

import { Action, CreateStatus, FetchStatus } from '../types';
import { createMapReducer } from '../../utils/redux';
import { CREATE_STATUS } from '../constants';

type EntriesState = number[];

export type PackagesState = {
    entries: EntriesState;
    fetchStatus: FetchStatus;
    advertisementCreateStatus: AdvertisementCreateStatusesState;
};

// This type represents the part of the whole state (starting from the root state object) which is handled by
// this reducer.
export type State = {
    packages: PackagesState;
};

const initialEntriesState = [];
function entriesReducer(state: EntriesState = initialEntriesState, action: Action) {
    switch (action.type) {
        case PACKAGES_FETCH.SUCCESS:
            const pkgs = action.payload.data.results;
            const pkgIds = pkgs.map((pkg) => pkg.id);

            return pkgIds;

        default:
            return state;
    }
}

const initialFetchStatusState = 'none';
function fetchStatusReducer(state: FetchStatus = initialFetchStatusState, action: Action) {
    switch (action.type) {
        case PACKAGES_FETCH.REQUEST:
            return 'loading';

        case PACKAGES_FETCH.SUCCESS:
            return 'loaded';

        case PACKAGES_FETCH.FAILURE:
            return 'failed';

        default:
            return state;
    }
}

export type AdvertisementCreateStatusState = CreateStatus;

export const initialAdvertisementCreateStatus = CREATE_STATUS.NONE;
function advertisementCreateStatusReducer(
    state: AdvertisementCreateStatusState = initialAdvertisementCreateStatus,
    action: Action
) {
    switch (action.type) {
        case PACKAGE_ADVERTISEMENT_CREATE.REQUEST:
            return CREATE_STATUS.CREATING;

        case PACKAGE_ADVERTISEMENT_CREATE.SUCCESS:
            return CREATE_STATUS.CREATED;

        case PACKAGE_ADVERTISEMENT_CREATE.FAILURE:
            return CREATE_STATUS.FAILED;

        default:
            return state;
    }
}

export type AdvertisementCreateStatusesState = {
    [key: string]: AdvertisementCreateStatusState;
};

const advertisementCreateStatusesReducer = createMapReducer(advertisementCreateStatusReducer, (action: Action) => {
    switch (action.type) {
        case PACKAGE_ADVERTISEMENT_CREATE.REQUEST:
        case PACKAGE_ADVERTISEMENT_CREATE.SUCCESS:
        case PACKAGE_ADVERTISEMENT_CREATE.FAILURE:
            return action.payload.packageId;

        default:
            return null;
    }
});

const packagesReducer = combineReducers({
    entries: entriesReducer,
    fetchStatus: fetchStatusReducer,
    advertisementCreateStatus: advertisementCreateStatusesReducer
});

export default resetOnLogout(packagesReducer);
