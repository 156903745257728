import * as React from 'react';
import classNames from 'classnames';

import './style.scss';

type Props = {
    children: React.ReactNode;

    center?: boolean;
    right?: boolean;
};

const DialogActionBar = (props: Props) => {
    const {
        children,

        center,
        right
    } = props;

    const actions = React.Children.map(children, (child, index) => {
        return (
            <div key={index} className="DialogActionBar__action">
                {child}
            </div>
        );
    });

    const modifiers = {
        'DialogActionBar--center': center || false,
        'DialogActionBar--right': right || false
    };

    return <div className={classNames('DialogActionBar Dialog__action-bar', modifiers)}>{actions}</div>;
};

export default DialogActionBar;
