import { combineReducers } from 'redux';
import { resetReducer } from '../../utils/redux';

import resetOnLogout from '../authentication/reducer/resetOnLogout';

import { SET_CLIENT_INFO, RESET_CLIENT_INFO, CLIENT_INFO_FETCH, CLIENT_INFO_UPDATE } from './actions';

// We want to mount our reducer here
export const MOUNT = 'clientInfo';

import { Action, FetchStatus as FetchStatusState, UpdateStatus as UpdateStatusState } from '../types';
import { FETCH_STATUS, UPDATE_STATUS } from '../constants';
import { ClientInfo } from './types';

type EntityState = ClientInfo | null;

export type State = {
    fetchStatus: FetchStatusState;
    updateStatus: UpdateStatusState;
    entity: EntityState;
};

export function fetchStatusReducer(state: FetchStatusState = 'none', action: Action) {
    switch (action.type) {
        case CLIENT_INFO_FETCH.REQUEST:
            return FETCH_STATUS.LOADING;

        case CLIENT_INFO_FETCH.SUCCESS:
            return FETCH_STATUS.LOADED;

        case CLIENT_INFO_FETCH.FAILURE:
            return FETCH_STATUS.FAILED;

        default:
            return state;
    }
}

export function updateStatusReducer(state: UpdateStatusState = 'none', action: Action): UpdateStatusState {
    switch (action.type) {
        case CLIENT_INFO_UPDATE.REQUEST:
            return UPDATE_STATUS.UPDATING;
        case CLIENT_INFO_UPDATE.SUCCESS:
            return UPDATE_STATUS.UPDATED;
        case CLIENT_INFO_UPDATE.FAILURE:
            return UPDATE_STATUS.FAILED;

        default:
            return state;
    }
}

export function entityReducer(state: EntityState = null, action: Action) {
    switch (action.type) {
        case SET_CLIENT_INFO:
        case CLIENT_INFO_FETCH.SUCCESS:
        case CLIENT_INFO_UPDATE.SUCCESS:
            return action.payload.clientInfo;

        default:
            return state;
    }
}

const resetOnClientInfoReset = resetReducer((state, action) => action.type === RESET_CLIENT_INFO);

const clientInfoReducer = combineReducers({
    fetchStatus: fetchStatusReducer,
    updateStatus: updateStatusReducer,
    entity: entityReducer
});

export default resetOnLogout(resetOnClientInfoReset(clientInfoReducer));
