import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Recruiter } from '../../../modules/recruiters/types';
import JobFormCallToActions from './JobFormCallToActions';
import JobFormCompanyFormField, { JobFormCompanyFormFieldValue } from './JobFormCompanyFormField';
import JobFormSectorsFormFields, { JobFormSectorsFormFieldsValue } from './JobFormSectorsFormFields';

export type JobFormCompanyDetailsFormFieldsetValues = {
    company: JobFormCompanyFormFieldValue;
    sectors: JobFormSectorsFormFieldsValue;
};

export type JobFormCompanyDetailsFormFieldsetProps = {
    recruiter: Recruiter;
    isCompanyEditable: boolean;
    onClickCallToAction: () => void;
};

function JobFormCompanyDetailsFormFieldset({
    recruiter,
    isCompanyEditable,
    onClickCallToAction
}: JobFormCompanyDetailsFormFieldsetProps) {
    return (
        <fieldset className="panel">
            <div className="panel-heading">
                <h2 className="panel-title">
                    <FormattedMessage id="JOB_EDIT.COMPANY_DETAILS_FORM_FIELDSET.TITLE" />
                </h2>
            </div>
            <div className="panel-body">
                <JobFormCallToActions recruiter={recruiter} onClickCallToAction={onClickCallToAction} />

                <JobFormCompanyFormField isCompanyEditable={isCompanyEditable} />

                <JobFormSectorsFormFields />
            </div>
        </fieldset>
    );
}

export default JobFormCompanyDetailsFormFieldset;
