import { createSelector } from 'reselect';

import { State as RootState } from '../../store/reducer';
import { getPackage } from '../entities/selectors';

import { initialAdvertisementCreateStatus, PackagesState as State } from './reducer';
import { PackageId } from './types';

const getRoot = (state: RootState) => state.packages;

export const getFetchStatus = createSelector(getRoot, (state: State) => {
    return state.fetchStatus;
});

export const getEntries = createSelector(getRoot, (state: State) => {
    return state.entries;
});

export function getPackages(state: RootState) {
    return getEntries(state).map((packageId: PackageId) => {
        const pkg = getPackage(state, packageId);

        if (!pkg) {
            throw new Error(
                `Cannot map package id to package entity - missing package entity for package id ${packageId}`
            );
        }

        return pkg;
    });
}

const getAdvertismentCreateStatuses = createSelector(getRoot, (state: State) => {
    return state.advertisementCreateStatus;
});

export function getAdvertismentCreateStatus(state: RootState, packageId: PackageId) {
    return getAdvertismentCreateStatuses(state)[packageId] || initialAdvertisementCreateStatus;
}
