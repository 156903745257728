import { createAction as createActionLegacy } from 'redux-actions';
import { ApiEntityResponse, ApiError, ApiListResponse, ApiListResponseData } from '../../services/api/types';
import { createAction, createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';
import { JobId } from '../jobs/types';
import { Errors } from '../types';
import { ApplicantId, Candidate, CandidateId } from './types';

// Factory to create all actions of the job module
const createType = createActionTypeCreator('CANDIDATE');

export const LOAD_CANDIDATES = createType('LOAD_CANDIDATES');
export const loadCandidates = createActionLegacy(LOAD_CANDIDATES, (listName, jobId, params) => {
    return {
        listName,
        jobId,
        params
    };
});

export const FETCH_CANDIDATES = createRequestActionTypes(createType('FETCH_CANDIDATES'));
export const fetchCandidates = {
    request: createAction(FETCH_CANDIDATES.REQUEST, (listName: string, jobId: JobId, page: number) => ({
        payload: {
            listName,
            jobId,
            page
        }
    })),
    success: createAction(
        FETCH_CANDIDATES.SUCCESS,
        (listName: string, jobId: JobId, page: number, response: ApiListResponse<Candidate>) => ({
            payload: {
                listName,
                jobId,
                page,
                data: response.data
            }
        })
    ),
    failure: createAction(FETCH_CANDIDATES.FAILURE, (listName: string, jobId: JobId, errors) => ({
        payload: {
            listName,
            jobId,
            errors
        }
    }))
};
export type FetchCandidatesActions$Request = ReturnType<typeof fetchCandidates['request']>;
export type FetchCandidatesActions$Success = ReturnType<typeof fetchCandidates['success']>;
export type FetchCandidatesActions$Failure = ReturnType<typeof fetchCandidates['failure']>;

export const LOAD_CANDIDATE = createType('LOAD_CANDIDATE');
export const loadCandidate = createActionLegacy(LOAD_CANDIDATE, (candidateId) => ({
    candidateId
}));

export const FETCH_CANDIDATE = createRequestActionTypes(createType('FETCH_CANDIDATE'));
export const fetchCandidate = {
    request: createAction(FETCH_CANDIDATE.REQUEST, (candidateId: CandidateId) => ({
        payload: {
            candidateId
        }
    })),
    success: createAction(
        FETCH_CANDIDATE.SUCCESS,
        (candidateId: CandidateId, response: ApiEntityResponse<Candidate>) => ({
            payload: {
                candidateId,
                data: response.data
            }
        })
    ),
    failure: createAction(FETCH_CANDIDATE.FAILURE, (candidateId: CandidateId, errors: ApiError[]) => ({
        payload: {
            candidateId,
            errors
        }
    }))
};
export type FetchCandidateActions$Request = ReturnType<typeof fetchCandidate['request']>;
export type FetchCandidateActions$Success = ReturnType<typeof fetchCandidate['success']>;
export type FetchCandidateActions$Failure = ReturnType<typeof fetchCandidate['failure']>;

export const UPDATE_CANDIDATE = createType('UPDATE_CANDIDATE');
export const updateCandidate = createActionLegacy(UPDATE_CANDIDATE, (candidateId, newCandidate) => ({
    candidateId,
    newCandidate
}));

export const CANDIDATE_UPDATE = createRequestActionTypes(createType('CANDIDATE_UPDATE'));
export const candidateUpdate = {
    request: createAction(CANDIDATE_UPDATE.REQUEST, (candidateId: CandidateId) => ({
        payload: {
            candidateId
        }
    })),
    success: createAction(
        CANDIDATE_UPDATE.SUCCESS,
        (candidateId: CandidateId, response: ApiEntityResponse<Candidate>) => ({
            payload: {
                candidateId,
                data: response.data
            }
        })
    ),
    failure: createAction(CANDIDATE_UPDATE.FAILURE, (candidateId: CandidateId, errors: ApiError[]) => ({
        payload: {
            candidateId,
            errors
        }
    }))
};
export type CandidateUpdateActions$Request = ReturnType<typeof candidateUpdate['request']>;
export type CandidateUpdateActions$Success = ReturnType<typeof candidateUpdate['success']>;
export type CandidateUpdateActions$Failure = ReturnType<typeof candidateUpdate['failure']>;

export const UPDATE_CANDIDATE_STATE = createType('UPDATE_CANDIDATE_STATE');
export const updateCandidateState = createActionLegacy(UPDATE_CANDIDATE_STATE, (candidateId, newState) => ({
    candidateId,
    newState
}));

export const DELETE_CANDIDATE = createType('DELETE_CANDIDATE');
export const deleteCandidate = createAction(
    DELETE_CANDIDATE,
    (candidateId, conversationId, messageOwnerId, messageText) => ({
        payload: {
            candidateId,
            conversationId,
            messageOwnerId,
            messageText
        }
    })
);
export type DeleteCandidateAction = ReturnType<typeof deleteCandidate>;

export const CANDIDATE_DELETE = createRequestActionTypes(createType('CANDIDATE_DELETE'));
export const candidateDelete = {
    request: createAction(CANDIDATE_DELETE.REQUEST, (candidateId: CandidateId) => ({
        payload: {
            candidateId
        }
    })),
    success: createAction(CANDIDATE_DELETE.SUCCESS, (candidateId: CandidateId) => ({
        payload: {
            candidateId
        }
    })),
    failure: createAction(CANDIDATE_DELETE.FAILURE, (candidateId: CandidateId, errors: ApiError[]) => ({
        payload: {
            candidateId,
            errors
        }
    }))
};
export type CandidateDeleteActions$Request = ReturnType<typeof candidateDelete['request']>;
export type CandidateDeleteActions$Success = ReturnType<typeof candidateDelete['success']>;
export type CandidateDeleteActions$Failure = ReturnType<typeof candidateDelete['failure']>;

export const FETCH_CANDIDATES_BY_APPLICANT_ID = createType('FETCH_CANDIDATES_BY_APPLICANT_ID');
export const fetchCandidatesByApplicantId = createActionLegacy(
    FETCH_CANDIDATES_BY_APPLICANT_ID,
    ({ applicantId }: { applicantId: ApplicantId }) => ({
        applicantId
    })
);
export const fetchOtherCandidatesThatMatchApplicant = fetchCandidatesByApplicantId;

export const CANDIDATES_BY_APPLICANT_ID_FETCH = createRequestActionTypes(
    createType('CANDIDATES_BY_APPLICANT_ID_FETCH')
);
export const candidatesByApplicantIdFetch = {
    request: createAction(
        CANDIDATES_BY_APPLICANT_ID_FETCH.REQUEST,
        ({ applicantId }: { applicantId: ApplicantId }) => ({
            payload: {
                applicantId
            }
        })
    ),
    success: createAction(
        CANDIDATES_BY_APPLICANT_ID_FETCH.SUCCESS,
        ({ applicantId, data }: { applicantId: ApplicantId; data: ApiListResponseData<Candidate> }) => ({
            payload: {
                applicantId,
                data
            }
        })
    ),
    failure: createAction(
        CANDIDATES_BY_APPLICANT_ID_FETCH.FAILURE,
        ({ applicantId, errors }: { applicantId: ApplicantId; errors: Errors }) => ({
            payload: {
                applicantId,
                errors
            }
        })
    )
};
export type CandidatesByApplicantIdFetchActions$Request = ReturnType<typeof candidatesByApplicantIdFetch['request']>;
export type CandidatesByApplicantIdFetchActions$Success = ReturnType<typeof candidatesByApplicantIdFetch['success']>;
export type CandidatesByApplicantIdFetchActions$Failure = ReturnType<typeof candidatesByApplicantIdFetch['failure']>;

export const FORWARD_CANDIDATE = createType('FORWARD_CANDIDATE');
export const forwardCandidate = createActionLegacy(FORWARD_CANDIDATE, (candidateId, email, subject, message) => ({
    candidateId,

    email,
    subject,
    message
}));

export const CANDIDATE_FORWARD = createRequestActionTypes(createType('CANDIDATE_FORWARD'));
export const candidateForward = {
    request: createActionLegacy(CANDIDATE_FORWARD.REQUEST, (candidateId) => ({ candidateId })),
    success: createActionLegacy(CANDIDATE_FORWARD.SUCCESS, (candidateId) => ({ candidateId })),
    failure: createActionLegacy(CANDIDATE_FORWARD.FAILURE, (candidateId) => ({ candidateId }))
};
