import resetOnLogout from '../../authentication/reducer/resetOnLogout';
import {
    CONVERSATION_FETCH,
    CONVERSATION_UPDATE,
    CONVERSATION_POLLING,
    ConversationFetchActions$Success,
    ConversationUpdateActions$Success,
    ConversationPollingActions$Success
} from '../../conversations/actions';

import { EntityTypeEntitesState } from './';
import { Conversation } from '../../conversations/types';
import {
    CandidateUpdateActions$Success,
    CANDIDATE_UPDATE,
    FetchCandidateActions$Success,
    FETCH_CANDIDATE
} from '../../candidates/actions';

const conversationsReducer = (
    state: EntityTypeEntitesState<Conversation> = {},
    action:
        | ConversationFetchActions$Success
        | ConversationUpdateActions$Success
        | ConversationPollingActions$Success
        | FetchCandidateActions$Success
        | CandidateUpdateActions$Success
): EntityTypeEntitesState<Conversation> => {
    switch (action.type) {
        case CONVERSATION_FETCH.SUCCESS:
        case CONVERSATION_UPDATE.SUCCESS:
        case CONVERSATION_POLLING.SUCCESS: {
            const { data: conversation } = action.payload.response;

            return {
                ...state,
                [conversation.id]: conversation
            };
        }

        case FETCH_CANDIDATE.SUCCESS:
        case CANDIDATE_UPDATE.SUCCESS: {
            const { data: candidate } = action.payload;

            const conversationsRelatedToCandidate = Object.values(state).filter((conversation) => {
                return conversation.id === candidate.conversation_id;
            });

            return conversationsRelatedToCandidate.reduce((state, conversationRelatedToCandidate) => {
                return {
                    ...state,
                    [conversationRelatedToCandidate.id]: entityReducer(conversationRelatedToCandidate, action)
                };
            }, state);
        }

        default:
            return state;
    }
};

function entityReducer(
    state: Conversation,
    action: FetchCandidateActions$Success | CandidateUpdateActions$Success
): Conversation {
    switch (action.type) {
        case FETCH_CANDIDATE.SUCCESS:
        case CANDIDATE_UPDATE.SUCCESS: {
            const { data: candidate } = action.payload;

            if (state.id !== candidate.conversation_id) {
                return state;
            }

            return {
                ...state,
                unread_message_count: candidate.unread_message_count
            };
        }

        default: {
            return state;
        }
    }
}

export default resetOnLogout(conversationsReducer as any) as typeof conversationsReducer;
