import { createSelector } from 'reselect';
import { ApplicationState } from '../../store/reducer';
import { I18N_MOUNT, I18NState } from './reducer';

function getI18NState(state: ApplicationState): I18NState {
    return state[I18N_MOUNT];
}

export const getLocale = createSelector(getI18NState, (i18n) => {
    return i18n.locale;
});
