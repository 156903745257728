import * as React from 'react';
import classNames from 'classnames';

type RenderProps = {
    children?: React.ReactNode;
    truncate?: boolean;
    skeleton?: boolean;
};

type Props = {
    children?: React.ReactNode;
    className?: string;
    component?: React.ComponentType<RenderProps>;
    truncate?: boolean;
    skeleton?: boolean;
};

const DetailsPrimary = (props: Props) => {
    const {
        truncate,
        skeleton,
        className,

        component,

        ...restProps
    } = props;

    // Generate className property
    const classNameStr = classNames(
        'Details__primary-content',
        {
            'Details__primary-content--truncate': truncate,
            'Details__primary-content--skeleton': skeleton
        },

        className
    );

    // Get component
    const Component = component || 'span';

    return (
        <Component className={classNameStr} {...restProps}>
            {props.children}
        </Component>
    );
};

export default DetailsPrimary;
