import { combineReducers } from 'redux';

import { omit } from '../../utils/object';

import resetOnLogout from '../authentication/reducer/resetOnLogout';

import {
    ADD_SYSTEM_NOTIFICATION,
    REMOVE_SYSTEM_NOTIFICATION,
    ADD_ACTION_NOTIFICATION,
    REMOVE_ACTION_NOTIFICATION
} from './actions';

export const MOUNT = 'notifications';

import { Action } from '../types';
import { SystemNotification, ActionNotification } from './types';

export type SystemNotificationsState = {
    [id: string]: SystemNotification;
};

export type ActionNotificationsState = {
    [id: string]: ActionNotification;
};

export type NotificationsState = {
    systemNotifications: SystemNotificationsState;
    actionNotifications: ActionNotificationsState;
};

export type State = {
    notifications: NotificationsState;
};

export const initialSystemNotificationsState: SystemNotificationsState = {};
export const initialActionNotificationsState: ActionNotificationsState = {};

export function systemNotificationsReducer(
    state: SystemNotificationsState = initialSystemNotificationsState,
    action: Action
) {
    const { payload } = action;

    switch (action.type) {
        case ADD_SYSTEM_NOTIFICATION:
            const systemNotificationWithoutId = omit(payload, 'id') as SystemNotification;

            return {
                ...state,
                [payload.id]: systemNotificationWithoutId
            };

        case REMOVE_SYSTEM_NOTIFICATION:
            return Object.keys(state).reduce((newState, notificationId) => {
                if (notificationId !== payload.id) {
                    newState[notificationId] = state[notificationId];
                }
                return newState;
            }, {} as SystemNotificationsState);

        default:
            return state;
    }
}

export function actionNotificationsReducer(
    state: ActionNotificationsState = initialActionNotificationsState,
    action: Action
): ActionNotificationsState {
    const { payload } = action;

    switch (action.type) {
        case ADD_ACTION_NOTIFICATION:
            const actionNotificationWithoutId = omit(payload, 'id') as ActionNotification;

            // We don't merge it with the previous state because we want to remove the previous values
            return {
                [payload.id]: actionNotificationWithoutId
            };

        case REMOVE_ACTION_NOTIFICATION:
            return Object.keys(state).reduce((newState, notificationId) => {
                if (notificationId !== payload.id) {
                    newState[notificationId] = state[notificationId];
                }
                return newState;
            }, {} as ActionNotificationsState);

        default:
            return state;
    }
}

const notificationsReducer = combineReducers({
    systemNotifications: systemNotificationsReducer,
    actionNotifications: actionNotificationsReducer
});

export default resetOnLogout(notificationsReducer);
