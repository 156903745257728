import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import jobUrlIcon from '../../assets/images/ic_job_url.svg';
import jobManualIcon from '../../assets/images/ic_job_manual.svg';
import jobCopyIcon from '../../assets/images/ic_job_copy.svg';

import './style.scss';

const JobCreateSwitcher = () => {
    return (
        <div className="JobCreateSwitcher">
            <div className="JobCreateSwitcher__container">
                <NavLink
                    replace
                    to="/job-create/parse/"
                    className="JobCreateSwitcher__button"
                    activeClassName="JobCreateSwitcher__button--active"
                >
                    <img className="JobCreateSwitcher__button-icon" src={jobUrlIcon} role="presentation" />
                    <FormattedMessage id="JOB_CREATE_SWITCHER.BUTTON.URL" />
                </NavLink>
                <NavLink
                    to="/job/new"
                    className="JobCreateSwitcher__button"
                    activeClassName="JobCreateSwitcher__button--active"
                >
                    <img className="JobCreateSwitcher__button-icon" src={jobManualIcon} role="presentation" />
                    <FormattedMessage id="JOB_CREATE_SWITCHER.BUTTON.MANUAL" />
                </NavLink>
                <NavLink
                    replace
                    to="/job-create/copy/"
                    className="JobCreateSwitcher__button"
                    activeClassName="JobCreateSwitcher__button--active"
                >
                    <img className="JobCreateSwitcher__button-icon" src={jobCopyIcon} role="presentation" />
                    <FormattedMessage id="JOB_CREATE_SWITCHER.BUTTON.COPY" />
                </NavLink>
            </div>
        </div>
    );
};

export default JobCreateSwitcher;
