import * as React from 'react';

import { icons } from '../constants';

import './style.scss';

type Props = {
    children?: React.ReactNode;

    active: boolean;

    onClick?: (event: React.MouseEvent) => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;

    bsRole?: string;
};

class FilterControlToggle extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(event: React.MouseEvent<HTMLButtonElement>) {
        // We've to stop it here otherwise <RootCloseWrapper /> in the FilterControlMenu will pick the event up and might toggle the menu again
        event.stopPropagation();
        this.props.onClick?.(event);
    }

    render() {
        const icon = this.props.active ? 'active' : 'none';

        let label: React.ReactNode = null;
        if (!!React.Children.count(this.props.children)) {
            label = <span className="FilterControlToggle__label">{this.props.children}</span>;
        }

        return (
            <button
                type="button"
                className="FilterControlToggle"
                onClick={this.handleClick}
                onKeyDown={this.props.onKeyDown}
            >
                {label}

                <img className="FilterControlToggle__icon" src={icons[icon]} role="presentation" />
            </button>
        );
    }
}

export default FilterControlToggle;
