import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import JobFormRequirementsFormFields, { JobFormRequirementsFormFieldsValue } from '../../JobFormRequirementsFormFields';

export type JobFormSkillsFormFieldsValue = JobFormRequirementsFormFieldsValue;
export type JobFormSkillsFormFieldsName = 'skills';
export const JOB_EDIT_SKILLS_FORM_FIELDS_NAME = 'skills';

export type JobFormSkillsFormFieldsProps = {};

function JobFormSkillsFormFields({}: JobFormSkillsFormFieldsProps) {
    const intl = useIntl();

    return (
        <JobFormRequirementsFormFields
            name={JOB_EDIT_SKILLS_FORM_FIELDS_NAME}
            labelText={<FormattedMessage id="JOB_EDIT.SKILLS_FORM_FIELDS.LABEL_TEXT" />}
            helpText={<FormattedMessage id="JOB_EDIT.SKILLS_FORM_FIELDS.HELP_TEXT" />}
            inputPlaceholderText={intl.formatMessage({ id: 'JOB_EDIT.SKILLS_FORM_FIELDS.INPUT_PLACEHOLDER_TEXT' })}
            minLength={3}
            errorMessages={{
                duplicate: <FormattedMessage id="JOB_EDIT.SKILLS_FORM_FIELDS.ERROR_DUPLICATE" />
            }}
        />
    );
}

export default JobFormSkillsFormFields;
