import React from 'react';
import { FormattedMessage } from 'react-intl';

import './CandidateApplicationMessageCallToActionText.scss';

export type CandidateApplicationMessageCallToActionTextProps = {};

function CandidateApplicationMessageCallToActionText({}: CandidateApplicationMessageCallToActionTextProps) {
    return (
        <div className="CandidateApplicationMessageCallToActionText">
            <FormattedMessage id="CANDIDATE_APPLICATION_MESSAGE_CALL_TO_ACTION_TEXT.TEXT" />
        </div>
    );
}

export default CandidateApplicationMessageCallToActionText;
