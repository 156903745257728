/**
 * Converts the given value to a valid number or returns null if it's not possible.
 */
export function parseIntOrNull(value: unknown, radix?: number): number | null {
    let tempValue = value;

    if (typeof tempValue === 'string') {
        tempValue = parseInt(tempValue, radix);
    }

    if (typeof tempValue === 'number') {
        return isNaN(tempValue) ? null : tempValue;
    }

    return null;
}

export function randomNumber(min: number, max: number): number {
    return Math.random() * (max - min) + min;
}

export function isNumber(value: number) {
    return !isNaN(value);
}
