import equal from 'deep-equal';

import * as sessionStorage from '../../services/sessionStorage';
import { initializeCheckoutProcessStates } from '../checkout/actions';
import { getCheckoutProcessStates } from '../entities/selectors';
import { count } from '../../utils/object';

import { Middleware } from 'redux';
import { State } from '../../store/reducer';

const STORAGE_KEY = 'checkouts';

function getInitialCheckoutProcessStates() {
    return sessionStorage.get(STORAGE_KEY) || {};
}

function restoreCheckoutProcessStates(store) {
    const initialCheckoutProcessStates = getInitialCheckoutProcessStates();

    if (count(initialCheckoutProcessStates) >= 1) {
        store.dispatch(initializeCheckoutProcessStates(initialCheckoutProcessStates));
    }
}

function saveCheckoutProcessStates(prevState, nextState) {
    const prevCheckoutProcessStates = getCheckoutProcessStates(prevState);
    const nextCheckoutProcessStates = getCheckoutProcessStates(nextState);

    if (!equal(prevCheckoutProcessStates, nextCheckoutProcessStates)) {
        sessionStorage.set(STORAGE_KEY, nextCheckoutProcessStates);
    }
}

export function createCheckoutProcessStatesPersistorMiddleware(): Middleware<State, any, any> {
    return (store) => {
        // We delay the restoring as we can't do it during store initialization
        setTimeout(() => {
            restoreCheckoutProcessStates(store);
        });

        return (next) => {
            return (action) => {
                const prevState = store.getState();
                const result = next(action);
                const nextState = store.getState();

                saveCheckoutProcessStates(prevState, nextState);

                return result;
            };
        };
    };
}
