import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './style.scss';

type Props = {
    candidateState: string;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const CandidateStateItem = (props: Props) => {
    const { candidateState, onClick } = props;

    return (
        <button
            type="button"
            onClick={onClick}
            className={classNames('CandidateStateItem', `CandidateStateItem--${candidateState}`)}
        >
            {/*
                This wrapper is needed as workaround for a safari bug. Buttons can't be flexbox
                https://github.com/philipwalton/flexbugs#9-some-html-elements-cant-be-flex-containers
             */}
            <span className="CandidateStateItem__flex-wrapper">
                <span className="CandidateStateItem__indicator"></span>
                <span className="CandidateStateItem__name">
                    <FormattedMessage id={`CONSTANT.CANDIDATE_STATE.${candidateState}`} />
                </span>
            </span>
        </button>
    );
};

export default CandidateStateItem;
