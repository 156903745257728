import * as React from 'react';
import { getComponentName } from '../../utils/component';

type ScrolledState = {
    scrollTop: number;
    scrolled: boolean;
    reachedEnd: boolean;
};

export type ScrolledProps = {
    scrollTop: number;
    scrolled: boolean;
    reachedEnd: boolean;
    onScroll: (event: React.UIEvent) => void;
};

function wrapWithScrolled<Props>(Component: React.ComponentType<Props & ScrolledProps>) {
    const wrappedComponentName = getComponentName(Component);

    return class extends React.Component<Props, ScrolledState> {
        static WrappedComponent: React.ComponentType<Props & ScrolledProps> = Component;
        static displayName: string = `Scrolled(${wrappedComponentName})`;

        constructor(props) {
            super(props);

            this.state = {
                scrollTop: -1,
                scrolled: false,
                reachedEnd: false
            };

            this.handleScroll = this.handleScroll.bind(this);
        }

        handleScroll(event: React.UIEvent) {
            const target = event.target as HTMLElement;

            const reachedEnd = target.scrollHeight - target.scrollTop <= target.offsetHeight;

            this.setState({
                scrollTop: target.scrollTop,
                scrolled: target.scrollTop > 0,
                reachedEnd: reachedEnd
            });
        }

        render() {
            const { scrollTop, scrolled, reachedEnd } = this.state;
            return (
                <Component
                    {...this.props}
                    scrollTop={scrollTop}
                    scrolled={scrolled}
                    reachedEnd={reachedEnd}
                    onScroll={this.handleScroll}
                />
            );
        }
    };
}

export default wrapWithScrolled;
