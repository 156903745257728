import { Keyframes, config } from 'react-spring/renderprops';
import { ANIMATION_STEP_INIT, ANIMATION_STEP_OPEN, ANIMATION_STEP_NEXT_STEP } from '../../constants';

const AnimationStates = {
    [ANIMATION_STEP_INIT]: {
        opacity: 0,
        wrapperTranslate: [-225, -32, 100, 100],
        childTranslate: [0, 0, 0, 100]
    },
    [ANIMATION_STEP_OPEN]: {
        opacity: 1,
        wrapperTranslate: [-225, -16, 100, 100],
        childTranslate: [0, 0, 0, 100],
        delay: 700
    },
    [ANIMATION_STEP_NEXT_STEP]: [
        {
            wrapperTranslate: [-32, -146, 100, 100],
            childTranslate: [0, 0, 100, 0],
            opacity: 0,
            delay: 0,
            config: {
                duration: 0.01
            }
        },
        {
            opacity: 1,
            delay: 300,
            config: config.default
        }
    ]
};

export const AnimationKeyframes = Keyframes.Spring({ // eslint-disable-line
    [ANIMATION_STEP_INIT]: AnimationStates[ANIMATION_STEP_INIT],
    [ANIMATION_STEP_OPEN]: {
        from: AnimationStates[ANIMATION_STEP_INIT],
        ...AnimationStates[ANIMATION_STEP_OPEN]
    },
    [ANIMATION_STEP_NEXT_STEP]: AnimationStates[ANIMATION_STEP_NEXT_STEP]
});

export const AnimationLeaveKeyframes = {
    [ANIMATION_STEP_INIT]: {
        from: {
            opacity: 1,
            wrapperTranslate: AnimationStates[ANIMATION_STEP_OPEN].wrapperTranslate
        },
        opacity: 0,
        wrapperTranslate: [-225, 0, 100, 100]
    },
    [ANIMATION_STEP_NEXT_STEP]: {
        from: {
            opacity: 1,
            wrapperTranslate: AnimationStates[ANIMATION_STEP_NEXT_STEP][0].wrapperTranslate
        },
        opacity: 0,
        wrapperTranslate: [-32, -130, 100, 100],

        // Ensure the child doesn't move
        childTranslate: AnimationStates[ANIMATION_STEP_NEXT_STEP][0].childTranslate
    }
};

export const emptyTransition = { visibility: 'visible' };
