import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { FormField, Input } from '@truffls/design-system-react';
import deepEqual from 'deep-equal';

import LoaderButton from '../../components/LoaderButtonV2';
import CandidateNotesMobilePage from '../../components/CandidateNotesMobilePage';
import { updateCandidate } from '../../modules/candidates/actions';
import { getCandidate, getCandidateUpdateStatus } from '../../modules/entities/selectors';

import CandidateDetailsRow from '../CandidateDetailsPage/CandidateDetailsRow';

import { Candidate } from '../../modules/candidates/types';
import { UpdateStatus } from '../../modules/types';
import { State as ApplicationState } from '../../store/reducer';

import './CandidateNotesItem.style.scss';

type ConnectorProps = {
    candidateId: number;
};

type ConnectedStateProps = {
    candidate: Candidate | null;
    updateStatus: UpdateStatus;
};

type ConnectedDispatchProps = {
    updateCandidate: typeof updateCandidate;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type FormValues = {
    notes: string;
};

type State = {
    isFormDirty: boolean;
    isFormSubmitting: boolean;
    isFormSubmitted: boolean;
    initialFormValues: FormValues | null;
    formValues: FormValues | null;
    showMobileView: boolean;
};

export class CandidateNotesItem extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const { candidate } = props;

        const initialFormValues = !!candidate ? getFormValuesFromCandidate(candidate) : null;
        const formValues = initialFormValues;

        this.state = {
            isFormDirty: false,
            isFormSubmitting: false,
            isFormSubmitted: false,
            initialFormValues,
            formValues,
            showMobileView: false
        };

        this.handleShowMobileView = this.handleShowMobileView.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps: Props) {
        const nextProps = this.props;
        const nextCandidate = nextProps.candidate;
        const { initialFormValues, formValues, isFormSubmitting } = this.state;

        if (!!nextCandidate) {
            const nextInitialFormValues = getFormValuesFromCandidate(nextCandidate);

            const hasIntialFormValuesDefined = !initialFormValues;
            const isFormDirty = !!initialFormValues && !!formValues && !deepEqual(initialFormValues, formValues);
            const isFormClean = isFormDirty === false;
            const nextInitialFormValuesAreDifferent = !deepEqual(initialFormValues, nextInitialFormValues);

            if (hasIntialFormValuesDefined) {
                // We will set initialFormValues – and formValues if not set yet - in case they aren't set yet.
                this.setState(
                    (state): State => ({
                        ...state,
                        initialFormValues: nextInitialFormValues,
                        // We only set them if there is no form value set yet – that can happend because the candidate loading
                        // was slower than rendering the form and the first user input.
                        formValues: !!state.formValues ? state.formValues : nextInitialFormValues
                    })
                );
            } else if (isFormClean && nextInitialFormValuesAreDifferent) {
                this.setState(
                    (state): State => ({
                        ...state,
                        initialFormValues: nextInitialFormValues,
                        formValues: nextInitialFormValues
                    })
                );
            }
        }

        // If we aren't submitting the form right now we don't do anything – because another part of the application
        // did update the state and we don't want to take over the values before the user saved their changes.
        if (!isFormSubmitting) {
            return;
        }

        // We close the mobile view as soon as the update was completed – either successful or unsuccessful
        if (prevProps.updateStatus === 'updating' && nextProps.updateStatus !== 'updating') {
            this.setState(
                (state): State => ({
                    ...state,
                    showMobileView: false
                })
            );
        }

        // Successful case – we reset all states and update the notes with the values from the new candidate object.
        if (prevProps.updateStatus === 'updating' && nextProps.updateStatus === 'updated') {
            const nextInitialFormValues = nextCandidate ? getFormValuesFromCandidate(nextCandidate) : null;
            const nextFormValues = nextInitialFormValues;

            this.setState(
                (state): State => ({
                    ...state,
                    isFormSubmitting: false,

                    initialFormValues: nextInitialFormValues,
                    formValues: nextFormValues
                })
            );

            /* We delay the close of the mobile view because LoaderButton takes some time to stop the animation
             * and could close the view because react-overlays doesn't check for the element on transitionEnd.
             * This may be fixed with the next release (cur: 0.7.0) which will use react-transition-group which
             * hopefully will handle the behavior correctly.
             */
            setTimeout(() => {
                this.setState(
                    (state): State => ({
                        ...state,
                        showMobileView: false
                    })
                );
            }, 300);
        }
    }

    handleShowMobileView() {
        this.setState({ showMobileView: true });
    }

    handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const name = event.target.name as keyof FormValues;
        const value = event.target.value;

        this.setState(
            (state): State => {
                const nextFormValues = {
                    // In case the form values are still `null` – what can happen if the user made changes before the
                    // candidate was loaded – we want to normalize them so that we have a complete/valid values object.
                    ...normalizeFormValues(state.formValues),
                    [name]: value.replace(/^\s*/, '')
                };

                return {
                    ...state,
                    formValues: nextFormValues
                };
            }
        );
    }

    handleKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (event.ctrlKey && (event.keyCode === 10 || event.keyCode === 13)) {
            this.handleUpdateCandidate();
        }
    }

    handleCancel() {
        this.setState(
            (state): State => ({
                ...state,
                formValues: state.initialFormValues,
                showMobileView: false
            })
        );
    }

    handleSubmit(event: React.SyntheticEvent) {
        event.preventDefault();

        this.handleUpdateCandidate();
    }

    handleUpdateCandidate() {
        const { candidateId, updateCandidate } = this.props;
        const { formValues } = this.state;

        const updateData = getCandidateUpdateDataFromFormValues(formValues as FormValues);

        updateCandidate(candidateId, updateData);

        this.setState(
            (state): State => ({
                ...state,
                isFormSubmitting: true
            })
        );
    }

    render() {
        const { formValues, showMobileView, isFormSubmitting } = this.state;

        const normalizedFormValues = normalizeFormValues(formValues);
        const isSubmitButtonDisabled = isFormSubmitting;

        return (
            <span>
                <div className="CandidateNotesItem__desktop-view">
                    <CandidateDetailsRow label={<FormattedMessage id="CANDIDATE_NOTES_ITEM.TITLE" />} layout="stacked">
                        <form onSubmit={this.handleSubmit}>
                            <FormField
                                id="candidate-notes-item-notes"
                                labelText={<FormattedMessage id="CANDIDATE_NOTES_ITEM.LABEL" />}
                            >
                                <Input
                                    value={normalizedFormValues.notes}
                                    name="notes"
                                    multiline
                                    className="CandidateNotesItem__desktop-view__textarea"
                                    onChange={this.handleChange}
                                    onKeyDown={this.handleKeyDown}
                                />
                            </FormField>
                            <div className="tf-form-actions CandidateNotesItem__desktop-view__button">
                                <LoaderButton
                                    type="submit"
                                    onClick={this.handleSubmit}
                                    loading={isFormSubmitting}
                                    disabled={isSubmitButtonDisabled}
                                    typeStyle="raised"
                                    variationStyle="brand"
                                >
                                    <FormattedMessage id="CANDIDATE_NOTES_ITEM.ACTION.SUBMIT" />
                                </LoaderButton>
                            </div>
                        </form>
                    </CandidateDetailsRow>
                </div>
                <div className="CandidateNotesItem__mobile-bar" onClick={this.handleShowMobileView}>
                    <FormattedMessage id="CANDIDATE_NOTES_ITEM.ACTION.SHOW_NOTES" />
                </div>

                <CandidateNotesMobilePage
                    open={showMobileView}
                    onCancel={this.handleCancel}
                    notes={normalizedFormValues.notes}
                    isSaving={isFormSubmitting}
                    onChange={this.handleChange}
                    onSubmit={this.handleSubmit}
                />
            </span>
        );
    }
}

function getFormValuesFromCandidate(candidate: Candidate): FormValues {
    return {
        notes: candidate.notes
    };
}

function getCandidateUpdateDataFromFormValues(formValues: FormValues): Partial<Candidate> {
    return {
        notes: formValues.notes
    };
}

function normalizeFormValues(formValues: FormValues | null): FormValues {
    return {
        notes: formValues?.notes || ''
    };
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => {
    const candidateId = props.candidateId;

    return {
        candidate: getCandidate(state, candidateId),
        updateStatus: getCandidateUpdateStatus(state, candidateId)
    };
};

const mapDispatchToProps: ConnectedDispatchProps = {
    updateCandidate
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateNotesItem);
