import React from 'react';
import { DropTargetMonitor } from 'react-dnd';

import { DragObject } from './types';

export type DragStrategy = (
    ref: any,
    id: string,
    getIndexByItemId: (id: DragObject['id']) => number,
    moveItem: (fromIndex: number, toIndex: number) => void
) => (item: DragObject, monitor: DropTargetMonitor) => void;

export const horizontalDragStrategy: DragStrategy = (
    ref: React.RefObject<HTMLElement | null>,
    id: string,
    getIndexByItemId: (id: DragObject['id']) => number,
    moveItem: (fromIndex: number, toIndex: number) => void
) => {
    return (item: DragObject, monitor: DropTargetMonitor) => {
        if (!ref.current) {
            return;
        }

        const dragIndex = getIndexByItemId(item.id);
        const hoverIndex = getIndexByItemId(id);

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        if (!clientOffset) {
            return;
        }

        // Get pixels to the top
        const hoverClientX = clientOffset.x - hoverBoundingRect.left;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
            return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
            return;
        }
        // Time to actually perform the action
        moveItem(dragIndex, hoverIndex);
    };
};

export const verticalDragStrategy: DragStrategy = (
    ref: React.RefObject<HTMLElement | null>,
    id: string,
    getIndexByItemId: (id: DragObject['id']) => number,
    moveItem: (fromIndex: number, toIndex: number) => void
) => {
    return (item: DragObject, monitor: DropTargetMonitor) => {
        if (!ref.current) {
            return;
        }

        const dragIndex = getIndexByItemId(item.id);
        const hoverIndex = getIndexByItemId(id);

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        if (!clientOffset) {
            return;
        }

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            return;
        }

        // Time to actually perform the action
        moveItem(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        // item.index = hoverIndex;
    };
};
