import { JobExperience } from './types';

export const nonFeedProviders = ['recruiter', 'inhouse'];

export const CAREER_STATUTES = [
    'regular-employment',
    'fixed-term-contract',
    'project-work',
    'trainee',
    'internship',
    'working-student'
];

export const EXPERIENCES: JobExperience[] = ['without-experience', 'with-experience', 'with-staff-responsibility'];

export const FUNCTIONS = [
    'marketing-and-communications',
    'distribution-and-sales',
    'administration-and-secretarial',
    'staff',
    'purchasing-materials-management-and-logistics',
    'it',
    'engineers-and-technical-occupations',
    'design-and-architecture',
    'finances'
];

export const SECTORS = [
    'agriculture-forestry-fishing-and-horticulture',
    'mining',
    'food-related-products',
    'textiles-clothing-and-leather-goods',
    'media-film-radio-television-publishing',
    'chemical-petrochemical-industry',
    'pharmaceutical-industry',
    'metal-industry',
    'automotive-suppliers',
    'aviation-aerospace',
    'consumer-goods-and-consumer-durables',
    'wood-furniture-industry',
    'printing-paper-packaging-industry',
    'glass-ceramics-manufacturing-processing',
    'machinery-equipment',
    'electrical-and-optical',
    'miscellaneous-manufacturing-industries',
    'energy-water-supply-and-disposal',
    'construction-industry',
    'craft',
    'capitals-retail',
    'hotel-catering',
    'transportation-logistics',
    'telecommunications',
    'banks',
    'financial-services',
    'insurance',
    'real-estate',
    'it-internet',
    'science-research',
    'consulting-auditing-law',
    'agency-advertising-marketing-pr',
    'personal-services',
    'other-services',
    'public-services-organizations',
    'education-training',
    'health-social-services',
    'leisure-tourism-culture-sport',
    'other-sectors',
    'medical'
];

export const WORKTYPES = ['full-time', 'part-time'];
