import { PropertyValue } from '../../types/utilities';

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

export function createActionTypeCreator<Base extends string>(base: Base) {
    return <Name extends string>(name: Name) => {
        // eslint-disable-next-line
        return `${base}/${name}` as `${Base}/${Name}`;
    };
}

type RequestActionTypes<
    RequestName extends string = string,
    SuccessName extends string = string,
    FailureName extends string = string
> = {
    REQUEST: RequestName;
    SUCCESS: SuccessName;
    FAILURE: FailureName
};

export function createRequestActionTypes<Base extends string>(base: Base): RequestActionTypes<`${Base}/${typeof REQUEST}`, `${Base}/${typeof SUCCESS}`, `${Base}/${typeof FAILURE}`> {
    const createName = createActionTypeCreator(base);

    const types: any = [REQUEST, SUCCESS, FAILURE].reduce((acc: {}, type: string): {} => {
        acc[type] = createName(type);

        return acc;
    }, {});

    return types;
}

type SetActionTypes<Base extends string, Set extends ReadonlyArray<string>> = {
    [key in Set[number]]: `${Base}/${key}`
};

export function createSetActionTypes<Base extends string, Set extends ReadonlyArray<string>>(base: Base, set: Set): SetActionTypes<Base, Set> {
    const createName = createActionTypeCreator<Base>(base);

    const types = set.reduce<Partial<SetActionTypes<Base, Set>>>((acc, type: string): {} => {
        acc[type] = createName(type);

        return acc
    }, {});

    return types as SetActionTypes<Base, Set>;
}

type PrepareActionType<Payload, Meta> = (
    ...args
) => {
    payload: Payload;
    meta?: Meta;
};

type ActionCreatorWithoutPayload<Type extends string = string> = () => {
    type: Type;
    payload: void;
    meta: void;
};

export type ActionCreatorWithPayload<Payload, Meta, Params extends any[], Type extends string = string> = (
    ...args: Params
) => {
    type: Type;
    payload: Payload;
    meta: Meta;
};

export function createAction<Type extends string = string>(type: Type): ActionCreatorWithoutPayload<Type>;

export function createAction<
    Payload,
    Meta,
    PrepareAction extends PrepareActionType<Payload, Meta>,
    Type extends string = string
>(
    type: Type,
    prepareAction: PrepareAction
): ActionCreatorWithPayload<
    ReturnType<PrepareAction>['payload'],
    PropertyValue<ReturnType<PrepareAction>, 'meta'>,
    Parameters<PrepareAction>,
    Type
>;

export function createAction<
    Payload,
    Meta,
    PrepareAction extends PrepareActionType<Payload, Meta>,
    Type extends string = string
>(type: Type, prepareAction?: PrepareAction) {
    const actionCreator = (...args) => {
        if (typeof prepareAction === 'function') {
            const preparedAction = prepareAction(...args);

            if (!preparedAction) {
                throw new Error('prepareAction did not return an object');
            }

            return {
                type,
                payload: preparedAction.payload,
                meta: preparedAction.meta ?? undefined
            };
        }

        return {
            type,
            payload: undefined,
            meta: undefined
        };
    };

    return actionCreator;
}
