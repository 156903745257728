import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormField, Input } from '@truffls/design-system-react';

import scrolled, { ScrolledProps } from '../Scrolled';

import LoaderButton from '../LoaderButtonV2';
import MobilePageContainer from '../MobilePageContainer';
import NavigationBar, { NavigationBarText, NavigationBarCloseAction } from '../NavigationBar';
import BottomActionBar from '../BottomActionBar';

import './CandidateNotesMobilePage.style.scss';

type Props = ScrolledProps & {
    open: boolean;
    notes: string;
    isSaving: boolean;

    onCancel: () => void;
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onSubmit: (notes: React.FormEvent<HTMLFormElement>) => void;
};

class CandidateNotesMobilePage extends React.Component<Props> {
    constructor(props: Props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
        this.props.onChange(event);
    }

    handleKeyDown(event) {
        if (event.ctrlKey && (event.keyCode === 10 || event.keyCode === 13)) {
            this.handleSubmit(event);
        }
    }

    handleSubmit(event) {
        this.props.onSubmit(event);
    }

    render() {
        const {
            open,
            scrolled,
            notes,
            isSaving,

            onScroll,
            onCancel
        } = this.props;

        return (
            <form onSubmit={this.handleSubmit}>
                <MobilePageContainer show={open} transitionName="slideUp">
                    <NavigationBar
                        noShadow={!scrolled}
                        centerElement={
                            <NavigationBarText>
                                <FormattedMessage id="CANDIDATE_NOTES_MOBILE_PAGE.TITLE" />
                            </NavigationBarText>
                        }
                        rightElement={<NavigationBarCloseAction onClick={onCancel} />}
                    />
                    <div className="CandidateNotesMobilePage__content" onScroll={onScroll}>
                        <FormField id="notes" labelText={<FormattedMessage id="CANDIDATE_NOTES_MOBILE_PAGE.LABEL" />}>
                            <Input
                                name="notes"
                                value={notes}
                                multiline
                                className="CandidateNotesMobilePage__input"
                                onChange={this.handleChange}
                                onKeyDown={this.handleKeyDown}
                            />
                        </FormField>
                    </div>
                    <BottomActionBar>
                        <LoaderButton
                            type="submit"
                            onClick={this.handleSubmit}
                            loading={isSaving}
                            disabled={isSaving}
                            typeStyle="flat"
                            variationStyle="brand"
                        >
                            <FormattedMessage id="CANDIDATE_NOTES_MOBILE_PAGE.ACTION.SAVE" />
                        </LoaderButton>
                    </BottomActionBar>
                </MobilePageContainer>
            </form>
        );
    }
}

export default scrolled(CandidateNotesMobilePage);
