import { useState, useEffect, ChangeEvent, FocusEvent } from 'react';
import { enhanceMeta, Meta, EnhancedMeta } from './utils';

export type FieldMeta<M extends Meta = Meta> = EnhancedMeta<M> & { onInactive: EnhancedMeta<M> };

export function useFieldMeta<M extends Meta>(meta: M): FieldMeta<M> {
    const enhancedMeta = enhanceMeta<M>(meta);

    const [onInactive, setOnInactive] = useState<EnhancedMeta<M>>(enhancedMeta);

    useEffect(() => {
        if (enhancedMeta.inactive) {
            setOnInactive(enhancedMeta);
        }
    }, [enhancedMeta.inactive]);

    return {
        ...enhancedMeta,

        onInactive
    };
}

export function useAutoFocus(ref, autoFocus) {
    useEffect(() => {
        if (autoFocus && !!ref.current) {
            ref.current.focus();
        }
    }, []);
}

type SimpleMeta = {
    active: boolean;
    valid: boolean;
    invalid: boolean;
    pristine: boolean;
    dirty: boolean;
    submitFailed: boolean;
};

type UseSimpleFieldProps = {
    value: any;
    initialValue: any;
    onChange: (event: ChangeEvent) => void;
    validate?: (value: any, allValues?: any, props?: any, name?: any) => any;
    submitFailed: boolean;
};

type SimpleFieldInputProps = {
    value: any;
    onChange: (event: ChangeEvent) => void;
    onFocus: (event: FocusEvent) => void;
    onBlur: (event: FocusEvent) => void;
};

type SimpleFieldMetaProps = FieldMeta<SimpleMeta>;

export function useSimpleField({
    value,
    initialValue,
    onChange,
    validate = () => null,
    submitFailed
}: UseSimpleFieldProps): { input: SimpleFieldInputProps; meta: SimpleFieldMetaProps } {
    const [isFocused, setIsFocused] = useState(false);

    const onFocus = () => {
        setIsFocused(true);
    };
    const onBlur = () => {
        setIsFocused(false);
    };

    const input: SimpleFieldInputProps = {
        value,
        onChange,
        onFocus,
        onBlur
    };

    const validationResult = validate(value);

    const valid = !validationResult;
    const pristine = value === initialValue;

    const plainMeta: SimpleMeta = {
        active: isFocused,
        valid,
        invalid: !valid,
        pristine,
        dirty: !pristine,
        submitFailed
    };
    const meta = useFieldMeta(plainMeta);

    return {
        input,
        meta
    };
}
