import { useCallback } from 'react';
import { useFormState } from 'react-final-form';
import { hasError, HasErrorMeta } from '../../utils/form/state';
import { JobFormPageValues, JOB_FORM_PAGE_VALIDATION_LEVEL_FULL } from './JobFormPage.constants';

export function useJobFormPageHasError() {
    const { values } = useFormState<JobFormPageValues>({
        subscription: {
            values: true
        }
    });

    const validationLevel = values['_validation-level'];

    return useCallback(
        (value: any, meta: HasErrorMeta) => {
            if (validationLevel === JOB_FORM_PAGE_VALIDATION_LEVEL_FULL && !meta.dirty) {
                return meta.invalid ?? false;
            }

            return hasError(value, meta);
        },
        [validationLevel]
    );
}
