import { getBrowserRequestedResolvedLocale } from '../../i18n/locale/browser';
import { I18NState } from './reducer';

export function preloadI18NState(): Partial<I18NState> {
    const locale = getBrowserRequestedResolvedLocale();

    return {
        locale: locale ?? undefined
    };
}
