import * as React from 'react';

import { FormattedMessage } from 'react-intl';

import Dialog from '../Dialog';
import DialogIcon from '../DialogIcon';
import DialogTitle from '../DialogTitle';
import DialogText from '../DialogText';

import requestTokenSentIcon from './images/ic_request-token-sent.svg';

type Props = {
    email: string;
};

const RequestTokenRequestSuccessDialog = ({ email }: Props) => {
    return (
        <Dialog>
            <DialogIcon src={requestTokenSentIcon} />
            <DialogTitle>
                <FormattedMessage id="REQUEST_TOKEN_REQUEST_SUCCESS_DIALOG.TITLE" />
            </DialogTitle>
            <DialogText>
                <FormattedMessage id="REQUEST_TOKEN_REQUEST_SUCCESS_DIALOG.TEXT" values={{ email }} />
            </DialogText>
        </Dialog>
    );
};

export default RequestTokenRequestSuccessDialog;
