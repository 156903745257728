import * as React from 'react';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import DropdownMenu from 'react-bootstrap/lib/DropdownMenu';

import './TemplatesDropdown.style.scss';

import TemplatesDropdownItem from './TemplatesDropdownItem';

import { Template } from '../../modules/templates/types';

type TemplateId = Template['id'];

export type Props = {
    templates: Template[];
    selectedTemplateId: TemplateId | null;
    onSelect: (templateId: TemplateId) => void;
    direction: 'up' | 'down';
};

type State = {
    isOpen: boolean;
};

class TemplatesDropdown extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { isOpen: false };

        this.toggleOpen = this.toggleOpen.bind(this);
    }

    toggleOpen() {
        this.setState((state) => ({
            isOpen: !state.isOpen
        }));
    }

    onSelect(templateId: TemplateId) {
        this.toggleOpen();
        this.props.onSelect(templateId);
    }

    render() {
        const { isOpen } = this.state;
        const { templates, selectedTemplateId, direction = 'down' } = this.props;

        const menuHasScroll = templates.length > 10;

        let buttonContent: React.ReactNode = null;
        if (selectedTemplateId !== null) {
            const selectedTemplate = templates.find((template) => template.id === selectedTemplateId);
            if (selectedTemplate) {
                buttonContent = <TemplatesDropdownItem template={selectedTemplate} />;
            }
        } else if (templates.length > 0) {
            const selectedTemplate = templates[0];
            buttonContent = <TemplatesDropdownItem template={selectedTemplate} />;
        }

        return (
            <Dropdown
                id="templates-dropdown"
                className="TemplatesDropdown"
                dropup={direction === 'up'}
                open={isOpen}
                onToggle={this.toggleOpen}
            >
                <ToggleButton bsRole="toggle">{buttonContent}</ToggleButton>
                <DropdownMenu
                    bsRole="menu"
                    data-testid="dropdown-menu"
                    className={classNames('TemplatesDropdown__menu', {
                        'TemplatesDropdown__menu--scrollable': menuHasScroll
                    })}
                >
                    {templates.map((template) => (
                        <button
                            key={template.id}
                            type="button"
                            className="TemplatesDropdown__menu__item"
                            onClick={() => this.onSelect(template.id)}
                        >
                            <TemplatesDropdownItem template={template} />
                        </button>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    }
}

// We need to specify optional props because react-boostrap pass them under the hood
type ToggleButtonProps = {
    bsRole?: string;

    id?: string;
    open?: boolean;
    onClick?: (event: React.MouseEvent) => void;
    onKeyDown?: (event: React.KeyboardEvent) => void;
    children: React.ReactNode;
};

// This has to be class because `Dropdown` expects that
class ToggleButton extends React.Component<ToggleButtonProps> {
    render() {
        const { id, open, onClick, onKeyDown, children } = this.props;

        return (
            <button
                id={id}
                type="button"
                data-testid="dropdown-button"
                className={classNames('TemplatesDropdown__button', {
                    'TemplatesDropdown__button--open': !!open
                })}
                onClick={onClick}
                onKeyDown={onKeyDown}
                role="button"
                aria-haspopup={true}
                aria-expanded={!!open}
            >
                {children}
            </button>
        );
    }
}

export default TemplatesDropdown;
