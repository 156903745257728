import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import SignupFormStepHeadline from '../SignupFormStepHeadline/';
import { getLoginRoute } from '../../routes';

import './SignupRequestTokenSent.style.scss';

type Props = {
    destinationUrl?: string;
};

const SignupRequestTokenSent = (props: Props): React.ReactElement => {
    return (
        <div className="SignupRequestTokenSent">
            <SignupFormStepHeadline className="SignupRequestTokenSent__headline">
                <FormattedMessage id="SIGNUP_REQUEST_TOKEN_SENT.TITLE" />
            </SignupFormStepHeadline>

            <p>
                <FormattedMessage id="SIGNUP_REQUEST_TOKEN_SENT.DESCRIPTION.FIRST" />
            </p>

            <p>
                <FormattedMessage id="SIGNUP_REQUEST_TOKEN_SENT.DESCRIPTION.SECOND" />
            </p>

            <Link to={getLoginRoute({ destinationUrl: props.destinationUrl })}>
                <FormattedMessage id="SIGNUP_REQUEST_TOKEN_SENT.LINK.LOGIN" />
            </Link>
        </div>
    );
};

export default SignupRequestTokenSent;
