import { History, Location, createHashHistory, createPath } from 'history';
import { trackPageview } from './services/analytics';
import { canTrackRoute } from './routes/routesAnalytics';
import { routesRegistry } from './routes/registry';

export const history = createHashHistory();

export function getHistory() {
    return history;
}

export function registerHistoryListener(history: History) {
    return history.listen((location: Location) => {
        scrollToTop();

        const path = createPath(location);

        if (canTrackRoute(routesRegistry, path)) {
            trackPageview(path);
        }
    });
}

function scrollToTop(): void {
    window.scrollTo(0, 0);
}
