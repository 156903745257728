import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@truffls/design-system-react';

import { Job } from '../../modules/jobs/types';

import classes from './JobDialogArchive.module.scss';

export type JobDialogArchiveProps = {
    open: boolean;
    job: Job;
    onClose: () => void;
    onSubmit: (arg1: boolean) => void;
};

type State = {
    doNotShowAgain: boolean;
};

class JobDialogArchive extends React.Component<JobDialogArchiveProps, State> {
    constructor(props: JobDialogArchiveProps) {
        super(props);

        this.state = {
            doNotShowAgain: false
        };

        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            doNotShowAgain: event.target.checked
        });
    }

    handleClose() {
        const { onClose } = this.props;

        this.setState({
            doNotShowAgain: false
        });

        onClose();
    }

    handleSubmit() {
        const { onSubmit } = this.props;
        const { doNotShowAgain } = this.state;

        onSubmit(doNotShowAgain);
    }

    render() {
        const { open } = this.props;
        const { doNotShowAgain } = this.state;

        return (
            <Dialog open={open} maxWidth="lg" onBackdropClick={this.handleClose}>
                <DialogTitle>
                    <FormattedMessage id="JOB_ARCHIVE_DIALOG.TITLE.premium" />
                </DialogTitle>
                <DialogContent>
                    {' '}
                    <FormattedMessage id="JOB_ARCHIVE_DIALOG.DESCRIPTION.FIRST" />{' '}
                    <FormattedMessage id="JOB_ARCHIVE_DIALOG.DESCRIPTION.SECOND.premium" />
                </DialogContent>
                <DialogActions>
                    <JobDialogArchiveDoNotShowAgainCheckbox
                        checked={doNotShowAgain}
                        onChange={this.handleCheckboxChange}
                    />
                    <Button typeStyle="flat" variationStyle="normal" component="button" onClick={this.handleClose}>
                        <FormattedMessage id="CONSTANT.DIALOG.CANCEL" />
                    </Button>
                    <Button
                        typeStyle="flat"
                        variationStyle="destructive"
                        component="button"
                        onClick={this.handleSubmit}
                    >
                        <FormattedMessage id="JOB_ARCHIVE_DIALOG.ACTION.SUBMIT.premium" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default JobDialogArchive;

type JobDialogArchiveDoNotShowAgainCheckboxProps = {
    checked: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
};

function JobDialogArchiveDoNotShowAgainCheckbox({ checked, onChange }: JobDialogArchiveDoNotShowAgainCheckboxProps) {
    return (
        <div className={classes.doNotShowAgain}>
            <span className="tf-checkbox">
                <input type="checkbox" id="job-archive-dialog-checkbox" checked={checked} onChange={onChange} />

                <label className="tf-checkbox__label" htmlFor="job-archive-dialog-checkbox">
                    <span className="tf-checkbox__faux"></span>
                    <span className="tf-checkbox__label-text">
                        <FormattedMessage id="CONSTANT.DIALOG.DO_NOT_SHOW_AGAIN" />
                    </span>
                </label>
            </span>
        </div>
    );
}
