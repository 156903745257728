import { ParsedUrlQuery } from 'querystring';
import { createRouteString, extractParams } from './utils';
import { RouteParamParseError } from './utils/errors';

/**
 * Request Token Login route
 */

export const LOGIN_REQUEST_TOKEN_PATH = '/login/:requestToken';

export type RequestTokenLoginRouteParams = {
    requestToken: string;
};

export type GetRequestTokenLoginRouteProps = {
    destinationUrl?: string;
};

export type RequestTokenLoginRouteQuery = {
    destinationUrl?: string;
};

export type RequestTokenLoginRouteRawQuery = {
    'destination-url'?: string;
};

export function getRequestTokenLoginRoute(
    requestToken: RequestTokenLoginRouteParams['requestToken'],
    { destinationUrl }: GetRequestTokenLoginRouteProps = {}
) {
    let rawQuery: RequestTokenLoginRouteRawQuery = {};

    if (typeof destinationUrl === 'string') {
        rawQuery['destination-url'] = destinationUrl;
    }

    return createRouteString(
        LOGIN_REQUEST_TOKEN_PATH,
        {
            requestToken
        },
        rawQuery
    );
}

export function parseRequestTokenLoginRouteParams(input: string | ParsedUrlQuery): RequestTokenLoginRouteParams {
    const rawParams = typeof input === 'string' ? extractParams(LOGIN_REQUEST_TOKEN_PATH, input) : input;

    const params: Partial<RequestTokenLoginRouteParams> = {};
    if (typeof rawParams.requestToken === 'string' && rawParams.requestToken.length > 0) {
        params.requestToken = rawParams.requestToken;
    } else {
        throw new RouteParamParseError(LOGIN_REQUEST_TOKEN_PATH, rawParams, 'requestToken', input);
    }

    return params as RequestTokenLoginRouteParams;
}

export function parseRequestTokenLoginRouteQuery(
    rawQuery: RequestTokenLoginRouteRawQuery | ParsedUrlQuery
): RequestTokenLoginRouteQuery {
    const query: RequestTokenLoginRouteQuery = {};

    if (typeof rawQuery['destination-url'] === 'string') {
        query.destinationUrl = rawQuery['destination-url'];
    }

    return query;
}
