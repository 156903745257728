import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import Dialog, { DialogProps } from '../DialogV2';
import DialogTitle from '../DialogV2/DialogTitle';
import DialogContent from '../DialogV2/DialogContent';
import DialogActions from '../DialogV2/DialogActions';
import Button from '../Button';

type Props = DialogProps & {
    onSubmit: () => void;
};

class JobDialogDiscard extends React.Component<Props> {
    render() {
        const { open, onClose, onSubmit } = this.props;

        return (
            <Dialog open={open} onClose={onClose}>
                <DialogTitle>
                    <FormattedMessage id="JOB_DISCARD_DIALOG.TITLE" />
                </DialogTitle>
                <DialogContent>
                    <FormattedMessage id="JOB_DISCARD_DIALOG.DESCRIPTION" />
                </DialogContent>
                <DialogActions>
                    <Button typeStyle="flat" variationStyle="normal" component="button" onClick={onClose}>
                        <FormattedMessage id="CONSTANT.DIALOG.CANCEL" />
                    </Button>
                    <Button typeStyle="flat" variationStyle="destructive" component="button" onClick={onSubmit}>
                        <FormattedMessage id="JOB_DISCARD_DIALOG.ACTION.SUBMIT" />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default JobDialogDiscard;
