export const toString = (v) => {
    return typeof v === 'undefined' || v === null ? '' : v + '';
};

export function convertFalseToEmptyString(value) {
    return value === false ? '' : value;
}

export function trimString(value) {
    if (!value) {
        return '';
    }
    return value.trim();
}
