import React from 'react';
import { useSelector } from 'react-redux';
import { FETCH_STATUS_NONE } from '../../../modules/constants';
import { getListByCoordinatesEntries, getListByCoordinatesFetchStatus } from '../../../modules/jobRegions/selectors';
import { Coordinates, JobRegion } from '../../../modules/jobRegions/types';
import { FetchStatus } from '../../../modules/types';
import { ApplicationState } from '../../../store/types';
import { useField } from '../../../utils/form/react-final-form';
import { JOB_EDIT_LOCATION_FORM_FIELD_NAME, JobFormLocationFormFieldValue } from '../JobFormLocationFormField';

import JobFormLocationRegionWarningField from './JobFormLocationRegionWarningField';

export type JobFormLocationRegionWarningFieldContainerProps = {};

function JobFormLocationRegionWarningFieldContainer({}: JobFormLocationRegionWarningFieldContainerProps) {
    const field = useField<JobFormLocationFormFieldValue, HTMLElement>(JOB_EDIT_LOCATION_FORM_FIELD_NAME, {
        subscription: {
            value: true
        }
    });

    const selector = React.useMemo(() => {
        return makeSelector(field.input.value);
    }, [field.input.value]);

    const { jobRegions, jobRegionsFetchStatus } = useSelector(selector);

    return <JobFormLocationRegionWarningField jobRegionsFetchStatus={jobRegionsFetchStatus} jobRegions={jobRegions} />;
}

export default JobFormLocationRegionWarningFieldContainer;

type JobFormLocationRegionWarningFieldContainerSelectorReturnProps = {
    jobRegionsFetchStatus: FetchStatus;
    jobRegions: JobRegion[];
};

function makeSelector(value: JobFormLocationFormFieldValue) {
    let coordinates: Coordinates | null = null;

    if (typeof value?.latitude === 'number' && typeof value?.longitude === 'number') {
        coordinates = {
            latitude: value.latitude,
            longitude: value.longitude
        };
    }

    return (state: ApplicationState): JobFormLocationRegionWarningFieldContainerSelectorReturnProps => {
        if (!coordinates) {
            return {
                jobRegionsFetchStatus: FETCH_STATUS_NONE,
                jobRegions: []
            };
        }

        const jobRegionsFetchStatus = getListByCoordinatesFetchStatus(state, coordinates);
        const jobRegions = getListByCoordinatesEntries(state, coordinates);

        return {
            jobRegionsFetchStatus,
            jobRegions
        };
    };
}
