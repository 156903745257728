import { createSelector } from 'reselect';

import { MOUNT } from './reducer';

import { UserState as State, State as ApplicationState } from './reducer';

function getRoot(state: ApplicationState): State {
    return state[MOUNT];
}

export const getFetchStatus = createSelector(getRoot, (state) => state.fetchStatus);

export const getEntity = createSelector(getRoot, (state) => {
    return state.entity;
});

export const getUser = getEntity;
