import React from 'react';
import { useFieldArray } from 'react-final-form-arrays';

import SortableList from '../../../../../components/SortableList/SortableList';

import { useJobFormTasksEditMode } from '../JobFormTasksEditModeContext';

import JobTasksListItem from './JobFormTasksListItemFormField';
import JobTasksListItemPlaceholder from './JobFormTasksListItemPlaceholder';

import './JobFormTasksListFormFields.scss';

export type JobFormTasksListFormFieldsProps = {};

function JobFormTasksListFormFields({}: JobFormTasksListFormFieldsProps) {
    const { fields } = useFieldArray<string>('tasks');

    const names = fields.map((name) => name);

    const { fieldsInEditMode } = useJobFormTasksEditMode();
    const editMode = fieldsInEditMode.length > 0;

    const onMove = (fromIndex: number, toIndex: number) => {
        fields.move(fromIndex, toIndex);
    };

    const getIdFromItem = (item: string): string => {
        return item;
    };

    const disableDragging = editMode;

    return (
        <SortableList
            className="JobFormTasksListFormFields"
            disableSorting={disableDragging}
            items={names}
            getIdFromItem={getIdFromItem}
            onMoveItem={onMove}
            placeholder={<JobTasksListItemPlaceholder />}
            type="TASK"
        >
            {({ item: name, index }) => {
                const handleDeleteItem = () => {
                    fields.remove(index);
                };

                return <JobTasksListItem name={name} draggingDisabled={disableDragging} onDelete={handleDeleteItem} />;
            }}
        </SortableList>
    );
}

export default JobFormTasksListFormFields;
