type SubscriberCallback = (decision: boolean) => void;

type CookieBanner = {
    get: () => boolean;
    set: (decision: boolean) => void;
    subscribe: (callback: SubscriberCallback, options?: { once?: boolean }) => void;
    unsubscribe: (callback: SubscriberCallback) => void;
    open: () => void;
    language: (lang: string) => void;
};

export async function getCookieBanner(): Promise<CookieBanner> {
    const tcb = await waitForCookieBannerSetup();

    return new Promise((resolve) => {
        tcb((cookieBanner) => {
            resolve(cookieBanner);
        });
    });
}

function isReady(window: Window): window is Window & { tcb: Exclude<Window['tcb'], undefined> } {
    return typeof window.tcb === 'function';
}

function waitForCookieBannerSetup(timeoutInSec = 120): Promise<Exclude<Window['tcb'], undefined>> {
    const timeoutInMs = timeoutInSec * 1000;

    if (isReady(window)) {
        return Promise.resolve(window.tcb);
    }

    return new Promise((resolve, reject) => {
        let complete = false;
        let timeout;
        let waitInterval;

        timeout = setTimeout(() => {
            if (complete) {
                return;
            }

            complete = true;
            clearInterval(waitInterval);

            reject();
        }, timeoutInMs);

        waitInterval = setInterval(() => {
            if (complete) {
                clearInterval(waitInterval);
                return;
            }

            if (isReady(window)) {
                complete = true;

                clearInterval(waitInterval);
                clearTimeout(timeout);

                resolve(window.tcb);
            }
        }, 50);
    });
}
