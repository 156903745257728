import { combineReducers } from 'redux';

import { RESET, SET_STEP, SET_EMAIL_TAKEN_DECISION, RESET_EMAIL_TAKEN_DECISION } from './actions';

import { STEPS } from './constants';

// Types
import { Action } from '../../modules/types';
import { Step, EmailTakenDecision } from './constants';

// TODO: Use a more precise type
type StepState = Step;

export const initialStepState: StepState = STEPS[0];

export function stepReducer(state: StepState = initialStepState, action: Action): StepState {
    switch (action.type) {
        case RESET:
            return initialStepState;

        case SET_STEP:
            return action.payload.step;

        default:
            return state;
    }
}

export const initialEmailTakenDecisionState: EmailTakenDecision = 'none';

export function emailTakenDecisionReducer(
    state: EmailTakenDecision = initialEmailTakenDecisionState,
    action: Action
): EmailTakenDecision {
    switch (action.type) {
        case SET_EMAIL_TAKEN_DECISION:
            return action.payload.decision;

        case RESET_EMAIL_TAKEN_DECISION:
            return initialEmailTakenDecisionState;

        default:
            return state;
    }
}

export type State = {
    step: StepState;
    emailTakenDecision: EmailTakenDecision;
};

const rootReducer = combineReducers({
    step: stepReducer,
    emailTakenDecision: emailTakenDecisionReducer
});

export default rootReducer;
