import * as React from 'react';

import './SignupFormStepIndicator.style.scss';
import { FormattedMessage } from 'react-intl';

type SignupFormStepIndicatorProps = {
    steps: string[];
    currentStep: string;
    offset?: number;
};

function SignupFormStepIndicator({ steps, currentStep, offset = 0 }: SignupFormStepIndicatorProps) {
    const currentStepPosition = 1 + steps.findIndex((step) => step === currentStep);

    return (
        <div className="SignupFormStepIndicator">
            <FormattedMessage
                id="SIGNUP_FORM_STEP_INDICATOR.DESCRIPTION"
                values={{
                    // We want to make the second step look like the first step
                    currentStep: currentStepPosition + offset,
                    stepsCount: steps.length
                }}
            />
        </div>
    );
}

export default SignupFormStepIndicator;
