import { ParsedUrlQuery } from 'querystring';
import { ExtraDataError } from '../../utils/errors';

export class RouteParamParseError extends ExtraDataError {
    constructor(pathPattern: string, rawParams: ParsedUrlQuery, paramName: string, input: string | ParsedUrlQuery) {
        const rawValue = rawParams[paramName];

        super(`Invalid value '${rawValue}' for route parameter '${paramName}' for route '${pathPattern}'`, {
            path: input
        });
    }
}
