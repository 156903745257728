import * as React from 'react';

import useBreakpointMatch, { Match } from '../../hooks/useBreakpointMatch';
import { Breakpoint as BreakpointTypes } from '../../utils/viewport';

type RenderProps = {
    match: Match | null;
};

type Props<T extends React.ReactNode> = {
    on: BreakpointTypes;
    component?: React.ComponentType<RenderProps>;
    render?: (props: RenderProps) => T;
    children?: (props: RenderProps) => T;
};

function Breakpoint<T extends React.ReactNode>({
    on,
    component,
    render,
    children
}: Props<T>): T | React.ReactElement | null {
    const match = useBreakpointMatch(on);

    const props: RenderProps = { match };

    if (!!component) {
        return !!match ? React.createElement(component, props) : null;
    }

    if (!!render) {
        return !!match ? render(props) : null;
    }

    if (typeof children === 'function') {
        return children(props);
    }

    return null;
}

export default Breakpoint;
