import * as React from 'react';
import classNames from 'classnames';

import './style.scss';

type Props = {
    className?: string;
};

class LoadingWheel extends React.PureComponent<Props> {
    render() {
        const className = classNames('LoadingWheel', this.props.className);

        return <span className={className}></span>;
    }
}

export default LoadingWheel;
