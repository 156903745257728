import pathToRegexp, { compile, parse } from 'path-to-regexp';
import url from 'url';
import { LOGIN_REQUEST_TOKEN_PATH } from './authentication';
import { AUTOMATIC_REQUEST_TOKEN_REQUEST_PATH_PATTERN } from './automatic-request-token-request';
import {
    CHECKOUT_ADDRESS_PATH_PATTERN,
    CHECKOUT_FINISH_PATH_PATTERN,
    CHECKOUT_PATH_PATTERN,
    CHECKOUT_PRODUCT_PATH_PATTERN,
    CHECKOUT_STEP_PATH_PATTERN,
    CHECKOUT_SUCCESS_PATH_PATTERN
} from './checkout';
import { createPathnamePatternResolver } from './utils';

export function makeWithPrefix(path) {
    return `/#${path}`;
}

type OptionsWithDestinationUrl = {
    destinationUrl?: string;
};

type LoginOptions = OptionsWithDestinationUrl & {
    verificationSuccessful?: boolean;
};

export const PATH_PATTERNS = {
    LOGIN: '/login',
    LOGIN_REQUEST_TOKEN: LOGIN_REQUEST_TOKEN_PATH,
    LOGIN_TEMP_TOKEN: '/temp-login/:tempToken',
    SIGNUP: '/signup',
    PASSWORD_RESET: '/password-reset',
    PASSWORD_RESET_CONFIRM: '/password-reset/confirm/:uid/:token',
    REQUEST_TOKEN: '/magic-link',
    AUTOMATIC_REQUEST_TOKEN: AUTOMATIC_REQUEST_TOKEN_REQUEST_PATH_PATTERN,
    VERIFY_EMAIL: '/verify-email/:verificationToken',
    VERIFY_EMAIL_REQUEST: '/resend-verification',
    UNSUBSCRIBE_TEMP_RECRUITER: '/unsubscribe-temp-recruiter',
    JOBS: '/jobs',
    JOB: '/job/:jobId',
    JOB_EDIT: '/job/:jobId/edit',
    JOB_CREATE: '/job-create',
    JOB_NEW: '/job/new',
    JOB_CREATE_PARSE: '/job-create/parse',
    JOB_CREATE_COPY: '/job-create/copy',
    CANDIDATES: '/jobs/:jobId/candidates',
    CANDIDATES_NEW_MESSAGES: '/jobs/:jobId/candidates/messages',
    CANDIDATES_NOT_INTERESTING: '/jobs/:jobId/candidates/hidden',
    CANDIDATE: '/jobs/:jobId/candidates/:candidateId',
    CANDIDATE_CONVERSATION: '/jobs/:jobId/candidates/:candidateId/conversation',
    CANDIDATE_DOCUMENTS: '/jobs/:jobId/candidates/:candidateId/documents',
    PRICES: '/prices',
    CHECKOUT: CHECKOUT_PATH_PATTERN,
    CHECKOUT_STEP: CHECKOUT_STEP_PATH_PATTERN,
    CHECKOUT_PRODUCT: CHECKOUT_PRODUCT_PATH_PATTERN,
    CHECKOUT_ADDRESS: CHECKOUT_ADDRESS_PATH_PATTERN,
    CHECKOUT_FINISH: CHECKOUT_FINISH_PATH_PATTERN,
    CHECKOUT_SUCCESS: CHECKOUT_SUCCESS_PATH_PATTERN,
    SETTINGS_PROFILE: '/settings/profile',
    SETTINGS_COMPANY_PROFILE: '/settings/company-profile',
    SETTINGS_ADDRESS: '/settings/address',
    SETTINGS_ACCOUNT: '/settings/account',
    SETTINGS_TEMPLATES: '/settings/templates',
    SETTINGS_TEMPLATES_CREATE: '/settings/templates/create',
    SETTINGS_TEMPLATES_EDIT: '/settings/templates/:templateId/edit',
    CONTACT: '/contact'
} as const;

export const REDIRECTS: Array<[string, string]> = [
    ['/anonymous/jobs', PATH_PATTERNS.JOBS],
    ['/jobs/drafts', PATH_PATTERNS.JOBS]
];

/**
 * Thoose path patterns should be ignored and the tracker should check the next one in the list
 */
export const PATH_PATTERNS_TO_SKIP_FOR_TRACKING: string[] = [PATH_PATTERNS.CHECKOUT_STEP];

/**
 * Thoose path pattens should not be tracked if they match. This could be because they are a parent page that redirects
 * to the correct child page.
 */
export const PATH_PATTERNS_TO_EXCLUDE_FOR_TRACKING: string[] = [PATH_PATTERNS.CHECKOUT];

/**
 * Thoose paths should not be tracked if they match. This could be because they usually redirect to another path.
 */
export const PATHS_TO_EXLCUDE_FOR_TRACKING: string[] = [...REDIRECTS.map(([from]) => from), '/'];

export const BACK_ROUTES = {
    [PATH_PATTERNS.AUTOMATIC_REQUEST_TOKEN]: null,
    [PATH_PATTERNS.JOB]: PATH_PATTERNS.JOBS,
    [PATH_PATTERNS.JOB_EDIT]: PATH_PATTERNS.JOB,
    [PATH_PATTERNS.JOB_CREATE]: PATH_PATTERNS.JOBS,
    [PATH_PATTERNS.JOB_NEW]: PATH_PATTERNS.JOB_CREATE,
    [PATH_PATTERNS.JOB_CREATE_PARSE]: PATH_PATTERNS.JOB_CREATE,
    [PATH_PATTERNS.JOB_CREATE_COPY]: PATH_PATTERNS.JOB_CREATE,
    [PATH_PATTERNS.CANDIDATES]: PATH_PATTERNS.JOBS,
    [PATH_PATTERNS.CANDIDATES_NEW_MESSAGES]: PATH_PATTERNS.CANDIDATES,
    [PATH_PATTERNS.CANDIDATES_NOT_INTERESTING]: PATH_PATTERNS.CANDIDATES,
    [PATH_PATTERNS.CANDIDATE]: PATH_PATTERNS.CANDIDATES,
    [PATH_PATTERNS.CANDIDATE_CONVERSATION]: PATH_PATTERNS.CANDIDATES,
    [PATH_PATTERNS.CANDIDATE_DOCUMENTS]: PATH_PATTERNS.CANDIDATES,
    [PATH_PATTERNS.SETTINGS_PROFILE]: PATH_PATTERNS.JOBS,
    [PATH_PATTERNS.SETTINGS_COMPANY_PROFILE]: PATH_PATTERNS.JOBS,
    [PATH_PATTERNS.SETTINGS_ADDRESS]: PATH_PATTERNS.JOBS,
    [PATH_PATTERNS.SETTINGS_ACCOUNT]: PATH_PATTERNS.JOBS
};

export const NO_BACK_ROUTES = [
    '/',
    PATH_PATTERNS.LOGIN,
    PATH_PATTERNS.SIGNUP,
    PATH_PATTERNS.JOBS,
    PATH_PATTERNS.AUTOMATIC_REQUEST_TOKEN,
    PATH_PATTERNS.CHECKOUT_SUCCESS
];

export { getAutomaticRequestTokenRequestRoute } from './automatic-request-token-request';
export {
    CHECKOUT_PROCESS_STEPS,
    CHECKOUT_STEPS,
    CHECKOUT_STEP_ADDRESS,
    CHECKOUT_STEP_FINISH,
    CHECKOUT_STEP_PRODUCT,
    CHECKOUT_STEP_SUCCESS,
    getCheckoutAddressRoute,
    getCheckoutFinishRoute,
    getCheckoutProductRoute,
    getCheckoutRoute,
    getCheckoutStepRoute,
    getCheckoutSuccessRoute
} from './checkout';
export type {
    CheckoutAddressRouteParams,
    CheckoutFinishRouteParams,
    CheckoutProcessStep,
    CheckoutProductRouteParams,
    CheckoutRouteParams,
    CheckoutStep,
    CheckoutStepRouteParams,
    CheckoutSuccessRouteParams
} from './checkout';

export function getLoginRoute(options: LoginOptions = {}) {
    const query = {};

    if (typeof options.verificationSuccessful !== 'undefined') {
        query['verification-successful'] = options.verificationSuccessful;
    }

    if (typeof options.destinationUrl !== 'undefined') {
        query['destination-url'] = options.destinationUrl;
    }

    return url.format({
        pathname: compile(PATH_PATTERNS.LOGIN)(),
        query
    });
}

export function getSignupRoute(options: OptionsWithDestinationUrl = {}): string {
    const query = {};

    if (!!options.destinationUrl) {
        query['destination-url'] = options.destinationUrl;
    }

    return url.format({
        pathname: compile(PATH_PATTERNS.SIGNUP)(),
        query
    });
}

export function getJobsRoute(): string {
    return compile(PATH_PATTERNS.JOBS)();
}

export function getJobRoute(jobId: number): string {
    return compile(PATH_PATTERNS.JOB)({ jobId });
}

type GetJobEditRouteOptions = {
    isParsed?: boolean;
    isUpgraded?: boolean;
    initialValidation?: boolean;
};

export type JobEditRouteQuery = {
    parsed?: 0 | 1;
    upgraded?: 0 | 1;
    initialValidation?: 0 | 1;
};

export function getJobEditRoute(jobId: number, options: GetJobEditRouteOptions = {}): string {
    const query: JobEditRouteQuery = {};

    if (typeof options.isParsed === 'boolean') {
        query.parsed = options.isParsed ? 1 : 0;
    }

    if (typeof options.isUpgraded === 'boolean') {
        query.upgraded = options.isUpgraded ? 1 : 0;
    }

    if (typeof options.initialValidation === 'boolean') {
        query.initialValidation = options.initialValidation ? 1 : 0;
    }

    return url.format({
        pathname: compile(PATH_PATTERNS.JOB_EDIT)({ jobId }),
        query
    });
}

export function getJobCreateRoute(): string {
    return compile(PATH_PATTERNS.JOB_CREATE)();
}

export function getCandidatesRoute(jobId: number): string {
    return compile(PATH_PATTERNS.CANDIDATES)({ jobId });
}

export function getCandidatesWithNewMessagesRoute(jobId: number): string {
    return compile(PATH_PATTERNS.CANDIDATES_NEW_MESSAGES)({ jobId });
}

export function getCandidatesNotInterestingRoute(jobId: number): string {
    return compile(PATH_PATTERNS.CANDIDATES_NOT_INTERESTING)({ jobId });
}

export function getCandidateRoute(jobId: number, candidateId: number): string {
    return compile(PATH_PATTERNS.CANDIDATE)({ jobId, candidateId });
}

export function getCandidateConversationRoute(jobId: number, candidateId: number): string {
    return compile(PATH_PATTERNS.CANDIDATE_CONVERSATION)({
        jobId,
        candidateId
    });
}

export function getCandidateDocumentsRoute(jobId: number, candidateId: number): string {
    return compile(PATH_PATTERNS.CANDIDATE_DOCUMENTS)({ jobId, candidateId });
}

export function getPricesRoute(): string {
    return compile(PATH_PATTERNS.PRICES)();
}

export function getResetPasswordRoute(): string {
    return compile(PATH_PATTERNS.PASSWORD_RESET)();
}

export function getTemplatesListRoute(): string {
    return compile(PATH_PATTERNS.SETTINGS_TEMPLATES)();
}

export function getCreateTemplateRoute(): string {
    return compile(PATH_PATTERNS.SETTINGS_TEMPLATES_CREATE)();
}

export function getEditTemplateRoute(templateId: number): string {
    return compile(PATH_PATTERNS.SETTINGS_TEMPLATES_EDIT)({ templateId });
}

export type GetProfileSettingsRouteOptions = {
    backToJob?: number;
};

export type ProfileSettingsRouteQuery = {
    backToJob: number | null;
};

export type ProfileSettingsRouteRawQuery = {
    backToJob?: string;
};

export function getProfileSettingsRoute(options: GetProfileSettingsRouteOptions = {}): string {
    const rawQuery: ProfileSettingsRouteRawQuery = {};

    if (typeof options.backToJob === 'number') {
        rawQuery.backToJob = String(options.backToJob);
    }

    return url.format({
        pathname: compile(PATH_PATTERNS.SETTINGS_PROFILE)(),
        query: rawQuery
    });
}

export function parseProfileSettingsRouteQuery(rawQuery: ProfileSettingsRouteRawQuery): ProfileSettingsRouteQuery {
    const query: ProfileSettingsRouteQuery = {
        backToJob: null
    };

    query.backToJob = parseInt(rawQuery.backToJob ?? '');
    if (isNaN(query.backToJob)) {
        query.backToJob = null;
    }

    return query;
}

export type GetCompanyProfileSettingsRouteOptions = {
    backToJob?: number;
};

export type CompanyProfileSettingsRouteQuery = {
    backToJob: number | null;
};

export type CompanyProfileSettingsRouteRawQuery = {
    backToJob?: string;
};

export function getCompanyProfileSettingsRoute(options: GetCompanyProfileSettingsRouteOptions = {}): string {
    const rawQuery: CompanyProfileSettingsRouteRawQuery = {};

    if (typeof options.backToJob === 'number') {
        rawQuery.backToJob = String(options.backToJob);
    }

    return url.format({
        pathname: compile(PATH_PATTERNS.SETTINGS_COMPANY_PROFILE)(),
        query: rawQuery
    });
}

export function parseCompanyProfileSettingsRouteQuery(
    rawQuery: CompanyProfileSettingsRouteRawQuery
): CompanyProfileSettingsRouteQuery {
    const query: CompanyProfileSettingsRouteQuery = {
        backToJob: null
    };

    query.backToJob = parseInt(rawQuery.backToJob ?? '');
    if (isNaN(query.backToJob)) {
        query.backToJob = null;
    }

    return query;
}

export function getAddressSettingsRoute(): string {
    return compile(PATH_PATTERNS.SETTINGS_ADDRESS)();
}

export function getAccountSettingsRoute(): string {
    return compile(PATH_PATTERNS.SETTINGS_ACCOUNT)();
}

export function getContactRoute(): string {
    return compile(PATH_PATTERNS.CONTACT)();
}

export const getPathnamePattern = createPathnamePatternResolver(Object.values(PATH_PATTERNS));

export function getPathnameParams(pathname: string, pathnamePattern: string): { [index: string]: any } {
    const pathnameRegex = pathToRegexp(pathnamePattern);
    let match = pathnameRegex.exec(pathname);

    if (!match || match.length === 1) {
        return {};
    }

    const singleMatches = match.slice(1); // first item is full match

    // Tokens are either strings for hardcoded parts or objects for params
    const pathParamsTokens = parse(pathnamePattern).filter(
        (token): token is pathToRegexp.Key => typeof token !== 'string'
    );

    const pathParams = {};
    pathParamsTokens.forEach((token, index) => {
        pathParams[token.name] = singleMatches[index];
    });

    return pathParams;
}

const getBackRouteExclude = createPathnamePatternResolver(NO_BACK_ROUTES);
export function hasBackRoute(pathname: string) {
    return typeof getBackRouteExclude(pathname) !== 'string';
}

export function getBackPathname(pathname: string): string | null {
    const pathnamePattern = getPathnamePattern(pathname);
    if (!pathnamePattern) {
        return null;
    }

    const targetPath = BACK_ROUTES[pathnamePattern];
    if (!targetPath) {
        return null;
    }

    const pathParams = getPathnameParams(pathname, pathnamePattern);

    return compile(targetPath)(pathParams);
}
