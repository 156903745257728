import { combineReducers } from 'redux';
import { ApiError } from '../../services/api/types';
import resetOnLogout from '../authentication/reducer/resetOnLogout';
import {
    CANCEL_JOB_PARSING,
    CancelJobParsingAction,
    JOB_PARSING,
    JobParsing$FailureAction,
    JobParsing$StartAction,
    JobParsing$SuccessAction
} from './actions';
import { JOB_PARSING_ERROR, JOB_PARSING_STATUS, JobParsingError, JobParsingStatus } from './constants';

export const JOB_PARSING_MOUNT = 'jobParsing';

export type JobParsingStatusState = JobParsingStatus;

function statusReducer(
    state: JobParsingStatusState = 'none',
    action: JobParsing$StartAction | JobParsing$SuccessAction | JobParsing$FailureAction | CancelJobParsingAction
): JobParsingStatusState {
    switch (action.type) {
        case JOB_PARSING.START: {
            return JOB_PARSING_STATUS.PARSING;
        }
        case JOB_PARSING.SUCCESS: {
            return JOB_PARSING_STATUS.PARSED;
        }
        case JOB_PARSING.FAILURE: {
            return JOB_PARSING_STATUS.FAILED;
        }
        case CANCEL_JOB_PARSING: {
            return JOB_PARSING_STATUS.NONE;
        }
        default: {
            return state;
        }
    }
}

export type JobParsingErrorState = JobParsingError | null;

function errorReducer(
    state: JobParsingErrorState = null,
    action: JobParsing$FailureAction | CancelJobParsingAction
): JobParsingErrorState {
    switch (action.type) {
        case JOB_PARSING.FAILURE: {
            if (action.payload.error) {
                return action.payload.error;
            } else if (action.payload.serverErrors) {
                return JOB_PARSING_ERROR.SERVER;
            }

            return null;
        }

        case CANCEL_JOB_PARSING: {
            return null;
        }

        default: {
            return state;
        }
    }
}

export type JobParsingServerErrorsState = ApiError[] | null;

function serverErrorsReducer(
    state: JobParsingServerErrorsState = null,
    action: JobParsing$FailureAction | CancelJobParsingAction
): JobParsingServerErrorsState {
    switch (action.type) {
        case JOB_PARSING.FAILURE: {
            return action.payload.serverErrors ?? null;
        }

        case CANCEL_JOB_PARSING: {
            return null;
        }

        default: {
            return state;
        }
    }
}

export type JobParsingState = {
    status: JobParsingStatusState;
    error: JobParsingErrorState;
    serverErrors: JobParsingServerErrorsState;
};

const jobParsingReducer = combineReducers<JobParsingState>({
    status: statusReducer,
    error: errorReducer,
    serverErrors: serverErrorsReducer
});

export default resetOnLogout(jobParsingReducer);
