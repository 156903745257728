export const appName = 'Truffls Business';

export const cookieStorageKeyPrefix = 'truffls.dashboard.';
export const localStorageKeyPrefix = 'truffls.dashboard.';
export const sessionStorageKeyPrefix = 'truffls.dashboard.';

export const companyName = {
    upperCase: 'Truffls',
    lowerCase: 'truffls'
};

export const companyContact = {
    name: 'Truffls - Kundenservice',
    email: 'kunden@truffls.de',
    website: 'truffls.de',
    phone: '+49 (0)30 9700 5010',
    address: 'FUNKE Works GmbH - Tassiloplatz 27 - 81541 München - Germany'
};

export const legalCompanyName = 'FUNKE Works GmbH';
export const legalLinks = {
    imprint: 'https://truffls.de/imprint',
    privacy: 'https://truffls.de/privacy'
};

export const example = {
    phone: '+49 30 9700 5010'
};

export const links = {
    privacy: {
        de: 'https://truffls.de/de/datenschutz',
        en: 'https://truffls.de/en/privacy'
    },
    gtc: {
        de: 'https://truffls.de/de/agb/',
        en: 'https://truffls.de/en/gtc/'
    },
    imprint: {
        de: 'https://truffls.de/de/impressum',
        en: 'https://truffls.de/en/imprint'
    },
    gdpr: {
        de: 'https://dsgvo-gesetz.de/',
        en: 'https://gdpr-info.eu/'
    },
    website: {
        de: 'https://truffls.de/de',
        en: 'https://truffls.de/en'
    },
    websiteB2B: {
        de: 'https://truffls.de/de/mobile-recruiting',
        en: 'https://truffls.de/en/mobile-recruiting'
    },
    calendly: {
        de: 'https://truffls.de/book-a-consulation/',
        en: 'https://truffls.de/book-a-consulation/'
    }
};

export const targetCountries = [
    'DE',
    'AT',
    'BE',
    'CZ',
    'DK',
    'FR',
    'GB',
    'ES',
    'LU',
    'NL',
    'PL',
    'PT',
    'US'
] as Country[];
export const vatRequiredCountries = [
    'AT',
    'BE',
    'CZ',
    'DK',
    'FR',
    'GB',
    'ES',
    'LU',
    'NL',
    'PL',
    'PT',
    'US'
] as Country[];

export const locale = 'de-de';

export const currency = 'EUR' as Currency;

export type Country = 'DE' | 'AT' | 'BE' | 'CZ' | 'DK' | 'FR' | 'GB' | 'ES' | 'LU' | 'NL' | 'PL' | 'PT' | 'US';

export type Currency = 'EUR' | 'CHF';
