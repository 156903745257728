import { differenceInHours, parseISO } from 'date-fns';
import * as React from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import { formatISO } from '../../utils/date';

import './MessageItemDate.style.scss';

export type MessageItemDateProps = {
    date: string;
    shouldUpdate?: boolean;
};

function MessageItemDate({ date: dateStr, shouldUpdate = true }: MessageItemDateProps) {
    const [now, setNow] = React.useState<Date>(() => new Date());
    const [date, setDate] = React.useState<Date>(() => parseISO(dateStr));

    React.useEffect(() => {
        setDate(parseISO(dateStr));
    }, [dateStr]);

    React.useEffect(() => {
        if (!shouldUpdate) {
            return;
        }

        const interval = setInterval(() => {
            setNow(new Date());
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, [shouldUpdate]);

    const displayType = getDisplayType(date, now);

    return (
        <div className="MessageItemDate">
            <div className="MessageItemDate__body">
                <time dateTime={formatISO(date)}>
                    {displayType === 'time' && (
                        <FormattedTime hour="2-digit" minute="2-digit" hour12={false} value={date} />
                    )}
                    {displayType === 'yesterday-time' && (
                        <FormattedMessage
                            id="MESSAGE_ITEM.DATE.YESTERDAY"
                            values={{
                                time: <FormattedTime hour="2-digit" minute="2-digit" hour12={false} value={date} />
                            }}
                        />
                    )}
                    {displayType === 'date' && (
                        <FormattedDate year="2-digit" month="2-digit" day="2-digit" value={date} />
                    )}
                </time>
            </div>
        </div>
    );
}

export default MessageItemDate;

function getDisplayType(date: Date, now: Date) {
    const hoursDiff = differenceInHours(now, date);

    if (hoursDiff < 24) {
        return 'time';
    } else if (hoursDiff >= 24 && hoursDiff < 24) {
        return 'yesterday-time';
    } else {
        return 'date';
    }
}
