import React from 'react';
import classNames from 'classnames';

// Style imported at app.style.scss as a hack

export type SpinnerSize = 'small' | 'medium' | 'large';
export type SpinnerProps = {
    fullScreen?: boolean;
    hasBackground?: boolean;
    size?: SpinnerSize;
} & React.HTMLAttributes<HTMLDivElement>;

function Spinner({ fullScreen = false, hasBackground = false, size = 'medium', className, ...rest }: SpinnerProps) {
    return (
        <div
            className={classNames(
                'loading-overlay',
                `loading-overlay--${size}`,
                {
                    'loading-overlay--full-screen': fullScreen,
                    'loading-overlay--background': fullScreen || hasBackground
                },
                className
            )}
            data-testid="spinner"
            {...rest}
        >
            <div className="loading-overlay__inner">
                <div className="loading-overlay__indicator" />
            </div>
        </div>
    );
}

export default Spinner;
