import * as React from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '../../../components/Button';
import DialogV2 from '../../../components/DialogV2';
import DialogV2Actions from '../../../components/DialogV2/DialogActions';
import DialogV2Content from '../../../components/DialogV2/DialogContent';
import DialogV2Title from '../../../components/DialogV2/DialogTitle';

import { links } from '../../../constants';

import trialIllustration from '../../../assets/images/trial-illustration.svg';

import './TrialExpiredDialog.style.scss';

type Props = {
    open: boolean;
    onClose: () => void;

    intl: IntlShape;
};

function TrialExpiredDialog(props: Props) {
    const {
        open,
        onClose,

        intl
    } = props;

    const contactLink = links.calendly[intl.locale];

    return (
        <DialogV2 open={open} onClose={onClose}>
            <img className="TrialExpiredDialog__image" src={trialIllustration} role="presentation" />
            <DialogV2Title>
                <FormattedMessage id="TRIAL_EXPIRED_DIALOG.TITLE" />
            </DialogV2Title>
            <DialogV2Content className="TrialExpiredDialog__content">
                <FormattedMessage id="TRIAL_EXPIRED_DIALOG.TEXT" />
            </DialogV2Content>
            <DialogV2Actions>
                <Button
                    type="button"
                    onClick={onClose}
                    typeStyle="flat"
                    variationStyle="normal"
                    className="TrialExpiredDialog__action-cancel"
                >
                    <FormattedMessage id="TRIAL_EXPIRED_DIALOG.ACTION_CANCEL" />
                </Button>
                <Button
                    component="a"
                    href={contactLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onClose}
                    typeStyle="flat"
                    variationStyle="normal"
                >
                    <FormattedMessage id="TRIAL_EXPIRED_DIALOG.ACTION_CONTACT" />
                </Button>
                <Button component={Link} to="/prices" onClick={onClose} typeStyle="raised" variationStyle="brand">
                    <FormattedMessage id="TRIAL_EXPIRED_DIALOG.ACTION_BUY" />
                </Button>
            </DialogV2Actions>
        </DialogV2>
    );
}

export default injectIntl(TrialExpiredDialog);
