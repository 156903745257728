import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

import { isNew } from '../../modules/candidates/utils';
import { Candidate } from '../../modules/candidates/types';

import Avatar from '../Avatar';
import Badge from '../Badge';

import CandidateAvatarFallback from '../CandidateAvatarFallback';

import './CandidateAvatar.style.scss';

type Props = {
    candidate: Candidate | null;
    showNewBadge?: boolean;
    showUnreadMessageBadge?: boolean;
    skeleton?: boolean;
    className?: string;
};

const CandidateAvatar = ({
    candidate,

    showNewBadge,
    showUnreadMessageBadge,

    skeleton,
    className
}: Props) => {
    const profilePicture =
        !!candidate && !!candidate.applicant.portrait_file ? candidate.applicant.portrait_file.small : null;

    const shouldShowFallback = !!candidate;
    const shouldShowUnreadMessageCount = !!candidate && !!candidate.unread_message_count && showUnreadMessageBadge;
    const shouldShowNewBadge = !!candidate && isNew(candidate) && showNewBadge;

    const computedClassName = classNames('CandidateAvatar', className, {
        'CandidateAvatar--skeleton': skeleton
    });

    return (
        <div className={computedClassName}>
            {shouldShowUnreadMessageCount && !!candidate && (
                <div className="CandidateAvatar__messages-badge">
                    <Badge danger>{candidate.unread_message_count}</Badge>
                </div>
            )}

            {shouldShowNewBadge && (
                <div className="CandidateAvatar__new-badge">
                    <Badge danger>
                        <FormattedMessage id="CANDIDATE_AVATAR.BADGE.NEW" />
                    </Badge>
                </div>
            )}

            <Avatar
                src={profilePicture}
                fallback={shouldShowFallback && !!candidate && <CandidateAvatarFallback candidate={candidate} />}
                highlight={shouldShowNewBadge}
                border
            />
        </div>
    );
};

export default CandidateAvatar;
