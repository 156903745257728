import * as React from 'react';
import fieldify from '../../utils/form/field';
import FormElement from '../FormElement';

const FormFieldCheckbox = (props) => {
    const {
        input,
        meta,

        className,
        required,
        label,
        helpText,
        toggleElement,
        errors,

        ...rest
    } = props;

    const id = `${meta.form}__${input.name}`;

    return (
        <FormElement
            className={className}
            value={input.value}
            helpText={helpText}
            meta={meta}
            toggleElement={toggleElement}
            errors={errors}
        >
            <span className="tf-checkbox">
                <input {...rest} {...input} required={required} type="checkbox" id={id} />
                <label htmlFor={id} className="tf-checkbox__label">
                    <span className="tf-checkbox__faux" />
                    <span className="tf-checkbox__label-text">{label}</span>
                </label>
            </span>
        </FormElement>
    );
};

export default fieldify({
    type: 'checkbox'
})(FormFieldCheckbox);
