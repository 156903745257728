import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import SignupFormStepHeadline from '../SignupFormStepHeadline';
import Button from '../Button';
import { getLoginRoute } from '../../routes';

import SignupSuccessTrialCountdown from './SignupSuccessTrialCountdown';

import './SignupSuccess.style.scss';

export type SignupSuccessProps = {
    destinationUrl?: string;

    trialEndDate?: string | null;

    recruiterAndNotSignedUp: boolean;
    sameEmailAddress: boolean;
};

function SignupSuccess({
    destinationUrl,
    trialEndDate,

    recruiterAndNotSignedUp,
    sameEmailAddress
}: SignupSuccessProps) {
    const displayState = recruiterAndNotSignedUp ? (sameEmailAddress ? 'trial-no-verification' : 'trial') : 'new';

    return (
        <div className="SignupSuccess">
            <div className="SignupSuccess__body">
                <div className="SignupSuccess__content">
                    <div className="SignupSuccess__header">
                        <div className="SignupSuccess__headline">
                            <SignupFormStepHeadline>
                                <FormattedMessage id="SIGNUP_SUCCESS.TITLE" />
                            </SignupFormStepHeadline>
                        </div>
                    </div>

                    {displayState === 'new' && (
                        <React.Fragment>
                            <p>
                                <FormattedMessage id="SIGNUP_SUCCESS.DESCRIPTION_1" />
                            </p>

                            <p>
                                <FormattedMessage id="SIGNUP_SUCCESS.DESCRIPTION_2" />
                            </p>
                        </React.Fragment>
                    )}

                    {displayState === 'trial' && (
                        <React.Fragment>
                            <p>
                                <FormattedMessage id="SIGNUP_SUCCESS.DESCRIPTION_1_FOR_TRIAL" />
                            </p>

                            <p>
                                <FormattedMessage id="SIGNUP_SUCCESS.DESCRIPTION_2_FOR_TRIAL" />
                            </p>
                        </React.Fragment>
                    )}

                    {displayState === 'trial-no-verification' && (
                        <React.Fragment>
                            <p>
                                <FormattedMessage id="SIGNUP_SUCCESS.DESCRIPTION_1_FOR_TRIAL_NO_VERIFICATION" />
                            </p>

                            <p>
                                <FormattedMessage id="SIGNUP_SUCCESS.DESCRIPTION_2_FOR_TRIAL" />
                            </p>

                            <div className="SignupSuccess__action-bar">
                                <Button
                                    component={Link}
                                    to={getLoginRoute({ destinationUrl: destinationUrl })}
                                    typeStyle="raised"
                                    variationStyle="brand"
                                >
                                    <FormattedMessage id="SIGNUP_SUCCESS.BUTTON_FOR_TRIAL_NO_VERIFICATION" />
                                </Button>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
            <div className="SignupSuccess__aside">
                <div className="SignupSuccess__aside-content">
                    {!!trialEndDate && (
                        <div className="SignupSuccess__countdown">
                            <SignupSuccessTrialCountdown trialEndDate={trialEndDate}>
                                <FormattedMessage id="SIGNUP_SUCCESS.COUNTDOWN_DESCRIPTION" />
                            </SignupSuccessTrialCountdown>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SignupSuccess;
