import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CheckoutContactButton from '../../../components/CheckoutContactButton';

import CheckoutProductPageHeadline from '../CheckoutProductPageHeadline';

import { links } from '../../../constants';

import spotlightPortfolioImage1x from '../../../assets/images/spotlight-portfolio.png';
import spotlightPortfolioImage2x from '../../../assets/images/spotlight-portfolio@2x.png';
import spotlightPortfolioImage3x from '../../../assets/images/spotlight-portfolio@3x.png';

import classes from './CheckoutProductPageProductCategorySpotlightPortfolio.module.scss';

export type CheckoutProductPageProductCategorySpotlightPortfolioProps = {};

function CheckoutProductPageProductCategorySpotlightPortfolio({}: CheckoutProductPageProductCategorySpotlightPortfolioProps) {
    const intl = useIntl();

    return (
        <div className={classes.root}>
            <div className="container">
                <CheckoutProductPageHeadline
                    subtitle={<FormattedMessage id="CHECKOUT_PRODUCT_PAGE.SPOTLIGHT_PORTFOLIO.SUBTITLE" />}
                >
                    <FormattedMessage id="CHECKOUT_PRODUCT_PAGE.SPOTLIGHT_PORTFOLIO.TITLE" />
                </CheckoutProductPageHeadline>

                <img
                    className={classes.image}
                    src={spotlightPortfolioImage1x}
                    srcSet={[
                        `${spotlightPortfolioImage1x}`,
                        `${spotlightPortfolioImage2x} 2x`,
                        `${spotlightPortfolioImage3x} 3x`
                    ].join(', ')}
                    role="presentation"
                />

                <div className={classes.buttonContainer}>
                    <CheckoutContactButton
                        component="a"
                        href={links.calendly[intl.locale]}
                        target="_blank"
                        rel="noopener noreferrer"
                    />
                </div>
            </div>
        </div>
    );
}

export default CheckoutProductPageProductCategorySpotlightPortfolio;
