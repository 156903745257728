import React from 'react';
import { useHistory } from 'react-router-dom';
import { getJobsRoute } from '../../../routes';
import {
    AutomaticRequestTokenAuthenticatedRequestPage,
    AutomaticRequestTokenAuthenticatedRequestPageProps
} from './AutomaticRequestTokenAuthenticatedRequestPage';

export type AutomaticRequestTokenAuthenticatedRequestPageContainerProps = Omit<
    AutomaticRequestTokenAuthenticatedRequestPageProps,
    'redirect'
>;

export function AutomaticRequestTokenAuthenticatedRequestPageContainer({
    destinationUrl = getJobsRoute()
}: AutomaticRequestTokenAuthenticatedRequestPageContainerProps) {
    const history = useHistory();

    const redirect = (path: string) => {
        history.replace(path);
    };

    return <AutomaticRequestTokenAuthenticatedRequestPage destinationUrl={destinationUrl} redirect={redirect} />;
}
