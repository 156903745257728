import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Input } from '@truffls/design-system-react';
import { useFieldArray } from 'react-final-form-arrays';

import { Values } from '../../../../../types/utilities';

import Button from '../../../../../components/Button';
import FormError from '../../../../../components/FormError/FormError';

import { ERRORS } from './JobFormTasksCreateFormField.constants';
import { validate } from './JobFormTasksCreateFormField.utils';

import './JobFormTasksCreateFormField.scss';

export type JobFormTasksCreateFormFieldProps = {};

function JobFormTasksCreateFormField({}: JobFormTasksCreateFormFieldProps) {
    const { fields } = useFieldArray<string>('tasks');

    const intl = useIntl();
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState<Values<typeof ERRORS> | null>(null);

    const submit = () => {
        const cleanValue = value.trim();

        if (cleanValue.length === 0) {
            return;
        }

        const error = validate(cleanValue, {
            items: fields.value ?? []
        });

        if (!!error) {
            setError(error);
            return;
        }

        fields.push(cleanValue);

        setValue('');
    };

    const onChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.preventDefault();

        setError(null);
        setValue(event.target.value);
    };

    const onKeyPressInput = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            submit();
        }
    };

    const onClickButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        submit();
    };

    return (
        <React.Fragment>
            <div className="JobFormTasksCreateFormField">
                <div className="JobFormTasksCreateFormField__input">
                    <Input
                        id="job-tasks"
                        multiline
                        placeholder={intl.formatMessage({
                            id: 'JOB_EDIT.TASKS_CREATE_FORM_FIELD.PLACEHOLDER_TEXT'
                        })}
                        className="JobFormTasksCreateFormField__input-control"
                        value={value}
                        onChange={onChangeInput}
                        onKeyPress={onKeyPressInput}
                        data-testid="job-tasks-form-input"
                    />
                </div>

                <Button
                    typeStyle="raised"
                    variationStyle="brand"
                    className="JobFormTasksCreateFormField__submit-button"
                    onClick={onClickButton}
                    data-testid="job-tasks-form-submit"
                >
                    <FormattedMessage id="JOB_TASKS_FORM.SUBMIT_BUTTON.LABEL" />
                </Button>
            </div>
            {error === ERRORS.DUPLICATE && <FormError messageId="JOB_TASKS_FORM.ERROR_DUPLICATE.TEXT" />}
        </React.Fragment>
    );
}

export default JobFormTasksCreateFormField;
