import { FileType } from './types';

const TWENTY_FIVE_MEGABYTES = 25000000;
export const MAX_SIZE_FILE = TWENTY_FIVE_MEGABYTES;

// File category
export const FILE_CATEGORY_RESUME: 'resume' = 'resume';
export const FILE_CATEGORY_GENERATED_RESUME: 'generated-resume' = 'generated-resume';
export const FILE_CATEGORY_GRADUATION_CERTIFICATE: 'graduation-certificate' = 'graduation-certificate';
export const FILE_CATEGORY_CERTIFICATE: 'certificate' = 'certificate';
export const FILE_CATEGORY_WORK_SAMPLE: 'work-sample' = 'work-sample';
export const FILE_CATEGORY_EMPLOYMENT_REFERENCE: 'employment-reference' = 'employment-reference';
export const FILE_CATEGORY_OTHER: 'other' = 'other';

export const FILE_CATEGORIES = {
    RESUME: FILE_CATEGORY_RESUME,
    GENERATED_RESUME: FILE_CATEGORY_GENERATED_RESUME,
    GRADUATION_CERTIFICATE: FILE_CATEGORY_GRADUATION_CERTIFICATE,
    CERTIFICATE: FILE_CATEGORY_CERTIFICATE,
    WORK_SAMPLE: FILE_CATEGORY_WORK_SAMPLE,
    EMPLOYMENT_REFERENCE: FILE_CATEGORY_EMPLOYMENT_REFERENCE,
    OTHER: FILE_CATEGORY_OTHER
};

// File type
export const FILE_TYPE_PDF: 'PDF' = 'PDF';
export const FILE_TYPE_PNG: 'PNG' = 'PNG';
export const FILE_TYPE_JPG: 'JPG' = 'JPG';
export const FILE_TYPE_XLS: 'XLS' = 'XLS';
export const FILE_TYPE_DOC: 'DOC' = 'DOC';
export const FILE_TYPE_ZIP: 'ZIP' = 'ZIP';
export const FILE_TYPE_GENERIC: 'GENERIC' = 'GENERIC';

export const FILE_TYPES = {
    PDF: FILE_TYPE_PDF,
    PNG: FILE_TYPE_PNG,
    JPG: FILE_TYPE_JPG,
    XLS: FILE_TYPE_XLS,
    DOC: FILE_TYPE_DOC,
    ZIP: FILE_TYPE_ZIP,

    GENERIC: FILE_TYPE_GENERIC
};

export const FILE_MIME_TYPES_MAPPING: {
    [index in Exclude<FileType, typeof FILE_TYPE_GENERIC>]: Array<string>;
} = {
    [FILE_TYPES.PDF]: ['application/pdf'],
    [FILE_TYPES.PNG]: ['image/png'],
    [FILE_TYPES.JPG]: ['image/jpeg'],
    [FILE_TYPES.XLS]: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
    [FILE_TYPES.DOC]: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'],
    [FILE_TYPES.ZIP]: ['application/zip']
};
