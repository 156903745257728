import { createAction } from 'redux-actions';

import { createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';

import { ListResponseData, Errors } from '../types';
import { SearchResult } from './types';

const createActionType = createActionTypeCreator('SEARCH');

export const RESET_SEARCH = createActionType('RESET_SEARCH');

export const FETCH_SEARCH_RESULTS = createActionType('FETCH_SEARCH_RESULTS');
export const SEARCH_RESULTS_FETCH = createRequestActionTypes(createActionType('SEARCH_RESULTS_FETCH'));

export const resetSearch = createAction(RESET_SEARCH);

export const fetchSearchResults = createAction(
    FETCH_SEARCH_RESULTS,
    ({ type, query, page, append = false }: { type: string; query: string; page?: number; append?: boolean }) => {
        return {
            type,
            query,
            page,
            append
        };
    }
);

export const searchResultsFetch = {
    request: createAction(SEARCH_RESULTS_FETCH.REQUEST, (type: string, query: string, page: number) => {
        return {
            type,
            query,
            page
        };
    }),
    success: createAction(
        SEARCH_RESULTS_FETCH.SUCCESS,
        (type: string, query: string, page: number, data: ListResponseData<SearchResult>, append: boolean) => {
            return {
                type,
                query,
                page,
                data,
                append
            };
        }
    ),

    failure: createAction(
        SEARCH_RESULTS_FETCH.FAILURE,
        (type: string, query: string, page: number, errors: Errors, append: boolean) => {
            return {
                type,
                query,
                page,
                errors,
                append
            };
        }
    )
};
