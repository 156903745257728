export const MESSAGES_SMALL_SEPARATOR_SIZE = 4;
export const MESSAGES_BIG_SEPARATOR_SIZE = 8;

const USER_MESSAGE_TYPES_TEXT: 'text' = 'text';
const USER_MESSAGE_TYPES_ATTACHMENT: 'attachment' = 'attachment';

export const USER_MESSAGE_TYPES = {
    TEXT: USER_MESSAGE_TYPES_TEXT,
    ATTACHMENT: USER_MESSAGE_TYPES_ATTACHMENT
};

const BACKEND_MESSAGE_TYPES_INFO: 'info' = 'info';
const BACKEND_MESSAGE_TYPES_DOCUMENT_REQUEST: 'document-request' = 'document-request';
const BACKEND_MESSAGE_TYPES_STATUS: 'status' = 'status';

export const BACKEND_MESSAGE_TYPES = {
    INFO: BACKEND_MESSAGE_TYPES_INFO,
    DOCUMENT_REQUEST: BACKEND_MESSAGE_TYPES_DOCUMENT_REQUEST,
    STATUS: BACKEND_MESSAGE_TYPES_STATUS
};

const INTERNAL_MESSAGE_TYPES_SEPARATOR: 'internal:separator' = 'internal:separator';
const INTERNAL_MESSAGE_TYPES_DATE: 'internal:date' = 'internal:date';
const INTERNAL_MESSAGE_TYPES_READ_STATUS: 'internal:read-status' = 'internal:read-status';

export const INTERNAL_MESSAGE_TYPES = {
    SEPARATOR: INTERNAL_MESSAGE_TYPES_SEPARATOR,
    DATE: INTERNAL_MESSAGE_TYPES_DATE,
    READ_STATUS: INTERNAL_MESSAGE_TYPES_READ_STATUS
};

export const MESSAGE_TYPES = {
    ...USER_MESSAGE_TYPES,
    ...BACKEND_MESSAGE_TYPES,
    ...INTERNAL_MESSAGE_TYPES
};
