import * as React from 'react';

import SignupPageContainer from '../SignupPageContainer';

import './SignupPageLayout.style.scss';

type Props = {
    children: React.ReactNode;
};

function SignupPageLayout({ children }: Props) {
    return (
        <SignupPageContainer
            className="SignupPageLayout"
            spacerClassName="SignupPageLayout__spacer"
            spacerChildren={<div className="SignupPageLayout__spacer-background" />}
            contentClassName="SignupPageLayout__content"
        >
            {children}
        </SignupPageContainer>
    );
}

export default SignupPageLayout;
