import * as React from 'react';

import SelectablePackageListItem from './SelectablePackageListItem';

import './PackageChooser.style.scss';

import { Package } from '../../modules/packages/types';

export type Props = {
    items: Package[];

    selectedPackage: number;
    onSelect: (arg1: number) => void;
};

function PackageChooser(props: Props) {
    const { items, selectedPackage, onSelect } = props;

    const itemElements = items.map((pkg) => (
        <li key={pkg.id} className="PackageChooser__item">
            <SelectablePackageListItem package={pkg} selected={selectedPackage === pkg.id} onSelect={onSelect} />
        </li>
    ));

    return <ul className="PackageChooser">{itemElements}</ul>;
}

export default PackageChooser;
