import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { FormattedMessage } from 'react-intl';
import { Candidate } from '../../../modules/candidates/types';
import { getFullName as getCandidateFullName } from '../../../modules/candidates/utils';
import { Job } from '../../../modules/jobs/types';
import { Recruiter } from '../../../modules/recruiters/types';
import Button from '../../Button';
import MobileAppMessenger from '../../MobileApp/MobileAppMessenger';
import DeleteCandidateViewMobileAppMessages from '../DeleteCandidateViewMobileAppMessages';

import './DeleteCandidateViewDesktop.scss';

export type DeleteCandidateViewDesktopProps = {
    recruiter: Recruiter | null;
    job: Job | null;
    candidate: Candidate;

    message: string;

    open: boolean;
    onClose: () => void;
    onSubmit: (event: React.FormEvent<HTMLElement>) => void;

    children: React.ReactNode;
};

export default function DeleteCandidateViewDesktop({
    recruiter,
    job,
    candidate,
    message,
    open,
    onClose,
    onSubmit,
    children
}: DeleteCandidateViewDesktopProps) {
    const handleClickCloseAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onClose();
    };

    return (
        <Modal show={open} onHide={onClose}>
            <Modal.Header className="DeleteCandidateViewDesktop__header">
                <Modal.Title className="DeleteCandidateViewDesktop__title">
                    <FormattedMessage
                        id="DELETE_CANDIDATE.TITLE.LONG"
                        values={{
                            name: getCandidateFullName(candidate)
                        }}
                    />
                </Modal.Title>
            </Modal.Header>

            <form onSubmit={onSubmit}>
                <Modal.Body className="DeleteCandidateViewDesktop__body">
                    <div className="DeleteCandidateViewDesktop__app-preview">
                        <div className="DeleteCandidateViewDesktop__app-preview__title">
                            <FormattedMessage id="DELETE_CANDIDATE.APP_PREVIEW.TITLE" />
                        </div>
                        <div className="DeleteCandidateViewDesktop__app-preview__phone">
                            <MobileAppMessenger scale={0.91} job={job} recruiter={recruiter}>
                                {recruiter && (
                                    <DeleteCandidateViewMobileAppMessages recruiter={recruiter} message={message} />
                                )}
                            </MobileAppMessenger>
                        </div>
                    </div>
                    <div className="DeleteCandidateViewDesktop__explanation">{children}</div>
                </Modal.Body>

                <Modal.Footer className="DeleteCandidateViewDesktop__footer">
                    <Button type="button" onClick={handleClickCloseAction} typeStyle="flat" variationStyle="normal">
                        <FormattedMessage id="DELETE_CANDIDATE.ACTION.CANCEL" />
                    </Button>
                    <Button type="submit" typeStyle="flat" variationStyle="destructive">
                        <FormattedMessage id="DELETE_CANDIDATE.ACTION.DELETE" />
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}
