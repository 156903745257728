import * as React from 'react';

import './style.scss';

type Props = {
    active: boolean;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

const ICONS = {
    none: require('../../assets/images/ic_filter.svg'),
    active: require('../../assets/images/ic_filter-active.svg')
};

const ListHeaderFilterToggle = (props: Props) => {
    let icon = 'none';
    if (props.active) {
        icon = 'active';
    }

    return (
        <button type="button" className="ListHeaderFilterToggle" onClick={props.onClick}>
            <img className="ListHeaderFilterToggle__icon" src={ICONS[icon]} role="presentation" />
        </button>
    );
};

export default ListHeaderFilterToggle;
