import * as React from 'react';
import { connect } from 'react-redux';

import { startBulkUpCheckout } from '../../modules/checkout/actions';

type Props = {
    startBulkUpCheckout: typeof startBulkUpCheckout;
};

class PricesPage extends React.Component<Props> {
    componentDidMount() {
        this.props.startBulkUpCheckout(true);
    }

    render() {
        return null;
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
    startBulkUpCheckout
};

export default connect(mapStateToProps, mapDispatchToProps)(PricesPage);
