import { all, spawn, take, put, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';

import { isResponseError, hasErrorWithCode } from '../../services/api/utils';
import { handleError } from '../../services/errorHandler';
import { ERROR_GENERIC_NOT_FOUND, ERROR_VALIDATION_ERROR } from '../../services/api/constants';
import { Response } from '../../services/api/types';

import { FETCH_FLAG, flagFetch, UPDATE_FLAG, flagUpdate } from './actions';
import * as flagApi from './api';
import { Flag, FlagSlug, FlagValue } from './types';
import { FLAGS } from './constants';
import { createLogger } from '../../utils/logger';
import { getFlagExistsRemotely } from './selectors';

const logger = createLogger('flags/saga');

export function* fetchFlagSaga(slug: FlagSlug): SagaIterator<void> {
    try {
        yield put(flagFetch.request(slug));

        const response = yield call(flagApi.fetchFlag, slug);

        yield put(flagFetch.success(slug, response));
    } catch (error) {
        if (isResponseError(error)) {
            const { errors = [] } = error.response.data;

            yield put(flagFetch.failure(slug, errors));

            // If we have more than just the generic error or no error we want to track the error
            if (!hasErrorWithCode(errors, ERROR_GENERIC_NOT_FOUND) || errors.length >= 2 || errors.length === 0) {
                handleError(error);
            }
        } else {
            yield put(flagFetch.failure(slug, []));

            handleError(error);
        }
    }
}

const UPDATE_ACTION_CREATE = 'create';
const UPDATE_ACTION_UPDATE = 'update';

export function* updateFlagSaga(slug: FlagSlug, value: FlagValue): SagaIterator<void> {
    const existsRemotely = yield select(getFlagExistsRemotely, slug);

    const initialAction = existsRemotely ? UPDATE_ACTION_UPDATE : UPDATE_ACTION_CREATE;
    let nextAction: typeof initialAction = initialAction;

    yield put(flagUpdate.request(slug));

    while (true) {
        const currentAction = nextAction;

        try {
            let response: Response<Flag> | null;
            if (currentAction === UPDATE_ACTION_CREATE) {
                response = yield call(flagApi.createFlag, slug, value);
            } else {
                response = yield call(flagApi.updateFlag, slug, value);
            }

            yield put(flagUpdate.success(slug, response));

            break;
        } catch (error) {
            if (isResponseError(error)) {
                const { errors } = error.response.data;

                if (!!errors && errors.length === 1) {
                    if (currentAction === UPDATE_ACTION_CREATE && hasErrorWithCode(errors, ERROR_VALIDATION_ERROR)) {
                        nextAction = UPDATE_ACTION_UPDATE;
                    } else if (
                        currentAction === UPDATE_ACTION_UPDATE &&
                        hasErrorWithCode(errors, ERROR_GENERIC_NOT_FOUND)
                    ) {
                        nextAction = UPDATE_ACTION_CREATE;
                    }
                }

                /* If the next action to execute is not the action with which we have started we want to give it
                 * another try with the new set action. This way we prevent trying to run the action which initially
                 * failed.
                 */
                if (nextAction !== initialAction) {
                    continue;
                }

                yield put(flagUpdate.failure(slug, errors));
                yield call(handleError, error);
            } else {
                yield put(flagUpdate.failure(slug, []));
                yield call(handleError, error);
            }

            break;
        }
    }
}

function* watchFetchFlagSaga(): SagaIterator<void> {
    const tasksBySlug: {
        [slug: string]: any;
    } = {};

    while (true) {
        const action = yield take(FETCH_FLAG);
        const { slug } = action.payload;

        if (!FLAGS.includes(slug)) {
            logger.debug(`Skip fetch of unsupported flag "${slug}".`);
            continue;
        }

        const currentTask = tasksBySlug[slug];
        if (!!currentTask && currentTask.isRunning()) {
            continue;
        }

        tasksBySlug[slug] = yield spawn(fetchFlagSaga, slug);
    }
}

function* watchUpdateFlagSaga(): SagaIterator<void> {
    const tasksBySlug: {
        [slug: string]: any;
    } = {};

    while (true) {
        const action = yield take(UPDATE_FLAG);
        const { slug, value } = action.payload;

        if (!FLAGS.includes(slug)) {
            logger.debug(`Skip uupdate of unsupported flag "${slug}".`);
            continue;
        }

        const currentTask = tasksBySlug[slug];
        if (!!currentTask && currentTask.isRunning()) {
            continue;
        }

        tasksBySlug[slug] = yield spawn(updateFlagSaga, slug, value);
    }
}

export default function* flagsSaga(): SagaIterator<void> {
    yield all([spawn(watchFetchFlagSaga), spawn(watchUpdateFlagSaga)]);
}
