import { SagaIterator } from 'redux-saga';
import { all, call, put, spawn, take } from 'redux-saga/effects';

import { updateRecruiterSaga } from '../../../modules/recruiters/saga';
import { isApiResponseError } from '../../../services/api/utils';

import { hideTutorial, tutorialComplete } from './actions';
import { COMPLETE_TUTORIAL } from './actionsTypes';

function* completeTutorial(): SagaIterator {
    try {
        yield put(tutorialComplete.request());

        yield call(updateRecruiterSaga, {
            has_onboarding_completed: true
        });

        yield put(tutorialComplete.success());

        yield put(hideTutorial());
    } catch (error) {
        if (isApiResponseError(error)) {
            yield put(tutorialComplete.failure(error.response.data.errors || []));
        } else {
            throw error;
        }
    }
}

function* watchCompleteTutorial(): SagaIterator {
    while (true) {
        yield take(COMPLETE_TUTORIAL);

        yield call(completeTutorial);
    }
}

export default function* root(): SagaIterator {
    yield all([spawn(watchCompleteTutorial)]);
}
