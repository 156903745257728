import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import LoadingWheel from '../../components/LoadingWheel';

import { verifyEmail } from '../../modules/authentication/actions';

import './style.scss';

type ConnectorProps = RouteComponentProps<{
    verificationToken: string;
}>;
type ConnectedActions = {
    verifyEmail: typeof verifyEmail;
};
type ConnectedProps = {
    verificationToken: string;
};
type Props = ConnectorProps & ConnectedActions & ConnectedProps;

class EmailVerificationPage extends React.Component<Props> {
    componentDidMount() {
        const {
            verificationToken,

            verifyEmail
        } = this.props;

        verifyEmail(verificationToken);
    }

    render() {
        return (
            <div className="EmailVerificationPage">
                <div className="EmailVerificationPage__loading-wheel">
                    <LoadingWheel />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any, props: ConnectorProps) {
    return {
        verificationToken: props.match.params.verificationToken
    };
}

const mapDispatchToProps = {
    verifyEmail: verifyEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationPage);
