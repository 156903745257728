import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import LoaderButton from '../../../components/LoaderButtonV2';

type Props = {
    submitting: boolean;
    submitStep?: boolean;
    valid?: boolean;
    forTrial: boolean;
    onNext?: () => void;
};

function SignupFormStepActionSubmit({ submitting, submitStep, valid, forTrial, onNext }: Props): React.ReactElement {
    let labelId;
    if (!submitStep) {
        labelId = forTrial ? 'SIGNUP_FORM_ACTIONS.CONTINUE_FOR_TRIAL' : 'SIGNUP_FORM_ACTIONS.CONTINUE';
    } else {
        labelId = forTrial ? 'SIGNUP_FORM_ACTIONS.SUBMIT_FOR_TRIAL' : 'SIGNUP_FORM_ACTIONS.SUBMIT';
    }

    return (
        <LoaderButton
            loading={submitting}
            type="submit"
            disabled={!valid}
            onClick={onNext}
            typeStyle="raised"
            variationStyle="brand"
        >
            <FormattedMessage id={labelId} />
        </LoaderButton>
    );
}

export default SignupFormStepActionSubmit;
