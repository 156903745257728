import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import i18nReducer, { I18N_MOUNT, I18NState } from '../modules/i18n/reducer';

import conversations, { MOUNT as CONVERSATIONS_MOUNT, ConversationState } from '../modules/conversations/reducer';

import jobParsingReducer, { JOB_PARSING_MOUNT, JobParsingState } from '../modules/jobParsing/reducer';

import agreementsReducer, { MOUNT as AGREEMENTS_MOUNT, State as AgreementsState } from '../modules/agreements/reducer';

import authenticationReducer, {
    MOUNT as AUTHENTICATION_MOUNT,
    State as AuthenticationState
} from '../modules/authentication/reducer';

import candidatesReducer, { MOUNT as CANDIDATES_MOUNT, State as CandidatesState } from '../modules/candidates/reducer';

import clientInfoReducer, { MOUNT as CLIENT_INFO_MOUNT, State as ClientInfoState } from '../modules/clientInfo/reducer';

import checkoutReducer, { MOUNT as CHECKOUT_MOUNT, State as CheckoutState } from '../modules/checkout/reducer';

import entitiesReducer, { MOUNT as ENTITIES_MOUNT, EntitiesState } from '../modules/entities/reducer';

import filesReducer, { MOUNT as FILES_MOUNT, FilesState } from '../modules/files/reducer';

import flagsReducer, { MOUNT as FLAGS_MOUNT, FlagsState } from '../modules/flags/reducer';

import notificationsReducer, {
    MOUNT as NOTIFICATIONS_MOUNT,
    State as NotificationsState
} from '../modules/notifications/reducer';

import jobRegionsReducer, { MOUNT as JOB_REGIONS_MOUNT, State as JobRegionsState } from '../modules/jobRegions/reducer';

import jobsReducer, { MOUNT as JOBS_MOUNT, State as JobsState } from '../modules/jobs/reducer';

import packagesReducer, { MOUNT as PACKAGES_MOUNT, PackagesState } from '../modules/packages/reducer';

import recruitersReducer, { MOUNT as RECRUITERS_MOUNT, State as RecruitersState } from '../modules/recruiters/reducer';

import searchReducer, { MOUNT as SEARCH_MOUNT, SearchState } from '../modules/search/reducer';

import templatesReducer, { MOUNT as TEMPLATES_MOUNT, State as TemplatesState } from '../modules/templates/reducer';

import uiReducer, { MOUNT as UI_MOUNT, UIState } from '../modules/ui/reducer';

import usersReducer, { MOUNT as USERS_MOUNT, State as UsersState } from '../modules/users/reducer';

import versionReducer, { VERSION_MOUNT, VersionState } from '../modules/version/reducer';

const REDUX_FORM_MOUNT = 'form';

export type ApplicationState = {
    [key in typeof REDUX_FORM_MOUNT]: any;
} &
    AgreementsState &
    AuthenticationState &
    CandidatesState &
    {
        [key in typeof CLIENT_INFO_MOUNT]: ClientInfoState;
    } &
    {
        [key in typeof CHECKOUT_MOUNT]: CheckoutState;
    } &
    {
        [key in typeof CONVERSATIONS_MOUNT]: ConversationState;
    } &
    {
        [key in typeof ENTITIES_MOUNT]: EntitiesState;
    } &
    {
        [key in typeof FILES_MOUNT]: FilesState;
    } &
    {
        [key in typeof FLAGS_MOUNT]: FlagsState;
    } &
    NotificationsState &
    {
        [key in typeof I18N_MOUNT]: I18NState;
    } &
    {
        [key in typeof JOB_PARSING_MOUNT]: JobParsingState;
    } &
    {
        [key in typeof JOB_REGIONS_MOUNT]: JobRegionsState;
    } &
    JobsState &
    {
        [key in typeof PACKAGES_MOUNT]: PackagesState;
    } &
    RecruitersState &
    {
        [key in typeof SEARCH_MOUNT]: SearchState;
    } &
    TemplatesState &
    {
        [key in typeof UI_MOUNT]: UIState;
    } &
    UsersState &
    {
        [key in typeof VERSION_MOUNT]: VersionState;
    };

/**
 * @deprecated Use `ApplicationState` instead.
 */
export type State = ApplicationState;

function createApplicationReducer() {
    return combineReducers<ApplicationState>({
        [REDUX_FORM_MOUNT]: formReducer,

        [CONVERSATIONS_MOUNT]: conversations,

        [AGREEMENTS_MOUNT]: agreementsReducer,
        [AUTHENTICATION_MOUNT]: authenticationReducer,
        [CANDIDATES_MOUNT]: candidatesReducer,
        [CLIENT_INFO_MOUNT]: clientInfoReducer,
        [CHECKOUT_MOUNT]: checkoutReducer,
        [ENTITIES_MOUNT]: entitiesReducer,
        [FILES_MOUNT]: filesReducer,
        [FLAGS_MOUNT]: flagsReducer,
        [NOTIFICATIONS_MOUNT]: notificationsReducer,
        [I18N_MOUNT]: i18nReducer,
        [JOB_PARSING_MOUNT]: jobParsingReducer,
        [JOB_REGIONS_MOUNT]: jobRegionsReducer,
        [JOBS_MOUNT]: jobsReducer,
        [PACKAGES_MOUNT]: packagesReducer,
        [RECRUITERS_MOUNT]: recruitersReducer,
        [SEARCH_MOUNT]: searchReducer,
        [TEMPLATES_MOUNT]: templatesReducer,
        [UI_MOUNT]: uiReducer,
        [USERS_MOUNT]: usersReducer,
        [VERSION_MOUNT]: versionReducer
    });
}

export default createApplicationReducer;
