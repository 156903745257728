import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FETCH_STATUS_LOADED } from '../../../modules/constants';
import { JobRegion } from '../../../modules/jobRegions/types';
import { FetchStatus } from '../../../modules/types';

import './JobFormLocationRegionWarningField.scss';

export type JobFormLocationRegionWarningFieldProps = {
    jobRegionsFetchStatus: FetchStatus;
    jobRegions: JobRegion[];
};

function JobFormLocationRegionWarningField({
    jobRegionsFetchStatus,
    jobRegions
}: JobFormLocationRegionWarningFieldProps) {
    const shouldShowJobRegionsWarning = jobRegionsFetchStatus === FETCH_STATUS_LOADED && jobRegions.length === 0;

    if (!shouldShowJobRegionsWarning) {
        return null;
    }

    return (
        <React.Fragment>
            <hr />
            <div className="media">
                <div className="media-left">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        className="JobFormLocationRegionWarningField__icon"
                    >
                        <path
                            fillRule="evenodd"
                            d="M12.3864,2.79999995 C9.82128221,2.84199378 7.3757289,3.89177125 5.57839997,5.72239997 C3.76133202,7.53147234 2.75833108,10.001864 2.79999995,12.5656 C2.79701978,15.0696417 3.79081055,17.4718996 5.56196193,19.2420003 C7.3331133,21.012101 9.73596052,22.0044661 12.24,22.0000151 L12.4112,22.0000151 C17.7537856,21.9450325 22.0435163,17.5754912 22,12.2328 L22,12.2328 C22.0059636,9.69771993 20.9891143,7.26740499 19.1796024,5.49192395 C17.3700904,3.7164429 14.92091,2.74591281 12.3864,2.79999995 Z M11.2,16.0336 C11.1883502,15.7167308 11.3046305,15.4084908 11.5226653,15.1782678 C11.7407001,14.9480447 12.0421659,14.8151862 12.3592,14.8096 L12.3808,14.8096 C13.0368167,14.8108613 13.5747133,15.3300393 13.5992,15.9856 C13.6110714,16.3025292 13.4948652,16.6108954 13.2767835,16.841168 C13.0587019,17.0714405 12.757106,17.2042318 12.44,17.2096 L12.4184,17.2096 C11.7627171,17.2075152 11.2252978,16.688798 11.2,16.0336 Z M11.6,12.8 L11.6,7.99999998 C11.6,7.55817218 11.9581722,7.19999998 12.4,7.19999998 C12.8418278,7.19999998 13.2,7.55817218 13.2,7.99999998 L13.2,12.8 C13.2,13.2418278 12.8418278,13.6 12.4,13.6 C11.9581722,13.6 11.6,13.2418278 11.6,12.8 Z"
                        />
                    </svg>
                </div>
                <div className="media-body">
                    <FormattedMessage id="JOB_EDIT.LOCATION_FORM_FIELDSET.JOB_REGION_NOT_FOUND_HELP_TEXT" />
                </div>
            </div>
        </React.Fragment>
    );
}

export default JobFormLocationRegionWarningField;
