import { combineReducers } from 'redux';

import resetOnLogout from '../../authentication/reducer/resetOnLogout';
import conversationReducer from './conversation';
import messagesReducer from './messages';
import { createMapReducer } from '../../../utils/redux';

export const MOUNT = 'conversations';

export type ConversationState = {
    [key: string]: CandidateConversationState;
};

export type CandidateConversationState = {
    conversation: any;
    messages: any;
};

const candidateConversationReducer = combineReducers<CandidateConversationState>({
    conversation: conversationReducer,
    messages: messagesReducer
});

function getMapKey(action) {
    if (typeof action.payload !== 'object' || typeof action.payload.conversationId !== 'number') {
        return undefined;
    }

    return action.payload.conversationId;
}

const candidateConversationsReducer = createMapReducer(candidateConversationReducer, getMapKey);

export default resetOnLogout(candidateConversationsReducer);
