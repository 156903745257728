/* global google */
import { createSingleton as geocoderService } from '../../utils/singleton';
import { createQueue } from '../../utils/queue';
import { ensureIntialization } from './init';
import { handleError } from '../errorHandler';

export type GeocoderRequest = google.maps.GeocoderRequest;

export type GeocoderResult = google.maps.GeocoderResult;

export enum GeocoderErrorCode {
    UNKNOWN = 'unknown',
    OVER_QUERY_LIMIT = 'over_query_limit'
}

const getGoogleMapsGeocoder = geocoderService(async () => {
    await ensureIntialization();

    return new window.google.maps.Geocoder();
});

const queue = createQueue<GeocoderRequest, GeocoderResult[]>({
    processData: async (data: GeocoderRequest): Promise<GeocoderResult[]> => {
        try {
            return await geocodeWithRetry(data);
        } catch (error) {
            console.log(error);
            handleError(error);
        }

        return [];
    }
});

export async function resolveAddress(country, address) {
    const request: GeocoderRequest = {
        address,
        componentRestrictions: {
            country
        }
    };

    return await queue.push(request);
}

export async function resolvePlaceId(placeId: string): Promise<GeocoderResult | null> {
    const request: GeocoderRequest = {
        placeId
    };

    const results = await queue.push(request);

    return results[0] ?? null;
}

async function geocodeWithRetry(request: GeocoderRequest) {
    const maxRetries = 3;

    for (let retries = 0; retries < maxRetries; retries++) {
        try {
            return await geocode(request);
        } catch (error) {
            if (isGeocoderStatus(error)) {
                const status = error;

                if (retries < maxRetries && isRetryableGeocoderStatus(status)) {
                    continue;
                }

                throw GeocoderError.fromStatus(status);
            }

            throw error;
        }
    }

    return [];
}

async function geocode(request: GeocoderRequest): Promise<GeocoderResult[]> {
    const service = await getGoogleMapsGeocoder();

    return new Promise<GeocoderResult[]>((resolve, reject) => {
        service.geocode(request, (results: google.maps.GeocoderResult[] | null, status: google.maps.GeocoderStatus) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                resolve(results as google.maps.GeocoderResult[]);
            } else if (status === window.google.maps.GeocoderStatus.ZERO_RESULTS) {
                resolve([]);
            } else {
                reject(status);
            }
        });
    });
}

class GeocoderError extends Error {
    constructor(public code: GeocoderErrorCode) {
        super(`Could not geocode address (${code})`);
    }

    static fromStatus(status: google.maps.GeocoderStatus) {
        // Map has to live inside the function because `window.google.maps`
        // isn't available during boot time.
        const statusToErrorCode = {
            [window.google.maps.GeocoderStatus.OVER_QUERY_LIMIT]: GeocoderErrorCode.OVER_QUERY_LIMIT,
            [window.google.maps.GeocoderStatus.ERROR]: GeocoderErrorCode.UNKNOWN
        };

        const code: GeocoderErrorCode = statusToErrorCode[status] || GeocoderErrorCode.UNKNOWN;

        return new GeocoderError(code);
    }
}

function isGeocoderStatus(status: any): status is google.maps.GeocoderStatus {
    return Object.values(window.google.maps.GeocoderStatus).includes(status);
}

function isRetryableGeocoderStatus(status: google.maps.GeocoderStatus) {
    // Array has to live inside the function because `window.google.maps`
    // isn't available during boot time.
    return [
        window.google.maps.GeocoderStatus.OVER_QUERY_LIMIT,
        window.google.maps.GeocoderStatus.UNKNOWN_ERROR
    ].includes(status);
}

export function getCoordinatesFromGeocoderResult({
    geometry
}: GeocoderResult): {
    latitude: number;
    longitude: number;
} {
    const longitude = geometry.location.lng();
    const latitude = geometry.location.lat();

    return {
        longitude,
        latitude
    };
}

function findAddressComponentByTypes(
    { address_components: addressComponents }: GeocoderResult,
    possibleTypes: string[]
) {
    return addressComponents.find(({ types }) => {
        return types.some((type) => possibleTypes.includes(type));
    });
}

export function getCountryCodeFromGeocoderResult(result: GeocoderResult): string | null {
    const countryComponent = findAddressComponentByTypes(result, ['country']);

    return countryComponent?.short_name ?? null;
}

export function getRegionFromGeocoderResult(result: GeocoderResult): string | null {
    const regionComponent = findAddressComponentByTypes(result, ['administrative_area_level_1']);

    return regionComponent?.long_name ?? null;
}

export function getLocalityFromGeocoderResult(result: GeocoderResult): string | null {
    const localityComponent = findAddressComponentByTypes(result, ['locality', 'postal_town']);

    return localityComponent?.long_name ?? null;
}

export function getPostalCodeFromGeocoderResult(result: GeocoderResult): string | null {
    const postalCodeComponent = findAddressComponentByTypes(result, ['postal_code']);

    return postalCodeComponent?.long_name ?? null;
}

export function getStreetNameFromGeocoderResult(result: GeocoderResult): string | null {
    const streetNameComponent = findAddressComponentByTypes(result, ['route']);

    return streetNameComponent?.long_name ?? null;
}

export function getStreetNumberFromGeocoderResult(result: GeocoderResult): string | null {
    const streetNumberComponent = findAddressComponentByTypes(result, ['street_number']);

    return streetNumberComponent?.long_name ?? null;
}

export type AddressType = 'establishment' | 'street_address' | 'premise' | 'route' | 'locality' | 'postal_code';
const addressTypePriorities: AddressType[] = [
    'establishment',
    'street_address',
    'premise',
    'route',
    'locality',
    'postal_code'
];

export function sortAddressTypesByPriority(types: AddressType[]): AddressType[] {
    return [...types].sort((a: AddressType, b: AddressType): -1 | 0 | 1 => {
        let aIndex = addressTypePriorities.indexOf(a);
        let bIndex = addressTypePriorities.indexOf(b);

        aIndex = aIndex === -1 ? addressTypePriorities.length : aIndex;
        bIndex = bIndex === -1 ? addressTypePriorities.length : bIndex;

        return aIndex !== bIndex ? (aIndex < bIndex ? -1 : 1) : 0;
    });
}
