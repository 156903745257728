export type Params = {
    pageSize: number;
    page: number;
    state?: string[];
    ordering?: string[];
    goodNewsEveryone?: boolean;
    isPremium?: boolean;
};

type ListName = 'news' | 'management' | 'copyable' | 'preview';

const PARAMS_BY_LIST_NAME: { [listName in ListName]: Params } = {
    news: {
        pageSize: 30,
        page: 1,

        state: ['draft', 'active', 'archived'],
        goodNewsEveryone: true,
        ordering: ['-good_news_count', 'candidate_new', 'messages_unread_by_recruiter']
    },

    management: {
        pageSize: 30,
        page: 1,

        state: ['draft', 'active', 'archived'],
        ordering: ['-updated'],
        goodNewsEveryone: false
    },

    copyable: {
        pageSize: 10,
        page: 1,

        state: ['active', 'archived'],
        ordering: ['title']
    },

    preview: {
        pageSize: 3,
        page: 1,

        state: ['active', 'archived'],
        ordering: ['-candidate', '-candidate_new'],
        isPremium: false
    }
};

export function getParamsByListName(listName: string) {
    return PARAMS_BY_LIST_NAME[listName];
}
