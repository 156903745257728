import { combineReducers } from 'redux';

import resetOnLogout from '../../authentication/reducer/resetOnLogout';

import {
    FETCH_JOBS,
    FETCH_JOB,
    JOB_CREATE,
    JOB_UPDATE,
    JOB_DELETE,
    JOB_ARCHIVE,
    STORE_JOB,
    REMOVE_CREATE_JOB_LOCAL_ID
} from '../../jobs/actions';
import { omit } from '../../../utils/object';

import { UPDATE_STATUS, CREATE_STATUS, FETCH_STATUS } from '../../constants';
import { Action } from '../../types';
import { JobId, Job } from '../../jobs/types';
import {
    EntityTypeEntitesState,
    EntityTypeStateEntitiesPartial,
    EntityTypeStateFetchStatusPartial,
    EntityTypeStateFetchErrorsPartial,
    EntityTypeStateCreateStatusPartial,
    EntityTypeStateCreateErrorsPartial,
    EntityTypeStateUpdateStatusPartial,
    EntityTypeStateUpdateErrorsPartial,
    EntityTypeStateDeleteStatusPartial,
    EntityTypeFetchStatusState,
    EntityTypeUpdateStatusState,
    EntityTypeDeleteStatusState,
    EntityTypeCreateStatusState,
    EntityTypeErrorsState
} from './';

export type JobEntityTypeState = EntityTypeStateEntitiesPartial<Job> &
    EntityTypeStateFetchStatusPartial &
    EntityTypeStateFetchErrorsPartial &
    EntityTypeStateCreateStatusPartial &
    EntityTypeStateCreateErrorsPartial &
    EntityTypeStateUpdateStatusPartial &
    EntityTypeStateUpdateErrorsPartial &
    EntityTypeStateDeleteStatusPartial;

export const entitiesReducer = (
    state: EntityTypeEntitesState<Job> = {},
    action: Action
): EntityTypeEntitesState<Job> => {
    switch (action.type) {
        case FETCH_JOBS.SUCCESS:
            const jobs: Job[] = action.payload.data.results;

            // Create a new state object by creating a copy of the actual state and insert/replace jobs.
            return jobs.reduce((state: EntityTypeEntitesState<Job>, job: Job) => {
                return {
                    // Create a weak copy of the current state
                    ...state,

                    // Insert/replace job by id
                    [job.id]: job
                };
            }, state);

        case JOB_CREATE.SUCCESS:
        case FETCH_JOB.SUCCESS:
        case JOB_UPDATE.SUCCESS: {
            const { data, localCreateId } = action.payload;
            const job: Job = data;

            let nextState = {
                ...state,
                [job.id]: job
            };

            if (localCreateId) {
                nextState = {
                    ...nextState,
                    [localCreateId]: job
                };
            }

            return nextState;
        }

        case STORE_JOB:
            const jobToStore: Job = action.payload.job;

            return {
                ...state,
                [jobToStore.id]: jobToStore
            };

        case JOB_DELETE.SUCCESS:
            return omit(state, '' + action.payload.candidateId);

        case REMOVE_CREATE_JOB_LOCAL_ID:
            const { localCreateId } = action.payload;

            return omit(state, localCreateId);

        default:
            return state;
    }
};

export const fetchStatusReducer = (
    state: EntityTypeFetchStatusState = {},
    action: Action
): EntityTypeFetchStatusState => {
    let jobId;

    switch (action.type) {
        case FETCH_JOB.REQUEST:
            jobId = action.payload.jobId as JobId;

            return {
                ...state,
                [jobId]: FETCH_STATUS.LOADING
            };

        case FETCH_JOB.SUCCESS:
            jobId = action.payload.jobId as JobId;

            return {
                ...state,
                [jobId]: FETCH_STATUS.LOADED
            };

        case FETCH_JOB.FAILURE:
            jobId = action.payload.jobId as JobId;

            return {
                ...state,
                [jobId]: FETCH_STATUS.FAILED
            };

        case JOB_DELETE.SUCCESS:
            jobId = action.payload.jobId as JobId;

            return omit(state, String(jobId));

        default:
            return state;
    }
};

function fetchErrorsReducer(state: EntityTypeErrorsState = {}, action: Action): EntityTypeErrorsState {
    switch (action.type) {
        case FETCH_JOB.SUCCESS: {
            const jobId = action.payload.jobId as JobId;

            return {
                ...state,
                [jobId]: null
            };
        }

        case FETCH_JOB.FAILURE: {
            const jobId = action.payload.jobId as JobId;
            const { errors } = action.payload;

            return {
                ...state,
                [jobId]: errors
            };
        }

        default:
            return state;
    }
}

export const createStatusReducer = (
    state: EntityTypeCreateStatusState = {},
    action: Action
): EntityTypeCreateStatusState => {
    const { localCreateId } = action.payload || {};

    // If there is not local reference id we skip updating the state
    if (!localCreateId) {
        return state;
    }

    switch (action.type) {
        case JOB_CREATE.REQUEST:
            return {
                ...state,
                [localCreateId]: CREATE_STATUS.CREATING
            };

        case JOB_CREATE.SUCCESS:
            return {
                ...state,
                [localCreateId]: CREATE_STATUS.CREATED
            };

        case JOB_CREATE.FAILURE:
            return {
                ...state,
                [localCreateId]: CREATE_STATUS.FAILED
            };

        case REMOVE_CREATE_JOB_LOCAL_ID:
            return omit(state, localCreateId);

        default:
            return state;
    }
};

function createErrorsReducer(state: EntityTypeErrorsState = {}, action: Action): EntityTypeErrorsState {
    const { localCreateId } = action.payload || {};

    // If there is not local reference id we skip updating the state
    if (!localCreateId) {
        return state;
    }

    switch (action.type) {
        case JOB_CREATE.SUCCESS:
            return {
                ...state,
                [localCreateId]: null
            };

        case JOB_CREATE.FAILURE:
            const { errors } = action.payload;

            return {
                ...state,
                [localCreateId]: errors
            };

        case REMOVE_CREATE_JOB_LOCAL_ID:
            return omit(state, localCreateId);

        default:
            return state;
    }
}

export const updateStatusReducer = (
    state: EntityTypeUpdateStatusState = {},
    action: Action
): EntityTypeUpdateStatusState => {
    let jobId;

    switch (action.type) {
        case JOB_UPDATE.REQUEST:
        case JOB_ARCHIVE.REQUEST:
            jobId = action.payload.jobId as JobId;

            return {
                ...state,
                [jobId]: UPDATE_STATUS.UPDATING
            };

        case JOB_UPDATE.SUCCESS:
        case JOB_ARCHIVE.SUCCESS:
            jobId = action.payload.jobId as JobId;

            return {
                ...state,
                [jobId]: UPDATE_STATUS.UPDATED
            };

        case JOB_UPDATE.FAILURE:
        case JOB_ARCHIVE.FAILURE:
            jobId = action.payload.jobId as JobId;

            return {
                ...state,
                [jobId]: UPDATE_STATUS.FAILED
            };

        case JOB_DELETE.SUCCESS:
            jobId = action.payload.jobId as JobId;

            return omit(state, String(jobId));

        default:
            return state;
    }
};

function updateErrorsReducer(state: EntityTypeErrorsState = {}, action: Action): EntityTypeErrorsState {
    switch (action.type) {
        case JOB_UPDATE.SUCCESS: {
            const jobId: JobId = action.payload.jobId;
            return {
                ...state,
                [jobId]: null
            };
        }

        case JOB_UPDATE.FAILURE: {
            const jobId: JobId = action.payload.jobId;
            const errors = action.payload.errors;

            return {
                ...state,
                [jobId]: errors
            };
        }

        case JOB_DELETE.SUCCESS: {
            const jobId: JobId = action.payload.jobId;

            return omit(state, jobId);
        }

        default: {
            return state;
        }
    }
}

export const deleteStatusReducer = (
    state: EntityTypeDeleteStatusState = {},
    action: Action
): EntityTypeDeleteStatusState => {
    let jobId;

    switch (action.type) {
        case JOB_DELETE.REQUEST:
            jobId = action.payload.jobId;

            return {
                ...state,
                [jobId]: 'deleting'
            };

        case JOB_DELETE.SUCCESS:
            jobId = action.payload.jobId;

            return {
                ...state,
                [jobId]: 'deleted'
            };

        case JOB_DELETE.FAILURE:
            jobId = action.payload.jobId;

            return {
                ...state,
                [jobId]: 'failed'
            };

        default:
            return state;
    }
};

const jobsReducer = combineReducers<JobEntityTypeState>({
    entities: entitiesReducer,
    fetchStatus: fetchStatusReducer,
    fetchErrors: fetchErrorsReducer,
    createStatus: createStatusReducer,
    createErrors: createErrorsReducer,
    updateStatus: updateStatusReducer,
    updateErrors: updateErrorsReducer,
    deleteStatus: deleteStatusReducer
});

export default resetOnLogout(jobsReducer);
