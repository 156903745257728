import * as React from 'react';
import classNames from 'classnames';

import styles from './PageContainer.module.scss';

export type PageContainerProps = {
    className?: string;
    children: React.ReactNode;
};

function PageContainer({ className, children }: PageContainerProps) {
    return <div className={classNames(styles.root, className)}>{children}</div>;
}

export default PageContainer;
