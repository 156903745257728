import { createSelector } from 'reselect';

import { getUIState } from '../../modules/ui/selectors';

const getSignupFormState = createSelector(getUIState, (uiState: ReturnType<typeof getUIState>) => uiState.signupForm);

export const getStep = createSelector(getSignupFormState, (signupFormState) => signupFormState.step);

export const getEmailTakenDecision = createSelector(
    getSignupFormState,
    (signupFormState) => signupFormState.emailTakenDecision
);
