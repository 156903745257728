import { combineReducers } from 'redux';

import { REQUEST_TOKEN_REQUEST, RESET_REQUEST_TOKEN_REQUEST } from '../actions';
import { STATUS_NONE, STATUS_REQUESTING, STATUS_REQUESTED, STATUS_FAILED } from '../../constants';

// Types
import { Action } from '../../types';

import { RequestStatus as Status } from '../../types';

export type State = {
    status: Status;
    email: string | null;
};

export function statusReducer(state: Status = STATUS_NONE, action: Action): Status {
    switch (action.type) {
        case REQUEST_TOKEN_REQUEST.REQUEST: {
            return STATUS_REQUESTING;
        }

        case REQUEST_TOKEN_REQUEST.SUCCESS: {
            return STATUS_REQUESTED;
        }

        case REQUEST_TOKEN_REQUEST.FAILURE: {
            return STATUS_FAILED;
        }

        case RESET_REQUEST_TOKEN_REQUEST: {
            return STATUS_NONE;
        }

        default: {
            return state;
        }
    }
}

export function emailReducer(state: string | null = null, action: Action): string | null {
    switch (action.type) {
        case REQUEST_TOKEN_REQUEST.SUCCESS: {
            const payload = action.payload;

            return payload.email;
        }

        case RESET_REQUEST_TOKEN_REQUEST: {
            return null;
        }

        default: {
            return state;
        }
    }
}

const rootReducer = combineReducers({
    status: statusReducer,
    email: emailReducer
});

export default rootReducer;
