import { GeocoderResult, AddressType, sortAddressTypesByPriority } from '../../../services/maps/geocoder';
import { JobLocation } from '../../../modules/jobs/types';
import { MapBounds } from '../../../components/Map';

const defaultLocation = {
    latitude: 51.165691,
    longitude: 10.451526
};
const defaultZoom = 5;

const zoomByAddressType: { [type in AddressType]?: number } = {
    establishment: 17,
    premise: 17,
    street_address: 17,
    route: 15,
    postal_code: 13,
    locality: 11
};

const zoomByLocationProperty = {
    street_address: 17,
    postal_code: 13,
    locality: 11,
    region: 10,
    country: 5
};

export function getMarker(location: JobLocation | null): { latitude: number; longitude: number } | undefined {
    const latitude = location?.latitude ?? null;
    const longitude = location?.longitude ?? null;

    if (!latitude || !longitude) {
        return undefined;
    }

    return {
        latitude,
        longitude
    };
}

export function getBounds(currentAddress: GeocoderResult | null): MapBounds | undefined {
    const southWest = currentAddress?.geometry.bounds?.getSouthWest() ?? null;
    const northEast = currentAddress?.geometry.bounds?.getNorthEast() ?? null;

    if (!southWest || !northEast) {
        return undefined;
    }

    return [
        [southWest.lat(), southWest.lng()],
        [northEast.lat(), northEast.lng()]
    ];
}

export function getCenter(location: JobLocation | null): { latitude: number; longitude: number } {
    return getMarker(location) ?? defaultLocation;
}

export function getZoom(location: JobLocation | null, currentAddress: GeocoderResult | null): number {
    let zoom: number | undefined;

    if (!!currentAddress) {
        // We don't have boundaries so we have to set the zoom.
        const prioritizedTypes = sortAddressTypesByPriority(currentAddress.types as AddressType[]);

        zoom = zoomByAddressType[prioritizedTypes[0]];
    }

    if (!zoom && !!location) {
        if (!!location.street_address) {
            zoom = zoomByLocationProperty.street_address;
        } else if (!!location.postal_code) {
            zoom = zoomByLocationProperty.postal_code;
        } else if (!!location.locality) {
            zoom = zoomByLocationProperty.locality;
        } else if (!!location.region) {
            zoom = zoomByLocationProperty.region;
        } else if (!!location.country) {
            zoom = zoomByLocationProperty.country;
        }
    }

    return zoom ?? defaultZoom;
}
