import React from 'react';
import { RequestTokenTokenType } from '../../../modules/authentication/type';
import { RequestStatus } from '../../../modules/types';
import LoadingPage from '../../LoadingPage';
import NotFoundPage from '../../NotFoundPage';
import { RequestTokenRequestFailurePage, RequestTokenRequestSuccessPage } from '../../RequestTokenRequestPage';
import { AutomaticRequestTokenRequestPageReason } from '../AutomaticRequestTokenRequestPage.constants';
import { mapReasonToRequestTokenTokenType } from '../AutomaticRequestTokenRequestPage.utils';

export type AutomaticRequestTokenAnonymousRequestPageProps = {
    email?: string;
    reason?: AutomaticRequestTokenRequestPageReason;
    requestTokenRequestStatus: RequestStatus;
    resetRequestTokenRequest: () => void;
    requestRequestToken: (email: string, tokenType?: RequestTokenTokenType) => void;
};

export function AutomaticRequestTokenAnonymousRequestPage({
    email,
    reason,
    requestTokenRequestStatus,
    resetRequestTokenRequest,
    requestRequestToken
}: AutomaticRequestTokenAnonymousRequestPageProps) {
    const [didRequest, setDidRequest] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!email) {
            return;
        }

        resetRequestTokenRequest();
        requestRequestToken(email, mapReasonToRequestTokenTokenType(reason));

        setDidRequest(true);
    }, []);

    if (!email) {
        return <NotFoundPage />;
    }

    if (didRequest && requestTokenRequestStatus === 'failed') {
        return <RequestTokenRequestFailurePage />;
    } else if (didRequest && requestTokenRequestStatus === 'requested') {
        return <RequestTokenRequestSuccessPage email={email} reason={reason} />;
    }

    return <LoadingPage />;
}
