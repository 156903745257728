import * as React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import './style.scss';

type Props = {
    children: React.ReactNode;
    containerClassName?: string;
};

function DialogPageContainerV2(props: Props) {
    const containerClassName = classNames('DialogPageContainerV2__container', props.containerClassName);

    return (
        <div className="DialogPageContainerV2">
            <Helmet bodyAttributes={{ class: 'DialogPageContainerV2__body' }} />

            <div className={containerClassName}>
                <div className="DialogPageContainerV2__content">{props.children}</div>
            </div>
        </div>
    );
}

export default DialogPageContainerV2;
