export const FORM_NAME = 'signupForm';

export type Step = 'login' | 'profile';

export const STEP_LOGIN = 'login';
export const STEP_PROFILE = 'profile';

export const STEPS: Step[] = [STEP_LOGIN, STEP_PROFILE];

export type FormValues = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    company: string;
    gender: 'm' | 'f';
    phone: string;
    acceptLatestGTC: boolean;
};

export type EmailTakenDecision = 'none' | 'different-email' | 'request-token';

export const EMAIL_TAKEN_DECISION_NONE = 'none';
export const EMAIL_TAKEN_DECISION_DIFFERENT_EMAIL = 'different-email';
export const EMAIL_TAKEN_DECISION_REQUEST_TOKEN = 'request-token';
