import React from 'react';

import closeIcon from '../../../assets/images/ic_cross.svg';

import './NavigationBarCloseAction.scss';

type Props = {
    children?: React.ReactNode;
    onClick?: React.DOMAttributes<HTMLButtonElement>['onClick'];
};

function NavigationBarCloseAction({ children, onClick }: Props) {
    return (
        <button type="button" className="NavigationBarCloseAction" onClick={onClick}>
            <img src={closeIcon} alt="" className="NavigationBarCloseAction__icon" />
            <span className="sr-only">{children || 'Close'}</span>
        </button>
    );
}

export default NavigationBarCloseAction;
