import { createStorage, isSessionStorageSupported } from '../utils/storage';

import { sessionStorageKeyPrefix } from '../constants';

const { get, set, remove } = createStorage({
    isStorageSupported: isSessionStorageSupported,
    getStorage: () => window.sessionStorage,
    storageKeyPrefix: sessionStorageKeyPrefix
});

export { get, remove, set };
