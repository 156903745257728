import * as React from 'react';
import { connect } from 'react-redux';

import { FETCH_STATUS_LOADED } from '../../modules/constants';
import { updateFlag } from '../../modules/flags/actions';
import { FLAG_HAS_SEEN_TRIAL_EXPIRED_DIALOG } from '../../modules/flags/constants';
import { getFlagFetchStatus, getFlagValue } from '../../modules/flags/selectors';
import { FlagValue } from '../../modules/flags/types';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { Recruiter } from '../../modules/recruiters/types';
import { Action, FetchStatus } from '../../modules/types';
import { ApplicationState } from '../../store/reducer';

import TrialExpiredDialog from './TrialExpiredDialog';
import { isAfterTrialEnd } from './TrialExpiredView.utils';

type ConnectedProps = {
    recruiter: Recruiter | null;
    hasSeenTrialExpiredDialog: FlagValue;
    hasSeenTrialExpiredDialogFetchStatus: FetchStatus;
};

type ConnectedDispatch = {
    updateHasSeenTrialExpiredDialog: (value: FlagValue) => Action;
};

type Props = ConnectedProps & ConnectedDispatch;

type State = {
    shouldShowDialog: boolean | null;
};

class TrialExpiredView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            shouldShowDialog: null
        };

        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.setShouldShowDialog();
    }

    componentDidUpdate() {
        this.setShouldShowDialog();
    }

    setShouldShowDialog() {
        const { shouldShowDialog } = this.state;

        // If we already have set the value we skip it
        if (typeof shouldShowDialog === 'boolean') {
            return;
        }

        const { recruiter, hasSeenTrialExpiredDialog, hasSeenTrialExpiredDialogFetchStatus } = this.props;

        if (!recruiter || hasSeenTrialExpiredDialogFetchStatus !== FETCH_STATUS_LOADED) {
            return null;
        }

        this.setState({
            shouldShowDialog: isAfterTrialEnd(recruiter.trial_end) && !hasSeenTrialExpiredDialog
        });
    }

    handleClose() {
        const { updateHasSeenTrialExpiredDialog } = this.props;

        this.setState({
            shouldShowDialog: false
        });

        updateHasSeenTrialExpiredDialog(true);
    }

    render() {
        const { shouldShowDialog } = this.state;

        return <TrialExpiredDialog open={!!shouldShowDialog} onClose={this.handleClose} />;
    }
}

const mapStateToProps = (state: ApplicationState): ConnectedProps => {
    return {
        recruiter: getRecruiter(state),
        hasSeenTrialExpiredDialog: getFlagValue(state, FLAG_HAS_SEEN_TRIAL_EXPIRED_DIALOG),
        hasSeenTrialExpiredDialogFetchStatus: getFlagFetchStatus(state, FLAG_HAS_SEEN_TRIAL_EXPIRED_DIALOG)
    };
};

const mapDispatchToProps = (dispatch): ConnectedDispatch => {
    return {
        updateHasSeenTrialExpiredDialog: (value: FlagValue) => {
            return dispatch(updateFlag(FLAG_HAS_SEEN_TRIAL_EXPIRED_DIALOG, value));
        }
    };
};

const withStore = connect(mapStateToProps, mapDispatchToProps);

export default withStore(TrialExpiredView);
