import * as React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './RequestDocumentsViewTiles.style.scss';

import { getFileCategoryTranslationId } from '../../../modules/files/utils';

import Checkbox from '../../../components/Checkbox';

import { SelectedTiles } from '../index';
import { FileCategory } from '../../../modules/files/types';

type Props = {
    categories: FileCategory[];
    handleChangeCheckbox: (category: FileCategory, checked: boolean) => void;
    selectedTiles: SelectedTiles;
    isViewportMobile: boolean;
};

const RequestDocumentsViewTiles = ({ categories, handleChangeCheckbox, selectedTiles, isViewportMobile }: Props) => (
    <div className="RequestDocumentsViewTiles">
        {categories.map((category) => {
            const categoryKey: string = category;

            return (
                <div
                    className={classNames('RequestDocumentsViewTiles__tile', {
                        'tf-has-success': selectedTiles[categoryKey]
                    })}
                    key={category}
                    onClick={() => {
                        handleChangeCheckbox(category, !selectedTiles[categoryKey]);
                    }}
                >
                    <label
                        className={classNames('tf-card RequestDocumentsViewTiles__tile-inner', {
                            'tf-card--compact': isViewportMobile,
                            'tf-card--success-lateral': isViewportMobile
                        })}
                        htmlFor={`RequestDocumentsView__document-tile--${category}`}
                    >
                        <Checkbox
                            id={`RequestDocumentsViewTiles__tile--${category}`}
                            checked={selectedTiles[categoryKey]}
                            onSpace={() => {
                                // needed for accesibility because it normally fires the click handler
                                handleChangeCheckbox(category, !selectedTiles[categoryKey]);
                            }}
                            onClick={(event) => {
                                // needed for not firing twice the parent's onClick handler
                                event.stopPropagation();
                            }}
                        >
                            <FormattedMessage id={getFileCategoryTranslationId(category)} />
                        </Checkbox>
                    </label>
                </div>
            );
        })}
    </div>
);

RequestDocumentsViewTiles.defaultProps = {
    isViewportMobile: false
};

export default RequestDocumentsViewTiles;
