import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import './CandidateConversationTemplatesOverlay.style.scss';

import PartialOverlay from '../../../components/PartialOverlay';
import TemplatesDropdown from '../../../components/TemplatesDropdown';
import Button from '../../../components/Button';

import { Template } from '../../../modules/templates/types';

type TemplateId = Template['id'];

type Props = {
    show: boolean;
    hideOverlay: () => void;
    templates: Template[];
    selectedTemplateId: TemplateId;
    selectTemplate: (templateId: TemplateId | null) => void;
    previewTemplate: (templateId: TemplateId | null) => void;
    insertTemplate: (templateId: TemplateId | null) => void;
    templatePreview: string;
    handleChangeTemplatePreview: (templatePreview: string) => void;
};

const CandidateConversationTemplatesOverlay = ({
    show,
    hideOverlay,
    templates,
    selectedTemplateId,
    selectTemplate,
    previewTemplate,
    insertTemplate,
    templatePreview,
    handleChangeTemplatePreview
}: Props) => {
    return (
        <PartialOverlay show={show} onHide={hideOverlay} coverPercentage={80}>
            <div className="CandidateConversationTemplatesOverlay">
                <div>
                    <span className="CandidateConversationTemplatesOverlay__title">
                        <FormattedMessage id="MESSAGE_FORM.TEMPLATES.SELECT_LABEL" />
                    </span>
                    <div className="CandidateConversationTemplatesOverlay__selection">
                        <div className="CandidateConversationTemplatesOverlay__selection__dropdown">
                            <TemplatesDropdown
                                templates={templates}
                                direction="down"
                                selectedTemplateId={selectedTemplateId}
                                onSelect={(templateId) => {
                                    selectTemplate(templateId);
                                    previewTemplate(templateId);
                                }}
                            />
                        </div>
                        <Button
                            data-testid="mobile-preview-template-insert-button"
                            className="CandidateConversationTemplatesOverlay__selection__preview-button"
                            type="button"
                            typeStyle="flat"
                            variationStyle="brand"
                            onClick={() => {
                                insertTemplate(selectedTemplateId);
                                hideOverlay();
                            }}
                        >
                            <FormattedMessage id="MESSAGE_FORM.TEMPLATES.PREVIEW_BUTTON.LABEL" />
                        </Button>
                    </div>
                </div>
                <div className="CandidateConversationTemplatesOverlay__preview">
                    <span className="CandidateConversationTemplatesOverlay__title">
                        <FormattedMessage id="MESSAGE_FORM.TEMPLATES.PREVIEW" />
                    </span>
                    <textarea
                        data-testid="mobile-preview-template-input"
                        value={templatePreview}
                        onChange={(event) => {
                            handleChangeTemplatePreview(event.target.value);
                        }}
                    />
                </div>
            </div>
        </PartialOverlay>
    );
};

export default CandidateConversationTemplatesOverlay;
