import { combineReducers } from 'redux';

import { Action, FetchStatus } from '../types';
import resetOnLogout from '../authentication/reducer/resetOnLogout';

import { FETCH_JOBS, JOB_DELETE } from './actions';
import { conditionalReducer } from '../../utils/redux';
import { FETCH_STATUS } from '../constants';
// We want to mount our reducer here
export const MOUNT = 'jobs';

export type ListName = 'news' | 'management' | 'copyable' | 'preview';

type EntriesState = number[];
type TotalCountState = number;
type PageState = number;
type ErrorState = null | any;
type FetchStatusState = FetchStatus;
export type ListState = {
    entries: EntriesState;
    totalCount: TotalCountState;
    page: PageState;
    errors: ErrorState;
    fetchStatus: FetchStatusState;
};

export type JobsState = {
    [listName in ListName]: ListState;
};

// This type represents the part of the whole state (starting from the root state object) which is handled by
// this reducer.
export type State = {
    jobs: JobsState;
};

export const entriesReducer = (state: EntriesState = [], action: Action): EntriesState => {
    switch (action.type) {
        case FETCH_JOBS.SUCCESS:
            return action.payload.data.results.map((job) => job.id);

        case JOB_DELETE.SUCCESS:
            const jobId = action.payload.jobId;
            return state.filter((id: number) => id !== jobId);

        default:
            return state;
    }
};

export const totalCountReducer = (state: TotalCountState = 0, action: Action): TotalCountState => {
    switch (action.type) {
        case FETCH_JOBS.SUCCESS:
            return action.payload.data.count;

        default:
            return state;
    }
};

export const pageReducer = (state: PageState = 1, action: Action): PageState => {
    switch (action.type) {
        case FETCH_JOBS.REQUEST:
        case FETCH_JOBS.SUCCESS:
            return action.payload.page;

        default:
            return state;
    }
};

export const errorsReducer = (state: ErrorState = null, action: Action): ErrorState => {
    switch (action.type) {
        case FETCH_JOBS.SUCCESS:
            return null;

        case FETCH_JOBS.FAILURE:
            return action.payload.errors || null;

        default:
            return state;
    }
};

export const fetchStatusReducer = (state: FetchStatusState = FETCH_STATUS.NONE, action: Action): FetchStatusState => {
    switch (action.type) {
        case FETCH_JOBS.REQUEST:
            return FETCH_STATUS.LOADING;

        case FETCH_JOBS.SUCCESS:
            return FETCH_STATUS.LOADED;

        case FETCH_JOBS.FAILURE:
            return FETCH_STATUS.FAILED;

        default:
            return state;
    }
};

const listReducer = combineReducers<ListState>({
    entries: entriesReducer,
    totalCount: totalCountReducer,
    page: pageReducer,
    errors: errorsReducer,
    fetchStatus: fetchStatusReducer
});

function createListCondition(listName) {
    return (state, action) => {
        return typeof action.payload === 'object' && action.payload.listName === listName;
    };
}

function createListReducer(listName: ListName) {
    const condition = createListCondition(listName);

    return conditionalReducer(condition)(listReducer);
}

const jobsReducer = combineReducers({
    news: createListReducer('news'),
    management: createListReducer('management'),
    copyable: createListReducer('copyable'),
    preview: createListReducer('preview')
});

export default resetOnLogout(jobsReducer);
