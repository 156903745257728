import React from 'react';
import useQuery from '../../hooks/useQuery';
import { parseAutomaticRequestTokenRequestRouteQuery } from '../../routes/automatic-request-token-request';
import { useAuthenticationState } from '../AuthenticationState';
import { AutomaticRequestTokenRequestPage } from './AutomaticRequestTokenRequestPage';

export type AutomaticRequestTokenRequestPageContainerProps = {};

export function AutomaticRequestTokenRequestPageContainer({}: AutomaticRequestTokenRequestPageContainerProps) {
    const rawQuery = useQuery();
    const { email, reason, destinationUrl } = parseAutomaticRequestTokenRequestRouteQuery(rawQuery);

    const state = useAuthenticationState();

    return (
        <AutomaticRequestTokenRequestPage state={state} email={email} reason={reason} destinationUrl={destinationUrl} />
    );
}
