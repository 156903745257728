import { Configuration } from './types';
import { setupEnvironment } from '../environment';
import { buildCommonConfig, CommonConfig } from './common';

/**
 * This configuration contains every configuirations that are specific to the client and can be exposed to the client.
 */
export type ClientConfig = CommonConfig & Configuration;

export function buildClientConfig(): ClientConfig {
    setupEnvironment();

    return {
        ...buildCommonConfig()
    };
}
