import { createAction } from 'redux-actions';

import { createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';

const createActionType = createActionTypeCreator('USER');

export const SET_USER = createActionType('SET_USER');
export const RESET_USER = createActionType('RESET_USER');

export const USER_FETCH = createRequestActionTypes(createActionType('USER_FETCH'));

export const setUser = createAction(SET_USER, (user) => {
    return { user };
});
export const resetUser = createAction(RESET_USER);

export const userFetch = {
    request: createAction(USER_FETCH.REQUEST),
    success: createAction(USER_FETCH.SUCCESS, (user) => {
        return { user };
    }),
    failure: createAction(USER_FETCH.FAILURE, (errors = []) => {
        return { errors };
    })
};
