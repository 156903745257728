import { combineReducers } from 'redux';
import listsByLocationReducer, { ListsByCoordinatesState } from './listsByCoordinates';

export const MOUNT: 'jobRegions' = 'jobRegions';

export type State = {
    listsByCoordinates: ListsByCoordinatesState;
};

export type { ListsByCoordinatesState, ListState } from './listsByCoordinates';

export default combineReducers({
    listsByCoordinates: listsByLocationReducer
});
