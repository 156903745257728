import { createAction, createActionTypeCreator } from '../../utils/redux';

const createActionType = createActionTypeCreator('VERSION');

export const START_CHECKER = createActionType('START_CHECKER');
export const startChecker = createAction(START_CHECKER);

export const STOP_CHECKER = createActionType('STOP_CHECKER');
export const stopChecker = createAction(STOP_CHECKER);

export const SET_LATEST_VERSION = createActionType('SET_LATEST_VERSION');
export const setLatestVersion = createAction(SET_LATEST_VERSION, (version: string) => {
    return {
        payload: {
            version
        }
    };
});
export type SetLatestVersionAction = ReturnType<typeof setLatestVersion>;
