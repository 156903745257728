export const emptyTransition = { left: 0 };

const animationDuration = 150;

export const TransitionAnimationKeyframes = {
    from: (goingRight) => ({
        height: 0,
        opacity: 0,
        left: goingRight ? 100 : -100
    }),
    enter: [
        {
            height: 'auto',
            delay: animationDuration + 4.75,
            config: {
                precision: 0.001,
                duration: animationDuration
            }
        },
        {
            opacity: 1,
            left: 0,
            delay: 200,
            config: {
                duration: animationDuration
            }
        }
    ],
    leave: (goingRight) => [
        {
            opacity: 0,
            left: goingRight ? -100 : 100,
            delay: 0,
            config: {
                duration: animationDuration
            }
        },
        {
            height: 0,
            delay: 0,
            config: {
                precision: 0.001,
                duration: animationDuration
            }
        }
    ]
};
