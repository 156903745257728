import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import deepEqual from 'deep-equal';

import PageContainer from '../../components/PageContainer';
import CandidateListHeader from '../../components/CandidateListHeader';
import CandidatePageHeader from '../../components/CandidatePageHeader';
import CandidateListPageFooter from '../../components/CandidateListPageFooter';
import ExpiringCandidateMessagingView from '../../components/ExpiringCandidateMessagingView';

import CandidateListPagePagination from './CandidateListPagePagination';
import CandidateListPageList from './CandidateListPageList';

import { parseSearch, stringifySearch } from '../../utils/url';
import { convertParamsToQueryParams } from '../../utils/params';

import { loadJob } from '../../modules/jobs/actions';
import { sendConversationMessage } from '../../modules/conversations/actions';
import { isMessageSending } from '../../modules/conversations/utils';
import { getConversationMessagesByCandidates } from '../../modules/conversations/selectors';
import { getJob, getJobFetchStatus } from '../../modules/entities/selectors';
import { getRecruiter } from '../../modules/recruiters/selectors';
import { getUser } from '../../modules/users/selectors';
import { loadCandidates } from '../../modules/candidates/actions';
import { getCandidates, getTotalCount, getPage, getFetchStatus } from '../../modules/candidates/selectors';

import { DEFAULT_PARAMS } from './constants';
import {
    resolveSearchParams,
    createPaginationLinkProps,
    convertParamsToActionParams,
    shouldShowJobPlaceholder,
    shouldShowCandidateListPlaceholder,
    shouldShowCandidateState
} from './utils';

// Types
import { State as ApplicationState } from '../../store/reducer';
import { Job } from '../../modules/jobs/types';
import { Recruiter } from '../../modules/recruiters/types';
import { User } from '../../modules/users/types';
import { Message } from '../../modules/conversations/types';
import { Candidate } from '../../modules/candidates/types';
import { Params, QueryParams } from './types';

type PossibleRouteParams = {
    jobId: string;
};

export type ConnectorProps = RouteComponentProps<PossibleRouteParams> & {
    listName: string;
};

type ConnectedStateProps = {
    jobId: number;
    job: Job | null;
    jobFetchStatus: string;

    currentUser: User | null;
    recruiter: Recruiter | null;

    candidates: Candidate[];
    candidatesTotalCount: number;
    candidatesPage: number;
    candidatesFetchStatus: string;
    candidatesConversationsMessages: {
        [index in Candidate['id']]: Message[];
    };
};

type ConnectedDispatchProps = {
    loadJob: typeof loadJob;
    loadCandidates: typeof loadCandidates;
    sendConversationMessage: typeof sendConversationMessage;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type ExpirationMessageState = {
    showExpirationMessagingView: boolean;
    sendingExpirationMessage: boolean;
    currentExpirationMessagingCandidateId: number | null;
    currentExpirationMessageLocalId: Message['id'] | null;
};

type State = ExpirationMessageState & {
    initialDataLoaded: boolean;
};

const getExpirationMessageInitialState = (): ExpirationMessageState => {
    return {
        showExpirationMessagingView: false,
        sendingExpirationMessage: false,
        currentExpirationMessagingCandidateId: null,
        currentExpirationMessageLocalId: null
    };
};

class CandidateListPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            initialDataLoaded: false,
            ...getExpirationMessageInitialState()
        };

        this.updateLocationQueryParams = this.updateLocationQueryParams.bind(this);
        this.getPaginationLinkProps = this.getPaginationLinkProps.bind(this);
        this.handleClickExpirationLink = this.handleClickExpirationLink.bind(this);
        this.handleCloseExpirationMessagingView = this.handleCloseExpirationMessagingView.bind(this);
        this.handleSubmitExpirationMessagingView = this.handleSubmitExpirationMessagingView.bind(this);
        this.getCurrentExpiringCandidate = this.getCurrentExpiringCandidate.bind(this);
    }

    componentDidMount() {
        const { jobId, loadJob } = this.props;

        loadJob(jobId);

        this.loadCandidates();
    }

    componentDidUpdate(prevProps: Props) {
        this.setInitiallyLoadedIfNeeded(prevProps, this.props);
        this.updateLocationQueryParamsIfNeeded(prevProps, this.props);
        this.loadCandidatesIfNeeded(prevProps, this.props);
        this.closeExpirationMessagingIfNeeded(prevProps, this.props);
    }

    setInitiallyLoadedIfNeeded(prevProps: Props, currentProps: Props) {
        const { initialDataLoaded } = this.state;

        if (initialDataLoaded) {
            return;
        }

        const justFinishedInitialLoading =
            prevProps.candidatesFetchStatus === 'loading' &&
            (currentProps.candidatesFetchStatus === 'loaded' || currentProps.candidatesFetchStatus === 'failed');

        if (justFinishedInitialLoading) {
            this.setState({
                initialDataLoaded: true
            });
        }
    }

    /*
     * If the page in the app state changed we want to update the search params in the location. This makes it
     * possible to control the page via the application state.
     */
    updateLocationQueryParamsIfNeeded(prevProps: Props, currentProps: Props) {
        if (prevProps.candidatesPage === currentProps.candidatesPage) {
            return;
        }

        const params = resolveSearchParams(currentProps.location.search);

        this.updateLocationQueryParams({
            ...params,
            page: currentProps.candidatesPage
        });
    }

    loadCandidatesIfNeeded(prevProps: Props, currentProps: Props) {
        const prevSearch = prevProps.location.search;
        const nextSearch = currentProps.location.search;

        if (prevSearch === nextSearch) {
            return;
        }

        const prevParams = resolveSearchParams(prevSearch);
        const nextParams = resolveSearchParams(nextSearch);

        if (!deepEqual(prevParams, nextParams)) {
            this.loadCandidates();
        }
    }

    closeExpirationMessagingIfNeeded(prevProps: Props, currentProps: Props) {
        const { sendingExpirationMessage, currentExpirationMessageLocalId } = this.state;

        const currentExpiringMessagingCandidate = this.getCurrentExpiringCandidate();

        if (!sendingExpirationMessage || !currentProps.currentUser || !currentExpiringMessagingCandidate) {
            return;
        }

        const currentExpiringMessagingCandidateId = currentExpiringMessagingCandidate.id;

        const prevConversationMessages = prevProps.candidatesConversationsMessages[currentExpiringMessagingCandidateId];
        const currentConversationMessages =
            currentProps.candidatesConversationsMessages[currentExpiringMessagingCandidateId];

        if (currentExpirationMessageLocalId !== null) {
            const isExpirationLocalMessage = (message) => message.id === currentExpirationMessageLocalId;

            const justFinishedSendingExpirationMessage =
                prevConversationMessages.some(isExpirationLocalMessage) &&
                !currentConversationMessages.some(isExpirationLocalMessage);

            if (justFinishedSendingExpirationMessage) {
                this.setState(getExpirationMessageInitialState());

                this.loadCandidates();
            }
        } else {
            const conversationMessagesJustUpdated =
                prevConversationMessages.length !== currentConversationMessages.length;

            const lastConversationMessage = currentConversationMessages[0]; // most recent messages are first

            const shouldGrabMessageLocalId =
                conversationMessagesJustUpdated &&
                !!lastConversationMessage &&
                isMessageSending(lastConversationMessage);

            if (shouldGrabMessageLocalId) {
                this.setState({
                    currentExpirationMessageLocalId: lastConversationMessage.id
                });
            }
        }
    }

    loadCandidates() {
        const search = this.props.location.search;
        const params = resolveSearchParams(search);
        const actionParams = convertParamsToActionParams(params);
        this.props.loadCandidates(actionParams);
    }

    updateLocationQueryParams(params: Partial<Params>) {
        const { history } = this.props;

        const queryParams: QueryParams = parseSearch(this.props.location.search);

        const newQueryParams = convertParamsToQueryParams(DEFAULT_PARAMS, params);

        if (!deepEqual(queryParams, newQueryParams)) {
            const newSearch = stringifySearch(newQueryParams);

            history.replace({
                search: newSearch
            });
        }
    }

    handleClickExpirationLink(candidateId: number) {
        this.setState({
            showExpirationMessagingView: true,
            currentExpirationMessagingCandidateId: candidateId
        });
    }

    handleCloseExpirationMessagingView() {
        this.setState(getExpirationMessageInitialState());
    }

    handleSubmitExpirationMessagingView(message: string) {
        const { sendConversationMessage, currentUser, candidates } = this.props;
        const { currentExpirationMessagingCandidateId } = this.state;

        const currentExpiringMessagingCandidate = candidates.find((candidate) => {
            return candidate.id === currentExpirationMessagingCandidateId;
        });

        if (!currentUser || !currentExpiringMessagingCandidate) {
            return;
        }

        const conversationId = currentExpiringMessagingCandidate.conversation_id;

        sendConversationMessage(conversationId, currentUser.id, message);

        this.setState({
            sendingExpirationMessage: true
        });
    }

    getPaginationLinkProps(page: number) {
        return createPaginationLinkProps(this.props.location, page);
    }

    getCurrentExpiringCandidate() {
        const { currentExpirationMessagingCandidateId } = this.state;
        const { candidates } = this.props;

        return candidates.find((candidate) => candidate.id === currentExpirationMessagingCandidateId);
    }

    render() {
        const { initialDataLoaded, showExpirationMessagingView, sendingExpirationMessage } = this.state;
        const {
            listName,
            jobId,
            job,
            jobFetchStatus,
            candidates,
            candidatesTotalCount,
            candidatesFetchStatus,
            location,
            recruiter
        } = this.props;

        const params = resolveSearchParams(location.search);

        const showJobPlaceholder = shouldShowJobPlaceholder(jobFetchStatus);

        const showCandidateState = shouldShowCandidateState(listName);

        const showPlaceholderList = shouldShowCandidateListPlaceholder(initialDataLoaded);

        const isFetchingCandidates = candidatesFetchStatus === 'loading'; // TODO: replace with FETCH_STATUS.LOADING

        const expiringCandidate = this.getCurrentExpiringCandidate();

        return (
            <PageContainer>
                {expiringCandidate && (
                    <ExpiringCandidateMessagingView
                        open={showExpirationMessagingView}
                        candidate={expiringCandidate}
                        job={job}
                        recruiter={recruiter}
                        loading={sendingExpirationMessage}
                        onClose={this.handleCloseExpirationMessagingView}
                        onSubmit={this.handleSubmitExpirationMessagingView}
                    />
                )}

                <CandidatePageHeader
                    skeleton={showJobPlaceholder}
                    job={job}
                    messageId="CANDIDATE_LIST_PAGE_HEADER.PREFIX"
                />

                <CandidateListHeader
                    showState={showCandidateState}
                    values={params}
                    onChange={this.updateLocationQueryParams}
                />

                <CandidateListPageList
                    showPlaceholderList={showPlaceholderList}
                    showProgress={showCandidateState}
                    isFetchingCandidates={isFetchingCandidates}
                    candidates={candidates}
                    listName={listName}
                    jobId={jobId}
                    onClickExpirationLink={this.handleClickExpirationLink}
                />

                {initialDataLoaded && (
                    <CandidateListPagePagination
                        totalCount={candidatesTotalCount}
                        pageSize={params.pageSize}
                        activePage={params.page}
                        getLinkProps={this.getPaginationLinkProps}
                    />
                )}

                <CandidateListPageFooter listName={listName} jobId={jobId} />
            </PageContainer>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => {
    const listName = props.listName;
    const jobId = parseInt(props.match.params.jobId, 10);

    const candidates = getCandidates(state, listName, jobId);
    const candidatesConversationsMessages = getConversationMessagesByCandidates(state, candidates);

    return {
        jobId,
        job: getJob(state, jobId),
        jobFetchStatus: getJobFetchStatus(state, jobId),
        currentUser: getUser(state),
        recruiter: getRecruiter(state),
        candidates,
        candidatesTotalCount: getTotalCount(state, listName, jobId),
        candidatesPage: getPage(state, listName, jobId),
        candidatesFetchStatus: getFetchStatus(state, listName, jobId),
        candidatesConversationsMessages
    };
};

const mapDispatchToProps = (dispatch: Dispatch, props: ConnectorProps) => {
    const listName = props.listName;
    const jobId = parseInt(props.match.params.jobId, 10);

    const actions = {
        loadJob,
        loadCandidates: loadCandidates.bind(null, listName, jobId),
        sendConversationMessage
    };

    return bindActionCreators(actions, dispatch);
};

const withStore = connect<ConnectedStateProps, ConnectedDispatchProps, ConnectorProps, ApplicationState>(
    mapStateToProps,
    mapDispatchToProps
);

export default withStore(CandidateListPage);
