export function containsChars(string: string): boolean {
    return /\S/.test(string);
}

export function isOnlyWhitespace(string: string): boolean {
    return !containsChars(string);
}

type Occurrence = {
    startIndex: number;
    endIndex: number;
};

export function findOccurrences(text: string, search: string): Occurrence[] {
    const searchLength = search.length;

    const occurrences: Occurrence[] = [];

    let offset = 0;
    let index = text.indexOf(search, offset);
    while (index !== -1) {
        const endIndex = index + searchLength;

        occurrences.push({
            startIndex: index,
            endIndex
        });

        offset = endIndex;
        index = text.indexOf(search, offset);
    }

    return occurrences;
}
