import { createAction as createActionLegacy } from 'redux-actions';

import { createActionTypeCreator, createRequestActionTypes, createAction } from '../../utils/redux';
import { Deferred } from '../../utils/promise';
import { GalleryImage, GalleryImageId, Recruiter } from './types';

const createActionType = createActionTypeCreator('RECRUITER');

export const SET_RECRUITER = createActionType('SET_RECRUITER');
export const setRecruiter = createActionLegacy(SET_RECRUITER, (recruiter) => {
    return {
        recruiter
    };
});

export const RECRUITER_FETCH = createRequestActionTypes(createActionType('RECRUITER_FETCH'));
export const recruiterFetch = {
    request: createActionLegacy(RECRUITER_FETCH.REQUEST),
    success: createActionLegacy(RECRUITER_FETCH.SUCCESS, (recruiter) => {
        return { recruiter };
    }),
    failure: createActionLegacy(RECRUITER_FETCH.FAILURE, (errors = []) => {
        return { errors };
    })
};

export const UPDATE_RECRUITER = createActionType('UPDATE_RECRUITER');
export const updateRecruiter = createAction(
    UPDATE_RECRUITER,
    (recruiterPartial: Partial<Recruiter>, { promise }: { promise?: Deferred<Recruiter> } = {}) => {
        return {
            payload: {
                recruiter: recruiterPartial
            },
            meta: {
                promise
            }
        };
    }
);
export type UpdateRecruiterAction = ReturnType<typeof updateRecruiter>;

export const RECRUITER_UPDATE = createRequestActionTypes(createActionType('RECRUITER_UPDATE'));
export const reruiterUpdate = {
    request: createActionLegacy(RECRUITER_UPDATE.REQUEST),
    success: createActionLegacy(RECRUITER_UPDATE.SUCCESS, (recruiter) => {
        return {
            recruiter
        };
    }),
    failure: createActionLegacy(RECRUITER_UPDATE.FAILURE, (errors = []) => {
        return {
            errors
        };
    })
};

export const UPDATE_RECRUITER_PORTRAIT = createActionType('UPDATE_RECRUITER_PORTRAIT');
export const updateRecruiterPortrait = createAction(
    UPDATE_RECRUITER_PORTRAIT,
    (file: File, { promise }: { promise?: Deferred<Recruiter> } = {}) => {
        return {
            payload: {
                file
            },
            meta: {
                promise
            }
        };
    }
);
export type UpdateRecuiterPortraitAction = ReturnType<typeof updateRecruiterPortrait>;

export const RECRUITER_PORTRAIT_UPDATE = createRequestActionTypes(createActionType('RECRUITER_PORTRAIT_UPDATE'));
export const recruiterPortraitUpdate = {
    request: createActionLegacy(RECRUITER_PORTRAIT_UPDATE.REQUEST),
    success: createActionLegacy(RECRUITER_PORTRAIT_UPDATE.SUCCESS, (recruiter: Recruiter) => {
        return {
            recruiter
        };
    }),
    failure: createActionLegacy(RECRUITER_PORTRAIT_UPDATE.FAILURE, (errors: unknown[] = []) => {
        return {
            errors
        };
    })
};

export const UPDATE_RECRUITER_COMPANY_LOGO = createActionType('UPDATE_RECRUITER_COMPANY_LOGO');
export const updateRecruiterCompanyLogo = createAction(
    UPDATE_RECRUITER_COMPANY_LOGO,
    (file: File, { promise }: { promise?: Deferred<Recruiter> } = {}) => {
        return {
            payload: {
                file
            },
            meta: {
                promise
            }
        };
    }
);
export type UpdateRecruiterCompanyLogoAction = ReturnType<typeof updateRecruiterCompanyLogo>;

export const RECRUITER_COMPANY_LOGO_UPDATE = createRequestActionTypes(
    createActionType('RECRUITER_COMPANY_LOGO_UPDATE')
);
export const recruiterCompanyLogoUpdate = {
    request: createActionLegacy(RECRUITER_COMPANY_LOGO_UPDATE.REQUEST),
    success: createActionLegacy(RECRUITER_COMPANY_LOGO_UPDATE.SUCCESS, (recruiter: Recruiter) => {
        return {
            recruiter
        };
    }),
    failure: createActionLegacy(RECRUITER_COMPANY_LOGO_UPDATE.FAILURE, (errors: unknown[] = []) => {
        return {
            errors
        };
    })
};

export const UPDATE_RECRUITER_COMPANY_BACKGROUND = createActionType('UPDATE_RECRUITER_COMPANY_BACKGROUND');
export const updateRecruiterCompanyBackground = createAction(
    UPDATE_RECRUITER_COMPANY_BACKGROUND,
    (file: File, { promise }: { promise?: Deferred<Recruiter> } = {}) => {
        return {
            payload: {
                file
            },
            meta: {
                promise
            }
        };
    }
);
export type UpdateRecruiterCompanyBackgroundAction = ReturnType<typeof updateRecruiterCompanyBackground>;

export const RECRUITER_COMPANY_BACKGROUND_UPDATE = createRequestActionTypes(
    createActionType('RECRUITER_COMPANY_BACKGROUND_UPDATE')
);
export const recruiterCompanyBackgroundUpdate = {
    request: createActionLegacy(RECRUITER_COMPANY_BACKGROUND_UPDATE.REQUEST),
    success: createActionLegacy(RECRUITER_COMPANY_BACKGROUND_UPDATE.SUCCESS, (recruiter: Recruiter) => {
        return {
            recruiter
        };
    }),
    failure: createActionLegacy(RECRUITER_COMPANY_BACKGROUND_UPDATE.FAILURE, (errors: unknown[] = []) => {
        return {
            errors
        };
    })
};

export const CREATE_RECRUITER_COMPANY_GALLERY_IMAGE = createActionType('CREATE_RECRUITER_COMPANY_GALLERY_IMAGE');
export const createRecruiterCompanyGalleryImage = createAction(
    CREATE_RECRUITER_COMPANY_GALLERY_IMAGE,
    (file: File, order: number, localCreateId?: string, { promise }: { promise?: Deferred<Recruiter> } = {}) => {
        return {
            payload: {
                file,
                order,
                localCreateId
            },
            meta: {
                promise
            }
        };
    }
);
export type CreateRecruiterCompanyGalleryImageAction = ReturnType<typeof createRecruiterCompanyGalleryImage>;

export const RECRUITER_COMPANY_GALLERY_IMAGE_CREATE = createRequestActionTypes(
    createActionType('RECRUITER_COMPANY_GALLERY_IMAGE_CREATE')
);
export const recruiterCompanyGalleryImageCreate = {
    request: createActionLegacy(RECRUITER_COMPANY_GALLERY_IMAGE_CREATE.REQUEST, (localCreateId: string | undefined) => {
        return {
            localCreateId
        };
    }),
    success: createActionLegacy(
        RECRUITER_COMPANY_GALLERY_IMAGE_CREATE.SUCCESS,
        (localCreateId: string | undefined, galleryImage: GalleryImage, recruiter: Recruiter) => {
            return {
                localCreateId,
                galleryImage,
                recruiter
            };
        }
    ),
    failure: createActionLegacy(
        RECRUITER_COMPANY_GALLERY_IMAGE_CREATE.FAILURE,
        (localCreateId: string | undefined, errors: unknown[] = []) => {
            return {
                localCreateId,
                errors
            };
        }
    )
};

export const REMOVE_CREATE_RECRUITER_COMPANY_GALLERY_IMAGE_LOCAL_ID = createActionType(
    'REMOVE_CREATE_RECRUITER_COMPANY_GALLERY_IMAGE_LOCAL_ID'
);
export const removeCreateRecruiterCompanyGalleryImageLocalId = createActionLegacy(
    REMOVE_CREATE_RECRUITER_COMPANY_GALLERY_IMAGE_LOCAL_ID,
    (localCreateId) => ({
        localCreateId
    })
);

export const DELETE_RECRUITER_COMPANY_GALLERY_IMAGE = createActionType('DELETE_RECRUITER_COMPANY_GALLERY_IMAGE');
export const deleteRecruiterCompanyGalleryImage = createAction(
    DELETE_RECRUITER_COMPANY_GALLERY_IMAGE,
    (id: GalleryImageId, { promise }: { promise?: Deferred<Recruiter> } = {}) => {
        return {
            payload: {
                id
            },
            meta: {
                promise
            }
        };
    }
);
export type DeleteRecruiterCompanyGalleryImageAction = ReturnType<typeof deleteRecruiterCompanyGalleryImage>;

export const RECRUITER_COMPANY_GALLERY_IMAGE_DELETE = createRequestActionTypes(
    createActionType('RECRUITER_COMPANY_GALLERY_IMAGE_DELETE')
);
export const recruiterCompanyGalleryImageDelete = {
    request: createActionLegacy(RECRUITER_COMPANY_GALLERY_IMAGE_DELETE.REQUEST, (id: GalleryImageId) => {
        return {
            id
        };
    }),
    success: createActionLegacy(
        RECRUITER_COMPANY_GALLERY_IMAGE_DELETE.SUCCESS,
        (id: GalleryImageId, recruiter: Recruiter) => {
            return {
                id,
                recruiter
            };
        }
    ),
    failure: createActionLegacy(
        RECRUITER_COMPANY_GALLERY_IMAGE_DELETE.FAILURE,
        (id: GalleryImageId, errors: unknown[] = []) => {
            return {
                id,
                errors
            };
        }
    )
};

export const REMOVE_DELETE_RECRUITER_COMPANY_GALLERY_IMAGE_ID = createActionType(
    'REMOVE_DELETE_RECRUITER_COMPANY_GALLERY_IMAGE_ID'
);
export const removeDeleteRecruiterCompanyGalleryImageId = createActionLegacy(
    REMOVE_DELETE_RECRUITER_COMPANY_GALLERY_IMAGE_ID,
    (id: GalleryImageId) => ({
        id
    })
);
