import debounce from 'just-debounce-it';
import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { isBefore, parseISO } from 'date-fns';

// import uploadFileIllustration from '../../assets/images/illustration-upload-file.png';

import './CandidateConversation.style.scss';

import { createWindow } from '../../common/window/';
import config from '../../config';

import { removeElementAtIndex } from '../../utils/array';

import { requestDownload } from '../../modules/files/api';
import { getDraft, setDraft } from '../../services/conversation/draft';

import {
    sendConversationMessage,
    startMessagesPolling,
    stopMessagesPolling,
    updateConversationLastRead
} from '../../modules/conversations/actions';
import {
    getConversation,
    getConversationFetchStatus,
    getConversationMessages,
    getConversationMessagesFetchStatus
} from '../../modules/conversations/selectors';
import { getCandidate, getJob } from '../../modules/entities/selectors';
import { getFileRequestByCandidateIdCreateStatus } from '../../modules/files/selectors';
import { fetchTemplates } from '../../modules/templates/actions';
import { getVisibleTemplates } from '../../modules/templates/selectors';
import { getUser } from '../../modules/users/selectors';

import Banner from '../../components/Banner';
import MessageForm from '../../components/MessageForm';
import MessageList from '../../components/MessageList';

import RequestDocumentsView from '../RequestDocumentsView';
import CandidateConversationTemplatesOverlay from './CandidateConversationTemplatesOverlay';

import { ERRORS_BANNER } from './CandidateConversation.constants';
import { allAttachmentsHaveCorrectSize, getCompiledTemplate } from './CandidateConversation.utils';

import MessageListEmptyState from '../../components/MessageListEmptyState/MessageListEmptyState';
import { Candidate } from '../../modules/candidates/types';
import { Conversation, EnhancedMessage, Message } from '../../modules/conversations/types';
import { areAllMessagesNotSentByUser } from '../../modules/conversations/utils';
import { Job } from '../../modules/jobs/types';
import { Template } from '../../modules/templates/types';
import { CreateStatus, FetchStatus } from '../../modules/types';
import { User } from '../../modules/users/types';

type TemplateId = Template['id'];

type ConnectorProps = {
    jobId: number;
    candidateId: number;
    useDraft?: boolean;
    useLegacyTheme?: boolean;
    isMobile: boolean;
};

type ConnectedStateProps = {
    candidate: Candidate | null;
    job: Job | null;
    currentUser: User;

    conversationId: number;
    conversation: Conversation | null;
    conversationFetchStatus: FetchStatus;

    messages: Message[];
    messagesFetchStatus: FetchStatus;

    fileRequestCreateStatus: CreateStatus;

    templates: Template[];
};

type ConnectedDispatchProps = {
    startMessagesPolling: typeof startMessagesPolling;
    stopMessagesPolling: typeof stopMessagesPolling;
    updateConversationLastRead: typeof updateConversationLastRead;
    sendConversationMessage: typeof sendConversationMessage;
    fetchTemplates: typeof fetchTemplates;
};

type Props = ConnectorProps & ConnectedStateProps & ConnectedDispatchProps;

type State = {
    newMessage: string;
    newMessageTouched: boolean;
    attachments: Array<File>;
    showRequestDocumentsView: boolean;
    errorBanner: string | null;
    selectedTemplateId: TemplateId | null;
    showMobileTemplatesOverlay: boolean;
    templatePreview: string;
};

// We create a safe setDraft to avoid writing into the storage to often.
const setDraftSafe = debounce(setDraft, 500);

export class CandidateConversation extends React.Component<Props, State> {
    updateConversationLastReadTimeout: ReturnType<typeof setTimeout> | null = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            newMessage: props.useDraft ? getDraft(props.conversationId) : '',
            newMessageTouched: false,
            attachments: [],
            showRequestDocumentsView: false,
            errorBanner: null,
            selectedTemplateId: null,
            showMobileTemplatesOverlay: false,
            templatePreview: ''
        };

        this.handleUpdateConversationLastRead = this.handleUpdateConversationLastRead.bind(this);
        this.handleClickDownloadAttachmentOfMessage = this.handleClickDownloadAttachmentOfMessage.bind(this);
        this.handleSendMessage = this.handleSendMessage.bind(this);
        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.handleChangeTemplatePreview = this.handleChangeTemplatePreview.bind(this);

        this.handleSelectAttachDocuments = this.handleSelectAttachDocuments.bind(this);
        this.handleDropDocuments = this.handleDropDocuments.bind(this);
        this.handleRemoveAttachment = this.handleRemoveAttachment.bind(this);
        this.showMaxSizeAttachmentError = this.showMaxSizeAttachmentError.bind(this);
        this.dismissErrorBanner = this.dismissErrorBanner.bind(this);
        this.handleSelectTemplate = this.handleSelectTemplate.bind(this);
        this.showMobileTemplatesOverlay = this.showMobileTemplatesOverlay.bind(this);
        this.hideMobileTemplatesOverlay = this.hideMobileTemplatesOverlay.bind(this);
        this.getCompiledTemplate = this.getCompiledTemplate.bind(this);
        this.previewTemplate = this.previewTemplate.bind(this);
        this.insertTemplate = this.insertTemplate.bind(this);
    }

    componentDidMount() {
        this.props.fetchTemplates();
        this.props.startMessagesPolling(this.props.conversationId);

        if (this.props.messagesFetchStatus === 'loaded') {
            this.scheduleUpdateConversationLastRead();
        }
    }

    componentWillUnmount() {
        this.clearScheduledUpdateConversationLastRead();
        this.props.stopMessagesPolling(this.props.conversationId);
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.fileRequestCreateStatus === 'creating' && this.props.fileRequestCreateStatus === 'created') {
            this.setState({
                showRequestDocumentsView: false
            });
        }

        if (this.props.messagesFetchStatus === 'loaded') {
            const currentUserId = this.props.currentUser.id;

            const prevMessagesFromOthers = prevProps.messages.filter(({ owner }) => {
                return owner !== currentUserId;
            });
            const currentMessagesFromOthers = this.props.messages.filter(({ owner }) => {
                return owner !== currentUserId;
            });

            if (prevMessagesFromOthers.length !== currentMessagesFromOthers.length) {
                this.scheduleUpdateConversationLastRead();
            }
        }
    }

    clearScheduledUpdateConversationLastRead() {
        if (this.updateConversationLastReadTimeout) {
            clearTimeout(this.updateConversationLastReadTimeout);
            this.updateConversationLastReadTimeout = null;
        }
    }

    scheduleUpdateConversationLastRead() {
        if (this.updateConversationLastReadTimeout) {
            this.clearScheduledUpdateConversationLastRead();
        }

        this.updateConversationLastReadTimeout = setTimeout(() => {
            this.handleUpdateConversationLastRead();
        }, 2000);
    }

    handleUpdateConversationLastRead() {
        const {
            conversationId,
            conversationFetchStatus,
            conversation,
            messagesFetchStatus,
            messages,
            updateConversationLastRead
        } = this.props;

        const isConversationLoaded = conversationFetchStatus === 'loaded';
        const areMessagesLoaded = messagesFetchStatus === 'loaded';

        if (!isConversationLoaded && !areMessagesLoaded) {
            updateConversationLastRead(conversationId);
        } else if (isConversationLoaded && !areMessagesLoaded) {
            updateConversationLastRead(conversationId);
        } else if (!isConversationLoaded && areMessagesLoaded) {
            const latestMessage = messages[messages.length - 1];

            if (!!latestMessage) {
                updateConversationLastRead(conversationId, latestMessage.created);
            } else {
                updateConversationLastRead(conversationId);
            }
        } else if (isConversationLoaded && !!conversation && areMessagesLoaded) {
            const latestMessage = messages[messages.length - 1];

            if (
                !!latestMessage &&
                (!conversation.last_read || isBefore(parseISO(conversation.last_read), parseISO(latestMessage.created)))
            ) {
                updateConversationLastRead(conversationId, latestMessage.created);
            } else if (!latestMessage) {
                updateConversationLastRead(conversationId);
            }
        }
    }

    handleClickDownloadAttachmentOfMessage(event: { message: Message | EnhancedMessage }) {
        const message = event.message;
        if (!message || !message.extra_data) {
            return;
        }

        const file = message.extra_data.file;
        if (!file) {
            return;
        }

        if (!!file.id) {
            requestDownload(file.id).then((response) => {
                window.location = response.data.url;
            });
        } else if (!!file.content_url) {
            // The content url is always a preview of a file and we need a new tab for it.
            const previewWindow = createWindow();
            previewWindow.location = file.content_url;
            previewWindow.focus();
        }
    }

    handleSendMessage() {
        const newMessage = this.state.newMessage.trim();

        const noUser = !this.props.currentUser;
        const isEmptyMessage = !/\S/.test(newMessage);
        const noAttachments = this.state.attachments.length === 0;
        const someAttachmentHasSizeError = !allAttachmentsHaveCorrectSize(this.state.attachments);

        if (noUser || (isEmptyMessage && noAttachments) || someAttachmentHasSizeError) {
            return;
        }

        this.props.sendConversationMessage(
            this.props.conversationId,
            this.props.currentUser.id,
            newMessage,
            this.state.attachments,
            true
        );

        this.setState({
            newMessage: '',
            attachments: []
        });
    }

    handleChangeMessage(message: string) {
        this.setState({
            newMessage: message
        });

        if (this.props.useDraft) {
            setDraftSafe(this.props.conversationId, message);
        }

        if (!this.state.newMessageTouched) {
            this.handleUpdateConversationLastRead();
            this.setState({
                newMessageTouched: true
            });
        }
    }

    handleChangeTemplatePreview(preview: string) {
        this.setState({ templatePreview: preview });
    }

    handleSelectAttachDocuments(files: Array<File>) {
        const attachments = [...files]; // convert into true array

        this.setState((state) => ({
            attachments: [...state.attachments, ...attachments]
        }));

        // Check for errors
        const someAttachmentHasSizeError = !allAttachmentsHaveCorrectSize(attachments);
        if (someAttachmentHasSizeError) {
            this.showMaxSizeAttachmentError();
        }
    }

    handleDropDocuments(acceptedFiles: Array<File>) {
        if (!acceptedFiles) {
            return;
        }

        this.handleSelectAttachDocuments(acceptedFiles);
    }

    handleRemoveAttachment(index: number) {
        this.setState((state) => ({
            attachments: removeElementAtIndex(state.attachments, index)
        }));
    }

    showMaxSizeAttachmentError() {
        this.setState({ errorBanner: ERRORS_BANNER.ATTACHMENT_SIZE });
    }

    dismissErrorBanner() {
        this.setState({ errorBanner: null });
    }

    handleSelectTemplate(templateId: TemplateId | null) {
        this.setState({ selectedTemplateId: templateId });
    }

    showMobileTemplatesOverlay() {
        if (this.props.isMobile) {
            this.setState({
                showMobileTemplatesOverlay: true,
                selectedTemplateId: null,
                templatePreview: this.getCompiledTemplate(null) || ''
            });
        }
    }

    hideMobileTemplatesOverlay() {
        if (this.props.isMobile) {
            this.setState({
                showMobileTemplatesOverlay: false
            });
        }
    }

    getCompiledTemplate(templateId?: TemplateId | null): string | void {
        const { selectedTemplateId } = this.state;
        const { templates, candidate, job } = this.props;

        const targetTemplateId = typeof templateId !== 'undefined' ? templateId : selectedTemplateId;

        return getCompiledTemplate(templates, targetTemplateId, candidate, job);
    }

    previewTemplate(templateId?: TemplateId | null) {
        const compiledTemplate = this.getCompiledTemplate(templateId);

        if (compiledTemplate) {
            this.handleChangeTemplatePreview(compiledTemplate);
        }
    }

    insertTemplate(templateId?: TemplateId | null) {
        const { templatePreview } = this.state;

        const shouldInsertTemplatePreview = templatePreview !== '';

        if (shouldInsertTemplatePreview) {
            this.handleChangeMessage(templatePreview);
        } else {
            const compiledTemplate = this.getCompiledTemplate(templateId);

            if (compiledTemplate) {
                this.handleChangeMessage(compiledTemplate);
            }
        }
    }

    render() {
        const { jobId, candidateId, conversation, messages, messagesFetchStatus, useLegacyTheme } = this.props;
        const conversationIsOpen = !conversation?.closed;

        const [showRequestDocumentsView, setShowRequestDocumentsView] = [
            this.state.showRequestDocumentsView,
            (v) => {
                this.setState({ showRequestDocumentsView: v });
            }
        ];

        const handleClickRequestDocuments = () => {
            setShowRequestDocumentsView(true);
        };

        const handleCloseRequestDocuments = () => {
            setShowRequestDocumentsView(false);
        };

        // TODO: replace root div with FileDropZone component when recruiters attachments are supported in the whole product
        return (
            <div
                className={classNames('CandidateConversation', {
                    'CandidateConversation--legacy-theme': useLegacyTheme
                })}
                // TODO: Uncomment when recruiters are able to send attachments
                // overlay={
                //     <div className="CandidateConversation__drag-overlay">
                //         <img
                //             className="CandidateConversation__drag-overlay__illustration"
                //             src={uploadFileIllustration}
                //             alt="3 envelopes of different sizes and colors in a tray"
                //         />
                //         <span className="CandidateConversation__drag-overlay__title">
                //             <FormattedMessage id="CANDIDATE_CONVERSATION_PAGE.DRAG_AND_DROP_FILE_OVERLAY.TITLE" />
                //         </span>
                //     </div>
                // }
                // onDrop={this.handleDropDocuments}
            >
                {this.state.errorBanner === ERRORS_BANNER.ATTACHMENT_SIZE && (
                    <Banner
                        type="error"
                        content={{
                            id: 'CANDIDATE_CONVERSATION_PAGE.MAX_SIZE_ERROR.CONTENT'
                        }}
                        disableDismiss
                        actions={[
                            {
                                label: 'CANDIDATE_CONVERSATION_PAGE.MAX_SIZE_ERROR.DISMISS_ACTION',
                                onAction: this.dismissErrorBanner
                            }
                        ]}
                    />
                )}

                <RequestDocumentsView
                    jobId={jobId}
                    candidateId={candidateId}
                    open={showRequestDocumentsView}
                    onClose={handleCloseRequestDocuments}
                />

                <CandidateConversationTemplatesOverlay
                    show={this.state.showMobileTemplatesOverlay}
                    hideOverlay={this.hideMobileTemplatesOverlay}
                    templates={this.props.templates}
                    selectedTemplateId={this.state.selectedTemplateId as TemplateId}
                    selectTemplate={this.handleSelectTemplate}
                    previewTemplate={this.previewTemplate}
                    insertTemplate={this.insertTemplate}
                    templatePreview={this.state.templatePreview}
                    handleChangeTemplatePreview={this.handleChangeTemplatePreview}
                />

                <div className="CandidateConversation__message-list">
                    <MessageList
                        messages={messages}
                        participants={!!this.props.conversation ? this.props.conversation.participants : []}
                        currentUser={this.props.currentUser}
                        onClickDownloadAttachmentOfMessage={this.handleClickDownloadAttachmentOfMessage}
                        useLegacyTheme={useLegacyTheme}
                    >
                        {messagesFetchStatus === 'loaded' && areAllMessagesNotSentByUser(this.props.messages) && (
                            <MessageListEmptyState />
                        )}
                    </MessageList>
                </div>

                {conversationIsOpen && (
                    <div data-testid="message-form" className="CandidateConversation__message-form">
                        <MessageForm
                            message={this.state.newMessage}
                            attachments={this.state.attachments}
                            templates={this.props.templates}
                            selectedTemplateId={this.state.selectedTemplateId}
                            showRequestDocuments={config.showRequestDocuments}
                            isMobile={this.props.isMobile}
                            removeAttachment={this.handleRemoveAttachment}
                            onSendMessage={this.handleSendMessage}
                            onChangeMessage={this.handleChangeMessage}
                            onSelectAttachDocuments={this.handleSelectAttachDocuments}
                            onClickRequestDocumentsButton={handleClickRequestDocuments}
                            onClickTemplatesButton={this.showMobileTemplatesOverlay}
                            onSelectTemplate={this.handleSelectTemplate}
                            insertTemplate={this.insertTemplate}
                            useLegacyTheme={useLegacyTheme}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state, props: ConnectorProps): ConnectedStateProps => {
    const candidate = getCandidate(state, props.candidateId);
    const conversationId = !!candidate ? candidate.conversation_id : 0;
    const job = getJob(state, props.jobId);

    const currentUser = getUser(state);

    if (!currentUser) {
        throw new Error('Could not get the current user from state');
    }

    return {
        candidate,
        job,
        currentUser,

        conversationId: conversationId,
        conversation: getConversation(state, conversationId),
        conversationFetchStatus: getConversationFetchStatus(state, conversationId),

        // We create a new array because Array#reverse is mutating
        messages: [...getConversationMessages(state, conversationId)].reverse(),
        messagesFetchStatus: getConversationMessagesFetchStatus(state, conversationId),

        fileRequestCreateStatus: getFileRequestByCandidateIdCreateStatus(state, props.candidateId),

        templates: getVisibleTemplates(state)
    };
};

const mapDispatchToProps: ConnectedDispatchProps = {
    startMessagesPolling,
    stopMessagesPolling,
    updateConversationLastRead,
    sendConversationMessage,
    fetchTemplates
};

export default connect(mapStateToProps, mapDispatchToProps)(CandidateConversation);
