import * as React from 'react';
import classNames from 'classnames';
import { Map as LeafletMap, TileLayer } from 'react-leaflet';
import config from '../../config';

import { MapMarkerProps } from './MapMarker';

import { MapBounds } from './types';

import { LEAFLET_URL_TEMPLATE, LEAFLET_ATTRIBUTION, MAX_ZOOM } from './constants';

import './Map.scss';

type ValidChild = React.ReactElement<MapMarkerProps>;

export type MapProps = {
    lat?: number;
    long?: number;
    zoom?: number;
    children?: ValidChild | ValidChild[];
    noDragging?: boolean;
    noZooming?: boolean;
    noControls?: boolean;

    bounds?: MapBounds;

    className?: string;
    id?: string;
    style?: React.CSSProperties;
};

class Map extends React.Component<MapProps> {
    render() {
        const {
            lat,
            long,
            zoom,
            noDragging,
            noZooming,
            noControls,
            children,
            bounds,
            className,
            id,
            style
        } = this.props;

        const position: [number, number] | undefined = !!lat && !!long ? [lat, long] : undefined;

        return (
            <LeafletMap
                className={classNames('Map', className)}
                dragging={!noDragging}
                scrollWheelZoom={!noZooming}
                doubleClickZoom={!noZooming}
                zoomControl={!noControls}
                center={position}
                zoom={zoom}
                id={id}
                style={style}
                bounds={bounds}
            >
                <TileLayer
                    attribution={LEAFLET_ATTRIBUTION}
                    url={LEAFLET_URL_TEMPLATE}
                    accessToken={config.leaflet.accessToken}
                    id={'mapbox/streets-v11'}
                    maxZoom={MAX_ZOOM}
                />
                {children}
            </LeafletMap>
        );
    }
}

export default Map;
