import * as React from 'react';
import { RawIntlProvider, useIntl, IntlShape } from 'react-intl';

type IntlEnhancerProps = {
    enhancer?: (intl: IntlShape) => IntlShape;
    children: React.ReactNode;
};

function IntlEnhancer({ enhancer: enhance, children }: IntlEnhancerProps) {
    const intl = useIntl();
    const enhancedIntl = typeof enhance === 'function' ? enhance(intl) : intl;

    return <RawIntlProvider value={enhancedIntl}>{children}</RawIntlProvider>;
}

export default IntlEnhancer;
