import * as React from 'react';
import classNames from 'classnames';

import wrapWithScrolled, { ScrolledProps } from '../../Scrolled';

type Props = ScrolledProps & {
    children: React.ReactNode;
    className?: string;
};

function DialogContent(props: Props): React.ReactElement {
    const className = classNames('tf-dialog__content', props.className, {
        'tf-dialog__content--scroll-at-top': !props.scrolled,
        'tf-dialog__content--scroll-at-bottom': props.reachedEnd
    });

    return (
        <div className={className} onScroll={props.onScroll}>
            {props.children}
        </div>
    );
}

export default wrapWithScrolled(DialogContent);
