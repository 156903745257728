import * as React from 'react';

import './CheckoutProductPageHeadline.style.scss';

type Props = {
    subtitle?: (React.ReactNode | null) | string;
    children: React.ReactNode;
};

function CheckoutProductPageHeadline(props: Props) {
    return (
        <div className="CheckoutProductPageHeadline">
            <h2 className="CheckoutProductPageHeadline__title">{props.children}</h2>

            {!!props.subtitle && <p className="CheckoutProductPageHeadline__subtitle">{props.subtitle}</p>}
        </div>
    );
}

export default CheckoutProductPageHeadline;
