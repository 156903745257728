import { useState, useEffect } from 'react';

function useMediaQueryMatches(query: string, initial: boolean | null = false): boolean | null {
    const [matches, setMatches] = useState<boolean | null>(initial);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const mediaQueryList: MediaQueryList = window.matchMedia(query);
        mediaQueryList.addListener;

        setMatches(mediaQueryList.matches);

        const listener = (mediaQueryListEvent: MediaQueryListEvent) => {
            setMatches(mediaQueryListEvent.matches);
        };

        const supportsEventListeners =
            'addEventListener' in (mediaQueryList as any) && 'removeEventListener' in (mediaQueryList as any);

        if (supportsEventListeners) {
            mediaQueryList.addEventListener('change', listener);
        } else {
            mediaQueryList.addListener(listener);
        }

        return () => {
            if (supportsEventListeners) {
                mediaQueryList.removeEventListener('change', listener);
            } else {
                mediaQueryList.removeListener(listener);
            }
        };
    }, [query]);

    return matches;
}

export default useMediaQueryMatches;
