import { Reducer } from 'redux';
import { Action, Pagination as _PaginationState } from '../../modules/types';
import { getUrlSearch, parseSearch } from '../url';

export type ReducerCondition = <S>(state: S, action: Action) => boolean;

export function conditionalReducer(condition: ReducerCondition) {
    return <S>(reducer: Reducer<S, Action>): Reducer<S, Action> => {
        const initialState = reducer(undefined, { type: null } as any);

        return (state: S = initialState, action: Action) => {
            if (!condition<S>(state, action)) {
                return state;
            }

            return reducer(state, action);
        };
    };
}

export function resetReducer(condition: ReducerCondition) {
    return <S>(reducer: Reducer<S, Action>): Reducer<S, Action> => {
        const initialState = reducer(undefined, { type: null } as any);

        return (state: S = initialState, action: Action) => {
            if (condition<S>(state, action)) {
                return initialState;
            }

            return reducer(state, action);
        };
    };
}

type CreateMapReducerState<S> = {
    [key: string]: S;
};

/**
 * Creates a reducer that applies the given sub reducer to the values of the map if the key getter returns a truthy
 * value.
 * @param {Reducer<SubState>} subReducer the reducer that should be applied to the values of the map
 * @param {(action: Action) => string | null} getKey the getter that returns the key of the value the reducer should be applied to
 * @returns {Reducer<State>} the reducer that applies the sub reducer to the value that match the key returned by the key getter
 */
export function createMapReducer<SubState, A extends Action>(
    subReducer: Reducer<SubState, A | Action>,
    getKey: (action: A) => string | null
): Reducer<CreateMapReducerState<SubState>, A> {
    const mapReducer = (state: CreateMapReducerState<SubState> = {}, action: A): CreateMapReducerState<SubState> => {
        const key = getKey(action);

        if (key === null || typeof key === 'undefined') {
            return state;
        }

        let subState = state[key];
        if (typeof subState === 'undefined') {
            const initAction: Action = {
                type: '@@mapReducer/init',
                payload: {}
            };
            subState = subReducer(undefined, initAction);
        }

        const newSubState = subReducer(subState, action);

        return {
            ...state,
            [key]: newSubState
        };
    };

    return mapReducer;
}

type CreateMapIteratorReducerState<S> = {
    [key: string]: S;
};

/**
 * Iterates the give reducer over all the values which are currently in the state of this reducer.
 * @param {Reducer<SubState>} subReducer the reducer that should be applied to all given values
 * @returns {Reducer<State>} the reducer that iterates the sub reducer over all values of the current state
 */
export function createMapIteratorReducer<SubState>(
    subReducer: Reducer<SubState, Action>
): Reducer<CreateMapIteratorReducerState<SubState>, Action> {
    return (state: CreateMapIteratorReducerState<SubState> = {}, action: Action) => {
        const keys = Object.keys(state);

        return keys.reduce(
            (state, key) => {
                const subState = state[key];
                const newSubState = subReducer(subState, action);

                return {
                    ...state,
                    [key]: newSubState
                };
            },
            { ...state }
        );
    };
}

export type PaginationState = _PaginationState;
export function createPagination({
    count,
    next,
    previous
}: {
    count?: number;
    next?: string | null;
    previous?: string | null;
} = {}): PaginationState {
    const nextState: PaginationState = {
        count: count || 0,
        nextPage: null,
        previousPage: null
    };

    // Next page
    const nextPageUrl = next;
    if (!!nextPageUrl) {
        const search = getUrlSearch(nextPageUrl);
        const { page: nextPage } = parseSearch<{ page: string }>(search);

        nextState.nextPage = parseInt(nextPage, 10);
    }

    // Previous page
    const previousPageUrl = previous;
    if (!!previousPageUrl) {
        const search = getUrlSearch(previousPageUrl);
        const { page: previousPage } = parseSearch<{ page: string }>(search);

        nextState.previousPage = parseInt(previousPage, 10);
    }

    return nextState;
}
