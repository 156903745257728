import { createSelector } from 'reselect';

import { State as RootState } from '../../store/reducer';
import { FetchStatus } from '../types';
import { JobRegion } from './types';

import { MOUNT, State, ListsByCoordinatesState, ListState } from './reducer';
import { convertCoordinatesToCoordinatesString } from './utils';
import { Coordinates } from './types';
import { initialListState } from './reducer/listsByCoordinates';

function getState(state: RootState): State {
    return state[MOUNT];
}

const getListsByCoordinates: (state: RootState) => ListsByCoordinatesState = createSelector(getState, (state) => {
    return state.listsByCoordinates;
});

function getListByCoordinates(state: RootState, coordinates: Coordinates): ListState {
    const key = convertCoordinatesToCoordinatesString(coordinates);

    return getListsByCoordinates(state)[key] || initialListState;
}

export function getListByCoordinatesFetchStatus(state: RootState, coordinates: Coordinates): FetchStatus {
    return getListByCoordinates(state, coordinates).fetchStatus;
}

export function getListByCoordinatesEntries(state: RootState, coordinates: Coordinates): JobRegion[] {
    return getListByCoordinates(state, coordinates).entries;
}
