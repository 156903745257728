import { combineReducers } from 'redux';

import resetOnLogout from '../../authentication/reducer/resetOnLogout';
import { FETCH_FILES_BY_CANDIDATE_ID } from '../../files/actions';

import { EntityTypeEntitesState, EntityTypeStateEntitiesPartial } from './';
import { Action } from '../../types';
import { File } from '../../files/types';

export type FileEntityTypeState = EntityTypeStateEntitiesPartial<File>;

export const entitiesReducer = (
    state: EntityTypeEntitesState<File> = {},
    action: Action
): EntityTypeEntitesState<File> => {
    switch (action.type) {
        case FETCH_FILES_BY_CANDIDATE_ID.SUCCESS:
            const files: File[] = action.payload.data.results;

            // Create a new state object by creating a copy of the actual state and insert/replace files.
            return files.reduce((state: EntityTypeEntitesState<File>, file: File) => {
                return {
                    // Create a weak copy of the current state
                    ...state,

                    // Insert/replace file by id
                    [file.id]: file
                };
            }, state);

        default:
            return state;
    }
};

const filesReducer = combineReducers<FileEntityTypeState>({
    entities: entitiesReducer
});

export default resetOnLogout(filesReducer);
