import * as React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

function LanguageDropdownToggle() {
    const { locale } = useIntl();

    return (
        <span className="NavigationActions__dropdown-toggle">
            <FormattedMessage id={`NAVIGATION.ACTIONS.LANGUAGE_MENU.LABEL.${locale}`} />
        </span>
    );
}

export default LanguageDropdownToggle;
