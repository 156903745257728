import React from 'react';
import { FormattedMessage } from 'react-intl';
import BottomActionBar from '../../BottomActionBar';
import Button from '../../Button';
import MobilePageContainer from '../../MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../../NavigationBar';
import wrapWithScrolled, { ScrolledProps } from '../../Scrolled';
import './DeleteCandidateViewMobile.scss';

export type DeleteCandidateViewMobileProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: (event: React.FormEvent<HTMLElement>) => void;
    children: React.ReactNode;
};

export type DeleteCandidateViewMobileFinalProps = DeleteCandidateViewMobileProps & ScrolledProps;

function DeleteCandidateViewMobile({
    open,
    onClose,
    onSubmit,
    children,
    onScroll,
    scrolled
}: DeleteCandidateViewMobileFinalProps) {
    const handleClickCloseAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        onClose();
    };

    return (
        <MobilePageContainer show={open}>
            <NavigationBar
                noShadow={!scrolled}
                centerElement={
                    <NavigationBarText>
                        <FormattedMessage id="DELETE_CANDIDATE.TITLE.SHORT" />
                    </NavigationBarText>
                }
                rightElement={<NavigationBarCloseAction onClick={handleClickCloseAction} />}
            />

            <form onSubmit={onSubmit} className="DeleteCandidateViewMobile__form">
                <div className="DeleteCandidateViewMobile__content" onScroll={onScroll}>
                    {children}
                </div>

                <BottomActionBar>
                    <Button type="submit" typeStyle="flat" variationStyle="destructive">
                        <FormattedMessage id="DELETE_CANDIDATE.ACTION.DELETE" />
                    </Button>
                </BottomActionBar>
            </form>
        </MobilePageContainer>
    );
}

export default wrapWithScrolled(DeleteCandidateViewMobile);
