import { JobStatistics } from '../../modules/jobs/types';

export function calculateCountOfNewCandidates(statistics: JobStatistics) {
    return statistics.candidates_new;
}

export function calculateCountOfSeenCandidates(statistics: JobStatistics) {
    return (
        statistics.candidates_locked +
        statistics.candidates_interesting +
        statistics.candidates_phone_interview +
        statistics.candidates_interview +
        statistics.candidates_hired
    );
}

export function calculateCountOfUnreadMessages(statistics: JobStatistics) {
    return statistics.messages_unread_by_recruiter;
}
