import * as React from 'react';
import classNames from 'classnames';

export type JobFormTasksListItemFormFieldProps = {
    disabled: boolean;
};

function JobFormTasksListItemFormField({ disabled }: JobFormTasksListItemFormFieldProps) {
    const computedClassName = classNames('JobFormTasksListItemFormField__drag-handle', {
        'JobFormTasksListItemFormField__drag-handle--inactive': disabled
    });

    return (
        <span className={computedClassName}>
            <svg
                className="JobFormTasksListItemFormField__drag-handle-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path
                    fillRule="evenodd"
                    d="M3,18.2884615 C2.58578644,18.2884615 2.25,17.9526751 2.25,17.5384615 C2.25,17.124248 2.58578644,16.7884615 3,16.7884615 L21,16.7884615 C21.4142136,16.7884615 21.75,17.124248 21.75,17.5384615 C21.75,17.9526751 21.4142136,18.2884615 21,18.2884615 L3,18.2884615 Z M3,12.75 C2.58578644,12.75 2.25,12.4142136 2.25,12 C2.25,11.5857864 2.58578644,11.25 3,11.25 L21,11.25 C21.4142136,11.25 21.75,11.5857864 21.75,12 C21.75,12.4142136 21.4142136,12.75 21,12.75 L3,12.75 Z M3,7.21153846 C2.58578644,7.21153846 2.25,6.87575202 2.25,6.46153846 C2.25,6.0473249 2.58578644,5.71153846 3,5.71153846 L21,5.71153846 C21.4142136,5.71153846 21.75,6.0473249 21.75,6.46153846 C21.75,6.87575202 21.4142136,7.21153846 21,7.21153846 L3,7.21153846 Z"
                />
            </svg>
        </span>
    );
}

export default JobFormTasksListItemFormField;
