import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CandidateStateChangeViewMobile from '../../components/CandidateStateChangeViewMobile';
import CandidateStateItem from '../../components/CandidateStateItem';
import CandidateStateProgress from '../../components/CandidateStateProgress';
import HireConfirmationView from '../../components/HireConfirmationView';
import UnlockCandidateView, {
    UNLOCK_CANDIDATE_VIEW_DO_NOT_SHOW_AGAIN_STORAGE_KEY
} from '../../components/UnlockCandidateView';
import { updateCandidateState } from '../../modules/candidates/actions';
import { Candidate, CandidateState } from '../../modules/candidates/types';
import { isBetweenInterestingAndHired, isUnprocessedOrNotInteresting } from '../../modules/candidates/utils';
import { getCandidate } from '../../modules/entities/selectors';
import { getCandidateConversationRoute } from '../../routes';
import * as localStorage from '../../services/localStorage';
import { State as ApplicationState } from '../../store/reducer';
import { BREAKPOINT_MD, matchesBreakpoint } from '../../utils/viewport';
import CandidateActionUnlock from '../CandidateActionUnlock';
import './style.scss';

type ConnectorProps = {
    jobId: number;
    candidateId: number;
};

type ConnectedStateProps = {
    candidate: Candidate | null;
};

type ConnectedDispatchProps = {
    updateCandidateState: typeof updateCandidateState;
};

type Props = RouteComponentProps & ConnectedStateProps & ConnectedDispatchProps & ConnectorProps;

type State = {
    selectedState: CandidateState;

    showStateChangeView: boolean;

    shouldShowUnlockView: boolean;
    showUnlockView: boolean;

    showHireView: boolean;
};

export class CandidateStatePicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            selectedState: !!props.candidate ? props.candidate.state : 'new',

            showStateChangeView: false,

            shouldShowUnlockView: !localStorage.get(UNLOCK_CANDIDATE_VIEW_DO_NOT_SHOW_AGAIN_STORAGE_KEY),
            showUnlockView: false,

            showHireView: false
        };

        this.handleOpenStateChangeView = this.handleOpenStateChangeView.bind(this);
        this.handleCloseStateChangeView = this.handleCloseStateChangeView.bind(this);
        this.handleCloseUnlockView = this.handleCloseUnlockView.bind(this);
        this.handleCloseHireView = this.handleCloseHireView.bind(this);

        this.handleChangeState = this.handleChangeState.bind(this);
        this.handleConfirmUnlock = this.handleConfirmUnlock.bind(this);
        this.handleConfirmHire = this.handleConfirmHire.bind(this);
        this.changeState = this.changeState.bind(this);
    }

    handleOpenStateChangeView(): void {
        this.setState({ showStateChangeView: true });
    }

    handleOpenUnlockView(): void {
        this.setState({ showUnlockView: true });
    }

    handleOpenHireView(): void {
        this.setState({ showHireView: true });
    }

    handleCloseStateChangeView(): void {
        this.setState({ showStateChangeView: false });
    }

    handleCloseUnlockView(): void {
        this.setState({ showUnlockView: false });
    }

    handleCloseHireView(): void {
        this.setState({ showHireView: false });
    }

    handleConfirmUnlock(shouldBypassViewNextTime: boolean) {
        this.changeState('after-confirmation', 'interesting', shouldBypassViewNextTime);
    }

    handleConfirmHire() {
        this.changeState('after-confirmation', 'hired', false);
    }

    handleChangeState(newState: CandidateState) {
        this.changeState('start', newState, false);
    }

    changeState(phase: 'start' | 'after-confirmation', newState: CandidateState, shouldBypassViewNextTime: boolean) {
        const { history, jobId, candidateId, candidate, updateCandidateState } = this.props;

        if (!candidate) {
            return;
        }

        const processStateChange = (newState) => {
            this.setState(() => ({
                selectedState: newState
            }));

            updateCandidateState(candidateId, newState);

            const redirectToConversation =
                matchesBreakpoint(BREAKPOINT_MD) &&
                // If the candidate will be moved to interesting and the current state is not later one
                newState === 'interesting' &&
                !isBetweenInterestingAndHired(candidate);

            if (redirectToConversation) {
                history.replace(getCandidateConversationRoute(jobId, candidateId));
            }

            this.setState(() => ({
                showStateChangeView: false,
                showUnlockView: false,
                showHireView: false
            }));
        };

        if (newState === 'interesting') {
            const shouldShowUnlockView = !localStorage.get(UNLOCK_CANDIDATE_VIEW_DO_NOT_SHOW_AGAIN_STORAGE_KEY);

            const noConfirmationNeeded =
                phase === 'after-confirmation' ||
                // If the user decided that they don't want to see anymore before we skip the confirmation view
                (phase === 'start' && !shouldShowUnlockView) ||
                // If the candidate was already unlocked we skip the confirmation step
                (phase === 'start' && isBetweenInterestingAndHired(candidate));

            if (noConfirmationNeeded) {
                // If the user decided that they don't want to see the view next time we update it
                if (shouldShowUnlockView && shouldBypassViewNextTime) {
                    localStorage.set(UNLOCK_CANDIDATE_VIEW_DO_NOT_SHOW_AGAIN_STORAGE_KEY, true);
                }

                processStateChange('interesting');
            } else {
                this.handleOpenUnlockView();
            }
        } else if (newState === 'hired') {
            if (phase === 'after-confirmation') {
                processStateChange(newState);
            } else {
                this.handleOpenHireView();
            }
        } else {
            processStateChange(newState);
        }
    }

    render() {
        const { candidate } = this.props;
        const { selectedState, showStateChangeView, showUnlockView, showHireView } = this.state;

        if (!candidate) {
            return null;
        }

        const shouldShowUnlockView = !localStorage.get(UNLOCK_CANDIDATE_VIEW_DO_NOT_SHOW_AGAIN_STORAGE_KEY);

        return (
            <div>
                <div className="CandidateStatePicker__desktop">
                    <CandidateStateProgress state={selectedState} onChangeState={this.handleChangeState} />
                </div>

                <div className="CandidateStatePicker__mobile">
                    {isUnprocessedOrNotInteresting(candidate) ? (
                        <div className="CandidateStatePicker__mobile-unlock-button-container">
                            <CandidateActionUnlock
                                candidateId={candidate.id}
                                className="CandidateStatePicker__mobile-unlock-button"
                            />
                        </div>
                    ) : (
                        <CandidateStateItem candidateState={candidate.state} onClick={this.handleOpenStateChangeView} />
                    )}
                </div>

                <CandidateStateChangeViewMobile
                    open={showStateChangeView}
                    candidate={candidate}
                    onClose={this.handleCloseStateChangeView}
                    onChangeState={this.handleChangeState}
                />

                <UnlockCandidateView
                    open={showUnlockView}
                    doNotShowAgain={!shouldShowUnlockView}
                    onClose={this.handleCloseUnlockView}
                    onUnlock={this.handleConfirmUnlock}
                />

                <HireConfirmationView
                    open={showHireView}
                    candidate={candidate}
                    onClose={this.handleCloseHireView}
                    onConfirm={this.handleConfirmHire}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState, props: ConnectorProps): ConnectedStateProps => ({
    candidate: getCandidate(state, props.candidateId)
});

const mapDispatchToProps: ConnectedDispatchProps = {
    updateCandidateState
};

const withStore = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(withStore(CandidateStatePicker));
