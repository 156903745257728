import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Button from '../../components/Button';
import Dialog from '../../components/Dialog';
import DialogActionBar from '../../components/DialogActionBar';
import DialogText from '../../components/DialogText';
import DialogTitle from '../../components/DialogTitle';

import { getLoginRoute } from '../../routes';

import { companyContact } from '../../constants';

// Types
type Props = {
    successful: boolean;
};

function EmailVerificationRequestDialog(props: Props) {
    const messageVariant = props.successful ? 'successful' : 'unsuccessful';

    const mailLink = (
        <a href={`mailto:${companyContact.email}`} target="_blank" rel="noopener noreferrer">
            {companyContact.email}
        </a>
    );

    return (
        <Dialog>
            <DialogTitle>
                <FormattedMessage id={`EMAIL_VERIFICATION_REQUEST_DIALOG.${messageVariant}.TITLE`} />
            </DialogTitle>
            <DialogText>
                <FormattedMessage
                    id={`EMAIL_VERIFICATION_REQUEST_DIALOG.${messageVariant}.TEXT`}
                    values={{
                        mailLink: mailLink
                    }}
                />
            </DialogText>
            <DialogActionBar center>
                <Button typeStyle="raised" variationStyle="brand" component={Link} to={getLoginRoute()}>
                    <FormattedMessage id="EMAIL_VERIFICATION_REQUEST_DIALOG.ACTION" />
                </Button>
            </DialogActionBar>
        </Dialog>
    );
}

export default EmailVerificationRequestDialog;
