import { Button } from '@truffls/design-system-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Job } from '../../modules/jobs/types';
import { isActive, isDraft } from '../../modules/jobs/utils';
import { handleError } from '../../services/errorHandler';
import { ExtraDataError } from '../../utils/errors';

export type JobActionViewInAppProps = {
    job: Job;
};

function JobActionViewInApp({ job }: JobActionViewInAppProps) {
    if (!isDraft(job) && !isActive(job)) {
        return null;
    }

    const handleClickPreview = async () => {
        if (!!job.preview_url) {
            window.open(job.preview_url, '_blank');
        } else {
            handleError(
                new ExtraDataError('Draft job is missing preview URL', {
                    jobId: job.id,
                    jobState: job.state,
                    jobPreviewUrl: job.preview_url
                })
            );
        }
    };

    return (
        <React.Fragment>
            {isDraft(job) && (
                <Button type="button" onClick={handleClickPreview} typeStyle="outlined" variationStyle="normal">
                    <FormattedMessage id="JOB_ACTION_VIEW_IN_APP.PREVIEW_LABEL" />
                </Button>
            )}
            {isActive(job) && (
                <Button component="a" href={job.static_url} typeStyle="outlined" variationStyle="normal">
                    <FormattedMessage id="JOB_ACTION_VIEW_IN_APP.LABEL" />
                </Button>
            )}
        </React.Fragment>
    );
}

export default JobActionViewInApp;
