import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { Options, Value } from '../constants';

type Props = {
    name: string;
    title: React.ReactNode;

    options: Options;

    id?: string;
    value?: Value[];
    onChange?: (value: Value[]) => void;
};

type Handlers = {
    handleChange: {
        _default: (event: React.ChangeEvent) => void;
        [filter: string]: (event: React.ChangeEvent) => void;
    };
};

class FilterSection extends React.Component<Props> {
    handlers: Handlers;

    constructor(props: Props) {
        super(props);

        this.handlers = {
            handleChange: {
                _default: this.handleChange.bind(this, '_default')
            }
        };
    }

    componentDidMount() {
        this.updateHandlers(this.props.options);
    }

    componentDidUpdate() {
        this.updateHandlers(this.props.options);
    }

    updateHandlers(options: Options) {
        this.handlers.handleChange = options.reduce((handlers, option) => {
            const filter = option.value;

            // We don't want to recreate existing handlers
            if (!!handlers[filter]) {
                return handlers;
            }

            return {
                ...handlers,
                [filter]: this.handleChange.bind(this, filter)
            };
        }, this.handlers.handleChange);
    }

    handleChange(filter: '_default' | Value) {
        const value = this.props.value;
        const onChange = this.props.onChange;

        if (typeof value === 'undefined' || typeof onChange === 'undefined') {
            return;
        }

        if (filter === '_default') {
            return onChange([]);
        }

        const index = value.indexOf(filter);
        if (!!~index) {
            onChange([...value.slice(0, index), ...value.slice(index + 1)]);
        } else {
            onChange([...value, filter]);
        }
    }

    render() {
        const { title } = this.props;

        return (
            <div className="FilterAndOrderingPageMobile__section">
                <div className="FilterAndOrderingPageMobile__section-title">{title}</div>

                {this.renderOptions()}
            </div>
        );
    }

    renderOptions() {
        const { id = '', options, value = [] } = this.props;
        const { handleChange } = this.handlers;

        const optionsElements = options.map(
            (option): React.ReactElement => {
                const filter = option.value;

                const checked = !!~value.indexOf(filter);

                return (
                    <span key={`option-${filter}`} className="tf-checkbox">
                        <input
                            type="checkbox"
                            id={`${id}_option_${filter}`}
                            checked={checked}
                            onChange={handleChange[filter]}
                        />

                        <label
                            className="tf-checkbox__label FilterAndOrderingPageMobile__option"
                            htmlFor={`${id}_option_${filter}`}
                            role="option"
                        >
                            <span className="tf-checkbox__faux FilterAndOrderingPageMobile__option-control" />
                            <span className="tf-checkbox__label-text FilterAndOrderingPageMobile__option-label">
                                {option.label}
                            </span>
                        </label>
                    </span>
                );
            }
        );

        return (
            <React.Fragment>
                <span className="tf-radio">
                    <input
                        type="radio"
                        id={`${id}_option__default`}
                        checked={!value.length}
                        onChange={handleChange._default}
                    />

                    <label
                        className="tf-radio__label FilterAndOrderingPageMobile__option"
                        htmlFor={`${id}_option__default`}
                        role="option"
                    >
                        <span className="tf-radio__faux FilterAndOrderingPageMobile__option-control" />
                        <span className="tf-radio__label-text FilterAndOrderingPageMobile__option-label">
                            <FormattedMessage id="FILTER_AND_ORDERING_PAGE_MOBILE.FILTER_DEFAULT" />
                        </span>
                    </label>
                </span>
                {optionsElements}
            </React.Fragment>
        );
    }
}

export default FilterSection;
