import { ANIMATION_STEP_INIT, ANIMATION_STEP_OPEN, ANIMATION_STEP_NEXT_STEP } from './constants';
import { COMPLETE_TUTORIAL_UPDATE_STATUS } from './redux/constants';

import { User } from '../../modules/users/types';
import { Recruiter } from '../../modules/recruiters/types';

import { CompleteTutorialUpdateStatus } from './redux/types';

export function shouldOpenTutorial(
    user: User | null,
    isAuthenticated: boolean,
    recruiter: Recruiter | null,
    completeTutorialUpdateStatus: CompleteTutorialUpdateStatus
) {
    return (
        !!user &&
        isAuthenticated &&
        !!recruiter &&
        recruiter.signed_up &&
        !recruiter.has_onboarding_completed &&
        completeTutorialUpdateStatus !== COMPLETE_TUTORIAL_UPDATE_STATUS.UPDATING
    );
}

export function shouldShowGtc(user: User | null, isLegalAgreementEnabled: boolean) {
    return isLegalAgreementEnabled && !!user && !user.accepted_latest_gtc;
}

export function getAnimationState(isOpen: boolean, step: number) {
    let animationState;
    if (isOpen) {
        if (step > 0) {
            animationState = ANIMATION_STEP_NEXT_STEP;
        } else {
            animationState = ANIMATION_STEP_OPEN;
        }
    } else {
        animationState = ANIMATION_STEP_INIT;
    }

    return animationState;
}
