import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from '@truffls/design-system-react';

import { Candidate } from '../../../modules/candidates/types';

import Dialog from '../../../components/DialogV2';
import DialogTitle from '../../../components/DialogV2/DialogTitle';
import DialogContent from '../../../components/DialogV2/DialogContent';
import DialogActions from '../../../components/DialogV2/DialogActions';

type Props = {
    open: boolean;
    onClose: () => void;
    isLoading: boolean;
    hasFailed: boolean;
    candidates: Candidate[];
    children: React.ReactNode;
};

function CandidateAlsoAppliedForModal({ open, onClose, children }: Props) {
    const onClickCloseAction = () => {
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <FormattedMessage id="CANDIDATE_ALSO_APPLIED_FOR_VIEW.MODAL.TITLE_TEXT" />
            </DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button type="button" typeStyle="flat" variationStyle="normal" onClick={onClickCloseAction}>
                    <FormattedMessage id="CANDIDATE_ALSO_APPLIED_FOR_VIEW.CLOSE_TEXT" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CandidateAlsoAppliedForModal;
