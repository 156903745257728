import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormField, Input } from '@truffls/design-system-react';

import { useField } from '../../../../utils/form/react-final-form';

import { useJobFormPageHasError } from '../../../JobFormPage/JobFormPage.hooks';

import { parseValue } from './JobFormBenefitsFormField.utils';

export type JobFormBenefitsFormFieldValue = string;
export type JobFormBenefitsFormFieldName = 'benefits';
export const JOB_EDIT_BENEFITS_FORM_FIELD_NAME = 'benefits';

export type JobFormBenefitsFormFieldProps = {};

function JobFormBenefitsFormField({}: JobFormBenefitsFormFieldProps) {
    const { input, meta } = useField<JobFormBenefitsFormFieldValue, HTMLInputElement | HTMLTextAreaElement>(
        JOB_EDIT_BENEFITS_FORM_FIELD_NAME,
        {
            parse: parseValue
        }
    );
    const hasError = useJobFormPageHasError();

    return (
        <FormField
            id="form-job-field-benefits"
            labelText={<FormattedMessage id="JOB_EDIT.BENEFITS_FORM_FIELD.LABEL_TEXT" />}
            hasError={hasError(input.value, meta)}
        >
            <Input {...input} multiline minRows={5} data-testid="job-form-benefits-form-field-input" />
        </FormField>
    );
}

export default JobFormBenefitsFormField;
