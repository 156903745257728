import { FormField, Input } from '@truffls/design-system-react';
import * as React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { links } from '../../constants';
import { Candidate } from '../../modules/candidates/types';
import { getExpirationDaysLeft } from '../../modules/candidates/utils';
import { Job } from '../../modules/jobs/types';
import { Recruiter } from '../../modules/recruiters/types';
import { getInitials } from '../../modules/recruiters/utils';
import { isOnlyWhitespace } from '../../utils/string';
import { BREAKPOINT_MD, matchesBreakpoint } from '../../utils/viewport';
import BottomActionBar from '../BottomActionBar';
import Button from '../Button';
import LoaderButton from '../LoaderButtonV2';
import MobileAppChatMessage from '../MobileApp/MobileAppChatMessage';
import MobileAppMessenger from '../MobileApp/MobileAppMessenger';
import MobilePageContainer from '../MobilePageContainer';
import NavigationBar, { NavigationBarCloseAction, NavigationBarText } from '../NavigationBar';
import scrolled, { ScrolledProps } from '../Scrolled';

import './ExpiringCandidateMessagingView.style.scss';

type Props = ScrolledProps & {
    open: boolean;
    loading: boolean;

    candidate: Candidate | null;
    job: Job | null;
    recruiter: Recruiter | null;

    onClose: () => void;
    onSubmit: (message: string) => void;

    intl: IntlShape;
};

type State = {
    isViewportMobile: boolean;
    message: string;
};

class ExpiringCandidateMessagingView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isViewportMobile: false,
            message: ''
        };

        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.updateViewportState();
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.open && !!this.props.open) {
            this.updateViewportState();
        }

        if (!!prevProps.open && !this.props.open) {
            this.setState({ message: '' });
        }
    }

    updateViewportState() {
        if (!matchesBreakpoint(BREAKPOINT_MD)) {
            this.setState({ isViewportMobile: true });
        } else {
            this.setState({ isViewportMobile: false });
        }
    }

    handleChangeMessage(event) {
        this.setState({ message: event.target.value });
    }

    handleKeyPress(event) {
        if (event.ctrlKey && (event.keyCode === 13 || event.keyCode === 10)) {
            this.handleSubmit(event);
        }
    }

    handleClose() {
        this.setState({ message: '' });
        this.props.onClose();
    }

    handleSubmit(event) {
        event.preventDefault();
        const { recruiter } = this.props;
        const { message } = this.state;

        if (isOnlyWhitespace(message) || !recruiter) {
            return;
        }

        this.props.onSubmit(message);
    }

    generateMessage(): React.ReactNode {
        const { recruiter } = this.props;
        const { message } = this.state;

        if (isOnlyWhitespace(message) || !recruiter) {
            return null;
        }

        const recruiterPortraitSrc = !!recruiter.portrait_file ? recruiter.portrait_file.original : null;

        return (
            <span key="recruiter-message">
                <MobileAppChatMessage
                    fromUserType="recruiter"
                    portraitImageSrc={recruiterPortraitSrc}
                    portraitFallBack={getInitials(recruiter)}
                    messageText={message}
                />
            </span>
        );
    }

    render() {
        const { candidate, intl } = this.props;
        const { message } = this.state;

        if (!candidate) {
            return null;
        }

        const title = <FormattedMessage id="EXPIRING_CANDIDATE_MESSAGING_VIEW.TITLE" />;

        const link = (
            <a href={links.privacy[intl.locale]} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="EXPIRING_CANDIDATE_MESSAGING_VIEW.EXPLANATION.LINK" />
            </a>
        );

        const content = (
            <div>
                <div className="ExpiringCandidateMessagingView__heading">
                    <FormattedMessage id="EXPIRING_CANDIDATE_MESSAGING_VIEW.HEADING" />
                </div>
                <p className="ExpiringCandidateMessagingView__explanation">
                    <FormattedMessage
                        id="EXPIRING_CANDIDATE_MESSAGING_VIEW.EXPLANATION"
                        values={{
                            daysLeft: getExpirationDaysLeft(candidate).toString(),
                            link: link
                        }}
                    />
                </p>
                <div className="ExpiringCandidateMessagingView__cta">
                    <FormattedMessage id="EXPIRING_CANDIDATE_MESSAGING_VIEW.CTA" />
                </div>
                <FormField
                    id="message"
                    labelText={<FormattedMessage id="EXPIRING_CANDIDATE_MESSAGING_VIEW.MESSAGE.LABEL" />}
                >
                    <Input
                        value={message}
                        multiline
                        minRows={2}
                        maxRows={2}
                        autoFocus
                        className="ExpiringCandidateMessagingView__textarea"
                        onChange={this.handleChangeMessage}
                        onKeyDown={this.handleKeyPress}
                    />
                </FormField>
            </div>
        );

        if (this.state.isViewportMobile) {
            return this.renderMobileFrame(title, content);
        } else {
            return this.renderDesktopFrame(title, content);
        }
    }

    renderMobileFrame(title, content) {
        const { scrolled, onScroll, open, loading } = this.props;

        const isMessageEmpty = isOnlyWhitespace(this.state.message);

        return (
            <MobilePageContainer show={open}>
                <NavigationBar
                    noShadow={!scrolled}
                    centerElement={<NavigationBarText>{title}</NavigationBarText>}
                    rightElement={<NavigationBarCloseAction onClick={this.handleClose} />}
                />

                <form onSubmit={this.handleSubmit} className="ExpiringCandidateMessagingView__form">
                    <div className="ExpiringCandidateMessagingView__content" onScroll={onScroll}>
                        {content}
                    </div>

                    <BottomActionBar>
                        <LoaderButton
                            type="submit"
                            typeStyle="raised"
                            variationStyle="brand"
                            disabled={isMessageEmpty}
                            loading={loading}
                        >
                            <FormattedMessage id="EXPIRING_CANDIDATE_MESSAGING_VIEW.ACTION.SUBMIT" />
                        </LoaderButton>
                    </BottomActionBar>
                </form>
            </MobilePageContainer>
        );
    }

    renderDesktopFrame(title, content) {
        const { open, loading, recruiter, job } = this.props;

        const isMessageEmpty = isOnlyWhitespace(this.state.message);

        return (
            <Modal show={open} onHide={this.handleClose}>
                <Modal.Header className="ExpiringCandidateMessagingView__modal-header">
                    <Modal.Title className="ExpiringCandidateMessagingView__modal-title">{title}</Modal.Title>
                </Modal.Header>

                <form onSubmit={this.handleSubmit}>
                    <Modal.Body className="ExpiringCandidateMessagingView__modal-body">
                        <div className="ExpiringCandidateMessagingView__app-preview">
                            <div className="ExpiringCandidateMessagingView__app-preview__title">
                                <FormattedMessage id="EXPIRING_CANDIDATE_MESSAGING_VIEW.APP_PREVIEW.TITLE" />
                            </div>
                            <div className="ExpiringCandidateMessagingView__app-preview__phone">
                                <MobileAppMessenger scale={0.91} job={job} recruiter={recruiter}>
                                    {this.generateMessage()}
                                </MobileAppMessenger>
                            </div>
                        </div>
                        <div className="ExpiringCandidateMessagingView__modal-explanation">{content}</div>
                    </Modal.Body>

                    <Modal.Footer className="ExpiringCandidateMessagingView__modal-footer">
                        <Button
                            type="button"
                            typeStyle="flat"
                            variationStyle="normal"
                            className="pull-left"
                            onClick={this.handleClose}
                        >
                            <FormattedMessage id="EXPIRING_CANDIDATE_MESSAGING_VIEW.ACTION.CANCEL" />
                        </Button>
                        <LoaderButton
                            type="submit"
                            typeStyle="flat"
                            variationStyle="brand"
                            disabled={isMessageEmpty}
                            loading={loading}
                        >
                            <FormattedMessage id="EXPIRING_CANDIDATE_MESSAGING_VIEW.ACTION.SUBMIT" />
                        </LoaderButton>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

export default injectIntl(scrolled(ExpiringCandidateMessagingView));
