import { FieldValidator } from 'final-form';

export function composeValidators<FieldValue = any>(
    validators: FieldValidator<FieldValue>[]
): FieldValidator<FieldValue> {
    return (...args) => {
        let result;
        for (let i = 0, l = validators.length; i < l && typeof result === 'undefined'; i++) {
            result = validators[i](...args);
        }

        return result;
    };
}

export function isValidator(value: any): value is FieldValidator<unknown> {
    return typeof value === 'function';
}

export function parseFileInputValue(files: File[]) {
    return files[0] || null;
}
