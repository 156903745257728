import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { companyContact } from '../../constants';

import Button from '../Button';
import Dialog from '../Dialog';
import DialogActionBar from '../DialogActionBar';
import DialogIcon from '../DialogIcon';
import DialogText from '../DialogText';
import DialogTitle from '../DialogTitle';

import requestTokenExpiredIcon from './images/ic_request-token-expired.svg';

const RequestTokenNotFoundDialog = () => {
    return (
        <Dialog>
            <DialogIcon src={requestTokenExpiredIcon} />
            <DialogTitle>
                <FormattedMessage id="REQUEST_TOKEN_NOT_FOUND_DIALOG.TITLE" />
            </DialogTitle>
            <DialogText>
                <FormattedMessage id="REQUEST_TOKEN_NOT_FOUND_DIALOG.TEXT" />
            </DialogText>
            <DialogActionBar center>
                <Button
                    component="a"
                    href={`mailto:${companyContact.email}`}
                    rel="noopener noreferrer"
                    typeStyle="raised"
                    variationStyle="brand"
                >
                    <FormattedMessage id="REQUEST_TOKEN_NOT_FOUND_DIALOG.ACTION" />
                </Button>
            </DialogActionBar>
        </Dialog>
    );
};

export default RequestTokenNotFoundDialog;
