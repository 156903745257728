import { FieldValidator } from 'final-form';
import React from 'react';
import { composeValidators } from './utils';

export default function useComposeValidators<FieldValue = any>(
    validators: FieldValidator<FieldValue>[]
): FieldValidator<FieldValue> {
    return React.useMemo(() => {
        return composeValidators(validators);
    }, [validators]);
}
