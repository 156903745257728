import { createAction } from 'redux-actions';
import { v4 as uuidV4 } from 'uuid';

import { createActionTypeCreator } from '../../utils/redux';

const createActionType = createActionTypeCreator('NOTIFICATIONS');

export const ADD_SYSTEM_NOTIFICATION = createActionType('ADD_SYSTEM_NOTIFICATION');
export const REMOVE_SYSTEM_NOTIFICATION = createActionType('REMOVE_SYSTEM_NOTIFICATION');

export const ADD_ACTION_NOTIFICATION = createActionType('ADD_ACTION_NOTIFICATION');
export const REMOVE_ACTION_NOTIFICATION = createActionType('REMOVE_ACTION_NOTIFICATION');

import { SystemNotification, ActionNotification } from '../../modules/notifications/types';

export const addSystemNotification = createAction(
    ADD_SYSTEM_NOTIFICATION,
    (
        type: SystemNotification['type'],
        content: SystemNotification['content'],
        actions: SystemNotification['actions'],
        illustration: SystemNotification['illustration'] | null = null,
        disableDismiss: SystemNotification['disableDismiss'] = false,
        dismissAction: SystemNotification['dismissAction'] | null = null
    ): SystemNotification => ({
        id: uuidV4(),
        created: Date.now(),
        type,
        content,
        actions,
        illustration,
        disableDismiss,
        dismissAction
    })
);

export const removeSystemNotification = createAction(REMOVE_SYSTEM_NOTIFICATION, (id: string) => ({
    id
}));

export const addActionNotification = createAction(
    ADD_ACTION_NOTIFICATION,
    (content: ActionNotification['content'], action: ActionNotification['action'] | null = null) => ({
        id: uuidV4(),
        created: Date.now(),
        content,
        action
    })
);

export const removeActionNotification = createAction(REMOVE_ACTION_NOTIFICATION, (id: string) => ({
    id
}));
