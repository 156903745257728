import * as React from 'react';

type Props = {
    children: React.ReactNode;
};

const HeadlineSubtitle = (props: Props) => {
    return <p className="Headline__subtitle">{props.children}</p>;
};

export default HeadlineSubtitle;
