import * as React from 'react';
import { FormField, Input } from '@truffls/design-system-react';

import fieldify, { FieldifyProps } from '../../utils/form/field';
import { hasError } from '../../utils/form/state';
import { useFieldMeta, useAutoFocus } from '../../utils/form/hooks';

type Props = FieldifyProps &
    Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'name' | 'id'> & {
        type: string;
        id: string;
        label?: React.ReactNode;
        labelText?: React.ReactNode;
        helpText?: React.ReactNode;
        errors?: {
            [key: string]: React.ReactNode;
        };
    };

const FormFieldInput = (props: Props) => {
    const {
        input,
        meta,

        id,
        type,
        label,
        labelText,
        helpText,
        errors = {},

        autoFocus,

        ...rest
    } = props;

    const inputRef = React.useRef<HTMLInputElement | null>(null);

    useAutoFocus(inputRef, autoFocus);

    const fieldMeta = useFieldMeta(meta);

    const fieldHasError = hasError(input.value, fieldMeta);

    const assistiveText = fieldHasError && errors[meta.error] ? errors[meta.error] : helpText;

    return (
        <FormField id={id} labelText={labelText || label} helpText={assistiveText} hasError={fieldHasError}>
            <Input {...rest} {...input} value={!!input.value ? input.value : ''} type={type} inputRef={inputRef} />
        </FormField>
    );
};

export default fieldify()(FormFieldInput);
