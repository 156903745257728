import * as React from 'react';
import { Candidate } from '../../modules/candidates/types';
import { Job } from '../../modules/jobs/types';
import { Recruiter } from '../../modules/recruiters/types';
import { BREAKPOINT_SM, matchesBreakpoint } from '../../utils/viewport';
import DeleteCandidateViewContent from './DeleteCandidateViewContent';
import DeleteCandidateViewDesktop from './DeleteCandidateViewDesktop';
import DeleteCandidateViewMobile from './DeleteCandidateViewMobile';

type Props = {
    open: boolean;

    candidate: Candidate | null;
    job: Job | null;
    recruiter: Recruiter | null;

    onClose: () => void;
    onDelete: (message: string) => void;
};

type State = {
    isViewportMobile: boolean;
    message: string;
};

class DeleteCandidateView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isViewportMobile: false,
            message: ''
        };

        this.handleChangeMessage = this.handleChangeMessage.bind(this);
        this.handleSubmitMessage = this.handleSubmitMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this.setState({
            isViewportMobile: matchesBreakpoint(BREAKPOINT_SM) === false
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (!prevProps.open && !!this.props.open) {
            this.setState({
                isViewportMobile: matchesBreakpoint(BREAKPOINT_SM) === false
            });
        }
    }

    handleChangeMessage(message: string) {
        this.setState({ message });
    }

    handleSubmitMessage(event: React.FormEvent<HTMLElement>) {
        event.preventDefault();

        this.props.onDelete(this.state.message);
    }

    handleClose() {
        this.setState({ message: '' });
        this.props.onClose();
    }

    render() {
        const { recruiter, job, candidate } = this.props;
        const { isViewportMobile, message } = this.state;

        if (!candidate) {
            return null;
        }

        const content = (
            <DeleteCandidateViewContent
                message={message}
                onChangeMessage={this.handleChangeMessage}
                onSubmitMessage={this.handleSubmitMessage}
            />
        );

        if (isViewportMobile) {
            return (
                <DeleteCandidateViewMobile
                    open={this.props.open}
                    onClose={this.handleClose}
                    onSubmit={this.handleSubmitMessage}
                >
                    {content}
                </DeleteCandidateViewMobile>
            );
        } else {
            return (
                <DeleteCandidateViewDesktop
                    recruiter={recruiter}
                    job={job}
                    candidate={candidate}
                    message={message}
                    open={this.props.open}
                    onClose={this.handleClose}
                    onSubmit={this.handleSubmitMessage}
                >
                    {content}
                </DeleteCandidateViewDesktop>
            );
        }
    }
}

export default DeleteCandidateView;
