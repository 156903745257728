import React from 'react';
import { FormattedMessage } from 'react-intl';

import JobFormLanguagesFormFields, { JobFormLanguagesFormFieldsValue } from './JobFormLanguagesFormFields';
import JobFormSkillsFormFields, { JobFormSkillsFormFieldsValue } from './JobFormSkillsFormFields';
import JobFormTalentsFormFields, { JobFormTalentsFormFieldsValue } from './JobFormTalentsFormFields';

export type JobFormRequirementsFormFieldsetValues = {
    skills: JobFormSkillsFormFieldsValue;
    talents: JobFormTalentsFormFieldsValue;
    languages: JobFormLanguagesFormFieldsValue;
};

export type JobFormRequirementsFormFieldsetProps = {};

function JobFormRequirementsFormFieldset({}: JobFormRequirementsFormFieldsetProps) {
    return (
        <fieldset className="panel">
            <div className="panel-heading">
                <h2 className="panel-title">
                    <FormattedMessage id="JOB_EDIT.REQUIREMENTS_FORM_FIELDSET.TITLE" />
                </h2>
            </div>

            <div className="panel-body">
                <JobFormSkillsFormFields />
                <JobFormTalentsFormFields />
                <JobFormLanguagesFormFields />
            </div>
        </fieldset>
    );
}

export default JobFormRequirementsFormFieldset;
