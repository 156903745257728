import {
    PATHS_TO_EXLCUDE_FOR_TRACKING,
    PATH_PATTERNS_TO_EXCLUDE_FOR_TRACKING,
    PATH_PATTERNS_TO_SKIP_FOR_TRACKING
} from '.';
import { findTrackableRoute } from './registry/analytics';
import { RoutesRegistry } from './registry/registry';

export function findTrackableRouteWithExcludes(routesRegistry: RoutesRegistry, path: string) {
    return findTrackableRoute(routesRegistry, path, PATH_PATTERNS_TO_SKIP_FOR_TRACKING);
}

export function canTrackRoute(routesRegistry: RoutesRegistry, path: string): boolean {
    if (PATHS_TO_EXLCUDE_FOR_TRACKING.includes(path)) {
        return false;
    }

    const trackableRoute = findTrackableRouteWithExcludes(routesRegistry, path);

    if (PATH_PATTERNS_TO_EXCLUDE_FOR_TRACKING.includes(trackableRoute.path)) {
        return false;
    }

    return true;
}
