import { ProductV2 } from '../types';

export type ProductV2WithProductSlug = Omit<ProductV2, 'product_slug'> & {
    product_slug: NonNullable<ProductV2['product_slug']>;
    price: NonNullable<ProductV2['price']>;
};

export function hasProductSlug(product: ProductV2): product is ProductV2WithProductSlug {
    return product.product_slug !== null;
}
