import { CommonCookieStorageOptions } from './common';
import * as cookies from './cookie';
import { CookieStorage } from './types';
import { calculateExpiresFromMaxAge } from './utils';

export type CreateBrowserCookieStorageFactoryOptions<
    OutputData extends {},
    InputData extends {} = OutputData
> = CommonCookieStorageOptions<OutputData, InputData>;

export function createBrowserCookieStorageFactory<OutputData extends {}, InputData extends {} = OutputData>({
    name: cookieName,
    maxAge,
    secure,
    deserialize,
    serialize
}: CreateBrowserCookieStorageFactoryOptions<OutputData, InputData>): BrowserCookieStorageFactory<
    OutputData,
    InputData
> {
    return (): CookieStorage<OutputData, InputData> => {
        const get = (): OutputData | null => {
            const cookieValue = cookies.get(cookieName);

            if (!!cookieValue) {
                return deserialize(cookieValue);
            }

            return null;
        };

        const set = (data: InputData) => {
            const cookieValue = serialize(data);

            if (!!cookieValue) {
                cookies.set(cookieName, cookieValue, {
                    secure,
                    expires: calculateExpiresFromMaxAge(maxAge)
                });
            }
        };

        const remove = () => {
            cookies.remove(cookieName, {
                secure
            });
        };

        return {
            get,
            set,
            remove
        };
    };
}

export interface BrowserCookieStorageFactory<
    OutputData extends {},
    InputData extends {} = OutputData,
    Storage extends CookieStorage<OutputData, InputData> = CookieStorage<OutputData, InputData>
> {
    (): Storage;
}
