import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './SearchBarLoadingState.style.scss';

type Props = {
    asOverlay: boolean;
};

function SearchBarLoadingState({ asOverlay }: Props) {
    return (
        <div
            className={classNames('SearchBarLoadingState', {
                'SearchBarLoadingState--overlay': asOverlay
            })}
        >
            <div className="SearchBarLoadingState__content">
                <FormattedMessage id="SEARCH_BAR_LOADING_STATE.TEXT" />
            </div>
        </div>
    );
}

export default SearchBarLoadingState;
