import { combineReducers } from 'redux';

import { getUrlSearch, parseSearch } from '../../utils/url';

import resetOnLogout from '../authentication/reducer/resetOnLogout';

import { FETCH_STATUS, INITIAL_PAGINATION } from '../constants';

import { TEMPLATES_FETCH, TEMPLATE_DELETE } from './actions';

import { Template } from './types';
import { Action, FetchStatus, Errors, Pagination } from '../types';

type EntriesState = Array<Template['id']>;
type FetchStatusState = FetchStatus;
type PaginationState = Pagination;

export const MOUNT: 'templates' = 'templates';

export type TemplatesState = {
    entries: EntriesState;
    fetchStatus: FetchStatusState;
    pagination: PaginationState;
    errors: Errors;
};

export type State = {
    [key in typeof MOUNT]: TemplatesState;
};

export const entriesReducer = (state: EntriesState = [], action: Action): EntriesState => {
    switch (action.type) {
        case TEMPLATES_FETCH.SUCCESS:
            const newEntries = action.payload.data.results.map((template) => template.id);

            return newEntries;

        case TEMPLATE_DELETE.SUCCESS:
            const templateId = action.payload.templateId;
            return state.filter((id: Template['id']) => id !== templateId);

        default:
            return state;
    }
};

export const fetchStatusReducer = (state: FetchStatusState = FETCH_STATUS.NONE, action: Action): FetchStatusState => {
    switch (action.type) {
        case TEMPLATES_FETCH.REQUEST:
            return FETCH_STATUS.LOADING;

        case TEMPLATES_FETCH.SUCCESS:
            return FETCH_STATUS.LOADED;

        case TEMPLATES_FETCH.FAILURE:
            return FETCH_STATUS.FAILED;

        default:
            return state;
    }
};

export const paginationReducer = (state: PaginationState = INITIAL_PAGINATION, action: Action): PaginationState => {
    switch (action.type) {
        case TEMPLATES_FETCH.SUCCESS:
            const nextState: PaginationState = {
                ...INITIAL_PAGINATION,
                count: action.payload.data.count
            };

            // Next page
            const nextPageUrl = action.payload.data.next;
            const thereIsNextPage = nextPageUrl !== null;
            if (thereIsNextPage) {
                const search = getUrlSearch(nextPageUrl);
                const { page: nextPage } = parseSearch<{ page: string }>(search);

                nextState.nextPage = parseInt(nextPage, 10);
            }

            // Previous page
            const previousPageUrl = action.payload.data.previous;
            const thereIsPreviousPage = previousPageUrl !== null;
            if (thereIsPreviousPage) {
                const search = getUrlSearch(previousPageUrl);
                const { page: previousPage } = parseSearch<{ page: string }>(search);

                nextState.previousPage = parseInt(previousPage, 10);
            }

            return nextState;

        default:
            return state;
    }
};

export const errorsReducer = (state: Errors = [], action: Action): Errors => {
    switch (action.type) {
        case TEMPLATES_FETCH.REQUEST:
            return [];

        case TEMPLATES_FETCH.FAILURE:
            return action.payload.errors;

        default:
            return state;
    }
};

const templatesReducer = combineReducers({
    entries: entriesReducer,
    fetchStatus: fetchStatusReducer,
    pagination: paginationReducer,
    errors: errorsReducer
});

export default resetOnLogout(templatesReducer);
