import React from 'react';
import classNames from 'classnames';
import { useIsOpen } from '../../hooks/useIsOpen';
import useConditionalCache from '../../hooks/useConditionalCache';
import useMemoWithPrevious from '../../hooks/useMemoWithPrevious';

export type AlertProps = {
    children: React.ReactNode;
    dismissible?: boolean;
    initialIsOpen?: boolean;
    isOpen?: boolean;
    onClose?: () => void;
    type: 'info' | 'warning' | 'danger';
};

function Alert({
    children,
    dismissible = false,
    initialIsOpen = true,
    isOpen: controlledIsOpen,
    onClose,
    type
}: AlertProps) {
    const uncontrolledState = useIsOpen(initialIsOpen);

    const initialIsControlled = useConditionalCache(typeof controlledIsOpen === 'boolean', []);

    /*
     * We store the controlled `isOpen` value as long as it's valid and return the previous valid if it's invalid so
     * we have a valid state even if it changed to be uncontrolled after beeing controlled.
     */
    const currentControlledIsOpen = useMemoWithPrevious<boolean>(
        (previous = false) => {
            if (typeof controlledIsOpen === 'boolean') {
                return controlledIsOpen;
            }

            return previous;
        },
        [controlledIsOpen]
    );

    React.useEffect(() => {
        if (initialIsControlled !== (typeof controlledIsOpen === 'boolean')) {
            throw new Error('Cannot change <Alert /> from controlled to uncontrolled and and vice versa.');
        }
    }, [controlledIsOpen]);

    const isOpen = initialIsControlled ? currentControlledIsOpen : uncontrolledState.isOpen;

    const handleClickClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();

        if (!initialIsControlled) {
            uncontrolledState.close();
        }

        if (onClose) {
            onClose();
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <div
            className={classNames('alert', {
                'alert-info': type === 'info',
                'alert-warning': type === 'warning',
                'alert-danger': type === 'danger',
                'alert-dismissible': dismissible
            })}
            data-testid="alert"
        >
            {dismissible && (
                <button type="button" className="close" onClick={handleClickClose} data-testid="alert-close-button">
                    <span aria-hidden="true">×</span>
                    <span className="sr-only">Close</span>
                </button>
            )}
            {children}
        </div>
    );
}

export default Alert;
