import React from 'react';

import { GeocoderResult } from '../../../services/maps/geocoder';
import { JobLocation } from '../../../modules/jobs/types';
import Map, { MapMarker } from '../../../components/Map';

import { getBounds, getCenter, getMarker, getZoom } from './JobFormLocationMap.utils';

import './JobFormLocationMap.scss';

export type JobFormLocationMapProps = {
    currentAddress: GeocoderResult | null;
    location: JobLocation | null;
};

function JobFormLocationMap({ currentAddress, location }: JobFormLocationMapProps) {
    const marker = getMarker(location);

    const bounds = getBounds(currentAddress);
    let center: ReturnType<typeof getCenter> | undefined = undefined;
    let zoom: ReturnType<typeof getZoom> | undefined = undefined;

    if (!bounds) {
        center = getCenter(location);
        zoom = getZoom(location, currentAddress);
    }

    // We delay the rendering to ensure that all styles are already applied so that the map renders correctly.
    const [shouldRender, setShouldRender] = React.useState(false);
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setShouldRender(true);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <React.Fragment>
            {!shouldRender && <div className="JobFormLocationMap"></div>}
            {shouldRender && (
                <Map
                    lat={center?.latitude}
                    long={center?.longitude}
                    bounds={bounds}
                    zoom={zoom}
                    noZooming
                    noControls
                    noDragging
                    className="JobFormLocationMap"
                >
                    {marker && <MapMarker lat={marker.latitude} long={marker.longitude} />}
                </Map>
            )}
        </React.Fragment>
    );
}

export default JobFormLocationMap;
