import { createAction } from 'redux-actions';
import { createActionTypeCreator, createRequestActionTypes } from '../../utils/redux';
import { Response, ListResponseData, Errors } from '../types';
import { Coordinates, JobRegion } from './types';

// Factory to create all actions of the templates module
const createActionType = createActionTypeCreator('JOB_REGIONS');

// Fetch job regions
export const FETCH_JOB_REGIONS_BY_COORDINATES = createActionType('FETCH_JOB_REGIONS_BY_COORDINATES');
export const fetchJobRegionsByCoordinates = createAction(
    FETCH_JOB_REGIONS_BY_COORDINATES,
    (coordinates?: Coordinates) => ({
        coordinates
    })
);

export const JOB_REGIONS_BY_COORDINATES_FETCH = createRequestActionTypes(createActionType('JOB_REGIONS_FETCH'));
export const jobRegionsByCoordinatesFetch = {
    request: createAction(JOB_REGIONS_BY_COORDINATES_FETCH.REQUEST, (coordinates) => ({
        coordinates
    })),

    success: createAction(
        JOB_REGIONS_BY_COORDINATES_FETCH.SUCCESS,
        (coordinates: Coordinates, response: Response<ListResponseData<JobRegion>>) => ({
            coordinates,
            data: response.data
        })
    ),

    failure: createAction(JOB_REGIONS_BY_COORDINATES_FETCH.FAILURE, (coordinates: Coordinates, errors: Errors) => ({
        coordinates,
        errors
    }))
};
