import { ScriptHTMLAttributes } from 'react';
import { ClientConfig } from '../../runtime-config';

type Service = {
    key: string;
    apiUrlPattern?: RegExp;
    scriptSrc: string;
    scriptProps?: Omit<ScriptHTMLAttributes<HTMLScriptElement>, 'src'>;
    snippet: string;
    confirmation: boolean;
    toggle?: (on: boolean) => void;
};

export function getServices(config: ClientConfig) {
    return [
        !!config.analytics.posthogApiKey &&
            ({
                key: 'posthog',
                confirmation: false,
                apiUrlPattern: /^https:\/\/sonic\.truffls\.de\//,
                scriptSrc: 'https://sonic.truffls.de/static/array.js',
                snippet: `
                !function(t,e){var o,n,p,r;e.__SV||(window.posthog=e,e._i=[],e.init=function(i,s,a){
                function g(t,e){var o=e.split(".");2==o.length&&(t=t[o[0]],e=o[1]),t[e]=function(){
                t.push([e].concat(Array.prototype.slice.call(arguments,0)))}}var u=e;
                for(void 0!==a?u=e[a]=[]:a="posthog",u.people=u.people||[],u.toString=function(t){var e="posthog";
                return"posthog"!==a&&(e+="."+a),t||(e+=" (stub)"),e},u.people.toString=function(){return
                u.toString(1)+".people (stub)"},
                o="capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys".split(" "),
                n=0;n<o.length;n++)g(u,o[n]);e._i.push([i,s,a])},e.__SV=1)}(document,window.posthog||[]);

                posthog.init('${config.analytics.posthogApiKey}',{
                    api_host:'https://sonic.truffls.de',
                    autocapture: false,
                    capture_pageview: false,
                    disable_session_recording: true,
                    advanced_disable_decide: true
                })
            `
            } as Service)
    ].filter((service): service is Service => !!service);
}
